import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {SkladService} from '../../service/sklad.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

interface DataFormat {
    name: string,
    value: number
}

@Component({
    selector: 'app-pp-act-status-select',
    templateUrl: './pp-act-status-select.component.html',
    styleUrls: ['./pp-act-status-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PpActStatusSelectComponent
        }
    ]
})
export class PpActStatusSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() public  sele
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Статус акта';
    @Input() initValue: number = null;
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: DataFormat[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    onTouched = () => {};

    constructor(
        private api: SkladService,
    ) {
    }



    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }
        this.api.getPPActStatuses().subscribe(data => {
            this.dropdownList = data;
            if (this.initValue) {
                this.selectedItems = this.dropdownList.filter(x => { return x.value === this.initValue })
            }
        })
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    writeValue(data: number[]) {
        this.onSetSelectedValues(data)
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.value) !== -1
        })
    }
    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }


}
