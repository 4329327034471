import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class OrdersService extends LaraService {
    /** поиск заказов по фильтам */
    public getList(data) {
        return this.post('/order/list', data);
    }

    /** получение заказов для контроля хабов */
    public getOrdersForControl(data) {
        return this.post('/hubs/getOrdersForControl', data);
    }

    /** статистика по маршруту курьера */
    public getRouteCourier(data) {
        return this.post('/maps/zonestat', data);
    }

    /** количество точек на карте **/
    public getPointCount(query) {
        return this.get('/maps/getPointsForMap?' + query);
    }

    public getRoutesPointCount(query) {
        return this.get('/routes/getRoutesForMap?' + query);
    }

    /* public getCourierControlMap(delivery_date) {
         return this.get('/maps/getPointsForMap', {
             delivery_date: delivery_date
         });

     }*/

    /** статистика для грида, принимает тот же запрос что и сам грид */
    public getStatistic(data) {
        return this.post('/order/statistic', data);
    }

    /**
     * поиск заборов по фильтам
     * @deprecated Ganzal: используется OrdersMapComponent а я его тоже в мусорку определяю
     */
    public getZList(data) {
        return this.post('/zorder/list', data);
    }

    /** список по uid */
    public getUids(uids) {
        return this.post('/order/uids', {uids: uids});
    }

    /** Список зон для карты */
    public getZones(tarif = null, districtId = null, type = null, hubId = null, isSameday = null, shiftNumber = null) {
        return this.post('/logistic/zones', {
            tarif: tarif,
            district_id: districtId,
            type: type,
            hub_id: hubId,
            is_sameday: isSameday,
            shift_number: shiftNumber,
        });
    }

    /** Группы зон */
    public getZoneGroups(hubId = null) {
        return this.post('/logistic/zoneGroups', {hubId: hubId});
    }
    public addZoneGroup(data) {
        return this.post('/logistic/addZoneGroup', data);
    }

    /** массовая отмена заказов */
    public setCancelStatusOrders(uids) {
        return this.post('/order/canceled', {uid: uids});
    }

    /** массовое назначение курьера */
    public setCourierOrders(uids, courierId, sessionUid: string = null) {
        return this.post('/order/reCourier', {uid: uids, courier_id: courierId, sessionUid: sessionUid});
    }

    /** массовая установка даты доставки */
    public setDeliveryDate(uids, delivery_date, substatus_datechange) {
        return this.post('/order/carryover', {
            uid: uids,
            delivery_date: delivery_date,
            substatus_id: substatus_datechange
        });
    }

    /** массовый перевод на доставку */
    public setDeliveryStatusOrders(uids) {
        return this.post('/order/forDelivery', {uid: uids});
    }
    /** массовый возврат с доставки на склад */
    public cancelDeliveryForOrders(uids) {
        return this.post('/order/cancelDelivery', {uid: uids});
    }

    /** массовое закрытие заказов */
    public setFinishedStatusOrders(uids, status) {
        return this.post('/order/finished', {uid: uids, status: status});
    }

    /** массовая установка статуса заказам - админом */
    public setAdminStatus(uids, status) {
        return this.post('/order/setAdminStatus', {uid: uids, status: status});
    }

    public restoreOrders(uids) {
        return this.post('/order/restoreOrders', {uid: uids});
    }

    /** Обновление зоны */
    public updateZone(zone) {
        return this.post('/logistic/zone_edit', zone)
    }

    /** Добавление зоны */
    public addZone(zone) {
        return this.post('/logistic/zone_add', zone);
    }
}
