import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {LaraService} from './../../service/lara.service';
import {State} from '@progress/kendo-data-query';
import {Title} from '@angular/platform-browser';
import {DialogService} from '../../components/dialog/dialog.service';
import {ClientInfo, User, UserHub} from "../../service/models";
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";
import {NotifierService} from '../../service/notifier.service';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-refused-on-spot',
    templateUrl: './refused-on-spot.component.html',
    styleUrls: ['./refused-on-spot.component.scss'],
})

export class RefusedOnSpotComponent implements OnInit {

    public reasonsList: any = [];
    public ordersListResult: GridDataResult;

    public loadingReasons = false;
    public loadingOrders = false;
    public loadingConfirm = false;

    public formConfirm: FormGroup;

    public headerFilter: any = [];

    public selectedReasons: any;

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];
    public selectedClient: ClientInfo;
    public selectedOrdersIds: number[] = [];
    public selectedMassSubstatusId: number = 0;
    public mainColumnWidth: number = 25;

    public state: State = {
        take: 20,
        skip: 0,

        filter: {
            logic: 'and',
            filters: [],
        },
    };

    constructor(
        private api: LaraService,
        public dialog: DialogService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private helpers: HelpersService,
        private notifier: NotifierService,
        private title: Title,
    ) {
        this.title.setTitle('Отказы на месте');

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        this.formConfirm = new FormGroup({});
        this.selectedReasons = {};
    }

    ngOnInit() {
        this.loadReasonsList();
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();
            this.onChangeHub(hub);
        });
    }

    /**
     * Загружает список причин отказа на месте
     */
    loadReasonsList() {
        this.loadingReasons = true;
        this.api.getRefuseReasonsList().subscribe(data => {
            this.reasonsList = data;
            this.loadingReasons = false;
        }, () => {
            this.loadingReasons = false;
        });
    }

    /**
     * Загружает страницу списка неподтверждённых заказов с отказом на месте
     */
    loadOrdersPage() {
        this.loadingOrders = true;

        const filter: any = {};
        const headerFilter = this.headerFilter;
        if (this.selectedClient) {
            filter.client_id = this.selectedClient.id;
        }
        filter.hub_id = this.selectedHubId;
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.head = headerFilter;

        this.api.getRefusedOrders(filter).subscribe(data => {
            for (let order of data.data) {
                if ('undefined' === typeof this.selectedReasons[order.order_id]) {
                    this.selectedReasons[order.order_id] = 0;
                }
            }
            this.ordersListResult = data;
            // this.selectedReasons = {};
            this.loadingOrders = false;
        }, () => {
            this.loadingOrders = false;
        });
    }

    /**
     * Обработчик выбора причины
     * @param e Событие
     * @param uid Номер заказа
     */
    onReasonSelectChange(e, uid) {
        this.selectedReasons[uid] = 1 * e.target.value;
    }

    /**
     * Обработчик клика по кнопке подтверждения
     * @param e Событие
     * @param order_id Номер заказа
     */
    onConfirm(e, order_id) {
        if (this.loadingConfirm) {
            return;
        }

        const val = this.selectedReasons[order_id] || false;
        if (!val) {
            this.dialog.alert('Не выбрана причина!', 500);
            return;
        }

        this.confirmRefusedOrders([order_id], val);
    }

    /**
     * Обработчик изменения страницы
     * @param param
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.resetMassConfirmation();
        this.loadOrdersPage();
    }

    /**
     * Возвращает признак выделенности причины для заказа
     * @param order_id Номер заказа
     * @param substatus_id Идентификатор причины
     */
    isResonSelected(order_id, substatus_id) {
        return (this.selectedReasons[order_id] || 0) === substatus_id;
    }

    /**
     * Обработчик события изменения хаба (в фильтре или глобально)
     * @param hub
     */
    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;
        this.loadOrdersPage();
    }

    /**
     * Обработчик выбора клиента
     * @param event
     */
    onSelectClient(event) {
        if (this.selectedClient !== event) {
            this.resetMassConfirmation();
        }

        this.selectedClient = event ? event : null;
        this.mainColumnWidth = this.selectedClient ? 24 : 25;
        this.state.skip = 0;

        this.loadOrdersPage();
    }

    /**
     * Обработка изменения списка отмеченных заказов
     * @param event
     */
    listOfOrdersSelectionChange(event) {
        if (event.selectedRows.length) {
            event.selectedRows.map(unit => {
                if (-1 === this.selectedOrdersIds.indexOf(unit.dataItem.order_id)) {
                    this.selectedOrdersIds.push(unit.dataItem.order_id);
                }
            });
        }

        if (event.deselectedRows.length) {
            event.deselectedRows.map(unit => {
                const index = this.selectedOrdersIds.indexOf(unit.dataItem.order_id);
                if (index > -1) {
                    this.selectedOrdersIds.splice(index, 1);
                }
            });
        }
    }

    /**
     * Клик по кнопке массового подтверждения
     */
    onMassConfirm() {
        if (!this.selectedOrdersIds.length || !this.selectedClient || !this.selectedMassSubstatusId) {
            return;
        }

        this.confirmRefusedOrders(this.selectedOrdersIds, this.selectedMassSubstatusId);
    }

    /**
     * Обработка выбора причины в окне массового подтверждения
     * @param event
     */
    onMassReasonSelectChange(event) {
        this.selectedMassSubstatusId = event.target.value;
    }

    /**
     * Сброс состояния массового подтверждения
     * @private
     */
    private resetMassConfirmation() {
        this.selectedOrdersIds = [];
        this.selectedMassSubstatusId = 0;
    }

    /**
     * Запуск подтверждения отказов
     * @param order_ids
     * @param substatus_id
     * @private
     */
    private confirmRefusedOrders(order_ids: Array<number>, substatus_id: number) {
        this.loadingConfirm = true;
        const data = {
            order_ids,
            substatus_id,
        };

        this.api.confirmRefusedOrders(data).subscribe(data => {
            this.resetMassConfirmation();

            if (data.message) {
                this.dialog.alert(data.message, 500);
            } else {
                if (data.success.length && !(data.warning.length || data.not_found.length)) {
                    this.notifier.openNotifier(this.helpers.getPluralEnding(data.success.length, [
                        data.success.length + ' отказ подтверждён',
                        data.success.length + ' отказа подтверждено',
                        data.success.length + ' отказов подтверждено',
                    ]), null, {class: 'success'});
                } else {
                    let dialogMessage = '';

                    if (data.warning.length) {
                        dialogMessage += '<h6>Предупреждения (' + data.warning.length + ')</h6>';

                        dialogMessage += '<ul>';
                        data.warning.map(message => {
                            dialogMessage += '<li>' + message + '</li>';
                        });
                        dialogMessage += '</ul>';
                    }

                    if (data.not_found.length) {
                        dialogMessage += '<h6>Не найдено (' + data.not_found.length + ')</h6>';

                        dialogMessage += '<ul>';
                        data.not_found.map(message => {
                            dialogMessage += '<li>' + message + '</li>';
                        });
                        dialogMessage += '</ul>';
                    }

                    if (data.success.length) {
                        dialogMessage += '<h6>Подтверждено (' + data.success.length + ')</h6>';

                        dialogMessage += '<ul>';
                        data.success.map(message => {
                            dialogMessage += '<li>' + message + '</li>';
                        });
                        dialogMessage += '</ul>';
                    }

                    this.dialog.alert('<div class="text-left">' + dialogMessage + '</div>', 500, 400, true);
                }
            }

            this.loadingConfirm = false;

            this.loadOrdersPage();
        }, () => {
            this.loadingConfirm = false;
        });
    }
}

// TODO технический долг Иванова: сделано на костылях а хотелось на группах форм
// TODO технический долг Иванова: ну или хотя бы привязаться к моделям
// TODO технический долг Иванова: я тут был, но техдолгом не стал заниматься - пусть капают техпроценты...
