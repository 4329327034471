import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';

interface CourierType {
    name: string,
    value: number
}

@Component({
    selector: 'app-courier-type-select',
    templateUrl: './courier-type-select.component.html',
    styleUrls: ['./courier-type-select.component.scss']
})
export class CourierTypeSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 3;
    @Input() placeHolder = 'Тип курьера';
    @Output() onChange = new EventEmitter<number | number[]>();
    dropdownList: CourierType[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    constructor(
        private api: LaraService,
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }

        this.api.getCourierTypes().subscribe(data => {
            this.dropdownList = data;
        })
        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
           return data.indexOf(x.value) !== -1
        })
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
