import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {NotifierService} from '../../service/notifier.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';
import {SkladService} from '../../service/sklad.service';


@Component({
    selector: 'app-control-courier-mact',
    templateUrl: './control-courier-mact.component.html',
    styleUrls: ['./control-courier-mact.component.scss'],
})
export class ControlCourierMactComponent implements OnInit {
    public currentHubs = [];
    public formFilter: FormGroup;
    public formScanFilter: FormGroup;
    public isLoading = false;
    public isScanLoading = false;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    @ViewChild('scan') public scanInput: ElementRef;

    public cnt_new = 0;
    public cnt_archived = 0;
    public gridData = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'name',
        selectAllText: 'Выбрать всё',
        unSelectAllText: 'Убрать всё',
        searchPlaceholderText: 'Поиск',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        protected app: AppComponent,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService,
    ) {
        this.formFilter = new FormGroup(
            {
                date_start: new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required),
                date_end: new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required),
                courier_ids: new FormControl([]),
                status_ids: new FormControl([]),
                hub_ids: new FormControl([]),
            },
        );

        this.formScanFilter = new FormGroup(
            {
                number: new FormControl(null),
                message: new FormControl(null),
                message_class: new FormControl('alert-success'),
            }
        )
    }

    ngOnInit() {
        this.onChangeHub();
        this.setFocusOnScanInput();
    }

    private onChangeHub() {
        this.currentHubs = [this.currentUserService.getCurrentHub().id, ];
        this.formFilter.controls.hub_ids.setValue(this.currentHubs);
        this.appUserSelectEventsSubjectEmit(this.currentHubs);
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.currentHubs = [hub.id, ];
            this.formFilter.controls.hub_ids.setValue(this.currentHubs);
            this.appUserSelectEventsSubjectEmit(this.currentHubs);
        });
    }

    appUserSelectEventsSubjectEmit(hubs) {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: hubs});
    }

    /**
     * Событие при смене клиента
     * @param event
     */
    onChangeClientSelect(event) {
        this.formFilter.controls.client_id.setValue(event ? event.id : null);
    }

    /**
     * Событие при смене даты
     * @param controlName
     */
    onDateChange(controlName) {
        this.formFilter.controls[controlName].setValue(this.datePipe.transform(this.formFilter.controls[controlName].value, 'yyyy-MM-dd'))
    }

    /**
     * Событие при отправке формы
     */
    onFormSubmit() {
        if (!this.formFilter.value.date_start || !this.formFilter.value.date_end) {
            return this.notifier.openNotifier('Укажите дату!', null, {class: 'danger', duration: 3000});
        }

        this.isLoading = true;

        this.api.getMactsForControl(this.formFilter.value).subscribe(result => {
            this.isLoading = false;
            this.gridData = result;
            this.cnt_new = this.gridData.filter(x => x.is_archived === false).length;
            this.cnt_archived = this.gridData.filter(x => x.is_archived === true).length;
        }, error => {
            this.isLoading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Событие при смене курьера
     * @param event
     */
    onChangeCourierSelect(event) {
        this.formFilter.controls.courier_ids.setValue(event.length > 0 ? event.map(x => x.id) : []);
    }

    /**
     * Событие при смене статуса акта
     * @param event
     */
    onChangeMactStatusSelect(event) {
        this.formFilter.controls.status_ids.setValue(event);
    }

    OnScanFormSubmit() {
        this.isScanLoading = true;
        this.api.scanMact(this.formScanFilter.value).subscribe((result) => {
            this.setScanMessage(result.message,  result.message_class);
            this.clearScanInput();
            this.isScanLoading = false;
            this.onFormSubmit();
        }, error => {
            this.isScanLoading = false;
            this.clearScanInput();
        });
    }

    private setScanMessage(message, message_class, timerClear = true) {
        this.formScanFilter.controls.message_class.setValue(message_class);
        this.formScanFilter.controls.message.setValue(message);
        if (timerClear) {
            setTimeout(() => {
                this.setScanMessage('', 'alert-success', false);
            }, 4000)
        }
    }
    private clearScanInput() {
        this.formScanFilter.controls.number.setValue(null);
    }

    public setFocusOnScanInput() {
        setTimeout(() => {
            this.scanInput.nativeElement.focus();
        }, 0);
    }
}
