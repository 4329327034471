import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../app.component';
import {LaraService} from '../../../service/lara.service';
import {MemberService} from '../../../service/member.service';
import {State} from '@progress/kendo-data-query';
import {PageChangeEvent} from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-role-dialog',
  templateUrl: './role-dialog.component.html',
  styleUrls: ['./role-dialog.component.scss']
})
export class RoleDialogComponent implements OnInit {
    public width;
    public top;
    public role;
    public headerFilter: any = [];
    public opened;
    public state: State = {
        take: 10,
        skip: 0,

        filter: {
            logic: 'and',
            filters: []
        }
    };
    public users;
    public gridData;
    public editPermissionsButton = false;
    public groups = [];
    public tab = 1;
    public loading = false;
    public loading_perm = false;
    public detect_change = false;
    constructor(
        protected app: AppComponent,
        private api: LaraService,
        private apim: MemberService
    ) {
        this.width = window.innerWidth * 0.5;
        this.top = window.innerHeight * 0.1;
    }

    ngOnInit() {
        this.getPermissions();
        this.loadUsers()
    }
    onClose() {
        this.app.closeDialog(this);
    }
    tabs(index) {
        this.tab = index;
    }
    deleteRole(user) {
        this.loading = true;
        const filtered: any = {};
        filtered.roles = user.roles.filter(x => {
            return x.id !== this.role.id;
        })
        filtered.roles = filtered.roles.map(x => x.id);
        this.apim.updateUser(user.id, filtered).subscribe(data => {
            this.loading = false;
            this.loadUsers();
        })
    }
    getPermissions() {
        this.api.getMembersPermissionsGroups().subscribe(data => {
            this.groups = data;
            if (this.groups[this.groups.length - 1].permissions.length === 0) {
                this.groups.splice(this.groups.length - 1, 1);
            }
            this.resetPermissions();
        })
    }
    setPermission(e, id, index_group) {
        for (const permission of this.groups[index_group].permissions) {
            if (permission.id === id) {
                permission.checked = e.target.checked
            }
        }
        this.detect_change = true;
    }
    editPermissions() {
        if (this.editPermissionsButton) {
            this.loading_perm = true;
            let permissions = [];
            for (const group of this.groups) {
                for (const permission of group.permissions) {
                    if (permission.checked) {
                        permissions.push(permission);
                    }
                }
            }
            permissions = permissions.map(x => x.id);
            this.api.setRolePermissions(this.role.id, {permissions: permissions}).subscribe(data => {
                this.role = data;
                this.opened = '';
                this.resetPermissions();
                this.loading_perm = false;
                this.editPermissionsButton = !this.editPermissionsButton;
            })
        } else {
            this.editPermissionsButton = !this.editPermissionsButton;
        }
    }
    loadUsers() {
        const filter: any = {};
        const headerFilter = this.headerFilter;
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.head = headerFilter;
        filter.role = this.role.id;
        this.apim.usersListPanel(filter).subscribe(data => {
            this.gridData = data;
        });
    }
    setFilter (e) {
        this.headerFilter = e.filters;
        this.loadUsers();
        this.state.skip = 0;
    }
    public pageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.loadUsers();
    }
    resetPermissions() {
        for (const group of this.groups) {
            for ( const permission of group.permissions) {
                permission.checked = false;
            }
        }
        for (const role_permission of this.role.permissions) {
            for (const group of this.groups) {
                for (const permission of group.permissions) {
                    if (permission.id === role_permission.id) {
                        permission.checked = true;
                    }
                }
            }
        }
        this.detect_change = false;
    }
    checkRights(group, setting) {
        const length = group.permissions.length;
        let i = 0;
        for (const permission of group.permissions) {
            if (permission.checked) {
                i++;
            }
        }
        if (setting === 'all') {
            if (length === i) {
                return true;
            }
        }
        if (setting === 'drop') {
            if (i === 0) {
                return true;
            }
        }
        if (setting === 'count') {
            return i + ' из ' + length;
        }
    }
    changeAllRights(group, setting) {
        for (const permission of group.permissions) {
            permission.checked = setting;
        }
        this.detect_change = true;
    }
}
