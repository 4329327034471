import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {LaraService} from './lara.service';

@Injectable({
    providedIn: 'root',
})
export class SubstatusesService {
    private SUBSTATUSES_STORAGE_KEY = 'substatuses';

    constructor(
        private api: LaraService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) {
    }

    public async get(force = false, type_ids = []) {
        if (force) {
            return await this.getSubstatuses(type_ids);
        }
        return this.fetchSubstatusesFromLocalStorage(type_ids);
    }

    protected fetchSubstatusesFromLocalStorage(type_ids) {
        const storedSubstatuses = this.storage.get(this.SUBSTATUSES_STORAGE_KEY);
        if (!storedSubstatuses) {
            return this.get(true, type_ids);
        }
        return this.onEmitSubstatuses(storedSubstatuses, type_ids);
    }


    protected getSubstatuses(type_ids) {
        return this.api.getAllSubstatuses().toPromise().then((data) => {
            this.storage.set(this.SUBSTATUSES_STORAGE_KEY, data)
            return this.onEmitSubstatuses(data, type_ids)
        })
    }

    private onEmitSubstatuses(substatuses, type_ids) {
        return type_ids.length > 0 ? this.filterSubstatuses(substatuses, type_ids) : substatuses;
    }

    private filterSubstatuses(substatuses: any, type_ids: any[]) {
        return substatuses.filter(
            x => {
                return type_ids.indexOf(x.type) !== -1
            },
        )
    }
}
