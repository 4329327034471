import {LaraService} from "./lara.service";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class MemberService extends LaraService {

    /**
     * список пользователей системы
     */
    public usersList(isActive = false) {
        return this.post('/members/list', (isActive ? '/active' : ''));
    }

    /**
     * полный список пользователей
     * @param isActive - активные или не активные
     * @param roleIds - ид роли
     * @param sortSpecialCouriersFirst - требуется ли сортировка по спец курьерам
     */
    public usersAll(isActive = false, roleIds, sortSpecialCouriersFirst = false) {
        return this.get(
            '/members/all' + (isActive ? '/active' : '') +
            (roleIds ? '/' + roleIds : '') +
            (sortSpecialCouriersFirst ? ('?sortSpecialCouriersFirst') : '')
        );
    }

    /**
     * список пользователей системы
     */
    public usersListPanel(data) {
        console.log('this ');
        console.log(data);
        return this.post('/members/list', data);
    }

    /**
     * список ролей
     */
    public rolesList() {
        return this.get('/members/roles');
    }

    /**
     * список групп
     */
    public groupsList() {
        return this.get('/members/groups');
    }

    /**
     * инфо по группе
     */
    public getGroupInfo(id) {
        return this.get('/members/groups/' + id);
    }

    /**
     * создание группы
     * @param data
     */
    public createUserGroup(data) {
        return this.post('/members/groups/new', data);
    }

    /**
     * изменение группы
     * @param id
     * @param data
     */
    public updateUserGroup(id, data) {
        return this.post('/members/groups/' + id, data);
    }

    /**
     * удаление группы
     * @param id
     */
    public removeUserGroup(id) {
        return this.delete('/members/groups/' + id);
    }

    /**
     * создание пользователя
     * @param data
     */
    public createUser(data) {
        return this.post('/members/user/new', data);
    }

    /**
     * обновление пользователя
     * @param id
     * @param data
     */
    public updateUser(id, data) {
        return this.post('/members/user/' + id, data);
    }
}
