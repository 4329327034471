import {Component, OnInit} from '@angular/core';
import {CurrentUserService} from "../../service/current-user.service";
import {Router} from "@angular/router";
import {User} from "../../service/models";

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    view: (user: User) => boolean;
    items?: RouteInfo[];
}

const MENU: RouteInfo[] = [
    {
        title: 'Клиенты',
        path: '/clients/index',
        view: (user: User) => !user.isRoleCustomer() && !user.isRoleCourier(),
        items: [
            {
                path: '/clients/index',
                title: 'Клиенты',
                icon: 'group',
                view: (user: User) => true,
            },
            {
                path: '/clients/matrix',
                title: 'Тарифная матрица',
                icon: 'view_module',
                view: (user: User) => user.isRoleDirector() || user.isRoleFinance() || user.isRoleManager() || user.isRoleService(),
            },
            {
                path: '/clients/matrixregion',
                title: 'Региональная матрица',
                icon: 'view_module',
                view: (user: User) => user.isRoleDirector() || user.isRoleFinance() || user.isRoleManager() || user.isRoleService(),
            },
            {
                path: '/clients/kgt',
                title: 'КГТ матрица',
                icon: 'view_module',
                view: (user: User) => user.isRoleDirector() || user.isRoleFinance() || user.isRoleManager() || user.isRoleService(),
            },
            {
                path: '/clients/take-away',
                title: 'Матрица заборов',
                icon: 'view_module',
                view: (user: User) => user.isRoleDirector() || user.isRoleFinance() || user.isRoleManager() || user.isRoleService(),
            },
            {
                path: '/clients/calculator',
                title: 'Калькулятор',
                icon: 'local_atm',
                view: (user: User) => true,
            },
            {
                path: '/clients/ta-calculator',
                title: 'Калькулятор Заборов',
                icon: 'local_atm',
                view: (user: User) => true,
            }
        ]
    },
    {
        title: 'Пользователи',
        path: '/users/index',
        view: (user: User) => user.isRoleDirector(),
        items: [
            {
                path: '/users/index',
                title: 'Пользователи',
                icon: 'supervisor_account',
                view: (user: User) => true,
            },
            {
                path: '/users/groups/index',
                title: 'Группы',
                icon: 'supervisor_account',
                view: (user: User) => true,
            }
        ]
    }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    private currentMenu;
    menu: any[] = [];
    public user: User;

    constructor(
        private currentUser: CurrentUserService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.currentUser.get().subscribe((user: User) => {
            this.user = new User(user);
            if (!user) {
                this.menu = [];
            } else if (user.isRoleAdmin()) {
                this.menu = MENU;
            } else {
                const filter = item => item.view(user);
                this.menu = MENU.filter(item => {
                    if (item.items) {
                        item.items = item.items.filter(filter);
                    }
                    return filter(item);
                });
            }
        });
    }

    isActiveRoot(item) {
        if (this.router.url == item.path) {
            this.currentMenu = item;
            return true;
        }
        let find = false;
        item.items.forEach((link) => {
            if (this.router.url == link.path) {
                find = true;
            }
        });
        return find;
    }
}
