import {BaseModel, PpAct} from '../../service/models';

export class InventivActsModel extends BaseModel {
    /** @var int Статус Новый */
    public static readonly STATUS_NEW = 1;

    /** @var int Статус Обработан */
    public static readonly STATUS_PROCESSED = 2;

    public act_date: string;
    public status: number;
    public status_name: string;

    public excess_json: string;
    public excess_count: number;
    public excess_array;

    public received_count: number;

    public files: InventivActFileModel[];

    public pp_acts: PpAct[];
}

export class InventivActFileModel extends BaseModel {
    public original_path: string;
    public original_filename: string;
    public original_url: string;
    public processed_path: string;
    public processed_url: string;

    public orders_json: string;
    public orders_count: number;
    public orders_array;

    public received_orders_json: string;
    public received_orders_count: number;
    public received_orders_array;
}
