import {Component, Input, OnInit} from '@angular/core';
import {StatisticsService} from '../../statistics.service';
import {AppComponent} from '../../../app.component';
import {HubsService} from '../../../service/hubs.service';

@Component({
  selector: 'app-courier-salary-list',
  templateUrl: './courier-salary-list.component.html',
  styleUrls: ['./courier-salary-list.component.scss']
})
export class CourierSalaryListComponent implements OnInit {

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Информация о курьерах';

    public loading: boolean = false;
    public couriers;

    constructor(
        private api: StatisticsService,
        protected app: AppComponent,
        public hubsService: HubsService
    ) {
        if (window.pageYOffset > 0) {
            this.windowTop = window.pageYOffset + 50;
        }

        if (window.innerWidth > 600) {
            this.width = 600;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.loadOrders();
    }

    loadOrders() {
        this.couriers = null;
        this.loading = true;
        this.api.getCourierSalaryStatCourierList({})
            .subscribe(data => {
                this.couriers = data

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
