import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {parseNumber} from '@progress/kendo-angular-intl';
import {PpAct} from '../../service/models';
import {PpActClientImportComponent} from '../pp-act-client-import/pp-act-client-import.component';
import {AppComponent} from '../../app.component';
import {FileDownloadService} from '../../http/file-download.service';
import {CurrentUserService} from '../../service/current-user.service';
import {Subject} from 'rxjs';
import {EventerService} from '../../service/eventer.service';

interface PPInterface {
    name: string,
    value: number,
    checked: boolean
}

interface DataInterface {
    name: string,
    value: number
}

@Component({
    selector: 'app-pp-acts',
    templateUrl: './pp-acts.components.html',
    styleUrls: ['./pp-acts.components.scss'],
})
export class PpActsComponents implements OnInit {
    public formFilter: FormGroup;
    public isLoading = false;
    public selectedAllCheckBox = false;
    public gridData: PpAct[] = [];
    public selectedStatuses = [];
    public ppTypes: PPInterface[] = [
        {
            name: 'приёма-передачи',
            value: PpAct.TYPE_MAIN,
            checked: true,
        },
        {
            name: 'излишков',
            value: PpAct.TYPE_EXCESS,
            checked: false,
        },
        {
            name: 'недостачи',
            value: PpAct.TYPE_DEFICIT,
            checked: false,
        },
    ];
    dropdownDateTypeList: DataInterface[] = [
        {
            value: 1,
            name: 'Дата',
        },
        {
            value: 2,
            name: 'Дата закрытия',
        },
    ];
    selectedDateType = [this.dropdownDateTypeList[0]];

    dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'name',
        selectAllText: 'Выбрать всё',
        unSelectAllText: 'Убрать всё',
        searchPlaceholderText: 'Поиск',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    public selectedActIds: number[] = [];
    public currentHubs = [];

    private today;
    private yesterday;

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        protected app: AppComponent,
        private fileDownloadService: FileDownloadService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
    ) {
        this.today = new Date();
        this.yesterday = new Date();
        this.yesterday.setDate(this.today.getDate() - 1);

        this.formFilter = new FormGroup(
            {
                date_type: new FormControl(null),
                date_start: new FormControl(this.yesterday),
                date_end: new FormControl(this.today),
                type_ids: new FormControl([]),
                status_ids: new FormControl([]),
                client_ids: new FormControl([]),
                search_act_number: new FormControl(null),
                hub_ids: new FormControl([]),
                is_all_and_new: new FormControl(),
            },
        );
        this.updateTypeInFilter();
    }

    ngOnInit() {
        this.onChangeHub();
    }


    /**
     * Событие при выборе всех чекбоксов
     */
    onChangeAllCheckbox() {
        this.ppTypes.forEach(t => t.checked = this.selectedAllCheckBox);
        this.updateTypeInFilter();
    }


    /**
     * Событие при выборе 1 чекбокса
     */
    onChangeCheckedCheckBox() {
        setTimeout(() => {
            this.selectedAllCheckBox = this.ppTypes.every(t => t.checked);
        }, 0)
        this.updateTypeInFilter();
    }

    onChangeAllNew(event) {

        this.selectedAllCheckBox = event.target.checked
        this.onChangeAllCheckbox()
        if (event.target.checked) {
            this.formFilter.patchValue({
                status_ids: [1],
                date_start: '',
                date_end: '',
            })

        } else {
            this.formFilter.patchValue({
                status_ids: [],
                date_start: this.yesterday,
                date_end: this.today,
            })
        }
    }

    /**
     * Получение заголовка
     */
    getTitleText() {
        return this.ppTypes.filter(t => {
            return t.checked
        }).map(value => value.name).join(', ');
    }

    getActTypeName(actType) {
        const itemS = this.ppTypes.find(item => item.value === actType);
        if (!itemS) {
            return 'неизвестный тип';
        }
        return itemS.name;
    }

    /**
     * Событие при смене клиента
     * @param event
     */
    onChangeClientSelect(event) {
        this.formFilter.controls.client_ids.setValue(event.length > 0 ? event.map(x => x.id) : []);
    }


    /**
     * Событие при отправке формы
     */
    onFormSubmit() {
        if (!this.formFilter.value.search_act_number) {
            if (this.formFilter.value.type_ids.length < 1) {
                return this.notifier.openNotifier('Выберите тип акта!', null, {class: 'danger', duration: 3000});
            }

            if ((!this.formFilter.value.date_start || !this.formFilter.value.date_end) && !this.formFilter.value.is_all_and_new) {
                return this.notifier.openNotifier('Укажите дату!', null, {class: 'danger', duration: 3000});
            }
            if (!this.formFilter.value.date_type) {
                return this.notifier.openNotifier('Укажите поле поиска дата / дата закрытия!', null, {
                    class: 'danger',
                    duration: 3000,
                });
            }
        }

        const query = {...this.formFilter.value};
        query.date_start = this.datePipe.transform(query.date_start, 'yyyy-MM-dd')
        query.date_end = this.datePipe.transform(query.date_end, 'yyyy-MM-dd')

        this.resetCheckedTable()
        this.isLoading = true;
        this.api.getPpActs(query).subscribe(result => {
            this.isLoading = false;
            this.gridData = result;
        }, error => {
            this.isLoading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Выбор типа даты
     * @param event
     */
    onChangeDateType(event) {
        this.formFilter.controls.date_type.setValue(event.length > 0 ? event[0].value : null);
    }


    /**
     * Событие при выборе всех чекбоксов таблицы
     * @param event
     */
    onChangeAllCheckboxTable(event) {
        const checked = event.target.checked;
        this.selectedActIds = checked ? this.gridData.filter(function (x) {
            return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(x.type) !== -1) && x.status !== PpAct.STATUS_CLOSED
        }).map(x => {
            return x['id'];
        }) : [];
        this.gridData.filter(function (x) {
            return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(x.type) !== -1) && x.status !== PpAct.STATUS_CLOSED
        }).forEach(value => {
            document.getElementById('check-box-' + value['id'])['checked'] = checked;
        })
    }

    /**
     * Событие при смене состояния чекбокса таблицы
     * @param event
     */
    onChangeCheckboxTable(event) {
        const value = parseNumber(event.target.value);
        const index = this.selectedActIds.indexOf(value);
        if (index > -1) {
            this.selectedActIds.splice(index, 1);
        } else {
            this.selectedActIds.push(value);
        }
        document.getElementById('check-box-all-table')['checked'] = this.selectedActIds.length === this.gridData.length;
    }

    /**
     * Сделать акт закрытым
     */
    makeActClosed() {
        this.notifier.openNotifier('Изменения обрабатываются...');
        this.api.makePPActsClosed({
            act_ids: this.selectedActIds,
        }).subscribe(value => {
            this.notifier.openNotifier(value.message, null, {class: 'success', duration: 3000});
            if (value.errors) {
                alert(value.errors);
            }
            this.onFormSubmit();
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }

    checkVisibleCheckBox(dataItem: PpAct) {
        return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(dataItem.type) !== -1) && dataItem.status !== PpAct.STATUS_CLOSED
    }

    public onClientActLoadClick() {
        this.app.createDialog(PpActClientImportComponent, {}).onDestroy(data => {

        })
    }

    /**
     * Обновление в фильтре иформации о выбранных типах(е)
     * @private
     */
    private updateTypeInFilter() {
        setTimeout(() => {
            this.formFilter.controls.type_ids.setValue(this.ppTypes.filter(t => {
                return t.checked
            }).map(x => x.value));
        }, 0)
    }

    /**
     * Сброс состояния чекбоксов таблицы
     * @private
     */
    private resetCheckedTable() {
        this.selectedActIds = [];
        document.getElementById('check-box-all-table')['checked'] = false;
    }


    disableSearch() {
        return (this.formFilter.value.search_act_number && this.formFilter.value.search_act_number.length < 4);
    }

    getActSourceName(source_name: string) {
        return source_name ? source_name : 'неизвестно';
    }

    private onChangeHub() {
        this.currentHubs = [this.currentUserService.getCurrentHub().id];
        this.formFilter.controls.hub_ids.setValue(this.currentHubs);
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.currentHubs = [hub.id];
            this.formFilter.controls.hub_ids.setValue(this.currentHubs);
        });
    }

}
