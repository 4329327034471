// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    lara_api_url: 'https://api.dev.e-bulky.ru/api/v1',
    dadata_api_key: '3ad30a9739966ebbc1ea59dfdddcfabc97803c89',
    yandex_api_key: '0e111c3b-8586-4865-8a40-d2d414d76a35',
    not_founded_courier_id: -1, // ID курьера Розыск в старой системе в users
    not_founded_courier_id_crm: -1, // ID курьера Розыск на бою в users
    transfer_courier_id: -1, // ID курьера Под перенос на бою системе в users
    transfer2_courier_id: -1, // ID курьера Под перенос2 на бою системе в users
    problem_courier_id: -1, // ID курьера Проблемный в старой системе в в users
    truck_courier_id: -1, // ID курьера Пятитонник на бою

    moscow_logsis_warehouse_id: -1, // ID склада Логсис в МСК
    topdelivery_warehouse_id: -1, // ID склада TopDelivery в МСК

    detmir_client_id: -1, // ID клиента для Детского мира
    yandex_client_id: -1, // ID клиента для Яндекса в новой системе
    cab_apikey: 'secretapikeyforlogsis',

    tiny_mce_key : 'c7ah5rs26s2oib75jajyyidycv3rji8x1nbjo0oki2czqgre',
};
