import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SkladService} from '../../../service/sklad.service';
import {AppComponent} from '../../../app.component';
import {HelpersService} from "../../../service/helpers.service";
import {ReturnActService} from '../../../service/return-act.service';
import {ClientInfo} from '../../../service/models';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-print-return-act',
    templateUrl: './print-return-act.component.html',
    styleUrls: ['./print-return-act.component.scss'],
})
export class PrintReturnActComponent implements OnInit {

    returnActId: number;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public loading = false;

    public isPrintPlaces = false;

    public actSum = 0;
    public returnAct;


    public placesCount = 0;
    public goodsCount = 0;

    constructor(
        private route: ActivatedRoute,
        private api: ReturnActService,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.route.params.subscribe(params => {
            this.returnActId = params['return_act_id'];
            if (params['print_places']) {
                this.isPrintPlaces = params['print_places'];
            }

            /** кастомная печать инвентив */
            if (params['client_id'] && ClientInfo.INVENTIV_IDS.indexOf(parseInt(params['client_id'])) != -1) {
                document.location.href = (
                    environment.lara_api_url.substr(0, environment.lara_api_url.indexOf('/api/v1')) + '/printer/customReturnActs/' +
                    this.returnActId + '/' +
                    params['client_id'] +'?token=' +
                    this.api.getToken()
                );
            }
        });


        this.api.getActWithGoodsAndPlaces(this.returnActId).subscribe((data) => {


            this.returnAct = data;
            let total = 0;
            for (const order of data.orders) {
                for (const good of order.returned_goods) {
                    total += (good.count * good.price);
                    this.goodsCount += good.count
                }

                this.placesCount += (order.returned_places ? order.returned_places.length : 0)
            }

            this.actSum = total;

            this.loading = false;

        });


    }



    decimal(number) {
        return parseFloat(number).toFixed(2);
    }

    printPage() {
        window.print();
    }
}
