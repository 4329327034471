import {Component, OnInit} from '@angular/core';
import {ClientInfo, MatrixTakeAway, User} from '../../service/models';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {MatrixService} from '../../service/matrix.service';
import {Title} from '@angular/platform-browser';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';
import {CurrentUserService} from '../../service/current-user.service';

@Component({
    selector: 'app-take-away',
    templateUrl: './take-away.component.html',
    styleUrls: ['./take-away.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class TakeAwayComponent implements OnInit {

    public selectedClient: ClientInfo;

    public pageTitle = 'Тарифная матрица заборов';

    public formMatrixList: FormArray = new FormArray([]);

    public defaultMatrix: MatrixTakeAway[];

    public personalMatrix = false;

    public createPersonalMatrix = false;

    protected user: User;

    constructor(
        private api: MatrixService,
        private title: Title,
        private route: ActivatedRoute,
        private userService: CurrentUserService
    ) {
    }

    ngOnInit() {
        this.userService.get().subscribe((user: User) => {
            this.user = user;
        });
        this.route.paramMap.subscribe((params: ParamMap) => {
            const clientId = params.get('id');
            if (clientId) {
                this.api.getClientInfo(clientId).subscribe((client: ApiClientInfo) => {
                    this.onChangeClient(client.client);
                });
            } else {
                this.loadMatrix();
            }
        });
    }

    public isEditDefaultMatrix() {
        if (this.user.isRoleAdmin()) {
            return true;
        }
        if (this.selectedClient) {
            return true;
        }
        return false;
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        if (client) {
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.title.setTitle(this.pageTitle);
        }
        this.loadMatrix();
    }

    public onAddDist() {
        this.formMatrixList.push(new FormArray([
            new FormControl('', Validators.required),
            new FormControl('', Validators.required)
        ]));
    }

    public onDeleteDist(index) {
        this.formMatrixList.removeAt(index);
    }

    public onSubmitForm() {
        this.api.updateTakeAway({
            'matrix': this.formMatrixList.value
        }, this.selectedClient ? this.selectedClient.id : null).subscribe(() => {
            this.loadMatrix();
        });
    }

    protected loadMatrix() {
        this.personalMatrix = false;
        this.defaultMatrix = null;
        this.formMatrixList = new FormArray([]);
        this.api.getTakeAway(this.selectedClient ? this.selectedClient.id : null).subscribe((data: MatrixTakeAway[]) => {
            for (let item of data) {
                this.formMatrixList.push(new FormArray([
                    new FormControl(item.dist_to, Validators.required),
                    new FormControl(item.price, Validators.required),
                ]));
            }
            if (this.selectedClient) {
                this.personalMatrix = data.length > 0;
                this.createPersonalMatrix = !this.personalMatrix;
            }
        });
        this.api.getTakeAway().subscribe((defaultData: MatrixTakeAway[]) => {
            this.defaultMatrix = defaultData;
        });
    }

}
