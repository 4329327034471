import {Component, ComponentRef, EventEmitter, Input, Output} from '@angular/core';
import {AppComponent} from '../../app.component';
import {
    SpreadBankPaymentOrderDialogComponent,
} from './spread-bank-payment-order-dialog/spread-bank-payment-order-dialog.component';

@Component({
    selector: 'app-spread-bank-payment-order',
    templateUrl: './spread-bank-payment-order.component.html',
    styleUrls: ['./spread-bank-payment-order.component.scss'],
})
export class SpreadBankPaymentOrderComponent {
    @Input('bankPaymentOrderId') public bankPaymentOrderId: number | null = null;

    @Output() dialogOpenedChange = new EventEmitter<any>();
    @Output() onDestroy = new EventEmitter<boolean>();
    public loading = false;
    private dialogRef: ComponentRef<any>;

    constructor(
        private app: AppComponent,
    ) {
    }

    public onOpen() {
        this.dialogRef = this.app.createDialog(SpreadBankPaymentOrderDialogComponent, {
            bankPaymentOrderId: this.bankPaymentOrderId,
            parent: this,
        });

        this.dialogOpenedChange.emit(true);

        this.dialogRef.onDestroy(() => {
            this.dialogOpenedChange.emit(false);
            this.onDestroy.emit(true);
        });
    }

    public onClose() {
        this.dialogRef.destroy();
    }
}
