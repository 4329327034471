import {Component, OnInit} from '@angular/core';
import {Observable, Observer} from 'rxjs';

import {DeliveryCouriersComponent} from './../delivery-couriers/delivery-couriers.component';
import {ReceptionCouriersComponent} from './../reception-couriers/reception-couriers.component';

export interface TransferTab {
    label: string;
    content: any;
}

@Component({
    selector: 'app-transfer-tab',
    templateUrl: './transfer-tab.component.html',
    styleUrls: ['./transfer-tab.component.scss']
})
export class TransferTabComponent implements OnInit {

    asyncTabs: Observable<TransferTab[]>;

    selectedIndex = 0;

    constructor() {
        this.asyncTabs = new Observable((observer: Observer<TransferTab[]>) => {
            setTimeout(() => {
                observer.next([
                    {label: 'Прием курьеров', content: ReceptionCouriersComponent},
                    {label: 'Выдача курьерам', content: DeliveryCouriersComponent},
                ]);
            }, 1000);
        });
    }

    ngOnInit() {
    }

    resetTabIndex() {
        this.selectedIndex = 0;
    }

}
