import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';

import {ClientInfo, ZoneGroup} from '../../service/models';
import {MatrixService} from '../../service/matrix.service';
import {HelpersService} from '../../service/helpers.service';

interface IServiceMatrixDefaults {
    is_disabled?: boolean;
    service_price?: boolean;
}

interface IServiceMatrixData {
    is_disabled?: boolean | number;
    service_price_rate?: number;
    service_price_fix?: number;

    name?: string;
    code?: string;

    is_disabled_by_pricing?: boolean;
    is_disabled_by_category?: boolean;
    is_disabled_overall?: boolean;

    is_default?: boolean | number;
    is_empty?: boolean | number;

    x_ui_is_disabled_overall?: boolean;
}

interface IServiceWithMatrix extends IServiceMatrixData {
    service_id: number;
    service_code?: string;
    default_matrix?: IServiceMatrixData;
    defaults?: IServiceMatrixDefaults;

    formGroupIdx?: number;
}

interface IServiceCategoryMatrixDefaults {
    is_disabled?: boolean;
    service_contractor_trip_price_fix?: boolean;
    service_category_min_sum_price_fix?: boolean;
}

interface IServiceCategoryMatrixData {
    is_disabled?: boolean | number;
    service_contractor_trip_price_fix?: number;
    service_category_min_sum_price_fix?: number;
    services: IServiceWithMatrix[];

    name?: string;
    code?: string;

    is_disabled_by_pricing?: boolean;
    is_disabled_overall?: boolean;

    is_default?: boolean | number;
    is_empty?: boolean | number;
    is_available_for_client?: boolean | number;

    x_ui_is_disabled_overall?: boolean;
}

interface IServiceCategoryWithMatrix extends IServiceCategoryMatrixData {
    service_category_id: number;
    service_category_name?: string;
    service_category_code?: string;
    default_matrix?: IServiceCategoryMatrixData;
    defaults?: IServiceCategoryMatrixDefaults;

    formGroupIdx?: number;
}

@Component({
    selector: 'app-matrix-services',
    templateUrl: './matrix-services.component.html',
    styleUrls: ['./matrix-services.component.scss'],
    providers: [
        ColumnResizingService,
    ],
})
export class MatrixServicesComponent implements OnInit {
    public hubId: number;

    public zoneGroupsList: ZoneGroup[];
    public categoryList: string[];
    public servicesByCategories?: IServiceCategoryWithMatrix[] = null;
    public selectedZoneGroupId = null;
    public selectedCategory = 'A';
    public isSelectedCategory = true;
    public selectedClient: ClientInfo;
    public pageTitle = 'Тарифная матрица услуг';
    public isMatrixLoading = false;
    public canEditClient = false;
    public canEditDefault = false;
    public isTipExpanded = false;
    public formGroupFake = new FormArray([]);
    private matrix?: any[] = null;

    constructor(
        private api: MatrixService,
        private helpers: HelpersService,
        private route: ActivatedRoute,
        private title: Title,
    ) {
    }

    ngOnInit() {
        this.canEditClient = this.helpers.checkPermissions('matrix:update-client');
        this.canEditDefault = this.helpers.checkPermissions('matrix:update-default');

        this.route.params.subscribe((params) => {
            this.hubId = +params['id'];

            this.loadZoneGroups();
        })

        /* грузим список категорий */
        this.api.getMatrixZoneGroupsCategoryList().subscribe((data: string[]) => {
            this.categoryList = data;
        });

        this.getServicesByCategories();
    }

    public onChangeZoneGroup(id: number) {
        this.selectedZoneGroupId = id;
        this.loadMatrix();
    }

    public onChangeCategory(categoryCode: string) {
        this.selectedCategory = categoryCode;
        this.loadMatrix();
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;

        if (client) {
            this.selectedCategory = client.matrix_spb;
            this.isSelectedCategory = false;
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name);
        } else {
            this.isSelectedCategory = true;
            this.title.setTitle(this.pageTitle);
        }

        this.updateFormDisabledState();
        this.loadMatrix();
    }

    public onSubmitForm() {
        const matrix = [];
        this.servicesByCategories.map(serviceCategory => {
            const serviceCategoryPayload = {
                ...this.formGroupFake.at(serviceCategory.formGroupIdx).value,
                service_category_id: serviceCategory.service_category_id,
                services: [],
            };

            serviceCategory.services.map(service => {
                const servicePayload = {
                    ...this.formGroupFake.at(service.formGroupIdx).value,
                    service_id: service.service_id,
                }

                serviceCategoryPayload.services.push(servicePayload);
            });

            matrix.push(serviceCategoryPayload);
        });

        const payload = {
            matrix,
            category: this.selectedClient ? null : this.selectedCategory,
        }

        this.isMatrixLoading = true;
        this.api.updateTariffServiceZoneGroupMatrix(
            payload,
            this.selectedCategory,
            this.selectedZoneGroupId,
            this.selectedClient ? this.selectedClient.id : null,
        ).subscribe(() => {
            this.loadMatrix();
            this.isMatrixLoading = false;
        }, () => {
            this.isMatrixLoading = false;
        });
    }

    public canEdit() {
        return (this.selectedClient && this.canEditClient) || (!this.selectedClient && this.canEditDefault);
    }

    onClickReload() {
        this.loadMatrix();
    }

    updateFormDisabledState() {
        if (this.canEdit()) {
            this.formGroupFake.enable();
        } else {
            this.formGroupFake.disable();
        }
    }

    protected loadMatrix() {
        this.isMatrixLoading = true;
        this.api.getTariffServiceZoneGroupMatrix(
            this.selectedZoneGroupId,
            this.selectedCategory,
            this.selectedClient ? this.selectedClient.id : null,
        ).subscribe(matrix => {
            this.matrix = matrix;
            this.mergeMatrixWithServices();
            this.isMatrixLoading = false;
        }, () => {
            this.isMatrixLoading = false;
        });
    }

    private getServicesByCategories() {
        this.servicesByCategories = [];
        while (this.formGroupFake.length) {
            this.formGroupFake.removeAt(0);
        }

        this.api.getServicesByCategories().subscribe((data: any[]) => {
            data.map(serviceCategory => {
                serviceCategory.service_category_id = serviceCategory.id;
                serviceCategory.service_category_name = serviceCategory.name;
                serviceCategory.service_category_code = serviceCategory.code;

                const serviceControls = [];
                serviceCategory.services.map((service, serviceIndex) => {
                    service.service_id = service.id;
                    service.service_name = serviceCategory.name;
                    service.service_code = serviceCategory.code;

                    serviceControls[serviceIndex] = new FormGroup({
                        is_disabled: new FormControl(null),
                        service_price_rate: new FormControl(null),
                        service_price_fix: new FormControl(null),
                    });

                    service.formGroupIdx = this.formGroupFake.length;
                    this.formGroupFake.push(serviceControls[serviceIndex]);
                });

                const serviceCategoryControls = new FormGroup({
                    is_disabled: new FormControl(null),
                    service_contractor_trip_price_fix: new FormControl(null),
                    service_category_min_sum_price_fix: new FormControl(null),
                });

                serviceCategory.formGroupIdx = this.formGroupFake.length;
                this.formGroupFake.push(serviceCategoryControls);
            });

            this.servicesByCategories = data;

            this.mergeMatrixWithServices();
        });
    }

    private mergeMatrixWithServices() {
        if (null === this.servicesByCategories || null === this.matrix) {
            return;
        }

        this.servicesByCategories.map(serviceCategory => {
            const matrixCategory = this.matrix[serviceCategory.service_category_id] || null;
            serviceCategory.defaults = null;

            serviceCategory.is_default = null;
            serviceCategory.is_empty = null;
            serviceCategory.is_disabled = null;
            serviceCategory.is_disabled_by_pricing = null;
            serviceCategory.is_disabled_overall = null;

            if (matrixCategory) {
                serviceCategory.is_disabled = matrixCategory.is_disabled;
                this.formGroupFake.at(serviceCategory.formGroupIdx).markAsPristine();
                this.formGroupFake.at(serviceCategory.formGroupIdx).setValue({
                    is_disabled: matrixCategory.is_disabled,
                    service_contractor_trip_price_fix: matrixCategory.service_contractor_trip_price_fix,
                    service_category_min_sum_price_fix: matrixCategory.service_category_min_sum_price_fix,
                });

                serviceCategory.is_disabled_by_pricing = matrixCategory.is_disabled_by_pricing;
                serviceCategory.is_disabled_overall = matrixCategory.is_disabled_overall;
                serviceCategory.x_ui_is_disabled_overall = serviceCategory.is_disabled_overall;

                serviceCategory.defaults = matrixCategory.defaults;
                serviceCategory.is_default = matrixCategory.is_default;
                serviceCategory.is_empty = matrixCategory.is_empty;
                serviceCategory.default_matrix = matrixCategory.default_matrix || null;

                if (serviceCategory.default_matrix) {
                    if (null === serviceCategory.is_disabled && matrixCategory.default_matrix.is_disabled) {
                        serviceCategory.x_ui_is_disabled_overall = true;
                    }

                    if (null === serviceCategory.is_disabled_by_pricing && matrixCategory.default_matrix.is_disabled_by_pricing) {
                        serviceCategory.x_ui_is_disabled_overall = true;
                    }
                }
            }

            if (this.selectedClient) {
                serviceCategory.is_available_for_client = matrixCategory.is_available_for_client || false;
                if (!serviceCategory.is_available_for_client) {
                    serviceCategory.x_ui_is_disabled_overall = true;
                }
            } else {
                serviceCategory.is_available_for_client = null;
            }

            serviceCategory.services.map(service => {
                service.defaults = null;

                service.is_default = null;
                service.is_empty = null;
                service.is_disabled = null;
                service.is_disabled_by_category = null;
                service.is_disabled_by_pricing = null;
                service.is_disabled_overall = null;

                const matrixService = matrixCategory ? (matrixCategory.services[service.service_id] || null) : null;
                if (matrixService) {
                    service.is_disabled = matrixService.is_disabled;
                    this.formGroupFake.at(service.formGroupIdx).markAsPristine();
                    this.formGroupFake.at(service.formGroupIdx).setValue({
                        is_disabled: matrixService.is_disabled,
                        service_price_rate: matrixService.service_price_rate,
                        service_price_fix: matrixService.service_price_fix,
                    });

                    service.is_disabled_by_pricing = matrixService.is_disabled_by_pricing;
                    service.is_disabled_by_category = matrixService.is_disabled_by_category;
                    service.is_disabled_overall = matrixService.is_disabled_overall;
                    service.x_ui_is_disabled_overall = (service.is_disabled_overall || serviceCategory.x_ui_is_disabled_overall);

                    service.defaults = matrixService.defaults;
                    service.is_default = matrixService.is_default;
                    service.is_empty = matrixService.is_empty;
                    service.default_matrix = matrixService.default_matrix || null;
                    if (service.default_matrix) {
                        if (null === service.is_disabled_by_pricing && matrixService.default_matrix.is_disabled_by_pricing) {
                            service.x_ui_is_disabled_overall = true;
                        }

                        if (null === service.is_disabled_by_category && matrixService.default_matrix.is_disabled_by_category) {
                            service.x_ui_is_disabled_overall = true;
                        }

                        if (null === service.is_disabled && matrixService.default_matrix.is_disabled) {
                            service.x_ui_is_disabled_overall = true;
                        }
                    }
                }
            });
        });
    }

    private loadZoneGroups() {
        /* грузим список групп зон */
        this.api.getMatrixZoneGroupsList(this.hubId).subscribe((data: ZoneGroup[]) => {
            if (data.length) {
                this.zoneGroupsList = data;
                this.selectedZoneGroupId = data[0].id;

                this.loadMatrix();
            }
        });
    }
}
