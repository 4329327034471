import {Component, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {HelpersService} from '../../service/helpers.service';
import {EventerService} from '../../service/eventer.service';
import {NotifierService} from '../../service/notifier.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {User, UserHub} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {PageChangeEvent, RowClassArgs} from '@progress/kendo-angular-grid';
import {FileDownloadService} from '../../http/file-download.service';
import {MemberService} from '../../service/member.service';
import {HubsService} from '../../service/hubs.service';
import {SubstatusesService} from '../../service/substatuses.service';

@Component({
    selector: 'app-problems',
    templateUrl: './problems.component.html',
    styleUrls: ['./problems.component.scss'],
})
export class ProblemsComponent implements OnInit {
    public problemList: any;
    public substatuses: any;
    public substatus: any;
    public problemIndex: any;
    public hubDeliveryIntervals: any;
    public filter: any;
    public order;
    public cancelLoader = false;
    public cancelOrderId = '';
    public loading;
    public excelIsLoading = false;
    public total = 50;
    public last_work_status = null;
    public work_status = 'all';
    public state: State = {
        skip: 0,
        take: 50,
        filter: {
            logic: 'and',
            filters: [],
        },
        sort: [],
    };
    public selectedTypeId = 0;
    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];
    public hubs: any;
    public selectedUser: User = null;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public formFilter: FormGroup;
    public couriersAll = [];
    public reasonsAll = [];
    public couriersCurrentHub = [];
    public predStatusAll = [
        {value: 0, name: 'Не выбран'},
        {value: 1, name: 'Недозвон'},
        {value: 6, name: 'Сдвиг'},
        {value: 2, name: 'Перенос'},
        {value: 3, name: 'Отмена'},
        {value: 4, name: 'Подъём КГТ'},
        {value: 7, name: 'Покупателя нет на месте'},
        {value: 20, name: 'Доставка через подземный паркинг'},
    ]
    selectedPredStatus = [];
    public selectedDeliveryIntervals = [];
    private readonly SORT_DEFAULT: SortDescriptor[] = [
        {field: 'kgt', dir: 'desc'},
        {field: 'created_at', dir: 'asc'},
    ];
    private readonly SORT_ALTERNATE: SortDescriptor[] = [
        {field: 'courier_problems_count', dir: 'desc'},
    ];
    @ViewChild('closeModal') private closeModal;
    private selectedCourierIds = [];
    private selectedSubstatusIds = [];

    constructor(
        private datePipe: DatePipe,
        protected api: LaraService,
        private hubsService: HubsService,
        public helper: HelpersService,
        protected eventer: EventerService,
        public dialog: DialogService,
        private currentUserService: CurrentUserService,
        private fileDownloadService: FileDownloadService,
        private notifier: NotifierService,
        private memberService: MemberService,
        private substatusesService: SubstatusesService,
    ) {
        this.eventer.apiError.subscribe(data => {
            this.setLoadingParameter();
        });

        this.eventer.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        const today = new Date();
        this.formFilter = new FormGroup({
            date_start: new FormControl(today),
            date_end: new FormControl(today),
        });
        this.state.sort = this.SORT_DEFAULT;
    }

    public hideModal() {
        this.closeModal.nativeElement.click();
    }

    setPredstatus(event) {
        if (this.selectedPredStatus.length > 1) {
            this.selectedPredStatus = [];
            this.selectedPredStatus.push(event[event.length - 1]);
        }
        this.selectedTypeId = this.selectedPredStatus.length > 0 ? this.selectedPredStatus.map(status => status.value)[0] : 0;
        this.getProblemList();
    }

    setOrdersFilter(e) {
        this.order = e.target.value;
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            const hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub];
            this.selectedHubId = hub.id;

            this.hubsService.get().subscribe(hubs => {
                this.hubs = hubs;
                this.updateHubDeliveryIntervalsList();

                this.getProblemList();
                this.getOrderCancelSubstatuses();
                this.prepareFilters();
            });
        });
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    getOrderCancelSubstatuses() {
        this.substatusesService.get(false, [5]).then(data => {
            this.substatuses = data;
        });
    }

    setLoadingParameter() {
        for (const problem of this.problemList) {
            problem.loading = 0;
        }
    }

    setLoading(index, status) {

        this.problemList.data[index]['loading'] = status;
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000})
        })
    }

    getFilter() {
        /** Переключили вкладку, то откроем 1 страницу */
        if (this.last_work_status !== this.work_status) {
            this.last_work_status = this.work_status;
            this.state.skip = 0;
        }

        const filter: any = {};
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.type_id = this.selectedTypeId;
        filter.work_status = this.work_status;
        filter.hub_id = this.selectedHubId;
        filter.courier_ids = this.selectedCourierIds;
        filter.substatus_ids = this.selectedSubstatusIds;
        filter.date_start = this.datePipe.transform(this.formFilter.value.date_start, 'yyyy-MM-dd');
        filter.date_end = this.datePipe.transform(this.formFilter.value.date_end, 'yyyy-MM-dd');
        if (this.order) {
            filter.order_id = this.order;
        }
        if (this.selectedDeliveryIntervals.length) {
            filter.delivery_intervals = this.selectedDeliveryIntervals.map(interval => interval.id);
        }
        filter.sort = this.state.sort;
        console.warn(filter);
        return filter;
    }

    getProblemList() {
        this.loading = true;
        this.api.getProblemList(this.getFilter()).subscribe(data => {
            this.total = data.total;
            this.loading = false;
            if (this.state.skip > data.total) {
                const temp = data;
                temp.current_page = 0;
                this.state.skip = 0;
                this.problemList = temp;
                this.getProblemList();
            } else {
                this.problemList = data;
            }
            this.problemList.from = 0;
            this.problemList.last_page = 0;
            this.problemList.data.forEach(function (problem) {
                if (problem.processed_time) {
                    problem.processed_time = problem.processed_time.split(' ', 2)[1];
                }

                problem._courier = null;
                if (problem.courier) {
                    problem._courier = problem.courier;
                } else if (problem.order && problem.order.courier) {
                    problem._courier = problem.order.courier;
                }
            });
        }, error => {
            this.loading = false;
        })
    }


    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {
            this.selectedUser = null;
        }
    }

    getKgtInWork(id, index) {
        this.setLoading(index, 1);
        this.api.getKgtInWork(id).subscribe(data => {
            this.setLoading(index, 0);
            this.problemList.data[index].manager_id = data.user.id;
            this.problemList.data[index].manager = data.user;
        })
    }

    enableUndergroundParking(manager, problem, index) {
        this.setLoading(index, 1);
        this.api.editOrder(problem.order.uid, {option_underground_parking: true}).subscribe(data => {
            this.notifier.openNotifier(`${problem.order.uid} - Доставка через паркинг разрешена`, null, {class: 'success', duration: 3000});
            this.process(manager, problem.id, index);
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
            this.setLoading(index, 0);
        });
    }

    disableUndergroundParking(manager, problem, index) {
        this.setLoading(index, 1);
        this.api.editOrder(problem.order.uid, {option_underground_parking: false}).subscribe(data => {
            this.notifier.openNotifier(`${problem.order.uid} - Запрещена доставка через паркинг`, null, {class: 'success', duration: 3000});
            this.process(manager, problem.id, index);
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
            this.setLoading(index, 0);
        });
    }

    process(manager, problemId, index) {

        index = index - this.state.skip;


        this.setLoading(index, 1);
        if (manager) {     // Решить проблему
            this.api.resolveProblem(problemId).subscribe(data => {
                // this.arrayChanges('delete', index, data);
                this.getProblemList();
                this.setLoading(index, 0);
            })
        } else {            // Взять в работу
            this.api.getProblemInWork(problemId).subscribe(data => {

                this.arrayChanges('inWork', index, data);
                this.setLoading(index, 0);
            })
        }
    }

    cancelOrder(orderId) {
        this.cancelLoader = true;
        if (orderId) {
            this.api.setCancelSubstatus([orderId], this.substatus).subscribe(data => {
                this.cancelLoader = false;

                if (data.message) {
                    this.dialog.alert(data.message, 500);
                }

                this.getProblemList();
                this.hideModal();
            })
        }
    }

    getSubstatus(e) {
        this.substatus = e.target.value;
    }

    arrayChanges(move, index, result) {
        if (move === 'delete') {
            this.problemList.data.splice(index, 1);
        }
        if (move === 'inWork') {
            this.problemList.data[index].manager_id = result.manager_id;
            this.problemList.data[index].manager = result.manager;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.getProblemList();
    }

    public rowCallback(context: RowClassArgs) {
        if (context.dataItem.order.is_important) {
            return 'is_important';
        }
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;
        this.updateHubDeliveryIntervalsList();
        this.appHubChangeEventSubjectEmit();
        this.getProblemList();
        this.prepareFilters();
    }

    appHubChangeEventSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    onExcelClick() {
        const filter = this.getFilter();
        const fileName = [
            'ProblemsList_',
            filter.date_start,
            ' - ',
            filter.date_end,
        ].join('');

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/logistic/problemsExcel', fileName + '.xlsx', 'post', filter)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    selectedCourierChanged(event: any[]) {
        this.selectedCourierIds = event.length > 0 ? event.map(courier => courier.id) : [];
        this.getProblemList();
    }

    setSubstatus(event: any[]) {
        this.selectedSubstatusIds = event.length > 0 ? event.map(substatus => substatus.substatus_id) : [];
        this.getProblemList();
    }

    hubDeliveryIntervalChanged() {
        this.getProblemList();
    }

    onSortChange() {
        if (this.state.sort !== this.SORT_DEFAULT) {
            this.state.sort = this.SORT_DEFAULT;
        } else {
            this.state.sort = this.SORT_ALTERNATE;
        }

        this.getProblemList();
    }

    private updateHubDeliveryIntervalsList() {
        this.hubDeliveryIntervals = [];
        this.selectedDeliveryIntervals = [];

        const currentHub = this.hubs.find(h => h.id === this.selectedHubId);
        if (!currentHub) {
            return;
        }

        this.hubDeliveryIntervals = currentHub.delivery_intervals.map(interval => {
            return {
                id: interval.id,
                label: interval.label_short,
            }
        });

        this.hubDeliveryIntervals.push({
            id: 0,
            label: 'Нестандартный',
        });
    }

    /**
     * Подготовка фильтров
     * @private
     */
    private prepareFilters() {
        if (this.couriersAll.length === 0) {
            this.memberService.usersAll(true, 4, false).subscribe(data => {
                this.couriersAll = data;
                this.setCouriersCurrentHub();
            })

            this.api.getProblemsReasonsList().subscribe(data => {
                this.reasonsAll = data;
            })
        } else {
            this.setCouriersCurrentHub();
        }
    }

    /**
     * Выборка курьеров только текущего хаба
     * @private
     */
    private setCouriersCurrentHub() {
        this.couriersCurrentHub = this.couriersAll.filter(courier => {
            return courier.default_hub_id === this.selectedHubId;
        })
    }
}
