import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {User, UserHub} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {Subject} from "rxjs";
import {CurrentUserService} from "../../service/current-user.service";

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit {
    // TODO: RESEARCH-667 filter users by selected hubs
    // TODO: RESEARCH-667 send hub_id with query to backend
    public header;
    public desc;
    public selectedUsers: User[];
    public templates: any;
    public loader = false;
    public error: string;

    public appHubChangeEventSubject: Subject<any> = new Subject<any>();

    public selectedHubs: UserHub[] = [];

    constructor(
        private currentUserService: CurrentUserService,
        protected api: LaraService
    ) { }

    ngOnInit() {
        let selectedHub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [selectedHub, ];
        this.appUserSelectEventsSubjectEmit();
        this.getPushTemplates();
    }

    checkInputs() {
        if (!this.header) {
            this.error = 'Тема письма обязательна для заполнения!';
        } else if (!this.desc) {
            this.error = 'Текст письма обязателен для заполнения!';
        } else {
            this.error = '';
        }
    }

    sendPushNotification() {
        if (this.header && this.desc) {
            this.loader = true;
            let cour: number | number[] = 0;
            if (1 === this.selectedUsers.length) {
                cour = this.selectedUsers[0].id;
            } else if (this.selectedUsers.length) {
                cour = this.selectedUsers.map((user: User) => {
                    return user.id;
                });
            }

            let hubs = null;
            if (this.selectedHubs.length) {
                hubs = this.selectedHubs.map(hub => {
                    return hub.id;
                });
            }

            this.api.sendPushNotification(cour, this.header, this.desc, hubs).subscribe(data => {
                let messageParts = [];

                if (data.global) {
                    messageParts.push('Глобальное уведомление отправлено!');
                } else {
                    messageParts.push('Уведомлений отправлено: ' + data.queued + '.');
                    if (data.invalid) {
                        messageParts.push('Невозможно уведомить: ' + data.invalid + '!');
                    }
                }

                alert(messageParts.join("\n"));
                this.header = '';
                this.desc = '';
                this.error = '';
                this.loader = false;
            })
        } else {
            alert('Все поля обязательны для заполнения!');
        }
    }

    setTemplate(header, desc) {
        this.header = header;
        this.desc = desc;
        this.checkInputs();
    }
    public onChangeUser(users: User[]) {
        this.selectedUsers = users;
    }
    getPushTemplates() {
        this.api.getPushTemplates().subscribe(data => {
            this.templates = data;
        })
    }
    substr(text) {
        return text.substring(0, 30);
    }
    updTemplate(e, update) {
        switch (update) {
            case 'header': {
                this.header = e.target.value;
                break;
            }
            case 'desc': {
                this.desc = e.target.value;
                break;
            }
            default: {
                break;
            }
        }
    }
    length() {
        if (this.desc) {
            return 4095 - this.desc.length;
        } else {
            return 4095;
        }
    }

    onChangeHub(hubs) {
        this.selectedHubs = hubs;
        this.appUserSelectEventsSubjectEmit();
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }
}
