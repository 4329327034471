import {CommonModule, DatePipe} from '@angular/common';
import {ComponentsModule} from '../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {
    MAT_DATE_LOCALE,
    MAT_TABS_CONFIG,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatTabsModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BodyModule, GridModule, SharedModule} from '@progress/kendo-angular-grid';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {ActlistComponent} from './actlist/actlist.component';
import {BoardOnlineComponent} from './board-online/board-online.component';
import {CounterComponent} from './board-online/counter.component';
import {DeliveryCouriersComponent} from './transfer/delivery-couriers/delivery-couriers.component';
import {ErrorDialogComponent} from './../components/error-dialog/errordialog.component';
import {ErrorDialogService} from './../components/error-dialog/errordialog.service';
import {IndexComponent} from './transfer/index/index.component';
import {MactlistComponent} from './mactlist/mactlist.component';

import {ProcessingReturnsComponent} from './processing-returns/processing-returns.component';
import {ReceptionCouriersComponent} from './transfer/reception-couriers/reception-couriers.component';
import {ReceptionOrdersComponent} from './reception-orders/reception-orders.component';
import {SberbankComponent} from '../sberbank/sberbank.component';
import {TransferTabComponent} from 'app/storage/transfer/transfer-tab/transfer-tab.component';
import {TurnWorkComponent} from './../turn-work/turn-work.component';
import {ClaimLetterComponent} from './printer/claim-letter/claim-letter.component';
import {ReturnactsComponent} from './returnacts/returnacts.component';
import {ReturnActComponent} from './return-act/return-act.component';
import {ReturnactModalComponent} from './return-act/returnact-modal/returnact-modal.component';
import {PrintCourierMactComponent} from './printer/print-courier-mact/print-courier-mact.component';
import {ActComponent} from './act/act.component';
import {ActModalComponent} from './act/act-modal/act-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DialogService} from '../components/dialog/dialog.service';
import {DialogComponent} from '../components/dialog/dialog.component';
import {CreateTeComponent} from './create-te/create-te.component';
import {TeListComponent} from './te-list/te-list.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {TransitListComponent} from './transit-list/transit-list.component';
import {NgxPrintModule} from 'ngx-print';
import {PalletCardComponent} from './pallet-card/pallet-card.component';
import {PalletModalComponent} from './pallet-card/pallet-modal/pallet-modal.component';
import {TransitCardComponent} from './transit-card/transit-card.component';
import {TransitModalComponent} from './transit-card/transit-modal/transit-modal.component';
import {TransitPrintComponent} from './printer/transit-print/transit-print.component';
import {ActCreateComponent} from './act-create/act-create.component';
import {PowerOfAttorneyComponent} from './printer/power-of-attorney/power-of-attorney.component';
import { PreAssemblyComponent } from './pre-assembly/pre-assembly.component';
import {ReturnOrdersComponent} from './transfer/return-orders/return-orders.component';
import {OrdersPlacementCheckComponent} from './orders-placement-check/orders-placement-check.component';
import {OrdersPlacementReportComponent} from './orders-placement-report/orders-placement-report.component';
import {PalletListComponent} from './pallet/pallet-list/pallet-list.component';
import {PalletItemComponent} from './pallet/pallet-item/pallet-item.component';
import {PalletEditModalComponent} from './pallet/pallet-edit-modal/pallet-edit-modal.component';
import {TransitsListComponent} from './transits/transits-list/transits-list.component';
import {TransitsItemComponent} from './transits/transits-item/transits-item.component';
import {TransitDriverModalComponent} from './transits/transit-driver-modal/transit-driver-modal.component';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import { TransitReceiveComponent } from './transits/transit-receive/transit-receive.component';
import {TransitVehicleModalComponent} from './transits/transit-vehicle-modal/transit-vehicle-modal.component';
import {SpbControlComponent} from '../logistics/spb-control/spb-control.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { TransitCarrierModalComponent } from './transits/transit-carrier-modal/transit-carrier-modal.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {MatIconModule} from '@angular/material/icon';
import {SecondShiftAssemblingComponent} from './second-shift-assembling/second-shift-assembling.component';
import {SecondShiftControlAssemblingComponent} from './second-shift-control-assembling/second-shift-control-assembling.component';
import {SecondShiftAssemblingOrdersComponent} from './second-shift-assembling-orders/second-shift-assembling-orders.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PpActsComponents} from './pp-acts/pp-acts.components';
import {PpActPlacesDialogComponent} from './pp-acts/pp-act-places/pp-act-places.component';
import { PpActClientImportComponent } from './pp-act-client-import/pp-act-client-import.component';
import {ActPrintComponent, ActPrintDialogComponent} from './act-print/act-print.component';
import { PpActClientComponent } from './pp-act-client/pp-act-client.component';
import { PpActClientProcessComponent } from './pp-act-client/pp-act-client-process/pp-act-client-process.component';
import {ActDiffComponent} from './act-diff/act-diff.component';
import {ActPdfComponent} from './act-pdf/act-pdf.component';
import {ActExcelComponent} from './act-excel/act-excel.component';
import {ControlCourierMactComponent} from './control-courier-mact/control-courier-mact.component';
import {ReturnActCreateComponent} from './return-act-create/return-act-create.component';
import { LostOrdersComponent } from './lost-orders/lost-orders.component';
import { ReturnOrderModalComponent } from './return-order-modal/return-order-modal.component';
import { ReturnActPrepareComponent } from './return-act-prepare/return-act-prepare.component';
import {ReturnActsTransferCourierComponent} from './return-acts-transfer-courier/return-acts-transfer-courier.component';
import {ReturnActsArchivariusComponent} from './return-acts-archivarius/return-acts-archivarius.component';
import {ReturnActsFixerComponent} from './return-acts-fixer/return-acts-fixer.component';

import {DatePickerModule, DateRangeModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {HhcCourierAssemblingComponent} from './hhc-courier-assembling/hhc-courier-assembling.component';
import {HhcCourierAssemblingLegacyComponent} from './hhc-courier-assembling-legacy/hhc-courier-assembling-legacy.component';

@NgModule({
    declarations: [
        ActlistComponent,
        ControlCourierMactComponent,
        PpActsComponents,
        BoardOnlineComponent,
        CounterComponent,
        DeliveryCouriersComponent,
        ErrorDialogComponent,
        DialogComponent,
        IndexComponent,
        MactlistComponent,
        ProcessingReturnsComponent,
        ReceptionOrdersComponent,
        ReceptionCouriersComponent,
        ReturnOrdersComponent,
        OrdersPlacementCheckComponent,
        OrdersPlacementReportComponent,
        ReceptionOrdersComponent,
        SecondShiftAssemblingComponent,
        SberbankComponent,
        TransferTabComponent,
        TurnWorkComponent,
        ClaimLetterComponent,
        ReturnactsComponent,
        ReturnActComponent,
        ReturnActCreateComponent,
        ReturnActsTransferCourierComponent,
        ReturnactModalComponent,
        PrintCourierMactComponent,
        ActComponent,
        ActModalComponent,
        CreateTeComponent,
        TeListComponent,
        TransitListComponent,
        PalletListComponent,
        PalletItemComponent,
        PalletCardComponent,
        PalletModalComponent,
        PalletEditModalComponent,

        TransitsListComponent,
        TransitsItemComponent,
        TransitDriverModalComponent,
        TransitVehicleModalComponent,

        TransitCardComponent,
        TransitModalComponent,
        TransitPrintComponent,

        PowerOfAttorneyComponent,
        ActCreateComponent,
        PreAssemblyComponent,
        TransitReceiveComponent,
        SpbControlComponent,
        TransitCarrierModalComponent,
        SecondShiftControlAssemblingComponent,
        SecondShiftAssemblingOrdersComponent,
        PpActPlacesDialogComponent,
        PpActClientImportComponent,
        PpActClientComponent,
        PpActClientProcessComponent,
        ActPrintComponent,
        ActDiffComponent,
        ActExcelComponent,
        ActPdfComponent,
        ActPrintDialogComponent,
        LostOrdersComponent,
        ReturnOrderModalComponent,
        ReturnActPrepareComponent,
        ReturnActsArchivariusComponent,
        ReturnActsFixerComponent,
        HhcCourierAssemblingComponent,
        HhcCourierAssemblingLegacyComponent,

    ],
    imports: [

        CommonModule,
        ComponentsModule,
        FormsModule,
        MatDialogModule,
        GridModule,
        MatTabsModule,
        MatButtonModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        WindowModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        NgxBarcodeModule,
        NgxPrintModule,
        DropDownsModule,
        MatSelectModule,
        BodyModule,
        SharedModule,
        MatCheckboxModule,
        MatRadioModule,
        NgxMatFileInputModule,
        MatIconModule,
        NgMultiSelectDropDownModule,
        TimePickerModule,
        DatePickerModule,
        DateRangeModule,
    ],
    exports: [
        ActlistComponent,
        ControlCourierMactComponent,
        PpActsComponents,
        BoardOnlineComponent,
        IndexComponent,
        MactlistComponent,

        ProcessingReturnsComponent,
        ReceptionCouriersComponent,
        ReceptionOrdersComponent,
        TurnWorkComponent,
        ReturnActComponent,
    ],
    providers: [
        DatePipe,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        DialogService,
        ErrorDialogService,
        /*{
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpConfigInterceptor,
        },*/
        {provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms'}},
        [{provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}]
    ],
    entryComponents: [
        DialogComponent,
        DeliveryCouriersComponent,
        DialogComponent,
        ErrorDialogComponent,
        ReceptionCouriersComponent,
        ReturnOrdersComponent,
        ReturnactModalComponent,
        ActModalComponent,
        PalletModalComponent,
        PalletEditModalComponent,
        TransitModalComponent,
        TransitDriverModalComponent,
        TransitVehicleModalComponent,
        TransitCarrierModalComponent,
        SecondShiftAssemblingOrdersComponent,
        PpActClientImportComponent,
        PpActPlacesDialogComponent,
        PpActClientProcessComponent,
        ActPrintDialogComponent,
        ReturnOrderModalComponent

    ]
})

export class StorageDeprecatedModule {
}
