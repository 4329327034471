import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {HttpNoInterceptorService} from '../../../service/http-no-interceptor.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../../service/helpers.service';
import {CurrentUserService} from '../../../service/current-user.service';
import {MaxSizeValidator} from '@angular-material-components/file-input';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {InventivActsCompareComponent} from '../inventiv-acts-compare/inventiv-acts-compare.component';
import {InventivActsModel} from '../inventiv-acts.model';
import {NotifierService} from '../../../service/notifier.service';

@Component({
  selector: 'app-inventiv-acts-upload',
  templateUrl: './inventiv-acts-upload.component.html',
  styleUrls: ['./inventiv-acts-upload.component.scss']
})
export class InventivActsUploadComponent implements OnInit {

    @Input() isOnlyActLoading: boolean = false;

    public width = 700;
    public windowHeight = 600;
    public form: FormGroup;

    public fileIsUploading: boolean = false;

    /* 30 МБ в байтах */
    private maxSize = (30) * (1024 * 1024);
    public maxActDate: Date = new Date();

    public failedBarcodes: string[];
    public loadingStatus: string[] = [];
    public loadingError: string;

    public loadedAct: InventivActsModel;

    constructor(
        protected app: AppComponent,
        protected api: SkladService,
        private http: HttpNoInterceptorService,
        protected dialog: DialogService,
        private datePipe: DatePipe,
        private notifier: NotifierService,
    ) {
        this.form = new FormGroup({
            'act_date': new FormControl(new Date()),
            'files': new FormArray([]),
        });

        this.addFileForm();
    }

    /**
     * Добавление файла
     */
    public addFileForm() {
        (<FormArray>this.form.get('files')).push(
            new FormGroup({
                'file': new FormControl(null, [
                    Validators.required,
                    MaxSizeValidator(this.maxSize),
                ]),
            })
        )
    }

    /** Удаление файла */
    public removeFileForm(index) {
        (<FormArray>this.form.get('files')).removeAt(index);
    }

    get filesControls() {
        return (<FormArray>this.form.get('files')).controls;
    }

    ngOnInit() {
    }

    /**
     * Отправка файлов на сервер
     */
    onFileUpload() {
        this.failedBarcodes = null;
        this.loadingStatus = [];
        this.loadingError = null;

        for (const control of this.filesControls) {
            if (control.get('file').value === null) {
                this.loadingError = 'Пожалуйста, выберите файл';
                return;
            }
        }

        const formData = new FormData();
        formData.append('act_date', this.datePipe.transform(this.form.get('act_date').value, 'yyyy-MM-dd'));

        for (const control of this.filesControls) {
            formData.append('excel[]', control.get('file').value);
        }

        this.fileIsUploading = true;
        this.http.post('/storage/inventiv-acts/uploadAct', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((data) => {
                this.fileIsUploading = false;

                this.failedBarcodes = null;

                let ordersCount = 0;
                for (const file of data.files) {
                    ordersCount += file.orders_count;
                }

                this.notifier.openNotifier('Акт успешно загружен, найдено ' + ordersCount + ' заказов', null, {class: 'success', duration: 3000});

                this.onClose();

                this.loadedAct = data;
            }, (error: HttpErrorResponse) => {
                this.fileIsUploading = false;

                if (!error.error.response) {
                    this.loadingError = 'Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова';
                } else {
                    this.loadingError = error.error.response.error;
                }
            });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    public onProcessClick(act: InventivActsModel) {
        this.app.createDialog(InventivActsCompareComponent, {
            act: act,
        }).onDestroy(data => {

        })

        this.onClose();
    }

    /**
     * Может быть постобработан
     * @param act
     */
    public canBePostProcessed(act: InventivActsModel) {
        return act.status !== InventivActsModel.STATUS_PROCESSED && this.isOnlyActLoading;
    }
}
