import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from "../../service/sklad.service";
import {HelpersService} from "../../service/helpers.service";
import {FormControl, FormGroup} from "@angular/forms";
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {Title} from "@angular/platform-browser";
import {User} from '../../service/models';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-te-list',
    templateUrl: './te-list.component.html',
    styleUrls: ['./te-list.component.scss']
})
export class TeListComponent implements OnInit {
    public gridData: GridDataResult;

    private pageTitle = 'Транспортные единицы';
    public pageSize = 20;
    public skip = 0;
    public selectedTE: string[] = [];
    public selectedUser: User = null;
    @ViewChild('input_status') inputStatus: ElementRef;
    @ViewChild('transit_date') transitDate: ElementRef;

    public formList: FormGroup;
    public formEdit: FormGroup;

    constructor(
        private title: Title,
        private api: SkladService,
        public helpers: HelpersService
    ) {
        this.title.setTitle(this.pageTitle);

        this.formList = new FormGroup({
            inputStatus: new FormControl(''),
        });

        this.formEdit = new FormGroup(
            {
                transitDate: new FormControl('')
            }
        );
    }

    ngOnInit() {
        this.inputStatus.nativeElement.value = 0;
        this.loadTE();
    }


    public loadTE() {
        this.selectedTE = [];
        let status = this.inputStatus.nativeElement.value;
        if (!status) {
            status = 0;
        }
        this.api.getPackList(this.pageSize, this.skip, status).subscribe(data => {
            this.gridData = {data: data.packs.data, total: data.packs.total};
        });

    }

    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {

            this.selectedUser = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadTE();
    }

    public createTransit() {
        if (!this.selectedTE) {
            alert('Не выбраны палеты!');
            return;
        }
        if (!this.transitDate.nativeElement.value) {
            alert('Выберите дату перемещения');
            return;
        }

        const query = this.formEdit.value;
        query.transit_date = this.helpers.formatDateForSQL(this.transitDate.nativeElement.value);
        // TODO сделать возможность выбора склада отправления и склада назначения
        query.from_warehouse_id = environment.moscow_logsis_warehouse_id;
        query.to_warehouse_id = environment.topdelivery_warehouse_id;
        if (this.selectedUser) {
            query.courier_id = this.selectedUser.id;
        } else {
            query.courier_id = 0;
        }
        query.pack_ids = this.selectedTE;
        console.log(query);

        this.api.createTransit(query).subscribe(data => {
            console.log(data);
            alert('Перемещение: ' + data.transit.id + ' создано; ' + data.message);
            this.loadTE();
        })
    }

    getPrint(data) {
        // роутер на печать
        return ['/te-print', {'packId': data}]
    }
}
