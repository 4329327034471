import {AdminRoutingModule} from './admin-routing.module';
import {ComponentsModule} from '../components/components.module';
import {CommonModule} from '@angular/common';
import {BodyModule, ColumnMenuModule, FilterMenuModule, GridModule, HeaderModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {NgModule} from '@angular/core';
import {
    MAT_TABS_CONFIG,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
} from '@angular/material';
import {AdminHeaderComponent} from './admin-header/admin-header.component';
import {AdminNavbarComponent} from './admin-navbar/admin-navbar.component';
import {SearchComponent} from '../search/search.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgxBarcodeModule} from 'ngx-barcode';
import {NgxPrintModule} from 'ngx-print';
import {OrdersCheckComponent} from '../logistics/orders-check/orders-check.component';
import {OrdersListComponent} from '../orders-list/orders-list.component';
import {OrdersListDirective} from '../orders-list/orders-list.directive';
import {ZordersListComponent} from '../logistics/zorders-list/zorders-list.component';
import {CourierTarifficatorComponent} from '../logistics/courier-tarifficator/courier-tarifficator.component';
import {CourierStatusComponent} from '../logistics/courier-status/courier-status.component';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {CourierDocsComponent} from '../logistics/courier-docs/courier-docs.component';
import {UnrecognizedAddressesComponent} from '../logistics/unrecognized-addresses/unrecognized-addresses.component';
import {AddressesCacheReattachmentComponent} from '../addresses/addresses-cache-reattachment/addresses-cache-reattachment.component';
import {HttpConfigInterceptor} from '../interceptor/httpconfig.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import { CourierDialogComponent } from '../components/courier-card/courier-dialog/courier-dialog.component';
import {ProblemsComponent} from '../logistics/problems/problems.component';
import {CourierWorkdayComponent} from '../logistics/courier-workday/courier-workday.component';
import {CourierExtraExpensesComponent} from '../logistics/courier-extra-expenses/courier-extra-expenses.component'
import {PushNotificationsComponent} from '../mailer/push-notifications/push-notifications.component';
import {MatOptionModule} from '@angular/material/core';
import {SberbankControlComponent} from '../logistics/sberbank-control/sberbank-control.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ProblemsCounterComponent} from './admin-navbar/problems-counter.component';
import {ChartsModule} from '@progress/kendo-angular-charts';
import 'hammerjs'
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {RegionalOrdersComponent} from '../logistics/regional-orders/regional-orders.component';
import {RefusedOnSpotComponent} from '../logistics/refused-on-spot/refused-on-spot.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EditorModule} from '@tinymce/tinymce-angular';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {MatTabsModule} from '@angular/material/tabs';
import {DispatchedNotificationsComponent} from '../mailer/dispatched-notifications/dispatched-notifications.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {
    CourierScheduleComponent,
    CourierScheduleDayDialogComponent,
    CourierSchedulePlanningDialogComponent,
} from '../logistics/courier-schedule/courier-schedule.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBadgeModule} from '@angular/material/badge';
import {OrdersSignaturesVerificationQueueComponent} from '../client-service/orders-signatures-verification-queue/orders-signatures-verification-queue.component';
import {OrdersSignaturesVerificationQueueCounterComponent} from './admin-navbar/orders-signatures-verification-queue-counter.component';
import {RefusedOrdersQueueCounterComponent} from './admin-navbar/refused-orders-queue-counter.component';
import {CompanyCommissionChairsCardDialogComponent} from '../components/company-commission-chairs/company-commission-chairs-card.component';
import {
    ReturnActCreateCardComponent,
    ReturnActCreateCardDialogComponent,
} from '../components/return-act-create-card/return-act-create-card.component';
import {StorageDeprecatedModule} from '../storage/storage-deprecated.module';
import {FuelPricesComponent} from '../logistics/fuel-prices/fuel-prices.component';
import {PochtaRuPostcodesComponent} from '../additional/pochta-ru-postcodes/pochta-ru-postcodes.component';

@NgModule({
    declarations: [
        AdminNavbarComponent,
        AdminHeaderComponent,
        SearchComponent,
        OrdersCheckComponent,
        OrdersListComponent,
        OrdersListDirective,
        ZordersListComponent,
        CourierStatusComponent,
        UnrecognizedAddressesComponent,
        AddressesCacheReattachmentComponent,
        CourierDocsComponent,
        CourierTarifficatorComponent,
        CourierDialogComponent,
        ProblemsComponent,
        CourierWorkdayComponent,
        PushNotificationsComponent,
        SberbankControlComponent,
        ProblemsCounterComponent,
        OrdersSignaturesVerificationQueueCounterComponent,
        RefusedOrdersQueueCounterComponent,
        RefusedOnSpotComponent,
        RegionalOrdersComponent,
        CourierExtraExpensesComponent,
        CourierScheduleComponent,
        CourierScheduleDayDialogComponent,
        CourierSchedulePlanningDialogComponent,
        DispatchedNotificationsComponent,
        OrdersSignaturesVerificationQueueComponent,
        ReturnActCreateCardDialogComponent,
        CompanyCommissionChairsCardDialogComponent,
        FuelPricesComponent,
        PochtaRuPostcodesComponent,
    ],
    imports: [
        CommonModule,
        GridModule,
        RowFilterModule,
        MDBBootstrapModule.forRoot(),
        ComponentsModule,
        StorageDeprecatedModule,
        WindowModule,
        NgMultiSelectDropDownModule,
        AdminRoutingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        NgxBarcodeModule,
        MatInputModule,
        AngularYandexMapsModule,
        NgMultiSelectDropDownModule,
        NgxPrintModule,
        DragDropModule,
        ChartsModule,
        MatTabsModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        BodyModule,
        EditorModule,
        MatListModule,
        MatCardModule,
        MatChipsModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatButtonModule,
        NgxMatFileInputModule,
        MatSlideToggleModule,
        FilterMenuModule,
        MultiSelectModule,
        ColumnMenuModule,
        HeaderModule,
        MatDialogModule,
        MatBadgeModule,
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpConfigInterceptor,
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms'
            }
        },
    ],
    exports: [
        AdminNavbarComponent,
        AdminHeaderComponent,
    ],
    entryComponents: [
        CourierDialogComponent,
        CourierScheduleDayDialogComponent,
        CourierSchedulePlanningDialogComponent,
        ReturnActCreateCardDialogComponent,
        ReturnActCreateCardComponent,
        CompanyCommissionChairsCardDialogComponent,
    ]
})

export class AdminModule {
}
