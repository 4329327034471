import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {Hub} from '../../../service/models';
import {StatisticsService} from '../../statistics.service';

@Component({
    selector: 'app-daily-meeting-clients',
    templateUrl: './daily-meeting-clients.component.html',
    styleUrls: ['./daily-meeting-clients.component.scss']
})
export class DailyMeetingClientsComponent implements OnInit {

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = '';
    public titleSource = 'Статистика привоза по клиентам';

    public loading: boolean = false;
    public stat;

    @Input('selectedHub') selectedHub: Hub;
    @Input('type') type: string;

    constructor(
        private api: StatisticsService,
        protected app: AppComponent
    ) {
        if (window.innerWidth > 550) {
            this.width = 550;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.title = this.titleSource;
        if (this.type === 'top20') {
            this.title = this.titleSource + ' Топ-20';
        }

        this.loadStat();
    }

    loadStat() {
        this.stat = null;
        this.loading = true;
        this.api.getMeetingClientsTodayStat({hub_id: this.selectedHub.id, type: this.type})
            .subscribe(data => {
                this.stat = data;

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    onClose() {
        this.app.closeDialog(this);
    }

}
