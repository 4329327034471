import {Injectable} from '@angular/core';
import {DocReturnAct} from "../models";

@Injectable({providedIn: 'root'})

export class DocReturnActHelper {
    /**
     * Возвращает класс лейбла статуса акта возврата документов
     * @param status
     */
    public getActStatusLabelClass(status: number) {
        let classes = [
            'act-status-label-base',
        ];

        switch (status) {
            case DocReturnAct.STATUS_OPENED:
                classes.push('text-primary');
                break;

            case DocReturnAct.STATUS_CLOSED:
                classes.push('text-success');
                break;

            case DocReturnAct.STATUS_SIGNED:
                classes.push('text-white', 'bg-success');
                break;

            default:
                classes.push('text-white', 'bg-danger');
                break;
        }

        return classes.join(' ');
    }

    /**
     * Возвращает текст лейбла статуса акта возврата документов
     * @param status
     */
    public getActStatusLabelText(status: number) {
        let statusDescription = '';
        if ('undefined' !== typeof DocReturnAct.STATUS_DESCRIPTION[status]) {
            statusDescription = DocReturnAct.STATUS_DESCRIPTION[status];
        }

        return statusDescription.length ? statusDescription : 'Неизвестно';
    }
}
