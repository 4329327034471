import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {ActivatedRoute} from '@angular/router';
import {HubsService} from '../../../service/hubs.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {throwError} from 'rxjs';
import {Hub, Pallet, Transit, TransitFile, User} from '../../../service/models';
import {DialogService} from '../../../components/dialog/dialog.service';
import {FileDownloadService} from '../../../http/file-download.service';
import {PalletEditModalComponent} from '../../pallet/pallet-edit-modal/pallet-edit-modal.component';
import {TransitDriverModalComponent} from '../transit-driver-modal/transit-driver-modal.component';
import {TransitVehicleModalComponent} from '../transit-vehicle-modal/transit-vehicle-modal.component';
import {TransitCarrierModalComponent} from '../transit-carrier-modal/transit-carrier-modal.component';
import {HttpNoInterceptorService} from '../../../service/http-no-interceptor.service';
import {AcceptValidator, MaxSizeValidator} from '@angular-material-components/file-input';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-transits-item',
    templateUrl: './transits-item.component.html',
    styleUrls: ['./transits-item.component.scss']
})
export class TransitsItemComponent implements OnInit {
    public transitId;
    public transit: Transit;

    public width;

    public palletInput = false;
    public loading: boolean = false;
    public torg13Loading: boolean = false;
    public ttnLoading: boolean = false;
    public transportWaybillLoading: boolean = false;

    public formEdit: FormGroup;
    private newCourier;

    public courierLoading: boolean = false;
    public statusLoading: boolean = false;

    private accept = 'application/pdf';

    /* 30 МБ в байтах */
    private maxSize = (30) * (1024 * 1024);

    public fileTypes = TransitFile.fileTypes;

    public transitFiles;
    public newFileAddingClosed = true;

    constructor(
        public helpers: HelpersService,
        protected route: ActivatedRoute,
        protected app: AppComponent,
        protected dialog: DialogService,
        protected hubsService: HubsService,
        private http: HttpNoInterceptorService,
        private fileDownloadService: FileDownloadService,
        protected api: SkladService
    ) {
        this.width = window.innerWidth * 0.5;

        this.formEdit = new FormGroup({
            new_status: new FormControl(''),
            file_type: new FormControl(''),
            file: new FormControl(null, [
                Validators.required,
                MaxSizeValidator(this.maxSize),
                AcceptValidator(this.accept)
            ]),
        });
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.transitId = +params['id'];

            this.getTransit();
        })
    }

    getTransit() {
        this.loading = true;
        this.api.getTransitInfo(this.transitId).subscribe((data) => {
            this.transit = data;
            this.transitFiles = data.files;
            this.loading = false;
        });
    }

    addPallet(event) {
        this.api.setPalletToHubTransit(this.transit.id, [event.target.value]).subscribe(data => {
            this.updateTransit();
            console.log(data);
        });
        event.target.value = '';
    }

    orderStatus(status) {
        return this.helpers.getNameOrderStatus(status);
    }

    deletePallet(palletId) {
        this.api.removePalletFromTransit(this.transit.id, [palletId]).subscribe(data => {
            console.log(data);
            this.updateTransit();
            this.dialog.alert('Палета №' + palletId + ' удалена');
        });
    }

    updateTransit() {
        this.api.getTransitInfo(this.transit.id).subscribe((data) => {
            this.transit = data;
        });
    }

    getPlacesCount(pack) {
        let count = 0;
        for (let i = 0; i < pack.orders.length; i++) {
            count += pack.orders[i].bar_codes.length;
        }

        return count;
    }

    protected handleError(e) {
        return throwError(e);
    }

    getTorg13() {
        if (!this.isAllowedToPrint()) {
            return;
        }

        this.torg13Loading = true;
        this.fileDownloadService
            .downloadFile('/storage/transits/transit/' + this.transitId + '/torg13', 'Torg13.xlsx')
            .subscribe(response => {
                this.torg13Loading = false;
            }, error => {
                this.torg13Loading = false;
            });
    }

    getTransportWaybill() {
        if (!this.isAllowedToPrint()) {
            return;
        }

        this.transportWaybillLoading = true;
        this.fileDownloadService
            .downloadFile('/storage/transits/transit/' + this.transitId + '/transportWaybill', 'TransportWaybill.xlsx')
            .subscribe(response => {
                this.transportWaybillLoading = false;
            }, error => {
                this.transportWaybillLoading = false;
            });
    }

    getTtn() {
        if (!this.isAllowedToPrint()) {
            return;
        }

        this.ttnLoading = true;
        this.fileDownloadService
            .downloadFile('/storage/transits/transit/' + this.transitId + '/ttn', 'Ttn.xlsx')
            .subscribe(response => {
                this.ttnLoading = false;
            }, error => {
                this.ttnLoading = false;
            });
    }

    /** LSD-13545 Не даём печатать документы, пока перемещение не отправлено */
    private isAllowedToPrint() {
        if (this.transit.status === Transit.STATUS_NEW) {
            this.dialog.alert('Нельзя распечатать документ, пока перемещение не отправлено!', 440);
            return false;
        }

        return true;
    }

    onSetSelectCourier(user: User) {
        this.newCourier = user;
    }

    onUseSetCourier() {
        if (!this.newCourier) {
            this.dialog.alert('Выберите курьера');
            return;
        }

        const dialog = this.dialog.confirm('Вы уверены, что хотите назначить курьера ' + this.newCourier.name + ' на данное перемещение?').subscribe(result => {
            if (result) {
                this.courierLoading = true;
                this.api.setCourierForTransits([this.transitId], this.newCourier.id)
                    .subscribe(data => {
                        this.getTransit();
                        this.courierLoading = false;
                    });
            }
            dialog.unsubscribe();
        });
    }

    onSetStatus() {
        if (!this.formEdit.value.new_status) {
            this.dialog.alert('Выберите статус');
            return;
        }

        const dialog = this.dialog.confirm('Установить статус ' + this.helpers.getNameTransitStatus(this.formEdit.value.new_status) + '?').subscribe(result => {
            if (result) {
                this.statusLoading = true;
                this.api.setHubTransitStatus(this.transitId, this.formEdit.value.new_status).subscribe(data => {
                    this.getTransit();
                    if (data.message) {
                        this.dialog.alert(data.message);
                    }

                    this.statusLoading = false;
                }, error => {
                    this.statusLoading = false;
                });
            }
            dialog.unsubscribe();
        })
    }

    /**
     * Можно изменять статус
     */
    public canChangeStatus() {
        return this.transit.status === Transit.STATUS_NEW || this.transit.status === Transit.STATUS_ONDELIVERY;
    }

    /**
     * Можно изменять курьера
     */
    public canChangeCourier() {
        if (this.transit.hub_destination.type === Hub.TYPE_TOPDELIVERY
            && this.transit.status === Transit.STATUS_NEW
        ) {
            return true;
        }

        return false;
    }

    /** у перемещения может быть курьер */
    public canHasCourier() {
        return (this.transit.hub_destination.type === Hub.TYPE_TOPDELIVERY);
    }

    /**
     * Доступна печать документов
     */
    public canPrintDocuments() {
        return (this.transit.hub_destination.type === Hub.TYPE_LOGSIS);
    }

    public onChangeDriverClick() {
        this.app.createDialog(TransitDriverModalComponent, {
            transit: this.transit
        }).onDestroy(data => {
            this.updateTransit();
        })
    }

    public onChangeCarrierClick() {
        this.app.createDialog(TransitCarrierModalComponent, {
            transit: this.transit
        }).onDestroy(data => {
            this.updateTransit();
        })
    }

    public onChangeVehicleClick() {
        this.app.createDialog(TransitVehicleModalComponent, {
            transit: this.transit
        }).onDestroy(data => {
            this.updateTransit();
        })
    }

    /**
     * Отправка файлов на сервер
     */
    onFileUpload() {
        const fileType = this.formEdit.get('file_type').value;
        if (!fileType) {
            alert('Пожалуйста, выберите тип файла');
            return;
        }

        const formData = new FormData();
        formData.append('file', this.formEdit.controls.file.value);
        formData.append('transit_id', this.transit.id.toString());
        formData.append('file_type', this.formEdit.get('file_type').value);
        this.http.post('/storage/transits/addFile', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((data) => {
                this.transitFiles = data;

                this.formEdit.get('file_type').setValue('');
                this.newFileAddingClosed = true;
            }, (error: HttpErrorResponse) => {
                this.loading = false;
                if (!error.error.response) {
                    this.dialog.alert('Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова');
                } else {
                    this.dialog.alert(error.error.response.error);
                }
            });
    }

    /**
     * Возвращает название файла
     * @param fileType
     */
    public getFileTypeName(fileType) {
        const type = this.fileTypes.find(t => {
            return fileType === t.type;
        })

        if (type) {
            return type.name;
        }

        return null;
    }
}
