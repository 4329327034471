import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ReturnAct} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {HelpersService} from '../../service/helpers.service';

interface IReturnActToPlaceInArchiveResponse {
    return_act: ReturnAct;
    initial_status: number;
}

@Component({
    selector: 'app-return-acts-archivarius',
    templateUrl: './return-acts-archivarius.component.html',
    styleUrls: ['./return-acts-archivarius.component.scss'],
})
export class ReturnActsArchivariusComponent implements OnInit {

    @ViewChild('barcode') public barcode: ElementRef;
    public loading: boolean = false;
    public returnAct: ReturnAct | null = null;
    public initialStatus: number | null = null;
    public initialStatusText: string | null = null;

    constructor(
        private api: LaraService,
        private helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.setFocusOnBarcode();
    }

    onBarcodeInput(event) {
        if (event.keyCode !== 13) {
            return;
        }

        this.onBarcodeScan();
    }

    onBarcodeScan() {
        const barcode = this.barcode.nativeElement.value.trim();
        if (!barcode.length) {
            return;
        }

        this.scanReturnActToPlaceInArchive(barcode);
    }

    isBarcodeScanDisabled() {
        return !this.barcode.nativeElement.value.trim().length;
    }

    private scanReturnActToPlaceInArchive(barcode: string) {
        this.loading = true;
        this.returnAct = null;
        this.initialStatus = null;
        this.initialStatusText = null;

        this.api.scanReturnActToPlaceInArchive(barcode).subscribe((response: IReturnActToPlaceInArchiveResponse) => {
            this.returnAct = response.return_act;
            this.initialStatus = response.initial_status;
            this.initialStatusText = this.helpers.getReturnActStatusName(this.initialStatus);

            this.loading = false;

            this.barcode.nativeElement.value = '';
            this.setFocusOnBarcode();
        }, () => {
            this.loading = false;
            this.setFocusOnBarcode();
        });
    }

    private setFocusOnBarcode() {
        this.barcode.nativeElement.focus();
    }
}
