import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-root',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {

    title = 'Angular-Interceptor';
    editForm: FormGroup;
    editControl: FormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog
    ) {
        if (this.data.type === 'edit') {
            this.editForm = new FormGroup({
                editControl: new FormControl(this.data.value)
            });
        }

        if (this.data.type === 'chooseSomething') {
            this.editForm = new FormGroup({
                editControl: new FormControl(this.data.currentValue.toString())
            });
        }
    }


    onCancel() {
        this.data.event.emit(false);
    }

    onOk() {
        this.data.event.emit(true);
    }

    onFinishEdit() {

        this.data.event.emit(this.editForm.value.editControl);
        this.dialog.closeAll();
    }
}
