import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';

interface IHistoryRecord {
    id?: number;
    subject?: string;
    value?: string;
    created_at?: string | Date;
    created_at_day_of_week?: string;
    user_id?: number | string;
    user_name?: string;
    origin?: string;
    special?: number;
    x_expanded?: boolean;
    x_display_user_id?: boolean;
}

@Component({
    selector: 'app-log-reader',
    templateUrl: './log-reader.component.html',
    styleUrls: ['./log-reader.component.scss'],
})
export class LogReaderComponent implements OnInit, OnChanges, OnDestroy {
    public logs: null | IHistoryRecord[] = null;
    public loading: boolean = false;
    @Input() objectType: string | null = null;
    @Input() objectId: string | number | null = null;
    @Input() data: null | IHistoryRecord[] = null;
    @Input() display: boolean = true;
    @Input() caption: string | null = null;
    @Input() showColumnNames: boolean = true;
    @Input() showUserIds: boolean = false;
    public postmanBulkEdit = {};
    public postmanBulkEditLines = {};
    public postmanBulkEditLoading = {};
    private objectTypeBcc: string | null = null;
    private objectIdBcc: string | null = null;
    private apiSubscription;

    constructor(
        private api: LaraService,
        private datePipe: DatePipe,
        public helpersService: HelpersService,
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.unsubscribe();
        this.resetPostmanBulkEdit();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.refreshLogs();
    }

    getLogs() {
        if (!this.display) {
            return;
        }

        this.resetPostmanBulkEdit();
        this.logs = (null === this.logs ? null : []);
        this.objectTypeBcc = this.objectType;
        this.objectIdBcc = this.objectId.toString();

        const apiMethod = this.getApiMethodRef();
        if (apiMethod) {
            this.loading = true;
            this.apiSubscription = apiMethod.call(this.api, this.objectId).subscribe(result => {
                this.logs = result.map((log: IHistoryRecord) => {
                    if (log.created_at) {
                        log.created_at_day_of_week = this.datePipe.transform(log.created_at, 'EEE', '', 'ru-RU');
                        log.created_at = this.datePipe.transform(log.created_at, 'dd.MM.yyyy HH:mm:ss');
                    }

                    return log;
                });

                this.loading = false;
                this.unsubscribe();
            }, () => {
                this.loading = false;
                this.unsubscribe();
            });
        }
    }

    /**
     * Запрашивает вводные данные запроса к API createorder из журнала в виде пригодном для вставки в Postman в режиме Bulk Edit
     * @param origin
     * @param recordId
     */
    loadPostmanBulkEdit(origin, recordId) {
        this.postmanBulkEditLoading[origin + '/' + recordId] = true;

        this.api.getCommonLogRecordForPostmanBulkEdit(origin, recordId).subscribe(response => {
            this.postmanBulkEditLines[origin + '/' + recordId] = +response.split(/\r\n|\r|\n/).length;
            this.postmanBulkEdit[origin + '/' + recordId] = response;

            this.postmanBulkEditLoading[origin + '/' + recordId] = false;
        }, () => {
            this.postmanBulkEditLoading[origin + '/' + recordId] = false;
        });
    }

    /**
     * Очищает коллекцию данных пригодных для вставки в Postman в режиме Bulk Edit
     */
    resetPostmanBulkEdit() {
        this.postmanBulkEdit = {};
        this.postmanBulkEditLines = {};
        this.postmanBulkEditLoading = {};
    }

    private refreshLogs() {
        if (null !== this.data) {
            this.logs = this.data;
            return;
        }

        if (this.objectType && this.objectId) {
            this.getLogs();
        }
    }

    private getApiMethodRef() {
        switch (this.objectType) {
            case 'order':
                return this.api.getLogs;

            case 'zorder':
                return this.api.getLogsZorder;
        }
    }

    private unsubscribe() {
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
            this.apiSubscription = null;
        }
    }
}
