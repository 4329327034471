import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {ReturnActService} from '../../service/return-act.service';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';

interface DataFormat {
    name: string,
    value: number
}

interface EventFormat {
    type: string,
    value: object
}

@Component({
    selector: 'app-return-act-client-warehouse-select',
    templateUrl: './return-act-client-warehouse-select.component.html',
    styleUrls: ['./return-act-client-warehouse-select.component.scss'],
})
export class ReturnActClientWarehouseSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Склад клиента';
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: DataFormat[] = [];
    selectedItems = [];
    selectedHubId: number;
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<EventFormat>;

    constructor(
        private api: ReturnActService,
        private currentUserService: CurrentUserService,
        protected eventer: EventerService,
    ) {
    }

    ngOnInit(): void {

        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEventSubscription(data));
        }
        const hub = this.currentUserService.getCurrentHub();

        this.selectedHubId = hub.id;
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true,
        };
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubId = hub.id;

    }

    /**
     * Подписка на родителя
     * @param data
     */
    onEventSubscription(data: EventFormat) {
        switch (data.type) {
            case 'set-client':
                this.onSetValue(data.value)
                break;
        }
    }

    /**
     * Установка данных
     */
    onSetValue(clientId) {
        if (clientId === null) {
            clientId = 0;
        }
        this.api.clientWarehousesWhereReturnsPossible(this.selectedHubId, clientId).subscribe(data => {
            this.selectedItems = [];
            this.dropdownList = data;

            if (this.dropdownList.length === 1) {
                this.selectedItems = this.dropdownList;
            }
        })
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.value) !== -1
        })
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
