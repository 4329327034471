import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ReturnAct} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {ReturnActService} from '../../service/return-act.service';

@Component({
    selector: 'app-return-acts-fixer',
    templateUrl: './return-acts-fixer.component.html',
    styleUrls: ['./return-acts-fixer.component.scss'],
})
export class ReturnActsFixerComponent implements OnInit {

    public readonly RETURN_ACT_STATUS_FORMED = ReturnAct.STATUS_FORMED;
    @ViewChild('barcode') public barcode: ElementRef;
    public loading: boolean = false;
    public returnAct: ReturnAct | null = null;
    public fixerAvailable: boolean = false;
    public fixerExecuted: boolean = false;

    constructor(
        private api: ReturnActService,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.setFocusOnBarcode();
    }

    onBarcodeInput(event) {
        if (event.keyCode !== 13) {
            return;
        }

        this.onBarcodeScan();
    }

    onBarcodeScan() {
        const barcode = this.barcode.nativeElement.value.trim();
        if (!barcode.length) {
            return;
        }

        this.lookupReturnAct(barcode);
    }

    isBarcodeScanDisabled() {
        return !this.barcode.nativeElement.value.trim().length;
    }

    onPowerButtonClick() {
        if (!this.returnAct) {
            return;
        }

        this.fixReturnAct(this.returnAct.old_vact_id);
    }

    private lookupReturnAct(barcode: string) {
        this.loading = true;
        this.returnAct = null;
        this.fixerExecuted = false;

        this.api.getReturnActDetails(barcode).subscribe((returnAct: ReturnAct) => {
            this.returnAct = returnAct;
            this.isFixerAvailable();

            this.loading = false;

            this.barcode.nativeElement.value = '';
            this.setFocusOnBarcode();
        }, () => {
            this.loading = false;
            this.setFocusOnBarcode();
        });
    }

    private fixReturnAct(barcode: string) {
        this.fixerExecuted = true;
        this.loading = true;

        this.api.fixReturnAct(barcode).subscribe((returnAct: ReturnAct) => {
            this.returnAct = returnAct;
            this.isFixerAvailable();

            this.loading = false;
            this.setFocusOnBarcode();
        }, () => {
            this.loading = false;
            this.setFocusOnBarcode();
        });
    }

    private isFixerAvailable() {
        this.fixerAvailable = (this.returnAct && (
            ReturnAct.STATUS_FORMED !== this.returnAct.status ||
            !!this.returnAct.courier_id ||
            !!this.returnAct.zorder_id
        ));
    }

    private setFocusOnBarcode() {
        this.barcode.nativeElement.focus();
    }
}
