import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-new-stat-orders',
  templateUrl: './new-stat-orders.component.html',
  styleUrls: ['./new-stat-orders.component.scss']
})
export class NewStatOrdersComponent implements OnInit {

  public width = 500;
  public windowTop = 100;
  public windowHeight = 100;
  public title = 'Информация о заказах';

  public loading: boolean = false;

  @Input('data') data: any;

  constructor(
      protected app: AppComponent,
  ) {
    if (window.pageYOffset > 0) {
      this.windowTop = window.pageYOffset + 50;
    }

    if (window.innerWidth > 450) {
      this.width = 450;
    } else {
      this.width = (0.95 * window.innerWidth);
    }

    this.windowHeight = window.innerHeight * 0.9;
  }

  ngOnInit() {

  }

  onClose() {
    this.app.closeDialog(this);
  }


}
