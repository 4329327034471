import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {isJsObject} from '@angular/core/src/change_detection/change_detection_util';

@Component({
    selector: 'app-second-shift-assembling',
    templateUrl: './second-shift-assembling.component.html',
    styleUrls: ['second-shift-assembling.component.css']
})

export class SecondShiftAssemblingComponent implements OnInit {
    public formScan: FormGroup;
    public data;
    public notifyMessage: string = null;
    public isError = false;
    public errorClass = 'bg-warning';
    public loading = false;
    public printBarCode = null;
    @ViewChild('barcode') barcode: ElementRef;


    ngOnInit(): void {
        setTimeout(() => this.clearAndFocus(), 500);
    }
    constructor(
        private api: HttpNoInterceptorService,
    ) {
        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required),
        });
    }

    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    onScan() {
        if (!this.formScan.valid) {
            return this.clearAndFocus();
        }
        this.loading = true;
        this.isError = false;
        this.notifyMessage = null;
        this.data = [];
        this.api.post('/storage/second-shift/assemblingSecondShift', {barcode: this.formScan.controls.barcode.value}).subscribe((data: any) => {
            this.loading = false;
            this.isError = false
            this.data = data.body.response.data;
            this.notifyMessage = data.body.response.message;
            this.clearAndFocus();
        }, error => {
            this.notifyMessage = error.error.response.message;
            this.errorClass = error.error.response.extra && error.error.response.extra.errorClass ? error.error.response.extra.errorClass : 'bg-warning';
            this.isError = true;
            this.loading = false;
            this.clearAndFocus();
        });
    }

    clearAndFocus() {
        this.formScan.reset();
        this.barcode.nativeElement.focus();
    }
}
