import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocReturnAct} from "../../service/models";
import {DocReturnService} from "../../service/doc-return.service";
import {AppComponent} from "../../app.component";
import {DocReturnActDialogComponent} from "./doc-return-act-dialog/doc-return-act-dialog.component";

@Component({
    selector: 'app-doc-return-act-card',
    templateUrl: './doc-return-act-card.component.html',
    styleUrls: ['./doc-return-act-card.component.scss']
})
export class DocReturnActCardComponent implements OnInit {
    @Input('act_id') public act_id: number;
    @Input('act') public act: DocReturnAct;
    @Output() onClosed = new EventEmitter();

    public loading: boolean = false;
    private openedDialogs = {};

    constructor(
        protected api: DocReturnService,
        protected app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    /**
     * Клик по номеру акта - открывает карточку акта
     */
    onOpen() {
        if (this.act && this.act_id && this.act.id != this.act_id) {
            this.act = null;
        }

        if (this.act) {
            return this.dialogOpen(this.act_id);
        }

        if (!this.act) {
            this.loadAct(this.act_id);
        }
    }

    /**
     *
     * @param act_id
     */
    loadAct(act_id) {
        this.loading = true;
        this.api.getAct(act_id, true).subscribe(data => {
            this.act = new DocReturnAct(data);
            this.loading = false;
            this.dialogOpen(act_id);
        }, error => {
            this.loading = false;
        });
    }

    /**
     * Открывает или обновляет модальное окно с карточкой акта возврата документов
     * @param act_id
     */
    dialogOpen(act_id) {
        if ('undefined' !== typeof this.openedDialogs[act_id] && !!this.openedDialogs[act_id]) {
            this.openedDialogs[act_id].instance.act = this.act;
            return;
        }

        this.openedDialogs[act_id] = this.app.createDialog(DocReturnActDialogComponent, {
            act: this.act,
            parent: this,
        });
    }

    /**
     * Отправляет сигнал о закрытии модального окна
     * @param act_id
     * @constructor
     */
    DialogClosed(act_id) {
        if ('undefined' !== typeof this.openedDialogs[act_id]) {
            delete this.openedDialogs[act_id];
        }

        this.onClosed.emit(act_id);
    }

    /**
     * Удаление записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    removeRecord(id, act_id) {
        this.loading = true;
        this.api.removeRecordFromAct(id, act_id).subscribe(responsePayload => {
            this.loadAct(act_id);
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Восстановление записи заказа/штрихкода в акте
     * @param id
     * @param act_id
     */
    restoreRecord(id, act_id) {
        this.loading = true;
        this.api.restoreRecordInAct(id, act_id).subscribe(responsePayload => {
            this.loadAct(act_id);
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Клик по кнопке удаления записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    onRemoveRecord(id, act_id) {
        this.removeRecord(id, act_id);
    }

    /**
     * Клик по кнопке восстановления записи заказа/штрихкода в акте
     * @param id
     * @param act_id
     */
    onRestoreRecord(id, act_id) {
        this.restoreRecord(id, act_id);
    }
}
