import {Injectable} from '@angular/core';
import {LaraService} from '../service/lara.service';

@Injectable({
    providedIn: 'root',
})
export class PickupPointApiService extends LaraService {
    /** Список заказов ПВЗ */
    public listOrders(params) {
        return this.post('/pickup-point/listOrders', params);
    }

    /** Поиск заказов для отгрузки на ПВЗ */
    public lookupOrder(params) {
        return this.post('/pickup-point/lookupOrder', params);
    }

    /** Старт сборки заказа для отгрузки на ПВЗ */
    public beginAssembling(uid) {
        return this.post('/pickup-point/shipping/' + uid + '/beginAssembling', {});
    }

    /** Сканирование ШК в процессе отгрузки на ПВЗ */
    public checkBarcode(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/checkBarcode', params);
    }

    /** Завершение сборки заказа в процессе отгрузки на ПВЗ */
    public finishAssembling(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/finishAssembling', params);
    }

    /** Установка состояния документов в процессе отгрузки на ПВЗ */
    public setDocReturnState(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/setDocReturnState', params);
    }

    /** Установка списка отказных товаров в процессе отгрузки на ПВЗ */
    public setRefusedOrdersList(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/setRefusedOrdersList', params);
    }

    /** Установка состояния претензионных документов в процессе отгрузки на ПВЗ */
    public setPretensionState(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/setPretensionState', params);
    }

    /** Переход к оплате заказа в процессе отгрузки на ПВЗ */
    public beginPayment(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/beginPayment', params);
    }

    /** Проверка СМС-кода подтверждения закрытия заказа в процессе отгрузки на ПВЗ */
    public sendSMSWithCode(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/sendSMSWithCode', params);
    }

    /** Отправка СМС с кодом подтверждения закрытия заказа в процессе отгрузки на ПВЗ */
    public checkCodeFromSMS(uid, params) {
        return this.post('/pickup-point/shipping/' + uid + '/checkCodeFromSMS', params);
    }

    /** Проверка состояния оплаты заказа в процессе отгрузки на ПВЗ */
    public checkPaymentStatus(uid) {
        return this.post('/pickup-point/shipping/' + uid + '/checkPaymentStatus', {});
    }

    /** Завершение отгрузки на ПВЗ */
    public finishShipping(uid) {
        return this.post('/pickup-point/shipping/' + uid + '/finishShipping', {});
    }
}
