import {Component, Input, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-second-shift-assembling-orders',
    templateUrl: './second-shift-assembling-orders.component.html',
    styleUrls: ['./second-shift-assembling-orders.component.scss']
})
export class SecondShiftAssemblingOrdersComponent implements OnInit {

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Информация о заказах';

    public loading: boolean = false;
    public courierName;
    public courierAssemblingInfo;

    @Input('courierId') courierId: number;

    constructor(
        private api: LaraService,
        protected app: AppComponent
    ) {
        if (window.pageYOffset > 0) {
            this.windowTop = window.pageYOffset + 50;
        }

        if (window.innerWidth > 500) {
            this.width = 500;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = window.innerHeight * 0.8;
    }

    ngOnInit() {
        this.loadOrders();
    }

    loadOrders() {
        this.courierAssemblingInfo = null;
        this.loading = true;

        this.api.getSecondShiftCourierAssemblingInfo(this.courierId)
            .subscribe(data => {
                this.courierName = data.courier_name;
                this.courierAssemblingInfo = data.places;
                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
