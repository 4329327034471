import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
    public static readonly MODE_COUNTDOWN = 1;
    public static readonly MODE_TIMER = 2;

    @Input() since: number | null = null;
    @Input() limit: number | null = null;
    @Input() tickEvery: number = 1;
    @Input() mode: number = TimerComponent.MODE_COUNTDOWN;
    @Input() countdownShowNegative: boolean = false;
    @Input() paused: boolean = false;
    public timer: string = '∞';
    public title: string = '∞';
    public gameOver: boolean = true;
    private tickInterval;
    private _limit: number;

    constructor() {
    }

    ngOnInit() {
        let since;
        if (null === this.since) {
            since = Math.round((new Date().getTime()) / 1000);
        } else {
            since = Math.round((new Date(this.since).getTime()) / 1000);
        }

        this._limit = since + (+this.limit);
        this.tick();

        if (!this.paused) {
            this.start();
        }
    }

    ngOnDestroy() {
        this.clearInterval();
    }

    onTimerClick(event) {
        if (event.ctrlKey) {
            return this.togglePaused();
        }

        if (this.mode === TimerComponent.MODE_COUNTDOWN) {
            return this.toggleCountdownShowNegative();
        }
    }

    tick() {
        const now = Math.round((new Date().getTime()) / 1000);
        this.gameOver = (this.limit !== null && now >= this._limit);
        let timer, isNegativeValue, forceTimer, titlePrefix = '', titleSuffix = '';

        switch (this.mode) {
            case TimerComponent.MODE_COUNTDOWN:
                const left = this._limit - now;
                isNegativeValue = left < 0;
                timer = this.formatTimer(left);

                if (isNegativeValue) {
                    titlePrefix = 'Время вышло ';
                    titleSuffix = ' назад';
                } else {
                    titlePrefix = 'Осталось: ';
                }

                if (this.gameOver && !this.countdownShowNegative) {
                    forceTimer = 'Всё...';
                }

                break;

            case TimerComponent.MODE_TIMER:
                const spent = now - this.since;
                isNegativeValue = spent < 0;
                timer = this.formatTimer(spent);

                if (isNegativeValue) {
                    titlePrefix = 'Прошло: ';
                } else {
                    titlePrefix = 'Осталось: ';
                }

                break;
        }

        this.timer = forceTimer ? forceTimer : (isNegativeValue ? '-' : '') + timer;
        this.title = [titlePrefix, timer, titleSuffix, this.paused ? ' [остановлен]' : ''].join('');
    }

    private start() {
        this.tick();
        this.tickInterval = setInterval(() => {
            this.tick();
        }, this.tickEvery * 1000);
    }

    private stop() {
        this.clearInterval();
        this.tick();
    }

    private toggleCountdownShowNegative() {
        this.countdownShowNegative = !this.countdownShowNegative;
        this.tick();
    }

    private togglePaused() {
        this.paused = !this.paused;
        if (this.paused) {
            this.stop();
        } else {
            this.start();
        }
    }

    private clearInterval() {
        if (this.tickInterval) {
            clearInterval(this.tickInterval);
        }

        this.tickInterval = null;
    }

    private formatTimer(timerValue: number, glue: string = '') {
        let timerParts = [];
        timerValue = Math.abs(timerValue);
        const days = timerValue >= 86400 ? Math.floor(timerValue / 86400) : 0;
        if (days) {
            timerParts.push(days + 'д');
        }

        let hours = timerValue >= 3600 ? Math.floor((timerValue % 86400) / 3600) : 0;
        if (hours || days) {
            let hoursPart = hours.toString();
            if (days) {
                hoursPart = ('0' + hoursPart).substr(-2);
            }

            timerParts.push(hoursPart + ':');
        }

        let minutes = timerValue >= 60 ? Math.floor((timerValue % 3600) / 60) : 0;
        let minutesPart = minutes.toString();
        if (days || hours) {
            minutesPart = ('0' + minutesPart).substr(-2);
        }

        timerParts.push(minutesPart + ':');

        let seconds = (timerValue % 60);
        let secondsPart = seconds.toString();
        secondsPart = ('0' + secondsPart).substr(-2);
        timerParts.push(secondsPart + '');

        return timerParts.join(glue);
    }
}
