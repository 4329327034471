import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class ClientService extends LaraService {

    /**     Новости    **/

    /**
     * Получить список новостей
     * @param data
     */
    public getNews(data) {
        return this.get('/client-service/news/list', data);
    }

    /**
     * Получить список новостей
     * @param id
     */
    public getNewsById(id) {
        return this.get('/client-service/news/' + id);
    }

    /**
     * Редактирование или создание новости
     * @param params
     */
    public editOrCreate(params) {
        return this.post('/client-service/news/editOrCreate', params);
    }

    /**  Рассылка писем  **/

    /**
     * Получение списка email
     * @param params
     */
    public getEmails(params) {
        return this.get('/client-service/clients-sends', params);
    }

    /**
     * Получение списка компаний
     */
    public getParentCompanies() {
        return this.get('/clients/parentCompanies');
    }

    /**
     * Загрузка файлов
     */
    public sendEmails(params) {
        return this.post('/client-service/clients-sends/send-emails', params);
    }

    /**
     * Получение членов комиссии
     * принимающих участие в акте расхождений Торг-2
     * @param params
     */
    public setCompanyCommissionChairs(params) {
        return this.post('/clients/setCompanyCommissionChairs', params)
    }

    public updateClientField(clientId, data) {
        return this.post('/clients/clientField/' + clientId, data);
    }


    getProblemCalls(data) {
        return this.post('/client-service/problem-calls', data);
    }

    getProblemCallToWork(problemId) {
        return this.post('/client-service/problem-calls/take', {problem_id: problemId});
    }

    resolvedProblemCallToWork(problemId) {
        return this.post('/client-service/problem-calls/resolved', {problem_id: problemId});
    }

    getCallQualityStat(formFilter) {
        return this.post('/client-service/statistics/call-quality-stat', formFilter);
    }

    /** Получение ссылки на запись разговора по recording_id */
    getCallRecordingByRecordingId(recordingId: string) {
        return this.get('/client-service/calls/recordings/' + recordingId)
    }
}
