import {Injectable} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrdersService} from '../service/orders.service';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class OrdersListService extends BehaviorSubject<GridDataResult> {
    constructor(
        private api: OrdersService,
        private router: Router
    ) {
        super(null);
    }

    public query(state: any): void {
        this.fetch(state)
            .subscribe(x => super.next(x));
    }

    protected fetch(state: any): Observable<GridDataResult> {
        if (this.router.url === '/admin/orders/list/ondelivery' && !state.statuses) {
            state.statuses = [4];
        } else if (this.router.url === '/admin/orders/list/onware' && !state.statuses) {
            state.statuses = [3];
        } else if (this.router.url === '/admin/orders/list' && !state.statuses) {
            state.statuses = [2, 3];
        }
        return this.api.getList(state).pipe(
            map((response) => {
                return (<GridDataResult>{
                    data: this.injectRowFlagsClassName(response.orders.data),
                    total: parseInt(response.orders.total, 10)
                })
            })
        );
    }

    protected injectRowFlagsClassName(payload) {
        return payload.map(item => {
            item._flagClassName = this.calculateRowFlagsClassName(item);

            return item;
        });
    }

    protected calculateRowFlagsClassName(item) {
        let flags = [];

        if (item.is_important) {
            if (item.is_important > 1) {
                flags.push('is_very_important');
            } else {
                flags.push('is_important');
            }
        }

        if (item.is_client_return) {
            flags.push('is_client_return');
        }

        if (item.is_expensive) {
            flags.push('is_expensive');
        }

        if (1 > flags.length) {
            return null;
        }

        if (1 === flags.length) {
            return flags;
        }

        return [
            'order-row-style-base',
            'order-row-style--' + (flags.sort().join('--')),
        ];
    }

}
