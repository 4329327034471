import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {Hub} from '../../service/models';
import {HubsService} from '../../service/hubs.service';
import {EventerService} from '../../service/eventer.service';
import {CurrentUserService} from '../../service/current-user.service';

@Component({
    selector: 'app-interval-select',
    templateUrl: './interval-select.component.html',
    styleUrls: ['./interval-select.component.scss']
})
export class IntervalSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Интервал';
    @Input() hub: Hub = null;
    @Output() onChange = new EventEmitter<number | number[]>();
    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    public hubsList = [];

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    private hubEventsSubscription: Subscription;
    @Input() hubEvents: Observable<Hub>;

    constructor(
        private api: LaraService,
        private eventerService: EventerService,
        private hubsService: HubsService,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit(): void {
        this.hubsService.get().subscribe((hubs: Hub[]) => {

            this.hubsList = hubs;
            const selectedHubId = this.hub ? this.hub.id : this.currentUserService.getCurrentHub().id;

            this.dropdownList = this.hubsList.filter(x => (x.id === selectedHubId))[0].delivery_intervals;
            this.eventerService.primaryHubChange.subscribe((hub) => {
                this.dropdownList = this.hubsList.filter(x => (x.id === hub.id))[0].delivery_intervals;
            });

            if (this.events) {
                this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
            }

            if (this.hub && this.hubEvents) {
                this.hubEventsSubscription = this.hubEvents.subscribe(data => this.onSetSelectedHub(data));
            }

        });


        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'label_with',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.id) !== -1
        })
    }

    /**
     * Смена выбранного хаба, от родителя
     * @param hub
     */
    onSetSelectedHub(hub: Hub) {
        this.dropdownList = this.hubsList.filter(x => (x.id === hub.id))[0].delivery_intervals;
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
        if (this.hubEventsSubscription) {
            this.hubEventsSubscription.unsubscribe();
            this.hubEventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.id));
    }
}
