import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderSignature} from 'app/service/models';
import {HelpersService} from '../../service/helpers.service';
import {MatCheckboxChange} from '@angular/material/checkbox/typings/checkbox';

@Component({
    selector: 'app-order-signature-card',
    templateUrl: './order-signature-card.component.html',
    styleUrls: ['./order-signature-card.component.scss'],
})
export class OrderSignatureCardComponent implements OnInit {
    @Input('signature') signature: OrderSignature;

    @Input('order_uid') order_uid: string | null = null;
    @Input('recipient_name') recipient_name: string | null = null;
    @Input('target_name') target_name: string | null = null;

    @Input('selectable') selectable: boolean = false;
    @Input('cloneable') cloneable: boolean = false;

    @Input('debug') debug: boolean = false;

    @Output() clone = new EventEmitter<number>();
    @Output() select = new EventEmitter<MatCheckboxChange>();

    public extras = [];

    constructor(
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        if (this.order_uid) {
            this.extras.push({label: 'UID', value: this.order_uid});
        }

        if (this.target_name) {
            this.extras.push({label: 'Получатель', value: this.target_name});
        }

        if (this.recipient_name) {
            this.extras.push({label: 'Фактический получатель', value: this.recipient_name});
        }
    }

    onClone(signatureId: number) {
        this.clone.emit(signatureId);
    }

    onSelectionChange(event: MatCheckboxChange) {
        this.select.emit(event);
    }
}
