import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-returnact-modal',
    templateUrl: './returnact-modal.component.html',
    styleUrls: ['./returnact-modal.component.scss']
})
export class ReturnactModalComponent implements OnInit {

    public act: any;

    constructor(
        protected app: AppComponent,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {

    }

    calcPrice() {

        let goods_price_sum = 0;
        /*
        TODO Для Марины
        this.act.orders.map(item =>{

        })
        for(let item of this.act.orders){

        }
        */

        for (let i = 0; i < this.act.orders.length; i++) {
            for (let j = 0; j < this.act.orders[i].returned_goods.length; j++) {
                goods_price_sum += this.act.orders[i].returned_goods[j].price * this.act.orders[i].returned_goods[j].count;
            }
        }

        return goods_price_sum.toFixed(2);

    }

    getTotalAmount() {
        let total = 0;
        for (let i = 0; i < this.act.orders.length; i++) {
            for (let j = 0; j < this.act.orders[i].returned_goods.length; j++) {
                total += +this.act.orders[i].returned_goods[j].count;
            }
        }

        return total;
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
