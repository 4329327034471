import {Component, OnInit} from '@angular/core';
import {User} from "../../../service/models";
import {AppComponent} from "../../../app.component";
import {CurrentUserService} from "../../../service/current-user.service";

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

    public user: User;

    public currentUser: User;

    public width;

    public index = 1;

    constructor(
        protected app: AppComponent,
        protected currentUserService: CurrentUserService
    ) {
        this.width = window.innerWidth * 0.6;
        this.currentUserService.get().subscribe((user: User) => {
            this.currentUser = user;
        });
    }

    ngOnInit() {
        if (this.user.roles) {
            this.user.roles = this.user.roles.map(x => x.description);
            this.user.roles = this.user.roles.join(', ');
        }
    }

    onClose() {
        this.app.closeDialog(this);
    }

    openTabs(index) {
        this.index = index;
    }

}
