import {Component, OnInit} from '@angular/core';
import {ApiClientInfo} from '../../../service/api_result';
import {AppComponent} from '../../../app.component';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {CurrentUserService} from '../../../service/current-user.service';
import {User} from '../../../service/models';
import {HelpersService} from '../../../service/helpers.service';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {DialogService} from '../../dialog/dialog.service';
import {LaraService} from '../../../service/lara.service';
import {ClientService} from '../../../service/client.service';

@Component({
    selector: 'app-client-dialog',
    templateUrl: './client-dialog.component.html',
    styleUrls: ['./client-dialog.component.scss'],
    providers: [ColumnResizingService]
})
export class ClientDialogComponent implements OnInit {

    public clientInfo: ApiClientInfo;

    public user: User;

    public width;

    public index = 1;

    public optionsKey = [
        'buyback', 'call', 'equipment', 'fitting', 'opening', 'packaging', 'returned_doc', 'sms',
        'cod_cash', 'cod_card', 'os',
        'return_only_rate', 'return_only_fix', 'return_combined_rate', 'return_combined_fix',
        'installation_service', 'underground_parking',
    ];

    public optionsTitle = [
        'Возврат част. отказ(%)', 'Прозвон клиента (за день до доставки)', 'Комплектация',
        'Проверка заказа/проверка электроники', 'Вскрытие заказа',
        'Упаковка', 'Возврат накладной', 'Стоимость СМС',
        'Наложенный платеж (нал) %', 'Наложенный платеж (безнал) %', 'Объявленная ценность %',
        'Коэф (клиентский возврат)', 'Фикс (клиентский возврат)',
        'Коэф (обмен)', 'Фикс (обмен)',
        'Услуги монтажа (руб)', 'Подземный паркинг (руб)',
    ];

    public isFileUploadVisible: boolean = false;
    public rights = {
        uploadFiles: false, // Загрузка файлов
    };

    constructor(
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        public helper: HelpersService,
        private alerts: DialogService,
        private api: ClientService,
    ) {
        this.width = window.innerWidth * 0.6;
        this.currentUser.get().subscribe((user: User) => {
            this.user = user
        });
    }

    ngOnInit() {
        this.checkRights();
    }

    checkRights() {
        // Загрузка файлов
        this.rights.uploadFiles = this.helper.checkPermissionsAny([
            'files:client-contract:upload',
            'files:client-return-instruction:upload',
        ]);
    }

    getKgtValue(mass) {
        return this.clientInfo.matrix.kgt.matrix[mass];
    }

    getTakeAway() {
        return this.clientInfo.matrix.take_away.length ? this.clientInfo.matrix.take_away : this.clientInfo.matrix.take_away_default;
    }

    onClose() {
        this.app.closeDialog(this);
    }

    openTabs(index) {
        this.index = index;
    }

    parkingType(type) {
        switch (type) {
            case 1 : {
                return 'Нет';
            }
            case 2 : {
                return 'Оплата всех парковок';
            }
            case 3 : {
                return 'Оплата парковок в БЦ';
            }
            default : {
                return 'Информация отсутствует'
            }
        }
    }

    editFieldDialog(title, field, value) {
        const sub = this.alerts.openEditDialog(title, value).subscribe(newValue => {
            if (newValue) {
                this.api.updateClientField(this.clientInfo.client.id, {
                    field: field,
                    value: newValue
                }).subscribe(apiResult => {
                    this.clientInfo.client[field] = apiResult[field];
                });
            }
            sub.unsubscribe();
        });
    }

    onUploadAcknowledged(uploadedFiles) {
        this.clientInfo.client.files = uploadedFiles;
    }
}
