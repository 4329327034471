import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GridDataResult, PageChangeEvent, SelectionEvent} from '@progress/kendo-angular-grid';
import {Hub, Pallet, User} from '../../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../../service/sklad.service';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {PalletEditModalComponent} from '../pallet-edit-modal/pallet-edit-modal.component';
import {HubsService} from '../../../service/hubs.service';
import {Router} from '@angular/router';
import {DialogService} from '../../../components/dialog/dialog.service';

@Component({
    selector: 'app-pallet-list',
    templateUrl: './pallet-list.component.html',
    styleUrls: ['./pallet-list.component.scss']
})
export class PalletListComponent implements OnInit {
    public gridData: GridDataResult;

    private pageTitle = 'Транспортные единицы';
    public pageSize = 20;
    public skip = 0;

    public selectedTE: string[] = [];
    public selectedType: number;
    public selectedHubOrigin: number;
    public selectedHubDestination: number;

    public selectedUser: User = null;
    @ViewChild('input_type') inputType: ElementRef;
    @ViewChild('input_status') inputStatus: ElementRef;
    @ViewChild('input_hub_destination') inputHubDestination: ElementRef;
    @ViewChild('transit_date') transitDate: ElementRef;
    @ViewChild('transit_origin_hub') transitOriginHub: ElementRef;
    @ViewChild('transit_destination_hub') transitDestinationHub: ElementRef;

    public hubs: Hub[];

    public formList: FormGroup;
    public formEdit: FormGroup;

    public palletTypes = Pallet.TYPES_DESCRIPTION;
    public palletStatuses = Pallet.STATUSES_DESCRIPTION;

    constructor(
        private title: Title,
        private api: SkladService,
        public helpers: HelpersService,
        private hubsService: HubsService,
        private app: AppComponent,
        protected dialog: DialogService,
        private router: Router
    ) {
        this.title.setTitle(this.pageTitle);

        this.formList = new FormGroup({
            inputType: new FormControl(0),
            inputStatus: new FormControl(0),
            inputHubDestination: new FormControl(0),
        });

        this.formEdit = new FormGroup({
            transitDate: new FormControl(),
            transitOriginHub: new FormControl(),
            transitDestinationHub: new FormControl(),
        });
    }

    ngOnInit() {
        this.loadTE();

        this.hubsService.get()
            .subscribe((data: Hub[]) => {
                this.hubs = data;
            })
    }

    onCreatePackClick() {
        this.app.createDialog(PalletEditModalComponent, {
            pallet: new Pallet({})
        });
    }

    public loadTE() {
        this.selectedTE = [];
        this.clearSelectionFilter();

        const filters = {
            'status': this.inputStatus.nativeElement.value,
            'type': this.inputType.nativeElement.value,
            'hub_destination_id': this.inputHubDestination.nativeElement.value,
        };

        this.api.getPackFilteredList(filters, this.pageSize, this.skip).subscribe(data => {
            this.gridData = {data: data.packs.data, total: data.packs.total};
        });
    }

    getTypeDescription(type) {
        const findedType = this.palletTypes.find(t => {
            return t.id === +type;
        })

        return findedType.name;
    }

    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {

            this.selectedUser = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadTE();
    }

    public selectionChange(event: SelectionEvent): void {
        if (event.selectedRows.length === 1 && this.selectedTE.length === 0) {
            const pallet = event.selectedRows[0].dataItem;
            this.selectedHubDestination = pallet.hub_destination_id;
            this.selectedHubOrigin = pallet.hub_origin_id;
            this.selectedType = pallet.type;

            this.formEdit.get('transitOriginHub').setValue(this.getHubName(this.selectedHubOrigin));
            this.formEdit.get('transitDestinationHub').setValue(this.getHubName(this.selectedHubDestination));
        }

        if (event.deselectedRows.length === 1 && this.selectedTE.length === 1) {
            this.clearSelectionFilter()
        }
    }

    public clearSelectionFilter() {
        this.selectedHubDestination = null;
        this.selectedHubOrigin = null;
        this.selectedType = null;
    }

    public createTransit() {
        if (!this.selectedTE) {
            this.dialog.alert('Не выбраны палеты!');
            return;
        }
        if (!this.transitDate.nativeElement.value) {
            this.dialog.alert('Выберите дату перемещения');
            return;
        }

        const query = this.formEdit.value;
        query.transit_date = this.helpers.formatDateForSQL(this.transitDate.nativeElement.value);
        query.type = this.selectedType;
        query.hub_origin_id = this.selectedHubOrigin;
        query.hub_destination_id = this.selectedHubDestination;
        query.pack_ids = this.selectedTE;

        this.api.createHubTransit(query).subscribe(data => {
            this.dialog.alert('Перемещение: ' + data.transit.id + ' создано; ' + data.message);
            this.router.navigate(['/admin/sklad/transits/' + data.transit.id]);
        })
    }

    getPrint(data) {
        // роутер на печать
        return ['/te-print', {'packId': data}]
    }

    getHubName(hubId) {
        return this.hubsService.getHubNameById(+hubId);
    }

    isDisabledCheckbox(pallet) {
        if (pallet.transit_id) {
            return true;
        }

        if (this.selectedType && this.selectedHubOrigin && this.selectedHubDestination) {
            if (this.selectedType === pallet.type
                && this.selectedHubOrigin === pallet.hub_origin_id
                && this.selectedHubDestination === pallet.hub_destination_id
            ) {
                return false;
            }

            return true;
        }

        return false;
    }
}
