import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {LaraService} from '../../../service/lara.service';
import {HelpersService} from '../../../service/helpers.service';
import {DialogService} from '../../dialog/dialog.service';
import {ZorderComponent} from '../zorder.component';
import {DaDataConfig, DaDataType} from '@kolkov/ngx-dadata';
import {environment} from '../../../../environments/environment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Zorder} from '../../../service/models';
import {NotifierService} from '../../../service/notifier.service';
import {SubstatusesService} from '../../../service/substatuses.service';

@Component({
    selector: 'app-zorder-modal',
    templateUrl: './zorder-modal.component.html',
    styleUrls: ['./zorder-modal.component.scss'],
})
export class ZorderModalComponent implements OnInit {

    /* todo: use Zorder model */
    public zorder: Zorder;
    public logs: [];
    public substatuses: [];
    public date = null;
    public width;
    public top;
    public showLogs = false;
    public checkinfo = false;
    public checkdate = false;
    public checkStatus = false;
    public edit = true;
    public updatedInfo: string;
    public opened = false;
    public changeRight = false;
    public modal = '';
    public time_begin;
    public time_after;
    public comment;
    public loading = false;
    public saveWeightLoading = false;
    public saveDateLoading = false;
    public saveStatusLoading = false;
    public locationMapVisible: boolean = true;
    public formAddress: FormGroup;
    public sendNotification: FormGroup;
    public validNewAddress = false;
    public newAddress = null;
    public zorder_lat: number = null;
    public zorder_lon: number = null;
    public zorder_mapper_id: number = null;
    public zorder_address_text: string = null;
    public zorder_recalcable: boolean = false;

    @ViewChild('input_date') inputDate: ElementRef;
    @ViewChild('inputSubstatus') inputSubstatus: ElementRef;
    @ViewChild('inputStatus') inputStatus: ElementRef;
    @ViewChild('input_weight') inputWeight: ElementRef;
    @ViewChild('input_capacity') inputCapacity: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    @Output() info = new EventEmitter<this>();

    public parent: ZorderComponent;
    public configDaData: DaDataConfig = {
        apiKey: environment.dadata_api_key,
        type: DaDataType.address,
    };
    public courierNumber = null;
    public notificationTemplates = [];
    public logReaderDisplay: boolean = false;

    constructor(
        protected app: AppComponent,
        public helpers: HelpersService,
        protected api: LaraService,
        protected dialog: DialogService,
        private changeDetectorRef: ChangeDetectorRef,
        private helper: HelpersService,
        private notifier: NotifierService,
        private substatusesService: SubstatusesService,
    ) {
        this.opened = true;
        this.width = window.innerWidth * 0.5;
        this.top = window.innerHeight * 0.1;

        this.formAddress = new FormGroup({
            new_address: new FormControl('', Validators.required),
            force_update_coords: new FormControl(false),
        });

        this.sendNotification = new FormGroup({
            courier_id: new FormControl(null, Validators.required),
            message: new FormControl(null, Validators.required),
            is_push: new FormControl(true, Validators.required),
        });
    }

    ngOnInit() {
        this.updateProps();
        this.getZorderSubstatuses();
        this.editStatus();
        this.changeRight = this.helper.checkPermissions('log:zorder-change');
        if (!this.helper.checkPermissions('log:zorder-view')) {
            this.onClose();
        }
        ;
    }

    getAddr(info) {
        this.updatedInfo = 'Новый адрес: ' + info[0] + '\nНовые координаты: ' + info[1];
        this.changeDetectorRef.detectChanges();
        console.log(this.updatedInfo);
    }

    getZorderNameStatus(status) {
        return this.helpers.getNameZOrderStatus(status);
    }

    onDateChange() {
        this.date = this.inputDate.nativeElement.value;
    }

    onClose() {
        this.opened = false;
        this.app.closeDialog(this);
        this.parent.DialogClosed();
    }

    getZorderLogs() {
        this.api.getLogsZorder(this.zorder.id).subscribe((data: any) => {
            this.logs = data;
        });
    }

    getZorderSubstatuses() {
        this.substatusesService.get(false, [2]).then(data => {
            this.substatuses = data;
        })
    }

    setDateStatus() {
        if (!this.inputDate.nativeElement.value || !this.inputSubstatus.nativeElement.value || !this.zorder.id) {
            alert('Введите все данные');
            return;
        }
        this.saveDateLoading = true;
        console.log(this.inputDate.nativeElement.value)
        this.api.setDateSubstatusZorder(this.helpers.formatDateForSQL(this.inputDate.nativeElement.value), this.inputSubstatus.nativeElement.value, this.zorder.id).subscribe(data => {
            this.updateZorderInfo()
            this.notifier.openNotifier('Изменения сохранены...');
            this.saveDateLoading = false;
        }, () => {

            this.saveDateLoading = false;
        });

    }

    setWeightCapacity() {
        if (!this.inputWeight.nativeElement.value || !this.inputCapacity.nativeElement.value || !this.zorder.id) {
            alert('Введите все данные');
            return;
        }
        const q = confirm('Провести изменения?');
        if (q) {
            this.saveWeightLoading = true;
            this.api.setWeightCapacityZorder(this.inputWeight.nativeElement.value, this.inputCapacity.nativeElement.value, this.zorder.id).subscribe(data => {
                this.updateZorderInfo()
                this.notifier.openNotifier('Изменения сохранены...');
                this.saveWeightLoading = false;
            }, () => {

                this.saveWeightLoading = false;
            });
        } else {
            alert('Отмена операции');
        }
    }

    updateZorderInfo() {
        this.api.getZorder(this.zorder.id).subscribe((data) => {
            this.zorder = data.zorder;
        })
    }

    alertDone() {
        this.updateZorderInfo();
    }

    validInfo() {
        if (!this.inputWeight.nativeElement.value || !this.inputCapacity.nativeElement.value) {
            this.checkinfo = false;
        } else {
            this.checkinfo = true;
        }
    }

    validDate() {
        if (!this.inputDate.nativeElement.value || !this.inputSubstatus.nativeElement.value || !this.zorder.id) {
            this.checkdate = false;
        } else {
            this.checkdate = true;
        }
    }

    validStatus() {
        if (!this.inputStatus.nativeElement.value || !this.inputStatus.nativeElement.value) {
            this.checkStatus = false;
        } else {
            this.checkStatus = true;
        }
    }

    validData() {
        if (this.modal === 'interval') {
            if (this.time_begin && this.time_after) {
                return true;
            }
        }
        if (this.modal === 'comment') {
            if (this.comment) {
                return true;
            }
        }
        if (this.modal === 'addressZorder') {
            return this.validNewAddress;
        }
    }

    editStatus() {
        this.edit = this.helper.checkIsAllowEditZorder(this.zorder.status);
    }

    zorderRecalc() {
        this.api.zorderRecalc(this.zorder.id).subscribe(data => {
            console.log(data);
        })
    }

    setModal(modal) {
        this.modal = modal;
        this.time_begin = '';
        this.time_after = '';
        this.comment = '';
    }

    onChangeModals(setting, e) {
        switch (setting) {
            case 'time_begin': {
                this.time_begin = e.target.value;
                break;
            }
            case 'time_after': {
                this.time_after = e.target.value;
                break;
            }
            case 'comment': {
                this.comment = e.target.value;
                break;
            }
        }
    }

    saveData() {
        if (this.modal === 'interval') {
            this.loading = true;
            const adds = {time_begin: this.time_begin, time_end: this.time_after}
            this.api.setNewDataZorder(this.zorder.id, adds).subscribe(data => {
                this.zorder = data;
                this.updateProps();
                this.loading = false;
                console.log(data);
                this.closeModal.nativeElement.click();
                this.alertDone();
            }, () => {
                this.loading = false;
            });
        }
        if (this.modal === 'comment') {
            this.loading = true;
            const adds = {comments: this.comment};
            this.api.setNewDataZorder(this.zorder.id, adds).subscribe(data => {
                this.zorder = data;
                this.updateProps();
                this.loading = false;
                console.log(data);
                this.closeModal.nativeElement.click();
                this.alertDone();
            }, () => {
                this.loading = false;
            })
        }
        if (this.modal === 'addressZorder') {
            this.loading = true;
            const adds = {
                address: this.newAddress,
                force_update_coords: this.formAddress.value.force_update_coords,
            }
            this.api.setAddressZorder(this.zorder.id, adds).subscribe(data => {
                this.zorder = data;
                this.updateProps();
                this.loading = false;
                this.closeModal.nativeElement.click();
                this.alertDone();
            }, () => {
                this.loading = false;
            });
        }
    }

    onAddressEditorMapDialogOpenedChange(dialogOpened) {
        this.locationMapVisible = !dialogOpened;
    }

    /**
     * Подписали адрес ручками, а не выбрали из списка
     * @param event
     */
    addressChange(event) {
        this.validNewAddress = false;
    }

    /**
     * Данные сменены путём выбора от дадаты
     * @param event
     */
    addressSelectChange(event) {
        this.newAddress = event.value;
        this.validNewAddress = true;
    }

    onPointCoordsUpdated(coords) {
        this.zorder_lat = coords[0];
        this.zorder_lon = coords[1];
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000})
        });
    }

    private updateProps() {
        if (null !== this.zorder.lat && null !== this.zorder.lon) {
            this.zorder_lat = this.zorder.lat;
            this.zorder_lon = this.zorder.lon;
        } else if (null !== this.zorder.warehouse.lat && null !== this.zorder.warehouse.lon) {
            this.zorder_lat = this.zorder.warehouse.lat;
            this.zorder_lon = this.zorder.warehouse.lon;
        }

        this.zorder_address_text = this.zorder.address_text || this.zorder.warehouse.address_text || null;
        this.zorder_mapper_id = this.zorder.warehouse_address_id || this.zorder.warehouse.mapper_id || null;
        this.zorder_recalcable = Zorder.isZorderRecalcable(this.zorder);
    }

    onChangeMatTabIndex(index: number) {
        switch (index) {
            case 1: /** Отправка уведомлений */
            this.showSendNotificationCourier();
                break;
            case 2: /** История */
            this.logReaderDisplay = true;
                this.getZorderLogs();
                break;
        }
    }

    private showSendNotificationCourier() {
        if (!this.zorder.courier) {
            return this.notifier.openNotifier('Курьер не назначен на заказ');
        }

        if (this.zorder.courier.courier_option.phone) {
            this.courierNumber = this.zorder.courier.courier_option.phone;
        } else if (this.zorder.courier.courier_option.mobile_phone1) {
            this.courierNumber = this.zorder.courier.mobile_phone1;
        } else {
            this.courierNumber = this.zorder.courier.courier_option.mobile_phone2;
        }
        if (!this.courierNumber) {
            return this.notifier.openNotifier('У курьера не указан телефон!');
        }
        this.sendNotification.controls.courier_id.setValue(this.zorder.courier.id);
        this.getZorderTemplates()
    }

    sendNotificationCourier() {
        this.notifier.openNotifier('Отправка уведомления курьеру...');
        this.api.sendNotificationCourierAboutZorder(this.sendNotification.value).subscribe((result) => {
            this.notifier.openNotifier(result.message, null, {class: 'success', duration: 3000});
        });
    }

    setMessageValue(message) {
        this.sendNotification.controls.message.setValue(message);
    }
    /** Получение шаблонов уведомлений */
    getZorderTemplates() {
        const query = {
            zorder_id: this.zorder.id,
        }
        const templates = this.api.getZorderTemplates(query).subscribe(data => {
            this.notificationTemplates = data;
        })
    }

    setStatus() {
        this.saveStatusLoading = true;
        this.api.setStatusZorder(this.zorder.id, this.inputStatus.nativeElement.value).subscribe((result) => {
            this.notifier.openNotifier('успешно', null, {class: 'success', duration: 3000});
            this.saveStatusLoading = false;
        }, error => {
            this.saveStatusLoading = false;
        });
    }

}
