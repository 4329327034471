import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {ShiftType} from '../../service/models';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-shift-select',
    templateUrl: './shift-select.component.html',
    styleUrls: ['./shift-select.component.scss']
})
export class ShiftSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() requiredValue;
    @Input() placeHolder = 'Смена';
    @Input() initValue;

    @Input() events: Observable<void>;
    private eventsSubscription: Subscription;

    @Output() onChange = new EventEmitter<number[]>();

    dropdownList = ShiftType.description;
    private lastItems = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: 1,
            allowSearchFilter: false
        };

        if (this.initValue) {
            this.selectedItems = this.dropdownList.filter(value => {
                return value.value === this.initValue
            });
        }

        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEvents(data));
        }
    }

    onChangeModel(event) {
        this.checkRequired(event);
        this.onChange.emit(this.selectedItems.length > 0 ? (this.selectedItems.map((item) => item.value)) : []);
    }

    private checkRequired(event) {
        if (!this.requiredValue) {
            return;
        }
        if (this.lastItems.length === 0 && event.length === 0) {
            return;
        }
        if (event.length < 1) {
            this.selectedItems = [];
            this.selectedItems.push(this.lastItems);
        }
        if (event.length === 1) {
            this.lastItems = event[0];
        }
    }

    onEvents(data) {
        switch (data.type) {
            case 'changed':
                this.selectedItems = this.dropdownList.filter(value => {
                    return data.shiftNumbers.indexOf(value.value) !== -1;
                });

                break;
        }
    }

    ngOnDestroy(): void {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }
}
