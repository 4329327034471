import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ReturnAct, UserHub} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {ReturnActService} from '../../service/return-act.service';
import {NotifierService} from '../../service/notifier.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {HubsService} from '../../service/hubs.service';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';

interface WarehouseEventFormat {
    type: string,
    value: object
}

@Component({
    selector: 'app-return-act-create',
    templateUrl: './return-act-create.component.html',
    styleUrls: ['./return-act-create.component.scss'],
})
export class ReturnActCreateComponent implements OnInit {
    public appClientWarehouseEventSubject: Subject<WarehouseEventFormat> = new Subject<WarehouseEventFormat>();
    public isLoading = false;
    public isInitClient = false;
    public isInitWarehouse = false;
    public gridData: ReturnAct[] = [];
    public formFilter: FormGroup;

    public selectedHubId: number;


    constructor(
        public api: ReturnActService,
        public notifier: NotifierService,
        public helpers: HelpersService,
        protected dialog: DialogService,
        private router: Router,
        private currentUserService: CurrentUserService,
        protected eventer: EventerService,
    ) {
        this.formFilter = new FormGroup(
            {
                client_id: new FormControl(null),
                warehouse_id: new FormControl(null),
            },
        );
        this.eventer.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit(): void {
        const hub = this.currentUserService.getCurrentHub();

        this.selectedHubId = hub.id;
        this.loadingReturnActs()
    }

    /**
     * Событие при смене клиента
     * @param clientId
     */
    onChangeClientSelect(clientId) {
        if (!this.isInitClient) {
            return this.isInitClient = true;
        }
        this.formFilter.controls.client_id.setValue(clientId.length > 0 ? clientId[0] : null);
        this.appClientWarehouseEventSubject.next({
            type: 'set-client',
            value: this.formFilter.value.client_id,
        })
    }


    /**
     * Событие при смене склада
     * @param warehouseId
     */
    onChangeWarehouseSelect(warehouseId) {
        if (!this.isInitWarehouse) {
            return this.isInitWarehouse = true;
        }
        this.formFilter.controls.warehouse_id.setValue(warehouseId.length > 0 ? warehouseId[0] : null);
        this.loadingReturnActs();
    }

    /**
     * Загрузка возвратных актов
     * @private
     */
    public loadingReturnActs() {
        this.gridData = [];

        let clientId = this.formFilter.value.client_id;
        if (!clientId) {
            clientId = 0;
        }
        let warehouseId = this.formFilter.value.warehouse_id;
        if (!warehouseId) {
            warehouseId = 0;
        }
        this.isLoading = true;
        this.api.getNewReturnActs(this.selectedHubId, clientId, warehouseId).subscribe(data => {
            this.gridData = data;
            this.isLoading = false;

        }, error => {
            this.isLoading = false;
        })
    }

    onFormReturnAct(returnActId: number) {

        const dialog = this.dialog.confirm('Перевести акт в статус Сформирован?').subscribe(result => {
            dialog.unsubscribe();
            if (result) {

                this.api.setReturnActFormed(returnActId).subscribe((data) => {
                    this.notifier.openNotifier('Акт переведен в статус Сформирован ')
                    this.loadingReturnActs()
                }, error => {
                    this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                });
            }
        });

    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubId = hub.id;
        this.loadingReturnActs()
    }


    onScan(e) {
        if (!e.target.value) {
            return;
        }

        const search = e.target.value.trim();
        this.isLoading = true;

        this.api.findOrCreateReturn(search).subscribe(data => {
            this.router.navigate([this.helpers.getReturnActEditRouteLink(data)])

        }, error => {
            this.isLoading = false;
            this.notifier.openNotifier(error.error.response.message, null, {duration: 3000});
        });
    }

}
