import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClientInfo} from "../../service/models";
import {DaDataConfig, DaDataType} from "@kolkov/ngx-dadata";
import {environment} from "../../../environments/environment";
import {Title} from "@angular/platform-browser";
import {MatrixService} from "../../service/matrix.service";

@Component({
    selector: 'app-ta-calculator',
    templateUrl: './ta-calculator.component.html',
    styleUrls: ['./ta-calculator.component.scss']
})
export class TaCalculatorComponent implements OnInit {

    public formCalculator: FormGroup;

    public selectedClient: ClientInfo;

    public result;
    public resultKeys;

    public configDaData: DaDataConfig = {
        apiKey: environment.dadata_api_key,
        type: DaDataType.address
    };

    public optionDesc = {
        base: 'Базовый тариф (сумма)',
    };

    constructor(
        private title: Title,
        private api: MatrixService
    ) {
        this.title.setTitle('Калькулятор заборов');


        this.formCalculator = new FormGroup({
            address: new FormControl('', Validators.required),
            client_id: new FormControl(),
            apikey: new FormControl((environment.cab_apikey))
        });
    }

    ngOnInit() {
    }

    public onChangeClient(client: ClientInfo = null) {
        this.selectedClient = client;
        this.formCalculator.controls['client_id'].setValue(client ? client.client_uid : null);
        this.result = false;
    }

    getOptionName(name) {
        if (this.optionDesc[name]) {
            return this.optionDesc[name];
        }
    }

    onSubmit() {
        this.result = undefined;
        this.api.calculateTakeAway(this.formCalculator.value).subscribe((data: any) => {
            this.result = data;
            this.resultKeys = Object.keys(this.result.detail);
        });
    }

}
