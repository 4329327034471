import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StatusSelectEventsService {
    private notifier = new Subject<any>();

    public notifyObservable$ = this.notifier.asObservable();

    public notify(data: any) {
        this.notifier.next(data);
    }
}
