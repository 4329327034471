import {Component, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {ClientInfo} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from "../../components/dialog/dialog.service";

@Component({
    selector: 'app-regional-orders',
    templateUrl: './regional-orders.component.html',
    styleUrls: ['./regional-orders.component.scss']
})
export class RegionalOrdersComponent implements OnInit {

    private selectedClients;
    private statuses;
    public datestart;
    public dateend;
    private belated;
    public tableData;
    public loading = false;
    @ViewChild('input_datestart') input_datestart;
    @ViewChild('input_dateend') input_dateend;

    constructor(
        protected api: LaraService,
        private helpers: HelpersService,
        private dialog: DialogService
    ) { }

    ngOnInit() {
    }

    submit() {
        if (!this.datestart || !this.dateend) {
            this.dialog.alert('Укажите все данные');
            return;
        }
        let filter: any;
        filter = {start_date: this.datestart, end_date: this.dateend};
        if (this.belated) {
            filter.belated = this.belated;
        } if (this.statuses) {
            filter.status = this.statuses;
        } if (this.selectedClients) {
            filter.client_id = this.selectedClients.id;
        }
        this.getRegionalOrders(filter);
    }

    getRegionalOrders(filter) {
        this.loading = true;
        this.api.getRegionalOrders(filter).subscribe(data => {
            this.tableData = data;
            let i = 1;
            for (const order of this.tableData.orders) {
                order.index = i++;
                order.status_name = this.helpers.getNameOrderStatus(order.status);
                order.delivery_date_formatted = order.delivery_date ?  this.helpers.formatDate(order.delivery_date) : 'Отсутствует';
                order.maxdate_formatted = order.maxdate ? this.helpers.formatDateTime(order.maxdate) : 'Отсутствует';
                order.fill = this.setStatusOrder(order);
            }
            this.loading = false;
        })
    }

    setStatusOrder(order) {
        if (order.status < 5) {
            const start = (new Date(order.delivery_date + ' 00:00:00').getTime());
            const end = (new Date(order.maxdate)).getTime();
            return this.getDaysDiff(start, end);
        }
    }

    getDaysDiff(start, end) {
        const nowdate = new Date();
        const nowstamp = nowdate.getTime();
        const diff = nowstamp - end;
        let days = Math.floor(diff / (8640 * 10000));
        const hours = Math.floor((diff / 1000 / 60 / 60) - (days * 24));
        const mins = Math.floor((diff / 1000 / 60) - (days * 24 * 60) - (hours * 60))
        if (nowdate.getDay() === 6 || nowdate.getDay() === 0) {
            if (nowdate.getDay() === 6) {
                days -= 1;
            }
            if (nowdate.getDay() === 0) {
                days -= 2;
            }
        }
        if (days !== 0) {
            return 'alert-warning';
        }
        return;
    }

    onDateChange(date) {
        date === 1 ?
            this.datestart =
                this.helpers.formatDateForSQL(this.input_datestart.nativeElement.value) :
            this.dateend =
                this.helpers.formatDateForSQL(this.input_dateend.nativeElement.value);
    }

    onSelectClient(client: ClientInfo[]) {
        this.selectedClients = client;
        console.log(this.selectedClients);
    }

    onChangeStatuses(statuses) {
        this.statuses = statuses;
    }

    onFlagChange(e) {
        this.belated = e.target.checked;
    }
}
