import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ZordersService extends LaraService {
    /** поиск заказов по фильтам */
    public getList(data) {
        return this.post('/zorder/list', data);
    }

    /** массовая отмена заказов */
    public setStatusForZorders(ids, status) {
        return this.post('/zorder/setStatus', {status: status, ids: ids});
    }

    /** массовое назначение курьера */
    public setCourierForZorders(ids, courierId, force = 0) {
        console.log("force", force);
        return this.post('/zorder/setCourier', {ids: ids, courier_id: courierId, force: force});
    }

    /** звонки по забору */
    getZorderCalls(zorderId: number) {
        return this.get('/zorder/calls/' + zorderId);
    }
}
