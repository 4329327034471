import {Component, OnInit} from '@angular/core';
import {MatrixService} from '../../service/matrix.service';
import {Title} from '@angular/platform-browser';
import {ClientInfo, MatrixKgt} from '../../service/models';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';

@Component({
    selector: 'app-kgt',
    templateUrl: './kgt.component.html',
    styleUrls: ['./kgt.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class KgtComponent implements OnInit {
    private pageTitle = 'КГТ матрица';

    public selectedClient: ClientInfo = null;

    public formMatrixList: FormArray;
    public formMatrixMass: FormArray;

    public enableSaveAfterDelete = false;

    public matrix;

    constructor(
        private route: ActivatedRoute,
        private api: MatrixService,
        private title: Title
    ) {
        this.formMatrixList = new FormArray([]);
        this.formMatrixMass = new FormArray([]);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const clientId = params.get('id');
            if (clientId) {
                this.api.getClientInfo(clientId).subscribe((client: ApiClientInfo) => {
                    this.onChangeClient(client.client);
                });
            }
        });
        this.loadMatrix();
    }

    loadMatrix() {
        this.api.getKgt(this.selectedClient ? this.selectedClient.id : null).subscribe((data: MatrixKgt) => {
            this.matrix = data.matrix;

            this.formMatrixMass = new FormArray([]);
            this.formMatrixList = new FormArray([]);
            let disabled = false;
            for (let i = 0; i < data.mass.length; i++) {
                const isEdit = !data.matrix[i].is_edit || !this.selectedClient;


                this.formMatrixMass.push(new FormControl({
                    value: data.mass[i],
                    disabled: isEdit
                }, Validators.required));

                this.formMatrixList.push(new FormGroup({

                    'is_disabled_manually': new FormControl({
                        value: data.matrix[i].is_disabled_manually || disabled,
                        disabled: isEdit || disabled
                    }),

                    'floor_lift_price': new FormControl({
                        value: data.matrix[i].floor_lift_price,
                        disabled: isEdit || !this.isFloor()
                    }),

                    'floor_manually_price': new FormControl({
                        value: data.matrix[i].floor_manually_price,
                        disabled: isEdit || !this.isFloor() || data.matrix[i].is_disabled_manually
                    }),

                    'fix_lift_price': new FormControl({
                        value: data.matrix[i].fix_lift_price,
                        disabled: isEdit || !this.isFix()
                    }),

                    'fix_manually_price': new FormControl({
                        value: data.matrix[i].fix_manually_price,
                        disabled: isEdit || !this.isFix() || data.matrix[i].is_disabled_manually
                    })
                }));

                if (data.matrix[i].is_disabled_manually) {
                    disabled = true;
                }
            }

            console.log(this.formMatrixList);
        });
    }

    public isFix() {
        return !this.selectedClient || (this.selectedClient.kgt_type === 'fix' || this.selectedClient.kgt_type === 'mixed');
    }

    public isFloor() {
        return !this.selectedClient || (this.selectedClient.kgt_type === 'floor' || this.selectedClient.kgt_type === 'mixed');
    }

    public isClientMixed() {
        return this.selectedClient && this.selectedClient.kgt_type === 'mixed';
    }

    public isEditMass(massIndex) {
        if (!this.matrix[massIndex]) {
            return true;
        }
        return this.matrix[massIndex].is_edit;
    }

    public onDeleteMass(index) {
        const nMatrix = [];
        let hasEnabled = false;
        for (let i = 0; i < this.formMatrixMass.controls.length; i++) {
            if (i === index) {
                continue;
            }
            nMatrix.push(this.matrix[i]);
            if (!this.formMatrixMass.disabled) {
                hasEnabled = true;
            }
        }
        if (this.selectedClient && hasEnabled) {
            this.enableSaveAfterDelete = true;
        } else {
            this.enableSaveAfterDelete = false;
        }
        this.matrix = nMatrix;
        this.formMatrixMass.removeAt(index);
        this.formMatrixList.removeAt(index);
    }

    public onChange(index) {
        const disabled = this.formMatrixList.controls[index].get('is_disabled_manually').value;
        for (let i = index; i < this.formMatrixMass.controls.length; i++) {
            if (!this.matrix[i] || !this.matrix[i].is_edit) {
                continue;
            }

            const check = this.formMatrixList.controls[i].get('is_disabled_manually'),
                floor = this.formMatrixList.controls[i].get('floor_manually_price'),
                fix = this.formMatrixList.controls[i].get('fix_manually_price');

            if (i !== index && check) {
                if (disabled) {
                    check.disable();
                    check.setValue(true);
                } else {
                    check.enable();
                    check.setValue(false);
                }
            }
            if (disabled) {
                if (floor) {
                    floor.disable();
                }
                if (fix) {
                    fix.disable();
                }
            } else {
                if (floor) {
                    floor.enable();
                }
                if (fix) {
                    fix.enable();
                }
            }
        }
    }

    public onChangeClient(client: ClientInfo) {

        this.selectedClient = client;

        if (client) {
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.title.setTitle(this.pageTitle);
        }
        this.loadMatrix();
    }

    public onAddMass() {
        this.formMatrixMass.push(new FormControl('', Validators.required));

        const group = new FormGroup({
            'is_disabled_manually': new FormControl(''),
        });
        if (this.isFloor()) {
            group.addControl('floor_lift_price', new FormControl('', Validators.required));
            group.addControl('floor_manually_price', new FormControl('', Validators.required));
        }
        if (this.isFix()) {
            group.addControl('fix_lift_price', new FormControl('', Validators.required));
            group.addControl('fix_manually_price', new FormControl('', Validators.required));
        }
        this.formMatrixList.push(group);
    }

    public onSubmitForm() {
        const data = {
            mass: this.formMatrixMass.value,
            matrix: this.formMatrixList.value
        };
        this.api.updateKgt(data, this.selectedClient ? this.selectedClient.id : null).subscribe((result) => {
            this.loadMatrix();
        });

    }
}
