import {Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';
import {MatrixService} from '../../service/matrix.service';
import {Title} from '@angular/platform-browser';
import {ClientInfo, ClientServiceCategory, Hub, MatrixCategoryResponse} from '../../service/models';
import {HubsService} from '../../service/hubs.service';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
    public clientUid;
    public client: ApiClientInfo;

    public selectedParentClient;

    public categoryList: MatrixCategoryResponse;
    public clientServices?: ClientServiceCategory[] = null;
    private initialServicesFormArray: FormArray = null;
    public kgtTypes = ['none', 'floor', 'fix', 'mixed'];
    public kgtTitle = ['Не носим', 'Поэтажная', 'Фиксированная', 'Комбинированная (фикс+поэтажная)'];

    public matrixes = {
        'moscow': {
            'name': 'МСК и МО',
            'default': 'A',
        },
        'spb': {
            'name': 'СПб',
            'default': 'A',
        },
        'top_delivery': {
            'name': 'Top-Delivery',
            'default': 'A',
        },
    };

    public formClient: FormGroup;

    public selectedHubs: Hub[] = [];

    public save = false;

    constructor(
        private api: MatrixService,
        private route: ActivatedRoute,
        private router: Router,
        private hubsService: HubsService,
        private title: Title,
        public helper: HelpersService,
        private datePipe: DatePipe,
    ) {

    }

    /** Контролы настроек */
    get settingsControls() {
        return (<FormGroup>this.formClient.get('settings')).controls;
    }

    ngOnInit() {
        this.api.getMatrixCategoryList().subscribe(data => this.categoryList = data);

        this.route.paramMap.subscribe((params: ParamMap) => {
            this.clientUid = params.get('id');
            if (!this.clientUid) {
                alert('Клиент не выбран!');
                this.router.navigate(['/']);
            }
            this.loadClient();
        });
    }

    loadClient() {
        this.clientServices = [];
        this.api.getClientInfo(this.clientUid).subscribe(async (client: ApiClientInfo) => {
            this.client = client;
            this.setSelectedParentClient(client.parent_client);

            let hub = await this.hubsService.find(this.client.client.default_hub_id);
            this.selectedHubs = [];
            if (hub) {
                this.selectedHubs.push(hub);
            }
            this.initForm();
            this.clientServices = this.client.services;
            this.title.setTitle('Редактирование клиента: ' + this.client.client.client_uid + ': ' + this.client.client.full_name)
        });
    }

    /**
     * Устанавливает выбранного клиента в формате селектора
     * @param client
     * @private
     */
    private setSelectedParentClient(client: ClientInfo) {
        if (!client) {
            this.selectedParentClient = null;
            return;
        }

        this.selectedParentClient = {
            id: client.id,
            client_uid: client.client_uid_old,
            full_name_uid: '[' + client.client_uid_old + '] ' + client.name
        };
    }

    initForm() {
        this.formClient = new FormGroup({
            'matrix_moscow': new FormControl(this.client.client ? this.client.client.matrix : this.matrixes.moscow.default, Validators.required),
            'matrix_spb': new FormControl(this.client.client ? this.client.client.matrix_spb : this.matrixes.spb.default, Validators.required),
            'matrix_top_delivery': new FormControl(this.client.client ? this.client.client.matrix_top_delivery : this.matrixes.top_delivery.default, Validators.required),
            'parking_payment': new FormControl(this.client.client ? this.client.client.parking_payment : 1, Validators.required),
            'is_wc_delivery': new FormControl(this.client.client ? this.client.client.is_wc_delivery : 1, Validators.required),
            'cash_only': new FormControl(this.client.client ? this.client.client.cash_only : false, Validators.required),
            'is_readonly': new FormControl(this.client.client ? this.client.client.is_readonly : false, Validators.required),
            'set_refused_orders_unopened': new FormControl(this.client.client ? this.client.client.set_refused_orders_unopened : false, Validators.required),
            'active': new FormControl(this.client.client ? this.client.client.active : false, Validators.required),
            'is_round_weight': new FormControl(this.client.client ? this.client.client.is_round_weight : true),
            'kgt_type': new FormControl(this.client.client ? this.client.client.kgt_type : 'floor', Validators.required),
            'regional_agreement': new FormControl(this.client.client ? this.client.client.regional_agreement : false),
            'redelivery_percent': new FormControl(this.client.client ? this.client.client.redelivery_percent : false),
            'is_regions_by_easy_bulky': new FormControl(this.client.client ? this.client.client.is_regions_by_easy_bulky : false),
            'is_intermediary': new FormControl(this.client.client ? this.client.client.is_intermediary : false),
            'is_recipient_required': new FormControl(this.client.client ? this.client.client.is_recipient_required : false),
            'is_places': new FormControl(this.client.client ? this.client.client.is_places : false),
            'is_calc_spb_by_km': new FormControl(this.client.client ? this.client.client.is_calc_spb_by_km : false),
            'force_orange_receipts_since': new FormControl(this.getForceOrangeReceiptsSinceValue()),
            'is_ignore_kgt': new FormControl(
                {
                    value: this.client.client ? this.client.client.is_ignore_kgt : false,
                    disabled: !this.helper.checkPermissions('clients:edit_is_ignore_kgt'),
                }),
            'is_sameday_possible': new FormControl(this.client.client ? this.client.client.is_sameday_possible : false),
            'is_sameday_take_by_cargo': new FormControl(this.client.client ? this.client.client.is_sameday_take_by_cargo : false),

            'default_hub_id': new FormControl(this.client.client ? this.client.client.default_hub_id : null, Validators.required),
            'contract_type': new FormControl(this.client.client ? this.client.client.contract_type : null, Validators.required),

            'settings': this.getSettingsFormArray(),
            'services': this.getServicesFormArray(),
        });
    }

    /** Получить тип настройки по ключу */
    getSettingType(key) {
        return this.client.setting_keys[key].type;
    }

    /** Получить форму настроек для категории услуг */
    getServiceForm(index) {
        return (this.formClient.get('services') as FormArray).at(index) as FormGroup;
    }

    /** Проверка значения доступности категории услуг */
    checkClientServiceAvailability(index) {
        const formGroup = <FormGroup>this.getServiceForm(index);

        if (formGroup.value.is_available) {
            formGroup.controls.is_displayed_in_agent_report.setValue(true);
            formGroup.controls.is_displayed_in_agent_report.disable();
        } else {
            formGroup.controls.is_displayed_in_agent_report.enable();

            const initial = this.initialServicesFormArray.at(index).value;
            formGroup.controls.is_displayed_in_agent_report.setValue(initial.is_displayed_in_agent_report);
        }
    }

    onSubmit() {
        this.save = false;

        const query = this.formClient.value;
        if (query.force_orange_receipts_since) {
            query.force_orange_receipts_since = this.datePipe.transform(query.force_orange_receipts_since, 'yyyy-MM-dd');
        }

        // отформатируем настройки (дату, например)
        Object.keys(this.client.setting_keys).forEach(key => {
            if (this.client.setting_keys[key].type === 'date') {
                query.settings[key] = this.datePipe.transform(query.settings[key], 'yyyy-MM-dd');
            }
        })

        query.parent_client_id = this.selectedParentClient ? this.selectedParentClient.id : null;

        this.api.updateClient(this.clientUid, query).subscribe((res) => {

            this.save = res;
            this.loadClient()
        });
    }

    onChangeHub(hub) {
        this.formClient.controls['default_hub_id'].setValue(hub ? hub.id : null);
    }

    private getForceOrangeReceiptsSinceValue() {
        if (!this.client.client || !this.client.client.force_orange_receipts_since) {
            return null;
        }

        const date = new Date(this.client.client.force_orange_receipts_since);

        return date;
    }

    /**
     * Возвращает контролы для настроек
     */
    private getSettingsFormArray() {
        const optionsFormArray = new FormGroup({});

        if (this.client.setting_keys) {
            Object.keys(this.client.setting_keys).forEach(key => {
                const clientSetting = this.client.settings.find(setting => {
                    return setting.setting_key.key === key;
                })

                optionsFormArray.addControl(key, new FormControl(clientSetting ? clientSetting.setting_value : null))
            })
        }

        return optionsFormArray;
    }

    /**
     * Возвращает контролы для услуг
     */
    private getServicesFormArray() {
        const servicesFormArray = new FormArray([]);
        const initialServicesFormArray = new FormArray([]);

        if (this.client.services) {
            this.client.services.map(cat => {
                servicesFormArray.push(new FormGroup({
                    service_category_id: new FormControl(cat.service_category_id),
                    is_available: new FormControl(cat.is_available),
                    is_displayed_in_agent_report: new FormControl({
                        value: cat.is_displayed_in_agent_report,
                        disabled: cat.is_available,
                    }),
                }));
                initialServicesFormArray.push(new FormGroup({
                    service_category_id: new FormControl(cat.service_category_id),
                    is_available: new FormControl(cat.is_available),
                    is_displayed_in_agent_report: new FormControl(cat.is_displayed_in_agent_report),
                }));

            });
        }

        this.initialServicesFormArray = initialServicesFormArray;
        return servicesFormArray;
    }

    /**
     * Обработчик выбора клиента
     * @param event
     */
    onSelectClient(event) {
        this.setSelectedParentClient(event);
    }
}
