import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class PersonalService extends LaraService {

    /**
     * Получение списка аккаунт менеджеров
     */
    public getAccountManagers() {
        return this.get('/personal/accounts');
    }
}
