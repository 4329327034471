import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {SkladService} from '../../service/sklad.service';
import {OrdersService} from '../../service/orders.service';
import {ActivatedRoute, ParamMap} from '@angular/router';

@Component({
    selector: 'app-invoice-print-mobile',
    templateUrl: './invoice-print-mobile.component.html',
    styleUrls: ['./invoice-print-mobile.component.scss'],
})
export class InvoicePrintMobileComponent implements OnInit {
    public orders;
    public uids = [];
    public visible = false;
    constructor(
        private route: ActivatedRoute,
        protected app: AppComponent,
        private api: SkladService,
        private apio: OrdersService,
    ) {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.uids = params['params']['uids'].split(',');
            console.warn(this.uids);
            this.getOrder();
        });
    }

    getOrder() {
        if (this.uids) {
            this.apio.getUids(this.uids).subscribe(data => {
                this.orders = data;
                console.warn(this.orders);
                this.visible = false;
            })
        }
    }

    calculateSum(allGoods) {
        let sum = 0;
        allGoods.forEach(function (goods) {
            sum += (goods.price * goods.count)
        });
        return sum;
    }

    getClass(i) {
        return i === 0 ? 'border-dotted' : '';
    }

    ngOnInit(): void {
    }
}
