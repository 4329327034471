import {Injectable} from '@angular/core';
import {LaraService} from './lara.service';

@Injectable({
    providedIn: 'root'
})

export class LoggerService extends LaraService {
    public getChangesHistory(entity: string, entityId: number) {
        return this.get('/logger/getLogs/' + entity + '/' + entityId);
    }
}
