import {LaraService} from './lara.service';
import {Observable} from 'rxjs';
import {MatrixCategoryResponse, MatrixRegion, MatrixTopDeliveryZone, OrderZone, ZoneGroup} from './models';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})

export class MatrixService extends LaraService {
    /** Список доступных регионов */
    public getMatrixRegionsList(): Observable<MatrixRegion[]> {
        return this.get('/matrix/regions');
    }

    /** Список зон для установки тарифов */
    public getMatrixZonesList(): Observable<OrderZone[]> {
        return this.get('/matrix/zones');
    }

    /** Список групп зон для установки тарифов */
    public getMatrixZoneGroupsList(hubId: number): Observable<ZoneGroup[]> {
        return this.get('/matrix/zone_groups/' + hubId);
    }

    /** Список зон Топ Деливери */
    public getTopDelieryZones(): Observable<MatrixTopDeliveryZone[]> {
        return this.get('/matrix/top_delivery/zones');
    }

    /** список доступных категорий */
    public getMatrixCategoryList(): Observable<MatrixCategoryResponse> {
        return this.get('/matrix/categories');
    }

    /** список доступных категорий */
    public getReturnTypes(): Observable<string[]> {
        return this.get('/matrix/return_tar_types');
    }

    /**
     * обновление тарифной матрицы (default or client)
     * @param regionCode
     * @param category
     * @param clientId
     */
    public updateTariffMatrix(matrix, category, regionCode, clientId = null): Observable<boolean> {
        let url = '/matrix/';
        if (clientId) {
            url += 'client/' + regionCode + '/' + clientId;
        } else {
            url += 'default/' + regionCode + '/' + category;
        }
        return this.post(url, matrix);
    }

    /**
     * обновление тарифной матрицы по объёму
     * @param matrix
     * @param category
     * @param regionCode
     * @param clientId
     */
    public updateClientTariffMatrixByAmount(matrix, category, regionCode, clientId): Observable<boolean> {
        return this.post('/matrix/clientByAmount/' + regionCode + '/' + clientId, matrix);
    }

    /**
     * Установить клиенту матрицу по объёму
     * @param params
     */
    public setMatrixByAmount(params) {
        return this.post('/matrix/client/setMatrixByAmount', params);
    }

    /**
     * тарифная матрица (default or client)
     * @param regionCode
     * @param category
     * @param clientId
     */
    public getTariffMatrix(regionCode, category, clientId = null) {
        let url = '/matrix';
        if (clientId) {
            url += '/client/' + regionCode + '/' + clientId;
        } else {
            url += '/default/' + regionCode + '/' + category;
        }
        return this.get(url);
    }

    /**
     * Тарифная матрица по клиенту и его категориям
     * @param regionCode
     * @param category
     * @param clientId
     */
    public getClientTariffMatrixByAmount(regionCode, category, clientId) {
        return this.get('/matrix/clientByAmount/' + regionCode + '/' + clientId);
    }

    /** список доступных категорий матриц по группам зон */
    public getMatrixZoneGroupsCategoryList(): Observable<string[]> {
        return this.get('/matrix/zone_groups/categories');
    }

    /**
     * Тарифная матрица по группам зон
     * @param zoneGroupId
     * @param category
     * @param clientId
     */
    public getTariffZoneGroupMatrix(zoneGroupId: number, category: string, clientId: number = null) {
        let url = '/matrix/zone_groups';
        if (clientId) {
            url += '/client/' + zoneGroupId + '/' + clientId;
        } else {
            url += '/default/' + zoneGroupId + '/' + category;
        }
        return this.get(url);
    }

    /**
     * обновление тарифной матрицы по группам зон (default or client)
     * @param zoneId
     * @param category
     * @param clientId
     */
    public updateTariffZoneGroupMatrix(matrix, category, zoneId: number, clientId = null): Observable<boolean> {
        let url = '/matrix/zone_groups/';
        if (clientId) {
            url += 'client/' + zoneId + '/' + clientId;
        } else {
            url += 'default/' + zoneId + '/' + category;
        }
        return this.post(url, matrix);
    }

    /**
     * Тарифная матрица TopDelivery
     * @param zoneId
     * @param category
     * @param clientId
     */
    public getTopDeliveryMatrix(zoneId: number, category: string, clientId: number = null) {
        let url = '/matrix/top_delivery';
        if (clientId) {
            url += '/client/' + zoneId + '/' + clientId;
        } else {
            url += '/default/' + zoneId + '/' + category;
        }
        return this.get(url);
    }

    /**
     * обновление тарифной матрицы TopDelivery
     * @param zoneId
     * @param category
     * @param clientId
     */
    public updateTopDeliveryMatrix(matrix, category, zoneId: number, clientId = null): Observable<boolean> {
        let url = '/matrix/top_delivery/';
        if (clientId) {
            url += 'client/' + zoneId + '/' + clientId;
        } else {
            url += 'default/' + zoneId + '/' + category;
        }
        return this.post(url, matrix);
    }

    /**
     * расчет тарифа
     * @param data
     */
    public calculateTariff(data) {
        data.apikey = environment.cab_apikey;
        console.log (data);
        return this.post('/local/calculate', data);
    }

    /**
     * расчет забора
     * @param data
     */
    public calculateTakeAway(data) {
        return this.post('/local/ta-calculate', data);
    }

    public updatePvzMatrix(matrix, clientId = null, pvzId = null) {
        let url = '/matrix/pvz';
        /*if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default/' + category;
        }*/
        return this.post('', {
            matrix: matrix,
            client_id: clientId,
            pvz_id: pvzId
        });
    }

    /**
     * матрица КГТ
     * @param clientId
     */
    public getKgt(clientId = null) {
        let url = '/matrix/kgt';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.get(url);
    }

    /**
     * обновление матрицы КГТ
     * @param matrix
     * @param clientId
     */
    public updateKgt(matrix, clientId = null) {
        let url = '/matrix/kgt';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.post(url, matrix);
    }

    /**
     * матрица заборов
     * @param clientId
     */
    public getTakeAway(clientId = null) {
        let url = '/matrix/takeaway';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.get(url);
    }

    /**
     * матрица заборов обновление
     * @param clientId
     */
    public updateTakeAway(matrix, clientId = null) {
        let url = '/matrix/takeaway';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.post(url, matrix);
    }

    /**
     * матрица заборов ЦФО
     * @param clientId
     */
    public getTakeAwayCfo(clientId = null) {
        let url = '/matrix/takeAwayCfo';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.get(url);
    }

    /**
     * матрица заборов ЦФО обновление
     * @param clientId
     */
    public updateTakeAwayCfo(matrix, clientId = null) {
        let url = '/matrix/takeAwayCfo';
        if (clientId) {
            url += '/client/' + clientId;
        } else {
            url += '/default';
        }
        return this.post(url, matrix);
    }

    /**
     * Категории по типу
     * @param type
     */
    public getCategories(type: string) {
        return this.get('/matrix-categories/' + type);
    }

    /**
     * Добавить категорию
     * @param params
     */
    public createCategory(params) {
        return this.post('/matrix-categories/create', params);
    }

    /**
     * Обновить категорию
     * @param params
     */
    public updateCategory(params) {
        return this.post('/matrix-categories/update', params);
    }

    /**
     * Тарифная матрица по группам зон
     * @param zoneGroupId
     * @param category
     * @param clientId
     */
    public getTariffServiceZoneGroupMatrix(zoneGroupId: number, category: string, clientId: number = null) {
        let url = '/matrix/service_zone_groups';
        if (clientId) {
            url += '/client/' + zoneGroupId + '/' + clientId;
        } else {
            url += '/default/' + zoneGroupId + '/' + category;
        }

        return this.get(url);
    }

    /**
     * обновление тарифной матрицы по группам зон (default or client)
     * @param matrix
     * @param zoneId
     * @param category
     * @param clientId
     */
    public updateTariffServiceZoneGroupMatrix(matrix, category, zoneId: number, clientId = null): Observable<boolean> {
        let url = '/matrix/service_zone_groups/';
        if (clientId) {
            url += 'client/' + zoneId + '/' + clientId;
        } else {
            url += 'default/' + zoneId + '/' + category;
        }

        return this.post(url, matrix);
    }
}
