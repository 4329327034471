import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {SkladService} from '../../../service/sklad.service';
import {AppComponent} from '../../../app.component';
import {HelpersService} from '../../../service/helpers.service';
import {NotifierService} from '../../../service/notifier.service';
import {PpAct} from '../../../service/models';
import {FileDownloadService} from '../../../http/file-download.service';

@Component({
    selector: 'app-pp-act-places',
    templateUrl: './pp-act-places.component.html',
    styleUrls: ['./pp-act-places.component.scss'],
})
export class PpActPlacesComponent implements OnInit {
    public loading = false;
    @Input('pp_act_number') public pp_act_number: string;

    constructor(
        private api: SkladService,
        private notifier: NotifierService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getPPActByNumber(this.pp_act_number).subscribe((data) => {
            this.app.createDialog(PpActPlacesDialogComponent, {
                data: data,
            });
            this.loading = false;
        }, error => {
            this.loading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }
}


@Component({
    selector: 'app-pp-act-places-dialog',
    styleUrls: ['./pp-act-places.component.scss'],
    templateUrl: 'pp-act-places-dialog.component.html',
})
export class PpActPlacesDialogComponent implements OnInit {
    public data;
    public places = [];
    public placesAdoptedBefore;

    constructor(
        private api: SkladService,
        protected app: AppComponent,
        public helpers: HelpersService,
        public notifier: NotifierService,
        public fileDownloadService: FileDownloadService,
    ) {
    }

    ngOnInit() {
        this.places = [PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(this.data.act.type) !== -1 ? this.data.act.places : this.data.act.deficit_places;
        this.placesAdoptedBefore = (this.data.adoptedBefore && this.data.adoptedBeforeCount > 0 ? this.data.adoptedBefore : null);
    }

    onClose() {
        this.app.closeDialog(this);
    }

    isExcessesAct() {
        return this.data.act.type === PpAct.TYPE_EXCESS;
    }

    makeActClosed() {
        this.notifier.openNotifier('Изменения обрабатываются...');
        this.api.makePPActsClosed({
            act_ids: [this.data.act.id],
        }).subscribe(value => {
            this.notifier.openNotifier(value.message, null, {class: 'success', duration: 3000});
            if (value.errors) {
                alert(value.errors);
            }
            this.data.act.is_closed = 1;
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }
}
