import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {EventerService} from "../../service/eventer.service";
import {CurrentUserService} from "../../service/current-user.service";
import {UserHub} from "../../service/models";

@Component({
    selector: 'app-courier-tarifficator',
    templateUrl: './courier-tarifficator.component.html',
    styleUrls: ['./courier-tarifficator.component.scss']
})
export class CourierTarifficatorComponent implements OnInit {

    public couriersTariffsList: any;
    public loading = false;
    public form: FormGroup;
    public couriers: FormGroup;
    public date: any;
    public message = false;
    public holiday: any;
    public loadingDay: any;
    public changeCourierName: string;
    public changeCourierData: string;
    public min_salary: number;
    public selectedHub: UserHub = null;
    @ViewChild('input_date') inputDate: ElementRef;

    constructor(
        public api: LaraService,
        protected helper: HelpersService,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
        });
        this.form = new FormGroup({
            inputDate: new FormControl(),
            courierTypeIds: new FormControl([]),
        })
    }

    ngOnInit() {
    }

    getDate() {
        this.date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        this.getCourierTariffs();
    }

    setTypeHeaders() {
        let old_type = 0;

        for (let i = 0; i < this.couriersTariffsList.length; i++) {
            if (this.couriersTariffsList[i].type != old_type) {
                this.couriersTariffsList[i].type_header = this.helper.getCourierType(this.couriersTariffsList[i].type);
                old_type = this.couriersTariffsList[i].type;
            }
        }
    }

    getCourierTariffs() {
        this.loadingDay = true;
        this.message = false;
        this.loading = true;
        this.couriersTariffsList = [];


        this.api.getCouriersTariffs(this.date, (this.selectedHub ? this.selectedHub.id : this.currentUserService.getCurrentHub().id)).subscribe(data => {
            this.couriersTariffsList = this.filterDataByCourierType(data);
            this.setTypeHeaders();

            this.loading = false;
        });
        this.api.courTarSettingsMinSalary().subscribe(data => {
            this.min_salary = data.map(item => item.value);
        })
        this.getHoliday(this.date);
    }

    getHoliday(date) {
        this.api.getHolidayDate(date).subscribe(data => {
            this.holiday = data;
            this.loadingDay = false;
            console.log(this.holiday);
        })
    }

    getCourierName(cr_id) {
        for (const courier of this.couriersTariffsList) {
            if (courier.id === cr_id) {
                return courier.name;
            }
        }
    }

    updatePayType(cr_id, paytype) {
        const sets = {date: this.date, courier_id: cr_id, pay_type: paytype};
        this.api.updateCourierTariff(sets).subscribe(data => {
            console.log(data);
        });
        this.message = true;
        if (paytype === 1) {
            this.changeCourierData = 'Индивидуальная';
        } else if (paytype === 0) {
            this.changeCourierData = 'Стандарт';
        }
        this.changeCourierName = this.getCourierName(cr_id);
    }

    addRoublesChange(cr_id, event: any) {
        const sets = {date: this.date, courier_id: cr_id, add_roubles: event.target.value};
        this.api.updateCourierTariff(sets).subscribe(data => {
            console.log(data);
        });
        this.changeCourierName = this.getCourierName(cr_id);
        this.changeCourierData = 'Добавлено: ' + event.target.value + ' рублей';
        this.message = true;
    }

    updateMinSalary(cr_id, event: any) {
        const sets = {date: this.date, courier_id: cr_id, min_salary: event.target.value};
        this.api.updateCourierTariff(sets).subscribe(data => {
            console.log(data);
        });
        this.changeCourierName = this.getCourierName(cr_id);
        this.changeCourierData = 'Минимальная ЗП: ' + event.target.value + ' рублей';
        this.message = true;
    }

    updateHoliday(status) {
        this.loadingDay = true;
        this.api.updateHolidayStatus(this.date, status).subscribe(data => {
            console.log(data);
            this.getHoliday(this.date);
        })
    }

    /**
     * Событие при смене типа курьера
     *
     * @param value
     */
    onChangeCourierType(value) {
        this.form.controls.courierTypeIds.setValue(value);
    }

    filterDataByCourierType(data) {
        const courierTypeIds = this.form.controls.courierTypeIds.value;

        if (courierTypeIds.length === 0) {
            return data;
        }

        return data.filter(x => courierTypeIds.indexOf(x.type) !== -1);
    }
}
