import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IFileType} from '../../service/models';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {DialogService} from '../dialog/dialog.service';
import {LaraService} from '../../service/lara.service';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnChanges {
    @Input() type: IFileType | null = null;
    @Input() availableTypes: IFileType[] | null = null;
    @Input() itemType: string;
    @Input() itemId: number;

    @Output() fileUploaded = new EventEmitter<any>();
    @Output() uploadAcknowledged = new EventEmitter<File[]>();

    @ViewChild('fileInput') public fileInput: ElementRef;

    form: FormGroup;
    loading = false;
    loadingFileTypes: boolean = false;
    isFilePresent = false;
    uploadedFiles: File[] | null = null;

    constructor(
        private api: LaraService,
        private dialog: DialogService,
        private http: HttpNoInterceptorService,
    ) {
        this.form = new FormGroup({
            type: new FormControl(null, Validators.required),
            item_type: new FormControl(null, Validators.required),
            item_id: new FormControl(null, Validators.required),
            file: new FormControl(null, Validators.required),
            description: new FormControl(null),
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.warn(changes);
        if ('undefined' !== typeof changes['type']) {
            this.form.controls.type.setValue(this.type ? this.type.id : null);
        }
        if ('undefined' !== typeof changes['itemType']) {
            this.form.controls.item_type.setValue(this.itemType);
        }
        if ('undefined' !== typeof changes['itemId']) {
            this.form.controls.item_id.setValue(this.itemId);
        }

        if (null === this.availableTypes) {
            this.loadFileTypes();
        }
    }

    onFileChange() {
        this.isFilePresent = !!this.form.controls.file.value;
    }

    onUpload() {
        this.upload();
    }

    upload() {
        this.loading = true;
        const description = this.form.controls.description.value;
        const formData = new FormData();
        formData.append('type', this.form.controls.type.value);
        formData.append('item_type', this.form.controls.item_type.value);
        formData.append('item_id', this.form.controls.item_id.value);
        formData.append('description', (description && description.length ? description : ''));
        formData.append('file', this.form.controls.file.value);

        this.http.post('/files/upload', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((responsePayload) => {
                this.uploadedFiles = responsePayload;
                this.loading = false;
                this.fileUploaded.emit();
                this.form.controls.file.reset();
                this.isFilePresent = false;
            }, (error: HttpErrorResponse) => {
                this.loading = false;
                if (!error.error.response) {
                    this.dialog.alert('Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова');
                } else {
                    this.dialog.alert(error.error.response.error);
                }
            });
    }

    onTypeChange(newType) {
        this.type = newType;
        this.form.controls.type.setValue(newType ? newType.id : null);
    }

    onAcknowledgeUploadedFiles() {
        this.acknowledgeUploadedFiles();
    }

    acknowledgeUploadedFiles() {
        this.form.controls.file.setValue(null);
        this.uploadAcknowledged.emit(this.uploadedFiles);
        this.uploadedFiles = null;
    }

    loadFileTypes() {
        this.loadingFileTypes = true;
        this.api.getFileTypes(this.itemType).subscribe(payload => {
            this.availableTypes = payload;
            this.loadingFileTypes = false;
        }, () => {
            this.loadingFileTypes = false;
        });
    }
}
