import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({providedIn: 'root'})
export class HttpNoInterceptorService {
    private http: HttpClient;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private HttpHandler: HttpBackend,
    ) {
        this.http = new HttpClient(HttpHandler);
    }

    public post(url, data, headers?) {
        return this.http.post(environment.lara_api_url + url, data, {
            responseType: 'json',
            observe: 'response',
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer ' + this.getToken()
            })
        })
    };

    public get(url, params?, headers?) {
        return this.http.get(environment.lara_api_url + url, {
            params: params,
            responseType: 'json',
            observe: 'response',
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer ' + this.getToken()
            })
        })
    };

    private getToken() {
        return this.storage.get('access_token');
    }
}
