import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgxBarcodeModule} from 'ngx-barcode';
import {ReactiveFormsModule} from '@angular/forms';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AdminLayoutComponent} from './admin/admin-layout/admin-layout.component';
import {EmptyComponent} from './layouts/empty/empty.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {PrintlabelComponent} from './storage/printlabel/printlabel.component';
import {SberbankComponent} from './sberbank/sberbank.component';
import {ClaimLetterComponent} from './storage/printer/claim-letter/claim-letter.component';
import {PrintReturnActComponent} from './storage/printer/print-return-act/print-return-act.component';
import {PrintCourierMactComponent} from './storage/printer/print-courier-mact/print-courier-mact.component';
import {ZoneRedactorComponent} from './logistics/zone-redactor/zone-redactor.component';
import {PrintMactcrComponent} from './storage/printer/print-mactcr/print-mactcr.component';
import {TePrintComponent} from './storage/printer/te-print/te-print.component';
import {TransitPrintComponent} from './storage/printer/transit-print/transit-print.component';
import {LogistMapComponent} from './components/logist-map/logist-map.component';
import {MainMapComponent} from './components/main-map/main-map.component';
import {InvoicePrintMobileComponent} from './components/invoce-print-mobile/invoice-print-mobile.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'printlabel',
        component: PrintlabelComponent,
    },
    {
        path: '',
        component: EmptyComponent,
        loadChildren: './login/login.module#LoginModule',
    },
    {
        path: 'clients',
        component: AdminLayoutComponent,
        loadChildren: './personalcabinet/clients.module#ClientsModule',
    },
    {
        path: 'finance',
        component: AdminLayoutComponent,
        loadChildren: './finance/finance.module#FinanceModule'
    },
    {
        path: 'statistics',
        component: AdminLayoutComponent,
        loadChildren: './statistics/statistics.module#StatisticsModule'
    },
    {
        path: 'admin-service',
        component: AdminLayoutComponent,
        loadChildren: './admin-service/admin-service.module#AdminServiceModule'
    },
    {
        path: 'staff',
        component: AdminLayoutComponent,
        loadChildren: './staff/staff.module#StaffModule'
    },
    {
        path: 'client-service',
        component: AdminLayoutComponent,
        loadChildren: './client-service/client-service.module#ClientServiceModule'
    },
    {
        path: 'users',
        component: AdminLayoutComponent,
        loadChildren: './users/users.module#UsersModule'
    },
    {
        path: 'couriers',
        component: AdminLayoutComponent,
        loadChildren: './couriers/couriers.module#CouriersModule'
    },
    {
        path: 'logistics',
        component: AdminLayoutComponent,
        loadChildren: './logistics/logistics.module#LogisticsModule'
    },
    {
        path: 'storage',
        component: AdminLayoutComponent,
        loadChildren: './storage/storage.module#StorageModule'
    },
    {
        path: 'pickup-point',
        component: AdminLayoutComponent,
        loadChildren: './pickup-point/pickup-point.module#PickupPointModule'
    },
    {
        path: 'services',
        component: AdminLayoutComponent,
        loadChildren: './services/services.module#ServicesModule'
    },
    {
        path: 'common',
        component: AdminLayoutComponent,
        loadChildren: './common/common.module#CommonModule'
    },
    {
        path: 'reports',
        component: AdminLayoutComponent,
        loadChildren: './reports/reports.module#ReportsModule'
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        loadChildren: './admin/admin.module#AdminModule',
    },
    {
        path: 'print-return-acts',
        component: PrintReturnActComponent,
    },
    {
        path: 'print-courier-mact',
        component: PrintCourierMactComponent,
    },
    {
        path: 'te-print',
        component: TePrintComponent,
    },
    {
        path: 'print-mactcr',
        component: PrintMactcrComponent,
    },
    {
        path: 'claim-letter',
        component: ClaimLetterComponent,
    },
    {
        path: 'invoice-print-mobile',
        component: InvoicePrintMobileComponent,
    },
    {
        path: 'sberbank',
        component: SberbankComponent,
    },
    {
        path: 'logist-map',
        component: LogistMapComponent,
    },
    {
        path: 'main-map',
        component: MainMapComponent,
    },
    {
        path: 'transit-print',
        component: TransitPrintComponent,
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BrowserModule,
        NgxBarcodeModule,
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [
        ReactiveFormsModule,
    ],
})

export class AppRoutingModule {
}
