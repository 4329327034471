import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Hub, Pallet} from '../../../service/models';
import {HubsService} from '../../../service/hubs.service';
import {Router} from '@angular/router';
import {CurrentUserService} from '../../../service/current-user.service';

@Component({
    selector: 'app-pallet-edit-modal',
    templateUrl: './pallet-edit-modal.component.html',
    styleUrls: ['./pallet-edit-modal.component.scss']
})
export class PalletEditModalComponent implements OnInit {
    public pallet: Pallet;
    public width;
    public packForm: FormGroup;
    public hubs: Hub[];
    private currentHub: number;

    public types = Pallet.TYPES_DESCRIPTION;

    constructor(
        public helpers: HelpersService,
        protected app: AppComponent,
        protected api: SkladService,
        private hubsApi: HubsService,
        private router: Router,
        private currentUserService: CurrentUserService
    ) {
        this.currentHub = currentUserService.getCurrentHub().id;

        this.width = window.innerWidth * 0.5;

        this.hubsApi.get()
            .subscribe((data: Hub[]) => {
                this.hubs = data;
            })
    }

    ngOnInit() {
        this.packForm = new FormGroup({
            'type': new FormControl(this.pallet.type),
            'hub_destination_id': new FormControl(this.pallet.hub_destination_id),
        })
    }

    onClose() {
        this.app.closeDialog(this);
    }

    formIsValid() {
        return this.packForm.valid;
    }

    onFormSubmit() {
        if (!this.formIsValid()) {
            alert('Пожалуйста, заполните форму');
            return;
        }

        if (this.pallet.id) {
            // edit pallet
            this.api.updatePackInHub({
                'id': this.pallet.id,
                'hub_destination_id': this.packForm.value['hub_destination_id'],
                'type': this.packForm.value['type']
            })
                .subscribe(data => {
                    this.router.navigate(['/admin/sklad/pallets/' + data.pack.id]);
                    this.onClose();
                });
        } else {
            // create new
            this.api.createPackInHub({
                'hub_origin_id': this.currentHub,
                'hub_destination_id': this.packForm.value['hub_destination_id'],
                'type': this.packForm.value['type']
            })
                .subscribe(data => {
                    this.router.navigate(['/admin/sklad/pallets/' + data.pack.id]);
                    this.onClose();
                });
        }
    }
}
