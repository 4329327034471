import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AddressService} from "../../service/address.service";
import {HelpersService} from "../../service/helpers.service";

@Component({
    selector: 'app-addresses-cache-reattachment',
    templateUrl: './addresses-cache-reattachment.component.html',
    styleUrls: ['./addresses-cache-reattachment.component.scss']
})
export class AddressesCacheReattachmentComponent implements OnInit {
    @ViewChild('lookupTermInput') lookupTermInput: ElementRef;

    // Я.Карта - библиотека, инстанс карты, инстанс маркера, инстанс поиска
    protected ymaps: any = null;
    protected instance: any = null;
    protected locationPoint: any = null;
    protected searchControl: any = null;
    protected mapPoints = {};
    public autoScalable: boolean = false;
    public validBounds = [];

    public pinnedAddresses: number[] = [];
    public pinnedAddressLogs: number[] = [];
    public lookupTerm: string = '';
    public lookupScope: string = 'address.id';
    public loading: boolean = false;

    public addresses = [];
    public logToAddressIndex = {};
    public addressLogsForAttach = [];
    public expandedAddressesCount = 0;
    public expandedAddresses = {};
    public possibleAddressesForAttach = [];
    public attachMessage = '';
    public selectedAddressId: number = 0;
    public selectedAddressText: string | null = null;
    public lookupResultCount: number | null = null;
    public autoPin: boolean = true;
    public selectedPointId: string | null = null;
    public canReattach: boolean = false;

    private readonly dummyAddress = {
        id: 0,
        label: 'Служебный вечно плохой адрес',
        text: null,
    }

    public map;
    public points = {};

    // address.id,address_logs.id,address.full_address,address_logs.request
    public readonly lookupScopes = [
        {
            id: 'address.id',
            label: 'ID адреса (мапера)',
        },
        {
            id: 'address_logs.id',
            label: 'ID лога',
        },
        {
            id: 'address.full_address',
            label: 'Текст адреса',
        },
        {
            id: 'address_logs.request',
            label: 'Исходный запрос',
        },
    ];

    constructor(
        private api: AddressService,
        private helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.reset();
        this.canReattach = this.helpers.checkPermissions('addresses:cache:reattach');
        this.lookupTermInput.nativeElement.focus();
    }

    private buildLookupQuery() {
        let query = {
            lookup: this.lookupTerm.trim(),
            scope: this.lookupScope,
            pin: {
                address: this.pinnedAddresses,
                address_logs: this.pinnedAddressLogs,
            }
        }

        if (this.selectedAddressId) {
            query.pin.address.push(this.selectedAddressId);
        }

        if (this.addressLogsForAttach) {
            query.pin.address_logs.push(...this.addressLogsForAttach);
        }

        console.warn(query);

        return query;
    }

    private lookup() {
        let rePinAddresses = [];
        let rePinAddressLogs = [];

        const query = this.buildLookupQuery();
        this.loading = true;
        this.lookupResultCount = null;

        this.api.lookupCacheForAddress(query).subscribe(responsePayload => {
            this.addresses = responsePayload.map(address => {
                if (this.autoPin && (-1 === this.pinnedAddresses.indexOf(address.id))) {
                    this.pinnedAddresses.push(address.id);
                }

                let knownAddress = this.addresses.find(item => {
                    return item.id == address.id;
                });

                console.info(address, knownAddress);

                address.x_expanded = false;
                address.x_pinned = this.autoPin;
                address.x_visible = true;

                if (knownAddress) {
                    address.x_expanded = knownAddress.x_expanded;
                    address.x_pinned = knownAddress.x_pinned;
                    address.x_visible = knownAddress.x_visible;
                }

                let attachedLogs = 0;
                address.address_logs = address.address_logs.map(log => {
                    log.x_pinned = (-1 !== this.pinnedAddressLogs.indexOf(log.id));
                    log.x_attach = (-1 !== this.addressLogsForAttach.indexOf(log.id));
                    if (log.x_attach) {
                        attachedLogs++;
                    }
                    return log;
                });

                address.x_attach = (attachedLogs == address.address_logs.length);

                return address;
            });

            let matchCounter = 0;
            this.addresses.map((address) => {
                if (address.x_match) {
                    matchCounter++;
                }
            });

            this.lookupResultCount = matchCounter;

            this.countExpanded();
            this.updatePossibleAddressesForAttach();
            this.updateMapPoints();

            this.loading = false;
        }, () => {
            this.loading = false;
        })
    }

    private reset() {
        this.lookupScope = 'address.id';
        this.lookupTerm = '';
        this.pinnedAddresses = [];
        this.pinnedAddressLogs = [];
        this.addressLogsForAttach = [];
        this.addresses = [];
        this.updatePossibleAddressesForAttach();
    }

    private updatePossibleAddressesForAttach() {
        if (!this.addresses.length) {
            this.possibleAddressesForAttach = [];
            return;
        }

        let newPossibleAddressesForAttach = [];
        this.addresses.map((address) => {
            newPossibleAddressesForAttach.push({
                id: address.id,
                label: address.full_address + ' [' + address.id + ']',
                text: address.full_address,
            })
        });

        newPossibleAddressesForAttach.sort((a, b) => a.label.localeCompare(b.label));
        newPossibleAddressesForAttach.unshift(this.dummyAddress);

        this.possibleAddressesForAttach = newPossibleAddressesForAttach;
    }

    public onLookup() {
        this.lookup();
    }

    public onReset() {
        this.reset();
    }

    public onTogglePinned(event, prop, obj) {
        let id = obj.id;
        obj.x_pinned = event.target.checked;

        console.warn('toggle pin', event, prop, id);

        switch (prop) {
            case 'pinnedAddresses':
            case 'pinnedAddressLogs':
                console.info('-- ', this[prop]);

                if (event.target.checked) {
                    if (-1 === this[prop].indexOf(id)) {
                        this[prop].push(id);
                    }
                } else {
                    this[prop] = this[prop].filter(pinnedId => {
                        return pinnedId != id;
                    });
                }

                console.info('++ ', this[prop]);
                break;
        }
    }


    public onToggleForAttach(event, obj) {
        console.warn('toggle attach', event, obj);

        let ids = [];
        if ('undefined' !== typeof obj['address_logs']) {
            obj.address_logs.map(log => {
                log.x_attach = event.target.checked;
                ids.push(log.id);
            });
        } else {
            ids.push(obj.id);
            obj.x_attach = event.target.checked;
        }

        console.info('??', ids);
        console.info('--', this.addressLogsForAttach);

        this.addressLogsForAttach = this.addressLogsForAttach.filter(id => {
            return ids.indexOf(id) === -1;
        });

        console.info('==', this.addressLogsForAttach);

        if (event.target.checked) {
            this.addressLogsForAttach.push(...ids);
        }

        console.info('--', this.addressLogsForAttach);

        this.composeAttachMessage();
    }


    public onToggleMapVisible(id) {
        console.warn('toggle map visible', id);
        this.addresses = this.addresses.map(address => {
            if (address.id == id) {
                address.x_visible = !address.x_visible;

                if (!address.x_visible && this.selectedPointId == address.id) {
                    this.selectedPointId = null;
                }
            }

            return address;
        });

        this.updateMapPoints();
    }

    public onSelectPoint(id) {
        this.selectPoint(id);
    }

    public selectPoint(id) {
        this.selectedPointId = id;

        Object.keys(this.mapPoints).map((pointId) => {
            const point = this.mapPoints[pointId];

            point.options.set({
                preset: pointId == this.selectedPointId
                    ? 'islands#blueStretchyIcon' : 'islands#greenStretchyIcon',
            });
        });
    }

    public onToggleExpanded(id) {
        console.warn('toggle expanded', id);
        this.addresses = this.addresses.map(address => {
            if (address.id == id) {
                address.x_expanded = !address.x_expanded;
            }

            return address;
        });

        this.countExpanded();
    }

    protected countExpanded() {
        let expandedCount = 0;
        this.addresses.map((address) => {
            if (address.x_expanded) {
                expandedCount++;
            }
        });

        this.expandedAddressesCount = expandedCount;
    }

    public onSelectNewAddress(event) {
        let id = event.target.value;
        let selectedAddress = this.possibleAddressesForAttach.find((address) => {
            return address.id == id;
        });

        if (id && selectedAddress) {
            this.selectedAddressId = selectedAddress.id;
            this.selectedAddressText = selectedAddress.text;
        } else {
            this.selectedAddressId = 0;
            this.selectedAddressText = null;
        }

        this.composeAttachMessage();
    }

    private composeAttachMessage() {
        const cnt = this.addressLogsForAttach.length;
        let message = this.helpers.getPluralEnding(cnt, ['Выбранный', 'Выбранные', 'Выбранные']) + ' '
            + cnt + ' ' + this.helpers.getPluralEnding(cnt, ['лог будет', 'лога будут', 'логов будут']) + ' '
            + this.helpers.getPluralEnding(cnt, ['подчинен', 'подчинены', 'подчинены']);

        if (this.selectedAddressId) {
            message += ' адресу "' + this.selectedAddressText + '" [' + this.selectedAddressId + ']';
        } else {
            message += ' служебному вечно плохому адресу';
        }

        this.attachMessage = message;
    }

    public onAttach() {
        this.attach();
    }

    public attach() {
        if (!this.canReattach) {
            return;
        }

        if (0 == this.selectedAddressId) {
            this.loading = true;
            this.api.attachAddressLogsToSystemReservedBadAddress(this.addressLogsForAttach).subscribe(() => {
                this.lookup();
            }, () => {
                this.lookup();
            });
        } else {
            this.loading = true;
            this.api.attachAddressLogsToAddress(this.addressLogsForAttach, this.selectedAddressId).subscribe(() => {
                this.lookup();
            }, () => {
                this.lookup();
            });
        }
    }

    /**
     * Инициализация объектов Я.Карты по её готовности
     * @param event
     */
    onMapLoad(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;

        this.updateMapPoints();
    }

    /**
     * Обновляет точки на карте
     * @protected
     */
    protected updateMapPoints() {
        if (!this.ymaps || !this.instance) {
            return;
        }

        this.addresses.map((address) => {
            if ('undefined' === typeof this.mapPoints[address.id]) {
                this.mapPoints[address.id] = new this.ymaps.GeoObject({
                        type: 'Feature',
                        properties: {
                            iconContent: 'Адрес ' + address.id,
                            pointtype: 'address'
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [address.lat, address.lon],
                        }
                    },
                    {
                        preset: 'islands#greenStretchyIcon',
                        draggable: false,
                        visible: true,
                    }
                );
            }

            let point = this.mapPoints[address.id];
            if (address.x_visible) {
                this.instance.geoObjects.add(point);
            } else {
                this.instance.geoObjects.remove(point);
                point.options.set({
                    preset: 'islands#greenStretchyIcon',
                });
            }
        });

        this.autoScale();
    }

    /**
     * Обновляет признак возможности выполнения автоподгона масштаба карты
     * @protected
     *
     * TODO Позволил себе нагло дёрнуть из редактора адреса пока над тем не проведён очередной рефакторинг
     *  Проведи же рефакторинг, Вано.
     */
    protected updateMapAutoScalable() {
        this.validBounds = this.getBoundsWithValidCoords(this.instance.geoObjects.getBounds(), true);
        this.autoScalable = (1 < this.validBounds.length);

        return this.autoScalable;
    }

    /**
     * Автоподгон масштаба и положения карты для отображения всех гео-объектов
     *
     * TODO Позволил себе нагло дёрнуть из редактора адреса пока над тем не проведён очередной рефакторинг
     *  Проведи же рефакторинг, Вано.
     */
    autoScale() {
        if (!this.updateMapAutoScalable()) {
            return;
        }

        this.instance.setBounds(this.validBounds, {
            checkZoomRange: true,
            zoomMargin: 50,
        });
    }

    /**
     * Фильтрует массив координат границ, убирая дубликаты и опционально убирая точки за пределами РФ
     * @param bounds
     * @param filterNonRussian
     *
     * TODO Позволил себе нагло дёрнуть из редактора адреса пока над тем не проведён очередной рефакторинг
     *  Проведи же рефакторинг, Вано.
     */
    protected getBoundsWithValidCoords(bounds, filterNonRussian = false) {
        // console.warn('getBoundsWithValidCoords', bounds, filterNonRussian);

        if (null === bounds) {
            return [];
        }

        let output = [];

        // грубый фильтр по границам РФ
        // https://ru.wikipedia.org/wiki/Крайние_точки_России#Крайние_точки
        // Включая острова и эксклавы
        // Северная точка — окрестности мыса Флигели, Земля Франца-Иосифа, Архангельская область[1]
        //  81°50′35″ с. ш. 59°14′22″ в. д.
        // Южная точка — не именованная на картах точка с высотой свыше 3500 м расположена в 2,2 км к востоку от горы Рагдан и к юго-западу от гор Несен (3,7 км) и Базардюзю (7,3 км), Дагестан[2]
        //  41°11′07″ с. ш. 47°46′55″ в. д.
        // Западная точка — погранзастава Нормельн[3], Балтийская коса, Калининградская область[4]
        //  54°27′45″ с. ш. 19°38′19″ в. д.
        // Восточная точка — остров Ратманова, Чукотский автономный округ[5]
        //  65°47′ с. ш. 169°01′ з. д.
        if (filterNonRussian) {
            bounds = bounds.filter(b => {
                if (null === b[0] || null === b[1]) {
                    return true;
                }

                if (b[0] < 40 || b[0] > 82) {
                    return false;
                }

                if ((b[1] >= 19 && b[1] <= 180) || (b[1] >= -180 && b[1] <= -168)) {
                    return true;
                }

                return false;
            });

            // console.info('bound without non-Russian', bounds);
        }

        output = bounds.filter(b => {
            return (-1 === output.indexOf(b));
        });

        // console.info('getBoundsWithValidCoords pre-output', output);

        if (!output.length) {
            return [];
        }

        // немного колдуем с координатами чтоб сделать хорошие границы и карта не встала зумом "в квартиру"
        let minLat = 360 + output[0][0];
        let minLon = 360 + output[0][1];
        let maxLat = minLat;
        let maxLon = minLon;

        for (let coords of output) {
            minLat = Math.min(minLat, 360 + coords[0]);
            maxLat = Math.max(maxLat, 360 + coords[0]);
            minLon = Math.min(minLon, 360 + coords[1]);
            maxLon = Math.max(maxLon, 360 + coords[1]);
        }

        let avgLat = (minLat + maxLat) / 2;
        let avgLon = (minLon + maxLon) / 2;

        // console.info('min', [minLat, minLon]);
        // console.info('max', [maxLat, maxLon]);
        // console.info('avg', [avgLat, avgLon]);

        const deltaLat = 0.005;
        const deltaLon = 0.005;

        output = [
            [
                (Math.min(minLat, avgLat - deltaLat) - 360),
                (Math.min(minLon, avgLon - deltaLon) - 360),
            ],
            [
                (Math.max(maxLat, avgLat + deltaLat) - 360),
                (Math.max(maxLon, avgLon + deltaLon) - 360),
            ]
        ];

        // console.info('getBoundsWithValidCoords output', output);

        return output;
    }

}
