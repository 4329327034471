import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SkladService} from '../../service/sklad.service';
import {DatePipe} from '@angular/common';
import {ClientInfo, OrderGood} from '../../service/models';
import {throwError} from 'rxjs';
import {HelpersService} from '../../service/helpers.service';
import {TransitVehicleModalComponent} from '../transits/transit-vehicle-modal/transit-vehicle-modal.component';
import {AppComponent} from '../../app.component';
import {ReturnOrderModalComponent} from '../return-order-modal/return-order-modal.component';

@Component({
    selector: 'app-lost-orders',
    templateUrl: './lost-orders.component.html',
    styleUrls: ['./lost-orders.component.scss'],
})
export class LostOrdersComponent implements OnInit {
    public formFilter: FormGroup;
    public query;
    public orders;

    public selectedClients: ClientInfo[];

    public dataIsLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public pageOffset: number = 0;

    constructor(
        private api: SkladService,
        private datePipe: DatePipe,
        private app: AppComponent,
        public helper: HelpersService,
    ) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        this.formFilter = new FormGroup({
            date_type: new FormControl('date_delivery'),
            date_from: new FormControl(yesterday),
            date_to: new FormControl(today),
        });
    }

    ngOnInit() {
    }

    loadOrders() {
        const query = this.formFilter.value;

        query.skip = this.pageOffset;
        query.take = this.pageSize;

        query.date_from = this.datePipe.transform(query.date_from, 'yyyy-MM-dd');
        query.date_to = this.datePipe.transform(query.date_to, 'yyyy-MM-dd');

        query.client_ids = this.selectedClients ? this.selectedClients.map(client => client.id) : [];

        this.dataIsLoading = true;
        this.api.getOrdersWithDamagedGoods(query)
            .subscribe((data) => {
                this.orders = data;
                this.currentPage = data.currentPage;

                this.dataIsLoading = false;
            }, error => {
                this.dataIsLoading = false;
            })
    }

    onDataLoadClick() {
        // сбрасываем пагинацию
        this.pageOffset = 0;
        this.loadOrders();
    }

    onPageChange(event) {
        this.pageOffset = event.skip;
        this.loadOrders();
    }

    /**
     * Обработчик изменения фильтра по клиенту
     * @param clients
     */
    onChangeClient(clients: ClientInfo[]) {
        if (clients) {
            this.selectedClients = clients;
        } else {
            this.selectedClients = [];
        }
    }

    /**
     * Общее кол-во товаров в заказе
     * @param goods
     */
    getTotalGoodsCount(goods: OrderGood[]) {
        return goods.reduce((sum, current) => sum + current.count, 0);
    }

    /**
     * Кол-во утраченных товаров
     * @param goods
     */
    getDamagedGoodsCount(goods: OrderGood[]) {
        return goods.filter(good => (good.status === OrderGood.STATUS_DAMAGED || good.status === OrderGood.STATUS_DAMAGED_AND_COMPENSATED))
            .reduce((sum, current) => sum + current.count, 0);
    }

    onOrderClick(uid: string) {
        this.app.createDialog(ReturnOrderModalComponent, {
            order_uid: uid
        }).onDestroy(data => {
            this.loadOrders();
        })
    }
}
