import {ButtonsModule, CheckboxModule, WavesModule} from 'angular-bootstrap-md';
import {ClientsRoutingModule} from './clients-routing.module';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GridModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatTabsModule} from '@angular/material';
import {NgModule} from '@angular/core';
import {NgxDaDataModule} from '@kolkov/ngx-dadata';

import {CalculatorComponent} from '../calculators/calculator/calculator.component';
import {CalculatorsTabComponent} from './../calculators/calculators-tab/calculators-tab.component';
import {EditComponent} from './edit/edit.component';
import {FooterComponent} from './footer/footer.component';
import {IndexComponent} from './index/index.component';
import {KgtComponent} from '../matrixes/kgt/kgt.component';
import {MatrixComponent} from '../matrixes/matrix/matrix.component';
import {MatrixesTabComponent} from './../matrixes/matrixes-tab/matrixes-tab.component';
import {TaCalculatorComponent} from './../calculators/ta-calculator/ta-calculator.component';
import {TakeAwayComponent} from '../matrixes/take-away/take-away.component';
import {TakeAwayCfoComponent} from '../matrixes/take-away-cfo/take-away-cfo.component';
import {MatrixHubsComponent} from '../matrixes/matrix-hubs/matrix-hubs.component';
import {MatrixTopdeliveryComponent} from '../matrixes/matrix-topdelivery/matrix-topdelivery.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatrixCategoriesComponent} from '../matrixes/matrix-categories/matrix-categories.component';
import {MatrixOrdersAmountComponent} from '../matrixes/matrix-orders-amount/matrix-orders-amount.component';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {MatrixServicesComponent} from '../matrixes/matrix-services/matrix-services.component';

@NgModule({
    declarations: [
        CalculatorComponent,
        CalculatorsTabComponent,
        EditComponent,
        FooterComponent,
        IndexComponent,
        KgtComponent,
        MatrixComponent,
        MatrixOrdersAmountComponent,
        MatrixesTabComponent,
        MatrixTopdeliveryComponent,
        MatrixHubsComponent,
        MatrixServicesComponent,
        MatrixCategoriesComponent,
        TaCalculatorComponent,
        TakeAwayComponent,
        TakeAwayCfoComponent
    ],
    imports: [
        ButtonsModule,
        ClientsRoutingModule,
        CheckboxModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        GridModule,
        WindowModule,
        HttpClientModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        NgxDaDataModule,
        ReactiveFormsModule,
        RowFilterModule,
        WavesModule,
        MatIconModule,
    ],
    exports: [
        FooterComponent,
    ],
    entryComponents: [
        CalculatorComponent,
        TakeAwayCfoComponent,
        MatrixCategoriesComponent
    ]
})

export class ClientsModule {
}
