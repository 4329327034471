import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {HelpersService} from '../../service/helpers.service';

interface TypeFormat {
    name: string,
    id: number
}

@Component({
    selector: 'app-terminal-type-select',
    templateUrl: './terminal-type-select.component.html',
    styleUrls: ['./terminal-type-select.component.scss']
})
export class TerminalTypeSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Тип терминала';
    @Input() initValue: number = null;
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: TypeFormat[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    constructor(
        private helper: HelpersService,
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }
        this.dropdownList = this.helper.TERMINAL_TYPES;
        if (this.initValue) {
            this.selectedItems = this.dropdownList.filter(x => { return x.id === this.initValue })
        }
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.id) !== -1
        })
    }
    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.id));
    }
}
