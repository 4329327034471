import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class DetmirService extends LaraService {


    // Отправить возвратный акт в детский мир
    public sendReturnAct(actId, returnDate) {
        return this.post('/detmir/sendReturnAct', {act_id: actId, return_date: returnDate});
    }

    // получить штрихкод для наклейки
    public getLabel(actId) {
        return this.post('/detmir/getLabel', {act_id: actId});
    }

    // сформировать ТЕ по выбранным актам и отправить Транспортную Единицу в детский мир
    public sendTEInfo(actIds) {
        return this.post('/detmir/sendTEInfo', {actIds: actIds});
    }

    //удалить акт из ТЕ и отправить обновленную ТЕ в детский мир
    public deleteFromTE(actId) {
        return this.post('/detmir/deleteFromTE', {act_id: actId});
    }
}
