import {Component, OnInit, ViewChild} from '@angular/core';
import {BankPaymentOrder, IAgentReportsWithPaymentsBriefItem} from '../../../finance/finance-models';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {FinanceService} from '../../../service/finance.service';
import {HelpersService} from '../../../service/helpers.service';
import {SpreadBankPaymentOrderComponent} from '../spread-bank-payment-order.component';

@Component({
    selector: 'app-spread-bank-payment-order-dialog',
    templateUrl: './spread-bank-payment-order-dialog.component.html',
    styleUrls: ['./spread-bank-payment-order-dialog.component.scss'],
})
export class SpreadBankPaymentOrderDialogComponent implements OnInit {
    public bankPaymentOrderId: number;
    public bankPaymentOrder: BankPaymentOrder;
    public parent: SpreadBankPaymentOrderComponent;
    public opened = false;
    public loading = false;
    public hasNonSeparated = false;
    public hasSeparated = false;
    public reports: IAgentReportsWithPaymentsBriefItem[] | null = null;
    public displayedReports: IAgentReportsWithPaymentsBriefItem[] | null = null;
    public allChecked = false;
    public allCheckedDisabled = false;
    public summarySumCod = 0.0;
    public summarySumCodCash = 0.0;
    public summarySumCodCashless = 0.0;
    public summarySumTransferredCod = 0.0;
    public summarySumTransferredCodCash = 0.0;
    public summarySumTransferredCodCashless = 0.0;
    public summarySumReceivedFee = 0.0;
    public summarySumRestCod = 0.0;
    public summarySumRestCodCash = 0.0;
    public summarySumRestCodCashless = 0.0;
    public summarySumRestFee = 0.0;
    public summarySumTariff = 0.0;
    public sumUsedAlready = 0.0;
    public sumToSpread = 0.0;
    public readonly COD_IGNORE = 0;
    public readonly COD_CASH = BankPaymentOrder.COD_CASH;
    public readonly COD_CARD = BankPaymentOrder.COD_CARD;
    public readonly COD_MIXED = BankPaymentOrder.COD_MIXED;
    public readonly DIRECTION_IGNORE = 0;
    public readonly DIRECTION_EXPENSE = BankPaymentOrder.DIRECTION_EXPENSE;
    public readonly DIRECTION_INCOME = BankPaymentOrder.DIRECTION_INCOME;
    public codType = this.COD_IGNORE;
    public direction = this.DIRECTION_IGNORE;
    public form: FormGroup;
    public readonly UI_STEP_REPORTS = 1;
    public readonly UI_STEP_DONE = 2;
    public uiStep = this.UI_STEP_REPORTS;
    public agentReportsIds: number[] = [];
    public onlySelectedAgentReports = false;
    @ViewChild('closeModal') private closeModal;
    private resetPartSum = false;

    constructor(
        private api: FinanceService,
        public helpers: HelpersService,
    ) {
        this.form = new FormGroup({
            payment_sum: new FormControl(0.0),
            parts: new FormArray([]),
        });
    }

    ngOnInit() {
        this.opened = true;
        this.loading = true;

        this.getDataForSpreading();
    }

    public onClose() {
        this.hideModal();
    }

    public onChangeCodType(newCodType) {
        this.codType = newCodType;
        this.resetPartSum = true;
        this.updateDisplay();
    }

    public onClickReload() {
        this.getDataForSpreading();
    }

    public onChangeAllChecked(event) {
        this.displayedReports = this.displayedReports.map(item => {
            item.x_checked = event;

            return item;
        });

        this.syncAllChecked();
        this.updatePaymentSum();
        this.syncFormParts();
    }

    public onChangeChecked(agentReportId, event) {
        this.displayedReports = this.displayedReports.map(item => {
            if (item.id === agentReportId) {
                item.x_checked = event;
            }

            return item;
        });

        this.syncAllChecked();
        this.updatePaymentSum();
        this.syncFormParts();
    }

    public onUpdatePaymentSum(targetItem = null) {
        if (targetItem && !targetItem.x_sum && targetItem.x_checked) {
            targetItem.x_checked = false;
        }

        this.updatePaymentSum();
        this.syncFormParts();
    }

    public onClickCloneSum(item) {
        this.resetPartSum = true;
        this.setPartSum(item);
        this.resetPartSum = false;
    }


    public onClickSave() {
        this.loading = true;
        this.api.spreadBankPaymentOrder(this.bankPaymentOrder.id, this.form.value).subscribe(() => {
            this.uiStep = this.UI_STEP_DONE;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    public onClickDone() {
        this.hideModal();
    }

    private hideModal() {
        this.opened = false;
        this.parent.onClose();
    }

    private getDataForSpreading() {
        this.resetPartSum = true;
        this.loading = true;

        this.api.getDataForSpreadingBankPaymentOrder(this.bankPaymentOrderId).subscribe(reports => {
            let hasNonSeparated = false;
            let hasSeparated = false;

            this.bankPaymentOrder = reports.bank_payment_order;
            this.direction = this.bankPaymentOrder.direction;
            if (this.direction === this.DIRECTION_EXPENSE) {
                this.codType = this.bankPaymentOrder.cod_type;
            }

            this.sumUsedAlready = this.bankPaymentOrder.agent_reports_parts
                .map(item => !item.deleted_at ? +item.payment_sum : 0.0)
                .reduce((sum, val) => sum + val, 0.0);

            this.reports = reports.reports.map(item => {
                item.x_checked = false;
                item.x_disabled = false;
                item.x_sum = 0.0;

                if (item.is_cod_transfer_separated_by_type) {
                    hasSeparated = true;
                }

                if (!item.is_cod_transfer_separated_by_type) {
                    hasNonSeparated = true;
                }

                return item;
            });

            this.hasSeparated = hasSeparated;
            this.hasNonSeparated = hasNonSeparated;

            this.updateDisplay();

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    private updateDisplay() {
        this.displayedReports = this.reports.map(item => {
            item.x_checked = false;
            switch (this.direction) {
                case this.DIRECTION_EXPENSE:
                    switch (this.codType) {
                        case this.COD_CASH:
                            item.x_max = item.max_transfer_cash;
                            break;
                        case this.COD_CARD:
                            item.x_max = item.max_transfer_cashless;
                            break;
                        case this.COD_MIXED:
                            item.x_max = item.max_transfer_mixed;
                            break;
                    }
                    break;
                case this.DIRECTION_INCOME:
                    item.x_max = item.max_receive_fee;
                    break;
            }

            this.setPartSum(item);

            return item;
        }).filter(item => {
            return (!this.onlySelectedAgentReports || -1 !== this.agentReportsIds.indexOf(item.id))
                && (this.direction === this.DIRECTION_INCOME
                    || (
                        (this.codType === this.COD_IGNORE)
                        || (this.codType === this.COD_MIXED
                            && !item.is_cod_transfer_separated_by_type
                        )
                        || (this.codType
                            && this.codType !== this.COD_MIXED
                            && item.is_cod_transfer_separated_by_type
                        )
                    ))
        });

        const reports = this.displayedReports;
        this.resetPartSum = false;

        this.summarySumCod = reports.map(item => item.sum_np).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumCodCash = reports.map(item => item.sum_cod_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumCodCashless = reports.map(item => item.sum_cod_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumTariff = reports.map(item => item.sum_tariff_total_with_nds).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumTransferredCod = reports.map(item => item.transferred_mixed).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumTransferredCodCash = reports.map(item => item.transferred_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumTransferredCodCashless = reports.map(item => item.transferred_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumReceivedFee = reports.map(item => item.received_fee).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumRestCod = reports.map(item => item.rest_cod_mixed).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumRestCodCash = reports.map(item => item.rest_cod_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumRestCodCashless = reports.map(item => item.rest_cod_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumRestFee = reports.map(item => item.rest_fee).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.updatePaymentSum();
        this.syncAllChecked();
        this.syncFormParts();
    }

    private setPartSum(item) {
        switch (this.direction) {
            case this.DIRECTION_EXPENSE:
                switch (this.codType) {
                    case this.COD_CASH:
                        item.x_sum = this.resetPartSum ? item.rest_cod_cash : item.x_sum;
                        break;
                    case this.COD_CARD:
                        item.x_sum = this.resetPartSum ? item.rest_cod_cashless : item.x_sum;
                        break;
                    case this.COD_MIXED:
                        item.x_sum = this.resetPartSum ? item.rest_cod_mixed : item.x_sum;
                        break;
                    default:
                        item.x_sum = 0.0;
                }
                break;

            case this.DIRECTION_INCOME:
                item.x_sum = item.rest_fee;
                break;

            default:
                item.x_sum = 0.0;
        }

        item.x_disabled = !item.x_sum;
        if (item.x_disabled) {
            item.x_checked = false;
        }
    }

    private updatePaymentSum() {
        const paymentSum = this.displayedReports
            .map(item => item.x_checked ? item.x_sum : 0.0)
            .reduce((sum, val) => sum + val, 0.0);

        this.form.controls.payment_sum.setValue(paymentSum);
        this.sumToSpread = this.bankPaymentOrder.payment_sum - (this.sumUsedAlready + paymentSum);
    }

    private syncAllChecked() {
        const total = this.displayedReports.length;
        const checked = this.displayedReports.filter(item => item.x_checked).length;
        const withSum = this.displayedReports.filter(item => item.x_sum).length;

        this.allChecked = (total === checked);
        this.allCheckedDisabled = !withSum;
    }

    private syncFormParts() {
        while ((this.form.controls.parts as FormArray).length !== 0) {
            (this.form.controls.parts as FormArray).removeAt(0)
        }

        this.displayedReports.map(item => {
            if (!item.x_checked || !item.x_sum) {
                return;
            }

            (this.form.controls.parts as FormArray).push(new FormGroup({
                agent_report_id: new FormControl(item.id),
                payment_sum: new FormControl(item.x_sum),
            }));
        });
    }
}
