import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Hub, OrderZone, User} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {AutoCompleteComponent} from '@progress/kendo-angular-dropdowns';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {HelpersService} from '../../service/helpers.service';
import {Observable, Subscription} from "rxjs";

@Component({
    selector: 'app-zone-select',
    templateUrl: './zone-select.component.html',
    styleUrls: ['./zone-select.component.scss']
})
/* дока: https://github.com/nileshpatel17/ng-multiselect-dropdown#readme */
export class ZoneSelectComponent implements OnInit {
    @ViewChild('autocomplete') public autocomplete: AutoCompleteComponent;
    @Input() public selectedZones: OrderZone[] = [];
    @Input() public selectedZone: OrderZone = null;

    @Output() onChange = new EventEmitter<OrderZone | OrderZone[]>();
    @Input() protected multi: boolean = true;
    @Input() protected disabled: boolean = false;

    // тип зоны - 1 курьерская, 2 клиентская
    @Input() public zone_type = 1;

    /* хабы по которым делаем фильтрацию */
    @Input() public filterIncludeHubs: Array<number | Hub> = [];

    /* события и подписки - чтоб слушаться родителей */
    private eventsSubscription: Subscription;
    @Input() events: Observable<void>;


    public settings: IDropdownSettings = {};
    public zoneList = [];
    public zoneListFiltered = [];

    constructor(
        private api: LaraService,
        private helper: HelpersService
    ) {

    }


    ngOnInit() {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEvents(data));
        }
        this.settings = {
            idField: 'id',
            textField: 'zone_id',
            allowSearchFilter: true,
            singleSelection: !this.multi,
            enableCheckAll: this.multi,
            itemsShowLimit: 4,
            closeDropDownOnSelection: this.multi,
            searchPlaceholderText: 'искать',
            noDataAvailablePlaceholderText: 'ничего не найдено',
            defaultOpen: false
        };
        /* грузим список направлений */
        this.helper.getZones(this.zone_type).subscribe((zones: OrderZone[]) => {
            this.zoneList = zones;
            this.zoneList.sort(function (a, b) {
                return a.zone_id - b.zone_id;
            })
            for (const zone of this.zoneList) {
                zone.zone_id = zone.zone_id.toString();
            }

            return this.applyHubFilter();
        });
    }

    onEvents(data) {

        switch (data.type) {
            case 'filterIncludeHubsUpdated':
                this.filterIncludeHubs = data.filterIncludeHubs;
                return this.applyHubFilter();
        }
    }

    applyHubFilter() {
        this.zoneListFiltered = this.zoneList.filter((zone) => {

            return !this.filterIncludeHubs.find((item: Hub) => {
                return item.id !== zone.hub_id;
            });
        });

        this.helper.filterUsersListByHubsList(this.zoneList, this.filterIncludeHubs);
        this.onChange.emit(this.multi ? this.selectedZones : this.selectedZone);
    }


    onChangeZone() {
        this.onChange.emit(this.multi ? this.selectedZones : this.selectedZone);
    }

    onSelectItem(event) {
        const zone = this.findModel(event.zone_id);
        if (this.multi) {
            this.selectedZones.push(zone);
        } else {
            this.selectedZone = zone;
        }
        this.onChangeZone();
    }

    onSelectAll() {
        this.selectedZones = this.zoneList;
        this.onChangeZone();
    }

    onDeSelect(event) {
        const zone = this.findModel(event.zone_id);
        if (this.multi) {
            this.selectedZones = this.selectedZones.filter((item: OrderZone) => {
                return item.id !== zone.id;
            });
        } else {
            this.selectedZone = null;
        }
        this.onChangeZone();
    }

    onDeSelectAll() {
        this.selectedZones = [];
        this.selectedZone = null;
        this.onChangeZone();
    }

    protected findModel(id) {

        const list = this.zoneList.filter(item => {
            return item.zone_id === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }
}
