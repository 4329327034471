import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AssemblingSession, AssemblingTaskOrderPlace, Order, ShippingQueue} from '../../service/models';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../service/helpers.service';
import {StorageAssemblingService} from '../../service/storage-assembling.service';

enum UIScreens {
    SCREEN_COURIER_SELECT = 0,
    SCREEN_BRIEF = 1,
    SCREEN_ASSEMBLING_LIST = 2,
    SCREEN_DOCS = 3,
    SCREEN_MESSAGE = 4,
}

interface IStorageAssemblingResponse {
    queue: ShippingQueue;
    session?: AssemblingSession;
    assembling_task__orders_places?: AssemblingTaskOrderPlace[][];
    is_already_scanned?: boolean;
    order_with_multiple_places?: boolean;
    search?: any;
    error?: any;
}


@Component({
    selector: 'app-hhc-courier-assembling',
    templateUrl: './hhc-courier-assembling.component.html',
    styleUrls: ['./hhc-courier-assembling.component.scss'],
})
export class HhcCourierAssemblingComponent implements OnInit {
    @ViewChild('barCodeInput') public barCodeInput: ElementRef;

    public formSettings: FormGroup;
    public formScanner: FormGroup;

    public isInitial = false;
    public isBriefExpanded = false;
    public messagesToAcknowledge = [];
    public isExcludeMode = false;

    public loading = false;
    public courierName?: string = null;
    public courierNameFull?: string = null;
    public courierOldId?: number = null;
    public courierUserId?: number = null;
    public assemblingGateId?: number = null;
    public assemblingGateNum?: number = null;
    public loadingGateId?: number = null;
    public loadingGateNum?: number = null;

    public ordersCountTotal = 0;
    public ordersCountScanned = 0;
    public currentShelf = -1;
    public currentShelfTotal = 0;
    public currentShelfScanned = 0;

    public finishScreenData: any = null;

    public courierDocAttorneyLink = null;
    public courierDocLiabilityActLink = null;
    public courierDocRouteListLink = null;

    private negativeSound = null;
    private notifySound = null;
    private positiveSound = null;

    public initialAssemblingGateId = null;
    public initialLoadingGateId = null;

    public shippingQueue?: ShippingQueue = null;
    public assemblingSession?: AssemblingSession = null;
    public assemblingTaskOrdersPlaces = [];

    public assemblingTaskOrdersPlacesChanged = null;
    public isComplete = false;

    public overallOrdersCount = 0;
    public overallPlacesCount = 0;
    public assembledOrdersCount = 0;
    public assembledPlacesCount = 0;
    public rejectedOrdersCount = 0;
    public rejectedPlacesCount = 0;
    public excludedOrdersCount = 0;
    public excludedPlacesCount = 0;

    public uiScreen = UIScreens.SCREEN_COURIER_SELECT;
    public readonly uiScreens = UIScreens;

    private sortMetrics = {};


    constructor(
        public api: StorageAssemblingService,
        private datePipe: DatePipe,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.formSettings = new FormGroup({
            oldCourierId: new FormControl('', Validators.required),
        });

        this.formScanner = new FormGroup({
            barCode: new FormControl('', Validators.required),
        });

        this.negativeSound = new Audio();
        this.negativeSound.src = '../../../assets/sounds/negative-compressed.mp3';
        this.negativeSound.load();

        this.notifySound = new Audio();
        this.notifySound.src = '../../../assets/sounds/notify-compressed.mp3';
        this.notifySound.load();

        this.positiveSound = new Audio();
        this.positiveSound.src = '../../../assets/sounds/positive-compressed.mp3';
        this.positiveSound.load();

        this.barCodeInputFocus.bind(this);
    }

    /**
     * Деактивация поля ввода штрихкода
     * @private
     */
    private disableBarCodeInput() {
        this.formScanner.controls.barCode.disable();
    }

    /**
     * Активация поля ввода штрихкода
     * @private
     */
    private enableBarCodeInput() {
        this.formScanner.controls.barCode.enable();
    }

    /**
     * Определение класса элемента списка заказов
     * @param item
     * @param type
     */
    getItemClassName(item, type) {
        const status = 'order' === type ? item._order_overall_status : item.status;
        const replay = 'order' === type ? item._order_already_scanned : item._already_scanned;
        const recent = 'order' === type ? item._order_recently_scanned : item._recently_scanned;

        if (replay) {
            if (item.should_be_excluded) {
                return 'bg-dark text-warning';
            }

            return 'bg-dark text-success';
        }

        if (recent) {
            if (item.should_be_excluded) {
                return 'bg-secondary text-warning';
            }

            return 'bg-secondary text-success';
        }

        if (item.should_be_excluded) {
            /* надо оставить, но был добавлен */
            if (status === 2) {
                return 'bg-danger text-white';
            }

            /* надо оставить и так или иначе исключен */
            if (status === 3 || status === 4) {
                return 'bg-white text-success';
            }

            /* надо оставить и ещё не сканировали */
            if (status === 1) {
                return 'bg-white text-muted';
            }
        }

        /* собран на маршрут */
        if (status === 2) {
            return 'bg-success';
        }

        /* отложили, но нас не просили */
        if (status === 3 || status === 4) {
            return 'bg-warning';
        }

        /* клиентский возврат */
        if ('barcode' === type && item.is_client_return > 0) {
            return 'client-return';
        }

        if ('order' === type && item.order.is_client_return === Order.CLIENT_RETURN_ONLY) {
            return 'client-return';
        }

        return '';
    }

    /**
     * Получение курьера из очереди
     * @param oldCourierId
     * @private
     */
    private beginAssembling(oldCourierId) {
        this.loading = true;
        this.isInitial = true;

        this.api.beginAssembling(oldCourierId).subscribe((response) => {
            this.processResponse(response);
            this.uiScreen = UIScreens.SCREEN_BRIEF;

            this.isInitial = false;

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    private processResponse(response) {
        if (this.isInitial) {
            this.shippingQueue = response.queue;
            this.courierUserId = response.queue.courier_id;
            this.courierOldId = response.queue.courier.courier_option.old_courier_id;
            this.courierName = response.queue.courier.name;
            this.courierNameFull = response.queue.courier.courier_option.full_name;

            this.initialAssemblingGateId = response.queue.assembling_gate_id;
            this.assemblingGateId = response.queue.assembling_gate_id;
            this.initialLoadingGateId = response.queue.loading_gate_id;
            this.loadingGateId = response.queue.loading_gate_id;

            if (response.session) {
                this.assemblingSession = response.session;
            }
        }

        if (response.queue.assembling_gate_id) {
            this.assemblingGateNum = response.queue.assembling_gate.num;
        }

        if (response.queue.loading_gate_id) {
            this.loadingGateNum = response.queue.loading_gate.num;
        }

        if (response.assembling_task__orders_places) {
            this.processTaskOrdersPlaces(response.assembling_task__orders_places);
        }

        if (response.order_with_multiple_places) {
            this.messagesToAcknowledge.push(['Следует сканировать отдельные места заказа']);
        } else if (response.search && response.search.order) {
            this.inspectScannedOrder(response.search.order);
        }

        if (response.error) {
            this.messagesToAcknowledge.push([response.error]);
        }

        if (response.search) {
            if (response.search.barcode) {
                this.assemblingTaskOrdersPlaces.map(places => {
                    places.map(place => {
                        if (place.place_id === response.search.barcode.id) {
                            place._recenty_scanned = true;
                            this.scrollToRecentlyScanned('barcode', response.search.barcode.id);
                        }
                    });
                });
            } else if (response.search.order) {
                this.assemblingTaskOrdersPlaces.map(places => {
                    if (places[0].order_id === response.search.order.id) {
                        places[0]._order_recently_scanned = true;
                        this.scrollToRecentlyScanned('order', response.search.order.id);
                    }
                });
            }
        }

        if (response.is_already_scanned) {
            if (response.search.barcode) {
                this.assemblingTaskOrdersPlaces.map(places => {
                    places.map(place => {
                        if (place.place_id === response.search.barcode.id) {
                            place._already_scanned = true;
                        }
                    });
                });
            } else if (response.search.order) {
                this.assemblingTaskOrdersPlaces.map(places => {
                    if (places[0].order_id === response.search.order.id) {
                        places[0]._order_already_scanned = true;
                    }
                });
            }
        }

        if (!this.isInitial && this.assemblingTaskOrdersPlacesChanged) {
            this.messagesToAcknowledge.push(['Список заказов для сборки был обновлён']);
        }

        if (!this.isInitial && this.initialLoadingGateId !== this.loadingGateId) {
            const messageParts = ['Изменились ворота погрузки:'];
            if (null !== this.loadingGateId || null !== this.assemblingGateId) {
                messageParts.push('экипаж прибудет на ворота ' + (this.loadingGateNum || this.assemblingGateNum));
            }

            this.messagesToAcknowledge.push([
                messageParts.join(' '),
                () => {
                    this.rememberGates();
                },
            ]);
        }

        this.updateAssemblingStats();
        this.detectMessageToAcknowledge();
    }

    protected scrollToRecentlyScanned(type, id) {
        const el = document.querySelector(`[data-ref="${type}-${id}"]`);
        const ch = document.querySelector('#common-header');

        if (el && ch) {
            const headerOffset = ch.clientHeight;
            const elementPosition = el.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
            });
        }
    }

    private composeListSignature(list) {
        const knownOrdersIds = [];
        const knownPlacesIds = [];

        list.map(places => {
            places.map(place => {
                if (-1 === knownOrdersIds.indexOf(place.order_id)) {
                    knownOrdersIds.push(place.order_id + '/' + (place._order_should_be_excluded || 0));
                }

                if (-1 === knownPlacesIds.indexOf(place.place_id)) {
                    knownPlacesIds.push(place.place_id + '/' + (place.should_be_excluded || 0));
                }
            });
        });

        console.warn([knownOrdersIds, knownPlacesIds]);

        return [
            knownOrdersIds.sort().join(),
            knownPlacesIds.sort().join(),
        ];


    }

    /**
     * Генерация метрики сортировки
     * @param firstPlace
     * @private
     */
    private getSortMetric(firstPlace) {
        if ('undefined' === typeof this.sortMetrics[firstPlace.order_id]) {
            const shelf = (firstPlace.order.zone && firstPlace.order.zone.shelf) ? firstPlace.order.zone.shelf : 0;

            this.sortMetrics[firstPlace.order_id] =
                (1000000 * (firstPlace._order_overall_status || 0)) +
                (100000 * (1 - (firstPlace.order.option.is_bulky || 0))) +
                (1 * shelf);
        }

        return this.sortMetrics[firstPlace.order_id];
    }

    private processTaskOrdersPlaces(taskOrdersPlaces) {
        const [knownOrders, knownPlaces] = this.composeListSignature(this.assemblingTaskOrdersPlaces);
        const orders = [];
        taskOrdersPlaces.map(placesOfOrder => {
            placesOfOrder = placesOfOrder.sort((a, b) => {
                return a.place.place_num - b.place.place_num;
            });

            const orderStatuses = [];

            placesOfOrder.map((item: AssemblingTaskOrderPlace) => {
                if (-1 === orderStatuses.indexOf(item.status)) {
                    orderStatuses.push(item.status);
                }

                item._recently_scanned = false;
                item._already_scanned = false;
            });

            if (1 === orderStatuses.length) {
                placesOfOrder[0]._order_overall_status = orderStatuses[0];
            }

            placesOfOrder[0]._order_recently_scanned = false;
            placesOfOrder[0]._order_already_scanned = false;
            placesOfOrder[0]._order_sort_metric = this.getSortMetric(placesOfOrder[0]);

            orders.push(placesOfOrder);
        });

        this.assemblingTaskOrdersPlaces = orders;
        this.sortList();

        const [currentOrders, currentPlaces] = this.composeListSignature(this.assemblingTaskOrdersPlaces);

        this.assemblingTaskOrdersPlacesChanged = (currentOrders !== knownOrders || currentPlaces !== knownPlaces);
    }

    /**
     *
     * @private
     */
    private rememberGates() {
        this.initialAssemblingGateId = this.shippingQueue.assembling_gate_id;
        this.initialLoadingGateId = this.shippingQueue.loading_gate_id;
    }

    /**
     * Обработка клика по кнопке начала сборки
     */
    onClickBeginAssembling() {
        if (0 <= this.formSettings.value.oldCourierId) {
            this.beginAssembling(+this.formSettings.value.oldCourierId);
        } else {
            this.beginAssembling(0);
        }
    }

    /**
     * Обработка клика по кнопке отмены сборки
     */
    onClickBreakAssembling() {
        if (confirm('Прервать сборку?')) {
            this.breakAssembling();
        }
    }

    /**
     * Обработка клика по кнопке перехода к списку заказаов - скрываем бриф
     */
    onClickHideBrief() {
        this.uiScreen = UIScreens.SCREEN_ASSEMBLING_LIST;
        this.barCodeInputFocusDelayed(true);
    }

    /**
     * Обработка клика по заголовку списка заказов - показываем бриф
     */
    onClickShowBrief() {
        this.uiScreen = UIScreens.SCREEN_BRIEF;
    }

    /**
     * Обработка клика по переключателю режима сканирования
     */
    onClickToggleExcludeMode() {
        this.isExcludeMode = !this.isExcludeMode;
    }

    /**
     * Отложенная фокусировка в поле ввода штрихкода
     * @param reset
     * @private
     */
    private barCodeInputFocusDelayed(reset) {
        setTimeout(() => {
            this.barCodeInputFocus(reset);
        }, 500);
    }

    /**
     * Прекращение сборки
     * @private
     */
    private breakAssembling() {
        this.loading = true;
        this.api.breakAssembling(this.shippingQueue.id, this.assemblingSession.id)
            .subscribe(() => {
                this.loading = false;
                this.reset();
            }, () => {
                this.loading = false;
            });
    }

    /**
     * Обработка клика по кнопке завершения сборки
     */
    onClickFinishAssembling() {
        this.checkCanFinishAssembling();
    }

    /**
     * Завершение сборки с проверкой возможности её завершить
     * @private
     */
    private checkCanFinishAssembling() {
        if (!this.isComplete) {
            this.playNegativeSound();
            alert('Не все заказы полностью отсканированы!');
            return;
        }

        if (this.overallOrdersCount === this.assembledOrdersCount || confirm('Не все заказы отсканированы! Завершить сборку?')) {
            this.finishAssembling();
        }
    }

    /**
     * Завершение сборки
     * @private
     */
    private finishAssembling() {
        this.uiScreen = UIScreens.SCREEN_DOCS;

        this.courierDocAttorneyLink = null;
        this.courierDocLiabilityActLink = null;
        this.courierDocRouteListLink = null;

        this.loading = true;
        this.api.finishAssembling(this.shippingQueue.id, this.assemblingSession.id).subscribe(response => {
            const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

            this.courierDocRouteListLink = [
                '/couriers/route-list', {
                    date: today,
                    courier_id: this.courierUserId,
                    shift_number: 1,
                },
            ];

            if (response.courier_act_id) {
                this.courierDocLiabilityActLink = [
                    '/print-courier-mact', {
                        mactId: response.courier_act_id,
                    },
                ];
            }

            if (response.zorders_count) {
                this.courierDocAttorneyLink = [
                    '/power-of-attorney', {
                        date: today,
                        courier: this.courierUserId,
                    },
                ];
            }

            this.loading = false;
            this.uiScreen = UIScreens.SCREEN_DOCS;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Сканирование штрихкода
     * @param barCode
     * @private
     */
    private scanBarcode(barCode) {
        this.loading = true;

        this.api[this.isExcludeMode ? 'uncheckBarcode' : 'checkBarcode'](this.shippingQueue.id, this.assemblingSession.id, {
            barCode,
        }).subscribe(response => {
            this.loading = false;

            this.processResponse(response);

            if (!response.order_with_multiple_places) {
                if (response.search) {
                    this.barCodeInputFocusDelayed(true);
                    this.playPositiveSound();
                }
            }
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Дополнительное исследование отсканированного заказа
     * @param order
     * @private
     */
    private inspectScannedOrder(order) {
        const taskPlaces = this.assemblingTaskOrdersPlaces.find(item => {
            return (item[0].order_id === order.id);
        });

        const shelf = (taskPlaces[0].order.zone && taskPlaces[0].order.zone.shelf) ?
            taskPlaces[0].order.zone.shelf : -1;

        if (this.currentShelf !== shelf) {
            this.currentShelf = shelf;
        }
    }

    /**
     * Обновление статистики по сборке курьера
     * @private
     */
    private updateAssemblingStats() {
        let overallOrdersCount = 0;
        let overallPlacesCount = 0;

        let assembledOrdersCount = 0;
        let assembledPlacesCount = 0;

        let rejectedOrdersCount = 0;
        let rejectedPlacesCount = 0;

        let excludedOrdersCount = 0;
        let excludedPlacesCount = 0;

        let currentShelfTotal = 0;
        let currentShelfScanned = 0;

        this.assemblingTaskOrdersPlaces.map(taskOrderPlaces => {
            overallOrdersCount++;
            overallPlacesCount += taskOrderPlaces.length;

            const firstPlace = taskOrderPlaces[0];
            const order = firstPlace.order;

            const shelf = (order.zone && order.zone.shelf) ? order.zone.shelf : -1;
            if (shelf === this.currentShelf) {
                currentShelfTotal++;
            }

            if (firstPlace._order_overall_status === 2) {
                assembledOrdersCount++;
                assembledPlacesCount += taskOrderPlaces.length;

                currentShelfScanned++;
            }

            if (firstPlace.should_be_excluded && firstPlace._order_overall_status !== 2) {
                excludedOrdersCount++;
                excludedPlacesCount += taskOrderPlaces.length;
            }

            if (!firstPlace.should_be_excluded && (firstPlace._order_overall_status === 3 || firstPlace._order_overall_status === 4)) {
                rejectedOrdersCount++;
                rejectedPlacesCount += taskOrderPlaces.length;
            }
        });

        this.isComplete = (overallOrdersCount === (assembledOrdersCount + rejectedOrdersCount + excludedOrdersCount) &&
            overallPlacesCount === (assembledPlacesCount + rejectedPlacesCount + excludedPlacesCount)
        );

        this.overallOrdersCount = overallOrdersCount;
        this.overallPlacesCount = overallPlacesCount;
        this.assembledOrdersCount = assembledOrdersCount;
        this.assembledPlacesCount = assembledPlacesCount;
        this.rejectedOrdersCount = rejectedOrdersCount;
        this.rejectedPlacesCount = rejectedPlacesCount;
        this.excludedOrdersCount = excludedOrdersCount;
        this.excludedPlacesCount = excludedPlacesCount;
        this.currentShelfTotal = currentShelfTotal;
        this.currentShelfScanned = currentShelfScanned;
    }

    /**
     * Обработка клика по кнопке сканирования штрихкода или ввода символа ввода в поле штрихкода
     */
    onLaunchBarcodeScan() {
        const value = this.formScanner.value.barCode.trim();
        if (value.length > 0) {
            this.scanBarcode(value);
        }
    }

    /**
     * Обработка клика по кнопке сортировки списка
     */
    onClickSortList() {
        this.sortMetrics = {}
        this.sortList();
    }

    /**
     * Сортировка списка заказов
     * @private
     */
    private sortList() {
        this.assemblingTaskOrdersPlaces.map(placesOfOrder => {
            placesOfOrder[0]._order_sort_metric = this.getSortMetric(placesOfOrder[0]);
        });

        this.assemblingTaskOrdersPlaces = this.assemblingTaskOrdersPlaces.sort((a, b) => {
            return (a[0]._order_sort_metric) - (b[0]._order_sort_metric);
        });
    }

    /**
     * Обработка клика по кнопке ознакомления с уведомлением
     */
    onClickAcknowledgeMessage() {
        this.acknowledgeMessage();
    }

    /**
     * Ознакомление с текущим уведомлением и переход к следующему или обратно к списку заказов
     * @protected
     */
    protected acknowledgeMessage() {
        const message = this.messagesToAcknowledge.shift();
        if (message && message[1]) {
            message[1]();
        }

        this.detectMessageToAcknowledge();
    }

    /**
     * Проверка наличия уведомлений и переключение между экранами
     * @protected
     */
    protected detectMessageToAcknowledge() {
        if (this.messagesToAcknowledge.length) {
            this.uiScreen = UIScreens.SCREEN_MESSAGE;
            this.playNotifySound();
        } else {
            this.uiScreen = UIScreens.SCREEN_ASSEMBLING_LIST;
            this.barCodeInputFocusDelayed(true);
        }
    }

    /**
     * Установка фокуса на поле ввода штрихкода
     * @param reset Очищать ли поле ввода
     * @private
     */
    private barCodeInputFocus(reset: boolean = false) {
        if (reset) {
            this.formScanner.controls.barCode.setValue('');
        }

        if (this.uiScreen === UIScreens.SCREEN_ASSEMBLING_LIST) {
            this.enableBarCodeInput();
            this.barCodeInput.nativeElement.focus();
        }
    }

    /**
     * Воспроизведение приятного звукового файла
     * @private
     */
    private playPositiveSound() {
        if (this.positiveSound) {
            this.positiveSound.play();
        }
    }

    /**
     * Воспроизведение неприятного звукового файла
     * @private
     */
    private playNegativeSound() {
        if (this.negativeSound) {
            this.negativeSound.play();
        }
    }

    /**
     * Воспроизведение уведомительного звукового файла
     * @private
     */
    private playNotifySound() {
        if (this.notifySound) {
            this.notifySound.play();
        }
    }

    /**
     * Обработка клика по кнопке завершения сборки
     */
    onClickDone() {
        this.reset();
    }

    /**
     * Сброс состояния
     * @private
     */
    private reset() {
        this.courierUserId = null;
        this.courierOldId = null;
        this.courierName = null;
        this.courierNameFull = null;
        this.isExcludeMode = false;

        this.assemblingSession = null;
        this.shippingQueue = null;

        this.ordersCountTotal = 0;
        this.ordersCountScanned = 0;
        this.currentShelf = -1;
        this.currentShelfTotal = 0;
        this.currentShelfScanned = 0;

        this.finishScreenData = null;

        this.uiScreen = UIScreens.SCREEN_COURIER_SELECT;
    }
}
