import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';

@Injectable()
export class ErrorDialogService {
    public isDialogOpen: Boolean = false;

    constructor(public dialog: MatDialog) {
    }

    openDialog(data): any {

        if (this.isDialogOpen) {
            return false;
        }

        if (data.reason) {
            this.isDialogOpen = true;
            alert(data.reason);
            this.isDialogOpen = false;
        }
        return true;
    }
}
