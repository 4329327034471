import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup} from "@angular/forms";
import {DialogService} from "../../components/dialog/dialog.service";
import {Title} from "@angular/platform-browser";
import {SkladService} from './../../service/sklad.service';
import {ClientInfo, UserHub} from "../../service/models";
import {DatePipe} from '@angular/common';
import {State} from "@progress/kendo-data-query";
import {HelpersService} from '../../service/helpers.service';
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";


@Component({
    selector: 'app-orders-placement-report',
    templateUrl: './orders-placement-report.component.html',
    styleUrls: ['./orders-placement-report.component.scss']
})
export class OrdersPlacementReportComponent implements OnInit {
    public placementStatuses = [
        {value: 0, label: 'Все'},
        {value: 1, label: 'Не размещался'},
        {value: 2, label: 'Размещался'},
        {value: 3, label: 'Размещался корректно'},
        // {value: 4, label: 'Размещался некорректно'},
        // {value: 5, label: 'Открытые конфликты'},
        // {value: 6, label: 'Конфликты закрыты'},
        {value: 7, label: 'Перенос на корректную'},
        {value: 8, label: 'Оставлен на некорректной'},
    ]

    public deliveryAttempts = [
        {value: 'any', label: 'Не важно'},
        {value: 'none', label: 'Не был'},
        {value: 'have', label: 'Был'},
    ]

    public selectedClients: ClientInfo[] = [];

    public loadingReport = false;

    public reportOrders: GridDataResult;
    public reportSummary: {};

    public formFilter: FormGroup;

    public deliveryDate;
    public deliveryDatePicker;

    public today = new Date();
    public tomorrow = new Date(+new Date() + 86400000);

    public headerFilter: any = [];
    public selectedHubId: number = null;
    public selectedHubs: UserHub[];

    public state: State = {
        take: 50,
        skip: 0,

        filter: {
            logic: 'and',
            filters: []
        }
    };

    @ViewChild('deliveryDateInput') deliveryDateInput: ElementRef;
    @ViewChild('incomeDateInput') incomeDateInput: ElementRef;
    @ViewChild('placementStatusInput') placementStatusInput: ElementRef;

    constructor(
        private api: SkladService,
        public dialog: DialogService,
        private title: Title,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        protected helpers: HelpersService,
    ) {
        this.title.setTitle('Отчет о размещении на полках');
        this.deliveryDate = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');

        this.formFilter = new FormGroup({
            delivery_date: new FormControl(this.deliveryDate),
            placement_status: new FormControl(0),
            delivery_attempts: new FormControl('any'),
        });

        this.eventerService.primaryHubChange.subscribe((hub) => {
            console.info('primaryHubChange', hub);
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        let selectedHub = this.currentUserService.getCurrentHub();
        this.onChangeHub(selectedHub);
    }

    /**
     * Загружает страницу отчёта
     */
    loadReportPage() {
        this.loadingReport = true;

        const filter: any = {};
        filter.delivery_date = this.deliveryDate;
        filter.client_id = this.selectedClients.map(client => {
            return client.id;
        });

        filter.placement_status = this.formFilter.value.placement_status;
        filter.delivery_attempts = this.formFilter.value.delivery_attempts;

        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.hub_id = this.selectedHubId;

        const headerFilter = this.headerFilter;
        filter.head = headerFilter;

        this.api.orderPlacementReportGet(filter).subscribe(data => {
            this.reportOrders = data.orders;
            this.reportSummary = data.summary;
            this.loadingReport = false;
        });
    }

    /**
     * Обработчик изменения страницы
     * @param param
     */
    onPageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.loadReportPage();
    }

    /**
     * Обработчик изменения фильтра по клиенту
     * @param clients
     */
    onChangeClient(clients: ClientInfo[]) {
        if (clients) {
            this.selectedClients = clients;
        } else {
            console.log('client not selected ');
            this.selectedClients = [];
        }
    }

    /**
     * Обработчик клика по кнопке применения фильтра
     */
    onApplyFilter() {
        this.loadReportPage();
    }

    /**
     * Обработчик изменения даты доставки заказа
     */
    onDeliveryDateChange() {
        this.deliveryDate = this.helpers.formatDateForSQL(this.deliveryDateInput.nativeElement.value)
    }

    /**
     * Обработчик изменения в селекторе хаба
     * @param hub
     */
    onChangeHub(hub) {
        this.selectedHubId = hub.id;
        this.selectedHubs = [hub, ];
        this.loadReportPage();
    }

}
