import {Component, Input, OnInit} from '@angular/core';
import {FinanceService} from '../../../service/finance.service';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-kassa-report-orders',
    templateUrl: './kassa-report-orders.component.html',
    styleUrls: ['./kassa-report-orders.component.scss']
})
export class KassaReportOrdersComponent implements OnInit {

    @Input('type') type: string;
    @Input('query') query: string;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Список заказов';

    public orders;

    public ordersLoading: boolean = false;

    constructor(
        protected app: AppComponent,
        private financeApi: FinanceService
    ) {
        if (window.innerWidth > 800) {
            this.width = 800;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        switch (this.type) {
            case 'getOrangeCashReceipts':
                this.title = 'ОФД наличные';

                break;
            case 'getOrangeCardReceipts':
                this.title = 'ОФД эквайринг';

                break;
            case 'getApishipCashReceipts':
                this.title = 'Пробито на парадигму';

                break;
            case 'getIboxPayments':
                this.title = 'IBOX';

                break;
            case 'getAssistPayments':
                this.title = 'Assist';

                break;
            case 'getLifepayPayments':
                this.title = 'СБП';

                break;
            default:
                this.title = 'Произошла ошибка, обратитесь в техподдержку';
                console.log('Unknown type');
                return;
        }

        this.ordersLoading = true;
        this.financeApi[this.type](this.query).subscribe(data => {
            this.orders = data;
            this.ordersLoading = false;
        }, error => {
            this.ordersLoading = false;
        });
    }

    /**
     * Итоговая сумма по заказам
     */
    public getOrdersSum() {
        let sum = 0;
        for (let i in this.orders) {
            if (this.orders[i].type_key === 'income') {
                sum += +this.orders[i].amount;
            } else {
                sum -= +this.orders[i].amount;
            }
        }

        return sum;
    }

    /**
     * Возвращает класс строки
     * @param order
     */
    public getRowClass(order) {
        if (order.error) {
            return 'old-bg-danger';
        } else if (order.type_key !== 'income') {
            return 'old-bg-warning';
        }
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
