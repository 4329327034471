import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Hub, Pallet} from '../../../service/models';
import {SkladService} from '../../../service/sklad.service';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {PalletEditModalComponent} from '../pallet-edit-modal/pallet-edit-modal.component';
import {HubsService} from '../../../service/hubs.service';
import {DialogService} from '../../../components/dialog/dialog.service';

@Component({
    selector: 'app-pallet-item',
    templateUrl: './pallet-item.component.html',
    styleUrls: ['./pallet-item.component.scss']
})
export class PalletItemComponent implements OnInit {
    @ViewChild('input_barcode') inputBarcode: ElementRef;

    public loading: boolean = false;
    public placesSuggestLoading = false;

    public palletId;
    public pallet: Pallet;
    public palletBarcodes: string[] = [];

    public allPlacesList: any[] = [];
    public suggestPlacesList: any[] = [];
    public allBarcodesCount: number = 0;

    public scannedBarcodes: string[] = [];
    public scannedOrders: string[] = [];

    private notAllPlacesScanned: boolean = false;

    private dialogSubscription;

    private hubs: Hub[];

    public message: string = '';
    private messageTimeout;

    public printBarcode: string;
    public printOrderUid: string;
    public printNow: boolean = false;

    constructor(
        public helpers: HelpersService,
        private route: ActivatedRoute,
        private api: SkladService,
        protected dialog: DialogService,
        private hubsService: HubsService,
        private router: Router,
        private app: AppComponent
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.palletId = +params['id'];

            this.getPallet();
        })

        this.hubsService.get()
            .subscribe((data: Hub[]) => {
                this.hubs = data;
            })
    }

    getOrdersCount() {
        const orders = [];
        for (let i = 0; i < this.pallet.places.length; i++) {
            if (orders.indexOf(this.pallet.places[i].order.uid) === -1) {
                orders.push(this.pallet.places[i].order.uid);
            }
        }

        return orders.length;
    }

    onPackEditClick() {
        this.app.createDialog(PalletEditModalComponent, {
            pallet: this.pallet
        }).onDestroy(data => {
            this.getPallet();
        });
    }

    onPackDeleteClick() {
        this.dialogSubscription = this.dialog.confirm('Удалить палету?')
            .subscribe(result => {
                if (result) {
                    this.api.deletePack({packId: this.pallet.id})
                        .subscribe(rez => {
                            this.dialog.alert('Палета удалена.');
                            this.router.navigate(['/admin/sklad/pallets']);
                        });
                } else {
                    this.loading = false;
                }
                this.dialogSubscription.unsubscribe();
            });
    }

    getPlacesSuggest() {
        this.placesSuggestLoading = true;
        this.suggestPlacesList = [];
        this.api.getPlacesForPallet(this.palletId)
            .subscribe(data => {
                this.placesSuggestLoading = false;
                this.suggestPlacesList = data;

                // уберём уже добавленные в палету заказы
                for (let i = 0; i < this.suggestPlacesList.length; i++) {
                    if (this.orderInScannedList(this.suggestPlacesList[i])) {
                        this.suggestPlacesList[i].hide = 1;
                    }
                }
            }, error => {
                this.placesSuggestLoading = false;
            });
    }

    setFocusOnInput() {
        if (this.inputBarcode) {
            this.inputBarcode.nativeElement.value = '';
            this.inputBarcode.nativeElement.focus();
        }
    }

    isScannedAll() {
        console.log('isScannedAll: this.allBarcodesCount: ' + this.allBarcodesCount + '; this.scannedBarcodes.length: ' + this.scannedBarcodes.length);

        // для возвратов не обязательно сканировать все места заказа
        if (this.pallet.type === Pallet.TYPE_RETURN) {
            return this.scannedBarcodes.length > 0;
        }

        return ((this.allBarcodesCount === this.scannedBarcodes.length) && (this.allBarcodesCount > 0));
    }

    isScannedBarcode(barcode) {
        if (this.scannedBarcodes.indexOf(barcode) > -1) {
            return true;
        }

        this.notAllPlacesScanned = true;

        return false;
    }

    onScan(e) {
        if (!e.target.value) {
            this.setMessage('Не введен штрихкод');
            return;
        }

        const search = e.target.value.trim();
        if (this.barcodeIsScanned(search)) {
            this.setMessage('Место ' + search + ' уже отсканировано');

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);

            return;
        }

        if (this.barcodeIsInPallet(search)) {
            this.setMessage('Место ' + search + ' уже в паллете');

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);

            return;
        }

        this.loading = true;

        this.api.findPlaceForPacking({'pack_id': this.palletId, 'barcode': search}).subscribe((data: any) => {
            if (!data.bar_code) {
                this.setMessage('Отсканируйте места заказа!');

                e.target.value = '';
                this.loading = false;
                return;
            }

            e.target.value = '';
            this.notAllPlacesScanned = true;
            this.scannedBarcodes.push(data.bar_code.bar_code);

            if (!this.orderInScannedList(data.order)) {
                this.scannedOrders.push(data.order.id);
                this.allPlacesList.push(data.order);
                this.allBarcodesCount += data.order.bar_codes.length;
            }

            this.removeOrderFromSuggests(data.order.uid);

            // напечатать этикетку для заказов ТД
            if (this.pallet.hub_destination.type === Hub.TYPE_TOPDELIVERY) {
                this.onPrint(data.bar_code.id, data.order.uid);
            }

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);

            this.loading = false;
        }, error => {
            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);

            this.loading = false;
        });
    }

    setMessage(message) {
        this.message = message;
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }

        this.messageTimeout = setTimeout(() => {
            this.message = '';
        }, 10000);
    }

    removeOrderFromSuggests(orderUid) {
        for (let i = 0; i < this.suggestPlacesList.length; i++) {
            if (this.suggestPlacesList[i].uid === orderUid) {
                this.suggestPlacesList[i].hide = 1;
                break;
            }
        }
    }

    restoreOrderInSuggests(orderUid) {
        for (let i = 0; i < this.suggestPlacesList.length; i++) {
            if (this.suggestPlacesList[i].uid === orderUid) {
                this.suggestPlacesList[i].hide = 0;
                break;
            }
        }
    }

    barcodeIsScanned(barcode) {
        if (this.scannedBarcodes.indexOf(barcode) > -1) {
            return true;
        }

        this.notAllPlacesScanned = true;
        return false;

    }

    barcodeIsInPallet(barcode) {
        if (this.palletBarcodes.indexOf(barcode) > -1) {
            return true;
        }
        return false;
    }

    orderInScannedList(order) {
        return (this.scannedOrders.indexOf(order.id) > -1);
    }

    onAddToPack() {
        this.loading = true;

        const barcode_ids: number[] = [];
        // для возвратов добавляем только отсканированные баркода
        if (this.pallet.type === Pallet.TYPE_RETURN) {
            this.allPlacesList.forEach((item) => {
                item.bar_codes.forEach((br) => {
                    if (this.scannedBarcodes.indexOf(br.bar_code) > -1) {
                        barcode_ids.push(br.id);
                    }
                });
            });
        } else {
            this.allPlacesList.forEach((item) => {
                item.bar_codes.forEach((br) => {
                    barcode_ids.push(br.id);
                });
            });
        }

        this.api.addPlacesToPallet(this.pallet.id, barcode_ids).subscribe(data => {
            this.dialog.alert(
                this.helpers.getPluralEnding(barcode_ids.length, ['Добавлено', 'Добавлены', 'Добавлено'])
                + ' ' + barcode_ids.length
                + ' ' + this.helpers.getPluralEnding(barcode_ids.length, ['место', 'места', 'мест'])
            );

            this.clearAll();
            this.getPallet();
            this.loading = false;
            this.scannedBarcodes = [];
            this.allBarcodesCount = 0;

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);
        }, error => {
            this.loading = false;
        });
    }

    clearAll() {
        this.allPlacesList = [];
        this.scannedOrders = [];
        this.scannedBarcodes = [];
    }

    getPallet() {
        this.loading = true;
        this.api.getTransit(this.palletId).subscribe((data) => {
            this.pallet = data;

            this.scannedOrders = [];
            this.palletBarcodes = [];
            // добавим заказы в список отсканированных
            if (data.places) {
                for (let i = 0; i < data.places.length; i++) {
                    if (!this.orderInScannedList(data.places[i].order)) {
                        this.scannedOrders.push(data.places[i].order.id);
                    }

                    // список баркодов в палете
                    this.palletBarcodes.push(data.places[i].bar_code);
                }
            }

            this.getPlacesSuggest();
            this.loading = false;

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);
        });
    }

    onUnsavedOrderDelete(uid) {

        for (let i = 0; i < this.allPlacesList.length; i++) {
            if (this.allPlacesList[i].uid === uid) {
                for (let j = 0; j < this.allPlacesList[i].bar_codes.length; j++) {

                    const barcodeIndex = this.scannedBarcodes.indexOf(this.allPlacesList[i].bar_codes[j].bar_code);
                    if (barcodeIndex > -1) {
                        this.scannedBarcodes.splice(barcodeIndex, 1);
                    }
                }
                this.allBarcodesCount -= this.allPlacesList[i].bar_codes.length;
                this.scannedOrders.splice(this.scannedOrders.indexOf(this.allPlacesList[i].id), 1);
                this.allPlacesList.splice(i, 1);
                break;
            }
        }

        this.restoreOrderInSuggests(uid);

        setTimeout(() => {
            this.setFocusOnInput();
        }, 200);
    }

    onPlaceDelete(barcode) {
        this.loading = true;
        this.api.findPlaceForPacking({
            'pack_id': this.palletId,
            'barcode': barcode,
            'action': 'delete'
        }).subscribe((data: any) => {
            this.dialogSubscription = this.dialog.confirm(
                this.helpers.getPluralEnding(data.order.bar_codes.length, ['Будет удалено', 'Будут удалены', 'Будут удалены']) + ' '
                + data.order.bar_codes.length + ' '
                + this.helpers.getPluralEnding(data.order.bar_codes.length, ['место', 'места', 'мест']) + '!'
            )
                .subscribe(result => {
                    if (result) {
                        this.api.deletePlaceFromPallet(this.pallet.id, data.order.bar_codes.map(item => item.id)).subscribe(rez => {
                            this.getPallet();

                            this.loading = false;
                        }, error => {
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                    }
                    this.dialogSubscription.unsubscribe();

                    setTimeout(() => {
                        this.setFocusOnInput();
                    }, 200);
                });
        }, error => {
            this.loading = false;
        });
    }

    getTypeDescription(type) {
        const types = Pallet.TYPES_DESCRIPTION;
        const findType = types.find((e) => {
            return e.id === +type;
        })

        return findType.name;
    }

    getHubName(hubId) {
        return this.hubsService.getHubNameById(+hubId);
    }

    onPrint(barcode, orderUid) {
        this.printBarcode = barcode;
        this.printOrderUid = orderUid;
        this.printNow = true;
    }

    onFinishPrint(e) {
        this.printNow = !e;
    }
}
