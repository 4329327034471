import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppComponent} from '../../../app.component';
import {FinanceService} from '../../../service/finance.service';
import {DatePipe} from '@angular/common';
import {NotifierService} from '../../../service/notifier.service';
import {AgentReport} from '../../finance-models';
import {HelpersService} from '../../../service/helpers.service';
import {ClientInfo} from '../../../service/models';

@Component({
    selector: 'app-agent-report-create',
    templateUrl: './agent-report-create.component.html',
    styleUrls: ['./agent-report-create.component.scss'],
})
export class AgentReportCreateComponent implements OnInit {

    public width = 700;
    public windowHeight = 500;
    public form: FormGroup;
    public title: string;
    public dataIsLoading: boolean = false;
    public suggestIsLoading: boolean = false;
    public lastReportIsLoading: boolean = false;

    public clientId: number;
    public client: ClientInfo;
    public lastReport: AgentReport;

    protected today: Date;
    protected firstDayOfMonth: Date;
    protected twoDaysBefore: Date;

    public reportsToCreate;

    constructor(
        protected app: AppComponent,
        protected api: FinanceService,
        protected datePipe: DatePipe,
        private notifier: NotifierService,
        protected helpers: HelpersService,
    ) {
        this.form = new FormGroup({
            date_from: new FormControl(null, Validators.required),
            date_to: new FormControl(null, Validators.required),
            client_id: new FormControl(null, Validators.required),
        });

        this.today = new Date();
        this.firstDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
        this.twoDaysBefore = new Date();
        this.twoDaysBefore.setDate(this.today.getDate() - 2);

        this.setDatesByDefault();
        this.setDefaultTitle();
    }

    setDatesByDefault() {
        this.form.get('date_from').setValue(this.firstDayOfMonth > this.twoDaysBefore ? this.twoDaysBefore : this.firstDayOfMonth);
        this.form.get('date_to').setValue(this.twoDaysBefore);
    }

    setDefaultTitle() {
        this.title = 'Создание агентского отчёта';
    }

    ngOnInit() {
        this.loadSuggests();
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * При выборе клиента
     * @param event
     */
    onSelectClient(event) {
        this.clientId = event ? event.id : null;
        if (!this.clientId) {
            this.client = null;
        }

        this.form.get('client_id').setValue(this.clientId);

        this.loadLastAgentReport();
    }

    getClientReportPeriod() {
        if (!this.client) {
            return null;
        }

        return this.helpers.getClientSetting(this.client.settings, 'agent_report_period');
    }

    createReport() {
        const query = {...this.form.value};
        query.date_to = this.datePipe.transform(query.date_to, 'yyyy-MM-dd');
        query.date_from = this.datePipe.transform(query.date_from, 'yyyy-MM-dd');

        this.createReportFromData(query);
    }

    createReportFromData(params, callback = null) {
        this.dataIsLoading = true;
        this.api.createAgentReport(params)
            .subscribe(data => {
                this.dataIsLoading = false;
                this.notifier.openNotifier('Отчёт создан', null, {duration: 3000});

                if (callback) {
                    callback();
                } else {
                    this.app.closeDialog(this);
                }
            }, error => {
                this.dataIsLoading = false;
            })
    }

    loadLastAgentReport() {
        if (!this.clientId) {
            this.lastReport = null;
            this.setDatesByDefault();
            this.setDefaultTitle();
            return;
        }

        this.lastReportIsLoading = true;
        this.client = null;
        this.api.getLastAgentReport({client_id: this.clientId})
            .subscribe(data => {
                this.lastReport = data.lastReport;
                this.client = data.client;

                if (this.getClientReportPeriod()) {
                    this.title = 'Клиент ' + this.client.name + ', период отчёта ' + this.getClientReportPeriod() + ' ' + this.helpers.getPluralEnding(this.getClientReportPeriod(), ['день', 'дня', 'дней']);
                }

                if (this.lastReport) {
                    const dateEnd = new Date(this.lastReport.date_end);
                    dateEnd.setDate(dateEnd.getDate() + 1);

                    this.form.get('date_from').setValue(this.datePipe.transform(dateEnd, 'yyyy-MM-dd'));

                    if (this.twoDaysBefore < dateEnd) {
                        this.form.get('date_to').setValue(dateEnd);
                    }
                }

                this.lastReportIsLoading = false;
            }, error => {
                this.lastReportIsLoading = false;
            })
    }

    loadSuggests() {
        this.reportsToCreate = null;
        this.suggestIsLoading = true;
        this.api.getClientListForReports()
            .subscribe(data => {
                this.reportsToCreate = data;

                this.suggestIsLoading = false;
            }, error => {
                this.suggestIsLoading = false;
            })
    }

    createReportForClient(clientId: number, nextReportStart: string, nextReportEnd: string) {
        this.createReportFromData({
            client_id: clientId,
            date_from: nextReportStart,
            date_to: nextReportEnd
        }, () => {
            this.loadSuggests();
        })
    }
}
