import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';
import {AppComponent} from '../../app.component';
import {HelpersService} from '../../service/helpers.service';
import {StaffService} from '../../service/staff.service';
import {Ibox, OrderZone, Terminals} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {Subject} from 'rxjs';


@Component({
    selector: 'app-terminal-card',
    templateUrl: 'terminal-card.component.html',
    styleUrls: ['./terminal-card.component.scss'],
})
export class TerminalCardComponent implements OnInit {
    public loading = false;
    @Input('terminal_id') public terminal_id: number | null;
    @Output() onDestroy = new EventEmitter<boolean>();

    constructor(
        private api: StaffService,
        private notifier: NotifierService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        if (this.terminal_id) {
            this.api.getTerminalById(this.terminal_id).subscribe((data) => {
                this.showTerminalCard(data);
                this.loading = false;
            }, error => {
                this.loading = false;
                this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
            });
        } else {
            this.showTerminalCard();
            this.loading = false;
        }
    }

    private showTerminalCard(data = []) {
       const dialog = this.app.createDialog(TerminalCardDialogComponent, {
            data: data,
        });
       dialog.onDestroy(() => {
          this.onDestroy.emit(true);
       })
    }
}


@Component({
    selector: 'app-terminal-card-dialog',
    styleUrls: ['./terminal-card.component.scss'],
    templateUrl: 'terminal-card-dialog.component.html',
})
export class TerminalCardDialogComponent implements OnInit {
    public data: Terminals;
    public formFilter: FormGroup;
    public isNew = true;
    public isInit = false;
    public isInit2 = false;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public isSavingOrCreate = false;
    public isRemoving = false;
    public isLogsLoading = false;
    public dataLogs = [];

    constructor(
        private api: StaffService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        protected app: AppComponent,
        public helpers: HelpersService,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit() {
       this.setValues();
    }

    private setValues() {
        if (this.data && this.data.id) {
            this.isNew = false;
        }

        this.formFilter = new FormGroup(
            {
                terminal_serial: new FormControl(this.isNew ? null : this.data.terminal_serial, Validators.required),
                terminal_type: new FormControl(this.isNew ? null : this.data.terminal_type, Validators.required),
                comment: new FormControl(this.isNew ? null : this.data.comment),
                created_at: new FormControl({value: this.isNew ? null : this.data.created_at, disabled: true}, Validators.required),
                updated_at: new FormControl({value: this.isNew ? null : this.data.updated_at, disabled: true}, Validators.required),
                returned_at: new FormControl({value: this.isNew ? null : this.data.returned_at, disabled: true}),
                hub_id: new FormControl(this.isNew ? this.currentUserService.getCurrentHub().id : this.data.hub_id, Validators.required),
                hub_ids: new FormControl([this.currentUserService.getHubById(this.isNew ? this.currentUserService.getCurrentHub().id : this.data.hub_id)], Validators.required),
                status: new FormControl(this.isNew ? 1 : this.data.status, Validators.required),
                courier_id: new FormControl(this.isNew ? null : this.data.courier_id),
                courier_fio: new FormControl(this.isNew ? null : (this.data.courier_option ? this.data.courier_option.full_name : null)),
                selected_couriers: new FormControl(this.isNew ? [] : [this.data.courier]),
            },
        );
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * Событие при смене курьера
     * @param event
     */
    onChangeCourierSelect(event) {
        if (this.isInit) {
            this.formFilter.controls.courier_fio.setValue(event ? event.name : null);
            this.formFilter.controls.courier_id.setValue(event ? event.id : null);
        }
        this.isInit = true;
    }

    /**
     * Событие при смене хаба
     * @param event
     */
    onHubSelectChange(event) {
        this.formFilter.controls.hub_id.setValue(event ? event.id : null);
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.formFilter.value.hub_id]});
    }

    /**
     * Событие при смене типа терминала
     * @param event
     */
    onTerminalTypeChange(event: number[]) {
        this.formFilter.controls.terminal_type.setValue(event ? event[0] : null);
    }

    /**
     * Событие при смене статуса терминала
     * @param event
     */
    onTerminalStatusChange(event: number[]) {
        if (this.isInit2) {
            this.formFilter.controls.status.setValue(event ? event[0] : null);
        }
        this.isInit2 = true;
    }

    /**
     * Получить наименование типа терминала
     * @param terminal_type
     */
    getTerminalTypeName(terminal_type: number) {
        return Ibox.PROVIDERS.filter(x => {
            return x.id === terminal_type
        }).map(x => x.name);
    }

    onUpdateOrCreateTerminal() {
        this.isSavingOrCreate = true;
        this.api.updateOrCreateTerminal(this.isNew ? null : this.data.id, this.formFilter.value).subscribe(result => {
            this.notifier.openNotifier(this.isNew ? 'Терминал добавлен!' : 'Терминал обновлен', null, {class: 'success', duration: 1500});
            this.isSavingOrCreate = false;
            this.onClose();
        }, error => {
            this.isSavingOrCreate = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    removeTerminal() {
        this.isRemoving = true;
        this.api.removeTerminal(this.data.id).subscribe(result => {
            this.notifier.openNotifier('Терминал удалён', null, {class: 'success', duration: 1500});
            this.isRemoving = false;
            this.onClose();
        }, error => {
            this.isRemoving = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    onSelectIndexChange(index: number) {
        switch (index) {
            /** Логи */
            case 1:
                this.isLogsLoading = true;
                this.api.getTerminalLogs(this.data.id).subscribe(data => {
                    this.dataLogs = data;
                    this.isLogsLoading = false;
                }, error => {
                    this.isLogsLoading = false;
                    this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                });
            break;
        }
    }
}
