import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SkladService} from '../../../service/sklad.service';

@Component({
    selector: 'app-transit-print',
    templateUrl: './transit-print.component.html',
    styleUrls: ['./transit-print.component.scss']
})
export class TransitPrintComponent implements OnInit {
    public trId: string;
    public transit = null;
    public courier: any;

    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public loading = false;

    constructor(
        private route: ActivatedRoute,
        private api: SkladService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.route.params.subscribe(params => {
            this.trId = params['trId'];
        });

        this.api.getTransitForPrint(this.trId).subscribe((data) => {
            this.transit = data.transit;
            this.courier = data.courier;
            this.loading = false;
        });
    }
    printPage() {
        window.print();
    }
}
