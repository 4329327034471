import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ZordersService} from '../../service/zorders.service';
import {NotifierService} from '../../service/notifier.service';
import {LaraService} from '../../service/lara.service';
import {Call} from '../../service/models';

@Component({
    selector: 'app-zorder-calls-history',
    templateUrl: './zorder-calls-history.component.html',
    styleUrls: ['./zorder-calls-history.component.scss'],
})
export class ZorderCallsHistoryComponent implements OnInit {
    private eventsSubscription: Subscription;
    @Input('showDialog') showDialog = false;
    @Input() events: Observable<number>;
    public loading: any;
    public zorderCalls = null;
    public zorderId = null;
    private padding = null;

    constructor(
        private zordersService: ZordersService,
        private notifier: NotifierService,
        private api: LaraService
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(zorderId => {
                this.clearData();
                this.zorderId = zorderId;
                this.onStartLoadingData();
            });
        }
    }

    onStartLoadingData() {
        if (this.showDialog) {
            this.showModal();
        }
        this.loading = true;
        this.zordersService.getZorderCalls(this.zorderId).subscribe(data => {
            this.zorderCalls = data;
            this.loading = false;
        }, error => {
            this.loading = false;
        })
    }

    showModal() {
        document.getElementById('modalZorderCallsHistoryShow').click();
    }

    private clearData() {
        this.zorderId = null;
        this.zorderCalls = null;
    }


    getPaddingLeft() {
        if (this.padding) {
            return this.padding;
        }
        const el = document.getElementById('dop-info');
        if (!el) {
            return 0;
        }
        return (this.padding = (el.clientWidth * 2));
    }

    makeCallAny(toNumber, toTypeId, toUserId) {
        const callInfo = {
            t: {
                id: this.zorderId,
                type: Call.TARGET_TYPE_ZORDER,
            },
        };


        if (toTypeId && toUserId) {
            callInfo['to'] = {
                to: {
                    id: toUserId,
                    type: toTypeId,
                },
            }
        }

        this.makeCall(toNumber, callInfo);
    }

    private makeCall(phone, callInfo) {
        this.api.makeCall(phone, callInfo).subscribe(data => {
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000});
        })
    }
}
