import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Order, OrderBarCode} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SkladService} from '../../service/sklad.service';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../service/helpers.service';

interface IScannableItem {
    _is_scanned: boolean;
}

class ScannableOrder extends Order implements IScannableItem {
    _is_scanned: boolean;
    _is_any_barcode_scanned: boolean;
    bar_codes: ScannableOrderBarCode[];
}

class ScannableOrderBarCode extends OrderBarCode implements IScannableItem {
    _is_scanned: boolean;
}

@Component({
  selector: 'app-hhc-courier-assembling-legacy',
  templateUrl: './hhc-courier-assembling-legacy.component.html',
  styleUrls: ['./hhc-courier-assembling-legacy.component.scss']
})
export class HhcCourierAssemblingLegacyComponent implements OnInit {
    @ViewChild('barCodeInput') public barCodeInput: ElementRef;

    public formSettings: FormGroup;
    public formScanner: FormGroup;

    public loading = false;
    public courierName: string | null = null;
    public courierOldId: number | null = null;
    public courierUserId: number | null = null;

    public orders: ScannableOrder[] = [];
    private assembledOrdersIds = [];

    public ordersCountTotal = 0;
    public ordersCountScanned = 0;
    public currentShelf = -1;
    public currentShelfTotal = 0;
    public currentShelfScanned = 0;
    public shouldScanPlaceBarcode = false;

    public isFinishScreen = false;
    public finishScreenData: any = null;

    public courierDocAttorneyLink = null;
    public courierDocLiabilityActLink = null;
    public courierDocRouteListLink = null;

    private positiveSound = null;
    private negativeSound = null;

    constructor(
        private api: SkladService,
        private datePipe: DatePipe,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.formSettings = new FormGroup({
            oldCourierId: new FormControl('', Validators.required),
        });

        this.formScanner = new FormGroup({
            barCode: new FormControl('', Validators.required),
        });

        this.negativeSound = new Audio();
        this.negativeSound.src = '../../../assets/sounds/negative-compressed.mp3';
        this.negativeSound.load();

        this.positiveSound = new Audio();
        this.positiveSound.src = '../../../assets/sounds/positive-compressed.mp3';
        this.positiveSound.load();
    }

    /**
     * Деактивация поля ввода штрихкода
     * @private
     */
    private disableBarCodeInput() {
        this.formScanner.controls.barCode.disable();
    }

    /**
     * Активация поля ввода штрихкода
     * @private
     */
    private enableBarCodeInput() {
        this.formScanner.controls.barCode.enable();
    }

    /**
     * Определение класса элемента списка заказов
     * @param item
     * @param type
     */
    getItemClassName(item, type) {
        if (item._is_scanned) {
            return 'scanned';
        }

        if ('barcode' === type && item.is_client_return > 0) {
            return 'client-return';
        }

        if ('order' === type && item.is_client_return === Order.CLIENT_RETURN_ONLY) {
            return 'client-return';
        }

        return '';
    }

    /**
     * Получение курьера из очереди
     * @param oldCourierId
     * @private
     */
    private checkCourierStatus(oldCourierId) {
        this.loading = true;
        this.api.checkCourierStatus({oldCourierId}).subscribe(response => {
            if (response.user && response.user.courier_option) {
                this.courierName = response.user.courier_option.full_name;
                this.courierOldId = response.user.courier_option.old_courier_id;
                this.courierUserId = response.user.id;

                this.loadCourierQueue();
            } else {
                this.loading = false;
            }

        }, () => {
            this.loading = false;
        });
    }

    /**
     * Загрузка задания на сборку курьера
     * @private
     */
    private loadCourierQueue() {
        this.loading = true;
        this.orders = [];
        this.api.loadCourierQueue({userId: this.courierUserId}).subscribe(response => {
            if (response.orders) {
                response.orders = response.orders.map(order => {
                    const scannableOrder = new ScannableOrder(order);
                    scannableOrder.bar_codes = scannableOrder.bar_codes.map(barcode => new ScannableOrderBarCode(barcode));

                    return scannableOrder;
                });

                this.orders = response.orders;
                this.updateAssemblingStats();
            }

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Обработка клика по кнопке проверки курьера
     */
    onClickCheckCourier() {
        if (this.formSettings.value.oldCourierId.length > 0) {
            this.checkCourierStatus(this.formSettings.value.oldCourierId);
        }
    }

    /**
     * Обработка клика по кнопке взятия следующего курьера из очереди
     */
    onClickNextCourier() {
        this.checkCourierStatus(0);
    }

    /**
     * Обработка клика по кнопке отмены сборки
     */
    onClickBreakAssembling() {
        if (confirm('Прервать сборку?')) {
            this.reset();
        }
    }

    /**
     * Обработка клика по кнопке завершения сборки
     */
    onClickFinishAssembling() {
        this.checkCanFinishAssembling();
    }

    /**
     * Проверка возможности завершить сборку
     * @private
     */
    private checkCanFinishAssembling() {

        let partialScanned = false;

        const ordersIds = [];
        this.orders.map(order => {
            if (order._is_any_barcode_scanned && !order._is_scanned) {
                partialScanned = true;
                return;
            }

            if (order._is_scanned) {
                ordersIds.push(order.id);
            }
        });

        if (partialScanned) {
            this.playNegativeSound();
            alert('Не все заказы полностью отсканированы!');
            return;
        }

        this.assembledOrdersIds = ordersIds;

        if (this.ordersCountScanned === this.ordersCountTotal || confirm('Не все заказы отсканированы! Завершить сборку?')) {
            this.finishAssembling();
        }
    }

    /**
     * Завершение сборки
     * @private
     */
    private finishAssembling() {
        this.isFinishScreen = true;

        this.courierDocAttorneyLink = null;
        this.courierDocLiabilityActLink = null;
        this.courierDocRouteListLink = null;

        this.loading = true;
        this.api.finishCourierShipping({
            userId: this.courierUserId,
            order_id: this.assembledOrdersIds,
        }).subscribe(response => {
            this.api.returnCourierDocs({userId: this.courierUserId}).subscribe(responseDocs => {
                this.finishScreenData = responseDocs;

                const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
                if (responseDocs._next && responseDocs._next.courier_acts_ids
                    && responseDocs._next.courier_acts_ids.length) {
                    this.courierDocLiabilityActLink = [
                        '/print-courier-mact', {
                            mactId: responseDocs._next.courier_acts_ids[0],
                        },
                    ];
                }

                this.courierDocAttorneyLink = [
                    '/power-of-attorney', {
                        date: today,
                        courier: this.courierUserId,
                    },
                ];

                this.courierDocRouteListLink = [
                    '/couriers/route-list', {
                        date: today,
                        courier_id: this.courierUserId,
                        shift_number: 1,
                    },
                ];

                this.loading = false;
            }, () => {
                this.loading = false;
            });
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Обработка клика по кнопке возврата к списку заказов для сборки
     */
    onClickBackToAssembleList() {
        this.isFinishScreen = false;
    }

    /**
     * Сканирование штрихкода
     * @param barCode
     * @private
     */
    private scanBarcode(barCode) {
        this.loading = true;
        this.shouldScanPlaceBarcode = false;
        this.api.checkBarcode({
            userId: this.courierUserId,
            barCode,
        }).subscribe(response => {
            this.loading = false;

            if (response.barcode) {
                const order = this.orders.find(loopOrder => loopOrder.id === response.barcode.order_id);
                order._is_any_barcode_scanned = true;

                const barcode = order.bar_codes.find(loopBarcode => loopBarcode.id === response.barcode.id);
                barcode._is_scanned = true;

                const scannedBarcodesCount = order.bar_codes
                    .filter(loopBarcode => loopBarcode._is_scanned,
                    ).length;

                if (scannedBarcodesCount === order.bar_codes.length) {
                    order._is_scanned = true;
                }

                this.inspectScannedOrder(order);
                this.updateAssemblingStats();
                this.barCodeInputFocus(true);
                this.playPositiveSound();

                return;
            }

            if (response.order) {
                const order = this.orders.find(loopOrder => loopOrder.id === response.order.id);
                if (order.bar_codes.length > 1) {
                    this.shouldScanPlaceBarcode = true;
                    this.disableBarCodeInput();
                    this.playNegativeSound();

                    return;
                }

                order._is_any_barcode_scanned = true;
                order._is_scanned = true;

                this.inspectScannedOrder(order);
                this.updateAssemblingStats();
                this.barCodeInputFocus(true);
                this.playPositiveSound();
            }

        }, () => {
            this.loading = false;
        });
    }

    /**
     * Дополнительное исследование отсканированного заказа
     * @param order
     * @private
     */
    private inspectScannedOrder(order) {
        if (this.currentShelf !== order.zone.shelf) {
            this.currentShelf = order.zone.shelf;
        }
    }

    /**
     * Обновление статистики по сборке курьера
     * @private
     */
    private updateAssemblingStats() {
        let currentShelfTotal = 0;
        let currentShelfScanned = 0;
        let ordersCountTotal = 0;
        let ordersCountScanned = 0;

        this.orders.map(order => {
            ordersCountTotal++;
            if (order.zone.shelf === this.currentShelf) {
                currentShelfTotal++;

                if (order._is_scanned) {
                    currentShelfScanned++;
                }
            }

            if (order._is_scanned) {
                ordersCountScanned++
            }
        });

        this.ordersCountTotal = ordersCountTotal;
        this.ordersCountScanned = ordersCountScanned;
        this.currentShelfTotal = currentShelfTotal;
        this.currentShelfScanned = currentShelfScanned;
    }

    /**
     * Обработка клика по кнопке сканирования штрихкода или ввода символа ввода в поле штрихкода
     */
    onLaunchBarcodeScan() {
        if (this.formScanner.value.barCode.length > 0) {
            this.scanBarcode(this.formScanner.value.barCode);
        }
    }

    /**
     * Обработка клика по кнопке сортировки списка
     */
    onClickSortList() {
        this.orders = this.orders.sort((a, b) => {
            return (a._is_scanned ? 1 : 0) - (b._is_scanned ? 1 : 0);
        });
    }

    /**
     * Обработка клика по кнопке ознакомления с проблемой
     */
    onClickAcknowledgeProblem() {
        this.shouldScanPlaceBarcode = false;
        this.barCodeInputFocus(true);
    }

    /**
     * Установка фокуса на поле ввода штрихкода
     * @param reset Очищать ли поле ввода
     * @private
     */
    private barCodeInputFocus(reset: boolean = false) {
        if (reset) {
            this.formScanner.controls.barCode.setValue('');
        }

        this.barCodeInput.nativeElement.focus();
        this.enableBarCodeInput();
    }

    /**
     * Воспроизведение приятного звукового файла
     * @private
     */
    private playPositiveSound() {
        if (this.positiveSound) {
            this.positiveSound.play();
        }
    }

    /**
     * Воспроизведение неприятного звукового файла
     * @private
     */
    private playNegativeSound() {
        if (this.negativeSound) {
            this.negativeSound.play();
        }
    }

    /**
     * Обработка клика по кнопке завершения сборки
     */
    onClickDone() {
        this.reset();
    }

    /**
     * Сброс состояния
     * @private
     */
    private reset() {
        this.courierUserId = null;
        this.courierName = null;
        this.courierOldId = null;

        this.ordersCountTotal = 0;
        this.ordersCountScanned = 0;
        this.currentShelf = -1;
        this.currentShelfTotal = 0;
        this.currentShelfScanned = 0;

        this.isFinishScreen = false;
        this.finishScreenData = null;

        this.barCodeInputFocus(true);
    }
}

/**
 * todo: restyle buttons btn
 * todo: restyle text input
 */
