import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OrdersService} from '../../service/orders.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ClientInfo, District, OrderZone, User, UserHub} from '../../service/models';
import {DatePipe} from '@angular/common';
import {ZordersService} from '../../service/zorders.service';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {CurrentUserService} from '../../service/current-user.service';

@Component({
    selector: 'app-main-map',
    templateUrl: './main-map.component.html',
    styleUrls: ['./main-map.component.scss']
})
export class MainMapComponent implements OnInit {
    protected ymaps: any;
    protected instance: any;
    public isOpen = true;
    private selectedPoly: any = null;
    @Output() onChange = new EventEmitter<string[]>();
    @Output() useMark = new EventEmitter<this>();

    @Input('selected') public selected: string[] = [];
    @ViewChild('input_date') inputDate: ElementRef;


    public appHubChangeEventSubject: Subject<any> = new Subject<any>();

    public openLocalFilter = true;
    public toDay = new Date();

    public formFilter: FormGroup;

    public newCourier: User;
    public setNoCourier = false;
    private loadingObjectManager: any = null;

    public mapCenter: [number, number] = [55.751952, 37.600739];
    public mapZoom = 14;


    public zone_id: number;
    public waitCounter = 0;

    protected paintButton: any;
    protected paintOnMap: any;
    protected paintPolygon: any = null;
    protected isPainting = false;

    public courierRoute: any[] = [];
    public loadRoute = false;

    public routeButton;
    public isShowRoute = false;


    public filterButton;
    public isShowFilters = false;

    public pointsCount = -1;

    public selectedHub: UserHub = null;
    public hubChanged = true;

    public filters: {
        load_orders: boolean,
        load_zorders: boolean,

        date: any,
        before_14: boolean,
        after_18: boolean,
        before_18: boolean,
        before_25kg: boolean,
        before_30kg: boolean,
        before_40kg: boolean,
        more_25kg: boolean,
        more_30kg: boolean,
        more_40kg: boolean,
        is_important: boolean,
        was_postponed: boolean,
        is_verified: boolean,
        is_vip_delivery_shipment: boolean,
        prepayed: boolean,
        zones: OrderZone[],
        couriers: User[],
        clients: ClientInfo[],
        statuses: number[],
        shifts: number[],
        shiftsGlobal: number[],
        districts: District[],
        without_courier: boolean,
        cur_hub: boolean,

    } = {
        load_orders: true,
        load_zorders: true,

        date: new Date(),
        before_14: false,
        before_18: false,
        after_18: false,
        before_25kg: false,
        before_30kg: false,
        before_40kg: false,
        more_25kg: false,
        more_30kg: false,
        more_40kg: false,
        is_important: false,
        was_postponed: false,
        is_verified: null,
        is_vip_delivery_shipment: null,
        prepayed: false,
        zones: [],
        couriers: [],
        clients: [],
        statuses: [],
        shifts: [],
        shiftsGlobal: [],
        districts: [],
        without_courier: false,
        cur_hub: false
    };

    public selectZoneId: number = null;
    public ordersInZoneInfo = null;
    public ordersSelected: string[] = [];
    public zordersSelected: string[] = [];
    public courierForSelectedOrder = '';
    /* Дата доставки */
    public delivery_date;
    /* Идентификатор курьера */
    public courier_ids;


    constructor(
        protected api: OrdersService,
        protected apiZorder: ZordersService,
        protected helper: HelpersService,
        protected dialog: DialogService,
        private changeDetectorRef: ChangeDetectorRef,
        private currentUserService: CurrentUserService,
        protected dataPipe: DatePipe,
        protected title: Title) {
        this.formFilter = new FormGroup({
            inputDate: new FormControl(this.dataPipe.transform(this.toDay, 'yyyy-MM-dd')),
        });
    }

    ngOnInit() {


        if (this.useMark) {
            this.useMark.emit(this);
        }

        this.title.setTitle('Карта');
        this.selectedHub = this.currentUserService.getCurrentHub();
    }

    ngOnDestroy(): void {

    }

    onOpen() {
        this.isOpen = true;
    }


    loadMap(event) {
        console.log('load map');
        this.ymaps = event.ymaps;
        this.instance = event.instance;

        const elem = this.instance.container.getElement().parentElement.parentElement;
        elem.classList.remove('container');
        elem.classList.add('map');
        this.instance.container.fitToViewport();
        if (!this.ymaps.modules.isDefined('ext.paintOnMap')) {
            console.log('script not defined');
            const paintScriptElm = window.document.createElement('script');
            paintScriptElm.src = '/assets/js/paintOnMap.js';
            const scriptPaint = window.document.body.appendChild(paintScriptElm);
            scriptPaint.addEventListener('load', (e) => {
                this.initMap();

                this.bindEventPaint();
                this.bindEventMap();
                this.loadZones();

            });
        } else {

            this.initMap();
            this.bindEventPaint();
            this.bindEventMap();
            this.loadZones();
        }

        this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
        this.instance.setCenter(this.mapCenter, this.mapZoom);


    }


    getSelectedObjects(target) {
        const result = this.ymaps.geoQuery(this.loadingObjectManager.objects).searchIntersect(target)
            .search('geometry.type == "Point"');
        const arr = [];
        const zarr = [];
        let ordersTotalWeight = 0;
        let ordersTotalPlacesCount = 0;

        result.each(function (pt) {

            if (pt.properties.get('pointtype') === 'order') {
                arr.push(pt.properties.get('objectID'));
                ordersTotalWeight += (+pt.properties.get('weight'));
                ordersTotalPlacesCount += (+pt.properties.get('placesCount'));
            } else {
                zarr.push(pt.properties.get('objectID'));
            }
        });

        this.ordersSelected = arr;
        this.zordersSelected = zarr;
        this.courierForSelectedOrder = '';

        this.ordersInZoneInfo = {
            totalWeight: ordersTotalWeight.toFixed(0),
            totalPlacesCount: ordersTotalPlacesCount.toFixed(0),
        }
    }

    bindEventMap() {

        this.instance.geoObjects.events.add('click', e => {
            this.selectZoneId = null;

            const target = e.get('target');
            if (!target.geometry) {
                return;
            }
            if (target.geometry.getType() === 'Polygon') {

                this.selectZoneId = target.options.get('zone_id');
                this.getSelectedObjects(target);
                this.getRouteCourier(this.selectZoneId);


            }
        });
    }


    bindEventPaint() {
        this.ymaps.modules.require(['ext.paintOnMap'])
            .spread(ext => {
                this.paintOnMap = ext;
            }, error => {
                console.log('LOAD ERROR', error);
            });

        // PAINTING PROCESS //
        this.paintButton = new this.ymaps.control.Button({
            data: {content: '<b>Выделить область</b>'},

            options: {maxWidth: [150]}
        });

        this.routeButton = new this.ymaps.control.Button({
            data: {content: '<b>Маршрут</b>'}
        });
        this.filterButton = new this.ymaps.control.Button({
            data: {content: '<b>Фильтры</b>'}
        });

        this.instance.controls.add(this.paintButton);
        this.instance.controls.add(this.routeButton);
        this.instance.controls.add(this.filterButton);
        this.routeButton.events.add('click', e => {
            this.isShowRoute = !this.isShowRoute;
            this.routeButton.data.content = this.isShowRoute ? 'Показать маршрут' : 'Скрыть маршрут';
        });

        this.filterButton.events.add('click', e => {
            this.isShowFilters = !this.isShowFilters;
            this.filterButton.data.content = this.isShowFilters ? 'Фильтры' : 'Скрыть фильтры';
        })

        this.paintButton.events.add('click', e => {
            this.isPainting = !this.isPainting;
            if (!this.isPainting && this.paintPolygon) {
                this.instance.geoObjects.remove(this.paintPolygon);
                this.paintPolygon = null;
            }
        });

        const plygonStyle = {
            strokeColor: '#ff00ff',
            strokeOpacity: 0.7,
            strokeWidth: 3,
            fillColor: '#ff00ff',
            fillOpacity: 0.4
        };

        let paintProcess;

        this.instance.events.add('mousedown', e => {
            if (this.isPainting) {
                paintProcess = this.paintOnMap(this.instance, e, {style: plygonStyle});
            }
        }).add('mouseup', e => {
            if (paintProcess) {
                if (this.paintPolygon) {
                    this.instance.geoObjects.remove(this.paintPolygon);
                }
                const coordinates = paintProcess.finishPaintingAt(e);
                paintProcess = null;
                this.paintPolygon = new this.ymaps.Polygon([coordinates], {}, plygonStyle);
                this.instance.geoObjects.add(this.paintPolygon);


                this.getSelectedObjects(this.paintPolygon);
                this.bodyClick();

            }
        });
        // END PAINTING PROCESS //
    }


    initMap() {
        const myMap = this.instance;
        const query = this.makeQuery();


        const $url = environment.lara_api_url + '/maps/getPointsForMap?' + query;

        this.loadingObjectManager = new this.ymaps.LoadingObjectManager($url, {
            paddingTemplate: 'myCallback_%c',
            splitRequests: false,
            clusterize: false,
        });


        this.loadingObjectManager.objects.events.add('click', e => {


            const objectId = e.get('objectId');
            const object = this.loadingObjectManager.objects.getById(objectId);

            if (object.geometry.type === 'Point') {
                if (object.properties.pointtype === 'order') {
                    this.ordersSelected = [object.properties.objectID];
                    this.courierForSelectedOrder = (object.properties.courier) ? object.properties.courier : 'не задан';
                    this.zordersSelected = [];

                } else {

                    this.zordersSelected = [object.properties.objectID];
                    this.courierForSelectedOrder = (object.properties.courier) ? object.properties.courier : 'не задан';
                    this.ordersSelected = [];

                }
            }


            this.changeDetectorRef.detectChanges();
            this.bodyClick();
        });

        this.instance.geoObjects.add(this.loadingObjectManager);
        this.getPointsCount();


        console.log('objects added ');

    }

    /* TODO костыль из-за специфики рендера yandex map */
    bodyClick() {
        document.body.click();
    }


    /**
     * Назначаем курьера на выделенные заборы и заказы
     */
    onUseSetCourier() {
        if (!this.newCourier && !this.setNoCourier) {
            this.dialog.alert('Нужно выбрать курьера');
            return;
        }

        const uids = this.ordersSelected;

        let msg = (this.setNoCourier) ? 'Убрать курьера с ' : 'Назначить курьера на: ' + this.newCourier.name;

        msg += ' ' + uids.length + ' заказов ';

        if (this.zordersSelected.length) {
            msg += ' и ' + this.zordersSelected.length + ' заборов '
        }
        msg += '?';

        // const dialog = this.dialog.confirm(msg).subscribe(result => {
        // if (result) {
        this.waitCounter = 0;

        if (uids.length) {

            this.waitCounter++;
            this.api.setCourierOrders(uids, (this.setNoCourier ? 0 : this.newCourier.id)).subscribe(data => {

                for (let i = 0; i < data.changedOrders.length; i++) {
                    this.markPoint(data.changedOrders[i].uid, (this.setNoCourier ? 'islands#redDotIcon' : 'islands#darkGreenDotIcon'));
                }
                if (data.errors !== '') {
                    alert(data.errors);
                }
                this.waitCounter--;
            });
        }
        if (this.zordersSelected.length) {
            this.waitCounter++;

            this.setCourierForZorders(this.zordersSelected, (this.newCourier ? this.newCourier.id : 0), 0);

        }

    }

    setCourierForZorders(zordersIds, courierId, isForce) {
        this.apiZorder.setCourierForZorders(zordersIds, courierId, isForce).subscribe(data => {
            for (let i = 0; i < data.changedZorders.length; i++) {
                console.warn(data.changedZorders[i]);
                let icon = this.setNoCourier ? 'islands#yellowDeliveryIcon' : 'islands#blueDeliveryIcon';
                if (data.changedZorders[i].is_sameday && this.setNoCourier) {
                    icon = 'islands#violetAirportIcon';
                } else if (data.changedZorders[i].is_sameday && !this.setNoCourier) {
                    icon = 'islands#blueAirportIcon';
                }
                this.markPoint(data.changedZorders[i].id, icon);
            }
            if (data.errors !== '') {
                if (data.need_force && !isForce) {
                    if (confirm(data.errors + ' Назначить все равно?')) {
                        this.setCourierForZorders(zordersIds, courierId, 1);
                    }
                } else {
                    alert(data.errors);
                }
            }
            this.waitCounter--;
        });
    }

    markPoint(uid, preset) {
        this.loadingObjectManager.objects.setObjectOptions(uid, {'preset': preset});
    }


    getOldZorderId(objectId) {
        const object = this.loadingObjectManager.objects.getById(objectId);
        if (object.geometry.type === 'Point') {
            return object.properties.old_zorder_id;
        }
        return '';
    }


    implodeIds(arrOfObjects, is_zones = false) {
        let res_str = '';
        for (const one of arrOfObjects) {
            if (res_str) {
                res_str += ',';
            }
            if (is_zones) {
                res_str += one.zone_id;
            } else {
                res_str += one.id;
            }
        }
        return res_str;
    }

    implodeSts(arrOfObjects) {
        let res_str = '';
        for (const one of arrOfObjects) {

            console.log(one);
            if (res_str) {
                res_str += ',';
            }
            res_str += one;

        }
        return res_str;
    }

    makeQuery() {
        const query = 'mainmap=1' +
            '&hub_id=' + this.selectedHub.id +
            (this.filters.load_orders ? '&ord=1' : '') +
            (this.filters.load_zorders ? '&zord=1' : '') +
            (this.filters.was_postponed ? '&was_p=1' : '') +
            (this.filters.is_verified === true ? '&is_ver=1' : '') +
            (this.filters.is_verified === false ? '&is_ver=0' : '') +
            (this.filters.is_vip_delivery_shipment === true ? '&is_vip=1' : '') +
            (this.filters.is_vip_delivery_shipment === false ? '&is_vip=0' : '') +
            (this.filters.prepayed ? '&prep=1' : '') +
            '&date=' + this.helper.formatDateForSQL(this.inputDate.nativeElement.value) +
            '&cr_ids=' + this.implodeIds(this.filters.couriers) +
            '&cl_ids=' + this.implodeIds(this.filters.clients) +
            '&sts=' + this.filters.statuses.join(',') +
            '&shs=' + ((this.filters.shiftsGlobal.length > 0) ? this.filters.shiftsGlobal.join(',') : this.filters.shifts.join(',')) +
            '&zone_ids=' + this.implodeIds(this.filters.zones, true) +
            '&d_ids=' + this.implodeIds(this.filters.districts) +
            (this.filters.cur_hub ? '&cur_hub=1' : '') +
            (this.filters.before_14 ? '&bef_14=1' : '') +
            (this.filters.before_18 ? '&bef_18=1' : '') +
            (this.filters.after_18 ? '&af_18=1' : '') +
            (this.filters.is_important ? '&is_imp=1' : '') +
            (this.filters.without_courier ? '&wc=1' : '') +
            (this.filters.more_25kg ? '&more_25kg=1' : '') +
            (this.filters.more_30kg ? '&more_30kg=1' : '') +
            (this.filters.more_40kg ? '&more_40kg=1' : '') +
            (this.filters.before_25kg ? '&before_25kg=1' : '') +
            (this.filters.before_30kg ? '&before_30kg=1' : '') +
            (this.filters.before_40kg ? '&before_40kg=1' : '') ;


        return query;
    }

    reLoadObjects() {

        if (this.hubChanged) {
            this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
            this.instance.setCenter(this.mapCenter, this.mapZoom);
            this.hubChanged = false;
        }


        const query = this.makeQuery();
        console.log(query);
        const $url = environment.lara_api_url + '/maps/getPointsForMap?' + query;
        this.loadingObjectManager.setUrlTemplate($url);
        this.loadingObjectManager.reloadData();
        this.getPointsCount();


    }


    // Подгрузка зон
    loadZones() {

        this.api.getZones(0, null, 1).subscribe(zones => {
            // Проходимся по массиву
            for (const data of zones) {


                const poly = this.ymaps.geometry.Polygon.fromEncodedCoordinates(data.geometry);
                const polygon = new this.ymaps.Polygon(poly);

                this.setPolygonProps(polygon, data);
                /* конвертация координат в нормальную последовательность */

                const coors = polygon.geometry.getCoordinates();
                const coorsTo = coors.map(cor => {
                    return cor.map(point => [point[1], point[0]]);
                });
                polygon.geometry.setCoordinates(coorsTo);
                this.instance.geoObjects.add(polygon);

            }
        });
    }


    onSelectCourier(users: User[]) {
        this.filters.couriers = users;
    }

    onSelectClient(clients: ClientInfo[]) {
        this.filters.clients = clients;
    }

    onChangeZone(zones: OrderZone[]) {
        this.filters.zones = zones;
    }

    onChangeDistrict(districts: District[]) {
        this.filters.districts = districts;
    }

    onChangeStatuses(statuses) {
        this.filters.statuses = statuses;
    }


    updateSetCheckBox(event) {
        this.setNoCourier = event.target.checked;
    }

    updateCheckBox(event, key) {
        switch (key) {
            case 'before_14': {
                this.filters.before_14 = event.target.checked;
                break;
            }
            case 'after_18': {
                this.filters.after_18 = event.target.checked;
                break;
            }
            case 'cur_hub': {
                this.filters.cur_hub = event.target.checked;
                break;
            }
            case 'more_25kg': {
                this.filters.more_25kg = event.target.checked;
                break;
            }
            case 'more_30kg': {
                this.filters.more_30kg = event.target.checked;
                break;
            }
            case 'more_40kg': {
                this.filters.more_40kg = event.target.checked;
                break;
            }
            case 'before_25kg': {
                this.filters.before_25kg = event.target.checked;
                break;
            }
            case 'before_30kg': {
                this.filters.before_30kg = event.target.checked;
                break;
            }
            case 'before_40kg': {
                this.filters.before_40kg = event.target.checked;
                break;
            }
            case 'is_important': {
                this.filters.is_important = event.target.checked;
                break;
            }
            case 'was_postponed': {
                this.filters.was_postponed = event.target.checked;
                break;
            }
            case 'is_verified': {
                this.filters.is_verified = event;
                break;
            }
            case 'is_vip_delivery_shipment': {
                this.filters.is_vip_delivery_shipment = event;
                break;
            }
            case 'prepayed': {
                this.filters.prepayed = event.target.checked;
                break;
            }
            case 'load_orders': {
                this.filters.load_orders = event.target.checked;
                break;
            }
            case 'load_zorders': {
                this.filters.load_zorders = event.target.checked;
                break;
            }
            case 'without_courier': {
                this.filters.without_courier = event.target.checked;
                break;
            }


        }
    }

    onSetSelectCourier(user: User) {
        this.newCourier = user;
    }


    setPolygonProps(polygon, zone) {
        polygon.options.set({
            fillColor: (zone.color ? zone.color : '#554433'),
            strokeColor: '#3076B4',
            opacity: 0.5,
            strokeWidth: 1,
            objectID: zone.id,
            zone_id: zone.zone_id, // Место
            shelf: zone.shelf, // Полка
            district_id: zone.district_id, // Направление ид
            district_name: (zone.district ? zone.district.name : ''), // Направление имя
            tarif: zone.tarif
        });
    }


    getRouteCourier(zone_id) {
        this.loadRoute = true;
        this.api.getRouteCourier({
            zone_id: zone_id,
            delivery_date: this.helper.formatDateForSQL(this.inputDate.nativeElement.value)
        }).subscribe(data => {

            this.courierRoute = data;
            this.courierRoute['zone_id'] = zone_id;
            this.loadRoute = false;
            this.bodyClick();
        })
    }

    getPointsCount() {
        const query = this.makeQuery();
        this.pointsCount = -1;
        this.api.getPointCount(query + '&count=1').subscribe(data => {

            this.pointsCount = data;
            this.bodyClick();
            console.log(this.pointsCount);
        });
    }

    onChangeHub(hub) {
        this.selectedHub = hub;
        this.appUserSelectEventsSubjectEmit();
        this.hubChanged = true;
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
    }

    onChangeShift(shifts) {
        this.filters.shifts = shifts;
        this.setListCourierByShift(shifts)
    }

    onChangeShiftGlobal(shift: number[]) {
        this.setListCourierByShift(shift)
        this.filters.shiftsGlobal = shift;
    }

    /**
     * Установка списка курьеров
     * в соответствии с выбранной сменой
     * @param shifts
     */
    setListCourierByShift(shifts: number[]) {

        if (shifts.length === 1) {
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: shifts[0]});
            return;
        }
        this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: []});

    }
}


