import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';
import {OrderDialogComponent} from './order-dialog/order-dialog.component';
import {Order} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {HelpersService} from '../../service/helpers.service';
import {HubsService} from '../../service/hubs.service';

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {
    @Output() onClosed = new EventEmitter();
    @Output() onTransfer = new EventEmitter();
    @Output() onProblemResolved = new EventEmitter();
    @Input('orderUid') public orderUid: string;

    public loading = false;
    public user;

    constructor(
        protected api: LaraService,
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        protected hubsService: HubsService,
        public helper: HelpersService
    ) {
        this.currentUser.get().subscribe(data => {
            this.user = data;
        })
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getOrder(this.orderUid).subscribe(async (data: Order) => {
            this.app.createDialog(OrderDialogComponent, {
                order: await this.hubsService.defineOrderHubs(data),
                parent: this
            });
            /** Костыль который жизненно необходим
             * для появления карточки заказа на карте логистов
             */
            document.body.click();
            this.loading = false;
        });
    }

    public DialogClosed() {
        this.onClosed.emit();
    }

    public DialogOnTransfer() {
        this.onTransfer.emit();
    }

    public DialogOnProblemResolved () {
        this.onProblemResolved.emit();
    }
}
