import {Component, Input} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-act-excel',
    templateUrl: './act-excel.component.html',
    styleUrls: ['./act-excel.component.scss'],
})
export class ActExcelComponent {
    public isLoading = false;
    @Input('number') public number: string;

    constructor(
        private notifier: NotifierService,
        private fileDownloadService: FileDownloadService,
    ) {
    }

    loadExcelAct() {
        this.isLoading = true;
        this.notifier.openNotifier('Генерация файла excel...');
        const fileName = 'Акт - ' + this.number;
        this.fileDownloadService
            .downloadFile('/storage/acts/ppActPrintExcel/' + this.number, fileName + '.xls', 'post', this.number)
            .subscribe(response => {
                this.isLoading = false;
                this.notifier.openNotifier(fileName + ' сгенерирован', null, {class: 'success', duration: 3000});
            }, error => {
                this.isLoading = false;
            });
    }
}
