import {Component, OnInit, ViewChild} from '@angular/core';
import {StatusSelectComponent} from '../../components/status-select/status-select.component';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query/dist/npm/state';
import {FormControl, FormGroup} from '@angular/forms';
import {Hub, ServiceRequest} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {DatePipe} from '@angular/common';
import {EventerService} from '../../service/eventer.service';
import {HelpersService} from '../../service/helpers.service';
import {HubsService} from '../../service/hubs.service';
import {ServicesApiService} from '../services-api.service';

@Component({
    selector: 'app-service-requests-list',
    templateUrl: './service-requests-list.component.html',
    styleUrls: ['./service-requests-list.component.scss'],
})
export class ServiceRequestsListComponent implements OnInit {

    @ViewChild('input_status') statusSelectComp: StatusSelectComponent;

    public selectedStatuses: number[] = [];
    public loading = false;
    public ordersGridData: GridDataResult;
    public ordersGridState: State = {
        take: 2,
        skip: 0,
    }
    public orders = [];
    public formFilter: FormGroup;
    public today = new Date();
    public tomorrow = new Date(+new Date() + 86400000);

    public selectedHubs: Hub[] = [];
    public selectedHubId: number;

    public hubsList: Hub[] = null;
    public pageSizeDefault = 10;
    public pageSize = this.pageSizeDefault;
    public selectedRequests = [];

    public readonly statuses = ServiceRequest.STATUSES;
    public readonly statusesIds = this.statuses.map(status => status.id);
    public readonly statusesNames = this.statuses.map(status => status.name);
    public newServiceRequestStatus = null;

    constructor(
        private api: ServicesApiService,
        private currentUserService: CurrentUserService,
        private datePipe: DatePipe,
        private eventerService: EventerService,
        public helpers: HelpersService,
        private hubsService: HubsService,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.selectedStatuses = [2, 3, 4, 5];
        this.statusSelectComp.selectedStatuses = this.selectedStatuses;

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });

        const hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;

        this.formFilter = new FormGroup({
            clients: new FormControl(),
            statuses: new FormControl(this.selectedStatuses),
            hub_id: new FormControl(hub.id),
            date_from: new FormControl(this.datePipe.transform(this.today, 'yyyy-MM-dd')),
            date_to: new FormControl(this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd')),
        });

        this.listRequests();
    }

    onSearchRequests() {
        this.listRequests();
    }

    onSelectClient(event) {
        this.formFilter.controls.clients.setValue(event.map(client => client.id));
    }

    onSelectStatus(event) {
        this.formFilter.controls.statuses.setValue(event);
    };

    onChangeHub(hub) {
        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;
        this.formFilter.controls.hub_id.setValue(hub.id);
    }

    onPageChange({skip, take}: PageChangeEvent): void {
        if (this.loading) {
            return;
        }

        this.ordersGridState.skip = skip;
        this.ordersGridState.take = take;

        this.listRequests();
    }

    onClickMassSetServiceRequestStatus() {
        if (!this.newServiceRequestStatus || !this.selectedRequests.length) {
            return;
        }

        this.loading = true;
        this.api.massSetServiceRequestStatus({
            service_request_ids: this.selectedRequests,
            status: this.newServiceRequestStatus,
        }).subscribe(data => {
            this.selectedRequests = [];
            this.newServiceRequestStatus = null;

            this.listRequests();
        }, () => {
            this.loading = false;
        });
    }

    private listRequests() {
        this.loading = true;
        const filter = {
            take: this.ordersGridState.take,
            skip: this.ordersGridState.skip,
            ...this.formFilter.value,
        };

        if (filter.date_from) {
            filter.date_from = this.datePipe.transform(filter.date_from, 'yyyy-MM-dd');
        } else {
            filter.date_from = null;
        }

        if (filter.date_to) {
            filter.date_to = this.datePipe.transform(filter.date_to, 'yyyy-MM-dd');
        } else {
            filter.date_to = null;
        }

        this.api.listRequests(filter).subscribe(responsePayload => {
            this.ordersGridState.take = responsePayload.per_page;
            this.ordersGridState.skip = responsePayload.skip;

            responsePayload.data.map(serviceRequest => {
                const summary = {};
                serviceRequest.services.map(service => {
                    if (undefined === summary[service.type.category.code]) {
                        summary[service.type.category.code] = {
                            name: service.type.category.name,
                            services: {},
                        }
                    }

                    if (undefined === summary[service.type.category.code].services[service.type.code]) {
                        summary[service.type.category.code].services[service.type.code] = {
                            name: service.type.name,
                            quantity: 0,
                        }
                    }

                    summary[service.type.category.code].services[service.type.code].quantity += service.quantity;
                });

                serviceRequest.summary = summary;
            });
            this.ordersGridData = responsePayload;

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
