import {Directive, OnDestroy, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent} from '@progress/kendo-angular-grid';
import {OrdersService} from '../service/orders.service';
import {OrdersListService} from './orders-list.service';
import {Subscription} from 'rxjs';
import {OrderLoadingService} from './order-loading.service';
import {OrdersRequest} from '../service/models';
import {DatePipe} from '@angular/common';

@Directive({
    selector: '[appOrdersListDirective]'
})
export class OrdersListDirective extends DataBindingDirective implements OnInit, OnDestroy {
    protected query: OrdersRequest = new OrdersRequest();

    private serviceSubscription: Subscription;
    private loadingSubscription: Subscription;
    constructor(
        protected api: OrdersService,
        protected grid: GridComponent,
        protected orders: OrdersListService,
        private eventer: OrderLoadingService,
        protected datePipe: DatePipe
    ) {
        super(grid);
    }

    public ngOnInit(): void {
        this.serviceSubscription = this.orders.subscribe((result) => {
            this.grid.loading = false;
            this.grid.data = result;
            this.notifyDataChange();
        });

        this.loadingSubscription = this.eventer.loading.subscribe((data: OrdersRequest) => {
            if (data) {
                this.query = data;
            }
            this.rebind(true);
        });

        super.ngOnInit();

        this.query.delivery = {from: this.datePipe.transform(new Date(), 'yyyy-MM-dd')};
        // this.rebind(true);
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
            this.loadingSubscription.unsubscribe();
        }
    }

    public rebind(resetPage = false): void {
        this.query.map = false;
        if (!this.query) {
            this.query = new OrdersRequest();
        }
        this.grid.loading = true;

        this.query.limit = this.state.take;

        if (resetPage) {
            this.query.page = 1;
        } else {
            this.query.page = this.state.skip / this.state.take + 1;
        }
        const sort = {};
        if (this.state.sort) {
            for (const field of this.state.sort) {
                sort[field.field] = field.dir;
            }
        }
        this.query.sort = sort;

        this.orders.query(this.query);
        this.eventer.request.emit();
    }
}
