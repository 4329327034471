import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from '../../service/helpers.service';
import {ReturnActService} from '../../service/return-act.service';
import {ClientInfo, ReturnAct, User} from '../../service/models';
import {EventerService} from '../../service/eventer.service';
import {CurrentUserService} from '../../service/current-user.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-return-acts-transfer-courier',
    templateUrl: './return-acts-transfer-courier.component.html',
    styleUrls: ['./return-acts-transfer-courier.component.scss'],
})
export class ReturnActsTransferCourierComponent implements OnInit {
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public isLoading = false;
    public gridData = [];
    public formFilter: FormGroup;
    public formSearchFilter: FormGroup;
    public selectedHub;
    @ViewChild('date_start_input') dateStartInput: ElementRef;
    @ViewChild('date_end_input') dateEndInput: ElementRef;
    public isActiveAlert = false;
    public alertMessage = null;
    public alertClass = 'alert-success';
    public scanData;

    public returnReasonId = null;
    public returnActModel = ReturnAct;
    constructor(
        public api: ReturnActService,
        public helpers: HelpersService,
        protected eventer: EventerService,
        private currentUserService: CurrentUserService,
        public datePipe: DatePipe,
        public notifier: NotifierService,
    ) {
        this.formFilter = new FormGroup(
            {
                hub_id: new FormControl(this.currentUserService.getCurrentHub().id),
                courier_ids: new FormControl([]),
                client_ids: new FormControl([]),
                zorder_himself: new FormControl(0),
                hour_start: new FormControl(null),
                hour_end: new FormControl(null),
                min_start: new FormControl(null),
                min_end: new FormControl(null),
                date_start: new FormControl(null),
                date_end: new FormControl(null),
            },
        );

        this.formSearchFilter = new FormGroup(
            {
                input: new FormControl(null),
                is_auto_transfer_for_delivery: new FormControl(true)
            }
        )
        this.eventer.primaryHubChange.subscribe((hub) => {
            this.formFilter.controls.hub_id.setValue(hub.id);
            this.selectedHub = hub;
            this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
        });
    }

    ngOnInit(): void {
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});

        const startDate = new Date().setDate(new Date().getDate() - 1);
        this.formFilter.controls.date_start.setValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
        this.dateStartInput.nativeElement.value = this.datePipe.transform(startDate, 'dd.MM.yyyy');

        const endDate = new Date();
        this.formFilter.controls.date_end.setValue(this.datePipe.transform(endDate, 'yyyy-MM-dd'));
        this.dateEndInput.nativeElement.value = this.datePipe.transform(endDate, 'dd.MM.yyyy');
        setTimeout(() => {
            this.setFocusOnSearchInput();
        }, 0);
    }

    onScan() {
        this.isActiveAlert = false;
        this.api.scanReturnActBeforeTransferForDelivery(this.formSearchFilter.value).subscribe(data => {
            this.alertClass = data.alertClass;
            this.alertMessage = data.alertMessage;
            this.scanData = data;
            this.isActiveAlert = true;
            this.formSearchFilter.controls.input.setValue(null);

            if (
                this.formSearchFilter.value.is_auto_transfer_for_delivery &&
                !data.isError &&
                data.isCanTransferCourierToDelivery &&
                !data.isCanPickupSelf
            ) {
                this.transferActForDelivery();
            }
            this.onClickBtnSearch();
        })
    }

    setFocusOnSearchInput()
    {
        document.getElementById('scan_input').focus();
        document.getElementById('scan_input').click();
    }

    onChangeCourierSelect(couriers: User[]) {
        this.formFilter.controls.courier_ids.setValue(couriers.map(x => x.id));
    }

    onChangeClientSelect(clients: ClientInfo[]) {
        this.formFilter.controls.client_ids.setValue(clients.map(x => x.id));
    }

    onClickBtnSearch() {
        if (!this.formFilter.value.date_start) {
            return this.notifier.openNotifier('Не указан день начала');
        }

        if (!this.formFilter.value.date_end) {
            return this.notifier.openNotifier('Не указан день окончания выборки');
        }


        this.isLoading = true;

        this.formFilter.controls.date_start.setValue(
            this.datePipe.transform(this.formFilter.controls.date_start.value, 'yyyy-MM-dd')
        );

        this.formFilter.controls.date_end.setValue(
            this.datePipe.transform(this.formFilter.controls.date_end.value, 'yyyy-MM-dd')
        );
        this.api.getReturnActsForWithZorder(this.formFilter.value).subscribe(data => {
            console.warn(data);
            this.gridData = data;
            this.isLoading = false;
            this.setFocusOnSearchInput();
        }, error => {
            this.isLoading = false;
        })
    }

    onHourChange(control, event) {
        this.formFilter.controls[control].setValue(new Date(event).getHours());
    }

    onDateChange(control, event) {
        this.formFilter.controls[control].setValue(event.value);
    }

    onMinChange(control, event) {
        this.formFilter.controls[control].setValue(new Date(event).getMinutes());
    }

    transferActForDelivery() {
        this.isActiveAlert = false;
        this.notifier.openNotifier('Передача акта ' + this.scanData.returnAct.old_vact_id + ' на доставку...')
        this.api.transferActForDelivery({
            old_vact_id: this.scanData.returnAct.old_vact_id,
            date_start: this.formFilter.value.date_start,
            date_end: this.formFilter.value.date_end
        }).subscribe(data => {
            this.onClickBtnSearch();
                this.alertClass = data.alertClass;
                this.alertMessage = data.alertMessage;
                this.scanData = data;
                this.isActiveAlert = true;
        })
    }

    transferActToClient() {
        this.isActiveAlert = false;
        this.notifier.openNotifier('Передача акта ' + this.scanData.returnAct.old_vact_id + ' клиенту...')
        this.api.transferActToClient({
            old_vact_id: this.scanData.returnAct.old_vact_id
        }).subscribe(data => {
            this.onClickBtnSearch();
            this.notifier.openNotifier(data.message, null, {class: 'success', duration: 1500});
        })
    }

    returnFromDeliveryReturnAct(returnReasonId, detachFromZorder) {
        console.warn(this.scanData);
        this.isActiveAlert = false;
        this.notifier.openNotifier('Возврат акта ' + this.scanData.returnAct.old_vact_id + ' с доставки...')
        this.api.returnFromDeliveryReturnAct({
            old_vact_id: this.scanData.returnAct.old_vact_id,
            return_reason_id: returnReasonId,
            detach_from_zorder: detachFromZorder,
        }).subscribe(data => {
            this.onClickBtnSearch();
            this.notifier.openNotifier(data.message, null, {class: 'success', duration: 1500});
        })
    }

    onChangeReturnReasonSelect(returnReasonIds: number[]) {
        this.returnReasonId = returnReasonIds.length > 0 ? returnReasonIds[0] : null;
    }

    attachReturnActToZorder(zorderId, returnAct) {
        this.notifier.openNotifier('Привязка возвратного акта ' +  returnAct.old_vact_id + ' к забору...')
        this.api.attachReturnActToZorder(returnAct.id, zorderId).subscribe(x => {
            this.alertClass = 'alert-success';
            this.alertMessage = 'Возвратный акт ' + returnAct.old_vact_id + ' привязан к забору ' + zorderId;
            this.scanData = {
                isError: true,
                returnAct: returnAct
            };
            this.isActiveAlert = true;
        });
    }
}
