import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class StaffService extends LaraService {
    /** Получение списка статусов терминалов */
    getTerminalStatuses() {
        return this.get('/personal/terminal/statusesList');
    }

    /**
     * Список терминалов
     * @param data
     */
    getTerminals(data) {
        return this.post('/personal/terminal', data);
    }

    /**
     * Получение терминала по id
     * @param terminal_id
     */
    getTerminalById(terminal_id: number) {
        return this.get('/personal/terminal/id/' + terminal_id);
    }

    /**
     * Обновление / создание терминала
     * @param terminal_id
     * @param data
     */
    updateOrCreateTerminal(terminal_id: number|null, data) {
        return this.post('/personal/terminal/id/' + terminal_id, data);
    }

    /**
     * Удаление терминала
     * @param terminal_id
     */
    removeTerminal(terminal_id: number) {
        return this.post('/personal/terminal/remove/' + terminal_id, []);
    }

    /**
     * Получение логов терминала
     * @param terminal_id
     */
    getTerminalLogs(terminal_id: number) {
        return this.get('/personal/terminal/log/' + terminal_id);
    }

    /**
     * Получение терминалов курьера
     * @param courier_id
     */
    getTerminalsCourier(courier_id: number) {
        return this.get('/personal/terminal/courier/' + courier_id);
    }

    /**
     * Установка главного терминала курьера
     * @param courier_id
     * @param data
     */
    setMasterTerminalForCourier(courier_id: any, data) {
        return this.post('/personal/terminal/master/' + courier_id, data);
    }

    /**
     * Установка статуса терминала
     * @param terminal_id
     * @param status
     */
    setTerminalStatus(terminal_id, status) {
        return this.post('/personal/terminal/setStatus/' + terminal_id + '/' + status, []);
    }

    /**
     * Получение свободных терминалов
     */
    getTerminalsFree() {
        return this.get('/personal/terminal/free');
    }
}
