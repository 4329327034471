import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {AddressService} from '../../../service/address.service';
import {AppComponent} from '../../../app.component';
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-address-dialog',
    templateUrl: './address-dialog.component.html',
    styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {


    public address: any;
    public ordersCount: any = null;

    public addressId: number = null;
    public latitude: number = null;
    public longitude: number = null;
    public visible = false;
    public message = '';
    public width;

    public loading = false;

    constructor(
        protected app: AppComponent,
        private api: AddressService,
        public helpers: HelpersService
    ) {
        this.width = window.innerWidth * 0.6;
    }

    ngOnInit() {
        console.log('init');
        console.log(this.address);
        console.log(this.address.original_address);

    }

    changeAddress() {
        if (!confirm('Изменения коснется всех точек с таким адресом! ')) {
            return;
        }
        this.api.setAddressCoordinates(this.addressId, this.latitude, this.longitude).subscribe(data => {
            this.message = 'Адрес изменен';
        });
    }

    onClose() {
        this.app.closeDialog(this);
    }

}
