import {Component, OnInit, } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HelpersService} from '../../../service/helpers.service';
import {parseNumber} from '@progress/kendo-angular-intl';
import {ClientUser} from '../../../service/models';
import {NotifierService} from '../../../service/notifier.service';
import {LaraService} from '../../../service/lara.service';

@Component({
    selector: 'app-client-users-edit',
    templateUrl: './client-users-edit.component.html',
    styleUrls: ['./client-users-edit.component.scss']
})
export class ClientUsersEditComponent implements OnInit {
    public formClientUser: FormGroup;
    public clientUserId = null;
    public save = false;
    public loading = false;

    constructor(
        private api: LaraService,
        private route: ActivatedRoute,
        private router: Router,
        public helper: HelpersService,
        private notifier: NotifierService,
    ) {
        this.formClientUser = new FormGroup(
            {
                is_active: new FormControl(0),
                login: new FormControl(null, Validators.required),
                email: new FormControl(null, Validators.required),
                name: new FormControl(null, Validators.required),
                phone: new FormControl(null, Validators.required),
                new_password: new FormControl(null),
            }
        );
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.clientUserId = parseNumber(params.get('id'));
            if (!this.clientUserId) {
                alert('Пользователь клиента не выбран!');
                this.router.navigate(['/']);
            }
            this.loadClientUser();
        });
    }

    private loadClientUser() {
        this.loading = true;
        this.api.getClientUser(this.clientUserId).subscribe((clientUser: ClientUser) => {
            this.loading = false;
            if (clientUser) {
                this.formClientUser.controls.is_active.setValue(!clientUser.deleted_at);
                this.formClientUser.controls.login.setValue(clientUser.login);
                this.formClientUser.controls.email.setValue(clientUser.email);
                this.formClientUser.controls.name.setValue(clientUser.name);
                this.formClientUser.controls.phone.setValue(clientUser.phone);
            }
        }, error => {
            this.loading = false;
        });
    }


    public onSubmit() {
        if (!this.formClientUser.valid) {
            this.notifier.openNotifier('Заполните все обязательные поля!')
        }
        const query = this.formClientUser.value;
        this.api.updateClientUser(this.clientUserId, query).subscribe(data => {
            this.notifier.openNotifier(data.message);
        });
    }
}
