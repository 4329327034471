import {Component, Inject, Input, OnInit} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

/**
 * <app-audio-player
 *     [src]="'https://s3-us-west-2.amazonaws.com/s.cdpn.io/9473/new_year_dubstep_minimix.ogg'"
 *     [title]="'Звонок от Иванова А.А оператору 018 Луняшиной Марине'">
 * </app-audio-player>
 */
@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
    private AUDIO_PLAYER_VOLUME = 'audio-player-volume';
    public isDialog = true;
    @Input() public src: string = null;
    @Input() public title: string = null;
    @Input() isCloseWindow = true;
    @Input() public left: number;
    @Input() public isTopAuto = false;
    public playerVolume = 0.1;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) {
    }

    ngOnInit() {
        this.updatePlayerValue(this.getPlayerVolumeFromStorage());
    }

    onPlayVolumeChange(event) {
        if (!event) {
            return;
        }
        const volume = event.target.volume;
        this.updatePlayerValue(volume);
        this.setPlayerVolumeToStorage(volume);
    }

    getPlayerVolumeFromStorage() {
        return this.storage.get(this.AUDIO_PLAYER_VOLUME) ? this.storage.get(this.AUDIO_PLAYER_VOLUME) : 0.1;
    }

    setPlayerVolumeToStorage(volume) {
        return this.storage.set(this.AUDIO_PLAYER_VOLUME, volume);
    }

    onOpenWindow() {
        this.updatePlayerValue(this.getPlayerVolumeFromStorage());
    }

    updatePlayerValue(value) {
        this.playerVolume = value;
    }
}
