import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {DialogService} from '../dialog/dialog.service';
import {NotifierService} from '../../service/notifier.service';

export interface CourierQueueStatusInterface {
    old_courier_id: number;
    time_come?: string;
    queue_status?: number;
    queue_id?: number;
}

@Component({
    selector: 'app-set-courier-arrived-for-work',
    templateUrl: './set-courier-arrived-for-work.component.html',
    styleUrls: ['./set-courier-arrived-for-work.component.scss'],
})
export class SetCourierArrivedForWorkComponent implements OnInit {
    @Input('courier') public courier: CourierQueueStatusInterface | null = null;

    @Output() courierQueueStatusChanged = new EventEmitter<CourierQueueStatusInterface>();

    public loading = false;
    private dialogSubscription = null;

    constructor(
        private api: LaraService,
        private dialog: DialogService,
        private notifier: NotifierService,
    ) {
    }

    ngOnInit() {
    }

    onClickSetCourierArrivedForWork() {
        this.dialogSubscription = this.dialog
            .confirm('Отметить приход курьера ' + this.courier.old_courier_id + ' на работу?')
            .subscribe(confirmed => {
                this.dialogSubscription.unsubscribe();

                if (!confirmed) {
                    return;
                }

                this.loading = true;
                this.api.setCourierArrivedForWork(this.courier.old_courier_id).subscribe((response) => {
                    this.notifier.openNotifier(response.message, null, {duration: 3000});

                    this.courier.queue_status = response.queue_status;
                    this.courier.time_come = response.time_come;

                    const eventPayload = <CourierQueueStatusInterface>{
                        old_courier_id: this.courier.old_courier_id,
                        queue_status: response.queue_status,
                        queue_id: response.queue_id,
                        time_come: response.time_come,
                    }

                    this.courierQueueStatusChanged.emit(eventPayload);

                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
            });
    }
}
