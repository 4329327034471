import {Component, Input} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-act-diff',
    templateUrl: './act-diff.component.html',
    styleUrls: ['./act-diff.component.scss'],
})
export class ActDiffComponent {
    public isLoading = false;
    @Input('number') public number: string;

    constructor(
        private notifier: NotifierService,
        private fileDownloadService: FileDownloadService,
    ) {
    }

    loadExcelDiffAct() {
        this.isLoading = true;
        this.notifier.openNotifier('Генерация файла расхождений по акту ' + this.number + ' в excel...');
        const fileName = 'Акт расхождений - ' + this.number;
        this.fileDownloadService
            .downloadFile('/storage/acts/diffActForPpAct/' + this.number, fileName + '.xls', 'post', this.number)
            .subscribe(response => {
                this.notifier.openNotifier(fileName + ' сгенерирован', null, {class: 'success', duration: 3000});
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            });
    }
}
