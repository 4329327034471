import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {HelpersService} from '../../service/helpers.service';
import {BankPaymentOrder} from '../../finance/finance-models';

class CodTypeItem {
    id: number;
    name: string;
}

@Component({
    selector: 'app-cod-type-select',
    templateUrl: './cod-type-select.component.html',
    styleUrls: ['./cod-type-select.component.scss'],
})
export class CodTypeSelectComponent implements OnInit, OnDestroy {
    @Input() public placeholder = 'Тип НП';
    @Input() public types: number[] = [];
    @Input() public selectedTypes: number[] = [];
    @Input() public selectedStatus: number = null;
    @Input() public customTypesNames = null;
    @Input() events: Observable<void>;
    @Output() onChange = new EventEmitter<number | number[]>();
    public typesList: CodTypeItem[] = [];
    public selectedItems: any[] = [];
    public settings: IDropdownSettings = {};
    @Input() protected multi = false;
    @Input() protected disabled = false;
    @Input() protected showLimit;
    private eventsSubscription: Subscription;

    constructor(
        protected helper: HelpersService,
    ) {
    }

    ngOnInit() {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEvents(data));
        }

        this.settings = {
            idField: 'id',
            textField: 'name',
            singleSelection: !this.multi,
            closeDropDownOnSelection: !this.multi,
            itemsShowLimit: this.showLimit ? this.showLimit : 2,
            defaultOpen: false,
        };

        if (!this.types.length) {
            this.types = BankPaymentOrder.COD.map(type => type.id);
        }

        let name = '';
        let i = 0;

        for (const id of this.types) {
            name = this.getStatusName(id, i);
            i++;

            this.typesList.push({
                id,
                name,
            });
        }

        this.setSelectedTypes(this.selectedTypes);
    }

    getStatusName(type: number, index: number) {
        if (this.customTypesNames && (this.customTypesNames.length > index)) {
            return this.customTypesNames[index];
        }

        return this.helper.getCodTypesName(type);
    }

    setSelectedTypes(types: number[]) {
        this.selectedTypes = types;

        this.selectedItems = [];
        let name = '';
        let i = 0;

        for (const id of this.types) {
            name = this.getStatusName(id, i);

            if (this.selectedTypes.indexOf(id) > -1) {
                this.selectedItems.push({
                    id,
                    name,
                });
            }

            i++;
        }
    }

    public onSelectType(event: CodTypeItem) {
        this.selectedTypes.push(event.id);
        this.onChangeType();
    }

    public onSelectAllTypes() {
        this.selectedTypes = this.typesList.map(type => type.id);
        this.onChangeType();
    }

    public onDeSelectType(event: CodTypeItem) {
        this.selectedTypes = this.selectedTypes.filter(type => {
            return type !== event.id;
        });

        this.onChangeType();
    }

    public onDeSelectAllTypes() {
        this.selectedTypes = [];
        this.onChangeType();
    }

    onChangeType() {
        this.onChange.emit(this.selectedTypes);
    }

    onEvents(data) {
        switch (data.type) {
            case 'typesListChanged':
                this.typesList = [];
                let name = '';
                let i = 0;
                this.customTypesNames = data.customTypesNames;
                this.types = data.types;
                for (const id of this.types) {
                    name = this.getStatusName(id, i);
                    i++;

                    this.typesList.push({
                        id,
                        name,
                    });
                }
        }
    }

    ngOnDestroy(): void {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }
}
