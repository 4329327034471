import {Component, ComponentFactoryResolver, Input, OnInit, } from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {ApiClientInfo} from '../../service/api_result';
import {ClientDialogComponent} from './client-dialog/client-dialog.component';
import {AppComponent} from '../../app.component';
import {HubsService} from '../../service/hubs.service';

@Component({
    selector: 'app-client-card',
    templateUrl: './client-card.component.html',
    styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent implements OnInit {

    @Input('client_id') public client_id: string | number;

    public loading = false;

    constructor(
        private api: LaraService,
        private resolver: ComponentFactoryResolver,
        private hubsService: HubsService,
        private app: AppComponent
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getClientInfo(this.client_id).subscribe(async (data: ApiClientInfo) => {
            data.client.default_hub = await this.hubsService.find(data.client.default_hub_id);
            console.log(data);
            this.app.createDialog(ClientDialogComponent, {
                clientInfo: data
            });
            this.loading = false;
        });
    }
}
