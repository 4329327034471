import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {PickupPointApiService} from '../pickup-point-api.service';
import {Location} from '@angular/common';
import {HelpersService} from '../../service/helpers.service';
import {Order} from '../../service/models';

enum UIScreens {
    SCREEN_LOOKUP_ORDER = 0,
    SCREEN_ASSEMBLING = 1,
    SCREEN_ACKNOWLEDGE = 2,
    SCREEN_PAYMENT = 3,
    SCREEN_SUCCESS = 4,
}

enum CloseTypes {
    DELIVERY = Order.STATUS_FINISH,
    PARTIAL = Order.STATUS_FAIL_PARTIAL,
    REFUSE = Order.STATUS_REFUSE,
}

@Component({
    selector: 'app-pickup-point-order-shipping',
    templateUrl: './pickup-point-order-shipping.component.html',
    styleUrls: ['./pickup-point-order-shipping.component.scss'],
})
export class PickupPointOrderShippingComponent implements OnInit {
    @Input('uid') public uid = null;

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('barCodeInput') barCodeInput: ElementRef;

    public order = null;
    public loading?: number = null;
    public searchTerm: string | null = null;
    public searchResult: any[] | null = null;
    public canViewDetails = false;

    public uiScreen = UIScreens.SCREEN_LOOKUP_ORDER;
    public readonly uiScreens = UIScreens;
    public closeType = null;
    public readonly closeTypes = CloseTypes;

    public formLookup: FormGroup;
    public formChecklist: FormGroup;
    public recentlyScannedBarcodeId = null;
    public recentlyScannedAgain = null;

    public assembled = false;

    public docsExists = null;
    public docsRequired = null;

    public pretensionRequired = null;
    public pretensionExists = null;

    public scannedBarcodes = [];
    public refusedGoods = [];

    public smsCode = '';
    public paymentSuccess = false;


    constructor(
        private api: PickupPointApiService,
        public helpers: HelpersService,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.formLookup = new FormGroup({
            search: new FormControl(''),
            force_uid: new FormControl(false),
        });

        this.formChecklist = new FormGroup({
            barcode: new FormControl(''),
        });
    }

    lookupOrder(search, force_uid = false) {
        this.searchTerm = search;
        this.loading = UIScreens.SCREEN_LOOKUP_ORDER;
        this.searchResult = null;
        this.order = null;

        this.api.lookupOrder({
            search,
            force_uid,
        }).subscribe(orders => {
            this.searchResult = orders;
            if (orders.length) {
                if (1 === orders.length) {
                    this.order = orders[0];
                }
            }

            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    ngOnInit() {
        this.barCodeInputFocus.bind(this);

        this.route.paramMap.subscribe((params: ParamMap) => {
            const uid = params['params'].uid;
            if ('undefined' !== typeof uid) {
                this.formLookup.controls.search.setValue(this.uid);
                if (uid !== this.order.uid) {
                    const url = this.router.createUrlTree(['pickup-point', 'shipping', uid]).toString();
                    this.location.go(url);
                }

                this.lookupOrder(uid);
            }
        });

        this.canViewDetails = this.helpers.checkPermissions('orders:view');

        // TODO Router: inbound param
        // TODO Router: sync with current order
        // TODO Router: external inbound
    }

    onLaunchLookupOrder() {
        const search = this.formLookup.controls.search.value;
        if (null !== search && search.trim().length > 0) {
            this.lookupOrder(search.trim())
        }
    }

    onClickBeginShipping() {
        this.beginAssembling();
    }

    onClickBeginAcknowledge() {
        this.beginAcknowledge();
    }

    /**
     * Обработка клика по кнопке сканирования штрихкода или ввода символа ввода в поле штрихкода
     */
    onLaunchCheckBarcode() {
        if (this.formChecklist.value.barcode.length > 0) {
            this.checkBarcode(this.formChecklist.value.barcode);
        }
    }

    checkBarcode(barcode) {
        this.loading = UIScreens.SCREEN_ASSEMBLING;

        this.recentlyScannedBarcodeId = null;
        this.recentlyScannedAgain = null;

        this.api.checkBarcode(this.order.uid, {barcode}).subscribe(data => {
            if (data.order || data.barcode) {

                let barCodeId;
                if (data.barcode) {
                    barCodeId = data.barcode.id;
                } else {
                    barCodeId = data.order.bar_codes[0].id;
                }

                this.recentlyScannedBarcodeId = barCodeId;
                if (this.scannedBarcodes.includes(barCodeId)) {
                    this.recentlyScannedAgain = true;
                } else {
                    this.scannedBarcodes.push(barCodeId);
                }
            }

            const debt = this.order.bar_codes.filter(loopBarcode => {
                return !this.scannedBarcodes.includes(loopBarcode.id);
            });

            this.assembled = !debt.length;

            if (this.assembled) {
                // if doc return then should answer yes
                // if can't answer yes then should call client service
            }

            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    getBarcodeClasses(barcode) {
        if (this.uiScreen !== UIScreens.SCREEN_ASSEMBLING) {
            return;
        }

        if (this.recentlyScannedBarcodeId === barcode.id) {
            if (this.recentlyScannedAgain) {
                return 'bg-dark text-info';
            }

            return 'bg-info';
        }

        if (this.scannedBarcodes.includes(barcode.id)) {
            return 'table-success';
        }

        return;
    }

    setOrder(order, toggleMode = false) {
        if (toggleMode) {
            if (this.order && this.order.id === order.id) {
                this.order = null;
                return;
            }
        }

        this.docsRequired = order.option.option_returned_doc;

        // TODO Docs: port valid way to determine if docs are required

        this.order = order;
    }

    toggleGoodRefuse(good) {
        if (this.uiScreen !== UIScreens.SCREEN_ACKNOWLEDGE || !this.order.option.option_buyback) {
            return;
        }

        if (!this.refusedGoods.includes(good.id)) {
            this.refusedGoods.push(good.id);
        } else {
            this.refusedGoods = this.refusedGoods.filter(loopGoodId => loopGoodId !== good.id);
        }

        if (this.refusedGoods.length === this.order.goods.length && this.closeType === CloseTypes.PARTIAL) {
            this.closeType = null;
            this.pretensionRequired = false;
            this.pretensionExists = false;
        }
    }

    onChangeCloseType() {
        this.pretensionRequired = this.closeType !== CloseTypes.DELIVERY;
        this.pretensionExists = false;
    }

    onClickBeginPayment() {
        if (null === this.closeType || (this.closeType !== CloseTypes.DELIVERY && !this.pretensionExists)) {
            return
        }

        this.beginPayment();
    }

    onClickPretensionFormed() {
        if (this.uiScreen !== UIScreens.SCREEN_ACKNOWLEDGE) {
            return;
        }

        this.pretensionExists = true;
    }

    beginPayment() {
        if (this.order.bill.is_np) {
            return;
        }

        this.loading = UIScreens.SCREEN_ACKNOWLEDGE;
        this.sendSMSWithCode();
    }

    onClickResendSSMWithCode() {
        this.resendSMSWithCode();
    }

    resendSMSWithCode() {
        this.loading = UIScreens.SCREEN_PAYMENT;
        this.sendSMSWithCode(true);
    }

    sendSMSWithCode(resend = null) {
        const params: any = {};
        if (resend) {
            params.resend = true;
        }

        this.api.sendSMSWithCode(this.order.uid, params).subscribe(() => {
            this.uiScreen = UIScreens.SCREEN_PAYMENT;
            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    onLaunchCheckCodeFromSMS() {
        if (this.smsCode.trim().length > 0) {
            this.checkCodeFromSMS();
        }
    }

    checkCodeFromSMS() {
        this.loading = UIScreens.SCREEN_PAYMENT;
        this.api.checkCodeFromSMS(this.order.uid, {code: this.smsCode}).subscribe(data => {
            if (data && data.uid && data.uid === this.order.uid) {
                this.paymentSuccess = true;
            }

            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    onClickFinishShipment() {
        this.finishShipment();
    }

    finishShipment() {
        this.loading = UIScreens.SCREEN_PAYMENT;
        this.api.finishShipping(this.order.uid).subscribe(() => {
            this.uiScreen = UIScreens.SCREEN_SUCCESS;
            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    private beginAcknowledge() {
        if (!this.assembled || (this.docsRequired && !this.docsExists)) {
            return;
        }

        this.loading = UIScreens.SCREEN_ASSEMBLING;
        this.api.finishAssembling(this.order.uid, {}).subscribe(() => {
                this.uiScreen = UIScreens.SCREEN_ACKNOWLEDGE;

                this.loading = null;
            }, () => {
                this.loading = null;
            },
        );
    }

    private resetShipmentState() {
        this.scannedBarcodes = [];
        this.recentlyScannedAgain = false;
        this.recentlyScannedBarcodeId = null;

        this.docsRequired = null;
        this.docsExists = null;

        this.refusedGoods = [];
        this.pretensionRequired = null;
        this.pretensionExists = null;

        this.closeType = null;

        this.smsCode = '';
        this.paymentSuccess = false;
    }

    private beginAssembling() {
        if (!this.order) {
            return;
        }

        if (this.order.pickup_point_status !== 3) {
            return;
        }

        if (this.order.bill.is_np) {
            return;
        }

        this.resetShipmentState();
        this.loading = UIScreens.SCREEN_ASSEMBLING;

        this.api.beginAssembling(this.order.uid).subscribe(() => {
            this.uiScreen = UIScreens.SCREEN_ASSEMBLING;
            this.barCodeInputFocusDelayed(true);
            this.loading = null;
        }, () => {
            this.loading = null;
        });
    }

    /**
     * Отложенная фокусировка в поле ввода штрихкода
     * @param reset
     * @private
     */
    private barCodeInputFocusDelayed(reset) {
        setTimeout(() => {
            this.barCodeInputFocus(reset);
        }, 500);
    }

    /**
     * Установка фокуса на поле ввода штрихкода
     * @param reset Очищать ли поле ввода
     * @private
     */
    private barCodeInputFocus(reset: boolean = false) {
        if (reset) {
            this.formChecklist.controls.barcode.setValue('');
        }

        if (this.uiScreen === UIScreens.SCREEN_ASSEMBLING) {
            this.barCodeInput.nativeElement.focus();
        }
    }
}
