import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Hub, HubDeliveryInterval} from "../../service/models";
import {HubsService} from "../../service/hubs.service";
import {Observable, Subscription} from "rxjs";


@Component({
    selector: 'app-hub-delivery-interval-select',
    templateUrl: './hub-delivery-interval-select.component.html',
    styleUrls: ['./hub-delivery-interval-select.component.scss']
})
export class HubDeliveryIntervalSelectComponent implements OnInit {
    @Input() events: Observable<void>;
    @Input() public disabled: boolean = false;
    @Input() public enableNotPresentInterval: boolean = false;
    @Input() public selectedInterval: (HubDeliveryInterval | number | null) = null;
    @Input() public filterHub: (Hub | number | null) = null;
    @Input() public shiftNumberFilter: (number | null) = null;
    @Output() onChange = new EventEmitter<HubDeliveryInterval | null>();
    @ViewChild('intervalSelect') intervalSelect: ElementRef;
    public hubDeliveryIntervalsList: HubDeliveryInterval[] = [];
    public selectedDeliveryInterval: (HubDeliveryInterval | null) = null;
    /* события и подписки - чтоб слушаться родителей */
    private eventsSubscription: Subscription;
    private notPresentInterval = new HubDeliveryInterval({
        id: HubDeliveryInterval.NOT_PRESENT_INTERVAL_ID,
    });

    constructor(
        private hubsService: HubsService,
    ) {
    }

    async ngOnInit() {
        await this.applyHub();
        this.applyInterval();
    }

    /**
     * Обработчик изменения интервала в выпадающем меню
     * @param event
     */
    onIntervalChange(event) {
        let selectedInterval = 1 * this.intervalSelect.nativeElement.value;
        this.selectedDeliveryInterval = this.hubDeliveryIntervalsList.find((interval: HubDeliveryInterval) => {
            return interval.id === selectedInterval;
        });
        this.onChange.emit(this.selectedDeliveryInterval);
    }

    /**
     * Обработчик событий от родителей
     * @param data
     */
    onEvents(data) {
        switch (data.type) {
            case 'filterHubUpdated':
                this.filterHub = data.filterHub;
                return this.applyHub();

            case 'selectedIntervalUpdated':
                this.selectedInterval = data.interval;
                return this.applyInterval();
        }
    }

    /**
     * Применение хаба - подгружает в активный список интервалы выбранного хаба
     */
    async applyHub() {
        this.hubDeliveryIntervalsList = [];

        if (null === this.filterHub) {
            return this.onIntervalChange(null);
        }

        if (typeof this.filterHub === "number") {
            this.filterHub = await this.hubsService.find(this.filterHub);
        }

        // если есть фильтр по номеру смены
        if (this.shiftNumberFilter) {
            this.hubDeliveryIntervalsList = this.filterHub.delivery_intervals.filter(interval => {
                return interval.shift_number === this.shiftNumberFilter;
            });
        } else {
            this.hubDeliveryIntervalsList = this.filterHub.delivery_intervals;
        }

        if (this.enableNotPresentInterval) {
            this.hubDeliveryIntervalsList.push(this.notPresentInterval);
        }
    }

    /**
     * Применение интервала - переключает список на выбранный интервал или дефолтный интервал хаба
     */
    applyInterval() {
        let selectedDeliveryInterval = this.hubDeliveryIntervalsList.find(interval => {
            if (typeof this.selectedInterval === 'number') {
                return interval.id === this.selectedInterval;
            } else if (this.selectedInterval.hasOwnProperty('id')) {
                return interval.id === this.selectedInterval.id;
            }
        });

        if (!selectedDeliveryInterval) {
            selectedDeliveryInterval = this.hubDeliveryIntervalsList.find((interval: HubDeliveryInterval) => {
                return interval.is_default_interval;
            });
        }

        let emitOnChange = this.selectedDeliveryInterval !== selectedDeliveryInterval;
        this.selectedDeliveryInterval = selectedDeliveryInterval;
        this.intervalSelect.nativeElement.value = selectedDeliveryInterval.id;

        if (emitOnChange) {
            return this.onChange.emit(this.selectedDeliveryInterval);
        }
    }

}
