import {Component, ComponentRef, EventEmitter, Input, Output} from '@angular/core';
import {AppComponent} from '../../app.component';

import {
    NewBankPaymentOrderDialogComponent,
} from './new-bank-payment-order-dialog/new-bank-payment-order-dialog.component';

@Component({
    selector: 'app-new-bank-payment-order',
    templateUrl: './new-bank-payment-order.component.html',
    styleUrls: ['./new-bank-payment-order.component.scss'],
})
export class NewBankPaymentOrderComponent {
    @Input('clientId') public clientId: number | null = null;
    @Input('agentReportsIds') public agentReportsIds: number[] = [];

    @Output() dialogOpenedChange = new EventEmitter<any>();
    @Output() onDestroy = new EventEmitter<boolean>();

    private dialogRef: ComponentRef<any>;

    constructor(
        private app: AppComponent,
    ) {
    }

    public onOpen() {
        this.dialogRef = this.app.createDialog(NewBankPaymentOrderDialogComponent, {
            clientId: this.clientId || null,
            agentReportsIds: this.agentReportsIds || [],
            parent: this,
        });

        this.dialogOpenedChange.emit(true);

        this.dialogRef.onDestroy(() => {
            this.dialogOpenedChange.emit(false);
            this.onDestroy.emit(true);
        });
    }

    public onClose() {
        this.dialogRef.destroy();
    }
}

