import {Component, OnInit} from '@angular/core';
import {ClientInfo} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LaraService} from '../../service/lara.service';
import {DialogService} from '../../components/dialog/dialog.service';

@Component({
    selector: 'app-act-create',
    templateUrl: './act-create.component.html',
    styleUrls: ['./act-create.component.scss']
})
export class ActCreateComponent implements OnInit {
    public info: FormGroup;
    public data: FormGroup;
    public selectedClient: ClientInfo;
    public orderList: any[];
    public selectedList: any[];

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        this.getOrderList();
    }

    constructor(protected api: LaraService,
                private dialog: DialogService) {
        this.data = new FormGroup({
            selectedOrders: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
    }

    getOrderList() {
        this.selectedList = [];
        this.api.getOrdersWithoutTransAct(this.selectedClient.id).subscribe(data => {
            this.orderList = data;
        })
    }

    getSelectedList() {
        if (this.orderList) {
            this.selectedList = this.data.value;
            console.log(this.selectedList);
        }
    }

    createAct() {
        if (this.selectedList.length == 0) {
            this.dialog.alert('Выберите заказы.');
            return;
        }
        const uids = this.data.value;
        console.log({order_uid: uids.selectedOrders});
        const dialog = this.dialog.confirm('Создать акт?').subscribe(result => {
            if (result) {
                this.api.createTransAct({order_uid: uids.selectedOrders}).subscribe(data => {
                    if (data) {
                        this.dialog.alert('Акт №' + data.trans_act.act_id + ' успешно создан');
                    }
                    this.getOrderList();
                })
            }
            dialog.unsubscribe();
        })
    }
}
