import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {NavigationEnd, Router} from '@angular/router';
import {interval} from 'rxjs';

export interface IOrdersSignaturesVerificationQueueStatsResponse {
    orders_count: number;
    verified_count: number;
    not_verified_count: number;
    effective_count: number;
    no_effective_count: number;
    missing_count: number;
}

@Component({
    selector: 'app-orders-signatures-verification-queue-counter',
    template: `
        <span class="counter"
              [ngClass]="getBadgeClassNames()"
              *ngIf="!loading" (click)="getCounter()">
            <span *ngIf="!failed">{{counter}}</span>
            <span class="far fa-dizzy" *ngIf="failed"></span>
            <i class="fas fa-sync-alt"></i>
        </span>
        <div *ngIf="loading" class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
    `,
    styles: [
        'span.counter {padding: 2px 5px;border-radius: 5px;}',
        'span > span {margin: 0 2px;}',
    ],
})

export class OrdersSignaturesVerificationQueueCounterComponent implements OnInit {
    public counter: number = 0;
    public failed: boolean = false;
    public loading: boolean = false;
    private counters: IOrdersSignaturesVerificationQueueStatsResponse = {
        orders_count: 0,
        verified_count: 0,
        not_verified_count: 0,
        effective_count: 0,
        no_effective_count: 0,
        missing_count: 0,
    }

    constructor(
        private api: LaraService,
        private router: Router,
    ) {
        interval(60000).subscribe(x => this.checkUrl(router.url))
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.checkUrl(val.url);
            }
        });
    }

    ngOnInit() {
        this.getCounter();
    }

    public getCounter() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.failed = false;

        this.api.getOrdersSignaturesVerificationQueueStats().subscribe((responsePayload: IOrdersSignaturesVerificationQueueStatsResponse) => {
            this.counters = responsePayload;
            this.counter = responsePayload.orders_count;
            this.loading = false;
        }, () => {
            this.loading = false;
            this.failed = true;
        });
    }

    private checkUrl(url) {
        const val = url.split('/');
        if (val.indexOf('orders-signatures-verification-queue') > -1) {
            this.getCounter();
        }
    }

    private getBadgeClassNames() {
        if (this.failed || this.counters.missing_count) {
            return 'text-white bg-danger';
        }

        if (!this.counters.orders_count) {
            return 'text-muted border border-muted';
        }

        return 'text-white bg-warning';
    }
}
