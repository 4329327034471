import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class LogisticService extends LaraService {

    /**
     * @param data
     */
    // получить доплаты курьеров
    public getExtraExpenses(data) {
        return this.post('/logistic/getExtraExpenses', data);
    }

    // одобрить доплату
    public approveExpense(id) {
        return this.post('/logistic/approveExpense', {id: id});
    }

    // отменить доплату
    public declineExpense(id) {
        return this.post('/logistic/declineExpense', {id: id});
    }

    public changeExpensePrice(id, price) {
        return this.post('/logistic/changeExpensePrice', {id: id, price: price});
    }

    public getExpensePhoto(id) {
        return this.get('/logistic/getExpensePhoto', {id: id});
    }

    // Изменить тип
    public changeExpenseType(id, type) {
        return this.post('/logistic/changeExpenseType', {id: id, type: type});
    }

    /**
     * Получение курьерского графика
     * @param data
     */
    public getCourierScheduleList(data) {
        return this.post('/logistic/courier-schedule', data)
    }

    /**
     * Получение логов для курьерского графика
     * @param id
     */
    public getCourierScheduleLog(id) {
        return this.get('/logistic/courier-schedule/log/' + id)
    }

    /**
     * Редактирование курьерского графика
     * @param data
     */
    public editCourierSchedule(data) {
        return this.post('/logistic/courier-schedule/edit', data);
    }

    /**
     * Создание курьерского графика
     * @param data
     */
    public createCourierSchedule(data) {
        return this.post('/logistic/courier-schedule/create', data);
    }

    /**
     * Копирование графика курьера за определённый период
     * @param courier_id
     * @param data
     */
    public copyCourierSchedule(courier_id, data) {
        return this.post('/logistic/courier-schedule/copy/' + courier_id, data);
    }

    /**
     * Планирование графика курьеров
     * @param data
     */
    public planningCourierSchedule(data) {
        return this.post('/logistic/courier-schedule/planning', data);
    }

    /**
     * Смена статуса назначения курьера в курьерском графике
     * @param data
     */
    changeAssignCourierInCourierSchedule(data) {
        return this.post('/logistic/courier-schedule/change-assign-courier', data);
    }

    /**
     * Получение кол-во заказов для курьерского графика
     * @param date
     * @param data
     */
    getCountOrdersForSchedule(data) {
        return this.post('/logistic/courier-schedule/count-orders', data);
    }
}
