import {Component, Input, OnInit} from '@angular/core';
import {NotifierService} from '../../../service/notifier.service';
import {ClientService} from '../../../service/client.service';

/**
 * <app-call-audio-player
 *     [title]="'Звонок от А на номер Б'"
 *     [recordingId]="'MToxMDExMTc2MToxMzc1ODU2MzAyMjow'"
 * >
 * </app-call-audio-player>
 */
@Component({
    selector: 'app-call-audio-player',
    templateUrl: './call-audio-player.component.html',
    styleUrls: ['../audio-player/audio-player.component.scss'],
})
export class CallAudioPlayerComponent implements OnInit {
    @Input() public left: number;
    @Input() public recordingId: string;
    @Input() public title: string = null;
    @Input() public isTopAuto = false;
    public loading = false;
    public scr = null;

    constructor(
        private notifier: NotifierService,
        private api: ClientService,
    ) {
    }

    ngOnInit(): void {
    }

    getCallUrl() {
        this.loading = true;
        this.scr = null;
        this.api.getCallRecordingByRecordingId(this.recordingId).subscribe(src => {
            this.scr = src;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }
}
