import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-turn-work',
    templateUrl: './turn-work.component.html',
    styleUrls: ['./turn-work.component.scss']
})
export class TurnWorkComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
