import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {User} from '../../service/models';
import {DialogService} from '../../components/dialog/dialog.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from "../../service/helpers.service";
import {UserSelectComponent} from "../../components/user-select/user-select.component";


@Component({
    selector: 'app-courier-docs',
    templateUrl: './courier-docs.component.html',
    styleUrls: ['./courier-docs.component.scss']
})
export class CourierDocsComponent implements OnInit {
    public selectedCourier: User;
    @ViewChild('input_date') inputDate: ElementRef;
    @ViewChild('input_courier') inputCourier: UserSelectComponent;
    public form: FormGroup;
    courierId: number;
    data: string;
    public enableBtn = false;
    public shiftNumber = 1;

    constructor(
        protected dialog: DialogService,
        protected helper: HelpersService
    ) {
        this.form = new FormGroup(
            {
                inputDate: new FormControl('')
            }
        );
    }

    ngOnInit() {
    }

    onSelectUser(user: User) {
        this.selectedCourier = user;
        if (this.inputCourier.filter_date && this.selectedCourier) {
            this.enableBtn = true;
        } else {
            this.enableBtn = false;
        }
    }

    onDateChange() {
        this.inputCourier.filter_date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        //   console.log('дата поменялася');
        this.inputCourier.load();
        //   console.log('перезагрузили однако');
        if (this.inputCourier.filter_date && this.selectedCourier) {
            this.enableBtn = true;
        } else {
            this.enableBtn = false;
        }
    }

    btnClick() {

        if (!this.selectedCourier) {
            this.dialog.alert('Выберите курьера');
            return;
        }
        if (!this.inputDate.nativeElement.value) {
            this.dialog.alert('Выберите дату');
            return;
        }
        this.courierId = (this.selectedCourier) ? this.selectedCourier.id : 0;


    }

    getAttorneyLink() {
        this.data = this.inputDate.nativeElement.value;
        this.courierId = (this.selectedCourier) ? this.selectedCourier.id : 0;
        const newData = this.helper.formatDateForSQL(this.data);
        return [
            '/power-of-attorney',
            {
                'date': newData,
                'courier': this.courierId
            }
        ]
    }

    getRouteListLink() {
        const newData = this.helper.formatDateForSQL(this.data);
        return [
            '/couriers/route-list/',
            {
                'date': newData,
                'courier_id': this.courierId,
                'shift_number': this.shiftNumber
            }
        ]
    }

    onChangeShift(shift: number[]) {
        this.shiftNumber = shift.length > 0 ? shift[0] : 1;
    }
}
