import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {ClientInfo, User, Hub} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {process, State} from '@progress/kendo-data-query';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {CurrentUserService} from '../../service/current-user.service';
import {HubsService} from 'app/service/hubs.service';
import { filterBy, FilterDescriptor, CompositeFilterDescriptor, distinct } from '@progress/kendo-data-query';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    public clientsList: ClientInfo[];

    public formFindClient: FormGroup;

    public loadingClients: Boolean = false;

    public currentUser: User;
    private hubs: Hub[];

    public gridData: GridDataResult;
    public state: State = {
        take: 50,
        skip: 0,

        filter: {
            logic: 'and',
            filters: []
        }
    };

    constructor(
        private api: LaraService,
        private hubsService: HubsService,
        public currentUserService: CurrentUserService
    ) {
        this.currentUserService.get().subscribe((user: User) => {
            this.currentUser = new User(user);
        })
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubs = hubs;
        });
    }

    ngOnInit() {
        this.formFindClient = new FormGroup({
            uid: new FormControl('', Validators.required)
        });

        this.loadClient();
    }

    loadClient() {
        this.loadingClients = true;
        this.api.getClientList().subscribe((data: ClientInfo[]) => {
            this.clientsList = data;
            this.clientsList = this.clientsList.map(item => {
                item.active = item.active ? true : false;
                item.default_hub = this.hubs.find((hub: Hub) => hub.id === item.default_hub_id);


                return item;
            });
            this.loadingClients = false;
            this.gridData = process(this.clientsList, this.state);
        });
    }

    onLoadClient() {
        this.api.loadOldClient(this.formFindClient.value.uid).subscribe((data) => {
            alert('Старый клиент загружен');
            this.loadClient();
        });
    }

    onUpdateClients() {
        this.api.updateOldClients().subscribe((data) => {
            alert('Обновлено ' + data.count + ' клиента');
            this.loadClient();
        })
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.gridData = process(this.clientsList, this.state);
    }


    public filterChange(filter: CompositeFilterDescriptor): void {
        this.state.filter = filter;
        console.log(filter);
        console.log ('filter changed');
        this.gridData = process(filterBy(this.clientsList, filter), this.state);
    }

    public distinctPrimitive(fieldName: string): any {
        return distinct(this.clientsList, fieldName).map(item => item[fieldName]);
    }
}
