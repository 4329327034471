import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PpAct} from '../../../service/models';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {SkladService} from '../../../service/sklad.service';
import {HelpersService} from '../../../service/helpers.service';
import {NotifierService} from '../../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {AppComponent} from '../../../app.component';
import {parseNumber} from '@progress/kendo-angular-intl';
import {InventivActsModel} from '../inventiv-acts.model';
import {FileDownloadService} from '../../../http/file-download.service';

interface PPInterface {
    name: string,
    value: number,
    checked: boolean
}

interface DataInterface {
    name: string,
    value: number
}

@Component({
    selector: 'app-inventiv-acts-compare',
    templateUrl: './inventiv-acts-compare.component.html',
    styleUrls: ['./inventiv-acts-compare.component.scss']
})
export class InventivActsCompareComponent implements OnInit {
    public width = 800;
    public windowHeight = 600;

    @Input() act: InventivActsModel;

    public formFilter: FormGroup;
    public isLoading = false;
    public gridData: PpAct[] = [];
    public ppTypes: PPInterface[] = [
        {
            name: 'приёма-передачи',
            value: PpAct.TYPE_MAIN,
            checked: true,
        },
        {
            name: 'излишков',
            value: PpAct.TYPE_EXCESS,
            checked: false,
        },
        {
            name: 'недостачи',
            value: PpAct.TYPE_DEFICIT,
            checked: false,
        },
    ];

    dateTypes = [
        {
            value: 1,
            name: 'Дата акта',
        },
        {
            value: 2,
            name: 'Дата закрытия',
        },
    ];

    dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'name',
        selectAllText: 'Выбрать всё',
        unSelectAllText: 'Убрать всё',
        searchPlaceholderText: 'Поиск',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    public selectedActIds: number[] = [];
    public currentHubs = [];

    public actIsProcessing = false;
    public processedActUrl;

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        protected app: AppComponent,
    ) {
    }

    ngOnInit() {
        const actDate = new Date(Date.parse(this.act.act_date));
        const dayBeforeActDate = new Date();
        dayBeforeActDate.setDate(actDate.getDate() - 1);

        this.formFilter = new FormGroup(
            {
                date_type: new FormControl(this.dateTypes[0].value),
                date_start: new FormControl(dayBeforeActDate, Validators.required),
                date_end: new FormControl(actDate, Validators.required),
            },
        );

        this.onFormSubmit();
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * Событие при выборе всех чекбоксов
     */
    onChangeAllCheckbox() {
    }

    getActTypeName(actType) {
        const itemS = this.ppTypes.find(item => item.value === actType);
        if (!itemS) {
            return 'неизвестный тип';
        }
        return itemS.name;
    }

    /**
     * Событие при смене даты
     * @param controlName
     */
    onDateChange(controlName) {

    }

    /**
     * Событие при отправке формы
     */
    onFormSubmit() {
        if (!this.formFilter.value.date_start || !this.formFilter.value.date_end) {
            return this.notifier.openNotifier('Укажите дату!', null, {class: 'danger', duration: 3000});
        }

        const query = {...this.formFilter.value};
        query.date_start = this.datePipe.transform(query.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(query.date_end, 'yyyy-MM-dd');
        query.inventiv_act_id = this.act.id;

        this.resetCheckedTable()
        this.isLoading = true;
        this.api.getActsForInventiv(query).subscribe(result => {
            this.isLoading = false;
            this.gridData = result;
        }, error => {
            this.isLoading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Событие при выборе всех чекбоксов таблицы
     * @param event
     */
    onChangeAllCheckboxTable(event) {
        const checked = event.target.checked;
        this.selectedActIds = checked ? this.gridData.filter(function (x) {
            return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(x.type) !== -1) && x.status === PpAct.STATUS_CLOSED
        }).map(x => {
            return x['id'];
        }) : [];

        this.gridData.filter(function (x) {
            return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(x.type) !== -1) && x.status === PpAct.STATUS_CLOSED
        }).forEach(value => {

        })
    }

    /**
     * Событие при смене состояния чекбокса таблицы
     * @param event
     */
    onChangeCheckboxTable(event) {
        const value = parseNumber(event.target.value);
        const index = this.selectedActIds.indexOf(value);
        if (index > -1) {
            this.selectedActIds.splice(index, 1);
        } else {
            this.selectedActIds.push(value);
        }
    }

    compareActs() {
        this.actIsProcessing = true;
        this.api.compareInventivAct({
            inventiv_act_id: this.act.id,
            act_ids: this.selectedActIds,
        }).subscribe(data => {
            this.notifier.openNotifier('Акт успешно обработан!', null, {class: 'success', duration: 3000});

            this.onClose();

            this.processedActUrl = data.processed_url;
            this.actIsProcessing = false;
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
            this.actIsProcessing = false;
        })
    }

    canBeChecked(dataItem: PpAct) {
        return ([PpAct.TYPE_MAIN, PpAct.TYPE_EXCESS].indexOf(dataItem.type) !== -1)
            && dataItem.status === PpAct.STATUS_CLOSED;
    }

    /**
     * Сброс состояния чекбоксов таблицы
     * @private
     */
    private resetCheckedTable() {
        this.selectedActIds = [];
    }

    public isChecked(act) {
        return this.selectedActIds.indexOf(act.id) !== -1;
    }
}
