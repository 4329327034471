import {Injectable} from '@angular/core';
import {LaraService} from '../service/lara.service';

@Injectable({
    providedIn: 'root',
})
export class ServicesApiService extends LaraService {
    /** Список заявок на сервисные услуги */
    public listRequests(params) {
        return this.post('/services/listRequests', params);
    }

    /** Массовая установка статуса заявкам на сервисное обслуживание */
    public massSetServiceRequestStatus(params) {
        return this.post('/services/massSetServiceRequestStatus', params);
    }
}
