import {Component, Input, OnInit} from '@angular/core';
import {OrderBadgesComponent, OrderBadgeItemInterface} from '../order-badges.component';

@Component({
    selector: 'app-order-badge-item',
    templateUrl: './order-badge-item.component.html',
    styleUrls: ['./order-badge-item.component.scss'],
})
export class OrderBadgeItemComponent implements OnInit {
    @Input() flag: string;
    @Input() displayIcon: boolean = true;
    @Input() displayLabel: boolean = false;
    @Input() invertedColors: boolean = false;
    @Input() muted: boolean = false;

    public item: OrderBadgeItemInterface | null = null;

    constructor() {
    }

    ngOnInit() {
        if ('undefined' === typeof OrderBadgesComponent.ORDER_BADGES[this.flag]) {
            return;
        }

        if (!this.displayIcon && !this.displayLabel) {
            this.displayIcon = true;
        }

        this.item = {flag: this.flag, ...OrderBadgesComponent.ORDER_BADGES[this.flag]};
    }

}
