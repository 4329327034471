import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';

import {ClientInfo, MatrixData, ZoneGroup} from '../../service/models';
import {MatrixService} from '../../service/matrix.service';
import {ApiClientInfo} from '../../service/api_result';

@Component({
    selector: 'app-matrix-hubs',
    templateUrl: './matrix-hubs.component.html',
    styleUrls: ['./matrix-hubs.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class MatrixHubsComponent implements OnInit {
    public hubId: number;

    public zoneGroupsList: ZoneGroup[];
    public categoryList: string[];
    public returnTypes;

    public smsTypes = [
        {id: 1, title: 'За пакет'},
        {id: 2, title: 'За штуку'},
    ];

    public selectedZoneGroupId = null;
    public selectedCategory = 'A';
    public isSelectedCategory = true;
    public selectedClient: ClientInfo;

    public pageTitle = 'Тарифная матрица';

    public formMatrixOptions: FormGroup;
    public formMatrixList: FormArray;
    public formMatrixMass: FormArray;

    public isMatrixLoading: boolean = false;

    public dataHash: MatrixData;
    public readonly matrixDataOptions = MatrixData.OPTIONS;

    public tarTypesTitle = [
        'Фиксированная цена', 'Процент от тарифа'
    ];

    constructor(
        private api: MatrixService,
        private title: Title,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.loadEmptyMatrix();

        this.route.params.subscribe((params) => {
            this.hubId = +params['id'];

            this.loadZoneGroups();
        })

        /* грузим список категорий */
        this.api.getMatrixZoneGroupsCategoryList().subscribe((data: string[]) => {
            this.categoryList = data;
        });

        this.api.getReturnTypes().subscribe((data) => {
            this.returnTypes = data;
        });
    }

    private loadZoneGroups() {
        /* грузим список групп зон */
        this.api.getMatrixZoneGroupsList(this.hubId).subscribe((data: ZoneGroup[]) => {
            if (data.length) {
                this.zoneGroupsList = data;
                this.selectedZoneGroupId = data[0].id;

                this.loadMatrix();
            }
        });
    }

    public getSmsTypeTitle(id: number) {
        const type = this.smsTypes.find(i => i.id === +id);
        if (type) {
            return type.title;
        }

        return null;
    }

    public onChangeZoneGroup(id: number) {
        this.selectedZoneGroupId = id;
        this.loadMatrix();
    }

    public onChangeCategory(categoryCode: string) {
        this.selectedCategory = categoryCode;
        this.loadMatrix();
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;

        if (client) {
            this.selectedCategory = client.matrix_spb;
            this.isSelectedCategory = false;
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.isSelectedCategory = true;
            this.title.setTitle(this.pageTitle);
        }

        this.loadMatrix();
    }

    public onAddMass() {
        this.formMatrixMass.push(new FormControl('', Validators.required));

        const newRow: FormArray = new FormArray([]);
        newRow.push(
            new FormArray([
                new FormControl('', Validators.required),
                new FormControl('', Validators.required)
            ])
        );

        this.formMatrixList.push(newRow);
    }

    public onDeleteMass(index) {
        this.formMatrixMass.removeAt(index);
        this.formMatrixList.removeAt(index);
    }

    public onSubmitForm() {
        const matrix = {};
        for (let i = 0; i < this.formMatrixMass.length; i++) {
            matrix[this.formMatrixMass.controls[i].value] = [
                this.getMatrixControl(i, 0).value,
                this.getMatrixControl(i, 1).value
            ];
        }

        const data = {
            matrix: matrix,
            options: this.formMatrixOptions.value,
            category: this.selectedClient ? null : this.selectedCategory
        };

        this.isMatrixLoading = true;
        this.api.updateTariffZoneGroupMatrix(
            data,
            this.selectedCategory,
            this.selectedZoneGroupId,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe(() => {
            this.loadMatrix();
            this.isMatrixLoading = false;
        }, error => {
            this.isMatrixLoading = false;
        });
    }

    public getMatrixControl(mass_index, type) {
        const arr: FormArray = this.formMatrixList.controls[mass_index] as FormArray;
        const ctrl: FormArray = arr.controls[0] as FormArray;
        return ctrl.controls[type];
    }

    /**
     * Можно ли редактировать матрицу?
     */
    public canEditMatrix() {
        // можно проверить права, если нужно
        return true;
    }

    protected createOptionsForm(values) {
        this.formMatrixOptions = new FormGroup({});
        for (const option of MatrixData.OPTIONS) {
            this.formMatrixOptions.addControl(option.key, new FormControl(values[option.key] === undefined ? '' : values[option.key]));
        }
    }

    protected loadEmptyMatrix() {
        this.formMatrixList = new FormArray([]);
        this.formMatrixMass = new FormArray([]);
        this.createOptionsForm([]);
    }

    protected loadMatrix() {
        this.createOptionsForm({});

        this.formMatrixList = new FormArray([]);
        this.formMatrixMass = new FormArray([]);

        this.api.getTariffZoneGroupMatrix(
            this.selectedZoneGroupId,
            this.selectedCategory,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe((data: MatrixData) => {
            /* форма опций */
            this.createOptionsForm(this.selectedClient ? data.options : data.options_default);

            /* сама матрица */
            this.dataHash = data;
            const matrix = data.matrix,
                mass = data.mass;

            for (let i = 0; i < mass.length; i++) {
                this.formMatrixMass.push(new FormControl(mass[i], Validators.required));

                const hash = [];
                const row: FormArray = new FormArray([]);
                hash.push(matrix[i][2]);
                row.push(new FormArray([
                    new FormControl(matrix[i][0], Validators.required),
                    new FormControl(matrix[i][1], Validators.required)
                ]))

                this.formMatrixList.push(row);
            }

            // this.checkMatrixForEmptyRows();
        });
    }

    /**
     * Если матрица весов пустая, добавим строку
     */
    public checkMatrixForEmptyRows() {
        if (this.dataHash.mass.length > 0) {
            return;
        }

        if (!this.canEditMatrix()) {
            return;
        }

        this.formMatrixMass.push(new FormControl('', Validators.required));

        const hash = [];
        const row: FormArray = new FormArray([]);
        hash.push(null);
        row.push(new FormArray([
            new FormControl(null, Validators.required),
            new FormControl(null, Validators.required)
        ]))

        this.formMatrixList.push(row);
    }

    /**
     * Делаем пример расчёта на половине весового интервала
     * @param matrixWeightTo
     * @param price
     * @param priceDelta
     */
    public getCalculateExample(matrixWeightTo: number, price: number, priceDelta: number) {
        if (+matrixWeightTo === 0 || price === null) {
            return null;
        }

        const matrixWeightFrom = this.getPreviousMassValue(matrixWeightTo);
        const exampleWeight = Math.round(10 * (matrixWeightFrom + matrixWeightTo) / 2) / 10;

        // округляем, если у клиента есть соответствующая настройка
        const roundedWeight = (this.selectedClient && this.selectedClient.is_round_weight ? Math.round(exampleWeight) : exampleWeight);
        const deliveryPrice = (roundedWeight - matrixWeightFrom) * priceDelta + price;

        return 'Цена за ' + exampleWeight + 'кг: ' + deliveryPrice + ' руб';
    }

    /**
     * Получаем предыдущий вес для указания интервала
     * @param currentMass
     */
    public getPreviousMassValue(currentMass) {
        const masses: number[] = [];
        for (const mass of this.formMatrixMass.controls) {
            masses.push(+mass.value);
        }

        // сортируем в обратном порядке
        masses.sort((a, b) => {
            return b - a;
        });

        for (const mass of masses) {
            if (mass < currentMass) {
                return mass;
            }
        }

        return 0;
    }
}
