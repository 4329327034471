import {CalculatorsTabComponent} from './../calculators/calculators-tab/calculators-tab.component';
import {EditComponent} from './edit/edit.component';
import {KgtComponent} from '../matrixes/kgt/kgt.component';
import {MatrixComponent} from '../matrixes/matrix/matrix.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {TakeAwayComponent} from 'app/matrixes/take-away/take-away.component';
import {TaCalculatorComponent} from 'app/calculators/ta-calculator/ta-calculator.component';
import {MatrixesTabComponent} from 'app/matrixes/matrixes-tab/matrixes-tab.component';
import {TakeAwayCfoComponent} from '../matrixes/take-away-cfo/take-away-cfo.component';
import {MatrixHubsComponent} from '../matrixes/matrix-hubs/matrix-hubs.component';
import {MatrixTopdeliveryComponent} from '../matrixes/matrix-topdelivery/matrix-topdelivery.component';
import {ClientUsersEditComponent} from '../components/client-users/client-users-edit/client-users-edit.component';
import {ClientUsersCreateComponent} from '../components/client-users/client-users-create/client-users-create.component';
import {MatrixOrdersAmountComponent} from '../matrixes/matrix-orders-amount/matrix-orders-amount.component';
import {MatrixServicesComponent} from '../matrixes/matrix-services/matrix-services.component';

const routes: Routes = [
    {path: 'index', component: IndexComponent},
    {path: 'matrixes', component: MatrixesTabComponent, children: [
        {path: 'matrix-moscow', component: MatrixComponent},
        {path: 'matrix-moscow-orders-amount', component: MatrixOrdersAmountComponent},
        {path: 'matrix-hubs/:id', component: MatrixHubsComponent},
        {path: 'matrix-services/:id', component: MatrixServicesComponent},
        {path: 'matrix-kgt', component: KgtComponent},
        {path: 'matrix-takeaway-moscow', component: TakeAwayComponent},
        {path: 'matrix-takeaway-cfo', component: TakeAwayCfoComponent},
        {path: 'topdelivery', component: MatrixTopdeliveryComponent},
    ]},
    {path: 'calculators', component: CalculatorsTabComponent},
    {path: 'edit/:id', component: EditComponent},
    {path: 'user/edit/:id', component: ClientUsersEditComponent},
    {path: 'user/create/:client_id', component: ClientUsersCreateComponent},
    {path: 'ta-calculator', component: TaCalculatorComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule {
}
