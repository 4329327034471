import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {SkladService} from '../../service/sklad.service';
import {OrdersService} from '../../service/orders.service';
import {HelpersService} from '../../service/helpers.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-invoice-print',
    templateUrl: './invoice-print.component.html',
    styleUrls: ['./invoice-print.component.scss']
})
export class InvoicePrintComponent implements OnChanges {
    public uniqueIndex = Math.random().toString();
    public orders;
    @Output('finishPrint') finishPrint = new EventEmitter<boolean>();
    @Input('title') title: string;
    @Input('disable') disable: boolean;
    @Input('uids') uids: any;
    @Input('visible') visible;
    @Input('icon') icon;
    @ViewChild('printButton') printButton: ElementRef;

    constructor(
        protected app: AppComponent,
        private api: SkladService,
        private apio: OrdersService,
        private helper: HelpersService,
        private sanitizer: DomSanitizer
    ) {
    }


    ngOnChanges(changes: SimpleChanges): void {
    }

    getOrder() {
        if (this.uids) {
            this.apio.getUids(this.uids).subscribe(data => {
                this.orders = data;
                setTimeout(() => this.printFile(), 1000);
            })
        }
    }

    printFile() {
        this.printButton.nativeElement.click();
        this.orders = [];
        this.finishPrint.emit(true);
    }

    calculateSum(allGoods) {
        let sum = 0;
        allGoods.forEach(function (goods) {
           sum += (goods.price * goods.count)
        });
        return sum;
    }

    getClass(i) {
        return i === 0 ? 'border-dotted' : '';
    }
}
