import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LaraService} from '../../../service/lara.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-power-of-attorney',
    template: ''
})
export class PowerOfAttorneyComponent {
   public url;
   public loading = false;
    constructor(
        private route: ActivatedRoute,
        private api: LaraService
    ) {
        this.route.params.subscribe(params => {
            document.location.href = (
                environment.lara_api_url.substr(0, environment.lara_api_url.indexOf('/api/v1')) + '/printer/courierDocs/' +
                params['date'] + '/' +
                params['courier'] + '?token=' +
                this.api.getToken()
            );
        });

    }
}
