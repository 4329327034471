import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-pre-assembly',
    templateUrl: './pre-assembly.component.html',
    styleUrls: ['./pre-assembly.component.scss']
})
export class PreAssemblyComponent implements OnInit {

    public INFO = 0;
    public SUCCESS = 1;
    public DANGER = 2;
    public assemble_btn = false;
    public message: any = {};
    public scanned = [];
    public loading = false;
    @ViewChild('input_barcode') input_barcode: ElementRef;
    @ViewChild('maxNum') maxNum: ElementRef;

    constructor(
        protected api: SkladService,
        public title: Title
    ) {
    }

    ngOnInit() {
        this.title.setTitle('Предсборка');
    }

    assemble_process() {
        this.loading = true;
        const check_cells = [];
        for (const cell of this.scanned) {
            if (cell.completed) {
                check_cells.push(cell.cell_num);
            }
        }
        if (check_cells.length !== 0) {
            this.setAlert('Ошибка',
                'У вас есть неупакованные ячейки!\r\n(№ ' + check_cells.join(', ') + ')',
                this.DANGER);
            return;
        }
        if (!this.assemble_btn) {
            setTimeout(() => {
                this.input_barcode.nativeElement.focus();
            }, 0);

            this.api.preAssembleStart(this.maxNum.nativeElement.value).subscribe(data => {
                if (!data.result) {
                    this.setUnfinishedData(data.predsbor.orderedCells);
                }
                this.setAlert('', data.message, this.INFO);
            })
            // Убрать
            this.loading = false;
        } else {
            this.api.preAssembleFinish().subscribe(data => {
                this.setAlert('', data.message, this.INFO);
                this.scanned = [];
            })
        }
        this.assemble_btn = !this.assemble_btn;
    }

    checkData(e) {
        this.loading = true;
        const num = e.target.value.trim();
        e.target.value = '';
        if (!num) {
            this.setAlert('', 'Введите данные!', this.DANGER);
        }
        if (num) {
            this.api.preAssembleScan(num).subscribe(data => {
                if (!data.result) {
                    this.setAlert('', data.message, this.DANGER);
                } else if (data.already_scanned) {
                    if (this.summPlaces(data.barCode.order.uid) === data.barCode.order.hash_bar_codes_count) {
                        this.setAlert('',
                            'Все места в ячейке №' + data.cell_num + ' собраны! \r\n Заказ '
                            + data.barCode.order.uid + ' можно упаковывать!',
                            this.SUCCESS);
                    } else {
                        this.setAlert('',
                            'Штрихкод №' + data.barCode.bar_code + ' уже отсканирован!',
                            this.DANGER);
                    }
                } else {
                    this.submitBarcode(data);
                }
            })
        }
    }

    summPlaces(uid) {
        for (const order of this.scanned) {
            if (order.uid === uid) {
                return order.bar_codes.length;
            }
        }
    }

    setAlert(head, body, status) {
        const statuses = ['alert-info', 'alert-success', 'alert-danger']
        this.message = {
            head: head,
            body: body,
            status: statuses[status]
        }
        this.loading = false;
    }

    submitBarcode(data) {
        let checked = false;
        for (const order of this.scanned) {
            if (order.uid === data.barCode.order.uid) {
                order.bar_codes.push(data.barCode.bar_code);
                if (order.bar_codes.length === data.barCode.order.hash_bar_codes_count) {
                    order.completed = true;
                }
                checked = true;
            }
        }
        if (!checked) {
            let completed;
            if (data.barCode.order.hash_bar_codes_count === data.barCode.bar_code.length) {
                completed = true;
            }
            const value = {
                uid: data.barCode.order.uid, cell_num: data.cell_num,
                length: data.barCode.order.hash_bar_codes_count, bar_codes: [data.barCode.bar_code],
                completed: completed
            };
            this.scanned.push(value);
        }
        const places = this.summPlaces(data.barCode.order.uid);
        if (places === data.barCode.order.hash_bar_codes_count) {
            this.setAlert(
                'Ячейка №' + data.cell_num,
                'Заказ ' +
                data.barCode.order.uid + ' собран, можно упаковывать!',
                this.SUCCESS);
        } else {
            this.setAlert(
                'Ячейка №' + data.cell_num,
                'Мест: ' + data.barCode.order.bar_codes.length + '. Заказ ' +
                data.barCode.bar_code + '\r\nСобрано: ' + places +
                ' из ' + data.barCode.order.hash_bar_codes_count,
                this.SUCCESS);
        }
    }

    setUnfinishedData(data) {
        for (const cell of data) {
            const order = cell.places[0];
            const bar_codes = [];
            for (const bar_code of cell.places) {
                bar_codes.push(bar_code[0].bar_code);
            }
            const val = {
                uid: order[0].order.uid,
                cell_num: cell.cell_num, bar_codes: bar_codes,
                length: order[0].order.hash_bar_codes_count,
                completed: cell.completed
            };
            this.scanned.push(val);
        }
    }

    cellComplete(cell_num, index) {
        this.loading = true;
        if (cell_num) {
            this.api.markCellAsCompleted(cell_num).subscribe(data => {
                this.setAlert('', data.message, this.INFO);
                this.scanned.splice(index, 1);
            })
        }
    }
}
