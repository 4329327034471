import {User, UserHub} from './../../service/models';
import {CurrentUserService} from './../../service/current-user.service';
import {NavigationEnd, Router} from '@angular/router';
import {LaraService} from './../../service/lara.service';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EventerService} from '../../service/eventer.service';
import {Subscription} from 'rxjs';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-admin-header',
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {

    @ViewChild('callPopupCloud') public callPopupCloud: ElementRef;
    public isCallPopupCloudOpened: boolean = false;

    public user: User;
    public currentHub: UserHub;
    public userHubs: UserHub[];
    public selectedHubs: UserHub[];

    public isQuickUploadAccessible: boolean = false;
    public isSidebarOpened: boolean = false;
    private routerSubscription: Subscription;

    constructor(
        private api: LaraService,
        private router: Router,
        private eventerService: EventerService,
        private currentUser: CurrentUserService,
        private helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.currentUser.get().subscribe(async (user: User) => {
            this.user = new User(user);
            this.currentHub = this.user.default_hub;
            this.userHubs = this.user.hubs;
            this.selectedHubs = [this.currentUser.getCurrentHub(), ];
            let isQuickUploadAccessible = false;
            [
                'files:quick-upload-interface',
                'storage:lost-orders-admin',
                'files:return-act-image:upload',
            ].map(permission => {
                isQuickUploadAccessible = (isQuickUploadAccessible || this.helpers.checkPermissions(permission));
            });
            this.isQuickUploadAccessible = isQuickUploadAccessible;
        });

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isSidebarOpened = false;
                this.eventerService.adminSidebarToggle.emit(false);
            }
        })
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    onExit() {
        this.currentUser.logout();
        this.api.logout();
        this.router.navigate(['login']);
    }

    onChangeHub(hub) {
        hub = this.currentUser.setCurrentHub(hub);
        this.selectedHubs = [hub, ];
        this.eventerService.primaryHubChange.emit(hub);
    }

    onShowMenuClick() {
        this.isSidebarOpened = !this.isSidebarOpened;
        this.eventerService.adminSidebarToggle.emit(this.isSidebarOpened);
    }

    onCallPopupCloudClick() {
        this.toggleCallPopupCloudOpened();
    }

    toggleCallPopupCloudOpened() {
        this.isCallPopupCloudOpened = !this.isCallPopupCloudOpened;
    }
}
