import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {TransitDriver, TransitVehicle} from '../../../service/models';

@Component({
    selector: 'app-transit-driver-modal',
    templateUrl: './transit-driver-modal.component.html',
    styleUrls: ['./transit-driver-modal.component.scss']
})
export class TransitDriverModalComponent implements OnInit {
    public width;
    public transit;

    public maxPassportDate: Date = new Date();

    public drivers: TransitDriver[];
    public driversSource: TransitDriver[];
    public newDriver: TransitDriver;
    public selectedDriver: TransitDriver;

    public formEdit: FormGroup;

    public isNewDriver: boolean = true;
    public driverIsSaving: boolean = false;
    public allowEditing: boolean = true;

    constructor(
        protected app: AppComponent,
        protected api: SkladService) {
        this.formEdit = new FormGroup({
            name: new FormControl(),
            phone: new FormControl(),
            organization_name: new FormControl(),
            organization_address: new FormControl(),
            organization_phone: new FormControl(),

            passport_number: new FormControl(),
            passport_issuer: new FormControl(),
            passport_date: new FormControl(),
            passport_issuer_code: new FormControl(),
        });
    }

    ngOnInit() {
        this.api.getTransitDrivers()
            .subscribe(data => {
                this.drivers = data;
                this.driversSource = data;
            })
    }

    handleFilter(value) {
        this.drivers = this.driversSource.filter((s) => {
            return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onDriverNameChange($event) {
        if (this.newDriver.id) {
            // заполним форму данными водителя
            const driver = this.drivers.find(d => this.newDriver.id === d.id);

            for(let i in this.formEdit.controls) {
                this.formEdit.get(i).setValue(driver[i]);
            }

            this.selectedDriver = this.newDriver;
            this.isNewDriver = false;
            this.allowEditing = false;
        }
    }

    onChooseNewDriverClick() {
        this.isNewDriver = true;
        this.allowEditing = true;
        for(let i in this.formEdit.controls) {
            this.formEdit.get(i).setValue(null);
        }
    }

    onEditExistingDriverClick() {
        this.allowEditing = true;
    }

    onSaveDriverButtonClick() {
        this.driverIsSaving = true;

        const driverData = this.formEdit.value;
        driverData.id = this.selectedDriver.id;

        this.api.saveTransitDriver(driverData)
            .subscribe((data: TransitDriver) => {
                this.driverIsSaving = false;

                this.addDriverToTransit(data);
            }, error => {
                this.driverIsSaving = false;
            });
    }

    onAddDriverButtonClick() {
        this.driverIsSaving = true;
        this.api.addTransitDriver(this.formEdit.value)
            .subscribe((data: TransitDriver) => {
                this.driverIsSaving = false;

                this.addDriverToTransit(data);
            }, error => {
                this.driverIsSaving = false;
            });
    }

    onSetDriverButtonClick() {
        this.addDriverToTransit(this.selectedDriver);
    }

    /** установить водителя в перемещении */
    addDriverToTransit(driver: TransitDriver) {
        this.api.setDriverInTransit(this.transit.id, driver.id)
            .subscribe(data => {
                this.driverIsSaving = false;
                this.onClose();
            }, error => {
                this.driverIsSaving = false;
            });
    }

    public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
        return {
            id: 0,
            name: text
        };
    }));
}
