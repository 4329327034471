import {Component, OnInit} from '@angular/core';
import {ClientInfo, MatrixTakeAwayCfo, User, ZoneGroup} from '../../service/models';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {MatrixService} from '../../service/matrix.service';
import {Title} from '@angular/platform-browser';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';
import {CurrentUserService} from '../../service/current-user.service';
import {OrdersService} from '../../service/orders.service';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-take-away-cfo',
    templateUrl: './take-away-cfo.component.html',
    styleUrls: ['./take-away-cfo.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class TakeAwayCfoComponent implements OnInit {

    public selectedClient: ClientInfo;

    public pageTitle = 'Тарифная матрица заборов ЦФО';

    public zoneGroups: ZoneGroup[];

    public defaultMatrixForm: FormArray = new FormArray([]);
    public clientMatrixForm: FormArray = new FormArray([]);

    public defaultMatrix: MatrixTakeAwayCfo[];
    public clientMatrix: MatrixTakeAwayCfo[];

    public hasPersonalMatrix = false;
    public createPersonalMatrix = false;

    public defaultMatrixIsSaving: boolean = false;
    public clientMatrixIsSaving: boolean = false;

    protected user: User;

    constructor(
        protected ordersApi: OrdersService,
        private api: MatrixService,
        private title: Title,
        private route: ActivatedRoute,
        private userService: CurrentUserService,
        private notifier: NotifierService
    ) {
    }

    ngOnInit() {
        this.userService.get().subscribe((user: User) => {
            this.user = user;
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const clientId = params.get('id');
            if (clientId) {
                this.api.getClientInfo(clientId).subscribe((client: ApiClientInfo) => {
                    this.onChangeClient(client.client);
                });
            } else {
                this.loadMatrix();
            }
        });
    }

    public canEditDefaultMatrix() {
        if (this.user.isRoleAdmin()) {
            return true;
        }
        return false;
    }

    public canEditClientMatrix() {
        if (this.user.isRoleAdmin()) {
            return true;
        }
        if (this.selectedClient) {
            return true;
        }
        return false;
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        if (client) {
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.title.setTitle(this.pageTitle);
        }
        this.loadMatrix();
    }

    public onSubmitDefaultMatrix() {
        this.defaultMatrixIsSaving = true;
        this.api.updateTakeAwayCfo({
            'matrix': this.defaultMatrixForm.value
        }).subscribe((defaultData: MatrixTakeAwayCfo[]) => {
            this.defaultMatrix = defaultData;
            this.drawDefaultMatrix();

            this.defaultMatrixIsSaving = false;
            this.notifier.openNotifier('Матрица успешно сохранена', null, {duration: 3000});
        }, error => {
            this.defaultMatrixIsSaving = false;
            this.notifier.openNotifier('Ошибка при сохранении', null, {class: 'danger', duration: 5000});
        });
    }

    public onSubmitClientMatrix() {
        this.clientMatrixIsSaving = true;
        this.api.updateTakeAwayCfo({
            'matrix': this.clientMatrixForm.value
        }, this.selectedClient.id).subscribe(() => {
            this.loadMatrix();

            this.clientMatrixIsSaving = false;
            this.notifier.openNotifier('Матрица успешно сохранена', null, {duration: 3000});
        }, error => {
            this.clientMatrixIsSaving = false;
            this.notifier.openNotifier('Ошибка при сохранении', null, {class: 'danger', duration: 5000});
        });
    }

    protected loadMatrix() {
        if (this.zoneGroups === undefined) {
            this.ordersApi.getZoneGroups()
                .subscribe((data: ZoneGroup[]) => {
                    this.zoneGroups = data;
                    this.loadMatrix();
                })

            return;
        }

        this.hasPersonalMatrix = false;
        this.createPersonalMatrix = false;

        this.defaultMatrix = null;
        this.clientMatrix = null;

        if (this.selectedClient) {
            this.api.getTakeAwayCfo(this.selectedClient.id).subscribe((data: MatrixTakeAwayCfo[]) => {
                this.clientMatrix = data;
                this.drawClientMatrix();
            });
        }

        this.api.getTakeAwayCfo().subscribe((defaultData: MatrixTakeAwayCfo[]) => {
            this.defaultMatrix = defaultData;
            this.drawDefaultMatrix();
        });
    }

    private drawDefaultMatrix() {
        this.defaultMatrixForm = new FormArray([]);

        for (const group of this.zoneGroups) {
            this.defaultMatrixForm.push(new FormArray([
                new FormControl(group.id, Validators.required),
                new FormControl(this.getDefaultTariff(group.id), Validators.required),
            ]));
        }
    }

    private drawClientMatrix() {
        this.clientMatrixForm = new FormArray([]);

        for (const group of this.zoneGroups) {
            this.clientMatrixForm.push(new FormArray([
                new FormControl(group.id, Validators.required),
                new FormControl(this.getClientTariff(group.id), Validators.required),
            ]));
        }

        if (this.selectedClient) {
            this.hasPersonalMatrix = (this.clientMatrix.length > 0);
        }
    }

    public getGroupName(groupId) {
        const filteredGroup = this.zoneGroups.find((group) => {
            return group.id === groupId;
        })

        return filteredGroup ? filteredGroup.name : null;
    }

    public getDefaultTariff(zoneGroupId) {
        if (!this.defaultMatrix) {
            return null;
        }

        return this.getTariff(this.defaultMatrix, zoneGroupId);
    }

    public getTariff(inputMatrix, zoneGroupId) {
        const filteredMatrix = inputMatrix.find((matrix) => {
            return matrix.zone_group_id === zoneGroupId;
        })

        return filteredMatrix ? filteredMatrix.price : null;
    }

    public getClientTariff(zoneGroupId) {
        if (!this.clientMatrix) {
            return null;
        }

        return this.getTariff(this.clientMatrix, zoneGroupId);
    }

}
