import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../../app.component";
import {AddressEditorMapComponent} from "../address-editor-map.component";

/**
 * Без селектора дабы не использовать компонент в отрыве от родительского
 * компонента AddressEditorMapComponent (селектор app-address-editor-map),
 * который в свою очередь наследует и расширяет функиональность карты локации
 * LocationMapComponent (селектор app-location-map)
 */
@Component({
    // selector: 'app-address-editor-map-dialog',
    templateUrl: './address-editor-map-dialog.component.html',
    styleUrls: ['./address-editor-map-dialog.component.scss']
})
export class AddressEditorMapDialogComponent implements OnInit {
    public P: AddressEditorMapComponent;

    constructor(
        protected app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    onClose() {
        this.app.closeDialog(this);
        this.P.onClose();
    }
}
