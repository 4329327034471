import {BaseModel, ClientInfo, ParentCompany} from '../service/models';

export class AgentReport extends BaseModel {
    public static readonly STATUS_DESCRIPTIONS = [
        'Новый',
        'Сформирован',
        'Согласован',
        'Отправлен',
        'Архив',
        'Отказ в согласовании',
    ];

    /** @var int Новый */
    public static readonly STATUS_NEW = 1;
    /** @var int Сформирован */
    public static readonly STATUS_FORMED = 2;
    /** @var int Согласован */
    public static readonly STATUS_ACCEPTED = 3;
    /** @var int Отправлен */
    public static readonly STATUS_SENT = 4;
    /** @var int Архив */
    public static readonly STATUS_ARCHIVE = 5;
    /** @var int Отказ в согласовании */
    public static readonly STATUS_REJECTED = 6;

    public client_id: number;
    public number: string;
    public date_start: string;
    public date_end: string;
    public report_orders;
    public report_zorders;

    public client?: ClientInfo;
    public status: number;
    public is_payment_without_service_fee: number;
    public is_cod_transfer_separated_by_type: number;
    public sum_np: number;
    public sum_cod_cash: number;
    public sum_cod_cashless: number;
    public sum_cod_cashless_card: number;
    public sum_cod_cashless_sbp: number;
    public sum_tariff_total: number;
    public sum_tariff_logistics: number;
    public sum_tariff_cod: number;
    public sum_tariff_addons: number;
    public sum_tariff_total_with_nds: number;
    public sum_tariff_total_nds: number;
    public is_payment_sent: number;
    public is_service_fee_received: number;
    public is_accepted_by_client: number;
    public is_rejected_by_client: number;
    public client_reject_reason: string;

    public bank_payment_orders_parts?: AgentReportBankPaymentOrderPart[];
}

export class BankPaymentOrder extends BaseModel {

    /** @var int Направление платежа: расход */
    public static readonly DIRECTION_EXPENSE = 1;
    /** @var int Направление платежа: приход */
    public static readonly DIRECTION_INCOME = 2;

    public static readonly DIRECTION_DESCRIPTION = [
        '',
        'Расход',
        'Приход',
    ];

    public static readonly DIRECTION = [
        {id: BankPaymentOrder.DIRECTION_EXPENSE, name: 'Расход', classes: null},
        {id: BankPaymentOrder.DIRECTION_INCOME, name: 'Приход', classes: null},
    ];

    /** @var int Тип наложенного платежа для перевода: нал */
    public static readonly COD_CASH = 1;

    /** @var int Тип наложенного платежа для перевода: безнал */
    public static readonly COD_CARD = 2;

    /** @var int Тип наложенного платежа для перевода: любой */
    public static readonly COD_MIXED = 3;

    public static readonly COD_DESCRIPTION = [
        '',
        'Нал',
        'Безнал',
        'Любой',
    ];

    public static readonly COD = [
        {id: BankPaymentOrder.COD_CASH, name: 'Нал', classes: null},
        {id: BankPaymentOrder.COD_CARD, name: 'Безнал', classes: null},
        {id: BankPaymentOrder.COD_MIXED, name: 'Любой', classes: null},
    ];

    /** @var int Статус платёжного поручения в CRM: черновик */
    public static readonly STATUS_DRAFT = 1;
    /** @var int Статус платёжного поручения в CRM: отправлен в банк */
    public static readonly STATUS_SENT = 2;
    /** @var int Статус платёжного поручения в CRM: исполнен */
    public static readonly STATUS_EXECUTED = 3;
    /** @var int Статус платёжного поручения в CRM: проверено */
    public static readonly STATUS_VERIFIED = 4;

    public static readonly STATUSES = [
        {id: BankPaymentOrder.STATUS_DRAFT, name: 'Черновик', classes: null},
        {id: BankPaymentOrder.STATUS_SENT, name: 'Отправлено в банк', classes: null},
        {id: BankPaymentOrder.STATUS_EXECUTED, name: 'Исполнено', classes: null},
        {id: BankPaymentOrder.STATUS_VERIFIED, name: 'Проверено', classes: null},
    ];

    public direction: number;
    public company_id?: number;
    public client_id?: number;
    public payer_tin: string;
    public payer_account: string;
    public recipient_tin: string;
    public recipient_account: string;
    public payment_order_no: number;
    public payment_order_date: string | Date;
    public payment_order_transfer_date: string | Date;
    public payment_sum: number;
    public payment_purpose: string;
    public cod_type: number;
    public status: number;
    public original_payload: string;
    public source_filename?: string;
    public spread_sum?: number;
    public is_spreading_complete?: number | boolean;

    public payer_name?: string;
    public recipient_name?: string;
    public client?: ClientInfo;
    public company?: ParentCompany;

    public agent_reports_parts?: AgentReportBankPaymentOrderPart[];
}

export class AgentReportBankPaymentOrderPart extends BaseModel {
    /** @var int Статус платёжного поручения в CRM: черновик */
    public static readonly STATUS_DRAFT = 1;
    /** @var int Статус платёжного поручения в CRM: отправлен в банк */
    public static readonly STATUS_SENT = 2;
    /** @var int Статус платёжного поручения в CRM: исполнен */
    public static readonly STATUS_EXECUTED = 3;
    /** @var int Статус платёжного поручения в CRM: проверено */
    public static readonly STATUS_VERIFIED = 4;

    public static readonly STATUSES = [
        {id: BankPaymentOrder.STATUS_DRAFT, name: 'Черновик', classes: null},
        {id: BankPaymentOrder.STATUS_SENT, name: 'Отправлено в банк', classes: null},
        {id: BankPaymentOrder.STATUS_EXECUTED, name: 'Исполнено', classes: null},
        {id: BankPaymentOrder.STATUS_VERIFIED, name: 'Проверено', classes: null},
    ];

    public agent_report_id: number;
    public bank_payment_order_id: number;
    public payment_sum: number;
    public status: number;

    public agent_report?: AgentReport;
    public bank_payment_order?: BankPaymentOrder;
}

export class BankPaymentOrderParserItem extends BankPaymentOrder {
    public static readonly PARSER_STATUS_UNPROCESSED = 1;
    public static readonly PARSER_STATUS_SKIPPED = 2;
    public static readonly PARSER_STATUS_MISSING = 3;
    public static readonly PARSER_STATUS_CREATED = 4;
    public static readonly PARSER_STATUS_EXISTS = 5;
    public static readonly PARSER_STATUS_MISMATCH = 6;
    public static readonly PARSER_STATUS_PENDING_EXECUTED = 7;
    public static readonly PARSER_STATUS_UPDATED_EXECUTED = 8;

    public static readonly PARSER_STATUS_DESCRIPTION = [
        '',
        'Не обработана',
        'Пропущена',
        'К созданию',
        'Запись создана',
        'Запись существует, без изменений',
        'Запись существует, обнаружены различия',
        'Черновая запись существует, ожидает обновления после проводки',
        'Черновая запись существует, обновлена после проводки',
    ];

    public x_payer_name?: string;
    public x_payer_name_original?: string;
    public x_payer_client_id?: number;
    public x_payer_company_id?: number;

    public x_recipient_name?: string;
    public x_recipient_name_original?: string;
    public x_recipient_client_id?: number;
    public x_recipient_company_id?: number;

    public x_payment_order_transfer_date__expense?: string | Date;
    public x_payment_order_transfer_date__income?: string | Date;

    public x_parser_status: number;
    public x_parser_message?: string;
    public x_record?: BankPaymentOrder;
    public x_diff: boolean;
    public x_diff_database: any;
    public x_diff_parser: any;

    public x_original_payload_expanded = false;
    public x_original_payload_first_line = false;
}

export interface IBankPaymentOrderSpreadingSuggestionPayload {
    bank_payment_order: BankPaymentOrder;
    reports: IAgentReportsWithPaymentsBriefItem[];
}

export interface IAgentReportsWithPaymentsBriefItem {
    id: number;
    number: number;
    is_payment_without_service_fee: number;
    is_cod_transfer_separated_by_type: number;
    status: number;
    date_start: string;
    date_end: string;
    sum_np: number;
    sum_cod_cash: number;
    sum_cod_cashless: number;
    sum_cod_cashless_card: number;
    sum_cod_cashless_sbp: number;
    sum_tariff_total_with_nds: number;
    transferred_mixed: number;
    transferred_cash: number;
    transferred_cashless: number;
    received_fee: number;

    rest_cod_mixed: number;
    rest_cod_cash: number;
    rest_cod_cashless: number;
    rest_fee: number;

    max_transfer_mixed: number;
    max_transfer_cash: number;
    max_transfer_cashless: number;
    max_receive_fee: number;

    bank_payment_orders_parts: IAgentReportsWithPaymentsBriefItemSpreadPart[];

    x_disabled: boolean;
    x_checked: boolean;
    x_sum: number;
    x_max: number;
}

export interface IAgentReportsWithPaymentsBriefItemSpreadPart {
    agent_report_id: number;
    bank_payment_order_id: number;
    direction: number;
    payment_order_no: number;
    payment_order_date: string;
    payment_order_transfer_date: string;
    payment_sum: string;
    part_status: string;
    status: string;
}
