import {CourierOption, Order, OrderBarCode, ReturnAct, User} from '../../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from 'app/service/sklad.service';
import * as $ from 'jquery';
import {HelpersService} from '../../../service/helpers.service';
import {ErrorDialogService} from '../../../components/error-dialog/errordialog.service';
import {NotifierService} from '../../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {DialogService} from "../../../components/dialog/dialog.service";
import {CurrentUserService} from '../../../service/current-user.service';

interface IReturnSomethingOnStockResponse {
    place?: OrderBarCode,
    order?: Order,
    return?: ReturnAct,
    message?: string,
}

@Component({
    selector: 'app-reception-couriers',
    templateUrl: './reception-couriers.component.html',
    styleUrls: ['./reception-couriers.component.scss'],
})
export class ReceptionCouriersComponent implements OnInit {
    public isAdmin = false;
    public formcheckCourier: FormGroup;
    public formorderCourier: FormGroup;

    public orders = [];
    public zorders = [];
    public printOrder;
    public printReturn = false;

    hideButton = false;
    showCloseCourierButton = false;
    loadingZOrder = false;
    loadingOrder = false;
    loadingReturnActResolution = false;
    isCourierClosed = false;
    courierMlist = null;

    courier: CourierOption = null;
    courierDate = null;

    public placeAdoptedStatus;

    barCodeResult: IReturnSomethingOnStockResponse;
    public classAlert: string;

    /* устанавливаем фокус на input штрихкода */
    @ViewChild('barCodeInput') barCodeInput: ElementRef;
    @ViewChild('courierInput') courierInput: ElementRef;
    @ViewChild('datePicker') datePicker: ElementRef;

    public ordersWithReturnDocuments = [];
    public returnActs: ReturnAct[] = [];
    public ordersWithPretension = [];

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        public error: ErrorDialogService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private dialog: DialogService,
        protected currentUserService: CurrentUserService
    ) {
        this.placeAdoptedStatus = OrderBarCode.STATUS_ADOPTED

        this.formcheckCourier = new FormGroup({
            oldCourierId: new FormControl('', Validators.required),
            dateCour: new FormControl('', Validators.required),
        });

        this.formorderCourier = new FormGroup({
            barCode: new FormControl('', Validators.required),
        });

    }

    ngOnInit() {
        this.currentUserService.get().subscribe((user: User) => {
            this.isAdmin = user.isRoleAdmin();
        });
        this.setFocusOnCourierInput()
    }

    checkStatusZOrder(zorder: any) {
        return zorder.status === 3 || zorder.status === 7;
    }

    onDateChange() {
        this.courierDate = this.datePipe.transform(this.formcheckCourier.value.dateCour, 'yyyy-MM-dd');
        this.loadDebt();
    }

    loadDebt() {
        if (!this.courier) {
            this.dangerNotifier('Не выбран курьер');
            this.setFocusOnCourierInput();
            return;
        }
        if (!this.courierDate) {
            this.dangerNotifier('Не выбрана дата');
            this.datePicker.nativeElement.focus();
            return;
        }
        this.loadingZOrder = this.loadingOrder = true;
        this.orders = this.zorders = [];
        this.api.courierDebt({userId: this.courier.user_id, date: this.courierDate}).subscribe(data => {
            this.ordersWithReturnDocuments = data.returnDocuments;
            this.returnActs = data.returnActs;
            this.ordersWithPretension = data.pretensions;
            // TODO Временно разрешаем закрытие курьера без сдачи возвратных актов (+1 место в коде)
            // const isClosePossible = !this.findReturnActsOnDelivery()
            const isClosePossible = true;
            this.loadingZOrder = this.loadingOrder = false;
            this.showCloseCourierButton = (!data.orders.length   && !data.zorders.length && isClosePossible);
            this.orders = data.orders;
            this.zorders = data.zorders;
            this.isCourierClosed = data.isCourierClosed;
            this.courierMlist = data.courierMlist;
            this.barCodeInput.nativeElement.focus();
        }, () => {
            this.loadingZOrder = this.loadingOrder = false;
        });
    }

    findReturnActsOnDelivery() {
        for (let i = 0; i < this.returnActs.length; i++) {
            if (this.returnActs[i].status === ReturnAct.STATUS_ONDELIVERY) {
                return true;
            }
        }
        return false;
    }

    loadCourier() {
        this.courier = null;
        this.orders = this.zorders = [];
        this.api.getNewCourierId(this.formcheckCourier.value).subscribe(courier => {
            this.courier = courier;

            if (this.courier) {
                this.setFocusOnDateInput();
            }
        });
    }

    returnSomethingOnStock(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.loadingOrder = true;
        this.barCodeResult = null;
        this.api.returnSomethingOnStock({
            userId: this.courier.user_id,
            barCode: this.formorderCourier.value.barCode,
        }).subscribe((result: IReturnSomethingOnStockResponse) => {
            this.loadingOrder = false;
            this.barCodeResult = result;

            if (this.barCodeResult.return) {
                // Складовщик увидит две кнопки и примет решение по судьбе возвратного акта
                return;
            }

            if (this.barCodeResult.order && this.barCodeResult.order.is_need_new_label && this.barCodeResult.order.shift_number == 2) {
                this.printReturn = false;
                this.printOrder = this.barCodeResult.order.uid;
                this.api.setLabelPrinted(this.barCodeResult.order.uid).subscribe((data: any) => {
                    console.log('printed');
                });
            }

            this.formorderCourier.reset();
            this.loadDebt();
            if (this.barCodeResult.order) {
                if (this.barCodeResult.order.status == 6 || this.barCodeResult.order.status == 7 || this.barCodeResult.order.status == 8) {
                    this.classAlert = 'return';
                    return;
                } else {
                    const diff = new Date(this.barCodeResult.order.delivery_date).getDate() - new Date().getDate();

                    if (diff == 0) {
                        this.classAlert = 'today';
                    } else {
                        this.classAlert = 'nextday';
                    }
                }
            }
        }, error => {
            this.loadingOrder = false;
        });
    }

    putZorderOnStock(id) {
        this.loadingZOrder = true;
        this.api.putZorderOnStock({zorderId: id}).subscribe(courier => {
            this.loadingZOrder = false;
            console.log(courier);
            /** todo refactoring - возвращать с сервера объект */
            this.barCodeResult = {
                message: courier,
                order: new Order({
                    id: id,
                }),
            };
            this.loadDebt();
        }, () => {
            this.loadingZOrder = false;
        });
    }

    closeCourierOnStock() {
        this.loadingZOrder = this.loadingOrder = true;
        this.api.closeCourierOnStock({
            userId: this.courier.user_id,
            date: this.courierDate,
            returnDocuments: this.ordersWithReturnDocuments,
            ordersWithPretension: this.ordersWithPretension
        }).subscribe(message => {
            this.dangerNotifier(message.message)
            this.barCodeResult = null;
            this.courier = null;
            this.loadingZOrder = this.loadingOrder = false;
            this.formcheckCourier.patchValue({
                oldCourierId: '',
            })
            this.setFocusOnCourierInput();
        }, () => {
            this.loadingZOrder = this.loadingOrder = false;
        });
    }

    /**
     * Проверка возможности закрыть курьера
     */
    checkPossibilityToCloseCourier() {
        let result = true;
        this.ordersWithReturnDocuments.forEach(returnDoc => {
            if (returnDoc.is_document_returned === null) {
                return result = false;
            }
        });

        this.ordersWithPretension.forEach(order => {
            if (order.is_pretension_returned === null) {
                return result = false;
            }
        });


        if (this.findReturnActsOnDelivery()) {
            // TODO Временно разрешаем закрытие курьера без сдачи возвратных актов (+1 место в коде)
            // return false;
            return true;
        }

        return result;
    }

    printReturnLabel(order) {
        this.printReturn = true;
        this.printOrder = order.uid;
    }

    setCargoCourier() {
        this.notifier.openNotifier('Курьер назначается...')
        this.api.setCargoCourierWhenReceivingAtWarehouse(this.barCodeResult.order.id).subscribe(result => {
            this.notifier.openNotifier(result, null, {class: 'success', duration: 1500});
        }, error => {
            console.warn(error);
            this.dangerNotifier(error.error.response.message)
        });
    }

    private setFocusOnCourierInput() {
        setTimeout(() => {
            this.courierInput.nativeElement.focus();
            this.courierInput.nativeElement.click();
        }, 0);
    }

    private setFocusOnDateInput() {
        setTimeout(() => {
            this.datePicker.nativeElement.focus();
            this.datePicker.nativeElement.click();
        }, 0);
    }

    private dangerNotifier(message) {
        return this.notifier.openNotifier(message, null, {class: 'danger', duration: 3000});
    }

    onUpdateButtonClick() {
        if (!this.courierDate) {
            return;
        }
        this.onDateChange();
    }

    onReturnActReturnClick() {
        if (!this.barCodeResult || !this.barCodeResult.return) {
            return;
        }

        this.returnReturnActOnReception({
            userId: this.courier.user_id,
            barcode: this.barCodeResult.return.old_vact_id,
        });
    }

    onReturnActSignedClick() {
        if (!this.barCodeResult || !this.barCodeResult.return) {
            return;
        }

        this.adoptSignedReturnActOnReception({
            userId: this.courier.user_id,
            barcode: this.barCodeResult.return.old_vact_id,
        })
    }

    /**
     * Возврат возвтратного акта с товарами обратно на склад
     * @param userId
     * @param barcode
     */
    private returnReturnActOnReception({userId, barcode}) {
        this.loadingReturnActResolution = true;
        this.api.returnReturnActOnReception({userId, barcode})
            .subscribe((result: IReturnSomethingOnStockResponse) => {
                this.loadingReturnActResolution = false;
                this.barCodeResult = result;
            }, () => {
                this.loadingReturnActResolution = false;
            });
    }

    /**
     * Приём подписанного возвратного акта
     * @param userId
     * @param barcode
     */
    private adoptSignedReturnActOnReception({userId, barcode}) {
        this.api.adoptSignedReturnActOnReception({userId, barcode})
            .subscribe((result: IReturnSomethingOnStockResponse) => {
                this.loadingReturnActResolution = false;
                this.barCodeResult = result;
            }, () => {
                this.loadingReturnActResolution = false;
            });
    }

    onDeleteCourierMlist() {
        if (!this.courierMlist) {
            return;
        }
        const dialog = this.dialog.confirm('Вы уверены? Действие нельзя отменить').subscribe(result => {
            if (result) {
                this.api.deleteCourierMlist(this.courierMlist.id).subscribe(data => {
                    this.onUpdateButtonClick();
                })
            }

            dialog.unsubscribe();
        })
    }
}
