import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {FormGroup} from '@angular/forms';


@Component({
    selector: 'app-pallet-modal',
    templateUrl: './pallet-modal.component.html',
    styleUrls: ['./pallet-modal.component.scss']
})
export class PalletModalComponent implements OnInit {
    // DELETAH
    public allPlacesList: any[] = [];
    public scanned_barcodes: string[] = [];
    public scanned_orders: number[] = [];
    public allBarcodesCount = 0;
    public message = '';
    public printOrders: any[] = [];
    public formScan: FormGroup;
    public notAllPlacesScanned = false;
    // DELETAH
    public pallet;
    public width;
    public addPlaceFlag = false;
    public allPlacesFlag = false;
    public scannedBarcodes: any[] = [];
    public allBarcodes: any[] = [];
    public fullScan = false;

    constructor(
        public helpers: HelpersService,
        protected app: AppComponent,
        protected api: SkladService) {
        this.width = window.innerWidth * 0.5;
    }

    ngOnInit() {
    }


    onClose() {
        this.app.closeDialog(this);
    }

    addPlaceInput() {
        this.addPlaceFlag = !this.addPlaceFlag;
    }/*
    placesInfo(e) {
        if (!e.target.value) {
            alert('Не введён штрихкод');
            return;
        }
        this.api.findForPacking(e.target.value.trim()).subscribe((data: any) => {
            if (!data.bar_code) {
                alert('Отсканируйте все места!');
                return;
            } else {
                // alert('');
            }
            e.target.value = '';
            this.fullScan = true;
            this.scannedBarcodes.push(data.bar_code.id);
            this.checkBarcodes(this.scannedBarcodes, this.allBarcodes);
            console.log(this.scannedBarcodes);
            console.log(this.allBarcodes);
            //if (!this.checkOrderInList(data.order)) {
                let orderArray: any[] = [];
                orderArray.push(data.order.id);
                orderArray['all'].push(data.order.bar_codes);
                orderArray['scanned'].push(data.bar_code.id);
                this.scannedBarcodes.concat(orderArray);
            //}
        });
    }
    checkOrderInList(order) {
        return (this.scanned_orders.indexOf(order.id) > -1);
    }
    checkBarcodes(scanned, all) {
      for (let barcode of all) {
          barcode.checked = false;
      }
      for (let barcode of all) {
          for (const checked of scanned) {
              if (barcode.id === checked) {
                  barcode.checked = true;
              }
          }
      }
      if (scanned.length === all.length) {
          this.allPlacesFlag = true;
      }
    }
    addPlace(e) {
        this.api.addPlacePallet(this.pallet.id, e.target.value).subscribe(data => {
            console.log(data);
        });
        console.log(e.target.value);
        this.updatePallet();
    }*/
    deletePlace(barcode) {
        this.api.findForPacking(barcode).subscribe((data: any) => {
            console.log(data);
            const q = confirm('Будет удалено ' + data.order.bar_codes.length + ' мест!');
            if (q) {
                this.api.deletePlacePallet(this.pallet.id, data.order.bar_codes.map(item => item.id)).subscribe(rez => {
                    this.updatePallet();
                    console.log(rez);
                });
            }
        });
    }

    updatePallet() {
        this.api.getTransit(this.pallet.id).subscribe((data) => {
            this.pallet = data;
        });
    }


    /////////////////////////////
    onScan(e) {

        if (!e.target.value) {
            this.message = 'Не введен штрихкод ';
            return;
        }

        const search = e.target.value;
        if (this.is_scaned(search)) {
            this.message = 'Это место уже отсканировано';
        }

        this.api.findForPacking(search).subscribe((data: any) => {

            if (!data.bar_code) {
                this.message = 'Отсканируйте места заказа!';
                return;
            } else {
                this.message = '';
            }
            e.target.value = '';
            this.notAllPlacesScanned = true;
            this.scanned_barcodes.push(data.bar_code.bar_code);

            if (!this.orderInList(data.order)) {
                this.scanned_orders.push(data.order.id);
                this.allPlacesList.push(data.order);
                this.allBarcodesCount += data.order.bar_codes.length;

            }
            this.printOrders.push(data.order);
        });
        console.log('done');
    }

    orderInList(order) {
        return (this.scanned_orders.indexOf(order.id) > -1);
    }

    is_scaned(barcode) {
        if (this.scanned_barcodes.indexOf(barcode) > -1) {
            return true;
        }
        console.log('не все места отсканированы' + this.notAllPlacesScanned);
        this.notAllPlacesScanned = true;
        return false;

    }

    onDelete(uid) {

        for (let i = 0; i < this.allPlacesList.length; i++) {
            if (this.allPlacesList[i].uid === uid) {
                for (let j = 0; j < this.allPlacesList[i].bar_codes.length; j++) {

                    const fnd = this.scanned_barcodes.indexOf(this.allPlacesList[i].bar_codes[j].bar_code);
                    if (fnd > -1) {

                        this.scanned_barcodes.splice(fnd, 1);
                    }
                }
                this.allBarcodesCount -= this.allPlacesList[i].bar_codes.length;
                this.scanned_orders.splice(this.scanned_orders.indexOf(this.allPlacesList[i].id), 1);
                this.allPlacesList.splice(i, 1);
                break;
            }
        }
    }

    isScannedAll() {
        return ((this.allBarcodesCount === this.scanned_barcodes.length) && (this.allBarcodesCount > 0));
    }

    onCreatePack() {

        const barcode_ids: number[] = [];
        this.allPlacesList.forEach(function (item) {
            item.bar_codes.forEach(function (br) {
                barcode_ids.push(br.id);
            });
        });
        console.log(this.pallet.id);
        console.log(barcode_ids);
        this.api.addPlacePallet(this.pallet.id, barcode_ids).subscribe(data => {
            alert('В ТЕ №' + this.pallet.id + ' было добавлено ' + barcode_ids.length + ' мест');
            this.updatePallet();
            this.clearAll();
        });
    }

    clearAll() {
        this.allPlacesList = [];
        this.scanned_orders = [];
        this.scanned_barcodes = [];
    }

}
