import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {LaraService} from '../../service/lara.service';
import {Observable, Subscription} from 'rxjs';

interface StatusInterface {
    status_ids: object,
    substatus_ids: object,
}

@Component({
    selector: 'app-schedule-status-select',
    templateUrl: './schedule-status-select.component.html',
    styleUrls: ['./schedule-status-select.component.scss']
})
export class ScheduleStatusSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() protected autoSelectSubstatuses = false;
    @Input() requiredValue = false;
    @Input() initStatus = null;
    @Input() initSubstatus = null;
    @Input() placeHolderStatus = 'Статус';
    @Input() placeHolderSubstatus = 'Подстатус';
    @Output() onChange = new EventEmitter<StatusInterface>();

    private lastStatus = [];
    private lastSubstatus = null;
    dropdownStatusList = [];
    dropdownSubstatusList = [];
    selectedStatuses = [];
    selectedSubstatuses = [];
    dropdownSettings: IDropdownSettings = {};
    substatusNotInit = true;

    private eventsSubscription: Subscription;
    @Input() events: Observable<any>;

    constructor(protected api: LaraService) {}
    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => {
                this.onSetSelectedValues(data)
            });
        }

        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: 1,
            allowSearchFilter: false
        };
        this.api.getCourierScheduleStateses().subscribe(value => {
            this.dropdownStatusList = value;
            if (this.initStatus) {
                this.selectedStatuses = value.filter(val => {
                    return val.id === this.initStatus;
                })
            }
        });
    }

    /**
     * Событие при смене статуса
     */
    onChangeStatus(event) {
        /** Идентификаторы выбранных статусов */
        const statusIds = this.selectedStatuses.map((item) => item.id)

        /** Выделение списка подстатусов доступных для выбранных статусов */
        this.dropdownSubstatusList = ((this.dropdownStatusList.filter((value) => {
            return statusIds.includes(value.id)
        })).map(x => x.substatuses)).reduce((a, b) => a.concat(b), []);

        /** очистка выбранных подстатусов при смене статуса */
        this.selectedSubstatuses = [];

        let skip = false;
        /** Инциализация значения по умолчанию для подстатуса */
        if (this.initSubstatus && this.selectedSubstatuses.length < 1 && this.substatusNotInit && this.dropdownSubstatusList.length > 0) {
            this.selectedSubstatuses = this.dropdownSubstatusList.filter(val => {
                return val.id === this.initSubstatus;
            })
            skip = true;
            this.substatusNotInit = false;
        }

        /** Авто включение всех доступных подстатусов для статуса */
        if (this.autoSelectSubstatuses) {
            this.selectedSubstatuses = this.dropdownSubstatusList;
        }

        this.checkRequiredStatus(event, skip);
        this.onEmitChanges()
    }

    /**
     * Обязательность статуса
     * @param event
     * @param skip
     * @private
     */
    private checkRequiredStatus(event, skip) {
        if (!this.requiredValue) {
            return;
        }
        /** подставляю первый подстатус выбранного статуса */
        if (!this.substatusNotInit && this.dropdownSubstatusList && !skip) {
            this.selectedSubstatuses = [];
            if (this.dropdownSubstatusList.length > 0 ) {
                console.warn(this.dropdownSubstatusList[0]);
                this.selectedSubstatuses.push(this.dropdownSubstatusList[0]);
            }
        }
        if (this.lastStatus.length === 0 && event.length === 0) {
            return;
        }

        if (event.length < 1) {
            this.selectedStatuses = [];
            this.selectedStatuses.push(this.lastStatus);
        }
        if (event.length === 1) {
            this.lastStatus = event[0];
        }
        skip = false;
    }

    /**
     * Обязательность подстатуса
     * @param event
     * @private
     */
    private checkRequiredSubstatus(event) {
        if (!this.requiredValue) {
            return;
        }

        if (event.length < 1  && this.lastSubstatus !== null) {
            this.selectedSubstatuses = [];
            this.selectedSubstatuses.push(this.lastSubstatus);
        }

        if (event.length === 1) {
            this.lastSubstatus = event[0];
        }
    }



    /**
     * Событие при смене подстатуса
     */
    onChangeSubstatus(event) {
        this.checkRequiredSubstatus(event);
        this.onEmitChanges()
    }

    onEmitChanges() {
        this.onChange.emit({
            'status_ids': this.selectedStatuses.length > 0 ? this.selectedStatuses.map(x => x.id) : this.selectedStatuses,
            'substatus_ids': this.selectedSubstatuses.length > 0 ? this.selectedSubstatuses.map(x => x.id) : this.selectedSubstatuses,
        });
    }

    onSetSelectedValues(data) {
        this.selectedStatuses = [];
        this.selectedStatuses = this.dropdownStatusList.filter(x => {
           return data['status_ids'].indexOf(x.id) !== -1
       })
        setTimeout(() => {
            this.selectedSubstatuses = this.dropdownSubstatusList.filter(x => {
                return data['substatus_ids'].indexOf(x.id) !== -1
            });
        }, 0);
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }
}
