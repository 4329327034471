import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../app.component';
import {FinanceService} from "../../../service/finance.service";

@Component({
  selector: 'app-agent-report-edit',
  templateUrl: './agent-report-edit.component.html',
  styleUrls: ['./agent-report-edit.component.scss']
})
export class AgentReportEditComponent implements OnInit {
  public width = 700;
  public windowHeight = 500;
  public title: string;

  public newStatusLoading = false;

  public agentReportId;
  public agentReportInfo;

  constructor(
      protected app: AppComponent,
      protected api: FinanceService,
  ) {
  }

  ngOnInit() {
    this.getAgentReportInfo();
    this.setDefaultTitle();
  }

  onClose() {
    this.app.closeDialog(this);
  }

  setDefaultTitle() {
    this.title = 'Редактирование агентского отчёта ' + this.agentReportId;
  }

  getAgentReportInfo() {
    this.api.getAgentReport(this.agentReportId).subscribe((data) => {
      this.agentReportInfo = data;
      console.log(this.agentReportInfo);
    }, error => {

    });
  }

  updateReport(params) {
    this.newStatusLoading = true;

    this.api.updateAgentReport(this.agentReportId, params).subscribe((data) => {
      this.getAgentReportInfo();
    }, error => {

    })
  }
}
