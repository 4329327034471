import {Observable} from 'rxjs';
import {MemberService} from '../service/member.service';
import {User} from '../service/models';
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";


@Injectable()

export class UserResolver implements Resolve<User> {

    constructor(
        private api: MemberService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        let user = this.api.usersList();
        return user;
    }
}

