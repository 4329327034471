import {EventEmitter, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {DialogComponent} from './dialog.component';

@Injectable()
export class DialogService {
    private event = new EventEmitter();

    constructor(
        public dialog: MatDialog
    ) {
    }

    alert(message, width = 300, height = 500, isHtml = false): any {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: width + 'px',
            maxHeight: height + 'px',
            data: {
                type: 'alert',
                message: message,
                event: this.event,
                isHtml: isHtml
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The ALERT dialog was closed');
        });

        return this.event;
    }

    confirm(message, isHtml = false) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                isHtml: isHtml,
                type: 'confirm',
                message: message,
                event: this.event
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The CONFIRM dialog was closed');
        });

        return this.event;
    }

    choooseDate(message) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '500px',

            data: {
                type: 'confirm',
                message: message,
                event: this.event
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The CONFIRM dialog was closed');
        });

        return this.event;
    }

    openEditDialog(message, value, placeholder = '') {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '400px',

            data: {
                type: 'edit',
                placeholder: placeholder,
                message: message,
                event: this.event,
                value: value
            }
        });

        return this.event;

    }

    /**
     * Диалог выбора чего-либо
     * принимает массив объектов для выбора, пример {'id': 1, 'name': 'Парковка'}
     * @param message - Заголовок
     * @param currentValue - текущее выбранное значение
     * @param values - Массив объектов для выбора
     * @param required - Обязателен лы выбор
     * @param placeholder - Подсказка
     * @param errorMessage - Сообщение об ошибке
     */
    chooseSomething(message, currentValue, values, required = true, placeholder = '', errorMessage = '') {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '400px',
            data: {
                type: 'chooseSomething',
                required: required,
                placeholder: placeholder,
                errorMessage: errorMessage,
                message: message,
                event: this.event,
                currentValue: currentValue,
                values: values
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The chooseSomething dialog was closed');
        });
        return this.event;
    }

    openImageDialog(image, url = null, text = null, width = 500) {

        const dialogRef = this.dialog.open(DialogComponent, {
            width: width + 'px',
            data: {
                type: 'image',
                event: this.event,
                image: image,
                url: url,
                text: text
            }
        });

        return this.event;
    }
}
