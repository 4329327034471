import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {NotifierService} from '../../service/notifier.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IFileType} from '../../service/models';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {AddressService} from '../../service/address.service';

@Component({
    selector: 'app-pochta-ru-postcodes',
    templateUrl: './pochta-ru-postcodes.component.html',
})
export class PochtaRuPostcodesComponent implements OnInit {
    @Input() type: IFileType | null = null;
    @Input() availableTypes: IFileType[] | null = null;

    public fileForm: FormGroup;
    public isLoading = false;

    constructor(
        private http: HttpNoInterceptorService,
        private notifier: NotifierService,
        protected app: AppComponent,
        protected api: AddressService,
        public dialogSrv: DialogService,
    ) {
        this.fileForm = new FormGroup(
            {
                file: new FormControl(null, Validators.required),
            },
        );
    }

    ngOnInit() {
    }

    onFormSubmit() {
        if (!this.fileForm.controls.file.value) {
            return this.notifier.openNotifier('Пожалуйста, выберите файл!', null, {class: 'danger', duration: 3000});
        }

        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', this.fileForm.controls.file.value);

        this.http.post('/pochta-ru/uploadPostCodes', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe(message => {
                this.isLoading = false;
                this.fileForm.controls.file.reset();

                this.dialogSrv.alert(message);
            }, (error: HttpErrorResponse) => {
                this.isLoading = false;
                if (!error.error.response) {
                    this.dialogSrv.alert('Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова');
                } else {
                    this.dialogSrv.alert(error.error.response.error);
                }
            });
    }

    importMissingPostalCodes() {
        this.isLoading = true;
        this.api.importMissingPostalCodes().subscribe((message) => {
            this.dialogSrv.alert(message);
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.dialogSrv.alert(error.error.response.message);
        });
    }
}
