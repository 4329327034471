import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppComponent} from '../../app.component';
import {HelpersService} from '../../service/helpers.service';
import {ReturnActService} from '../../service/return-act.service';
import {ReturnAct} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-return-act-create-card',
    templateUrl: 'return-act-create-card.component.html',
    styleUrls: ['./return-act-create-card.component.scss'],
})
export class ReturnActCreateCardComponent implements OnInit {
    public loading = false;
    @Input('client_id') public client_id: number;
    @Input('warehouse_id') public warehouse_id: number | null;
    @Input('hub_id') public hub_id: number;
    @Output() onDestroy = new EventEmitter<boolean>();

    constructor(
        private app: AppComponent,
        private api: ReturnActService,
    ) {
    }

    ngOnInit(): void {
    }


    public onOpen() {
        this.loading = true;
        this.api.getInfoBeforeCreateAct({
            hub_id: this.hub_id,
            client_id: this.client_id,
            warehouse_id: this.warehouse_id,
        }).subscribe(data => {
            data.hub_id = this.hub_id;
            this.showDialog(data);
            this.loading = false;
        }, error => {
            this.loading = false;
        })
    }

    private showDialog(data) {
        const dialog = this.app.createDialog(ReturnActCreateCardDialogComponent, {
            data: data,
        });
        dialog.onDestroy(() => {
            this.onDestroy.emit(true);
        })
    }
}

@Component({
    selector: 'app-return-act-create-card-dialog',
    styleUrls: ['./return-act-create-card.component.scss'],
    templateUrl: 'return-act-create-card-dialog.html',
})
export class ReturnActCreateCardDialogComponent implements OnInit {
    public data;
    public returnAct = ReturnAct;
    public type = ReturnAct.TYPE_ANY;
    public hub_id;

    constructor(
        protected app: AppComponent,
        public api: ReturnActService,
        public helpers: HelpersService,
        public notifier: NotifierService,
    ) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * Создание нового возвратного акта
     */
    createNewReturnAct() {
        this.notifier.openNotifier('Создание нового акта...');
        this.api.createNewReturnAct({
            hub_id: this.data.hub_id,
            client_id: this.data.client.id,
            warehouse_id: this.data.warehouse ? this.data.warehouse.id : null,
            type: this.type,
        }).subscribe(data => {
            this.notifier.openNotifier(data.message);
            this.onClose();
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }

    /**
     * Событие при смене типа акта
     * @param event
     */
    onChangeReturnActType(event: number[]) {
        this.type = event.length > 0 ? event[0] : null;
    }
}
