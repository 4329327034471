import {BaseModel} from "../../../service/models";

export class ZorderListResponseItem extends BaseModel {
    id: number;
    old_zorder_id: number;
    status: number;
    date: string;
    is_sameday: boolean | number;
    time_begin?: string;
    time_end?: string;
    address_text?: string;
    contact_phone?: string;
    contact_name?: string;
    zone_id?: number;
    comments?: string;

    client_id: number;
    client_uid_old: string;
    client_name: string;

    warehouse_name?: string;
    warehouse_address_text?: string;
    warehouse_contact_phone?: string;
    warehouse_contact_name?: string;

    courier_id?: string;
    courier_name?: string;
}
