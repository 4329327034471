import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rights-failure',
  templateUrl: './rights-failure.component.html',
  styleUrls: ['./rights-failure.component.scss']
})
export class RightsFailureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
