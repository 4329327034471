import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {User, UserHub} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-mactlist',
    templateUrl: './mactlist.component.html',
    styleUrls: ['./mactlist.component.scss']
})

export class MactlistComponent implements OnInit {
    public selectedCourier: User;
    public date = null;
    public gridData: GridDataResult;
    public formActs: FormGroup;
    private pageTitle = 'Акты матответственности';
    public pageSize = 30;
    public skip = 0;
    @ViewChild('input_date') inputDate: ElementRef;

    public selectedHubId: number;
    public selectedHubs: UserHub[];

    public appHubChangeEventSubject: Subject<any> = new Subject<any>();

    constructor(private title: Title,
                private api: SkladService,
                private currentUserService: CurrentUserService,
                private eventerService: EventerService,
                public helpers: HelpersService
    ) {
        this.title.setTitle(this.pageTitle);
        this.formActs = new FormGroup({
            inputDate: new FormControl(''),
        });
        this.eventerService.primaryHubChange.subscribe((hub) => {
            console.info('primaryHubChange', hub);
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        const selectedHub = this.currentUserService.getCurrentHub();
        this.onChangeHub(selectedHub);
    }

    getMactLink(data) {
        return [
            '/print-courier-mact',
            {
                'mactId': data
            }
        ]
    }

    onSelectUser(user: User) {
        this.selectedCourier = user;
        // this.loadActs();
    }

    onDateChange() {
        this.date = this.inputDate.nativeElement.value;
    }

    public btnClick() {
        this.skip = 0;
        this.loadActs();
    }

    public loadActs() {
        if (!this.date) {
            alert('Не выбрана дата');
            return;
        }
        const courierId = (this.selectedCourier) ? this.selectedCourier.id : 0;
        this.api.getCourierActs(this.selectedHubId, this.pageSize, this.skip, this.helpers.formatDateForSQL(this.date), courierId).subscribe(data => {
            this.gridData = {data: data.courier_acts, total: data.total};
        });
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadActs();
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.appUserSelectEventsSubjectEmit();
    }
}
