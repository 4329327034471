import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {SkladService} from '../../service/sklad.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {MaxSizeValidator} from '@angular-material-components/file-input';
import {ClientInfo, ClientSettingKey, Hub, PpActClient, User, UserHub} from '../../service/models';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../service/helpers.service';
import {PpActClientProcessComponent} from '../pp-act-client/pp-act-client-process/pp-act-client-process.component';
import {CurrentUserService} from '../../service/current-user.service';

@Component({
    selector: 'app-act-client-import',
    templateUrl: './pp-act-client-import.component.html',
    styleUrls: ['./pp-act-client-import.component.scss'],
})
export class PpActClientImportComponent implements OnInit {

    @Input() isOnlyActLoading: boolean = false;

    public width = 700;
    public windowHeight = 600;
    public form: FormGroup;

    public fileIsUploading: boolean = false;

    /* 30 МБ в байтах */
    private maxSize = (30) * (1024 * 1024);
    public maxActDate: Date = new Date();

    public selectedClients: ClientInfo[] = [];
    public failedBarcodes: string[];
    public loadingStatus: string[] = [];
    public loadingError: string;
    public alphabet: string[] = [];
    public columnNumbers: number[] = [];
    public hasNoSettings: boolean = false;
    public settingsWhereChanged: boolean = false;
    public selectedHub: Hub;

    public loadedAct: PpActClient;

    private settings = [
        'settings_data_start_line',
        'settings_data_column_number',
    ];

    constructor(
        protected app: AppComponent,
        protected api: SkladService,
        private http: HttpNoInterceptorService,
        protected dialog: DialogService,
        private datePipe: DatePipe,
        private helper: HelpersService,
        private currentUser: CurrentUserService,
    ) {
        this.form = new FormGroup({
            'act_date': new FormControl(new Date()),
            'files': new FormArray([]),
            'is_by_places_barcodes': new FormControl(null, Validators.required),
        });

        this.currentUser.get().subscribe(async (user: User) => {
            this.selectedHub = this.currentUser.getCurrentHub();
        });

        for (let i = 65; i <= 90; i++) {
            this.alphabet.push(String.fromCharCode(i));
        }

        this.columnNumbers = Array.from({length: 100}, (value, key) => key);
    }

    /**
     * Добавление файла
     */
    public addFileForm() {
        (<FormArray>this.form.get('files')).push(
            new FormGroup({
                'file': new FormControl(null, [
                    Validators.required,
                    MaxSizeValidator(this.maxSize),
                ]),
                'settings_data_start_line': new FormControl(null, Validators.required),
                'settings_data_column_number': new FormControl(null, Validators.required),
            })
        )

        this.updateSettingsOnSelectedClients();
    }

    /** Удаление файла */
    public removeFileForm(index) {
        (<FormArray>this.form.get('files')).removeAt(index);
    }

    get filesControls() {
        return (<FormArray>this.form.get('files')).controls;
    }

    ngOnInit() {
    }

    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;

        if (this.selectedClients.length) {
            if (!this.filesControls.length) {
                this.addFileForm();
            }
        }

        this.updateSettingsOnSelectedClients();
    }

    /** Обновление настроек из клиента */
    private updateSettingsOnSelectedClients() {
        let hasNoSettings = false;
        this.selectedClients.map(client => {
            const columnNumber = this.helper.getClientSetting(client.settings, ClientSettingKey.ACT_DATA_COLUMN_NUMBER);
            const startLine = this.helper.getClientSetting(client.settings, ClientSettingKey.ACT_DATA_START_LINE);
            const isByPlaces = this.helper.getClientSetting(client.settings, ClientSettingKey.ACT_IS_BY_PLACES_BARCODES);

            if (columnNumber === null
                || startLine === null
            ) {
                hasNoSettings = true;
            } else {
                if (!this.settingsWhereChanged) {
                    this.form.get('is_by_places_barcodes').patchValue(isByPlaces ? 1 : 0);

                    for (const control of this.filesControls) {
                        control.get('settings_data_column_number').patchValue(columnNumber);
                        control.get('settings_data_start_line').patchValue(startLine);
                    }
                }
            }
        });

        if (hasNoSettings) {
            this.hasNoSettings = true;

            if (!this.settingsWhereChanged) {
                this.form.get('is_by_places_barcodes').patchValue(null);

                for (const control of this.filesControls) {
                    this.settings.forEach((setting) => {
                        control.get(setting).patchValue(null);
                    })
                }
            }
        } else {
            this.hasNoSettings = false;
        }
    }

    onChangeFile(fileIndex) {
        console.log('file changed');
    }

    /** Фиксируем тот факт, что настройки менялись */
    onChangeSetting() {
        this.settingsWhereChanged = true;
    }

    /**
     * Отправка файлов на сервер
     */
    onFileUpload() {
        this.failedBarcodes = null;
        this.loadingStatus = [];
        this.loadingError = null;


        if (this.selectedClients.length === 0) {
            this.loadingError = 'Пожалуйста, выберите клиента';
            return;
        }

        if (this.form.get('is_by_places_barcodes').value === null) {
            this.loadingError = 'Пожалуйста, заполните настройки';
            return;
        }

        for (const control of this.filesControls) {
            if (control.get('file').value === null) {
                this.loadingError = 'Пожалуйста, выберите файл';
                return;
            }

            if (control.get('settings_data_start_line').value === null
                || control.get('settings_data_column_number').value === null) {
                this.hasNoSettings = true;
                this.loadingError = 'Пожалуйста, заполните настройки всех файлов';
                return;
            }
        }

        const formData = new FormData();
        formData.append('act_date', this.datePipe.transform(this.form.get('act_date').value, 'yyyy-MM-dd'));
        formData.append('settings_is_by_places_barcodes', this.form.get('is_by_places_barcodes').value);
        formData.append('is_only_act_loading', this.isOnlyActLoading ? '1' : '0');
        formData.append('hub_id', this.selectedHub.id.toString());

        for (const control of this.filesControls) {
            formData.append('excel[]', control.get('file').value);
            formData.append('settings_data_start_line[]', control.get('settings_data_start_line').value);
            formData.append('settings_data_column_number[]', control.get('settings_data_column_number').value);
        }

        this.selectedClients.map(client => {
            formData.append('client_id[]', client.id.toString());
        });

        this.fileIsUploading = true;
        this.http.post('/storage/acts/loadClientPpAct', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((data) => {
                this.fileIsUploading = false;

                this.failedBarcodes = data.failed.length ? data.failed : null;

                this.loadingStatus.push('Загружено ' + (this.form.get('is_by_places_barcodes').value ? 'мест: ' : 'заказов: ') + data.created.length);

                if (data.acts) {
                    const actNumbers = [];
                    data.acts.forEach((act) => {
                        actNumbers.push(act.number);
                    })

                    this.loadingStatus.push('Созданы акты: ' + actNumbers.join(', '));
                }

                this.loadedAct = data.client_act;
            }, (error: HttpErrorResponse) => {
                this.fileIsUploading = false;

                if (!error.error.response) {
                    this.loadingError = 'Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова';
                } else {
                    this.loadingError = error.error.response.error;
                }
            });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    public onProcessClick(actId: number) {
        this.app.createDialog(PpActClientProcessComponent, {
            actId: actId,
        }).onDestroy(data => {

        })

        this.onClose();
    }

    /**
     * Может быть постобработан
     * @param act
     */
    public canBePostProcessed(act: PpActClient) {
        return act.status !== PpActClient.STATUS_POSTPROCESSED && this.isOnlyActLoading;
    }

    public onChangeHub(hub: Hub) {
        this.selectedHub = hub;
    }
}
