import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {ReturnActService} from '../../service/return-act.service';

interface DataFormat {
    id: number,
    value: string
}

@Component({
    selector: 'app-return-act-zorder-select',
    templateUrl: './return-act-zorder-select.component.html',
    styleUrls: ['./return-act-zorder-select.component.scss'],
})
export class ReturnActZorderSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Курьер';
    @Input() return_act_id: number;
    @Input() refreshable: boolean = false;
    @Input() events: Observable<number[]>;
    @Output() onChange = new EventEmitter<number[]>();
    public dropdownList: DataFormat[] = [];
    public selectedItems = [];
    public dropdownSettings: IDropdownSettings = {};
    public loading: boolean = false;
    @Input() protected multi = false;
    private eventsSubscription: Subscription;

    constructor(
        public api: ReturnActService,
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(courierIds => this.onSetCouriers(courierIds));
        }

        this.getZordersCanBeLinkedWithReturnAct();

        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'value',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true,
        };
    }

    /**
     * Обработка родительских изменений значений входящих переменных
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        console.warn(changes, this.return_act_id);
        if ('undefined' !== typeof changes['return_act_id']) {
            this.dropdownList = [];

            if (this.return_act_id) {
                this.getZordersCanBeLinkedWithReturnAct();
            }
        }
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    /**
     * Обработка изменения списка выбранных значений
     * @param newValues
     */
    onChangeModel(newValues) {
        this.onChange.emit(newValues.map((item) => item.id));
    }

    /**
     * Обработка клика по кнопке обновления списка
     */
    onRefresh() {
        if (this.return_act_id) {
            this.getZordersCanBeLinkedWithReturnAct();
        }
    }

    /**
     * Получение списка заборов к которым можно привязать возвратный акт
     * @private
     */
    private getZordersCanBeLinkedWithReturnAct() {
        this.loading = true;
        this.api.getZordersCanBeLinkedWithReturnAct(this.return_act_id).subscribe(data => {
            this.dropdownList = data;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Смена данных от родителя
     * @param courierIds
     * @private
     */
    private onSetCouriers(courierIds: number[]) {
        this.selectedItems = this.dropdownList.filter(x => {
            return courierIds.indexOf(x.id) !== -1
        })
    }
}
