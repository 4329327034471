import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-intervals-stat-details',
    templateUrl: './intervals-stat-details.component.html',
    styleUrls: ['./intervals-stat-details.component.scss']
})
export class IntervalsStatDetailsComponent implements OnInit {

    @Input('orders') orders;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Информация о заказах';

    constructor(
        protected app: AppComponent
    ) {
        if (window.pageYOffset > 0) {
            this.windowTop = window.pageYOffset + 50;
        }

        if (window.innerWidth > 450) {
            this.width = 450;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = window.innerHeight * 0.9;
    }

    ngOnInit() {
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
