import {Component, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {CourierExtraExpences, User, UserHub} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {LogisticService} from '../../service/logistic.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {HubsService} from '../../service/hubs.service';
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';


@Component({
    selector: 'app-courier-extra-expenses',
    templateUrl: './courier-extra-expenses.component.html',
    styleUrls: ['./courier-extra-expenses.component.scss'],
})
export class CourierExtraExpensesComponent implements OnInit {

    public gridData: GridDataResult;
    public pageSize = 20;
    public skip = 0;
    public selectedUser: User = null;
    public query: any;
    public expenseTypes = CourierExtraExpences.TYPES_DESCRIPTION;
    public expenseStatuses = CourierExtraExpences.STATUSES_DESCRIPTION;
    public isLoading = false;
    public formList: FormGroup;
    public formEdit: FormGroup;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    private pageTitle = 'Курьерские доплаты';
    public currentHubs = [];


    constructor(private title: Title,
                private datePipe: DatePipe,
                private api: LogisticService,
                public helpers: HelpersService,
                private hubsService: HubsService,
                protected dialog: DialogService,
                protected currentUserService: CurrentUserService,
                protected eventerService: EventerService,
    ) {
        this.title.setTitle(this.pageTitle);

        const today = new Date();
        this.formList = new FormGroup({
            inputType: new FormControl('-1'),
            inputStatus: new FormControl('-1'),

            dateStart: new FormControl(today),
            dateEnd: new FormControl(today),
        });

    }

    ngOnInit() {
        this.onChangeHub();
    }

    private onChangeHub() {
        this.currentHubs = [this.currentUserService.getCurrentHub().id, ];
        this.appUserSelectEventsSubjectEmit(this.currentHubs);
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.currentHubs = [hub.id, ];
            this.appUserSelectEventsSubjectEmit(this.currentHubs);
        });
    }
    appUserSelectEventsSubjectEmit(hubs) {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: hubs});
    }

    public decline(id) {
        const sub = this.api.declineExpense(id).subscribe(data => {
            const row = this.findRow({id: id});
            if (row) {

                row.status = data.status;
            }

            sub.unsubscribe();
        });
    }

    public manage(id, price) {
        const sub = this.dialog.openEditDialog('Изменить стоимость', price, '').subscribe(result => {
            if (result) {
                this.api.changeExpensePrice(id, result).subscribe(data => {
                    const row = this.findRow({id: id});
                    if (row) {
                        row.price = data.price;
                    }
                });
            }

            sub.unsubscribe();

        });
    }

    public viewPhoto(id, url, comment_text) {
        if (url != null) {
            this.dialog.openImageDialog(null, url, comment_text, 300);
        } else {
            this.api.getExpensePhoto(id).subscribe(data => {
                this.dialog.openImageDialog(data.photo, null, comment_text, 300);
            });
        }

    }

    public approve(id) {
        const sub = this.api.approveExpense(id).subscribe(data => {
            const row = this.findRow({id: id});
            if (row) {
                row.status = data.status;
            }
            sub.unsubscribe();
        });
    }

    public loadExpenses() {
        const query = {
            'pageSize': this.pageSize,
            'skip': this.skip,
            'start_date': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'end_date': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'status': this.formList.value.inputStatus,
            'type': this.formList.value.inputType,
            'courier_id': (this.selectedUser ? this.selectedUser.id : 0),
            'hub_ids': this.currentHubs,
        };
        this.isLoading = true;
        this.api.getExtraExpenses(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data.data, total: data.total};
        });
    }

    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {
            this.selectedUser = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadExpenses();
    }

    /**
     * Изменение типа парковки
     * @param id
     * @param currentType
     */
    changeType(id, currentType) {
        const sub = this.dialog.chooseSomething(
            'Выберите тип доплаты',
            currentType,
            this.expenseTypes,
            true,
            '',
            'Не выбран тип доплаты!',
        ).subscribe(result => {
            if (result) {
                this.api.changeExpenseType(id, result).subscribe(data => {
                    console.log(data);
                    const row = this.findRow({id: id});
                    if (row) {
                        row.type = data.type;
                    }
                });
            }
            sub.unsubscribe();
        });
    }

    private findRow(row) {
        return this.gridData.data.find((item: any) => {
            return item.id === row.id;
        });
    }
}

