import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {LaraService} from '../../../service/lara.service';
import {NotifierService} from '../../../service/notifier.service';
import {HelpersService} from '../../../service/helpers.service';
import {HubsService} from '../../../service/hubs.service';
import {Call, CargoTariff, Hub, User} from '../../../service/models';
import 'rxjs/add/operator/first';
import {CourierCardComponent} from '../courier-card.component';
import {DateHelper} from '../../../service/helpers/date.helper';

enum UITabs {
    TAB_MAIN = 0,
    TAB_PASSPORT = 1,
    TAB_VEHICLE = 2,
    TAB_ETC = 3,
}

const loginAvailableValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const login = control.value;
    const available = CourierDialogComponent.LoginIsCheckedAndAvailable;

    return (
        !login.length ||
        (control.errors && control.errors.minLength) ||
        true === available
    ) ? null : {loginNotAvailable: true};
};

@Component({
    selector: 'app-courier-dialog',
    templateUrl: './courier-dialog.component.html',
    styleUrls: ['./courier-dialog.component.scss'],
})
export class CourierDialogComponent implements OnInit {
    public static LoginIsCheckedAndAvailable = null;
    public readonly LOGIN_MIN_LENGTH = 3;

    public courierId?: number | string = null;
    public parent: CourierCardComponent;

    public width;
    public uiTab = UITabs.TAB_MAIN;
    public courier?: User = null;
    public form: FormGroup;
    public formNew: FormGroup;
    public loading = false;
    public loadingSms = false;
    private requestLoginSuggestionsTimeout = null;
    public loadingLoginSuggestions = false;
    public loginSuggestions = [];
    private checkLoginAvailableTimeout = null;
    public loadingCheckLogin = false;
    public opened = false;
    public default_hub?: Hub = null;
    public default_hub_id_original?: number = null;
    public selectedHubs: Hub[];
    public hubsList: Hub[];
    public cargoTariffsList: CargoTariff[] = [];
    public cargoTariffsListLoading = false;
    public cargoTariffsListFiltered: CargoTariff[] = [];

    public hasRightsToEdit = false;
    public hasRightsToEditCargoTariff = false;
    public hasRightsToEditSelfEmployed = false;
    public hasRightToEndQueue = false;
    public isReadonly = true;
    private readonly ageValidatorFn: ValidatorFn = DateHelper.getAgeValidatorFn({min: 18, max: 65});

    public readonly uiTabs = UITabs;

    constructor(
        protected api: LaraService,
        private notifier: NotifierService,
        protected hubsService: HubsService,
        public helper: HelpersService,
    ) {
        this.width = window.innerWidth * 0.5;
        this.hasRightsToEdit = helper.checkPermissions('staff:courier-edit');
        this.hasRightsToEditCargoTariff = helper.checkPermissions('log:cargo-courier-workday');
        this.hasRightsToEditSelfEmployed = helper.checkPermissions('buh:change-is-self-employed');
        this.hasRightToEndQueue = this.helper.checkPermissions('storage:to-end-queue');

        this.formNew = new FormGroup({
            full_name: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            phone: new FormControl('', [
                Validators.required,
                Validators.minLength(10),
            ]),
            mobile_phone1: new FormControl('', [
                Validators.required,
                Validators.minLength(10),
            ]),
            mobile_phone2: new FormControl(''),
            login: new FormControl('', [
                Validators.required,
                Validators.minLength(this.LOGIN_MIN_LENGTH),
                loginAvailableValidator,
            ]),
        });

        this.form = new FormGroup({
            bypass_empty_values: new FormControl(0),
            active: new FormControl(0),
            activity_toggled: new FormControl(false),
            name: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            full_name: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            is_self_employed: new FormControl(0),
            shift_without_call: new FormControl(0),
            type: new FormControl(0, Validators.required),
            // cargo_tariff_id: new FormControl(''),
            phone: new FormControl('', [
                Validators.required,
                Validators.minLength(10),
            ]),
            mobile_phone1: new FormControl('', [
                Validators.required,
                Validators.minLength(10),
            ]),
            mobile_phone2: new FormControl(''),
            driver_license: new FormControl('', Validators.required),
            pass_sn: new FormControl('', Validators.required),
            pass_kem: new FormControl('', Validators.required),
            pass_kod: new FormControl('', Validators.required),
            pass_date: new FormControl('', Validators.required),
            pass_address: new FormControl('', Validators.required),
            fact_address: new FormControl('', Validators.required),
            car_mark: new FormControl('', Validators.required),
            car_model: new FormControl('', Validators.required),
            car_number: new FormControl('', Validators.required),
            fuel_grade_id: new FormControl(null, Validators.required),
            fuel_consumption: new FormControl(null, Validators.required),
            car_volume_capacity: new FormControl(0, [
                Validators.min(1),
                Validators.max(128),
            ]),
            car_weight_capacity: new FormControl(0, [
                Validators.min(1),
                Validators.max(65500),
            ]),
            car_is_downtown_permitted: new FormControl(0, Validators.required),
            // is_last_on_queue: new FormControl(0),
            is_close_with_locations: new FormControl(0),
            date_of_birth: new FormControl('', [
                Validators.required,
                this.ageValidatorFn,
            ]),
        });

        this.patchFormAvailability();
        this.patchFormValidators();

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });
    }

    ngOnInit() {
        this.loadCourierInfo();
        this.getCargoTariffsList();

        this.opened = true;
        this.parent.loading = false;
        // this.selectedHubs = [this.default_hub];
        // this.default_hub_id_original = this.default_hub.id;
    }

    get modalTitle() {
        if (null === this.courierId) {
            return 'Новый курьер';
        }

        if (null === this.courier) {
            return `Загрузка курьера №${this.courierId}`;
        }

        return `${this.courier.courier_option.full_name} №${this.courier.id} [${this.courier.courier_option.old_courier_id}]`;
    }

    onClose() {
        this.opened = false;
        this.parent.onClose();
    }

    private loadCourierInfo() {
        if (!this.courierId) {
            return;
        }

        this.loading = true;
        this.api.getCourierInfo(this.courierId).subscribe(data => {
            this.courier = data;
            this.patchForm();
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    private patchForm() {
        console.log(this.courier);
        this.form.patchValue({
            bypass_empty_values: false,
            active: this.courier.active ? this.courier.active : 0,
            activity_toggled: false,
            name: this.courier.name,
            full_name: this.courier.courier_option.full_name,
            is_self_employed: this.courier.courier_option.is_self_employed ? this.courier.courier_option.is_self_employed : 0,
            shift_without_call: this.courier.courier_option.shift_without_call ? this.courier.courier_option.shift_without_call : 0,
            type: this.courier.courier_option.type ? this.courier.courier_option.type : '',
            // cargo_tariff_id: (
            //     (this.courier.courier_option.type && 3 === this.courier.courier_option.type)
            //         ? this.courier.courier_option.cargo_tariff_id : 0
            // ),
            phone: this.courier.courier_option.phone,
            mobile_phone1: this.courier.courier_option.mobile_phone1,
            mobile_phone2: this.courier.courier_option.mobile_phone2 || '',
            driver_license: this.courier.courier_option.driver_license,
            pass_sn: this.courier.courier_option.pass_sn,
            pass_kem: this.courier.courier_option.pass_kem,
            pass_kod: this.courier.courier_option.pass_kod,
            pass_date: this.courier.courier_option.pass_date,
            pass_address: this.courier.courier_option.pass_address,
            fact_address: this.courier.courier_option.fact_address,
            car_mark: this.courier.courier_option.car_mark,
            car_model: this.courier.courier_option.car_model,
            car_number: this.courier.courier_option.car_number,
            fuel_grade_id: this.courier.courier_option.fuel_grade_id,
            fuel_consumption: this.courier.courier_option.fuel_consumption,
            car_volume_capacity: this.courier.courier_option.car_volume_capacity,
            car_weight_capacity: this.courier.courier_option.car_weight_capacity,
            car_is_downtown_permitted: this.courier.courier_option.car_is_downtown_permitted,
            // is_last_on_queue: this.courier.courier_option.is_last_on_queue,
            is_close_with_locations: this.courier.courier_option.is_close_with_locations,
            date_of_birth: this.courier.date_of_birth,
        });

        this.default_hub = this.hubsList.find((hub: Hub) => {
            return hub.id === this.courier.default_hub_id;
        });
    }

    onChangeType(event) {
        console.warn(event.target.value);
        this.patchFormValidators(true);
    }

    onChangeBypassEmptyValues(event) {
        console.warn(event.target.value);
        this.patchFormValidators(true);
    }

    private patchFormValidators(touch = false) {
        const bypassInactive = !(+this.form.value.active);
        const bypass = bypassInactive || this.form.value.bypass_empty_values;
        const bypassCar = (+this.form.value.type === 2);

        [
            'phone',
            'mobile_phone1',
            'mobile_phone2',
            'driver_license',
            'pass_sn',
            'pass_kem',
            'pass_kod',
            'pass_date',
            'pass_address',
            'fact_address',
            'date_of_birth',
            'car_mark',
            'car_model',
            'car_number',
            'fuel_grade_id',
            'fuel_consumption',
            'car_is_downtown_permitted',
        ].map(formControlName => {
            const control = this.form.get(formControlName);
            const validators = [];

            let skipRequiredValidator = bypass;
            if (!bypass) {
                switch (formControlName) {
                    case 'driver_license':
                    case 'car_mark':
                    case 'car_model':
                    case 'car_number':
                    case 'fuel_grade_id':
                    case 'fuel_consumption':
                    case 'car_is_downtown_permitted':
                        skipRequiredValidator = bypassCar;
                        break;

                    case 'mobile_phone2':
                        skipRequiredValidator = true;
                        break;

                    case 'is_self_employed':
                        skipRequiredValidator = !this.hasRightsToEditSelfEmployed;
                        break;
                }

                switch (formControlName) {
                    case 'phone':
                    case 'mobile_phone1':
                    case 'mobile_phone2':
                        validators.push(Validators.minLength(10));

                        break;

                    case 'date_of_birth':
                        validators.push(this.ageValidatorFn);
                        break;
                }
            }

            if (!skipRequiredValidator) {
                validators.push(Validators.required);
            }

            if (validators.length) {
                control.setValidators(validators);
                if (touch) {
                    control.markAsTouched();
                }
            } else {
                control.clearValidators();
                if (touch) {
                    control.markAsUntouched();
                }
            }

            control.updateValueAndValidity();
        });
    }

    private patchFormAvailability() {
        [
            'active',
            'shift_without_call',
            'type',
            'car_is_downtown_permitted',
            'is_close_with_locations',
            'fuel_grade_id',
        ].map(formControlName => {
            const control = this.form.get(formControlName);
            if (this.isReadonly) {
                control.disable({onlySelf: false});
            } else {
                control.enable({onlySelf: false});
            }

            control.updateValueAndValidity();
        });

        const isSelfEmployedControl = this.form.get('is_self_employed');
        if (this.hasRightsToEditSelfEmployed && !this.isReadonly) {
            isSelfEmployedControl.enable({onlySelf: false});
        } else {
            isSelfEmployedControl.disable({onlySelf: false});
        }

        // const isLastOnQueueControl = this.form.get('is_last_on_queue');
        // if (this.hasRightToEndQueue && !this.isReadonly) {
        //     isLastOnQueueControl.enable({onlySelf: false});
        // } else {
        //     isLastOnQueueControl.disable({onlySelf: false});
        // }
    }

    /**
     * Требуется ли указывать
     * данные автомобиля
     */
    dataCarIsRequired() {
        if (this.form.value.type === 2) {
            return true;
        }
        return this.form.value.car_mark.length > 0 && this.form.value.car_model.length > 0 && this.form.value.car_number.length > 0;
    }

    setCourierSettings() {
        if (this.form.status !== 'VALID') {
            this.notifier.openNotifier('Заполните все поля', null, {duration: 3000, class: 'danger'});
            this.patchFormValidators(true);
            return;
        }

        this.loading = true;
        const formValue = this.form.value;
        formValue.default_hub_id = this.courier.default_hub_id;
        formValue.courier_id = this.courier.courier_option.id;

        this.api.setCourierInfo(formValue).subscribe(data => {
            this.courier = data;
            this.patchForm();
            this.isReadonly = true;
            this.patchFormAvailability();
            this.patchFormValidators(true);
            this.loading = false;
            this.notifier.openNotifier('Изменения сохранены', null, {duration: 3000});
        });
    }

    openTab(uiTab) {
        this.uiTab = uiTab;
    }

    makeCall(phone) {
        const callInfo = {
            to: {
                id: this.courier.courier_option.id,
                type: Call.CALLED_TYPE_COURIER
            }
        }
        this.api.makeCall(phone, callInfo).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000});
        })
    }

    sendCourierPassword() {
        this.loadingSms = true;
        this.api.sendCourierPassword(this.courier.courier_option.user_id).subscribe(data => {
            this.loadingSms = false;
            alert('Логин: ' + data.login + '\n' + 'Пароль: ' + data.password);
            console.log(data.login + ':' + data.password);
        })
    }

    onChangeHub(event) {
        this.default_hub = event;
        this.courier.default_hub_id = event.id;
    }

    /**
     * Получает список тарифов курьеров-пятитонников
     */
    getCargoTariffsList() {
        this.cargoTariffsListLoading = true;
        this.api.getCargoTariffsList().subscribe(data => {
            this.cargoTariffsList = data.map(item => {
                return new CargoTariff(item);
            });

            this.updateCargoTariffsListFiltered();
            this.cargoTariffsListLoading = false;
        });
    }

    /**
     * Обновляет список тарифов для назначения на курьера
     * @private
     */
    private updateCargoTariffsListFiltered() {
        const currentTariffId = (
            this.courier &&
            this.courier.courier_option &&
            this.courier.courier_option.type &&
            3 == this.courier.courier_option.type
        ) ? this.courier.courier_option.cargo_tariff_id : 0;

        this.cargoTariffsListFiltered = this.cargoTariffsList.filter((cargoTariffItem: CargoTariff) => {
            return (this.default_hub.id === cargoTariffItem.hub_id || currentTariffId === cargoTariffItem.id);
        }).map((cargoTariffItem: CargoTariff) => {
            cargoTariffItem.is_foreign_hub_tariff = (this.default_hub.id !== cargoTariffItem.hub_id);

            return cargoTariffItem;
        });
    }

    /**
     * Метод вызывается при изменении статуса курьера
     * @param value
     */
    onChangeActive(value) {
        this.form.get('activity_toggled').setValue(true);
        const active = !!(+value);
        if (!active) {
            if (confirm('Желаете ли вы очистить следующие поля?\r\n-Контактный номер\r\n-Доп. телефон 1\r\n-Доп. телефон 2') === true) {
                this.form.get('phone').setValue(null);
                this.form.get('mobile_phone1').setValue(null);
                this.form.get('mobile_phone2').setValue(null);
                this.notifier.openNotifier('Поля очищены', null, {duration: 3000});
            }
        }

        this.patchFormValidators();
    }

    getCourierTerminalType(dataItem) {
        return (
            dataItem.courier_option &&
            dataItem.courier_option.terminal &&
            dataItem.courier_option.terminal.terminal_type
        ) ? dataItem.courier_option.terminal.terminal_type : null;
    }

    onInputPhone(event, form, field) {
        const rawValue = event.target.value;
        const cleanValue = this.helper.phoneClean(rawValue);
        const control = form.controls[field];

        if (rawValue !== cleanValue) {
            control.setValue(cleanValue);
        }

        if ('mobile_phone2' === field) {
            if (cleanValue.length) {
                control.setValidators([
                    Validators.minLength(10),
                ]);
            } else {
                control.clearValidators();
            }
        }

        control.updateValueAndValidity();
    }

    onInputFullName(event, form, field) {
        if (this.requestLoginSuggestionsTimeout) {
            clearTimeout(this.requestLoginSuggestionsTimeout);
        }

        const fullName = event.target.value.trim();

        form.controls[field].updateValueAndValidity();
        if (!fullName.length) {
            return;
        }

        this.requestLoginSuggestionsTimeout = setTimeout(() => {
            this.requestLoginSuggestions(fullName);
        }, 500);
    }

    private requestLoginSuggestions(full_name) {
        const requestPayload = {
            full_name,
            is_courier: true,
        }

        this.loadingLoginSuggestions = true;
        this.api.suggestLogins(requestPayload).subscribe(responsePayload => {
            this.loginSuggestions = responsePayload;
            this.loadingLoginSuggestions = false;
        }, () => {
            this.loadingLoginSuggestions = false;
        });
    }

    onClickUseLoginSuggestion(login) {
        CourierDialogComponent.LoginIsCheckedAndAvailable = true;

        this.formNew.controls.login.setValue(login);
        this.formNew.controls.login.markAsDirty();
        this.formNew.controls.login.updateValueAndValidity();
    }

    onInputLogin(event, form, field) {
        if (this.checkLoginAvailableTimeout) {
            clearTimeout(this.checkLoginAvailableTimeout);
        }

        const login = event.target.value.trim();
        if (!login.length) {
            return;
        }

        this.checkLoginAvailableTimeout = setTimeout(() => {
            this.checkLoginAvailable(event.target.value, form, field);
        }, 500);
    }

    private checkLoginAvailable(login, form = null, field = null) {
        this.checkLoginAvailableTimeout = null;
        CourierDialogComponent.LoginIsCheckedAndAvailable = null;

        if (!login.length || login.length < this.LOGIN_MIN_LENGTH) {
            return;
        }

        const requestPayload = {
            login,
            is_courier: true,
        }

        this.loadingCheckLogin = true;
        this.api.checkLoginAvailable(requestPayload).subscribe(responsePayload => {
            CourierDialogComponent.LoginIsCheckedAndAvailable = responsePayload.available;
            if (null !== form && null !== field) {
                form.controls[field].updateValueAndValidity();
            }

            this.loadingCheckLogin = false;
        }, () => {
            this.loadingCheckLogin = false;
        });
    }

    onInputCarCapacity(event, form, field) {
        const value = event.target.value.trim();
        const control = form.controls[field];

        if (value.length) {
            if ('car_volume_capacity' === field) {
                control.setValidators([
                    Validators.min(1),
                    Validators.max(128),
                ]);
            }

            if ('car_weight_capacity' === field) {
                control.setValidators([
                    Validators.min(1),
                    Validators.max(65000),
                ]);
            }
        } else {
            control.clearValidators();
        }

        control.updateValueAndValidity();
    }

    onClickCreateCourier() {
        if (this.formNew.invalid) {
            return;
        }

        const request = this.formNew.value;

        this.loading = true;
        this.api.createCourier(request).subscribe(courierUser => {
            this.courier = courierUser;

            this.patchForm();
            this.patchFormAvailability();

            this.courierId = courierUser.id;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    onClickEditBegin() {
        this.isReadonly = false;
        this.patchFormAvailability();
        this.patchFormValidators();
    }

    onClickEditRollback() {
        this.isReadonly = true;
        this.patchForm();
        this.patchFormAvailability();
        this.patchFormValidators(true);
    }

    onClickEditCommit() {
        this.setCourierSettings();
    }

    get newFullName() {
        return this.formNew.get('full_name');
    }

    get newPhone() {
        return this.formNew.get('phone');
    }

    get newMobilePhone1() {
        return this.formNew.get('mobile_phone1');
    }

    get newMobilePhone2() {
        return this.formNew.get('mobile_phone2');
    }

    get newLogin() {
        return this.formNew.get('login');
    }

    get newLoginIsAvailable() {
        return CourierDialogComponent.LoginIsCheckedAndAvailable;
    }

    get editName() {
        return this.form.get('name');
    }

    get editFullName() {
        return this.form.get('full_name');
    }

    get editPhone() {
        return this.form.get('phone');
    }

    get editMobilePhone1() {
        return this.form.get('mobile_phone1');
    }

    get editMobilePhone2() {
        return this.form.get('mobile_phone2');
    }

    get editDriverLicense() {
        return this.form.get('driver_license');
    }

    get editPassSN() {
        return this.form.get('pass_sn');
    }

    get editPassKem() {
        return this.form.get('pass_kem');
    }

    get editPassKod() {
        return this.form.get('pass_kod');
    }

    get editPassDate() {
        return this.form.get('pass_date');
    }

    get editPassAddress() {
        return this.form.get('pass_address');
    }

    get editFactAddress() {
        return this.form.get('fact_address');
    }

    get editDateOfBirth() {
        return this.form.get('date_of_birth');
    }

    get editCarMark() {
        return this.form.get('car_mark');
    }

    get editCarModel() {
        return this.form.get('car_model');
    }

    get editFuelGradeId() {
        return this.form.get('fuel_grade_id');
    }

    get editFuelConsumption() {
        return this.form.get('fuel_consumption');
    }

    get editCarNumber() {
        return this.form.get('car_number');
    }

    get editCarVolumeCapacity() {
        return this.form.get('car_volume_capacity');
    }

    get editCarWeightCapacity() {
        return this.form.get('car_weight_capacity');
    }

    get editCarIsDowntownPermitted() {
        return this.form.get('car_is_downtown_permitted');
    }
}
