import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {ReturnActService} from '../../service/return-act.service';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';

interface DataFormat {
    name: string,
    value: number
}

@Component({
    selector: 'app-return-act-client-select',
    templateUrl: './return-act-client-select.component.html',
    styleUrls: ['./return-act-client-select.component.scss'],
})
export class ReturnActClientSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Клиент';
    @Input() initValue: number = null;
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: DataFormat[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    selectedHubId: number;

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;
    public loading = false;

    constructor(
        private currentUserService: CurrentUserService,
        private api: ReturnActService,
        protected eventer: EventerService,
    ) {
        this.eventer.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }
        const hub = this.currentUserService.getCurrentHub();

        this.selectedHubId = hub.id;
        this.loadData();
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true,
        };
    }

    loadData() {
        this.loading = true;
        this.api.getClientsForCreateReturnAct(this.selectedHubId).subscribe(data => {
            this.loading = false;
            this.dropdownList = data;
            if (this.initValue) {
                this.selectedItems = this.dropdownList.filter(x => {
                    return x.value === this.initValue
                })
            }
        }, error => {
            this.loading = false;
        })
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }
        this.selectedHubId = hub.id;
        this.selectedItems = [];
        this.loadData();
    }


    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.value) !== -1
        })
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
