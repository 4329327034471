import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FinanceService} from '../../service/finance.service';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-e-kassir-encashment-modal',
    templateUrl: './e-kassir-encashment-modal.component.html',
    styleUrls: ['./e-kassir-encashment-modal.component.scss']
})
export class EKassirEncashmentModalComponent implements OnInit {
    public width;

    public formEdit: FormGroup;

    public encashmentIsProcessing: boolean = false;
    public encashmentInfo;

    constructor(
        protected app: AppComponent,
        protected api: FinanceService) {

        const today = new Date();

        this.formEdit = new FormGroup({
            time_hours: new FormControl(today.getHours()),
            time_minutes: new FormControl(today.getMinutes()),
            amount: new FormControl()
        });
    }

    ngOnInit() {
        this.loadEncashmentInfo();
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onTimeChange(event) {
        const params = this.formEdit.value;
        const time = new Date();
        time.setHours(params.time_hours, params.time_minutes);
        this.loadEncashmentInfo(time);
    }

    onEncashmentProcessClick() {
        this.encashmentIsProcessing = true;

        const params = this.formEdit.value;
        this.api.processEncashment(params)
            .subscribe((data) => {
                this.encashmentIsProcessing = false;

                this.onClose();
            }, error => {
                this.encashmentIsProcessing = false;
            });
    }

    loadEncashmentInfo(till_time: Date = null) {
        this.encashmentIsProcessing = true;
        this.api.getEncashmentInfo({till_time: (till_time ? till_time.toLocaleString() : null)})
            .subscribe((data) => {
                this.encashmentInfo = data;
                this.formEdit.get('amount').setValue(data.currentMoneyAmount);

                this.encashmentIsProcessing = false;
            }, error => {
                this.encashmentIsProcessing = false;
            })
    }
}
