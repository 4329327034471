import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-sameday-select',
    templateUrl: './sameday-select.component.html',
    styleUrls: ['./sameday-select.component.scss']
})
export class SamedaySelectComponent implements OnInit {
    @Input('placeholder') placeholder = 'День в день';
    @Input('value') protected initialValue: number | boolean | null = null;
    @Output() change = new EventEmitter<number | null>();

    private eventsSubscription: Subscription;
    @Input() events: Observable<void>;

    public readonly dropdownList = [
        {
            value: null,
            label: 'Не важно',
        },
        {
            value: 0,
            label: 'Обычные',
        },
        {
            value: 1,
            label: 'День в день',
        },
    ];

    public dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'label',
        itemsShowLimit: 1,
        allowSearchFilter: false
    };

    public selectedItems = [];
    protected effectiveValue: number | null = null;

    constructor() {
    }

    ngOnInit(): void {
        this.effectiveValue = null;
        this.selectValue(null === this.initialValue ? null : (this.initialValue ? 1 : 0));

        if (!this.selectedItems.length) {
            this.selectValue(null);
        }

        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.setNewValueEvent(data));
        }

        // console.warn('ngOnInit', this.selectedItems);
    }

    setNewValueEvent(value) {
        this.selectedItems = [];
        this.selectedItems.push(this.dropdownList[value]);
    }

    protected selectValue(value) {
        this.selectedItems = this.dropdownList.filter(item => {
            return item.value === value;
        });

        this.effectiveValue = this.selectedItems[0].value;

        // console.info('selectValue >>', this.selectedItems);
    }

    onChangeModel(event) {
        // console.warn('onChangeModel', event);
        // console.info('onChangeModel ??', this.selectedItems);

        if (!this.selectedItems.length) {
            this.selectValue(this.effectiveValue);
            return;
        }

        this.effectiveValue = this.selectedItems[0].value;

        this.change.emit(this.effectiveValue);
    }
}
