import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {User} from "../../service/models";
import {FormControl, FormGroup} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {SkladService} from "../../service/sklad.service";
import {HelpersService} from "../../service/helpers.service";
import {DialogService} from '../../components/dialog/dialog.service';

@Component({
    selector: 'app-transit-list',
    templateUrl: './transit-list.component.html',
    styleUrls: ['./transit-list.component.scss']
})
export class TransitListComponent implements OnInit {
    public gridData: GridDataResult;

    private pageTitle = 'Перемещения';
    public pageSize = 10;
    public skip = 0;
    public selectedTransit: string[] = [];
    public selectedUser: User = null;
    @ViewChild('input_status') inputStatus: ElementRef;
    @ViewChild('transit_date') transitDate: ElementRef;
    public query: any;
    protected newCourier: User = null;

    public formList: FormGroup;
    public formEdit: FormGroup;

    constructor(private title: Title,
                private api: SkladService,
                public helpers: HelpersService,
                protected dialog: DialogService
    ) {
        this.title.setTitle(this.pageTitle);

        this.formList = new FormGroup({
            inputStatus: new FormControl(''),
        });

        this.formEdit = new FormGroup(
            {
                new_status: new FormControl('')
            }
        );
    }

    getPrint(data) {
        return ['/transit-print', {'trId': data}]
    }


    ngOnInit() {
        this.inputStatus.nativeElement.value = 0;
        this.loadTransit();
    }


    public loadTransit() {
        this.selectedTransit = [];
        const query = this.formList.value;

        query.status = this.inputStatus.nativeElement.value;
        query.pageSize = this.pageSize;
        query.skip = this.skip;
        console.log(query);

        this.api.getTransitList(query).subscribe(data => {

            this.gridData = {data: data.transits.data, total: data.transits.total};
        });

    }

    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {

            this.selectedUser = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadTransit();
    }


    onSetSelectCourier(user: User) {
        this.newCourier = user;
    }

    onUseSetCourier() {
        if (!this.newCourier) {
            this.dialog.alert('Выберите курьера');
            return;
        }

        const dialog = this.dialog.confirm('Назначить курьера: ' + this.newCourier.name + ' на ' + this.selectedTransit.length + ' перемещений ').subscribe(result => {
            if (result) {
                this.api.setCourierForTransits(this.selectedTransit, this.newCourier.id).subscribe(data => this.loadTransit());
            }
            dialog.unsubscribe();
        });
    }

    onSetStatus() {
        if (!this.formEdit.value.new_status) {
            this.dialog.alert('Выберите статус');
            return;
        }

        const dialog = this.dialog.confirm('Задать статус ' + this.helpers.getNameTransitStatus(this.formEdit.value.new_status) + ' ' + this.selectedTransit.length + ' перемещениям?').subscribe(result => {
            if (result) {
                this.api.setTransitStatus(this.selectedTransit, this.formEdit.value.new_status).subscribe(data => {
                    this.loadTransit();
                    if (data.message) {
                        this.dialog.alert(data.message);
                    }
                });
            }
            dialog.unsubscribe();
        })
    }

}
