import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {User, UserHub} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';

@Component({
    selector: 'app-courier-status',
    templateUrl: './courier-status.component.html',
    styleUrls: ['./courier-status.component.scss'],
})

export class CourierStatusComponent implements OnInit {
    private readonly pageTitle = 'Приход курьеров';

    public todayCouriers: any[] = [];
    public message = '';
    public loading = false;

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];

    public canSetCourierArrived = false;

    constructor(
        private title: Title,
        private api: SkladService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        public helpers: HelpersService,
    ) {
        this.title.setTitle(this.pageTitle);

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.canSetCourierArrived = this.helpers.checkPermissions('log:set-courier-arrival');
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            const hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub];
            this.selectedHubId = hub.id;

            this.loadCouriers(this.selectedHubId);
        });
    }

    public loadCouriers(hubId: number) {
        this.loading = true;
        this.api.getTodayCouriersStatus(hubId).subscribe(data => {
            this.loading = false;
            console.log(data);
            if ((data != null) && (data.length)) {
                this.todayCouriers = data;
                this.message = '';
            } else {
                this.message = 'Не назначен ни один курьер на сегодня';
            }
        }, () => {
            this.loading = false;
        });
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;
        this.loadCouriers(this.selectedHubId);
    }

    onCourierQueueStatusChanged(event) {
        const index = this.todayCouriers.findIndex(courier => courier.old_courier_id === event.old_courier_id);
        if (-1 !== index) {
            this.todayCouriers[index].queue_status = event.queue_status;
            this.todayCouriers[index].time_come = event.time_come;
        }
    }
}
