import {Component, Input, OnInit} from '@angular/core';
import {TransitModalComponent} from './transit-modal/transit-modal.component';
import {AppComponent} from '../../app.component';
import {SkladService} from '../../service/sklad.service';


@Component({
    selector: 'app-transit-card',
    templateUrl: './transit-card.component.html',
    styleUrls: ['./transit-card.component.scss']
})
export class TransitCardComponent implements OnInit {
    public loading = false;
    public json: any[] = [];
    @Input('transit_id') public transit_id: string;

    constructor(private api: SkladService,
                protected app: AppComponent) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getTransitInfo(this.transit_id).subscribe((data) => {
            console.log(data);
            this.app.createDialog(TransitModalComponent, {
                transit: data
            });
            this.loading = false;
        });
    }
}
