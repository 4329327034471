import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-claim-letter',
    templateUrl: './claim-letter.component.html',
    styleUrls: ['./claim-letter.component.scss']
})
export class ClaimLetterComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
