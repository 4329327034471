import {Component, ComponentFactoryResolver, EventEmitter, OnInit, Output} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';
import {AppComponent} from '../../app.component';
import {HelpersService} from '../../service/helpers.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ClientService} from '../../service/client.service';
import {ParentCompany} from '../../service/models';


@Component({
    selector: 'app-company-commission-chairs-card',
    templateUrl: 'company-commission-chairs-card.html',
    styleUrls: ['./company-commission-chairs-card.component.css'],
})
export class CompanyCommissionChairsCardComponent implements OnInit {
    public loading = false;
    @Output() onDestroy = new EventEmitter<boolean>();

    constructor(
        private api: ClientService,
        private notifier: NotifierService,
        private app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.showTerminalCard();
        this.loading = false;
    }

    private showTerminalCard() {
        const dialog = this.app.createDialog(CompanyCommissionChairsCardDialogComponent, {});
        dialog.onDestroy(() => {
            this.onDestroy.emit(true);
        })
    }
}


@Component({
    selector: 'app-company-commission-chairs-card-dialog',
    styleUrls: ['./company-commission-chairs-card.component.css'],
    templateUrl: 'company-commission-chairs-dialog.html',
})
export class CompanyCommissionChairsCardDialogComponent implements OnInit {
    public formFilter: FormGroup;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public dataLogs = [];
    public isSaving = false;

    constructor(
        private api: ClientService,
        private notifier: NotifierService,
        protected app: AppComponent,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.setValues();
    }

    private setValues() {
        this.formFilter = new FormGroup(
            {
                act_diff_member_first: new FormControl(null),
                act_diff_member_first_value: new FormControl(null),
                act_diff_member_second: new FormControl(null),
                act_diff_member_second_value: new FormControl(null),
                act_diff_member_third: new FormControl(null),
                act_diff_member_third_value: new FormControl(null),
                act_diff_member_fourth: new FormControl(null),
                act_diff_member_fourth_value: new FormControl(null),
                company_id: new FormControl(null, Validators.required),
            },
        );
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onChangeSelectedCompany(company: ParentCompany) {
        this.formFilter.controls.company_id.setValue(company ? company.id : null);
        this.setCompanyCommissionChairs(company);
    }

    updateCompanyCommissionChairs() {
        this.isSaving = true;
        this.notifier.openNotifier('Обновление членов комиссии...');
        this.api.setCompanyCommissionChairs(this.formFilter.value).subscribe((result) => {
            this.isSaving = false;
            this.notifier.openNotifier('Члены комиссии изменены', null, {class: 'success', duration: 1500})
            console.warn(result)
        }, error => {
            this.isSaving = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }

    /**
     * Установка членов комиссии
     * выбранной компании
     * @private
     */
    private setCompanyCommissionChairs(company) {
        this.formFilter.controls.act_diff_member_first.setValue(company ? company.act_diff_member_first : null);
        this.formFilter.controls.act_diff_member_first_value.setValue(company ? company.act_diff_member_first_value : null);

        this.formFilter.controls.act_diff_member_second.setValue(company ? company.act_diff_member_second : null);
        this.formFilter.controls.act_diff_member_second_value.setValue(company ? company.act_diff_member_second_value : null);

        this.formFilter.controls.act_diff_member_third.setValue(company ? company.act_diff_member_third : null);
        this.formFilter.controls.act_diff_member_third_value.setValue(company ? company.act_diff_member_third_value : null);

        this.formFilter.controls.act_diff_member_fourth.setValue(company ? company.act_diff_member_fourth : null);
        this.formFilter.controls.act_diff_member_fourth_value.setValue(company ? company.act_diff_member_fourth_value : null);
    }
}
