import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Order, Transit} from '../../../service/models';
import {HelpersService} from '../../../service/helpers.service';
import {SkladService} from '../../../service/sklad.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {HubsService} from '../../../service/hubs.service';

@Component({
    selector: 'app-transit-receive',
    templateUrl: './transit-receive.component.html',
    styleUrls: ['./transit-receive.component.scss']
})
export class TransitReceiveComponent implements OnInit {
    @ViewChild('input_barcode') inputBarcode: ElementRef;
    @ViewChild('input_transit') inputTransit: ElementRef;

    public loading: boolean = false;
    public transit: any = null;
    public letPrint = true;
    public printStat = false;
    public uid;
    public bar;
    public message = '';
    public order = null;
    public alert = '';
    public transitModel = Transit;

    public notReceivedCnt = 0;

    constructor(public helpers: HelpersService,
                private api: SkladService,
                protected dialog: DialogService,
                private hubsService: HubsService) {
    }

    ngOnInit() {
        this.setFocusOnTransitInput()
    }

    onScanTransit(e) {
        if (!e.target.value) {
            this.message = 'Не введен номер перемещения';
            this.setFocusOnTransitInput()
            return;
        }

        const search = e.target.value.trim();
        this.loading = true;
        this.api.getTransitForReceive(search).subscribe((data: any) => {
            if (!data) {
                this.message = 'Перемещение не найдено';
                this.setFocusOnTransitInput()
                return;
            } else {
                this.transit = data;

                this.message = '';
            }
            this.countNotReceived();
            this.loading = false;
            if (this.notReceivedCnt === 0) {
                this.setFocusOnTransitInput()
            } else {
                this.setFocusOnBarcodeInput()
            }
        }, error => {
            this.loading = false;
        });
    }

    onScan(e) {
        if (!e.target.value) {
            this.message = 'Не введен штрихкод ';
            return;
        }

        const search = e.target.value.trim();
        this.loading = true;
        this.order = null;
        this.api.receivePlaceFromTransit({'transit_id': this.transit.id, 'barcode': search}).subscribe((data: any) => {
            this.message = data.message;

            e.target.value = '';
            this.alert = '';

            if (data.place) {
                this.order = data.place.order;

                // если тип перемещения НЕ возврат, а заказ отменён
                // показываем алерт
                if (this.transit.type !== Transit.TYPE_RETURN && this.order && this.order.status === Order.STATUS_CANCEL) {
                    alert('Заказ ' + this.order.uid + ' отменён');
                }

                if (data.place.order && data.place.order.is_need_new_label) {
                    this.alert = 'Надо напечатать новую этикетку!';
                    if (this.letPrint) {


                        console.log('Пытаемся печатать этикетку');
                        if (data.place.bar_code.length) {
                            this.bar = data.place.bar_code[0].id;
                        }
                        if (data.place.order.status === 3) { // order on stock -> set label as printed
                            this.api.setLabelPrinted(data.place.order.uid).subscribe((data: any) => {
                                console.log('printed');
                            });

                        }
                        this.uid = data.place.order.uid;
                        this.printStat = true;

                    }

                }

                this.removePlaceFromNotReceived(data.place.id);
                this.countNotReceived();

            }

            this.setFocusOnBarcodeInput();
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    removePlaceFromNotReceived(placeId) {
        this.transit.packs.forEach(function (item) {
            item.places_in_transit = item.places_in_transit.filter(obj => obj.id !== placeId);
        });
    }

    countNotReceived() {
        this.notReceivedCnt = 0;
        for (let i = 0; i < this.transit.packs.length; i++) {
            this.notReceivedCnt += this.transit.packs[i].places_in_transit.length;
        }
    }


    placeInPallet(place, pallet: any[]) {
        const num = pallet.find((item: any) => {
            return item.id === place.id;
        });


    }

    setFocusOnBarcodeInput() {
        setTimeout(() => {
            if (this.inputBarcode) {
                this.inputBarcode.nativeElement.value = '';
                this.inputBarcode.nativeElement.focus();
            }
        }, 0);
    }

    setFocusOnTransitInput() {
        setTimeout(() => {
            this.inputTransit.nativeElement.value = '';
            this.inputTransit.nativeElement.focus();
        }, 0);
    }

    setPrinter(e) {
        this.printStat = !e;
        this.uid = null;
        this.bar = null;
    }

    checkDate(order, diffDay) {
        if (!order || !order.delivery_date) {
            return false;
        }
        return (new Date(order.delivery_date).getDate() - new Date().getDate()) == diffDay;
    }
}
