import {Component, OnInit} from "@angular/core";
import {Observable, Observer} from 'rxjs';

import {TaCalculatorComponent} from './../ta-calculator/ta-calculator.component';
import {CalculatorComponent} from './../calculator/calculator.component';

export interface ExampleTab {
    label: string;
    content: any;
}

@Component({
    selector: "app-calculators-tab",
    templateUrl: "./calculators-tab.component.html",
    styleUrls: ["./calculators-tab.component.scss"]
})

export class CalculatorsTabComponent implements OnInit {

    asyncTabs: Observable<ExampleTab[]>;

    constructor() {
        this.asyncTabs = new Observable((observer: Observer<ExampleTab[]>) => {
            setTimeout(() => {
                observer.next([
                    {label: 'Калькулятор', content: CalculatorComponent},
                    {label: 'Калькулятор заборов', content: TaCalculatorComponent},
                ]);
            }, 1000);
        });
    }

    ngOnInit() {
    }

    selectedIndex = 0;

    resetTabIndex() {
        this.selectedIndex = 0;
    }
}
