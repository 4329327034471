import {Inject, Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {saveAs} from '@progress/kendo-file-saver';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Observable, Subscriber, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DialogService} from '../components/dialog/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {
    private http;

    constructor(
        private HttpHandler: HttpBackend,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        protected dialog: DialogService
    ) {
        this.http = new HttpClient(HttpHandler);
    }

    private getToken() {
        return this.storage.get('access_token');
    }

    public downloadFile(url: string, fileName: string, method: string = 'get', data = null) {
        return new Observable((observer) => {
            (method === 'get' ? this.getFileByGet(url) : this.getFileByPost(url, data))
                .pipe(catchError(this.handleError.bind(this, observer)))
                .subscribe((response: HttpResponse<any>) => {
                    console.log(response.headers.get('Content-Disposition'));

                    const blob = new Blob([response.body], {type: response.headers.get('content-type')});

                    // const contentDisposition = response.headers.get('content-disposition');
                    // filename = filename | contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    // console.log(filename);

                    const file = new File([blob], fileName, {type: response.headers.get('content-type')});
                    saveAs(file, fileName);

                    observer.next(response);
                    observer.complete();
                });
        });
    }

    /**
     * Получить файл, используя GET
     * @param url
     */
    private getFileByGet(url: string) {
        return this.http.get(environment.lara_api_url + url, {
            responseType: 'blob',
            observe: 'response',
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer ' + this.getToken()
            })
        })
    }

    /**
     * Получить файл, используя POST с параметрами
     * @param url
     * @param data
     */
    private getFileByPost(url: string, data) {
        return this.http.post(environment.lara_api_url + url, data, {
            responseType: 'blob',
            observe: 'response',
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': 'Bearer ' + this.getToken()
            })
        })
    }

    protected handleError(observer: Subscriber<any>, e) {

        this.parseBlobErrorAsJson(e)
            .then((response) => {
                observer.error(response.message);
                this.dialog.alert(response.message);
            })
            .catch((error) => {
                console.log(error);
            });

        // return error to observer
        observer.error(e);

        return throwError(e);
    }

    /** Парсим Blob с ошибками как JSON */
    protected parseBlobErrorAsJson(err) {
        return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                try {
                    const errmsg = JSON.parse((<any>e.target).result);

                    resolve(errmsg.response);
                } catch (e) {
                    reject(err);
                }
            };
            reader.onerror = (e) => {
                reject(err);
            };
            reader.readAsText(err.error);
        });
    }
}
