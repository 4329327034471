import {Location} from '@angular/common';
import {Component} from '@angular/core';

@Component({
    selector: 'back-button',
    template: '<button class="btn btn-success" (click)="goBack()">< Назад</button>',
    styles: [`.btn {
        padding: 4px 16px;
        height: 32px;
        border-radius: 16px;
        background-color: var(--dark-purple);
        border: 0;
        display: inline-block;
        color: #fff;
        font-family: 'MuseoSansCyrl';
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }`]
})

export class BackButtonComponent {
    constructor(private location: Location) {
    }

    goBack() {
        this.location.back();
    }
}
