import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})

export class CoordsHelper {
    /**
     * Сравнивает координаты двух точек с точностью до Н метров
     *
     * @param p1
     * @param p2
     */
    public static IsCoordsEquals(p1: any[], p2: any[]): boolean {
        const fractionDigits = 3;
        let p1lat = p1[0] || null;
        let p1lon = p1[1] || null;
        let p2lat = p2[0] || null;
        let p2lon = p2[1] || null;

        // console.warn('IsCoordsEquals?', [p1lat, p2lat, p1lon, p2lon]);
        // console.info('IsCoordsEquals lat', [(1 * p1lat).toFixed(5), (1 * p2lat).toFixed(5)]);
        // console.info('IsCoordsEquals lon', [(1 * p1lon).toFixed(5), (1 * p2lon).toFixed(5)]);

        const eq = (null !== p1lat && null !== p1lon && null !== p2lat && null !== p2lon && (
            // точность координаты от знаков после запятой: 4 = 11м, 5 = 1.1м, 6 = 0.11м
            (1 * p1lat).toFixed(fractionDigits) == (1 * p2lat).toFixed(fractionDigits)
            && (1 * p1lon).toFixed(fractionDigits) == (1 * p2lon).toFixed(fractionDigits)
        ));

        // console.warn('<< IsCoordsEquals', eq);

        return eq
    }
}
