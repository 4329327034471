import {Component, Input, OnInit} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-text-copy',
    templateUrl: './text-copy.component.html',
    styleUrls: ['./text-copy.component.scss'],
})
export class TextCopyComponent implements OnInit {
    @Input() tooltip = 'Скопировать';
    @Input() textForCopy = null;
    @Input() messageAfterCopy = 'Текст успешно скопирован в буфер обмена';

    constructor(
        private notifier: NotifierService,
    ) {

    }

    ngOnInit(): void {
    }

    copyToClipboard() {
        if (!this.textForCopy) {
            return this.notifier.openNotifier(
                'Текст для копирования в буфер обмена отсутствует',
                null,
                {
                    class: 'danger',
                    duration: 5000,
                },
            )
        }

        const event = (event: ClipboardEvent) => {
            event.clipboardData.setData('text/plain', this.textForCopy);
            event.preventDefault();
        }
        document.addEventListener('copy', event);
        document.execCommand('copy');
        document.removeEventListener('copy', event);
        this.notifier.openNotifier(this.messageAfterCopy);
    }
}
