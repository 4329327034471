import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Courier} from "../../../service/models";
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import * as fromCourier from "app/store/reducers/customer.reducer";
import {SkladService} from "app/service/sklad.service";

@Component({
    selector: "app-delivery-couriers",
    templateUrl: "./delivery-couriers.component.html",
    styleUrls: ["./delivery-couriers.component.scss"]
})
export class DeliveryCouriersComponent implements OnInit {
    public courier: Courier = new Courier();
    public orders = [];
    public barcodes = [];
    public order_id = [];
    public size: number;
    nameCourier: string;
    isMarked = false;
    public courier_id;
    isBarCodes = false;
    currentDate;
    hidebutton = true;
    courier_mlist: string;
    barcode;

    public formcheckCourier: FormGroup;
    public formorderCourier: FormGroup;
    public error = false;

    constructor(
        private store: Store<fromCourier.AppState>,
        private api: SkladService,
        private datePipe: DatePipe
    ) {
        this.formcheckCourier = new FormGroup({
            oldCourierId: new FormControl("", Validators.required)
        });
        this.formorderCourier = new FormGroup({
            barCode: new FormControl("", Validators.required)
        });
        this.error = false;
    }

    ngOnInit() {
        this.currentDate = this.datePipe.transform(new Date(), "dd.MM.yyyy");
        this.isMarked = false;
        this.barcode = 0;
    }

    public check(uid) {
        return this.order_id.includes(uid.toString().toLowerCase());
    }

    checkCourier() {
        this.api.checkCourierStatus(this.formcheckCourier.value).subscribe(courier => {
            if (!courier.allowAssembly) {
                if (confirm(`Курьер ${courier.user.name} собран. Пересобрать?`)) {
                }
            }

            if (courier.allowAssembly) {
                this.courier_id = courier.user.id;
                this.nameCourier = courier.user.name;
                this.api
                    .loadCourierQueue({userId: courier.user.id})
                    .subscribe(orders => {
                        this.orders = orders.orders;

                        for (let k = 0; k < orders.orders.length; k++) {
                            if (orders.orders[k].bar_codes.length >= 2) {
                                for (let j = 0; j < orders.orders[k].bar_codes.length; j++) {
                                    this.barcodes.push(orders.orders[k].bar_codes[j].bar_code);
                                }
                            }
                        }
                        this.size = orders.orders.length;
                    });
            }
        });
    }

    checkBarcode() {
        this.api.checkBarcode({
            userId: this.courier_id,
            barCode: this.formorderCourier.value.barCode
        }).subscribe(barcode => {
            if (barcode.barcode) {
                this.barcode = barcode.barcode.bar_code;
                this.order_id.push(barcode.barcode.bar_code.toString().toLowerCase());
                this.isMarked = true;
            }
            if (barcode.order) {
                let inStore = true;
                for (let bar of barcode.order.bar_codes) {
                    console.log(this.check(bar.bar_code), bar.bar_code);
                    if (!this.check(bar.bar_code)) {
                        inStore = false;
                    }
                }
                if (inStore) {
                    this.order_id.push(barcode.order.uid.toString().toLowerCase());
                }
            }

            console.log(this.order_id);
        });
    }

    finishCourierShipping() {
        this.api.finishCourierShipping({userId: this.courier_id, order_id: this.order_id}).subscribe(finish => {
            // console.log(finish);
        });

        this.isBarCodes = true;
        this.hidebutton = false;
        this.api.returnCourierDocs({userId: this.courier_id}).subscribe(docs => {
            this.courier_mlist = docs.courier_mlist;
            console.log(this.courier_mlist);
        });
    }

    returnCourierDocs() {
        window.open(this.courier_mlist, "_blank");
    }
}
