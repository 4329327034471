import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Hub, Order} from '../../service/models';
import {DialogService} from '../../components/dialog/dialog.service';
import {environment} from '../../../environments/environment';
import {HubsService} from '../../service/hubs.service';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-reception-orders',
    templateUrl: './reception-orders.component.html',
    styleUrls: ['./reception-orders.component.scss'],
})
export class ReceptionOrdersComponent implements OnInit, OnDestroy {
    public display: any;
    public date: Date = new Date();
    public resultScan: string[] = [];
    public tab = 1;
    public resultOrder: Order;
    public loading = false;
    public aLoading = false;
    public loadingIsBulky = false;
    public isSubscribed = null;
    public letPrint = false;
    public printStat = false;
    public lastPrintValue = false;
    public uid;
    public bar;
    public barcodeId;
    public orderId;

    public isForeignHubOrder = false;
    public isSuspicious = false; //заказ с подозрительным адресом
    public isRegional = false;
    public isNeedDimensions = false;
    private hubsList: Hub[];

    public formScan: FormGroup;
    @ViewChild('input_barcode') barcode: ElementRef;
    @ViewChild('input_weight') weight: ElementRef;
    @ViewChild('input_dimension_side1') dimension_side1: ElementRef;
    @ViewChild('input_dimension_side2') dimension_side2: ElementRef;
    @ViewChild('input_dimension_side3') dimension_side3: ElementRef;

    constructor(
        private api: SkladService,
        private hubsService: HubsService,
        private dialog: DialogService,
        private notifier: NotifierService,
    ) {
        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required),
            weight: new FormControl(''),
            dimension_side1: new FormControl(''),
            dimension_side2: new FormControl(''),
            dimension_side3: new FormControl(''),
        });


        this.isSubscribed = this.api.errorEmit().subscribe(error => {
            this.loading = false;
            this.formScan.reset();
            if (this.tab === 1) {
                this.barcode.nativeElement.focus();
            } else {
                this.weight.nativeElement.focus();
            }
        })
    }

    ngOnInit() {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            this.loadDisplay();
        });
    }

    ngOnDestroy(): void {
        if (this.isSubscribed) {
            this.isSubscribed.unsubscribe();
        }
    }


    loadDisplay() {
        if (this.tab !== 3) {

            this.aLoading = true;
            this.api.receptionDisplay().subscribe(data => {
                this.display = data;
                this.aLoading = false;
            });
            this.weight.nativeElement.focus();
        }
    }

    onRemoveOrder(uid) {
        const dialog = this.dialog.confirm('Удалить из акта заказ: ' + uid).subscribe(result => {
            if (result) {
                this.api.removeOrderOfAct(uid).subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    onRemoveBarCode(id) {
        const dialog = this.dialog.confirm('Удалить штрих код?').subscribe(result => {
            if (result) {
                this.api.removeNotFoundBarCode(id).subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    onRemoveAllBarCodes() {
        const dialog = this.dialog.confirm('Удалить все нераспознанные штрихкоды?').subscribe(result => {
            if (result) {
                this.api.removeNotFoundBarCode('all').subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }


    sendDimensions() {
        if ((!this.formScan.get('dimension_side1').value) || isNaN(this.formScan.get('dimension_side1').value)) {

            this.dimension_side1.nativeElement.focus()
            this.dialog.alert('Заполните габариты места')
            return;
        }
        if ((!this.formScan.get('dimension_side2').value) || isNaN(this.formScan.get('dimension_side2').value)) {

            this.dimension_side2.nativeElement.focus()
            this.dialog.alert('Заполните габариты места')
            return;
        }
        if ((!this.formScan.get('dimension_side3').value) || isNaN(this.formScan.get('dimension_side3').value)) {

            this.dimension_side3.nativeElement.focus()
            this.dialog.alert('Заполните габариты места')
            return;
        }
        const query = this.formScan.value;
        query.barcode_id = this.barcodeId
        query.order_id = this.orderId
        console.log(query)
        // return;
        this.api.setPlaceDimensions(query).subscribe((data) => {
            this.isNeedDimensions = false;
            this.resultScan = data.messages;
            this.resultOrder = null;
            this.formScan.reset();
        });
        return;
    }

    onScan() {
        if (this.isNeedDimensions) {
            return this.sendDimensions()
        }
        if (!this.formScan.valid) {
            this.formScan.reset();
            return;
        }

        if (this.tab !== 1 && !this.formScan.get('weight').value) {
            this.formScan.reset();
            this.weight.nativeElement.focus();
            this.dialog.alert('Товар необходимо взвесить!');
            return;
        }

        this.resultScan = [];
        this.resultOrder = null;
        switch (this.tab) {
            case 1:
                this.reception();
                this.barcode.nativeElement.focus();
                break;
            case 2:
                this.reception();
                this.weight.nativeElement.focus();
                break;
            case 3:
                this.letPrint = false;
                this.reWeight();
                this.weight.nativeElement.focus();
                break;
        }
    }

    reception() {
        this.loading = true;
        this.api.scanBarCode(this.formScan.value).subscribe((data: any) => {
            this.loading = false;
            this.resultScan = data.messages;

            this.isForeignHubOrder = false;
            this.isSuspicious = false;
            this.isRegional = false;
            this.isNeedDimensions = false;
            this.barcodeId = null;
            this.orderId = null;
            if (data.order) {
                this.resultOrder = data.order;
                if ('undefined' !== typeof data.is_foreign_hub_order && data.is_foreign_hub_order) {
                    this.isForeignHubOrder = true;
                    this.resultOrder.hub_destination = this.hubsList.find((hub: Hub) => {
                        return hub.id === this.resultOrder.hub_destination_id;
                    });
                }
                if (data.order.suspicious_address) {
                    this.isSuspicious = true;
                }
                if (data.order.is_regional) {
                    this.isRegional = true;
                }

                this.lastPrintValue = this.letPrint;
                /* Если заказ 2й смены и принимают его не на ТСД, напечатаем этикетку */
                if (data.order.shift_number === 2 && window.outerWidth > 480) {
                    this.letPrint = true;
                }
                if (this.letPrint || this.isForeignHubOrder) {
                    this.uid = data.order.uid;
                    if (data.bar_code.length) {
                        this.bar = data.bar_code[0].id;
                    }
                    this.printStat = true;
                }
                this.isNeedDimensions = data.need_dimensions


            }
            this.loadDisplay();

            if (!this.isNeedDimensions) {
                this.formScan.reset();
            } else {

                this.barcode.nativeElement.focus()
                this.setDimensionForm(data)
            }
        });
    }

    setDimensionForm(data) {
        if (this.isNeedDimensions && data.order) {
            let dimension_side1 = null;
            let dimension_side2 = null;
            let dimension_side3 = null;
            this.orderId = data.order.id
            console.log('set dimension')

            if (data.bar_code.length) {

                this.barcodeId = data.bar_code[0].id

                if ((data.bar_code[0].dimension_side1 != null) && (data.bar_code[0].dimension_side2 != null) && (data.bar_code[0].dimension_side3 != null)) {
                    console.log('set barcode dims')
                    dimension_side1 = data.bar_code[0].dimension_side1
                    dimension_side2 = data.bar_code[0].dimension_side2
                    dimension_side3 = data.bar_code[0].dimension_side3
                }
            }
            // если в заказе одно место, подставляем габариты заказа
            if (!dimension_side1 && ((data.order.hash_bar_codes_count === 1) || (!this.barcodeId))) {

                dimension_side1 = data.order.option.dimension_side1
                dimension_side2 = data.order.option.dimension_side2
                dimension_side3 = data.order.option.dimension_side3
            }

            this.formScan.patchValue({
                dimension_side1: dimension_side1,
                dimension_side2: dimension_side2,
                dimension_side3: dimension_side3,
                barcode: '',
                weight: '',
            });
        }
    }

    reWeight() {
        this.loading = true;
        this.isNeedDimensions = false;
        this.api.pereves(this.formScan.value).subscribe(data => {
            this.loading = false;
            this.resultOrder = data.order;
            this.resultScan = data.messages;
            this.isNeedDimensions = data.need_dimensions
            console.log(data)

            if (!this.isNeedDimensions) {
                this.formScan.reset();
            } else {

                this.barcode.nativeElement.focus()
                this.setDimensionForm(data)
            }

        });
    }

    onWeightKeyDown(event) {
        const weightInput = this.formScan.get('weight');
        weightInput.setErrors(null);

        if (event.keyCode !== 13) {
            return;
        }

        const weight = this.formScan.get('weight').value.replace(',', '.') * 100;
        if (isNaN(weight)) {
            weightInput.setErrors({
                invalidValue: true,
            });

            this.weight.nativeElement.focus();
            this.dialog.alert('Недопустимое значение веса!');
            return;
        }

        this.formScan.get('weight').setValue(weight.toFixed(2));
        this.barcode.nativeElement.focus();
    }

    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    dateStyle() {
        if (!this.resultOrder) {
            return;
        }

        if (this.isForeignHubOrder) {
            return 'foreign';
        }
        if (this.isSuspicious) {
            return 'suspicious';
        }
        if (this.isRegional) {
            return 'regional';
        }
        const delta = new Date(this.resultOrder.delivery_date).getDate() - new Date().getDate();
        console.log(new Date().getHours());

        if (delta == 0) {
            if (new Date().getHours() >= 6) {
                return 'prevday';
            }
            return 'today';
        }
        if (delta > 0) {
            if (delta === 1 && new Date().getHours() >= 21) {
                return 'today';
            }
            return 'nextday';
        }
        if (delta < 0) {
            return 'prevday'
        }

        if (this.resultOrder.courier.id == environment.not_founded_courier_id) {
            return 'notfounded';
        }
        if (this.resultOrder.courier.id == environment.problem_courier_id) {
            return 'problem';
        }
    }

    letMePrint(e) {
        this.letPrint = e.checked;
    }

    setPrinter(e) {
        // снимем флаг is_need_new_label, если был
        this.api.setLabelPrinted(this.uid).subscribe(data => {
            // ok
        })

        this.printStat = !e;
        this.uid = null;
        this.bar = null;
        if (this.lastPrintValue !== true) {
            this.letPrint = false;
        }
    }

    setCargoCourier() {
        this.notifier.openNotifier('Курьер назначается...')
        this.api.setCargoCourierWhenReceivingAtWarehouse(this.resultOrder.id).subscribe(result => {
            this.notifier.openNotifier(result, null, {class: 'success', duration: 1500});
        }, error => {
            console.warn(error);
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    toggleIsBulky() {
        this.notifier.openNotifier('Флаг переключается...');
        this.loadingIsBulky = true;
        this.api.setOrderIsBulky(this.resultOrder.uid, !this.resultOrder.option.is_bulky).subscribe(result => {
            this.resultOrder.option.is_bulky = result.option.is_bulky;
            this.loadingIsBulky = false;
            this.notifier.openNotifier('Записано', null, {class: 'success', duration: 1500});
        }, error => {
            this.loadingIsBulky = false;
            console.warn(error);
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }
}
