import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {LaraService} from "../../service/lara.service";
import {AppComponent} from "../../app.component";
import {User} from "../../service/models";
import {UserDialogComponent} from "./user-dialog/user-dialog.component";

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

    @Input('user_id') public user_id: string;

    public loading = false;

    constructor(
        private api: LaraService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getUserInfo(this.user_id).subscribe((data: User) => {
            this.app.createDialog(UserDialogComponent, {
                user: new User(data)
            });
            this.loading = false;
        });
    }
}
