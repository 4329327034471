import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {FinanceService} from '../../../service/finance.service';
import {HelpersService} from '../../../service/helpers.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-kassa-report-problems',
    templateUrl: './kassa-report-problems.component.html',
    styleUrls: ['./kassa-report-problems.component.scss']
})
export class KassaReportProblemsComponent implements OnInit {

    @Input('parentCompanyId') parentCompanyId: number = 0;
    @Input('reportDate') reportDate: string;


    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Список проблем';

    public fields = [];
    public problems = [];

    public problemsLoading: boolean = false;
    public problemIsResolving: boolean = false;

    public noProblemsForDay = false;

    public processingOrders: string[] = [];
    public processedOrders: string[] = [];

    constructor(
        protected app: AppComponent,
        private financeApi: FinanceService,
        public helper: HelpersService,
        private datePipe: DatePipe,
    ) {
        if (window.innerWidth > 800) {
            this.width = 800;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.loadProblems();
    }

    /**
     * Загрузить проблемы
     */
    loadProblems() {
        this.fields = [];
        this.problems = [];
        this.processingOrders = [];
        this.processedOrders = [];

        this.problemsLoading = true;
        this.financeApi.getKassaReportProblems({
            parent_company: this.parentCompanyId,
            date_from: this.reportDate,
            date_to: this.reportDate
        })
            .subscribe(data => {
                this.problems = data.report;
                this.fields = data.fieldNames;
                this.problemsLoading = false;

                this.noProblemsForDay = true;
                for (const report of this.problems) {
                    if (report.orders.length > 0) {
                        this.noProblemsForDay = false;
                        break;
                    }
                }
            }, error => {
                this.problemsLoading = false;
            });

        this.title = 'Список проблем за ' + this.datePipe.transform(this.reportDate, 'dd.MM.yyyy');
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * Решить проблемы с чеками по заказу
     * @param orderUid
     */
    resolveOrderReceiptProblems(orderUid: string) {
        this.processingOrders.push(orderUid);

        this.financeApi.correctReceipts(orderUid)
            .subscribe(data => {
                if (data.message) {
                    alert(data.message);
                } else {
                    this.processedOrders.push(orderUid);
                }

                this.removeFromProcessing(orderUid);
            }, error => {
                this.removeFromProcessing(orderUid);
            })
    }

    private removeFromProcessing(orderUid: string) {
        this.processingOrders.splice(this.processingOrders.indexOf(orderUid), 1);
    }

    public orderIsProcessing(orderUid: string) {
        return (this.processingOrders.indexOf(orderUid) >= 0);
    }

    public orderIsProcessed(orderUid: string) {
        return (this.processedOrders.indexOf(orderUid) >= 0);
    }
}
