import {NgModule} from '@angular/core';
import {PickupPointRoutingModule} from './pickup-point-routing.module';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import {PickupPointOrdersListComponent} from './pickup-point-orders-list/pickup-point-orders-list.component';
import {PickupPointOrderShippingComponent} from './pickup-point-order-shipping/pickup-point-order-shipping.component';

@NgModule({
    declarations: [
        PickupPointOrdersListComponent,
        PickupPointOrderShippingComponent,
    ],
    exports: [],
    imports: [
        RouterModule,
        PickupPointRoutingModule,
        ComponentsModule,
    ],
    providers: [],
    entryComponents: [],
})
export class PickupPointModule {
}
