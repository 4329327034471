import {Component, OnInit, ViewChild} from '@angular/core';
import {PickupPointApiService} from '../pickup-point-api.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {Hub, Order, User} from '../../service/models';
import {EventerService} from '../../service/eventer.service';
import {HubsService} from '../../service/hubs.service';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query/dist/npm/state';
import {StatusSelectComponent} from '../../components/status-select/status-select.component';

@Component({
    selector: 'app-pickup-point-orders-list',
    templateUrl: './pickup-point-orders-list.component.html',
    styleUrls: ['./pickup-point-orders-list.component.scss'],
})
export class PickupPointOrdersListComponent implements OnInit {

    @ViewChild('input_status') statusSelectComp: StatusSelectComponent;

    public selectedStatuses: number[] = [];
    public loading = false;
    public ordersGridData: GridDataResult;
    public ordersGridState: State = {
        take: 1,
        skip: 0,
    }
    public orders = [];
    public formFilter: FormGroup;
    public today = new Date();

    public selectedHubs: Hub[] = [];
    public selectedHubId: number;

    public hubsList: Hub[] = null;
    public pageSizeDefault = 100;
    public pageSize = this.pageSizeDefault;

    constructor(
        private api: PickupPointApiService,
        private currentUserService: CurrentUserService,
        private datePipe: DatePipe,
        private eventerService: EventerService,
        public helpers: HelpersService,
        private hubsService: HubsService,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.selectedStatuses = [2, 3];
        this.statusSelectComp.selectedStatuses = this.selectedStatuses;

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });

        const hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;

        this.formFilter = new FormGroup({
            clients: new FormControl(),
            statuses: new FormControl(this.selectedStatuses),
            hub_id: new FormControl(hub.id),
        });

        this.listOrders();
    }

    onSearchOrders() {
        this.listOrders();
    }

    onSelectClient(event) {
        this.formFilter.controls.clients.setValue(event.map(client => client.id));
    }

    onSelectStatus(event) {
        this.formFilter.controls.statuses.setValue(event);
    };

    onChangeHub(hub) {
        this.selectedHubs = [hub];
        this.selectedHubId = hub.id;
        this.formFilter.controls.hub_id.setValue(hub.id);
    }

    formatNum(value) {
        return parseFloat(value).toFixed(2);
    }

    onPageChange({skip, take}: PageChangeEvent): void {
        if (this.loading) {
            return;
        }

        this.ordersGridState.skip = skip;
        this.ordersGridState.take = take;

        this.listOrders();
    }

    private listOrders() {
        this.loading = true;
        const filter = {
            take: this.ordersGridState.take,
            skip: this.ordersGridState.skip,
            ...this.formFilter.value,
        };

        this.api.listOrders(filter).subscribe(responsePayload => {
            this.ordersGridState.take = responsePayload.per_page;
            this.ordersGridState.skip = responsePayload.skip;
            responsePayload.data.map(order => {
                order.readyForShipping = (
                    order.status === Order.STATUS_STORAGE
                    && null === order.returned_courier_id
                );
            });
            this.ordersGridData = responsePayload;

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
