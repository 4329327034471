import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TransitVehicle} from '../../../service/models';

@Component({
    selector: 'app-transit-vehicle-modal',
    templateUrl: './transit-vehicle-modal.component.html',
    styleUrls: ['./transit-vehicle-modal.component.scss']
})
export class TransitVehicleModalComponent implements OnInit {
    public width;
    public transit;

    public vehicles: TransitVehicle[];
    public vehiclesSource: TransitVehicle[];

    public newVehicle: TransitVehicle;
    public selectedVehicle: TransitVehicle;

    public formEdit: FormGroup;

    public isNewVehicle: boolean = true;
    public allowEditing: boolean = true;
    public vehicleIsSaving: boolean = false;

    public ownershipTypes = TransitVehicle.ownershipTypes;

    constructor(
        protected app: AppComponent,
        protected api: SkladService) {
        this.formEdit = new FormGroup({
            reg_number: new FormControl(null, [Validators.required]),
            model: new FormControl(null, [Validators.required]),
            weight_capacity: new FormControl(null, [Validators.required]),
            volume_capacity: new FormControl(null, [Validators.required]),
            ownership_type: new FormControl(null, [Validators.required])
        });
    }

    handleFilter(value) {
        this.vehicles = this.vehiclesSource.filter((s) => {
            return (s.model + ' ' + s.reg_number).toLowerCase().indexOf(value.toLowerCase()) !== -1
        });
    }

    ngOnInit() {
        this.api.getTransitVehicles()
            .subscribe(data => {
                this.vehicles = data;
                this.vehiclesSource = data.slice();
            })
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onVehicleNumberChange($event) {
        if (this.newVehicle.id) {
            // заполним форму данными машины
            const vehicle = this.vehicles.find(d => this.newVehicle.id === d.id);

            for(let i in this.formEdit.controls) {
                this.formEdit.get(i).setValue(vehicle[i]);
            }

            this.selectedVehicle = this.newVehicle;
            this.isNewVehicle = false;
            this.allowEditing = false;
        }
    }

    onChooseNewVehicleClick() {
        this.isNewVehicle = true;
        this.allowEditing = true;
        for(let i in this.formEdit.controls) {
            this.formEdit.get(i).setValue(null);
        }
    }

    onEditExistingVehicleClick() {
        this.allowEditing = true;
    }

    onSaveVehicleButtonClick() {
        this.vehicleIsSaving = true;

        const vehicleData = this.formEdit.value;
        vehicleData.id = this.selectedVehicle.id;

        this.api.saveTransitVehicle(vehicleData)
            .subscribe((data: TransitVehicle) => {
                this.vehicleIsSaving = false;

                this.addVehicleToTransit(data);
            }, error => {
                this.vehicleIsSaving = false;
            });
    }

    onAddVehicleButtonClick() {
        this.vehicleIsSaving = true;
        this.api.addTransitVehicle(this.formEdit.value)
            .subscribe((data: TransitVehicle) => {
                this.vehicleIsSaving = false;

                this.addVehicleToTransit(data);
            }, error => {
                this.vehicleIsSaving = false;
            });
    }

    onSetVehicleButtonClick() {
        this.addVehicleToTransit(this.selectedVehicle);
    }

    /** установить машину в перемещении */
    addVehicleToTransit(vehicle: TransitVehicle) {
        this.api.setVehicleInTransit(this.transit.id, vehicle.id)
            .subscribe(data => {
                this.vehicleIsSaving = false;
                this.onClose();
            }, error => {
                this.vehicleIsSaving = false;
            });
    }

    public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
        return {
            id: 0,
            reg_number: text
        };
    }));
}
