import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-fuel-prices',
    templateUrl: './fuel-prices.component.html',
    styleUrls: ['./fuel-prices.component.scss'],
})
export class FuelPricesComponent implements OnInit, AfterViewInit {

    public fuelPrices: any;
    public loading = false;
    public form: FormGroup;
    public couriers: FormGroup;
    public date: any;
    public loadingDay: any;
    public changeDataMessage: string;
    @ViewChild('input_date') inputDate: ElementRef;

    constructor(
        public api: LaraService,
        protected helper: HelpersService,
        protected datePipe: DatePipe,
    ) {
        this.form = new FormGroup({
            inputDate: new FormControl(new Date()),
        })
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getDate();
    }

    getDate() {
        this.date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        this.getCourierTariffs();
    }

    getCourierTariffs() {
        this.loadingDay = true;
        this.loading = true;
        this.fuelPrices = [];

        this.api.getFuelPrices(this.date).subscribe(data => {
            this.fuelPrices = data;
            this.loading = false;
        });
    }

    onInputPriceChange(event, fuel) {
        const data = {
            price: event.target.value
        };

        this.changeDataMessage = null;
        event.target.disabled = true;

        this.api.updateFuelPrice(fuel.id, data).subscribe((response) => {
            this.changeDataMessage = `Цена ${fuel.fuel_grade.name} изменена на ${response.price} рублей`;
            event.target.value = response.price;
            event.target.disabled = false;
        }, error => {
            event.target.disabled = false;
            this.getDate();
        });
    }

}
