import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OrderLoadingService {
    loading: EventEmitter<any> = new EventEmitter();
    request: EventEmitter<any> = new EventEmitter();

    constructor() {
    }
}
