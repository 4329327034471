import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShiftType, User, UserHub} from '../../service/models';
import {Subject} from 'rxjs';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {CurrentUserService} from '../../service/current-user.service';
import {EventerService} from '../../service/eventer.service';
import {DatePipe} from '@angular/common';
import {LogisticService} from '../../service/logistic.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {NotifierService} from '../../service/notifier.service';
import {parseNumber} from '@progress/kendo-angular-intl';

interface StatusInterface {
    status_ids: object,
    substatus_ids: object,
}

@Component({
    selector: 'app-courier-schedule',
    templateUrl: './courier-schedule.component.html',
    styleUrls: ['./courier-schedule.component.scss'],
})
export class CourierScheduleComponent implements OnInit {
    public formFilter: FormGroup;
    public selectedUsers: User[] = [];
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public selectedHubs: UserHub[] = null;
    public selectedHub: UserHub = null;
    public oneShift = null;
    public today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    public isLoading = false;
    public courierTypesList = [];
    public gridData: [];
    public dates: [];
    public countOrdersCouriers = [];
    public selectedKeysSchedule: number[] = [];

    public appCourierTypeChangeEventSubject: Subject<number[]> = new Subject<number[]>();
    public appScheduleStatusChangeEventSubject: Subject<any> = new Subject<any>();
    constructor(
        public matDialog: MatDialog,
        public helper: HelpersService,
        protected api: LogisticService,
        protected dialog: DialogService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private datePipe: DatePipe,
        private notifier: NotifierService
    ) {
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [this.selectedHub, ];
        this.formFilter = new FormGroup(
            {
                date_start: new FormControl(this.today),
                date_end: new FormControl(this.today),
                hub_id: new FormControl(this.selectedHub.id),
                courier_ids: new FormControl([]),
                shift_number: new FormControl([]),
                courier_type: new FormControl([]),
                status_ids: new FormControl([]),
                substatus_ids: new FormControl([]),
                district_ids: new FormControl([]),
                work_is_all_directions: new FormControl(0),
                add_volume: new FormControl(0),
                work_day: new FormControl(0),
                only_not_assigned: new FormControl(0),
            }
        );

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        this.api.getCourierTypes().subscribe(data => {
            this.courierTypesList = data;
        })
    }

    ngOnInit(): void {
        this.getCourierSchedule();
    }


    /**
     * Открытие диалоговой карточки по дню
     */
    async openScheduleDayDialog(courier, dayinfo): Promise<void> {
        if (dayinfo.day_past) {
            this.notifier.openNotifier('Редактировать прошедшие дни запрещено!', null, {class: 'danger', duration: 3000});
            return;
        }
        courier = this.prepareCourierData(courier);
        const dialogRef = this.matDialog.open(CourierScheduleDayDialogComponent, {
            width:  'auto',
            height: 'auto',
            data: {
                courier: courier,
                dayinfo: dayinfo,
                oneShift: this.oneShift,
            }
        });

        /**
         * Подписка на событие, закрытия диалогового окна редактирования даты
         */
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.notifier.openNotifier('Изменения обрабатываются...')

                /** сохранение */
                if (result.schedule_id !== -1) {
                    this.api.editCourierSchedule(result).subscribe(answer => {
                        this.notifier.openNotifier('Измененения сохранены', null, {class: 'success', duration: 1500});
                        this.replacingDataInGrid(courier.courier_id, answer);
                    }, error => {
                        this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                    })
                } else {
                    /** создание */
                    this.api.createCourierSchedule(result).subscribe(answer => {
                        this.notifier.openNotifier('Измененения сохранены', null, {class: 'success', duration: 1500});
                        this.replacingDataInGrid(courier.courier_id, answer);
                    }, error => {
                        this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                    })
                }
            }
        });
    }

    /**
     * Замена данных в гриде
     * @param courier_id
     * @param answer
     */
    replacingDataInGrid(courier_id, answer) {
        let temp;
        temp = this.gridData;
        this.gridData.map((row, index) => {
            if (row['courier_id'] === courier_id) {
                temp[index]['dates'][answer.date.toString()] = [];
                temp[index]['dates'][answer.date.toString()] = [answer];
            }
        })
        this.gridData = [];
        this.gridData = temp;
    }

    /**
     * Подготовка курьерских данных
     * @param courier
     */
    prepareCourierData(courier) {
       courier.courier_type_text = this.courierTypesList.filter(value => {
            return (value.value === courier.courier_type);
        })[0].name;
        return courier;
    }
    /**
     * Событие при смене хаба
     * @param hub
     */
    onChangeHub(hub) {
        if (hub.id !== this.selectedHub.id) {
            this.selectedUsers = [];
        }
        this.selectedHub = hub;
        this.selectedHubs = [hub, ];
        this.formFilter.controls.hub_id.setValue(this.selectedHub.id);
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
    }

    /**
     * Событие при смене смены
     * @param shifts
     */
    onChangeShift(shifts: number[]) {
        this.formFilter.controls.shift_number.setValue(shifts);
        if (shifts.findIndex(x => x === ShiftType.FIRST_SHIFT) !== -1 && shifts.length === 1) {
            this.oneShift = ShiftType.FIRST_SHIFT;
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: ShiftType.FIRST_SHIFT});
        } else if (shifts.findIndex(x => x === ShiftType.SECOND_SHIFT) !== -1 && shifts.length === 1) {
            this.oneShift = ShiftType.SECOND_SHIFT;
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: ShiftType.SECOND_SHIFT});
        } else {
            this.oneShift = null;
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: []});
        }
    }

    /**
     * Событие при смене курьера
     * @param couriers
     */
    onChangeCourier(couriers) {
        this.formFilter.controls.courier_ids.setValue(couriers.length > 0 ? couriers.map(x => x.id) : couriers);
    }

    /**
     * Событие при смене типа курьера
     * @param value
     */
    onChangeCourierType(value) {
        this.formFilter.controls.courier_type.setValue(value);
    }

    /**
     * Событие при смене даты
     * @param controlName
     */
    onChangeDate(controlName) {
        this.formFilter.controls[controlName].setValue(this.datePipe.transform(this.formFilter.controls[controlName].value, 'yyyy-MM-dd'))
        if (this.formFilter.value.date_start !== this.formFilter.value.date_end) {
            this.formFilter.controls.work_day.setValue(0);
        }
    }

    /**
     * Событие при смене статуса
     * @param event
     */
    onChangeStatus(event: StatusInterface) {
        this.formFilter.controls.status_ids.setValue(event.status_ids);
        this.formFilter.controls.substatus_ids.setValue(event.substatus_ids);
    }


    /**
     * Получение курьерского графика
     */
    getCourierSchedule() {
        this.isLoading = true;
        if (this.gridData) {
            this.resetCheked();
        }
        this.api.getCourierScheduleList(this.formFilter.value).subscribe(data => {
            this.gridData = data.schedule;
            this.dates = data.dates;
            this.isLoading = false;

            if (this.formFilter.value.date_start === this.formFilter.value.date_end) {
                this.getCountOrdersForSchedule();
            }
        }, error => {
            this.isLoading = false;
        })
    }

    /**
     * Копирование курьерского графика
     * @param courier_id
     */
    copyCourierSchedule(courier_id) {
        this.notifier.openNotifier('Изменения обрабатываются...')
        this.api.copyCourierSchedule(courier_id, {
            date_start: this.formFilter.controls.date_start.value,
            date_end: this.formFilter.controls.date_end.value
        }).subscribe(result => {
            this.notifier.openNotifier(result, null, {class: 'success', duration: 1500});
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }

    /**
     * Планирование курьерского графика
     */
    planningCourierSchedule() {
        const dialogRef = this.matDialog.open(CourierSchedulePlanningDialogComponent, {
            width:  'auto',
            height: 'auto',
            data: {
                courier_ids: this.selectedKeysSchedule,
                oneShift: this.oneShift,
            }
        });
    }

    setFilters(courierType, status_ids, substatus_ids) {
        this.appCourierTypeChangeEventSubject.next(courierType);
        this.appScheduleStatusChangeEventSubject.next({
            status_ids: status_ids,
            substatus_ids: substatus_ids,
        })
        setTimeout(() => {
            this.getCourierSchedule();
        }, 0);
    }

    setFilterDriversWork() {
       this.setFilters([1, 4, 5, 6, 7], [2], [5, 6]);
    }

    setFilterDriversSleep() {
        this.setFilters([1, 4, 5, 6, 7], [1], [3]);
    }

    setFilterCargoWork() {
        this.setFilters([3], [2], [5, 6]);
    }

    setFilterCargoSleep() {
        this.setFilters([3], [1], [3]);
    }

    setFilterPedestrianWork() {
        this.setFilters([2], [2], [5, 6]);
    }

    setFilterPedestrianSleep() {
        this.setFilters([2], [1], [3]);
    }

    onChangeDistrict(event) {
        this.formFilter.controls.district_ids.setValue(event.length > 0 ? event.map(x => x.id) : [])
    }

    setFilterStagerWork() {
        this.setFilters([4], [2], [5, 6]);
    }

    setFilterStagerSleep() {
        this.setFilters([4], [1], [3]);
    }

    setFilterHireWork() {
        this.setFilters([5], [2], [5, 6]);
    }

    setFilterHireSleep() {
        this.setFilters([5], [1], [3]);
    }

    /**
     * Событие при изменении состояния чекбокса
     * @param event
     */
   async onChangeCheckbox(event) {
       const value = parseNumber(event.target.value);
       const index = this.selectedKeysSchedule.indexOf(value);
        if (index > -1) {
            this.selectedKeysSchedule.splice(index, 1);
        } else {
            this.selectedKeysSchedule.push(value);
        }
        document.getElementById('check-box-all')['checked'] = this.selectedKeysSchedule.length === this.gridData.length;
    }

    /**
     * Событие при изменении состояния всех чекбоксов
     * @param event
     */
    onChangeAllCheckbox(event) {
        const cheked = event.target.checked;
        this.selectedKeysSchedule = cheked ? this.gridData.map(x => {
            return x['courier_id'];
        }) : [];
        this.gridData.forEach(value => {
            document.getElementById('check-box-' + value['courier_id'])['checked'] = cheked;
        })
    }

    /**
     * Сброс состояния чекбокса
     */
    resetCheked() {
         this.selectedKeysSchedule = [];
         document.getElementById('check-box-all')['checked'] = false
    }

    resetFilters() {
        this.setFilters([], [], []);
    }

    onChangeActiveWorkDay() {
        if (this.formFilter.controls.work_day.value > 0) {
            if (this.formFilter.controls.date_start.value !==  this.formFilter.controls.date_end.value) {
                this.formFilter.controls.date_start.setValue(this.today);
                this.formFilter.controls.date_end.setValue(this.today);
            }
        }
    }

    changeAssignCourier(schedule_id, courier_id) {
        this.notifier.openNotifier('Изменения в графике обрабатываются...');
        this.api.changeAssignCourierInCourierSchedule({
            schedule_id: schedule_id,
        }).subscribe(answer => {
            this.notifier.openNotifier('Измененения сохранены', null, {class: 'success', duration: 1500});
            this.replacingDataInGrid(courier_id, answer);
            this.getCountOrdersForSchedule();
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        })
    }

    async getCountOrdersForSchedule() {
        const courier_ids = this.gridData.map(x => {
            return x['courier_id'];
        });
        if (courier_ids.length < 1) {
            return;
        }
        const data = {
            date: this.formFilter.controls.date_start.value,
            courier_ids: courier_ids
        };
        this.api.getCountOrdersForSchedule(data).subscribe(value => {
            this.countOrdersCouriers = value;
        })
    }
}
@Component({
    selector: 'app-courier-schedule-planning-dialog-component',
    styleUrls: ['./courier-schedule.component.scss'],
    templateUrl: 'courier-schedule-planning-dialog.html',
})
export class CourierSchedulePlanningDialogComponent {
    formFilter: FormGroup;
    isLoading = false;
    constructor(
        protected api: LogisticService,
        protected dialog: DialogService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        public dialogRef: MatDialogRef<CourierSchedulePlanningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.formFilter = new FormGroup(
            {
                date_start: new FormControl(this.datePipe.transform(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd'), Validators.required),
                date_end: new FormControl(this.datePipe.transform(new Date().setDate(new Date().getDate() + 15), 'yyyy-MM-dd'), Validators.required),
                cnt_work_day: new FormControl(3, Validators.required),
                cnt_not_work_day: new FormControl(3, Validators.required),
                courier_ids: new FormControl(data.courier_ids),
                shift_number: new FormControl(data.oneShift),
                confirm: new FormControl(false),
            }
        );
    }

    /**
     * Закрытие диалогового окна
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Событие при смене даты
     * @param controlName
     */
    onChangeDate(controlName) {
        this.formFilter.controls[controlName].setValue(this.datePipe.transform(this.formFilter.controls[controlName].value, 'yyyy-MM-dd'))
    }

    /**
     * Начало планирования курьского графика
     */
    startPlanningCourierSchedule(confirm = false) {
        this.formFilter.controls.confirm.setValue(confirm);
        this.isLoading = true;
        this.notifier.openNotifier('Изменения в графике обрабатываются...');
        this.api.planningCourierSchedule(this.formFilter.value).subscribe(value => {
            this.isLoading = false;
            if (value.question) {
                const dialog = this.dialog.confirm(value.question, true).subscribe(confirmOk => {
                    if (confirmOk) {
                        this.startPlanningCourierSchedule(true);
                    } else {
                        this.notifier.openNotifier('Изменения не произведены');
                    }
                    dialog.unsubscribe();
                });
            } else {
                this.notifier.openNotifier(value, null, {class: 'success', duration: 1500});
                this.dialogRef.close();
            }
        }, errorPlanning => {
            this.isLoading = false;
        })
    }
}



@Component({
    selector: 'app-courier-schedule-day-dialog-component',
    styleUrls: ['./courier-schedule.component.scss'],
    templateUrl: 'courier-schedule-day-dialog.html',
})
export class CourierScheduleDayDialogComponent {
    tabIndex = 0;
    logs = [];
    isLoading = false;
    result = {
        shift_number: null,
        status_id: null,
        substatus_id: null,
    };
    constructor(
        protected api: LogisticService,
        protected dialog: DialogService,
        public dialogRef: MatDialogRef<CourierSchedulePlanningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    async loadLogs() {
        this.isLoading = true;
        this.logs = [];
        this.api.getCourierScheduleLog(this.data.courier.dates[this.data.dayinfo.day][0].id).subscribe(value => {
            this.logs = value;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        })
    }

    /**
     * Закрытие диалогового окна
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Событие при смене вкладки
     * @param event
     */
    onSelectIndexChange(event: number) {
        this.tabIndex = event;
        if (event === 1) {
            this.loadLogs();
        }
    }

    /**
     * Событие при смене статуса
     * @param event
     */
    onChangeScheduleStatus(event) {
        this.result.status_id = event.status_ids.length > 0 ? event.status_ids[0] : null;
        this.result.substatus_id = event.substatus_ids.length > 0 ? event.substatus_ids[0] : null;
    }

    /**
     * Событие при смене смены
     * @param shift
     */
    onChangeShift(shift) {
        this.result.shift_number = shift.length > 0 ? shift[0] : null;
    }

    /**
     * Закрытие диалогового окна и применение изменений
     */
    applyAndCloseDialog() {
        if (this.result.shift_number === null) {
          return this.dialog.alert('Укажите номер смены');
        }

        if (this.result.status_id === null) {
            return this.dialog.alert('Укажите статус');
        }
        if ((this.result.status_id === 1 || this.result.status_id === 2) && this.result.substatus_id === null) {
            return this.dialog.alert('Укажите подстатус');
        }
        const params = {
            schedule_id: this.data.courier.dates[this.data.dayinfo.day] ? this.data.courier.dates[this.data.dayinfo.day][0].id : -1,
            courier_id:  this.data.courier.courier_id,
            status_id: this.result.status_id,
            substatus_id: this.result.substatus_id,
            shift_number: this.result.shift_number,
        }
        if (!this.data.courier.dates[this.data.dayinfo.day]) {
            params['date'] = this.data.dayinfo.day;
        }
        this.dialogRef.close(params);
    }
}

interface CourierInfo {
    courier_id: number;
    courier_fio: string;
    dates: object;
    courier_type: number;
    courier_type_text: string;
}

class DayInfo {
    day: string;
    day_past: string;
    day_count: string;
    day_of_week: string;
    day_of_week_short: string;
}

interface CourierTypesList {
    name: string;
    value: number;
}

export interface DialogData {
    courier_ids: number[];
    oneShift: number | null;
    dayinfo: DayInfo;
    courier: CourierInfo;
    courierTypesList: CourierTypesList;
}
