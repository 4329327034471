import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {NavigationEnd, Router} from '@angular/router';
import {interval} from 'rxjs';
import {EventerService} from '../../service/eventer.service';
import {User} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';

export interface IRefusedOrdersQueueStatsResponse {
    orders_count: number;
}

@Component({
    selector: 'app-refused-orders-queue-counter',
    template: `
        <span class="counter"
              [ngClass]="getBadgeClassNames()"
              *ngIf="!loading" (click)="getCounter()">
            <span *ngIf="!failed">{{counter}}</span>
            <span class="far fa-dizzy" *ngIf="failed"></span>
            <i class="fas fa-sync-alt"></i>
        </span>
        <div *ngIf="loading" class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
    `,
    styles: [
        'span.counter {padding: 2px 5px;border-radius: 5px;}',
        'span > span {margin: 0 2px;}',
    ],
})

export class RefusedOrdersQueueCounterComponent implements OnInit {
    public counter: number = 0;
    public failed: boolean = false;
    public loading: boolean = false;
    private selectedHubId: number;

    constructor(
        private api: LaraService,
        private router: Router,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        interval(60000).subscribe(x => this.checkUrl(router.url))
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.checkUrl(val.url);
            }
        });
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.onChangeHub(this.currentUserService.getCurrentHub());
        });
    }

    /**
     * Обработчик события изменения хаба (в фильтре или глобально)
     * @param hub
     */
    private onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubId = hub.id;
        this.getCounter();
    }

    public getCounter() {
        if (this.loading || !this.selectedHubId) {
            return;
        }

        this.loading = true;
        this.failed = false;

        this.api.getRefusedOrdersQueueStats({hub_id: this.selectedHubId})
            .subscribe((responsePayload: IRefusedOrdersQueueStatsResponse) => {
                this.counter = responsePayload.orders_count;
                this.loading = false;
            }, () => {
                this.loading = false;
                this.failed = true;
            });
    }

    getBadgeClassNames() {
        if (this.counter) {
            return 'text-white bg-danger';
        }

        return 'text-muted border border-muted';
    }

    private checkUrl(url) {
        const val = url.split('/');
        if (val.indexOf('refused-on-spot') > -1) {
            this.getCounter();
        }
    }
}
