import {Component, OnInit} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {User, UserHub} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {HubsService} from '../../service/hubs.service';
import {EventerService} from '../../service/eventer.service';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-sberbank-control',
    templateUrl: './sberbank-control.component.html',
    styleUrls: ['./sberbank-control.component.scss']
})
export class SberbankControlComponent implements OnInit {
    editQueue = false;
    loading = false;

    public todayCouriers = [];
    public notReadyCouriers = [];
    public readyCouriers = [];

    counterState = 'counter is ticking';

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];

    public canSetCourierArrived = false;

    constructor(
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private hubsService: HubsService,
        private api: SkladService,
        public helper: HelpersService
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.canSetCourierArrived = this.helper.checkPermissions('log:set-courier-arrival');
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub, ];
            this.selectedHubId = hub.id;
        })
        this.loadingSberbank(this.selectedHubId);
    }

    loadingSberbank(hub_id: number = null) {
        if (null === hub_id) {
            hub_id = this.selectedHubId;
        }
        this.loading = true;
        this.editQueue = false;
        this.api.getSberbankOptimized(hub_id).subscribe(courier => {
            this.notReadyCouriers = courier.filter(item => {
                return item.priority <= 0;
            });
            this.readyCouriers = courier.filter(item => {
                return item.priority > 0;
            });
            this.loading = false;
        });
    }
    dropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.readyCouriers,
            event.previousIndex,
            event.currentIndex
        );
    }
    saveQueue() {
        this.editQueue = !this.editQueue;
        if (this.editQueue) { return; }
        const couriers: any = this.readyCouriers.map(data => {
            return data.id;
        });
        this.saveAssemblePriorities(couriers);
    }

    /**
     * Можно ли начать сборку
     * @param courier
     */
    canStartAssembling(courier) {
        if (courier.id === null) {
            return false;
        }

        // TODO SPRNT-377 разблокировать по просьбе логистов
        // if (courier.money_not_returned) {
        //     if (this.helper.checkPermissions('log:sberbank-assemble-notclosed-courier')) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // }

        return true;
    }

    startAssemble(id, index) {
        this.api.startAssemble(id).subscribe(data => {
            console.log(data);
            for (const courier of this.notReadyCouriers) {
                if (courier.id === id) {
                    this.readyCouriers.push(courier);
                }
            }
            this.notReadyCouriers.splice(index, 1);
        })
    }

    saveAssemblePriorities(couriers) {
        this.api.saveAssemblePriorities({id: couriers}).subscribe( data => {
            this.loadingSberbank(this.selectedHubId)
        })
    }
    timeAssemble (start, end, status) {
        const today = new Date();
        const month = +today.getMonth() + 1;
        if (!end) {
            const str = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            return Math.round(((new Date().getTime() - new Date(str).getTime()) / 1000) / 60);
        } else {
            const start_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            const end_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + end;
            return Math.round(((new Date(end_time).getTime() - new Date(start_time).getTime()) / 1000) / 60);
        }
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id))
        {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.loadingSberbank(this.selectedHubId);
    }

    onCourierQueueStatusChanged(event) {
        const index = this.notReadyCouriers.findIndex(courier => courier.old_courier_id === event.old_courier_id);
        if (-1 !== index) {
            this.notReadyCouriers[index].queue_status = event.queue_status;
            this.notReadyCouriers[index].status = event.queue_status;
            this.notReadyCouriers[index].id = event.queue_id;
            this.notReadyCouriers[index].time_come = event.time_come;
        }
    }
}
