import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {AddressService} from '../../service/address.service';
import {AppComponent} from "../../app.component";


import {AddressDialogComponent} from "./address-dialog/address-dialog.component";

@Component({
    selector: 'app-address-card',
    templateUrl: './address-card.component.html',
    styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {


    @Input('addressId') public addressId: number = null;
    @Input('latitude') public latitude: number = null;
    @Input('longitude') public longitude: number = null;

    public loading = false;


    constructor(
        private api: AddressService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;

        this.api.getAddress(this.addressId).subscribe(async (data) => {
            console.log(data);

            this.app.createDialog(AddressDialogComponent, {
                address: data.address,
                ordersCount: data.usage.ordersCount,
                latitude: this.latitude,
                longitude: this.longitude
            });
            this.loading = false;
        });
    }
}
