import {Component, Input, OnInit} from '@angular/core';
import {File, Order, OrderGood} from '../../service/models';
import {AppComponent} from '../../app.component';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {map} from 'rxjs/operators';
import {ApiResult} from '../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AcceptValidator, MaxSizeValidator} from '@angular-material-components/file-input';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-return-order-modal',
    templateUrl: './return-order-modal.component.html',
    styleUrls: ['./return-order-modal.component.scss'],
})
export class ReturnOrderModalComponent implements OnInit {

    @Input() order_uid: string;

    public width = 900;
    public title = 'Обработка заказа';
    public windowHeight = 800;

    public order: Order;
    public files: File[];
    public goodsStatuses;

    public fileIsAdding: boolean = false;
    public fileIsUploading: boolean = false;

    public formEdit: FormGroup;

    /* 10 МБ в байтах */
    private maxSize = (10) * (1024 * 1024);

    constructor(
        private app: AppComponent,
        private api: SkladService,
        public helpers: HelpersService,
        private http: HttpNoInterceptorService,
        protected dialogService: DialogService,
        private notifier: NotifierService,
    ) {
    }

    ngOnInit() {
        this.loadOrder();
    }

    loadOrder() {
        this.api.getOrderWithDamagedGoods(this.order_uid)
            .subscribe((data) => {
                this.order = data;
                this.files = data.files.filter(file => file.file_type === File.TYPE_ORDER_LOST);
                this.title = 'Обработка заказа ' + data.uid;
                this.setGoodsStatuses();
                this.initForm();
            }, error => {
            })
    }

    initForm() {
        this.formEdit = new FormGroup({
            description: new FormControl(),
            file: new FormControl(null, [
                Validators.required,
                MaxSizeValidator(this.maxSize),
            ]),
            goods: this.getGoodsFormArray()
        });
    }

    private getGoodsFormArray() {
        let goodsFormArray = new FormArray([]);

        if (this.order.all_goods) {
            this.order.all_goods.forEach((good) => {
                goodsFormArray.push(new FormGroup({
                    'id': new FormControl(good.id),
                    'name': new FormControl(good.name),
                    'vendor_code': new FormControl(good.vendor_code),
                    'count': new FormControl(good.count),
                    'price': new FormControl(good.price),
                    'status': new FormControl(good.status),
                }));
            })
        }

        return goodsFormArray;
    }

    get goodsControls() {
        return (<FormArray>this.formEdit.get('goods')).controls;
    }

    /**
     * Восстанавливает статус товара
     * @param goodId
     * @param oldStatus
     */
    restoreOldStatus(goodId: number, oldStatus: number) {
        const goodControl = this.goodsControls.find(control => control.get('id').value === goodId);
        goodControl.get('status').patchValue(oldStatus);
    }

    onClose() {
        this.app.closeDialog(this);
    }

    addFileClick() {
        this.fileIsAdding = true;
    }

    /**
     * Отправка файлов на сервер
     */
    onFileUpload() {
        const fileDescription = this.formEdit.get('description').value;

        if (!fileDescription || fileDescription.length <= 0) {
            alert('Пожалуйста, опишите файл');
            return;
        }

        if (!this.formEdit.controls.file.value) {
            alert('Пожалуйста, выберите файл');
            return;
        }

        const formData = new FormData();
        formData.append('file', this.formEdit.controls.file.value);
        formData.append('description', fileDescription);
        formData.append('order_uid', this.order_uid);

        this.fileIsUploading = true;
        this.http.post('/storage/lost-orders/uploadFile', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((data: File[]) => {
                this.files = data;

                this.fileIsAdding = false;
                this.fileIsUploading = false;

                this.notifier.openNotifier('Файл успешно добавлен', null, {duration: 3000});
            }, (error: HttpErrorResponse) => {
                this.fileIsUploading = false;

                if (!error.error.response) {
                    alert('Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова');
                } else {
                    alert(error.error.response.error);
                }
            });
    }

    setGoodsStatuses() {
        this.goodsStatuses = {};

        this.order.all_goods.forEach(good => {
            this.goodsStatuses[good.id] = this.getAvailableStatuses(good.status);
        })
    }

    getAvailableStatuses(status: number) {
        let availableStatusIds = [status];
        switch (status) {
            case OrderGood.STATUS_DAMAGED:
                availableStatusIds.push(OrderGood.STATUS_DAMAGED_AND_COMPENSATED);
                availableStatusIds.push(OrderGood.STATUS_REFUSE);
                break;
            default:
                availableStatusIds.push(OrderGood.STATUS_DAMAGED);
                break;
        }

        return OrderGood.STATUSES_DESCRIPTIONS
            .filter(goodStatus => (availableStatusIds.indexOf(goodStatus.id) !== -1));
    }

    onGoodStatusChange(status: number, goodId: number) {
        const oldStatus = this.order.all_goods.find(good => good.id === goodId).status;
        const sub = this.dialogService.openEditDialog('Укажите причину', '', '')
            .subscribe(result => {
                if (result) {
                    this.api.setOrderGoodStatus({
                        'reason': result,
                        'good_id': goodId,
                        'status': status
                    })
                        .subscribe(data => {
                            this.order = data;
                            this.setGoodsStatuses();
                            this.initForm();

                            this.notifier.openNotifier('Статус успешно изменён', null, {duration: 3000});
                        }, error => {
                            this.restoreOldStatus(goodId, oldStatus);
                        })
                } else {
                    this.restoreOldStatus(goodId, oldStatus);
                }

                sub.unsubscribe();
            });
    }

    canManageGoods() {
        return this.helpers.checkPermissions('storage:lost-orders-admin');
    }

    getGoodStatus(statusId) {
        const status = OrderGood.STATUSES_DESCRIPTIONS.find(goodStatus => goodStatus.id === statusId);
        return status.name;
    }
}
