import {Component, Input, OnInit} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {PalletModalComponent} from './pallet-modal/pallet-modal.component';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-pallet-card',
    templateUrl: './pallet-card.component.html',
    styleUrls: ['./pallet-card.component.scss']
})
export class PalletCardComponent implements OnInit {

    public loading = false;
    public json: any[] = [];
    @Input('pallet_id') public palletId: string;

    constructor(private api: SkladService,
                protected app: AppComponent) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getTransit(this.palletId).subscribe((data) => {
            console.log(data);
            this.app.createDialog(PalletModalComponent, {
                pallet: data
            });
            this.loading = false;
        });
    }

}
