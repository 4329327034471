import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppComponent} from '../../app.component';
import {StaffService} from '../../service/staff.service';
import {NotifierService} from '../../service/notifier.service';
import {CourierOption, Ibox, Terminals} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';

@Component({
    selector: 'app-courier-terminal-card',
    templateUrl: './courier-terminal-card.component.html',
    styleUrls: ['./courier-terminal-card.component.scss']
})
export class CourierTerminalCardComponent implements OnInit {
    public loading = false;
    @Input('btn_view') public btn_view: boolean;
    @Input('is_master_terminal') public is_master_terminal: boolean;
    @Input('courier_id') public courier_id: number;
    @Input('provider_id') public provider_id: number | null;
    @Output() onDestroy = new EventEmitter<boolean>();
    constructor(
        protected api: StaffService,
        private notifier: NotifierService,
        private app: AppComponent
    ) { }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getTerminalsCourier(this.courier_id).subscribe((data) => {
            data.terminal = new Terminals(data.terminal);
            data.terminals = data.terminals.map(x => new Terminals(x))
            this.showCourierTerminalCard(data);
            this.loading = false;
        }, error => {
            this.loading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    private showCourierTerminalCard(data: any) {
        const dialog = this.app.createDialog(CourierTerminalCardDialogComponent, {
            data: data,
        });

        dialog.onDestroy(() => {
            this.onDestroy.emit(true);
        })
    }

    isProviderIbox() {
        return Ibox.PROVIDER_IBOX === this.provider_id;
    }

    isProviderAssist() {
        return Ibox.PROVIDER_ASSIST === this.provider_id;
    }

    isNotProvider() {
        return !this.isProviderIbox() && !this.isProviderAssist();
    }
}

interface TerminalInterface {
    name: string,
    value: number
}

@Component({
    selector: 'app-courier-terminal-card-dialog',
    templateUrl: './courier-terminal-card-dialog.component.html',
    styleUrls: ['./courier-terminal-card.component.scss']
})
export class CourierTerminalCardDialogComponent implements OnInit {
    data: CourierOption;
    dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'name',
        selectAllText: 'Выбрать всё',
        unSelectAllText: 'Убрать всё',
        searchPlaceholderText: 'Поиск',
        noDataAvailablePlaceholderText: 'Нет свободных терминалов',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    dropdownTerminals: TerminalInterface[] = [];
    public selectedTerminal = [];
    constructor(
        private api: StaffService,
        private notifier: NotifierService,
        protected app: AppComponent,
        public helpers: HelpersService,
    ) {
    }
    ngOnInit(): void {
        this.loadFreeTerminals();
    }

    onClose() {
        this.app.closeDialog(this);
    }

    /**
     * Возврат терминала в отдел логистики
     */
    returnTerminalToLogistic(terminal_id: number) {
        this.api.setTerminalStatus(terminal_id, Terminals.STATUS_IN_LOGISTIC).subscribe(result => {
            this.notifier.openNotifier('Терминал возвращён в отдел логистики', null, {class: 'success', duration: 1500});
            this.updateTerminalsList();
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Установка главного терминала для курьера
     * @param checked
     * @param terminal_id
     */
    setMasterTerminalForCourier(checked: boolean, terminal_id: number) {
        this.notifier.openNotifier('Изменение главного терминала...');
        this.api.setMasterTerminalForCourier(this.data.user_id, {
            terminal_id: terminal_id,
            condition: checked
        }).subscribe((data) => {
            this.notifier.openNotifier('Главный терминал изменён', null, {class: 'success', duration: 1500});
            this.updateTerminalsList();
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Обновление списка терминалов
     */
    public updateTerminalsList() {
        this.api.getTerminalsCourier(this.data.user_id).subscribe((data) => {
            data.terminal = new Terminals(data.terminal);
            data.terminals = data.terminals.map(x => new Terminals(x))
            this.data = data;
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Добавление терминала курьеру
     * @param event
     */
    onSetTerminalCourier(event: any) {
        if (typeof (event) === 'undefined') {
            return;
        }

        if (event.length < 1) {
            return
        }
        this.notifier.openNotifier('Закрепление терминала...');
        this.api.updateOrCreateTerminal(event[0].value, {
            courier_id: this.data.user_id
        }).subscribe((result) => {
            this.notifier.openNotifier('Терминал закреплён за курьером', null, {class: 'success', duration: 1500});
            setTimeout(() => {
                this.selectedTerminal = [];
                this.loadFreeTerminals();
                this.updateTerminalsList();
            }, 0);
        }, error => {
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });


    }

    /**
     * Загрузка свободных терминалов
     * @private
     */
    public loadFreeTerminals() {
        this.api.getTerminalsFree().subscribe((data) => {
            this.dropdownTerminals = data;
        })
    }
}
