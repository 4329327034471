import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientInfo} from '../../../service/models';
import {FinanceService} from '../../../service/finance.service';
import {BankPaymentOrder, IAgentReportsWithPaymentsBriefItem} from '../../../finance/finance-models';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {HelpersService} from '../../../service/helpers.service';
import {DatePipe} from '@angular/common';
import {AppComponent} from '../../../app.component';
import {NewBankPaymentOrderComponent} from '../new-bank-payment-order.component';

@Component({
    selector: 'app-new-bank-payment-order-dialog',
    templateUrl: './new-bank-payment-order-dialog.component.html',
    styleUrls: ['./new-bank-payment-order-dialog.component.scss'],
})
export class NewBankPaymentOrderDialogComponent implements OnInit {
    public opened = false;
    public loading = false;
    public clientId: number | null = null;
    public selectedClient: ClientInfo = null;
    public hasNonSeparated = false;
    public hasSeparated = false;
    public data: IAgentReportsWithPaymentsBriefItem[] | null = null;
    public displayedData: IAgentReportsWithPaymentsBriefItem[] | null = null;
    public allChecked = false;
    public allCheckedDisabled = false;
    public summarySumCod = 0.0;
    public summarySumCodCash = 0.0;
    public summarySumCodCashless = 0.0;
    public summarySumTransferredCod = 0.0;
    public summarySumTransferredCodCash = 0.0;
    public summarySumTransferredCodCashless = 0.0;
    public summarySumRestCod = 0.0;
    public summarySumRestCodCash = 0.0;
    public summarySumRestCodCashless = 0.0;
    public summarySumTariff = 0.0;
    public readonly COD_IGNORE = 0;
    public readonly COD_CASH = BankPaymentOrder.COD_CASH;
    public readonly COD_CARD = BankPaymentOrder.COD_CARD;
    public readonly COD_MIXED = BankPaymentOrder.COD_MIXED;
    public form: FormGroup;
    public tomorrow = new Date(+new Date() + 86400000);
    public readonly UI_STEP_REPORTS = 1;
    public readonly UI_STEP_PAYMENT = 2;
    public readonly UI_STEP_DONE = 3;
    public uiStep = this.UI_STEP_REPORTS;
    public agentReportsIds: number[] = [];
    public onlySelectedAgentReports = false;
    public createdBankPaymentOrder: BankPaymentOrder;
    @ViewChild('closeModal') private closeModal;
    private parent: NewBankPaymentOrderComponent;
    private resetPartSum = false;

    constructor(
        private api: FinanceService,
        private app: AppComponent,
        public helpers: HelpersService,
        private datePipe: DatePipe,
    ) {
        this.form = new FormGroup({
            client_id: new FormControl(this.clientId),
            payment_order_no: new FormControl(null, Validators.required),
            payment_order_date: new FormControl(this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd'), Validators.required),
            payment_purpose: new FormControl(''),
            payment_sum: new FormControl(0.0),
            cod_type: new FormControl(BankPaymentOrder.COD_MIXED),

            parts: new FormArray([]),
        });
    }

    ngOnInit() {
        this.opened = true;

        if (this.clientId) {
            this.loading = true;
            const clientId = this.clientId;
            this.clientId = null;
            this.api.getClientInfo(clientId).subscribe(data => {
                this.selectedClient = data.client;
                this.updateForClient();
            });
        }

        this.onlySelectedAgentReports = !!this.agentReportsIds.length;
    }

    public onClose() {
        this.hideModal();
    }

    public onChangeSelectedClient(event) {
        this.selectedClient = event;
        this.resetPartSum = true;

        this.updateForClient();
    }

    public onChangeCodType(newCodType) {
        this.form.controls.cod_type.setValue(newCodType);
        this.resetPartSum = true;
        this.updateDisplay();
    }

    public onClickReload() {
        this.getDataForNewBankPaymentOrder();
    }

    public onChangeOnlySelectedAgentReports() {
        this.updateDisplay();
    }

    public onChangeAllChecked(event) {
        this.displayedData = this.displayedData.map(item => {
            item.x_checked = event;

            return item;
        });

        this.syncAllChecked();
        this.updatePaymentSum();
    }

    public onChangeChecked(agentReportId, event) {
        this.displayedData = this.displayedData.map(item => {
            if (item.id === agentReportId) {
                item.x_checked = event;
            }

            return item;
        });

        this.syncAllChecked();
        this.updatePaymentSum();
    }

    public onUpdatePaymentSum(targetItem = null) {
        if (targetItem && !targetItem.x_sum && targetItem.x_checked) {
            targetItem.x_checked = false;
        }

        this.updatePaymentSum();
    }

    public onClickCloneSum(item) {
        this.resetPartSum = true;
        this.setPartSum(item);
        this.resetPartSum = false;
    }

    public onClickGoToReportsSelectionStep() {
        this.uiStep = this.UI_STEP_REPORTS;
    }

    public onClickGoToPaymentInfoStep() {
        this.uiStep = this.UI_STEP_PAYMENT;
        this.syncFormParts();
    }

    public onClickSave() {
        this.loading = true;
        const payload = this.form.value;
        payload.payment_order_date = this.datePipe.transform(payload.payment_order_date, 'yyyy-MM-dd');

        this.api.createBankPaymentOrder(payload).subscribe(data => {
            this.createdBankPaymentOrder = data;
            this.uiStep = this.UI_STEP_DONE;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    public onClickDone() {
        this.hideModal();
    }

    private hideModal() {
        this.opened = false;
        this.parent.onClose();
    }

    private updateForClient() {
        if (!this.selectedClient) {
            this.data = [];
            this.displayedData = [];
            this.form.controls.client_id.setValue(null);
            return;
        }

        if (!this.selectedClient.full_name_uid) {
            this.selectedClient.full_name_uid = `[${this.selectedClient.client_uid_old}] ${this.selectedClient.name}`;
        }

        const reload = (this.clientId !== this.selectedClient.id);
        if (reload) {
            this.clientId = this.selectedClient.id
            this.form.controls.client_id.setValue(this.clientId);
            // const setting = this.helpers.getClientSetting(this.selectedClient.settings, 'separate_cod_transfer_by_payment_type');
            this.form.controls.cod_type.setValue(this.COD_IGNORE);

            this.getDataForNewBankPaymentOrder();
        }
    }

    private getDataForNewBankPaymentOrder() {
        this.resetPartSum = true;
        if (!this.clientId) {
            return;
        }

        this.loading = true;

        this.api.getDataForNewBankPaymentOrder(this.clientId).subscribe(data => {
            let hasNonSeparated = false;
            let hasSeparated = false;
            this.data = data.map(item => {
                item.x_checked = false;
                item.x_disabled = false;
                item.x_sum = 0.0;

                if (item.is_cod_transfer_separated_by_type) {
                    hasSeparated = true;
                }

                if (!item.is_cod_transfer_separated_by_type) {
                    hasNonSeparated = true;
                }

                return item;
            });

            this.hasSeparated = hasSeparated;
            this.hasNonSeparated = hasNonSeparated;
            this.form.controls.cod_type.setValue(this.COD_IGNORE);

            this.updateDisplay();

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    private updateDisplay() {
        this.displayedData = this.data.map(item => {
            item.x_checked = false;
            switch (this.form.value.cod_type) {
                case this.COD_CASH:
                    item.x_max = item.max_transfer_cash;
                    break;
                case this.COD_CARD:
                    item.x_max = item.max_transfer_cashless;
                    break;
                case this.COD_MIXED:
                    item.x_max = item.max_transfer_mixed;
                    break;
            }

            this.setPartSum(item);

            return item;
        }).filter(item => {
            return (!this.onlySelectedAgentReports || -1 !== this.agentReportsIds.indexOf(item.id))
                && (
                    (this.form.value.cod_type === this.COD_IGNORE)
                    || (this.form.value.cod_type === this.COD_MIXED
                        && !item.is_cod_transfer_separated_by_type
                    )
                    || (this.form.value.cod_type
                        && this.form.value.cod_type !== this.COD_MIXED
                        && item.is_cod_transfer_separated_by_type
                    )
                )
        });

        const data = this.displayedData;
        this.resetPartSum = false;

        this.summarySumCod = data.map(item => item.sum_np).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumCodCash = data.map(item => item.sum_cod_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumCodCashless = data.map(item => item.sum_cod_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumTariff = data.map(item => item.sum_tariff_total_with_nds).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumTransferredCod = data.map(item => item.transferred_mixed).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumTransferredCodCash = data.map(item => item.transferred_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumTransferredCodCashless = data.map(item => item.transferred_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.summarySumRestCod = data.map(item => item.rest_cod_mixed).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumRestCodCash = data.map(item => item.rest_cod_cash).reduce((sum, val) => {
            return sum + val;
        }, 0.0);
        this.summarySumRestCodCashless = data.map(item => item.rest_cod_cashless).reduce((sum, val) => {
            return sum + val;
        }, 0.0);

        this.updatePaymentSum();
        this.syncAllChecked();
    }

    private setPartSum(item) {
        switch (this.form.value.cod_type) {
            case this.COD_CASH:
                item.x_sum = this.resetPartSum ? item.rest_cod_cash : item.x_sum;
                break;
            case this.COD_CARD:
                item.x_sum = this.resetPartSum ? item.rest_cod_cashless : item.x_sum;
                break;
            case this.COD_MIXED:
                item.x_sum = this.resetPartSum ? item.rest_cod_mixed : item.x_sum;
                break;
            default:
                item.x_sum = 0.0;
        }

        item.x_disabled = !item.x_sum;
        if (item.x_disabled) {
            item.x_checked = false;
        }
    }

    private updatePaymentSum() {
        const paymentSum = this.displayedData
            .map(item => item.x_checked ? item.x_sum : 0.0)
            .reduce((sum, val) => sum + val, 0.0);

        this.form.controls.payment_sum.setValue(paymentSum);
    }

    private syncAllChecked() {
        const total = this.displayedData.length;
        const checked = this.displayedData.filter(item => item.x_checked).length;
        const withSum = this.displayedData.filter(item => item.x_sum).length;

        this.allChecked = (total === checked);
        this.allCheckedDisabled = !withSum;
    }

    private syncFormParts() {
        while ((this.form.controls.parts as FormArray).length !== 0) {
            (this.form.controls.parts as FormArray).removeAt(0)
        }

        this.displayedData.map(item => {
            if (!item.x_checked || !item.x_sum) {
                return;
            }

            (this.form.controls.parts as FormArray).push(new FormGroup({
                agent_report_id: new FormControl(item.id),
                payment_sum: new FormControl(item.x_sum),
            }));
        });
    }
}
