import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {
    ClientSettingKey,
    HubDeliveryInterval,
    Order,
    OrderGood,
    OrderSignature,
    OrderZone,
    Problem,
    SimilarOrderSignature,
    Template,
    User,
    OrderOption,
} from '../../../service/models';
import {CurrentUserService} from '../../../service/current-user.service';
import {DatePipe} from '@angular/common';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LaraService} from '../../../service/lara.service';
import {DaDataConfig, DaDataType} from '@kolkov/ngx-dadata';
import {environment} from '../../../../environments/environment';
import {DialogService} from '../../dialog/dialog.service';
import {OrderCardComponent} from '../order-card.component';
import {HelpersService} from '../../../service/helpers.service';
import {NotifierService} from '../../../service/notifier.service';
import {HubsService} from '../../../service/hubs.service';
import {AddressService} from '../../../service/address.service';
import {SubstatusesService} from '../../../service/substatuses.service';
import {Subject} from 'rxjs';
import {SkladService} from '../../../service/sklad.service';
import {ReturnOrderModalComponent} from '../../../storage/return-order-modal/return-order-modal.component';
import {detectChanges} from '@angular/core/src/render3';

@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.scss'],
})
export class OrderDialogComponent implements OnInit {
    @Output() onClosed = new EventEmitter<boolean>();
    @ViewChild('goodNameMark') public goodNameMark: ElementRef;
    @ViewChild('goodMark') public goodMark: ElementRef;
    @ViewChild('closeGoodSplitWizardModal') public closeGoodSplitWizardModal: ElementRef;
    @ViewChild('closeIntervalShiftEditorModal') public closeIntervalShiftEditorModal: ElementRef;

    // Переменные для проверки ПРАВ
    public rights = {
        isVerified: false,           // Кнопки редактирования Проверки
        removeProblem: false,        // Кнопка удаления проблемы у заказа
        returnStatusAccepted: false, // Кнопка возврата заказа в статус заказ подтвержден
        deleteShk: false,            // Удаление места
        importantRed: false,         // Кнопки редактирования Важности
        indexRed: false,             // Кнопка редактирования Индекса
        addressRed: false,           // Кнопка редактирования Адреса
        commentRed: false,           // Кнопка редактирования Комментария
        commentKsRed: false,         // Кнопка редактирования Комментария КС
        delivery_dateRed: false,     // Кнопка редактирования Даты Доставки
        delivery_timeRed: false,     // Кнопка редактирования Времени Доставки
        makeOrderDeliveredYesterday: false, // Кнопка сделать заказ доставленным за вчера
        courierRed: false,           // Кнопка редактирования Курьера
        weightRed: false,            // Кнопка редактирования Веса
        dimensionsRed: false,        // Кнопка редактирования Веса
        paymentTypeRed: false,       // Кнопка редактирования Заказа и ТП
        dopinfoCat: false,           // Кнопка ДОПИНФО
        tariffBreakdown: false,      // Вкладка Платёжные поля
        fullHistory: false,          // кнопка полная история заказа
        sendSmsCat: false,           // Кнопка Отправить СМС
        sendReceipt: false,          // Кнопка Отправить чек клиенту
        modalOrdersBtnRed: false,    // Кнопка модальное окно редактирования заказа
        modalOrdersRedStatus: false, // Сброс статуса
        modalOrdersRed: false,       // Модальное окно редактирования заказа
        specialLogs: false,          // Спец логи
        recalcBtn: false,            // Пересчёт заказа
        checkFix: false,             // Исправление чеков
        editRecipientName: false,    // Редактирование фактического получателя заказа
        toggleWeightValidated: false, // Вес проверен
        toggleAddressValidated: false, // Вес проверен
        reDelivery: false,            // Повторная доставка
        expectedReturnTime: false,    // Кнопка редактирования времени возврата на склад
        verifySignature: false,         // Верификация подписи
        reVerifySignature: false,       // Редактирование верифицированной подписи
        uploadFiles: false,             // Загрузка файлов
        changeExpireDate: false,        // Смена сроков хранения
    }
    public parent: OrderCardComponent;
    public index = 0;
    public width;
    // КОНЕЦ ПЕРЕМННЫХ ДЛЯ ПРОВЕРКИ ПРАВ
    public saving = false;
    public order: any;
    public orderModificated: any;
    public goodsCopy: any;
    public servicesCopy: any;
    public user: User;
    public zone: OrderZone;
    public BarCodeScanLog = [];
    public orders = [];
    public paymentData: any;
    public EditOrder: FormGroup;
    public sendMessage: FormGroup;
    public sendImMessage: FormGroup;
    public signOnMail: FormGroup;
    public dimensions: string[] = [];
    public substatuses;
    public intervalSubstatuses: [];
    public changeAddressSubstatuses: [];
    public documents = null;
    public mainAndExcessActs = null;
    public barcode_id = [];
    public selectedCourier;
    public loadBtn = false;
    public loadImportant = false;
    public loadIsVerified = false;
    public loadStatus = false;
    public loadSetAbandonedStatus = false;
    public loadSms = false;
    public sendImInProgress = false;
    public signatures: OrderSignature[] = [];
    public selectedSignatures: OrderSignature[] = [];
    public clientHasDisplayRecipientsSignaturesOption = false;
    public hasSignature = false;
    public hasSignatures = false;
    public hasVerifiedSignature = false;
    public signaturesLoaded: boolean | null = null;
    public showSignatureStatus = false;
    public effectiveSignatureId: number = null;
    public setEffectiveOrderSignatureEnabled: boolean = false;
    public markOrderSignatureVerifiedEnabled: boolean = false;
    public isLoadSign = false;
    public intervalTabs = 1;
    public resentTypeTabs = 1;
    public configDaData: DaDataConfig = {
        apiKey: environment.dadata_api_key,
        type: DaDataType.address,
    };
    public returnActs: string[] = [];
    BarCodeScanLogLoaded = false;
    loadRecalc = false;
    modalEdit = '';
    num = 0;
    optionsArray = {};
    newStatus;
    courMesTemps;
    push_header;
    push_desc;
    /* Для чеков */
    receiptsList;
    receiptsListDescription;
    checkLoad = false;
    public changeGood = false;
    public changeOpt = false;
    public kgt_lifting = 1; // значение по умолчанию в диалоге подъем КГТ
    public receiptUrl: string = null; // url чека
    public receiptUrlShort: string = null; // короткий url чека
    public receiptUrlExist = false; // url чека существует?
    public hubDeliveryIntervals: HubDeliveryInterval[] = [];
    public invalidDeliveryInterval = false;
    public deliveryInterval = null;

    public selectedDeliveryTime: number = null;
    public orderTransits = [];
    public orderTransitsLoading = false;
    public setStatusAcceptedLoading = false;
    public toggleWeightValidatedConfirmed = false;
    public toggleAddressValidatedConfirmed = false;
    public smsTemplates = [];
    public iMTemplates = [];
    public locationMapVisible = true;
    public locationMapOpened = false;
    public loadingLocationAddressRequiresCall = false;
    /** EAN уникальные номера товара */
    public eansGood = [];
    public paymentMismatch: boolean = false;
    public showSimilarSignatures = false;
    public similarSignaturesLoadedOnce = false;
    public similarSignaturesLoading = false;
    public similarSignaturesState = {
        take: 10,
        skip: 0,
        filter: '',
    }
    public similarSignatures: SimilarOrderSignature[] = [];
    public similarSignaturesTotal: number = 0;
    public deliveriesLoading = false;
    public deliveries = [];
    public template = Template;
    @ViewChild('emailReceipt') protected emailReceipt: ElementRef;
    @ViewChild('mobileNumberReceipt') protected mobileNumberReceipt: ElementRef;
    @ViewChild('closeModal') private closeModal;
    private value: any = {};
    public logReaderDisplay: boolean = false;
    public readonly ORDER_GOOD = OrderGood;

    public canMarkLostGoods = false;
    public canAbandonedStatusGoods = false;

    public isMultiplaceReturnAvailable = false;
    public isReturnsAvailable = false;

    public deliveriesId = null;
    public isNeedPretension: boolean = false;
    public isFileUploadVisible: boolean = false;

    public installationServiceStatus: null|string = null;
    public installationServiceClasses: null|string = null;

    public goodSplitWizardInitialCounter = 0;
    public goodSplitWizardForm: FormArray = new FormArray([]);
    public goodSplitWizardFormSum = 0;
    public goodSplitWizardFormDelta = 0;
    public goodSplitWizardFormValid = true;
    public goodSplitWizardFormMaxes: number[] = [];
    public goodSplitWizardLocked = false;
    private goodSplitWizardIndex: null|number = null;
    public problemsBadgeText = '';
    public problemsBadgeClasses = '';
    public intervalShiftEditorLoading = false;
    public intervalShiftEditorAvailable = false;
    public intervalShiftInfo = null;
    public intervalShiftEditForm: FormGroup;
    public intervalShiftRollbackForm: FormGroup;

    public isPickupPointShipmentAvailable = false;
    public hasNonGoodsWithPickupPointShipmentEnabled = false;
    public hasInvalidPaymentMethodForPickupPointShipment = false;

    public storageDateOverdue?: number;
    public storageDateOverdueClasses?: string;
    public pickupPointStorageDateOverdue?: number;
    public pickupPointStorageDateOverdueClasses?: string;
    public pickupPointCanStartDelivery?: boolean;
    public pickupPointNeedSms?: boolean;
    public servicesByCategories = null;
    private servicesTypes = [];
    public isHonorOrderVisibilityInApi = false;
    public loadingSetVisibilityForApi = false;

    constructor(
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        private datePipe: DatePipe,
        protected api: LaraService,
        protected apiSclad: SkladService,
        protected apiAddress: AddressService,
        public dialogSrv: DialogService,
        public helper: HelpersService,
        private hubsService: HubsService,
        private notifier: NotifierService,
        private substatusesService: SubstatusesService,
        private changeDetector: ChangeDetectorRef,
        private formBuilder: FormBuilder,
    ) {
        this.currentUser.get().subscribe((user: User) => {
            this.user = user;
        });
        this.width = 1000;

        this.sendMessage = new FormGroup({
            message: new FormControl(),
        });

        this.sendImMessage = new FormGroup({
            title: new FormControl(),
            message: new FormControl(),
        });

        this.signOnMail = new FormGroup({
            email: new FormControl('', Validators.required),
        });

        this.intervalShiftEditForm = new FormGroup({
            change_interval_reason: new FormControl(null, Validators.required),
            problem_to_update: new FormControl(null),
        });

        this.intervalShiftRollbackForm = this.formBuilder.group({
            data_log_to_rollback: new FormControl(null, Validators.required),
            problems_to_delete: this.formBuilder.array([]),
        });
    }

    ngOnInit() {
        this.getServicesByCategories();
        this.checkRights();
        if (!this.helper.checkPermissions('orders:view')) {
            this.onClose();
        }
        if (this.order.status === Order.STATUS_REFUSE || this.order.status === Order.STATUS_CANCEL) {
            this.canAbandonedStatusGoods = true;
        }

        this.isMultiplaceReturnAvailable = this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.IS_MULTIPLACES_RETURNS_ENABLED);
        this.isReturnsAvailable = this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.ALLOW_CLIENT_RETURN);
        this.isPickupPointShipmentAvailable = this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.PICKUP_POINT_SHIPMENT_AVAILABLE);
        this.isHonorOrderVisibilityInApi = !!this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.HONOR_ORDER_VISIBILITY_IN_API);

        this.canMarkLostGoods = this.helper.checkPermissions('storage:lost-orders-admin');

        this.hubDeliveryIntervals = this.order.hub_destination.delivery_intervals;

        let expectedReturnDate = null;
        let expectedReturnTime = null;
        if (this.order && this.order.storage_data && this.order.storage_data.expected_return_time) {
            expectedReturnDate = this.datePipe.transform(this.order.storage_data.expected_return_time, 'yyyy-MM-dd')
            expectedReturnTime = this.datePipe.transform(this.order.storage_data.expected_return_time, 'HH:mm')
            this.isNeedPretension = this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.IS_NEED_PRETENSION_ON_FULL_REFUZE)
                && (this.order.status === Order.STATUS_REFUSE);

        }
        this.EditOrder = new FormGroup({
            returnTime: new FormControl(expectedReturnTime),
            returnDate: new FormControl(expectedReturnDate),
            address: new FormControl(),
            office: new FormControl(),
            intercom: new FormControl(),
            post_code: new FormControl(),
            delivery_time: new FormControl(1),
            delivery_time1: new FormControl('10'),
            delivery_time2: new FormControl('14'),
            delivery_date: new FormControl(),
            is_sameday: new FormControl(),
            redelivery_date: new FormControl(),
            substatus: new FormControl(),
            dim1: new FormControl(),
            dim2: new FormControl(),
            dim3: new FormControl(),
            weight: new FormControl(),
            operator_comment: new FormControl(),
            client_comment: new FormControl(),
            status: new FormControl(),
            change_address_reason: new FormControl(),
            // RESEARCH-458
            interval_status: new FormControl(65),
            kgt_lift: new FormControl(this.order.target.lift),
            kgt_floor: new FormControl(this.order.target.floor),
            kgt_lifting: new FormControl(this.order.option.option_skid_kgt),
            // фактический получатель заказа
            recipient_name: new FormControl(''),
            recipient_name_clear: new FormControl(false),
            // Отправка чека клиенту
            email_resent_receipt: new FormControl(),
            number_resent_receipt: new FormControl(),
            //
            is_bulky: new FormControl(this.order.option.is_bulky),
            //
            storage_expire_date: new FormControl(this.order.storage_expire_date),
            pickup_point_free_storage_expire_date: new FormControl(this.order.pickup_point_free_storage_expire_date),
        });
        this.setNums();
        this.setReceipt();
        this.getOrderSubstatuses();
        this.orderModificated = this.order;
        this.orderModificated.option.count = this.order.hash_bar_codes_count - this.order.return_places_count
        this.setGoodsCopy();
        this.setServicesCopy();
        this.setPlacesArray();
        // платежи по карте показываем в любом случае
        this.getCardPayments();
        this.checkInterval();
        this.setEditOrderRecipientName();
        this.updateFormValues();
        this.updateInstallationServiceBadge();
        this.inspectProblems();
        this.updateIntervalShiftEditorAvailable();
        this.updateExpireDateDisplay();
    }

    /**
     * Получение шаблонов пушей
     * для отправки курьеру
     */
    getPushTemplates() {
        this.api.getPushTemplates().subscribe(data => {
            this.courMesTemps = data;
            this.courMesTemps = this.courMesTemps.filter(x => x.topic === 'Клиентский отдел');
        })
    }

    setTemplate(header, desc) {
        this.push_header = header;
        this.push_desc = this.order.uid + ': ' + this.assignTemplateVars(desc);
    }

    assignTemplateVars(desc) {
        desc = desc.replaceAll('{delivery_interval}', this.checkInterval());

        return desc;
    }

    public onChangeIsCalculatedDeliveryPrice() {
        if (!this.orderModificated.bill.is_calculated_delivery_price) {
            return;
        }
        const goodSum = this.calcPrice(this.goodsCopy, this.servicesCopy);
        const priceClientDelivery = (
            goodSum < this.order.price_client_delivery_rule.cost_of_redemption ?
                this.order.price_client_delivery_rule.price_delivery_before :
                this.order.price_client_delivery_rule.price_delivery_after
        );
        this.optionsArray['price_client_delivery'] = priceClientDelivery;
        this.orderModificated.bill.price_client_delivery = priceClientDelivery;
    }

    updTemplate(e, update) {
        switch (update) {
            case 'push_header': {
                this.push_header = e.target.value;
                break;
            }
            case 'push_desc': {
                this.push_desc = e.target.value;
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * Исправление чеков
     */
    correctReceipts() {
        this.checkLoad = true;
        this.api.correctReceipts(this.order.uid).subscribe(data => {
            this.receiptsList = data;
            this.checkLoad = false;
        })
    }

    /**
     * Получение чеков по заказу
     */
    getOrderReceipts() {
        this.api.getOrderReceipts(this.order.uid).subscribe(data => {
            this.receiptsList = data;
            this.receiptsListDescription = data.needReceiptDescription;

            for (const receipt of this.receiptsList.receipts) {
                if (receipt.type_key === 'return') {
                    receipt.fill_class = 'alert-info';
                } else if (receipt.canceled_by) {
                    receipt.fill_class = 'alert-danger'
                } else {
                    receipt.fill_class = '';
                }
            }
        })
    }

    /**
     * TODO рефактор
     * задает порядковый номер для товаров в заказеЮ заодно формирует массив возвратных актов для товаров заказа
     */
    setNums() {
        this.returnActs = [];
        let k = 1;
        for (let i = 0; i < this.order.bar_codes.length; i++) {
            for (let j = 0; j < this.order.bar_codes[i].goods.length; j++) {
                this.order.bar_codes[i].goods[j].index = k;
                k++;
            }
        }

        for (let i = 0; i < this.order.all_goods.length; i++) {
            if (this.order.all_goods[i].return_act) {
                this.add2Acts(this.order.all_goods[i].return_act.old_vact_id);
            }
        }
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000});
        })
    }

    sendMessagge(withParams = false, isSendOnEmail?, contactData?: string) {
        this.loadSms = true;

        if (!withParams) {
            this.api.sendOrderMessage(this.order.uid, this.sendMessage.value.message).subscribe(data => {
                alert('Сообщение отправлено!')
                this.sendMessage.patchValue({
                    message: '',
                })
                this.loadSms = false;
            }, error => {
                this.loadSms = false;
            })
        } else {
            if (isSendOnEmail) {
                this.api.sendOrderMessage(
                    this.order.uid,
                    this.sendMessage.value.message,
                    true,
                    isSendOnEmail,
                    contactData,
                    'Чек по заказу № ' + this.order.inner_n,
                ).subscribe(data => {
                    alert('Сообщение на почту отправлено!')
                    this.sendMessage.patchValue({
                        message: '',
                    })
                    this.loadSms = false;
                }, error => {
                    this.loadSms = false;
                })
            }
            if (!isSendOnEmail) {
                this.api.sendOrderMessage(
                    this.order.uid,
                    this.sendMessage.value.message,
                    true,
                    isSendOnEmail,
                    contactData,
                ).subscribe(data => {
                    alert('Сообщение на телефон отправлено!')
                    this.sendMessage.patchValue({
                        message: '',
                    })
                    this.loadSms = false;
                }, error => {
                    this.loadSms = false;
                })
            }
        }
    }

    setMessageValue(e) {
        this.sendMessage.patchValue({
            message: e.target.value,
        })
    }

    add2Acts(act_id) {
        if (this.returnActs.indexOf(act_id) < 0) {
            this.returnActs.push(act_id);
        }
    }

    checkStatus(status: number) {
        return this.helper.getNameOrderStatus(status);

        /* switch (status) {

             case 1: {
                 return 'Новая заявка';
             }
             case 2: {
                 return 'Заявка принята';
             }
             case 3: {
                 return 'На складе';
             }
             case 4: {
                 return 'На доставке';
             }
             case 5: {
                 return 'Доставлен';
             }
             case 6: {
                 return 'Частичный отказ';
             }
             case 7: {
                 return 'Полный отказ';
             }
             case 8: {
                 return 'Отмена';
             }
             case 9: {
                 return 'В перемещении';
             }
             case 10: {
                 return 'Отмена для возврата';
             }
             default: {
                 console.log('Invalid choice');
             }
         } */
    }

    saveChanges() {
        const query = this.EditOrder.value;

        if (this.modalEdit === 'address') {
            if (query.address && query.address.length >= 7 && query.change_address_reason) {
                return true;
            }
        } else if (this.modalEdit === 'office_and_intercom') {
            return true;
        } else if (this.modalEdit === 'expectedReturnTime') {

            return true;
        } else if (this.modalEdit === 'toggleIsBulky') {
            return true;
        } else if (this.modalEdit === 'weight') {
            if (query.weight) {
                return true;
            }
        } else if (this.modalEdit === 'toggleWeightValidated') {
            if (this.toggleWeightValidatedConfirmed) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'toggleAddressValidated') {
            if (this.toggleAddressValidatedConfirmed) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'editRecipientName') {
            if (this.EditOrder.value.recipient_name.trim().length || this.EditOrder.value.recipient_name_clear) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'interval') {
            if (this.intervalTabs === 1) {
                if (query.interval_status && query.delivery_time) {
                    return true;
                }
            }
            if (this.intervalTabs === 2) {
                if (query.interval_status && query.delivery_time1 && query.delivery_time2) {
                    return true;
                }
            }
        } else if (this.modalEdit === 'sendReceipt') {
            if (this.resentTypeTabs === 1) {
                if (this.emailReceipt &&
                    this.emailReceipt.nativeElement.value !== 'undefined' &&
                    this.emailReceipt.nativeElement.value) {
                    const value = this.emailReceipt.nativeElement.value;
                    if (value.length >= 1) {
                        return true;
                    }
                }
            }
            if (this.resentTypeTabs === 2) {
                if (this.mobileNumberReceipt &&
                    this.mobileNumberReceipt.nativeElement.value !== 'undefined' &&
                    this.mobileNumberReceipt.nativeElement.value) {
                    const value = this.mobileNumberReceipt.nativeElement.value;
                    if (value.length >= 1) {
                        return true;
                    }
                }
            }
        } else if (this.modalEdit === 'delivery_date') {
            if (query.delivery_date && query.substatus) {
                return true;
            }
        } else if (this.modalEdit === 'set_sameday') {
            if (query.is_sameday && query.substatus) {
                return true;
            }
        } else if (this.modalEdit === 'courier') {
            if (this.selectedCourier) {
                return true;
            }
        } else if (this.modalEdit === 'courierMessage') {
            if (this.push_header && this.push_desc) {
                return true;
            }
        } else if (this.modalEdit === 'operator_comment') {
            if (query.operator_comment) {
                return true;
            }
        } else if (this.modalEdit === 'client_comment') {
            if (query.client_comment) {
                return true;
            }
        } else if (this.modalEdit === 'post_code') {
            if (query.post_code.length === 6) {
                return true;
            }
        } else if (this.modalEdit === 'reDelivery') {
            if (query.redelivery_date) {
                return true;
            }
        } else {
            return true;
        }
    }

    getBarCodeScanLog() {
        this.api.getBarCodeScanLog(this.order.uid).subscribe((data: any) => {
            this.BarCodeScanLog = data;
            this.BarCodeScanLogLoaded = true;
        });
    }

    getMainAndExcessActs() {
        this.api.getMainAndExcessActs(this.order.uid).subscribe((data: any) => {
            this.mainAndExcessActs = data;
        })
    }

    getDocuments() {
        this.api.getDocuments(this.order.uid).subscribe((data: any) => {
            this.documents = data;
        });
    }

    checkInterval() {
        if (this.order.delivery_time) {
            const deliveryIntervalId = parseInt(this.order.delivery_time, 10);
            const deliveryInterval = this.order.hub_destination.delivery_intervals.find((interval) => {
                return interval.id === deliveryIntervalId;
            });
            if (deliveryInterval) {
                this.invalidDeliveryInterval = false;
                return this.deliveryInterval = deliveryInterval.label;
            }

            this.invalidDeliveryInterval = true;
            return this.deliveryInterval = 'Не передали интервал';
        } else {
            return this.deliveryInterval = this.order.delivery_time_start + ':00-' + this.order.delivery_time_end + ':00';
        }
    }

    option_opening(option: number) {
        switch (option) {
            case 1: {
                return 'Разрешено';
            }
            case 2: {
                return 'Только внешней упаковки заказа';
            }
            case 3: {
                return 'Запрещено';
            }
            default: {
                return 'Не указано';
            }
        }
    }

    ndsName(nds: number) {
        switch (nds) {
            case 1: {
                return 'HДС 18%';
            }
            case 2: {
                return 'Без HДС';
            }
            case 3: {
                return 'HДС 10%';
            }
            case 4: {
                return 'HДС 18/118';
            }
            case 5: {
                return 'HДС 10/110';
            }
            case 6: {
                return 'НДС 0%';
            }
            case 7: {
                return 'НДС 20%';
            }
            case 8: {
                return 'НДС 20/120';
            }
            default: {
                return 'Н/У';
            }
        }
    }

    getOrderSubstatuses() {
        this.substatusesService.get(false, [0, 3]).then(data => {
            this.substatuses = data;
        });

        this.substatusesService.get(false, [6]).then(data => {
            this.intervalSubstatuses = data;
        });

        this.substatusesService.get(false, [7]).then(data => {
            this.changeAddressSubstatuses = data;
        });
    }


    getOrderSignature() {
        this.resetSignaturesStatus();

        this.api.getOrderSignatures(this.order.uid).subscribe(orderSignatures => {
            this.processLoadedSignatures(orderSignatures);
        });
    }

    getCardPayments() {
        this.api.getOrderCardPayments(this.order.id).subscribe(data => {
            this.paymentData = data;
            this.checkPayment();
        });
    }

    changeStatus(e) {
        // console.log(e.target.value);
        this.newStatus = e.target.value;
    }

    setNewStatus() {
        this.loadStatus = true;
        this.saving = true;
        if (this.newStatus == 3) {
            this.api.setStatusStorage(this.order.uid).subscribe(data => {
                this.finishSave(data);
                // this.setGoodsCopy();
                this.loadStatus = false;
                this.newStatus = 0;
            }, error => {
                this.loadStatus = false;
                this.saving = false;
            })
        } else if (this.newStatus == 4) {
            this.api.setStatusDelivery(this.order.uid).subscribe(data => {
                this.finishSave(data);
                //this.setGoodsCopy();
                this.loadStatus = false;
                this.newStatus = 0;
            }, error => {
                this.loadStatus = false;
                this.saving = false;
            })
        } else {
            alert('Статус не выбран');
        }
    }

    calcPrice(goods, services) {
        let sum = 0;
        for (let i = 0; i < goods.length; i++) {
            if (!goods[i].is_cancel) {
                if (!goods[i].price || !goods[i].count) {
                    continue;
                }
                sum += goods[i].price * goods[i].count;
            }
        }

        if (services) {
            for (let i = 0; i < services.length; i++) {
                if (!services[i].price || !services[i].quantity) {
                    continue;
                }
                sum += services[i].price * services[i].quantity;
            }
        }

        return sum;
    }

    calcDeclaredPrice(goods) {
        let sum = 0;
        for (let i = 0; i < goods.length; i++) {
            sum += goods[i].declared_price
        }
        return sum;
    }

    getPlace(good, barcodes) {
        const places = [];
        for (let i = 0; i < barcodes.length; i++) {
            places[i].concat(barcodes[i])
        }
        return places;
    }

    itog(price: number, counts: number) {
        const allPrice = price * counts;
        return allPrice;
    }

    public hideModal() {
        this.closeModal.nativeElement.click();
    }

    public showEdit(what: string) {
        this.loadDataForModal(what);
        // копия интервала доставки для модального окна
        this.selectedDeliveryTime = this.order.delivery_time;
        this.saving = false;
        this.modalEdit = what;
    }

    public async finishSave(data) {
        data = await this.hubsService.defineOrderHubs(data);
        this.hubDeliveryIntervals = data.hub_destination.delivery_intervals;

        const deliveryIntervalId = data.delivery_time;
        const deliveryIntervalValid = this.hubDeliveryIntervals.find((interval) => {
            return interval.id === deliveryIntervalId;
        });

        if (!deliveryIntervalValid) {
            this.invalidDeliveryInterval = true;
        } else {
            this.invalidDeliveryInterval = false;
        }

        this.order = data;
        this.setGoodsCopy()
        this.setServicesCopy()
        this.setPlacesArray();

        this.setNums();
        this.hideModal();
        // зачем ? this.checkRights();
        this.checkInterval();
        this.setEditOrderRecipientName();
        this.updateFormValues();
        this.updateSignatureStatus();
        this.updateExpireDateDisplay();
        this.updateInstallationServiceBadge();
        this.inspectProblems();
        this.updateIntervalShiftEditorAvailable();

        this.saving = false;
    }

    updateFormValues() {
        this.EditOrder.get('is_sameday').setValue(this.order.is_sameday);
        this.EditOrder.get('office').setValue(this.order.target.office);
        this.EditOrder.get('intercom').setValue(this.order.target.intercom);
    }

    inspectProblems() {
        // TODO extract unresolved_problems from full problems list like this
        // this.order.unresolved_problems = this.order.problems.filter(problem => 0 == problem.processed);

        const unresolvedProblems = this.order.problems.filter(problem => 0 == problem.processed);
        const badgeTextItems = [this.order.problems.length];
        let badgeClasses = 'py-0 px-1 rounded ';

        if (unresolvedProblems.length) {
            badgeClasses += 'text-white bg-danger';
            badgeTextItems.unshift(unresolvedProblems.length);
        } else {
            badgeClasses += 'bg-white text-muted border border-muted';
        }

        this.problemsBadgeText = badgeTextItems.join(' / ');
        this.problemsBadgeClasses = badgeClasses;
    }

    getProblemRowClasses(problem) {
        return problem.processed ? '' : 'unresolved_problem';
    }

    updateOrder(setting) {
        const query = this.EditOrder.value;

        this.dimensions = [
            (query.dim1 != null) ? query.dim1 : this.order.option.dimension_side1,
            (query.dim2 != null) ? query.dim2 : this.order.option.dimension_side2,
            (query.dim3 != null) ? query.dim3 : this.order.option.dimension_side3,
        ];
        const address = query.address;
        this.saving = true;
        switch (setting) {
            case 'expectedReturnTime': {
                const res = {
                    expected_return_time: this.datePipe.transform(query.returnDate, 'yyyy-MM-dd') + ' ' + query.returnTime + ':00',
                }


                this.api.setExpectedReturnTimeOrder(this.order.uid, res).subscribe(data => {
                    this.finishSave(data);
                    this.saving = false;
                    this.hideModal();
                }, error => {
                    this.saving = false;
                });

                break;
            }
            case 'address': {
                if (address) {
                    if (address.length <= 7) {
                        alert('Адрес слишком короткий, данные не сохранены')
                        break;
                    }
                    if (!query.change_address_reason) {
                        alert('Не указана причина');
                        break;
                    }
                    this.api.setAddressOrder(this.order.uid, query.address, query.change_address_reason).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'office_and_intercom':
                this.api.setOfficeAndIntercomOrder(this.order.uid, query.office, query.intercom).subscribe(data => {
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });
                break;

            case 'weight': {
                if (query.weight) {
                    this.api.setWeightOrder(this.order.uid, query.weight).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                break;
            }
            case 'toggleWeightValidated': {
                if (this.toggleWeightValidatedConfirmed) {
                    this.api.setOrderWeightValidated(this.order.uid, !this.order.option.weight_validated).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                this.toggleWeightValidatedConfirmed = false;

                break;
            }

            case 'toggleIsBulky':
                this.saving = true;
                this.api.setOrderIsBulky(this.order.uid, this.EditOrder.value.is_bulky).subscribe(data => {
                    this.finishSave(data);
                    this.saving = false;
                }, () => {
                    this.saving = false;
                });
                break;

            case 'toggleAddressValidated': {
                if (this.toggleAddressValidatedConfirmed) {
                    this.api.setOrderAddressValidated(this.order.uid).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                //   this.toggleWeightValidatedConfirmed = false;

                break;
            }
            case 'editRecipientName':
                this.api.updateRecipientName(
                    this.order.uid,
                    this.EditOrder.value.recipient_name,
                    this.EditOrder.value.recipient_name_clear,
                ).subscribe(data => {
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });

                break;
            case 'dimension': {
                if (this.dimensions) {
                    this.api.setDimensionOrder(this.order.uid, this.dimensions).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'interval': {
                if (!query.interval_status) {
                    alert('Выберите причину смены интервала');
                    this.saving = false;
                    return;
                }
                // записываем значение из переменной связанной с модальным окном
                query.delivery_time = this.selectedDeliveryTime;
                if ((query.delivery_time) || (query.delivery_time1 && query.delivery_time2)) {
                    if (this.intervalTabs === 1) {
                        query.delivery_time = query.delivery_time ? query.delivery_time : this.order.delivery_time
                        this.api.setIntervalOrder(this.order.uid, query.delivery_time, query.interval_status).subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        });
                    } else if (this.intervalTabs === 2) {
                        this.api.setCustomInterval(this.order.uid, query.delivery_time1, query.delivery_time2,
                            query.interval_status).subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        })
                    }
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }

            case 'set_sameday': {
                if (!query.substatus) {
                    return;
                }

                if (query.is_sameday) {
                    this.api.setSamedayDelivery(this.order.uid, {'substatus': query.substatus})
                        .subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        })

                    return;
                }

                break;
            }

            case 'delivery_date': {
                if (!query.substatus) {
                    return;
                }

                if (query.delivery_date) {

                    this.api.setDeliveryDateOrder(this.order.uid, this.datePipe.transform(
                        query.delivery_date, 'yyyy-MM-dd'), query.substatus).subscribe(data => {
                        this.finishSave(data);
                        this.parent.DialogOnTransfer();
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'sendReceipt': {
                if (query.email_resent_receipt || query.number_resent_receipt) {
                    if (this.resentTypeTabs === 1) {
                        /* Почта */
                        this.sendMessage.value.message = 'Чек по вашему заказу № ' + this.order.inner_n + ' ' + this.receiptUrl;
                        this.sendMessagge(true, true, query.email_resent_receipt);
                    }
                    if (this.resentTypeTabs === 2) {
                        /* Телефон */
                        this.sendMessage.value.message = 'Чек по заказу ' + this.order.inner_n + ' ' + this.receiptUrlShort;
                        this.sendMessagge(true, false, query.number_resent_receipt);
                    }
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'reDelivery': {
                if (query.redelivery_date) {
                    this.api.reDeliveryOrder(this.order.uid, this.datePipe.transform(
                        query.redelivery_date, 'yyyy-MM-dd')).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    })
                }
                break;
            }
            case 'courier': {
                const order = [this.order.uid];
                if (this.selectedCourier) {
                    this.api.updateCourierOrder(order, this.selectedCourier.id).subscribe(data => {

                        if (data.changedOrders && data.changedOrders.length > 0) {
                            this.finishSave(data.changedOrders[0]);
                        }
                        if (data.errors) {
                            alert(data.errors);
                        }
                        this.saving = false;
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'courierMessage': {
                if (this.push_header && this.push_desc) {
                    this.api.sendPushNotification(this.order.courier_id, this.push_header, this.push_desc)
                        .subscribe(data => {
                            this.hideModal();
                            this.saving = false;
                        }, error => {
                            this.saving = false;
                        })
                }
                break;
            }
            case 'courierMessageSMS': {
                if (this.push_header && this.push_desc) {
                    this.api.sendCourierSmsNotification([this.order.courier_id], this.push_desc, this.order.uid)
                        .subscribe(data => {
                            this.hideModal();
                            this.saving = false;
                        }, error => {
                            this.saving = false;
                        })
                }
                break;
            }
            case 'operator_comment': {
                if (query.operator_comment) {
                    this.api.setOperatorComment(this.order.uid, query.operator_comment).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'client_comment': {
                if (query.client_comment) {
                    const dataq = {target_notes: query.client_comment}
                    this.api.editOrder(this.order.uid, dataq).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'post_code': {
                if (query.post_code && query.post_code.length === 6) {
                    const dataq = {post_code: query.post_code}
                    this.api.editOrder(this.order.uid, dataq).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    alert('Указан некорректный индекс');
                    this.saving = false;
                }
                break;
            }
            case 'payment_cash': {
                const dataq = {is_card: this.order.bill.payment_cash ? 1 : 0}
                this.api.setPaymentType(this.order.uid, dataq).subscribe(data => {
                    // если были платежи по карте, показываем их в любом случае
                    this.getCardPayments();
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });
                break;
            }
            case 'kgt_request': {
                //if (!query.kgt_floor) {
                //    query.kgt_floor = this.order.target.floor;
                //}

                //if (query.kgt_lifting === null) {
                //    query.kgt_lifting = this.kgt_lifting;
                //}
                //if (query.kgt_lift === null) {
                //   query.kgt_lift = this.order.target.lift;
                //}
                //if ((query.kgt_floor !== null) || (query.kgt_lifting == null) || (query.kgt_lift == null)) {
                console.log('kgt: floor lift lifting', query.kgt_floor, query.kgt_lift, query.kgt_lifting);
                this.api.setKGT(this.order.uid, query.kgt_floor, query.kgt_lift, query.kgt_lifting).subscribe(data => {
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });
                //}
                break;
            }
            case 'storage_expire_date':
                this.api.setOrderStorageExpireDate(this.order.uid, {
                    storage_expire_date: this.datePipe.transform(query.storage_expire_date, 'yyyy-MM-dd'),
                }).subscribe(data => {
                    this.finishSave(data);
                }, () => {
                    this.saving = false;
                });
                break;
            case 'pickup_point_free_storage_expire_date':
                this.api.setOrderPickupPointFreeStorageExpireDate(this.order.uid, {
                    pickup_point_free_storage_expire_date: this.datePipe.transform(query.pickup_point_free_storage_expire_date, 'yyyy-MM-dd'),
                }).subscribe(data => {
                    this.finishSave(data);
                }, () => {
                    this.saving = false;
                });
                break;
            default: {
                alert('Что-то пошло не так, обратитесь в техподдержку')
                break;
                this.saving = false;
            }
        }
    }

    /**
     * Получение списка транзитов для мест заказа
     */
    getOrderTransits() {
        this.orderTransitsLoading = true;
        this.api.getOrderTransits(this.order.uid).subscribe(data => {
            this.orderTransits = data;
            this.orderTransitsLoading = false;
        });
    }

    checkRights() {
        const fullHistoryAccess = this.helper.checkPermissions('orders:full-history');
        const operator = this.helper.checkPermissions('orders:change');
        const chiefOperator = this.helper.checkPermissions('orders:change-critical');
        const changePayment = this.helper.checkPermissions('orders:change-payment-type');
        const dopinfo = this.helper.checkPermissions('admin:dopinfo');
        this.rights.tariffBreakdown = this.helper.checkPermissions('orders:view-tariff-breakdown');
        this.rights.checkFix = this.helper.checkPermissions('orders:correct-check');

        this.rights.reDelivery = this.helper.checkPermissions('orders:re-delivery');
        this.rights.importantRed = this.helper.checkPermissions('orders:make-important');
        this.rights.isVerified = this.helper.checkPermissions('orders:change-is-verified');

        this.rights.verifySignature = this.helper.checkPermissions('orders:verify-signature');
        this.rights.removeProblem = this.helper.checkPermissions('orders:remove-problem')
        this.rights.returnStatusAccepted = this.helper.checkPermissions('orders:return-status-accepted')
        this.rights.deleteShk = this.helper.checkPermissions('orders:remove-place')
        this.rights.reVerifySignature = this.helper.checkPermissions('orders:re-verify-signature');

        const status = this.order.status < 5 || this.order.status === 9; // Конечный статус или перемещение между хабами
        if (operator) {
            this.rights.indexRed = status;                  // Кнопка редактирования Индекса
            this.rights.addressRed = true;                  // Кнопка редактирования Адреса
            this.rights.commentRed = status;                // Кнопка редактирования Комментария
            this.rights.commentKsRed = true;                // Кнопка редактирования Комментария КС
            this.rights.delivery_dateRed = status;          // Кнопка редактирования Даты Доставки
            this.rights.delivery_timeRed = status;          // Кнопка редактирования Времени Доставки
            this.rights.courierRed = status;                // Кнопка редактирования Курьера
            this.rights.weightRed = true;                   // Кнопка редактирования Веса
            this.rights.dimensionsRed = true;               // Кнопка редактирования Габаритов
            this.rights.sendReceipt = true;                 // Кнопка Отправить чек клиенту
            this.rights.sendSmsCat = status;                // Кнопка Отправить СМС
            this.rights.modalOrdersBtnRed = status;         // Кнопка модальное окно редактирования заказа
            this.rights.modalOrdersRed = status;            // Модальное окно редактирования заказа
            this.rights.specialLogs = false;                // Спец логи
            this.rights.recalcBtn = true;                   // Спец логи
            this.rights.modalOrdersRedStatus = status;      // Кнопка редактирования Статус
            this.rights.expectedReturnTime = true;          // Кнопка редактирования времени возврата на склад

        }

        // костыль на запрет редактирования даты доставки самыми одарёнными
        if (!this.helper.checkPermissions('orders.delivery_date:edit')) {
            this.rights.delivery_dateRed = false;
        }

        // can:orders:make-important
        if (chiefOperator && changePayment) {
            this.rights.specialLogs = true;                  // Спец логи
//            this.rights.importantRed = status;                 // Кнопка редактирования Важность
            this.rights.modalOrdersBtnRed = true;            // Кнопка модальное окно редактирования заказа
            this.rights.modalOrdersRedStatus = true;         // Кнопка редактирования Статус
            this.rights.paymentTypeRed = true;               // Кнопка Смены Типа оплаты
            this.rights.makeOrderDeliveredYesterday = true;  // Сделать заказ доставленным за вчера
        }
        if (dopinfo) {
            this.rights.dopinfoCat = true;                   // Кнопка Допинфо
            this.rights.specialLogs = true;                  // Спец логи
            this.rights.tariffBreakdown = true;
        }

        // Доступ к полной истории заказа
        if (fullHistoryAccess) {
            this.rights.fullHistory = true;
        }

        this.rights.changeExpireDate = chiefOperator;

        // Вес проверен
        const canToggleWeightValidatedOnDelivered = this.helper.checkPermissions('orders:toggle-weight-validated-on-delivered-orders');
        const canToggleWeightValidated = this.helper.checkPermissions('orders:toggle-weight-validated')
            || canToggleWeightValidatedOnDelivered;

        this.rights.toggleWeightValidated = (
            (status && canToggleWeightValidated)
            ||
            (!status && canToggleWeightValidatedOnDelivered)
        );

        // Редактирование фактического получателя заказа
        this.rights.editRecipientName = (!status && this.helper.checkPermissions('orders:edit-recipient-name'));

        // Загрузка файлов
        this.rights.uploadFiles = this.helper.checkPermissionsAny([
            'files:order-pretension:upload',
            'storage:lost-orders-admin',
        ]);
    }

    openTabs(index) {
        this.index = index;
        switch (index) {
            /** История */
            case 1:
                this.logReaderDisplay = true;
                break;
            /** Документы */
            case 2:
                this.getDocuments();
                this.getBarCodeScanLog();
                this.getMainAndExcessActs();
                break;
            /** Подпись */
            case 3:
                this.getOrderSignature();
                break;
            /** Перемещения */
            case 4:
                this.getOrderTransits();
                break;
            /** СМС */
            case 5:
                this.getSmsTemplates()
                break;
            /** Чеки */
            case 7:
                this.getOrderReceipts();
                break;
            /** Попытки доставки */
            case 10:
                this.getOrderDeliveries();
                break;
            case 12:
                this.getImTemplates()
                break;
        }
    }

    IntervalTabs(index) {
        this.intervalTabs = index;
    }

    ResentTypeTabs(index) {
        this.resentTypeTabs = index;
    }

    recalc() {
        this.loadRecalc = true;
        this.api.orderRecalc(this.order.uid).subscribe(data => {
            this.finishSave(data);
            this.loadRecalc = false;
        }, error => {
            this.loadRecalc = false;
        })
    }

    checkPayment() {
        if ((this.order.status === 5 || this.order.status === 6)
            && this.order.bill.payment_card > 0
            && (this.order.bill.np !== this.paymentData.sum)
        ) {
            this.paymentMismatch = true;
        } else {
            this.paymentMismatch = false;
        }
    }

    setGoodsCopy() {
        this.goodsCopy = this.order.all_goods.slice();
    }

    setServicesCopy() {
        this.servicesCopy = this.order.services.slice();
    }

    setNewInfoOrder(uid) {
        this.api.getOrder(this.order.uid).subscribe(async (data: Order) => {
            this.order = await this.hubsService.defineOrderHubs(data);

            this.goodsCopy = data.all_goods.slice();
            this.servicesCopy = data.services.slice();
            this.setNums();
        });
    }

    public selected(value: any): void {
        console.log('Selected value is: ', value);
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

    onSelectUser(user: User) {
        this.selectedCourier = user;
    }

    setImportantOrder(set) {
        this.loadImportant = true;
        const data = {is_important: set};
        this.api.makeImportant(this.order.uid, data).subscribe(data => {

            this.order = data;
            this.loadImportant = false;

        }, error => {
            this.loadImportant = false;
        })
    }

    /**
     * Изменение признака проверки заказа
     *
     * @param value
     */
    changeIsVerified(value) {
        this.loadIsVerified = true;
        this.api.changeIsVerified(this.order.uid, value).subscribe(data => {
            this.order = data;
            this.loadIsVerified = false;
        }, error => {
            this.loadIsVerified = false;
        })
    }

    /**
     * Можно ли включить sameday
     */
    samedayCanBeEnabled() {
        const now = new Date();

        if (this.order.client.is_sameday_possible
            && this.order.delivery_date === this.datePipe.transform(now, 'yyyy-MM-dd')
            && now.getHours() < Order.MAX_HOUR_CREATE_SAMEDAY_DELIVERY
        ) {
            return true;
        }

        return false;
    }

    checkVolumeWeight() {
        if (this.order.hub_destination_id !== 2) {
            return false;
        }
        if (this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.CALC_USE_WEIGHT)) {
            return false;
        }

        if (((this.order.option.dimension_side1 * this.order.option.dimension_side2 * this.order.option.dimension_side3) / 5000) > this.order.option.weight) {
            return true;
        } else {
            return false;
        }
    }

    checkEmail() {
        return ((this.order.client.finance_email != null) && (this.order.client.finance_email != ''));
    }

    sendEmailToImUserType(type) {
        this.loadBtn = true;
        this.api.emailToImUseType({
            order_id: this.order.id,
            type: type,
        }).subscribe(data => {
            this.loadBtn = false;
            this.order.email_counter++;
            this.notifier.openNotifier('Сообщение успешно отправлено');
        }, error => {
            this.loadBtn = false;
        })
    }

    /**
     * инициализирует массив с местами
     */
    setPlacesArray() {
        this.barcode_id = []
        for (const barcode of this.order.bar_codes) {
            this.barcode_id.push(barcode.id);
        }
    }

    addGoodOrder() {
        const goodTemplate = {
            name: '',
            weight: 0,
            count: 1,
            price: 0,
            is_cancel: 0,
            type: OrderGood.TYPE_GOOD,
            nds: 2,
            bar_code_id: this.barcode_id[0],
        };
        this.goodsCopy.push(goodTemplate);
        this.resetPlacesCount()
    }

    addServiceOrder() {
        const serviceTemplate = {
            service_id: 0,
            details: '',
            tax_type: 2,
            quantity: 1,
            price: 0,
            declared_price: 0,
        };
        this.servicesCopy.push(serviceTemplate);
    }

    public resetPlacesCount() {
        const returnGoodsCount = this.goodsCopy.filter(good => good.type === OrderGood.TYPE_CLIENT_RETURN).length;
        const goodsCount = this.goodsCopy.filter(good => good.type === OrderGood.TYPE_GOOD).length;
        this.orderModificated.return_places_count = returnGoodsCount;

        if ((this.orderModificated.option.count > 0) && (goodsCount == 0)) {

            this.orderModificated.option.count = 0;
        } else if ((this.orderModificated.option.count === 0) && (goodsCount > 0)) {
            this.orderModificated.option.count = 1;
        }
        this.changeDetector.detectChanges();
    }

    editGoodOrder(event, index, key) {
        switch (key) {
            case 'name' : {
                this.goodsCopy[index].name = event.target.value;
                break;
            }
            case 'count' : {
                this.goodsCopy[index].count = event.target.value;
                break;
            }
            case 'vendor_code' : {
                this.goodsCopy[index].vendor_code = event.target.value;
                break;
            }
            case 'price' : {
                this.goodsCopy[index].price = event.target.value;
                break;
            }
            case 'nds' : {
                this.goodsCopy[index].nds = event.target.value;
                break;
            }
            case 'type' : {
                this.goodsCopy[index].type = parseInt(event.target.value, 0);
                this.resetPlacesCount();
                break;
            }
            case 'declared_price' : {
                this.goodsCopy[index].declared_price = parseFloat(event.target.value);
                break;
            }
            case 'color' : {
                this.goodsCopy[index].color = event.target.value;
                break;
            }
            case 'size' : {
                this.goodsCopy[index].size = event.target.value;
                break;
            }
            case 'bar_code_id' : {
                this.goodsCopy[index].bar_code_id = event.target.value;
                break;
            }

            default: {
                alert('Нераспознанное значение');
            }
        }
    }

    deleteGood(index) {
        this.goodsCopy.splice(index, 1);
        this.resetPlacesCount();
    }

    /**
     * Запуск помощника разделения товарной позиции
     * @param index
     */
    goodSplitWizardBegin(index) {
        this.setGoodsCopy();

        const good = this.goodsCopy[index];
        if (good.type !== this.ORDER_GOOD.TYPE_INSTALLATION_SERVICE) {
            return;
        }

        this.goodSplitWizardIndex = index;
        this.goodSplitWizardInitialCounter = good.count;
        this.goodSplitWizardForm = new FormArray([]);
        this.goodSplitWizardForm.valueChanges.subscribe(() => {
            this.goodSplitWizardUpdateState();
        });

        this.goodSplitWizardLocked = false;
        this.goodSplitWizardForm.push(new FormControl({
            value: this.goodSplitWizardInitialCounter,
            disabled: false,
        }, [
            Validators.min(0),
        ]));
    }

    /**
     * Сохранение результата работы помощника разделения товарной позиции
     */
    goodSplitWizardCommit() {
        if (!this.goodSplitWizardFormValid || null === this.goodSplitWizardIndex) {
            return;
        }

        this.goodSplitWizardLocked = true;

        const good = this.goodsCopy[this.goodSplitWizardIndex];

        this.goodsCopy.splice(this.goodSplitWizardIndex, 1);
        this.goodSplitWizardForm.value.map(countForClonedGood => {
            this.goodsCopy.push({
                name: good.name,
                weight: good.weight,
                count: countForClonedGood,
                price: good.price,
                is_cancel: good.is_cancel,
                type: good.type,
                nds: good.nds,
                bar_code_id: good.bar_code_id,
            });
        });

        this.goodSplitWizardIndex = null;
        this.closeGoodSplitWizardModal.nativeElement.click();

        this.resetPlacesCount();
    }

    /**
     * Обновление модального окна помощника разделения товарной позиции
     */
    private goodSplitWizardUpdateState() {
        this.goodSplitWizardFormSum = this.goodSplitWizardForm.value.reduce((sum, val) => {
            return val + sum;
        });

        this.goodSplitWizardFormDelta = this.goodSplitWizardInitialCounter - this.goodSplitWizardFormSum;
        this.goodSplitWizardFormValid = (0 === this.goodSplitWizardFormDelta);

        this.goodSplitWizardForm.value.map((value, index) => {
            const totalLength = this.goodSplitWizardForm.value.length;
            const lastIndex = (totalLength - 1);

            if (
                0 === value && (
                    (this.goodSplitWizardFormDelta === 0 && index === lastIndex) ||
                    (this.goodSplitWizardFormDelta && totalLength > 1 && index !== lastIndex)
                )) {
                this.goodSplitWizardForm.removeAt(index);
            }
        });

        if (
            this.goodSplitWizardFormDelta &&
            this.goodSplitWizardForm.value[this.goodSplitWizardForm.value.length - 1] > 0
        ) {
            this.goodSplitWizardForm.push(new FormControl({
                value: 0,
                disabled: false,
            }, [
                Validators.min(0),
            ]));
        }

        this.goodSplitWizardFormMaxes = this.goodSplitWizardForm.value.map(val => {
            return val + this.goodSplitWizardFormDelta;
        });
    }

    editOptionOrder(event, key) {
        switch (key) {
            case 'inner_n' :
            case 'option_buyback':
            case 'os':
            case 'floor':
            case 'lift':
            case 'target_notes':
            case 'target_contacts':
            case 'second_phone':
            case 'option_opening':
            case 'target_name':
            case 'option_fitting': {
                this.optionsArray[key] = event.target.value;
                break;
            }
            case 'option_sms':
            case 'option_returned_doc':
            case 'option_call': {
                this.optionsArray[key] = event.target.checked;
                break;
            }
            case 'option_skid_kgt': {
                this.optionsArray['option_skid_kgt'] = event.target.value;
                this.orderModificated.option.option_skid_kgt = event.target.value;
                break;
            }
            case 'option_pickup_point_shipment': {
                this.optionsArray['option_pickup_point_shipment'] = event.target.checked;
                this.orderModificated.option.option_pickup_point_shipment = event.target.checked;
                break;
            }
            case 'option_vip_delivery_shipment': {
                this.optionsArray['option_vip_delivery_shipment'] = event.target.checked;
                this.orderModificated.option.option_vip_delivery_shipment = event.target.checked;
                break;
            }
            case 'option_underground_parking': {
                this.optionsArray['option_underground_parking'] = event.target.checked;
                this.orderModificated.option.option_underground_parking = event.target.checked;
                break;
            }
            case 'is_calculated_delivery_price': {
                this.optionsArray['is_calculated_delivery_price'] = event;
                this.orderModificated.bill.is_calculated_delivery_price = event;
                break;
            }
            case 'is_np': {
                this.optionsArray['is_np'] = event.target.value;
                this.orderModificated.bill.is_np = event.target.value;
                break;
            }
            case 'price_client_delivery': {
                this.optionsArray['price_client_delivery'] = event.target.value;
                this.orderModificated.bill.price_client_delivery = event.target.value;
                break;
            }
            case 'price_client_delivery_nds': {
                this.optionsArray['price_client_delivery_nds'] = event.target.value;
                this.orderModificated.bill.price_client_delivery_nds = event.target.value;
                break;
            }
            case 'prepaid_amount': {
                this.optionsArray['prepaid_amount'] = event.target.value;
                this.orderModificated.bill.prepaid_amount = event.target.value;
                break;
            }
            case 'places_count' : {
                this.orderModificated.option.count = event.target.value;
                this.resetPlacesCount()
                this.optionsArray['places_count'] = this.orderModificated.option.count;
                break;
            }
            case 'is_multiplaces_return': {
                this.optionsArray['is_multiplaces_return'] = event ? 1 : 0
                this.orderModificated.is_multiplaces_return = event

                break;
            }


            default: {
                alert('Нераспознанное значение');
                break;
            }
        }

        this.syncFormWithOptionPickupPointShipmentValue();
    }

    editServiceOrder(event, index, key) {
        switch (key) {
            case 'service_id':
            case 'details':
            case 'tax_type':
            case 'quantity':
                this.servicesCopy[index][key] = event.target.value;
                break;

            case 'price':
            case 'declared_price':
                this.servicesCopy[index][key] = parseFloat(event.target.value);
                break;
            default:
                alert('Нераспознанное значение');
        }
    }

    deleteService(index) {
        this.servicesCopy.splice(index, 1);
    }

    saveModifiedGoods() {

        for (const good of this.goodsCopy) {
            if (good.is_cancel == null) {
                good.is_cancel = 0;
            }
            if (!good.name || !good.vendor_code || good.count <= 0 || good.price < 0) {
                alert('Заполните все обязательные поля');
                return;
            }
        }

        if (!this.goodsCopy || this.goodsCopy.length === 0) {
            alert('Товарные позиции не переданы, отмена операции');
            return;
        }

        this.optionsArray['return_places_count'] = this.orderModificated.return_places_count
        this.optionsArray['places_count'] = this.orderModificated.option.count
        this.changeGood = true
        this.api.updateSettingsGoods(this.orderModificated.uid, {

            goods: this.goodsCopy,
            services: this.servicesCopy,
            options: this.optionsArray,
        }).subscribe(data => {
            this.finishSave(data);
            this.changeGood = false;
            this.notifier.openNotifier('Изменения сохранены...');
        });
    }

    saveModifiedOptions() {
        if (Object.keys(this.optionsArray).length > 0) {
            this.changeOpt = true;
            this.api.updateOptionsOrder(this.orderModificated.uid, this.optionsArray).subscribe(data => {
                this.finishSave(data);
                this.notifier.openNotifier('Изменения сохранены');
                this.changeOpt = false;
            }, error => {
                this.changeOpt = false;
            });
        }
    }

    setEditArray() {
        this.orderModificated = this.order;
    }

    mathPrice(value) {
        if (value < 0) {
            value = 0;
        }
        return parseFloat(value).toFixed(2);
    }

    onClose() {
        this.app.closeDialog(this);
        this.parent.DialogClosed();
    }

    daysDelta(delivery) {
        const del = new Date(delivery);
        const today = new Date();
        const diff = Math.abs(today.getTime() - del.getTime());
        const days = Math.floor(diff / (8640 * 10000));
        if (!days || (days === 1 && today.getHours() < 17)) {
            return true;
        }
        return false;
    }

    resolveProblem(manager, problemId) {
        if (manager) {
            this.api.resolveProblem(problemId).subscribe(data => {
                this.setNewInfoOrder(this.order.uid);
            });
            this.parent.DialogOnProblemResolved();
        }
    }

    /**
     * Выбор интервала в модально окне
     * Пишем во временную переменную на случай если злодей закроет модал крестом а не сохранением
     * @param event
     */
    onDeliveryIntervalChange(event) {
        let id = null;
        if (event.hasOwnProperty('id')) {
            id = event.id;
        }
        this.selectedDeliveryTime = id;
    }

    /**
     * Подтверждение проверки или отмены проверки веса
     * @param event
     */
    onToggleWeightValidatedConfirm(event) {
        this.toggleWeightValidatedConfirmed = event.target.checked;
    }

    onToggleAddressValidatedConfirm(event) {
        this.toggleAddressValidatedConfirmed = event.target.checked;
    }

    /**
     * Возврат документов включен в настройках клиента ?
     */
    public isDocumentReturnInClientSettings() {
        return !!(
            this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.MASTER_DOCS_RETURN)
            || this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.MASTER_DOCS)
        );
    }

    public isPricelessDeliveryOnRefuze() {
        return this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.IS_PRICELESS_DELIVERY_ON_REFUZE)
    }

    public isOrderFinished() {
        return (Order.STATUSES_CLOSED.indexOf(this.order.status) > -1);
    }

    /**
     * Переключает недоступность поля ввода имени фактического получателя по значению чекбокса "Удалить значение"
     */
    onToggleRecipientNameClear() {
        if (!this.EditOrder.value.recipient_name_clear) {
            this.EditOrder.controls.recipient_name.disable();
        } else {
            this.EditOrder.controls.recipient_name.enable();
        }
    }

    /**
     * Обновляет контролы редактора фактического получателя
     */
    setEditOrderRecipientName() {
        this.EditOrder.controls.recipient_name.reset({
            value: this.order.target.recipient_name ? this.order.target.recipient_name : '',
            disabled: false,
        });
        this.EditOrder.controls.recipient_name_clear.setValue(false);
    }

    /**
     * Копирование текста в буфер обмена
     */
    copyToClipboard(value, type = 1) {
        let text = 'Текст скопирован буфер обмена';
        switch (type) {
            case 1:
                text = 'Ссылка скопирована в буфер обмена';
                break;
            case 2:
                text = 'Маркировка скопирована в буфер обмена';
                break;
            case 3:
                text = 'EAN скопирован в буфер обмена';
                break;
            case 4:
                text = 'Номер заказа скопирован в буфер обмена';
                break;
        }

        const event = (event: ClipboardEvent) => {
            event.clipboardData.setData('text/plain', value);
            event.preventDefault();
        }
        document.addEventListener('copy', event);
        document.execCommand('copy');
        document.removeEventListener('copy', event);

        this.notifier.openNotifier(text);
    }

    /** Получение шаблонов смс отправляемых клинтским отделом. */
    getSmsTemplates() {
        const query = {
            client_id: this.order.client_id,
            order_id: this.order.id,
        }
        const templates = this.api.getSmsTemplates(query).subscribe(data => {
            this.smsTemplates = data;
        })
    }

    onPointCoordsUpdated(newCoords) {
        console.warn('new point coords', newCoords);
        this.order.target.lat = newCoords[0];
        this.order.target.lon = newCoords[1];
    }

    /**
     * Отправка подписи на почту
     */
    sendSignOnMail() {
        if (!this.checkErrorsForSendSigns) {
            return;
        }
        const query = {
            uid: this.order.uid,
            email: this.signOnMail.controls.email.value,
            signatures: this.selectedSignatures.map(signature => signature.id),
        }
        this.api.sendSignOnMail(query).subscribe(data => {
            this.notifier.openNotifier(data.message);
        })
    }

    checkErrorsForSendSigns() {
        if (!this.signOnMail.controls.email.value) {
            this.notifier.openNotifier('Не указана почта получателя!');
            return false;
        }
        if (this.selectedSignatures.length === 0) {
            this.notifier.openNotifier('Не выбрана не 1 подпись!');
            return false;
        }
        return true;
    }

    /**
     * Добавление / Удаление выбранной подписи
     * @param event
     */
    changeSelectedSign(event) {
        if (event.checked) {
            this.selectedSignatures.push(event.source.value);
        } else {
            const index = this.selectedSignatures.indexOf(event.source.value, 0);
            if (index > -1) {
                this.selectedSignatures.splice(index, 1);
            }
        }

        if (this.selectedSignatures.length === 1) {
            const anyVerify = (this.rights.verifySignature || this.rights.reVerifySignature);
            const reVerify = this.rights.reVerifySignature;
            if (this.selectedSignatures[0].is_effective && !this.selectedSignatures[0].is_verified && anyVerify) {
                this.markOrderSignatureVerifiedEnabled = true;
            }

            if (!this.selectedSignatures[0].is_effective && (
                (this.hasVerifiedSignature && reVerify) || (!this.hasVerifiedSignature && anyVerify)
            )) {
                this.setEffectiveOrderSignatureEnabled = true;
            }
        } else {
            this.markOrderSignatureVerifiedEnabled = false;
            this.setEffectiveOrderSignatureEnabled = false;
        }
    }

    /**
     * Отправка маршрутного листа по заказу с подписью на почту
     */
    sendMlistOnMail() {
        if (!this.checkErrorsForSendSigns) {
            return;
        }
        const query = {
            uid: this.order.uid,
            email: this.signOnMail.controls.email.value,
            signature: this.selectedSignatures[0].id,
            recipient_fio: this.signatures[0].recipient_fio,
        }
        this.api.sendMlistWithSignOnMail(query).subscribe(data => {
            this.notifier.openNotifier(data.message);
        })
    }

    onAddressEditorMapDialogOpenedChange(dialogOpened) {
        console.warn('onAddressEditorMapDialogOpenedChange', dialogOpened);
        this.locationMapVisible = !dialogOpened;
    }

    onLocationMapOpenedStateChange(locationMapOpened) {
        console.warn('onLocationMapOpenedStateChange', locationMapOpened);
        this.locationMapOpened = locationMapOpened;
    }

    /**
     * Обработчик изменений в чекбоксе "Требуется прозвон"
     * @param event
     */
    onToggleLocationAddressRequiresCall(event) {
        console.warn('onToggleLocationAddressRequiresCall', event, event.target.checked);
        this.setLocationAddressRequiresCall('order', this.order.id, event.target.checked ? 1 : 0);
    }

    /**
     * Обновление флага "Требуется прозвон"
     * @param objectType
     * @param objectId
     * @param callRequired
     */
    setLocationAddressRequiresCall(objectType, objectId, callRequired) {
        this.loadingLocationAddressRequiresCall = true;
        this.apiAddress.setLocationAddressRequiresCall(objectType, objectId, callRequired).subscribe(responsePayload => {
            this.order.address_requires_call = responsePayload.address_requires_call;
            this.loadingLocationAddressRequiresCall = false;
        }, () => {
            this.loadingLocationAddressRequiresCall = false;
        });
    }

    showGoodMark(good) {
        this.goodNameMark.nativeElement.innerText = '[' + good.vendor_code + ']' + good.name;
        this.goodMark.nativeElement.innerText = good.mark;
    }

    showEan(good) {
        this.eansGood = good.eans && good.eans.length > 0 ? good.eans : [];
    }

    /**
     * Сделать заказ доставленным вчерашним днём
     */
    makeOrderDeliveredYesterday() {
        this.api.makeOrderDeliveredYesterday(this.order.id, null).subscribe(data => {
            if (data && data.question) {
                if (confirm(data.question)) {
                    this.api.makeOrderDeliveredYesterday(this.order.id, {confirm: true}).subscribe(result => {
                        this.notifier.openNotifier(result.message);
                    });
                }
            }
        });
    }

    /**
     * Установка эффективной подписи заказа
     */
    public setEffectiveOrderSignature() {
        if (1 !== this.selectedSignatures.length) {
            return;
        }

        const orderUid = this.order.uid;
        const signatureId = this.selectedSignatures[0].id;

        this.resetSignaturesStatus();

        this.api.setEffectiveOrderSignature(orderUid, signatureId).subscribe(orderSignatures => {
            this.processLoadedSignatures(orderSignatures);
        }, () => {
        });
    }

    /**
     * Установка статуса "Верифицирована" для подписи заказа
     */
    public markOrderSignatureVerified() {
        if (1 !== this.selectedSignatures.length) {
            return;
        }

        if (!this.selectedSignatures[0].is_effective) {
            return;
        }

        const orderUid = this.order.uid;
        const signatureId = this.selectedSignatures[0].id;

        this.resetSignaturesStatus();

        this.api.markOrderSignatureVerified(orderUid, signatureId).subscribe(orderSignatures => {
            this.processLoadedSignatures(orderSignatures);
        }, () => {
        });
    }

    /**
     * Запрос страницы альбома похожих подписей
     * @param resetCache
     */
    getSimilarSignatures(resetCache: boolean = false) {
        const params = {
            pager_take: this.similarSignaturesState.take,
            pager_skip: this.similarSignaturesState.skip,
            reset_cache: resetCache,
        }

        this.similarSignaturesLoading = true;
        this.api.getSimilarSignatures(this.order.uid, params).subscribe(similarSignaturesPayload => {
            this.similarSignaturesTotal = similarSignaturesPayload.total;
            this.processLoadedSimilarSignatures(similarSignaturesPayload.data);
            this.similarSignaturesLoading = false;
        }, () => {
            this.similarSignaturesLoading = false;
        });
    }

    /**
     * Обработка открытия/закрытия альбома похожих подписей
     */
    public toggleShowSimilarSignatures() {
        this.showSimilarSignatures = !this.showSimilarSignatures;
        if (this.showSimilarSignatures && !this.similarSignaturesLoadedOnce) {
            this.getSimilarSignatures();
        }
    }

    /**
     * Обработка изменения страницы альбома похожих подписей
     * @param event
     */
    onChangeSimilarSignaturesPage(event) {
        this.similarSignaturesState.skip = event.pageIndex * event.pageSize;
        this.similarSignaturesState.take = event.pageSize;

        this.getSimilarSignatures();
    }

    /**
     * Обработка клика по кнопке клонирования подписи в альбом заказа
     * @param signatureId
     */
    public onCloneSignatureToOrder(signatureId) {
        this.cloneSignatureToOrder(signatureId);
    }

    public sendMessageToIm() {
        const data = {
            'title': this.sendImMessage.value.title,
            'message': this.sendImMessage.value.message,
            'order_id': this.order.id,
        }
        this.sendImInProgress = true;
        this.api.sendEmailToIm(data).subscribe(data => {
            this.notifier.openNotifier('Сообщение успешно отправлено');
            this.order.email_counter++;
            this.sendImInProgress = false;
        }, error => {
            this.sendImInProgress = false;
        })
    }

    /**
     * Проверка есть ли нерешённая проблема
     * получателя нет на месте
     */
    checkUnresolvedProblemRecipientNotInPlace() {
        if (!this.order || !this.order.unresolved_problems) {
            return false;
        }
        return this.order.unresolved_problems.filter(x => {
            return x.type_id === Problem.TYPE_RECIPIENT_NOT_IN_PLACE
        }).length > 0
    }

    /**
     * Сброс состояния подписи заказа
     * @private
     */
    private resetSignaturesStatus() {
        this.signaturesLoaded = false;
        this.selectedSignatures = [];
        this.hasSignature = false;
        this.hasSignatures = false;
        this.hasVerifiedSignature = false;
        this.effectiveSignatureId = null;
        this.markOrderSignatureVerifiedEnabled = false;
        this.setEffectiveOrderSignatureEnabled = false;
    }

    /**
     * Обработка альбома подписей получателя заказа
     * @param orderSignatures
     * @private
     */
    private processLoadedSignatures(orderSignatures) {
        this.signatures = orderSignatures.map(orderSignature => {
            orderSignature = new OrderSignature(orderSignature);
            if (orderSignature.is_effective) {
                this.hasSignature = true;
                this.effectiveSignatureId = orderSignature.id;
                if (orderSignature.is_verified) {
                    this.hasVerifiedSignature = true;
                }
            }

            return orderSignature;
        });

        this.hasSignatures = this.signatures.length > 0;

        this.signaturesLoaded = true;
        this.updateSignatureStatus();
    }

    /**
     * Обработка альбома похожих подписей
     * @param similarSignatures
     * @private
     */
    private processLoadedSimilarSignatures(similarSignatures) {
        console.log(similarSignatures);
        this.similarSignatures = similarSignatures.map(similarSignature => {
            return new SimilarOrderSignature(similarSignature);
        });

        this.similarSignaturesLoadedOnce = true;
    }

    /**
     * Устанавливает чек
     * @private
     */
    private setReceipt() {
        if (this.order.receipt && this.order.receipt.receipt_link) {
            this.receiptUrlExist = true;
            this.receiptUrl = this.receiptUrlShort = this.order.receipt.receipt_link;
            if ('undefined' !== typeof this.order.receipt.receipt_link_short && this.order.receipt.receipt_link_short) {
                this.receiptUrlShort = this.order.receipt.receipt_link_short;
            }
        }
    }

    private updateExpireDateDisplay() {
        this.storageDateOverdue = null;
        this.pickupPointStorageDateOverdue = null;

        if (this.order.status < 3) {
            return;
        }

        let date;
        if (this.order.status == 3) {
            date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        } else if (this.isOrderFinished()) {
            date = this.datePipe.transform(this.order.delivery_date, 'yyyy-MM-dd')
        }

        this.storageDateOverdue = this.getDateOverdue(date, this.order.storage_expire_date);
        this.storageDateOverdueClasses = this.getExpireDateDisplayClasses(this.storageDateOverdue);
        this.pickupPointStorageDateOverdue = this.getDateOverdue(date, this.order.pickup_point_free_storage_expire_date);
        this.pickupPointStorageDateOverdueClasses = this.getExpireDateDisplayClasses(this.pickupPointStorageDateOverdue);
    }

    private getExpireDateDisplayClasses(expireDays) {
        if (null === expireDays) {
            return '';
        }

        if (0 === expireDays) {
            return 'text-warning';
        }

        if (expireDays > 0) {
            return 'text-danger';
        }
    }

    /**
     * Обновляет признак демонстрации статуса подписи заказа
     * @private
     */
    private updateSignatureStatus() {
        this.clientHasDisplayRecipientsSignaturesOption = this.helper.getClientSetting(this.order.client_settings, ClientSettingKey.DISPLAY_RECIPIENTS_SIGNATURES);
        this.showSignatureStatus = (
            this.clientHasDisplayRecipientsSignaturesOption &&
            ([5, 6, 7].indexOf(this.order.status) !== -1) &&
            (this.rights.verifySignature || this.rights.reVerifySignature)
        );
    }

    /**
     * Клонирование подписи в альбом заказа
     * @param signatureId
     * @private
     */
    private cloneSignatureToOrder(signatureId) {
        this.resetSignaturesStatus();

        this.api.cloneSignatureToOrder(this.order.uid, signatureId).subscribe(orderSignatures => {
            this.processLoadedSignatures(orderSignatures);
            this.getSimilarSignatures(true);
        });
    }

    /**
     * Загрузка доп данных
     * при открытии модального окна
     * @private
     * @param modalName
     */
    private loadDataForModal(modalName: string) {
        switch (modalName) {
            case 'courierMessage':
                this.getPushTemplates();
                break;
        }
    }

    /**
     * Получение попыток доставки
     * @private
     */
    private getOrderDeliveries() {
        this.deliveriesLoading = true;
        this.api.orderDeliveries(this.order.uid).subscribe(data => {
            this.deliveriesLoading = false;
            this.deliveries = data;
        }, error => {
            this.deliveriesLoading = false;
        });
    }

    /**
     * Получение шаблонов для отправки в ИМ
     * @private
     */
    private getImTemplates() {
        const query = {
            client_id: this.order.client_id,
            order_id: this.order.id,
        }
        this.api.getImTemplates(query).subscribe(data => {
            this.iMTemplates = data;
        })
    }

    onOrderClick() {
        this.app.createDialog(ReturnOrderModalComponent, {
            order_uid: this.order.uid,
        }).onDestroy(data => {
            this.setNewInfoOrder(this.order.uid);
        })
    }

    setAbandonedStatusToGoods() {
        console.log(this.dialogSrv)
        const dlg = this.dialogSrv.confirm('Вы уверены? Все товары заказа будут удалены из возвратных актов').subscribe(result => {
            if (result) {
                this.loadSetAbandonedStatus = true;
                this.api.setRefuseStatusToGoods(this.order.uid).subscribe(data => {
                    this.notifier.openNotifier('Статус товаров успешно изменен', null, {duration: 3000});
                    this.loadSetAbandonedStatus = false;
                }, error => {
                    this.notifier.openNotifier('Непредвиденная ошибка', null, {duration: 3000});
                    this.loadSetAbandonedStatus = false;
                })
            }

            dlg.unsubscribe();
        })

    }


    /**
     * Возвращает класс строки товара
     * @param good
     */
    public getRowClass(good) {
        if (good.type === OrderGood.TYPE_CLIENT_RETURN) {
            return 'client_return_row';
        }

        if (good.type === OrderGood.TYPE_INSTALLATION_SERVICE) {
            if (good.is_cancel === 1) {
                return 'canceled_installation_service_row';
            }

            return 'installation_service_row';
        }

        if (good.is_cancel === 1) {
            return 'red_row';
        }

        if (good.status === OrderGood.STATUS_DAMAGED || good.status === OrderGood.STATUS_DAMAGED_AND_COMPENSATED) {
            return 'damaged_row';
        }

        return '';
    }

    /**
     * Нужно показать статус товара
     * @param good
     */
    public needShowStatus(good) {
        if (good.status === OrderGood.STATUS_DAMAGED || good.status === OrderGood.STATUS_DAMAGED_AND_COMPENSATED || good.type === OrderGood.TYPE_CLIENT_RETURN) {
            return true;
        }

        return false;
    }

    public needShowInstallationService(good) {
        return (good.type == OrderGood.TYPE_INSTALLATION_SERVICE);
    }

    public updateInstallationServiceBadge() {
        this.installationServiceStatus = this.helper.getInstallationServiceStatus(this.order);
        this.installationServiceClasses = this.helper.getInstallationServiceClasses(this.order);
    }

    /**
     * Клик по контролу подстановки электропочты "info@e-bulky.ru" в поле электропочты получателя письма с подписью
     */
    onEmailFillerClick() {
        this.signOnMail.controls.email.setValue('info@e-bulky.ru');
    }

    /**
     * Получение кол-ва товаров с типом клиентский возврат
     */
    getCountGoodWithTypeClientReturn() {
        return (this.goodsCopy.filter(x => x.type === OrderGood.TYPE_CLIENT_RETURN)).length;
    }

    /**
     * Можно ли менять тип товара
     */
    public cannotEditTypeGood(goodId, typeId) {
        return (
            (goodId) ||
            (typeId !== OrderGood.TYPE_GOOD && this.orderModificated.option.option_pickup_point_shipment) ||
            ((this.getCountGoodWithTypeClientReturn() > 0 && !this.is_multiplaces_return() || !this.isReturnsAvailable) && typeId === OrderGood.TYPE_CLIENT_RETURN)
        );
    }

    is_multiplaces_return() {
        return this.orderModificated.is_multiplaces_return;
    }

    replaceUnicodeInText(text) {
        const r = /\\u([\d\w]{4})/gi;
        text = text.replace(r, function (match, grp) {
            return String.fromCharCode(parseInt(grp, 16));
        });
        text = unescape(text);
        return JSON.parse(text);
    }

    onUploadAcknowledged(uploadedFiles) {
        this.order.files = uploadedFiles;
    }


    /**
     * Обработка изменения чекбокса признака негабаритного заказа
     */
    onToggleOrderIsBulky() {
        this.updateOrder('toggleIsBulky');
    }

    /**
     * Обновление возможности изменения сдвига интервала
     * @private
     */
    private updateIntervalShiftEditorAvailable() {
        this.intervalShiftEditorAvailable = (
            this.order &&
            this.order.change_interval_substatus &&
            this.order.change_interval_substatus.type == 6 &&
            this.order.delivery_date === this.datePipe.transform(new Date(), 'yyyy-MM-dd') &&
            -1 !== [4, 5, 6, 7].indexOf(this.order.status) &&
            this.helper.checkPermissions('orders:change-critical')
        );
    }

    get intervalShiftEditorProblemsToDeleteArray() {
        return (<FormArray>this.intervalShiftRollbackForm.get('problems_to_delete'));
    }

    /**
     * Распространение в заказ новых данных, загруженных в редактор сдвига интервала
     * @private
     */
    private propagateIntervalShiftDataToOrder() {
        this.order.delivery_time = this.intervalShiftInfo.delivery_time;
        this.order.delivery_time_start = this.intervalShiftInfo.delivery_time_start;
        this.order.delivery_time_end = this.intervalShiftInfo.delivery_time_end;
        this.order.change_interval_reason = this.intervalShiftInfo.change_interval_reason;
        this.order.change_interval_substatus = this.intervalShiftInfo.change_interval_substatus;
        this.checkInterval();
    }

    /**
     * Обновление форм редактора сдвига интервала
     * @private
     */
    private updateIntervalShiftEditorForms() {
        this.intervalShiftEditForm.controls['change_interval_reason'].setValue(this.intervalShiftInfo.change_interval_reason);
        this.intervalShiftEditForm.controls['problem_to_update'].setValue(null);
        this.intervalShiftRollbackForm.controls['data_log_to_rollback'].setValue(null);

        while (this.intervalShiftEditorProblemsToDeleteArray.length !== 0) {
            this.intervalShiftEditorProblemsToDeleteArray.removeAt(0)
        }

        for (const problem of this.intervalShiftInfo.problems) {
            this.intervalShiftEditorProblemsToDeleteArray.push(new FormControl(problem.id));
        }
    }

    /**
     * Запуск редактора сдвига интервала
     */
    intervalShiftEditorBegin() {
        this.intervalShiftEditorLoading = true;
        this.api.getIntervalShiftRollbackData(this.order.uid).subscribe(data => {
            this.intervalShiftInfo = data;
            this.updateIntervalShiftEditorForms();
            this.propagateIntervalShiftDataToOrder();

            this.intervalShiftEditorLoading = false;
        }, () => {
            this.intervalShiftEditorLoading = false;
        });
    }

    /**
     * Корректировка инициативы сдвига интервала
     */
    intervalShiftEditorUpdateReason() {
        if (this.isIntervalShiftEditorUpdateReasonDisabled()) {
            return;
        }

        this.intervalShiftEditorLoading = true;
        this.api.updateIntervalShiftReason(this.order.uid, this.intervalShiftEditForm.value).subscribe(data => {
            this.intervalShiftInfo = data;
            this.updateIntervalShiftEditorForms();
            this.propagateIntervalShiftDataToOrder();

            this.intervalShiftEditorLoading = false;
        }, () => {
            this.intervalShiftEditorLoading = false;
        });
    }

    /**
     * Признак недоступности корректировки инициативы сдвига интервала
     */
    isIntervalShiftEditorUpdateReasonDisabled() {
        return (
            this.intervalShiftEditorLoading ||
            (this.intervalShiftInfo &&
                (this.intervalShiftInfo.change_interval_reason === this.intervalShiftEditForm.value.change_interval_reason)
            )
        );
    }

    /**
     * Признак недоступности корректировки инициативы сдвига интервала
     */
    isIntervalShiftEditorRollbackDisabled() {
        return (
            this.intervalShiftEditorLoading ||
            !this.intervalShiftRollbackForm.value.data_log_to_rollback
        );
    }

    /**
     * Откат сдвига интервала
     */
    intervalShiftEditorRollbackShift() {
        if (this.isIntervalShiftEditorRollbackDisabled()) {
            return;
        }

        this.intervalShiftEditorLoading = true;
        const params = this.intervalShiftRollbackForm.value;
        params.data_log_to_rollback = +params.data_log_to_rollback;
        params.problems_to_delete = params.problems_to_delete.filter(value => value);

        this.api.rollbackIntervalShift(this.order.uid, params).subscribe(data => {
            this.intervalShiftInfo = data;
            this.updateIntervalShiftEditorForms();
            this.propagateIntervalShiftDataToOrder();

            this.intervalShiftEditorLoading = false;
        }, () => {
            this.intervalShiftEditorLoading = false;
        });
    }

    private syncFormWithOptionPickupPointShipmentValue() {
        this.checkGoodsForPickupPointShipmentRestrictions();

        if (!this.orderModificated.option.option_pickup_point_shipment) {
            this.hasInvalidPaymentMethodForPickupPointShipment = false;

            return false;
        }

        this.orderModificated.target.floor = 1;
        this.orderModificated.target.lift = 0;

        if (+this.orderModificated.bill.is_np) {
            this.hasInvalidPaymentMethodForPickupPointShipment = true;

            return true;
        } else {
            this.hasInvalidPaymentMethodForPickupPointShipment = false;

            return false;
        }
    }

    private checkGoodsForPickupPointShipmentRestrictions() {
        if (!this.orderModificated.option.option_pickup_point_shipment) {
            this.hasNonGoodsWithPickupPointShipmentEnabled = false;

            for (const good of this.goodsCopy) {
                if (good.type !== OrderGood.TYPE_GOOD) {
                    good._errors = [];
                }
            }

            return;
        }

        let anyNonGoods = false;
        for (const good of this.goodsCopy) {
            if (good.type !== OrderGood.TYPE_GOOD) {
                anyNonGoods = true;
                good._errors = ['forbidden_while_pickup_point_shipment_enabled'];
            }
        }

        if (this.orderModificated.is_multiplaces_return) {
            anyNonGoods = true;
        }

        this.hasNonGoodsWithPickupPointShipmentEnabled = anyNonGoods;
    }

    private getDateOverdue(date, expireDate) {
        if (!date || !expireDate) {
            return null;
        }

        const dateObj = new Date(date);
        const expireDateObj = new Date(expireDate);
        const msToExpire = dateObj.getTime() - expireDateObj.getTime();
        return msToExpire / 86400000;
    }

    private getServicesByCategories() {
        this.api.getServicesByCategories().subscribe(data => {
            this.servicesByCategories = data;
            const servicesTypes = [];

            data.map(category => {
                category.services.map(service => {
                    servicesTypes.push(service);
                });
            });

            this.servicesTypes = servicesTypes;
        });
    }

    public getServiceTypeName(serviceId) {
        const service = this.servicesTypes.find(loopService => loopService.id === serviceId);
        if (service) {
            return service.name;
        }

        return 'Неизвестно (' + serviceId + ')';
    }


    private setVisibilityForApi(isVisible) {
        if (this.loadingSetVisibilityForApi) {
            return;
        }

        this.loadingSetVisibilityForApi = true;
        this.api.setVisibilityForApi(this.order.uid, isVisible).subscribe(order => {
            this.order.storage_data.is_visible_for_api = order.storage_data.is_visible_for_api;
            this.loadingSetVisibilityForApi = false;
        }, () => {
            this.loadingSetVisibilityForApi = false;
        });
    }

    onClickSetVisibleForApi() {
        this.setVisibilityForApi(true);
    }

    onClickSetHiddenForApi() {
        this.setVisibilityForApi(false);
    }

    setStatusAccepted() {
        const sub = this.dialogSrv.confirm('Вы уверены что хотите вернуть статус заказа в заявка принята?').subscribe(result => {
            if (result) {
                this.setStatusAcceptedLoading = true;

                this.api.setStatusAccepted(this.order.id).subscribe(data => {
                    this.setStatusAcceptedLoading = false;
                    this.setNewInfoOrder(this.order.uid);
                    this.notifier.openNotifier('Успех', null, {class: 'success', duration: 3000});
                }, error => {
                    this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                    this.setStatusAcceptedLoading = false;
                })
            }

            sub.unsubscribe();
        });
    }

    deleteProblem(problemId) {
        const sub = this.dialogSrv.confirm('Вы уверены что хотите удалить проблему?').subscribe(result => {
            if (result) {
                this.api.deleteProblem(problemId, this.order.id).subscribe(data => {
                    this.setNewInfoOrder(this.order.uid);

                    this.notifier.openNotifier('Проблема удалена', null, {class: 'success', duration: 3000});
                }, error => {
                    this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
                });
            }

            sub.unsubscribe();
        });
    }

    deletePlaceFromOrder(barcodeId) {
        const sub = this.dialogSrv.confirm('Вы уверены что хотите удалить это место?').subscribe(result => {
            if (result) {
                this.api.deletePlaceFromOrder(barcodeId).subscribe(data => {
                    this.setNewInfoOrder(this.order.uid);
                    this.notifier.openNotifier('Успешно', null, {class: 'success', duration: 3000});
                }, error => {
                    this.notifier.openNotifier('Непредвиденная ошибка', null, {class: 'danger', duration: 3000});
                })
            }

            sub.unsubscribe();
        });
    }

    prepareReceiptLog(receipt) {
        return JSON.stringify(JSON.parse(receipt.provider_receipt), null, 2);
    }
}
