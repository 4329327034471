import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {ReturnactModalComponent} from './returnact-modal/returnact-modal.component';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-return-act',
    templateUrl: './return-act.component.html',
    styleUrls: ['./return-act.component.scss']
})
export class ReturnActComponent implements OnInit {

    @Input('vact_id') public vact_id: string;

    public loading = false;

    constructor(private api: SkladService,
                private resolver: ComponentFactoryResolver,
                private app: AppComponent) {
    }

    ngOnInit() {
    }

    public onOpen() {

        this.loading = true;
        this.api.getReturnAct(this.vact_id).subscribe((data) => {
            console.log(data);

            this.app.createDialog(ReturnactModalComponent, {
                act: data
            });
            console.log('done');
            this.loading = false;
        });
    }
}
