import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class SkladService extends LaraService {

    /**
     * @param data
     */
    // Проверить статус курьера
    public checkCourierStatus(data) {
        return this.post('/storage/checkCourierStatus', data);
    }

    // Проверить статус курьера
    public getNewCourierId(data) {
        return this.post('/storage/getNewCourierId', data);
    }

    // Загрузить заказы для выдачи
    public loadCourierQueue(data) {
        return this.post('/storage/loadCourierQueue', data);
    }

    // Проверить штрихкод
    public checkBarcode({userId, barCode}) {
        return this.post('/storage/checkBarcode', {userId, barCode})
    }

    // Завершить выдачи
    public finishCourierShipping({userId, order_id}) {
        return this.post('/storage/finishCourierShipping', {userId, order_id});
    }

    // Завершить погрузку
    public finishCourierLoading({userId}) {
        return this.post('/storage/finishCourierLoading', {userId});
    }

    // Начать погрузку
    public beginCourierLoading({userId}) {
        return this.post('/storage/beginCourierLoading', {userId});
    }

    // Получить список документов
    public returnCourierDocs(userId) {
        return this.post('/storage/returnCourierDocs', userId);
    }

    // Получить список для приема
    public courierDebt({userId, date}) {
        return this.get('/storage/courierDebt/' + userId + '/' + date);
    }

    // Проверить штрихкод
    public returnSomethingOnStock({userId, barCode}) {
        return this.post('/storage/returnSomethingOnStock', {userId, barCode})
    }

    /**
     * Возврат возвтратного акта с товарами обратно на склад
     * @param userId
     * @param barcode
     */
    public returnReturnActOnReception({userId, barcode}) {
        return this.post('/storage/returnReturnActOnReception', {userId, barcode});
    }

    /**
     * Приём подписанного возвратного акта
     * @param userId
     * @param barcode
     */
    public adoptSignedReturnActOnReception({userId, barcode}) {
        return this.post('/storage/adoptSignedReturnActOnReception', {userId, barcode});
    }

    // Принять заказ на склад
    public putZorderOnStock(zorderId) {
        return this.post('/storage/putZorderOnStock', zorderId);
    }

    // Закрыть курьера
    public closeCourierOnStock({userId, date, returnDocuments, ordersWithPretension}) {
        return this.post('/storage/closeCourierOnStock', {userId, date, returnDocuments, ordersWithPretension});
    }

    // Сбербанк
    public getSberbank(hub_id: number, improved: boolean = false) {
        return this.get('/storage/getSberbank/' + hub_id + (improved ? '?useImprovedProcedure=1' : ''));
    }

    // Сбербанк оптимизированный
    public getSberbankOptimized(hub_id: number) {
        return this.get('/logistic/getSberbankOptimized/' + hub_id);
    }

    // Установка приоритета
    public startAssemble(id) {
        return this.post('/logistic/startAssemble', {id: id});
    }

    // Обновление приоритетов
    public saveAssemblePriorities(data) {
        return this.post('/logistic/saveAssemblePriorities', data);
    }

    // Табло
    public getTodayCouriers(hubId: number) {
        return this.get('/storage/getTodayCouriers/' + hubId);
    }

    /**
     * приход курьеров
     * @param hubId
     */
    public getTodayCouriersStatus(hubId: number) {
        return this.get('/storage/getTodayCouriersStatus/' + hubId);
    }

    /** список актированных, не актированных заказов и не распознанных штрихкодов */
    public receptionDisplay() {
        return this.get('/storage/weights/display');
    }

    /** Перевес **/
    public pereves(data) {
        return this.post('/storage/weights/weight', data);
    }

    /** изменение габаритов мест  */
    public setPlaceDimensions(data) {
        return this.post('/storage/weights/setPlaceDimensions', data);
    }

    /** удаление заказа из акта ПП */
    public removeOrderOfAct(uid) {
        return this.delete('/storage/weights/order/' + uid);
    }

    /** удаление нераспознаного штрихкода */
    public removeNotFoundBarCode(id) {
        return this.delete('/storage/weights/barcode/' + id);
    }

    /** прием/весоприем на склад */
    public scanBarCode(data) {
        return this.post('/storage/weights/scan', data);
    }

    /** создание акта ПП для клиента */
    public createClientAct(clientId) {
        return this.post('/storage/weights/act/' + clientId, {});
    }

    /** поиск отказных товаров по клиенту и баркоду заказа */
    public getGoodsForReturn(clientId, barCode = null) {
        return this.post('/storage/getGoodsForReturn', {client_id: clientId, barcode: barCode});
    }

    /** Все отказные товары по клиенту */
    public getGoodsForReturnClient(clientId) {
        return this.get('/storage/getGoodsForReturn/' + clientId);
    }

    /** поиск отказных товаров по клиенту и баркоду заказа */
    public getGoodsForReturnByWarehouse(warehouseId, barCode = null) {
        return this.get('/storage/getGoodsForReturnByWarehouse/' + warehouseId + (barCode ? '/' + barCode : ''));
    }

    /**
     * получает список складов клиента
     * @param clientId
     */
    public getWarehouses(clientId) {
        return this.get('/storage/getWarehouses/' + clientId);
    }


    /** создать возвратный акт */
    public createReturnAct(data) {
        return this.post('/storage/createReturnAct', data);
    }

    /** получить список возвратных актов по клиенту и датам начала и конца периода */
    public getReturnActs(clientId, dateStart, dateEnd, hubId = null, statuses: number[] | null = null, barcode) {
        let params: any = {
            hub_id: hubId,
            barcode: barcode,
        }

        if (statuses.length) {
            params.statuses = statuses.join(',');
        }

        const query = this.getQueryStringFromArray(params);

        return this.get('/storage/getReturnActs/' + dateStart + '/' + dateEnd + (clientId ? '/' + clientId : '') + (query.length ? '?' + query : ''));

    }

    /** @deprecated получить возвратный акт по old_vact_id **/
    public getReturnAct(oldVactId) {
        return this.get('/storage/getReturnAct/' + oldVactId);
    }


    /** отправить возвратный акт на почту по id акта **/
    public sendMailReturn(id) {
        return this.get('/storage/mailReturnAct/' + id);
    }

    /**
     * Получить акт мат. ответственности по id для печати
     * @param mactId
     */
    public getCourierActByIdForPrint(mactId) {
        return this.get('/storage/acts/getCourierActByIdForPrint/' + mactId);
    }


    /** получить список актов матотв по курьеру и дате */
    public getCourierActs(hubId, pagesize, skip, date, courierId) {
        return this.get('/storage/acts/getCourierActs/hub/' + hubId + '/' + pagesize + '/' + skip + '/' + date + (courierId ? '/' + courierId : ''));
    }


    /** получить список актов ПП по клиенту и дате */
    public getOldPpActs(pagesize, skip, date, clientId) {
        return this.get('/storage/acts/getTransActs/' + pagesize + '/' + skip + (date ? '/' + date + (clientId ? '/' + clientId : '') : ''));
    }

    /**
     * Получение актов приёма-передачи/недостатков/излишек
     */
    public getPpActs(data) {
        return this.post('/storage/acts/ppActs', data);
    }

    /**
     * Получение актов, переданных клиентами
     */
    public getPpClientActs(data) {
        return this.post('/storage/acts/getPpClientActs', data);
    }

    /**
     * Постобработка клиентского акта
     * @param actId
     * @param data
     */
    public postProcessClientAct(actId: number, data) {
        return this.post('/storage/acts/postProcessClientAct/' + actId, data);
    }

    /**
     * Получение акта приёма-передачи/недостатков/излишек по номеру
     */
    public getPPActByNumber(number) {
        return this.get('/storage/acts/ppAct/' + number);
    }

    /**
     * Получение акта приёма-передачи/недостатков/излишек по номеру для печати
     */
    public getPpActByNumberForPrint(number: string) {
        return this.get('/storage/acts/ppActPrint/' + number);
    }

    /**
     * Получение списка статусов актов
     */
    public getPPActStatuses() {
        return this.get('/storage/acts/ppActStatuses');
    }

    /**
     * Закрытие акта приёма-передачи и излишек
     * @param data
     */
    public makePPActsClosed(data) {
        return this.post('/storage/acts/ppActClose', data);
    }

    /** получить акт ПП по id*/
    public getPpActById(actId) {
        return this.get('/storage/acts/getTransActByActId/' + actId);
    }

    /** получить Доверенность */
    public getAttorneys(date, crId) {
        return this.get('/storage/acts/getCourierDocs/' + date + '/' + crId);
    }

    public getCouriersWithDocs(date) {
        return this.get('/storage/acts/getCouriersWithDocs/' + date);
    }


    public createPack(data) {
        return this.post('/storage/transits/createPack', data);
    }

    public deletePack(data) {
        return this.post('/storage/transits/deletePack', data);
    }

    public createPackInHub(data) {
        return this.post('/storage/transits/createPackInHub', data);
    }

    public updatePackInHub(data) {
        return this.post('/storage/transits/updatePackInHub', data);
    }

    public findForPacking(data) {
        return this.get('/storage/transits/findForPacking/' + data);
    }

    public findPlaceForPacking(data) {
        return this.post('/storage/transits/findPlaceForPacking', data);
    }

    /** получить список палетт по статусу*/
    public getPackList(pagesize, skip, status) {
        return this.get('/storage/transits/packList/' + pagesize + '/' + skip + '/' + status);
    }

    /** получить список палет по фильтрам */
    public getPackFilteredList(filters, pagesize, skip) {
        return this.post('/storage/transits/packFilteredList/' + pagesize + '/' + skip, filters);
    }

    /** создание перемещения */
    public createTransit(data) {
        return this.post('/storage/transits/createTransit', data);
    }

    /** создание перемещения между хабами */
    public createHubTransit(data) {
        return this.post('/storage/transits/createHubTransit', data);
    }

    /** получить данные о перемещения */
    public getTransit(data) {
        return this.get('/storage/transits/pack/' + data);
    }

    /** получить данные о паллете */
    public getPack(packId: number) {
        return this.get('/storage/transits/pack/' + packId);
    }

    /** получить данные о перемещении */
    public getTransitInfo(id) {
        return this.get('/storage/transits/transit/' + id);
    }

    /** получить данные о перемещении */
    public getTransitForReceive(id) {
        return this.get('/storage/transits/getTransitForReceive/' + id);
    }

    /** прием места на склад из перемещения */
    public receivePlaceFromTransit(data) {
        return this.post('/storage/transits/receivePlaceFromTransit', data);
    }

    /** добавить палету к перемещению */
    public setPalletToTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/addToTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** добавить палету к перемещению в другой хаб */
    public setPalletToHubTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/addToHubTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** получить данные о перемещении */
    public removePalletFromTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/removeFromTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** получить список перемещений */
    public getTransitList(data) {
        return this.post('/storage/transits/list', data);
    }

    /** получить список перемещений между хабами */
    public getHubTransitList(data) {
        return this.post('/storage/transits/hubTransitList', data);
    }


    /** массовое назначение курьера на перемещения*/
    public setCourierForTransits(tids, courierId) {
        return this.post('/storage/transits/setCourier', {tids: tids, courier_id: courierId});
    }

    /** массовое изменения статуса перемещения*/
    public setTransitStatus(tids, status) {
        return this.post('/storage/transits/setStatus', {tids: tids, status: status});
    }

    /** изменения статуса перемещения между хабами */
    public setHubTransitStatus(transitId, status) {
        return this.post('/storage/transits/setTransitStatus', {transitId: transitId, status: status});
    }

    /**
     * Получение данных о перемещении для печати
     * @param $transitId
     */
    public getTransitForPrint(transitId) {
        return this.get('/storage/transits/getTransitInfoForAct/' + transitId);
    }

    /*  Получение данных о транзите */
    public getTransitdata(tids) {
        return this.get('/storage/transits/transit/' + tids);
    }


    /** Добавление места в паллету*/
    public addPlacePallet(pallet_id, barcode) {
        return this.post('/storage/transits/addToPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    /** Добавление мест в паллету*/
    public addPlacesToPallet(pallet_id, barcode) {
        return this.post('/storage/transits/addPlacesToPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    /** Список мест для добавления в паллету */
    public getPlacesForPallet(pallet_id, orderUids = []) {
        return this.post('/storage/transits/getPlacesForPallet', {pack_id: pallet_id, order_uids: orderUids});
    }

    /** Удаление места с паллеты*/
    public deletePlacePallet(pallet_id, barcode) {
        return this.post('/storage/transits/removeFromPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    public deletePlaceFromPallet(pallet_id, barcode) {
        return this.post('/storage/transits/removePlaceFromPack', {pack_id: pallet_id, barcode_ids: barcode});
    }


    /** Отчёт по возвратам*/
    public getOrderReturns(data) {
        return this.post('/statistics/getOrderReturns', data);
    }

    /** Начало предсборки*/
    public preAssembleStart(max) {
        return this.get('/storage/predsbor/start/' + max);
    }

    /** Скан предсборка*/
    public preAssembleScan(bar_code) {
        return this.post('/storage/predsbor/scan', {bar_code: bar_code});
    }

    /** Конец предсборки*/
    public preAssembleFinish() {
        return this.get('/storage/predsbor/finish');
    }

    /** Ячейка собрана*/
    public markCellAsCompleted(cell_num) {
        return this.post('/storage/predsbor/markCellAsCompleted', {cell_num: cell_num});
    }

    /** Проверка размещения: сканирование штрихкода */
    public orderPlacementScanBarCode(data) {
        return this.post('/storage/placement/scanBarCode', data);
    }

    /** Проверка размещения: подтверждение размещения на полке отличной от назначенной */
    public orderPlacementInvalidShelfAccept(data) {
        return this.post('/storage/placement/acceptInvalidShelf', data);
    }

    /** Проверка размещения: подтверждение перемещения на полку назначения */
    public orderPlacementInvalidShelfFix(data) {
        return this.post('/storage/placement/fixInvalidShelf', data);
    }

    /** Проверка размещения: отчет о размещении */
    public orderPlacementReportGet(data) {
        return this.post('/storage/placement/getReport', data);
    }

    /** Список водителей */
    public getTransitDrivers() {
        return this.get('/storage/transits/drivers');
    }

    public addTransitDriver(driver) {
        return this.post('/storage/transits/addTransitDriver', driver);
    }

    public saveTransitDriver(driver) {
        return this.post('/storage/transits/saveTransitDriver', driver);
    }

    public setDriverInTransit(transitId, driverId) {
        return this.post('/storage/transits/setDriverInTransit', {transitId: transitId, driverId: driverId});
    }

    /** Список компаний-перевозчиков */
    public getTransitCarriers() {
        return this.get('/storage/transits/carriers');
    }

    public addTransitCarrier(carrier) {
        return this.post('/storage/transits/addTransitCarrier', carrier);
    }

    public saveTransitCarrier(carrier) {
        return this.post('/storage/transits/saveTransitCarrier', carrier);
    }

    public setCarrierInTransit(transitId, carrierId) {
        return this.post('/storage/transits/setCarrierInTransit', {transitId: transitId, carrierId: carrierId});
    }

    /** Список машин */
    public getTransitVehicles() {
        return this.get('/storage/transits/vehicles');
    }

    public addTransitVehicle(vehicle) {
        return this.post('/storage/transits/addTransitVehicle', vehicle);
    }

    public saveTransitVehicle(vehicle) {
        return this.post('/storage/transits/saveTransitVehicle', vehicle);
    }

    public setVehicleInTransit(transitId, vehicleId) {
        return this.post('/storage/transits/setVehicleInTransit', {transitId: transitId, vehicleId: vehicleId});
    }

    /** Транспортные накладные */
    public getTransportWaybills(params) {
        return this.post('/transport-waybills/list', params);
    }

    public createTransportWaybill(params) {
        return this.post('/transport-waybills/create', params);
    }

    public addTransportWaybillDriver(params) {
        return this.post('/transport-waybills/addDriver', params);
    }

    public addTransportWaybillVehicle(params) {
        return this.post('/transport-waybills/addVehicle', params);
    }

    public addTransportWaybillWarehouse(params) {
        return this.post('/transport-waybills/addWarehouse', params);
    }

    public addTransportWaybillCompany(params) {
        return this.post('/transport-waybills/addCompany', params);
    }

    public getTransportWaybillDrivers() {
        return this.get('/transport-waybills/getDrivers');
    }

    public getTransportWaybillVehicles() {
        return this.get('/transport-waybills/getVehicles');
    }

    public getTransportWaybillCompanies() {
        return this.get('/transport-waybills/getCompanies');
    }

    public getTransportWaybillWarehouses() {
        return this.get('/transport-waybills/getWarehouses');
    }

    public deleteTransportWaybill(id) {
        return this.delete('/transport-waybills/' + id);
    }

    /** /Транспортные накладные */

    /** Получение списка статусов для актов мат. отв. */
    getMactStatuses() {
        return this.get('/storage/acts/getMactStatusesList');
    }

    /** Получение списка актов мат. отв. для контроля */
    getMactsForControl(params) {
        return this.post('/storage/acts/getMactsForControl', params);
    }

    /** Сканирование акта мат. отв. */
    scanMact(params) {
        return this.post('/storage/acts/scanMact', params);
    }

    /** Утраченные товары */
    getOrdersWithDamagedGoods(params) {
        return this.post('/storage/lost-orders/getOrders', params);
    }

    getOrderWithDamagedGoods(uid: string) {
        return this.get('/storage/lost-orders/getOrder/' + uid);
    }

    setOrderGoodStatus(params) {
        return this.post('/storage/lost-orders/setOrderGoodStatus', params);
    }

    /** /Утраченные товары */

    /** Тара клиентских возвратов */
    getClientReturnsPackages(params) {
        return this.post('/storage/client-returns/getPrintedPackages', params);
    }

    setClientReturnPackageAsPrinted(params) {
        return this.post('/storage/client-returns/setClientReturnPackageAsPrinted', params);
    }

    /** /Тара клиентских возвратов */

    /** перевес клиентских возвратов */
    getOrdersForWeighting(params) {
        return this.post('/storage/client-returns/getOrdersForWeighting', params)
    }

    /**
     * Список ворот
     */
    getHubGatesList() {
        return this.get('/hubs/gates/list');
        return this.get('/hubs/gates/');
    }

    /**
     * Комбинированный список ворот с позициями курьеров
     */
    listWithCouriersOffsets() {
        return this.get('/hubs/gates/listWithCouriersOffsets');
    }

    /**
     * Карточка ворот
     * @param id
     */
    getHubGate(id) {
        return this.get('/hubs/gates/' + id);
    }

    /**
     * Получение следующего свободного номера ворот в рамках хаба
     * @param hub_id
     * @param gate_num
     */
    getNextHubGateNum(hub_id) {
        return this.post('/hubs/gates/getNextHubGateNum', {
            hub_id,
        });
    }

    /**
     * Проверка уникальности номера ворот в рамках хаба
     * @param hub_id
     * @param gate_num
     */
    checkHubGateNum({hub_id, num}) {
        return this.post('/hubs/gates/checkNum', {
            hub_id,
            num,
        });
    }

    /**
     * Сохранение ворот (создание или редактирование)
     * @param params
     * @param id
     */
    saveHubGate(params, id = null) {
        return this.post('/hubs/gates/' + (id ? id : 'add'), params);
        return this.post('/hubs/gates/' + (id ? id : ''), params);
    }

    /**
     * Загрузка ворот
     */
    getHubGatesLoading() {
        return this.get('/hubs/gates/getLoading');
    }

    /** Акты Инвентив */
    public getInventivActs(params) {
        return this.post('/storage/inventiv-acts/getInventivActs', params);
    }

    public getActsForInventiv(data) {
        return this.post('/storage/inventiv-acts/getActsForInventiv', data);
    }

    public compareInventivAct(data) {
        return this.post('/storage/inventiv-acts/compareInventivAct', data);
    }

    public deleteInventivAct(data) {
        return this.post('/storage/inventiv-acts/deleteInventivAct/' + data.id, {});
    }
    /** /Акты Инвентив */

    public deleteCourierMlist(mlistId) {
        return this.post(`/storage/deleteCourierMlist/${mlistId}`, []);
    }
}
