import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class StorageAssemblingService extends LaraService {
    /**
     * Начало сессии сборки
     * @param oldCourierId
     */
    public beginAssembling(oldCourierId) {
        console.warn(this);
        return this.post('/storage/assembling/beginAssembling/' + oldCourierId, {});
    }

    /**
     * Прекращение сессии сборки
     * @param shippingQueueId
     * @param assemblingSessionId
     */
    public breakAssembling(shippingQueueId, assemblingSessionId) {
        return this.post('/storage/assembling/breakAssembling/' + shippingQueueId + '/' + assemblingSessionId, {});
    }

    /**
     * Получение информации о сборке
     * @param shippingQueueId
     */
    public getAssemblingTaskState(shippingQueueId) {
        return this.get('/storage/assembling/getAssemblingTaskState/' + shippingQueueId);
    }

    /**
     * Получение набора документов для курьера
     * @param shippingQueueId
     */
    public getCourierDocs(shippingQueueId) {
        return this.get('/storage/assembling/getCourierDocs/' + shippingQueueId);
    }

    /**
     * Сканирование штрихкода места/заказа, режим добавления
     * @param shippingQueueId
     * @param assemblingSessionId
     * @param param
     */
    public checkBarcode(shippingQueueId, assemblingSessionId, param) {
        console.warn(shippingQueueId, assemblingSessionId, param);
        console.warn(this);
        return this.post('/storage/assembling/checkBarcode/' + shippingQueueId + '/' + assemblingSessionId, param);
    }

    /**
     * Сканирование штрихкода места/заказа, режим удаления
     * @param shippingQueueId
     * @param assemblingSessionId
     * @param param
     */
    public uncheckBarcode(shippingQueueId, assemblingSessionId, param) {
        console.warn(shippingQueueId, assemblingSessionId, param);
        console.warn(this);
        return this.post('/storage/assembling/uncheckBarcode/' + shippingQueueId + '/' + assemblingSessionId, param);
    }

    /**
     * Завершение сборки
     * @param shippingQueueId
     * @param assemblingSessionId
     * @param param
     */
    public finishAssembling(shippingQueueId, assemblingSessionId, param = {}) {
        return this.post('/storage/assembling/finishAssembling/' + shippingQueueId + '/' + assemblingSessionId, param);
    }

    /**
     * Завершение отгрузки
     * @param shippingQueueId
     * @param param
     */
    public finishLoading(shippingQueueId, param) {
        return this.post('/storage/assembling/finishLoading/' + shippingQueueId, param);
    }

    /**
     * Список документов курьера
     * @param shippingQueueId
     * @param param
     */
    public courierDocs(shippingQueueId, param) {
        return this.post('/storage/assembling/courierDocs/' + shippingQueueId, param);
    }

    /**
     * Очередь на сборку
     * @param hubId
     * @param param
     */
    public shippingQueue(hubId, param) {
        return this.post('/storage/assembling/shippingQueue/' + hubId, param);
    }

    /**
     * Статус ворот погрузки
     * @param hubId
     * @param param
     */
    public hubGatesLoading(hubId, param) {
        return this.post('/storage/assembling/hubGatesLoading/' + hubId, param);
    }
}
