import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Hub, User} from '../../service/models';
import {MemberService} from '../../service/member.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {SkladService} from '../../service/sklad.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Observable, Subscription} from 'rxjs';
import {HelpersService} from '../../service/helpers.service';
import {EventerService} from '../../service/eventer.service';


@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
    @Output() onChange = new EventEmitter<User | User[]>();
    @Input() public selectedUser: User = null;
    @Input() public selectedUsers: User[] = [];

    @Input() public availableRoles: string = ''; // роли, через запятую
    @Input() public sortSpecialCouriersFirst = false; // требуется ли спец сортировка по курьерам пустышкам
    @Input() public placeHolder: string = 'Пользователь';
    @Input() public itemsShowLimit = 4;
    @Input() public shiftNumber: number;

    /* события и подписки - чтоб слушаться родителей */
    private eventsSubscription: Subscription;
    @Input() events: Observable<void>;


    /* полный список пользователей, полученный от API */
    public userList: Array<User> = [];
    /* отфильтрованный (возможно) список пользователей - для показа */
    public userListFiltered: Array<User> = [];
    /* группы по которым делаем фильтрацию */
    @Input() public roleList: Array<number> = [];

    public settings: IDropdownSettings = {};

    // по умолчанию показываем только активных пользователей
    public isActiveOnly: boolean = true;

    @Input() protected multi: boolean;
    @Input() public filter_type: string = ''; // если надо вызвать специфический набор юзеров
    public filter_date: string; // если надо передать дату для фильтра
    @Input() public disabled: boolean = false;
    @Input() public clearAfter = false;

    /* хабы по которым делаем фильтрацию */
    @Input() public filterIncludeHubs: Array<number | Hub> = [];

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private helpersService: HelpersService,
        private api: MemberService,
        public eventer: EventerService,
        private skladApi: SkladService,
    ) {
        this.eventer.userLookupById.subscribe(id => {
            const result = this.userList.find(item => {
                return item.id == id;
            });

            this.eventer.userLookupResponse.emit(result);
        });
    }

    ngOnInit() {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEvents(data));
        }

        this.settings = {
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            singleSelection: !this.multi,
            itemsShowLimit: this.itemsShowLimit,
            closeDropDownOnSelection: !this.multi,
            searchPlaceholderText: 'искать',
            noDataAvailablePlaceholderText: 'ничего не найдено',
            defaultOpen: false,
        };
        this.load();
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onEvents(data) {
        switch (data.type) {
            case 'filterIncludeHubsUpdated':
                this.filterIncludeHubs = data.filterIncludeHubs;
                return this.applyHubFilter();
            case 'filterShiftNumberUpdated':
                this.shiftNumber = data.shiftNumber;
                return this.applyShiftNumberFilter();
        }
    }

    public load() {
        switch (this.filter_type) {
            case 'couriersWithZorders': {
                if (this.filter_date) {
                    this.skladApi.getCouriersWithDocs(this.filter_date).subscribe(data => {
                        this.userList = this.cast(data);

                        this.applyHubFilter();
                        this.applyShiftNumberFilter();
                        return;
                    });
                }
                break;
            }
            case 'pickupPoint': {
                this.api.getPickupPointCouriersList().subscribe(data => {
                    console.log('pickupPoint')
                    this.userList = this.cast(data);
                    this.applyHubFilter();
                    return;
                });
                break;
            }
            case 'perenosCouriers': {
                this.api.getPerenosCouriersList().subscribe(data => {
                    console.log('perenos')
                    this.userList = this.cast(data);

                    this.applyHubFilter();
                    this.applyShiftNumberFilter();
                    return;
                });
                break;
            }
            case 'cargoCouriers': {
                this.api.getCargoCouriersList().subscribe(data => {
                    this.userList = this.cast(data);

                    this.applyHubFilter();
                    this.applyShiftNumberFilter();
                    return;
                });
                break;
            }
            default: {
                const users = this.storage.get('Users: ' + this.isActiveOnly + ';' + this.availableRoles);
                if (users) {
                    if ((new Date().getTime() - users.timestamp) / 1000 / 60 / 60 >= 1) {
                        this.getAllUsers();
                    }
                    this.userList = this.cast(users.data);

                    this.applyHubFilter();
                    this.applyShiftNumberFilter();
                    break;
                }
                this.getAllUsers();
                break;
            }
        }
    }

    getAllUsers() {
        this.api.usersAll(this.isActiveOnly, this.availableRoles, this.sortSpecialCouriersFirst).subscribe(data => {
            this.userList = this.cast(data);
            this.storage.set('Users: ' + this.isActiveOnly + ';' + this.availableRoles,
                {timestamp: new Date().getTime(), data: data});

            this.applyHubFilter();
            this.applyShiftNumberFilter();
        })
    }


    onChangeUser() {
        if (this.filter_type == 'cargoCouriers') {
            this.selectedUsers = this.selectedUsers.map(user => {
                let full = this.userList.find(find => {
                    return find.id === user.id;
                });
                if (full) {
                    user = full;
                }
                return user;
            });
        }

        if (this.selectedUsers.length === 0) {
            this.selectedUser = null;
        } else {
            this.selectedUser = this.selectedUsers[0];
        }
        this.onChange.emit(this.multi ? this.selectedUsers : this.selectedUser);
        if (this.clearAfter) {
            this.selectedUsers = [];
            this.selectedUser = null;
        }
    }

    onSelectItem(event) {
        this.onChangeUser();
    }

    onSelectAll() {
        this.onChangeUser();
    }

    onDeSelect(event) {
        this.onChangeUser();
    }

    onDeSelectAll() {
        this.onChangeUser();
    }

    cast(users: User[]) {
        return users.map((user: User) => {
            return new User(user);
        });
    }

    injectDefaultHubId(users: (User | null)[]): User[] {
        return users.map((mapUser: User | null) => {
            if (null === mapUser || !mapUser.hasOwnProperty('id') || mapUser.hasOwnProperty('default_hub_id')) {
                return mapUser;
            }

            let fullUser = this.userList.find((findUser: User) => {
                return findUser.id === mapUser.id;
            });

            mapUser.default_hub_id = fullUser ? fullUser.default_hub_id : null;
            return mapUser;
        });
    }

    applyHubFilter() {
        this.userListFiltered = this.filterByHubAndShift(this.userList, this.filterIncludeHubs, this.shiftNumber);
        this.selectedUsers = this.filterByHubAndShift(this.selectedUsers, this.filterIncludeHubs, this.shiftNumber);
        this.selectedUser = this.filterByHubAndShift([this.selectedUser], this.filterIncludeHubs, this.shiftNumber)[0];
        if (!this.selectedUser) {
            this.selectedUser = null;
        }

        this.onChange.emit(this.multi ? this.selectedUsers : this.selectedUser);
    }

    /**
     * Применяет фильтр по смене
     * @private
     */
    private applyShiftNumberFilter() {
        if (!this.shiftNumber) {
            return;
        }
        this.userListFiltered = this.filterByHubAndShift(this.userList, this.filterIncludeHubs, this.shiftNumber);
        this.selectedUsers = this.filterByHubAndShift(this.selectedUsers, this.filterIncludeHubs, this.shiftNumber);
        this.selectedUser = this.filterByHubAndShift([this.selectedUser], this.filterIncludeHubs, this.shiftNumber)[0];
        if (!this.selectedUser) {
            this.selectedUser = null;
        }

        this.onChange.emit(this.multi ? this.selectedUsers : this.selectedUser);
    }


    private checkShift(user: User, shiftNumber: number) {
        switch (shiftNumber) {
            case 1:
                return user.work_is_first_shift;
                break;
            case 2:
                return user.work_is_second_shift;
                break;
        }
        return true;
    }

    public filterByHubAndShift(users: (User | null)[], hubs: ((Hub | number)[]) | null, shiftNumber: number | null) {
        if (null === hubs || !hubs.length) {

            if (shiftNumber !== null) {
                return users.filter(user => this.checkShift(user, shiftNumber));
            } else {
                return users;
            }
        }

        return users.filter((user: User | null) => {
            if (null === user || !user.hasOwnProperty('default_hub_id')) {
                return true;
            }

            if (!this.checkShift(user, shiftNumber)) {
                return false;
            }
            return !!hubs.find((item: Hub | number) => {
                if (typeof item === 'number') {
                    return (item === user.default_hub_id)
                } else {
                    return (item.id === user.default_hub_id);
                }
            });
        });
    }
}
