import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../../statistics/statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {CourierUser, Hub, User, UserHub} from '../../service/models';
import {Subject} from 'rxjs';
import {LaraService} from "../../service/lara.service";
import {GridDataResult} from "@progress/kendo-angular-grid";

interface Type {
    name: string,
    value: number
}

@Component({
    selector: 'app-dispatched-notifications',
    templateUrl: 'dispatched-notifications.component.html',
    styleUrls: ['./dispatched-notifications.component.scss']
})
export class DispatchedNotificationsComponent implements OnInit {
    public formList: FormGroup;
    public isLoading = false;
    public date = new Date();
    public selectedHub: Hub [];
    public selectedHubs: UserHub[] = [];
    public selectedUsers = null;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public gridData: GridDataResult;

    public notificationsTypes: Type[] = [
        {value: 0, name: 'Все типы уведомлений'},
        {value: 1, name: 'СМС'},
        {value: 3, name: 'Пуш'}
    ];

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        protected api: LaraService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end:  new FormControl(this.date, Validators.required),
            notify_type:  new FormControl(0, Validators.required),
            old_courier_id:  new FormControl(''),
            device_token:  new FormControl(''),
        });
        this.selectedHub = [this.currentUserService.getCurrentHub(), ];
    }

    ngOnInit(): void {
    }

    loadData() {
        if (this.formList.controls.date_start.value > this.formList.controls.date_end.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {
                class: 'danger',
                duration: 5000
            });
        }

        this.isLoading = true;
        const query = this.formList.value;
        query.date_start = this.datePipe.transform(query.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(query.date_end, 'yyyy-MM-dd');
        query.courier_id = this.selectedUsers ? this.selectedUsers.id : null;
        console.warn(query);
        this.api.getDispatchedNotifications(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data, total: data.length};
        }, error => {
            this.isLoading = false;
        });
    }

    /**
     * Изменение хаба
     * @param hub
     */
    onSelectHub(hub) {
        this.selectedHubs = [hub, ];
        this.appHubChangeEventSubjectEmit();
    }

    /**
     * Обработчик изменения в селекторе курьера
     * @param users
     */
    onSelectCourier(users: CourierUser) {
        this.selectedUsers = users;
    }

    /**
     * Обработчик изменения фильтра по хабу - отправит новое фильтрующее значение селектору курьера
     */
    appHubChangeEventSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }
}

