import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {LaraService} from './lara.service';
import {Observable} from 'rxjs';
import {Hub, HubDeliveryInterval, Order} from './models';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class HubsService {
    private LOCAL_STORAGE_KEY = 'hubs';
    private hubs: Hub[] = [];
    private deliveryIntervals: HubDeliveryInterval[] = [];
    private deliveryIntervalsByHubs: { [key: number]: HubDeliveryInterval[] } = {};
    private loaded: boolean = false;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private api: LaraService,
        protected router: Router
    ) {
        this.boot();
    }

    /**
     * Начальная загрузка списка хабов - из локального хранилища барузера или запросом к API.
     */
    protected boot() {
        if (!this.fetchFromLocalStorage()) {
            this.reload().subscribe(() => {});
        }
    }

    /**
     * Возвращает имя хаба по id
     * @param id
     */
    public getHubNameById(id: number) {
        const hub = this.hubs.find(h => {
            return h.id === id;
        })

        if (hub) {
            return hub.name;
        } else {
            return null;
        }
    }

    /**
     * Чтение списка хабов из локального хранилища браузера
     */
    protected fetchFromLocalStorage() {
        const storedItem = this.storage.get(this.LOCAL_STORAGE_KEY);
        if (storedItem) {
            // TODO: Cache-Control
            if ((new Date().getTime() - storedItem.timestamp) / 1000 / 60 / 60 >= 1) {
                return false;
            }

            this.hubs = storedItem.data.map((item: Hub) => {
                return new Hub(item);
            });

            this.bootstrapIntervals();
            this.loaded = true;

            return true;
        }

        return false;
    }

    /**
     * Размещение списка хабов в локальном хранилище браузера
     * @param data
     */
    protected storeToLocalStorage(data) {
        this.storage.set(this.LOCAL_STORAGE_KEY, {timestamp: new Date().getTime(), data});
    }

    /**
     * Поиск объекта хаба по ID
     * @param id
     */
    public find(id: number) {
        return this.get().toPromise().then((hubs: Hub[]) => {
            return hubs.find((hub: Hub) => hub.id === id);
        })
    }

    /**
     * Получение списка хабов
     * @param fresh Форсирует получение свежего списка запросом к API
     */
    public get(fresh: boolean = false) {
        if (fresh) {
            this.hubs = [];
            this.loaded = false;
        }

        return new Observable((observer) => {
            if (this.loaded) {
                observer.next(this.hubs);
                observer.complete();
            } else {
                this.api.getHubsListWithDeliveryIntervals().subscribe((data: Hub[]) => {
                    if (data) {
                        data = data.map(item => {
                            return new Hub(item);
                        });

                        this.hubs = data;
                        this.storeToLocalStorage(data);
                        this.bootstrapIntervals();
                        this.loaded = true;
                    }

                    observer.next(this.hubs);
                    observer.complete();
                });
            }
        });
    }

    /**
     * Объявляет хабы заказа по их идентификаторам
     * @param order
     */
    public defineOrderHubs(order: Order) {
        return this.get().toPromise().then((hubs: Hub[]) => {
            if (order.hub_origin_id) {
                order.hub_origin = hubs.find((hub: Hub) => {
                    return hub.id === order.hub_origin_id;
                });
            }

            if (order.hub_destination_id) {
                order.hub_destination = hubs.find((hub: Hub) => {
                    return hub.id === order.hub_destination_id;
                });
            }

            if (order.hub_current_id) {
                order.hub_current = hubs.find((hub: Hub) => {
                    return hub.id === order.hub_current_id;
                });
            }

            return order;
        });
    }

    /**
     * Получение свежего списка хабов запросом к API
     */
    public reload() {
        return this.get(true);
    }

    /**
     * Инициализация списка интервалов доставки для хабов
     */
    protected bootstrapIntervals() {
        this.deliveryIntervalsByHubs = {};
        this.deliveryIntervals = [];

        this.hubs.map((hub: Hub) => {
            this.deliveryIntervalsByHubs[hub.id] = [];

            if (hub.hasOwnProperty('delivery_intervals') && hub.delivery_intervals.length) {
                hub.delivery_intervals.map((interval: HubDeliveryInterval) => {
                    this.deliveryIntervals.push(interval);
                    this.deliveryIntervalsByHubs[hub.id].push(interval);
                });
            }
        });
    }

    /**
     * Получение списка интервалов доставки для хаба
     * @param hub
     */
    public getHubDeliveryIntervals(hub: Hub | number) {
        let hub_id = null;
        if (typeof hub === 'number') {
            hub_id = hub;
        } else if (hub.hasOwnProperty('id')) {
            hub_id = hub.id;
        }

        if (!hub_id) {
            return [];
        }

        return this.get().toPromise().then(() => {
            if (!this.deliveryIntervalsByHubs.hasOwnProperty(hub_id)) {
                return [];
            }

            return this.deliveryIntervalsByHubs[hub_id];
        });
    }

    /**
     * Получение списка интервалов доставки
     * @param fresh Форсирует получение свежего списка запросом к API
     */
    public getDeliveryIntervals(fresh: boolean = false) {
        return this.get(fresh).toPromise().then(() => {
            return this.deliveryIntervals;
        });
    }
}
