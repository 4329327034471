import {Component, Input, OnInit} from '@angular/core';
import {StatisticsService} from '../../statistics.service';
import {AppComponent} from '../../../app.component';
import {Hub} from '../../../service/models';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-daily-meeting-orders',
    templateUrl: './daily-meeting-orders.component.html',
    styleUrls: ['./daily-meeting-orders.component.scss']
})
export class DailyMeetingOrdersComponent implements OnInit {

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Информация о заказах';

    public loading: boolean = false;
    public orders;
    public columns;

    @Input('selectedHub') selectedHub: Hub;
    @Input('reportDate') reportDate: Date;
    @Input('reportType') reportType: string;

    constructor(
        private api: StatisticsService,
        protected app: AppComponent,
        private datePipe: DatePipe
    ) {
        if (window.pageYOffset > 0) {
            this.windowTop = window.pageYOffset + 50;
        }

        if (window.innerWidth > 450) {
            this.width = 450;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = window.innerHeight * 0.9;
    }

    ngOnInit() {
        this.loadOrders();
    }

    loadOrders() {
        this.orders = null;
        this.loading = true;
        this.api.getMeetingOrdersList({
            hub_id: this.selectedHub.id,
            date: this.datePipe.transform(this.reportDate, 'yyyy-MM-dd'),
            report: this.reportType,
        })
            .subscribe(data => {
                this.orders = data.orders;
                this.title = data.title || this.title;
                this.columns = data.columns;

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    onClose() {
        this.app.closeDialog(this);
    }

    getColumns() {
        if (!this.orders || !this.orders[0]) {
            return null;
        }

        return Object.keys(this.orders[0]);
    }
}
