import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PickupPointOrdersListComponent} from './pickup-point-orders-list/pickup-point-orders-list.component';
import {PickupPointOrderShippingComponent} from './pickup-point-order-shipping/pickup-point-order-shipping.component';

const routes: Routes = [
    {path: 'orders-list', component: PickupPointOrdersListComponent},
    {path: 'shipping/:uid', component: PickupPointOrderShippingComponent, pathMatch: 'full'},
    {path: 'shipping', component: PickupPointOrderShippingComponent, pathMatch: 'full'},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PickupPointRoutingModule {
}
