import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {SkladService} from "../../service/sklad.service";
import {HelpersService} from "../../service/helpers.service";
import {OrdersService} from "../../service/orders.service";
import {DialogService} from "../../components/dialog/dialog.service";

@Component({
    selector: 'app-create-te',
    templateUrl: './create-te.component.html',
    styleUrls: ['./create-te.component.scss']
})
export class CreateTeComponent implements OnInit {
    public allPlacesList: any[] = [];
    public scanned_barcodes: string[] = [];
    public scanned_orders: number[] = [];
    public allBarcodesCount = 0;
    public message: string = '';
    private pageTitle = 'Создание ТЕ';
    public printOrders: any[] = [];
    public formScan: FormGroup;
    public notAllPlacesScanned = false;
    @ViewChild('input_barcode') barcode: ElementRef;
    public bar;
    public loading = false;
    uid: String;
    innerNumber: String;
    deliveryDate: String;
    status: String;
    dateObj: String;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public setPrint = false;

    constructor(
        private title: Title,
        private api: SkladService,
        public helpers: HelpersService,
        protected apis: OrdersService,
        protected dialog: DialogService
    ) {
        this.title.setTitle(this.pageTitle);

        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required)
        });
    }


    ngOnInit() {
    }

    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    onScan() {
        this.loading = true;

        if (!this.barcode.nativeElement.value) {
            this.message = 'Не введен штрихкод ';
            return;
        }

        const search = this.barcode.nativeElement.value;
        this.uid = search;
        if (this.is_scaned(search)) {
            this.message = 'Это место уже отсканировано';
        }

        this.api.findForPacking(search).subscribe((data: any) => {

            if (!data.bar_code) {
                this.message = 'Отсканируйте места заказа!';
                return;
            } else {
                this.message = '';
            }
            this.barcode.nativeElement.value = '';
            this.notAllPlacesScanned = true;

            if (this.scanned_barcodes.length) {
                const exists = this.scanned_barcodes.indexOf(data.bar_code.bar_code);
                if (exists < 0) {
                    this.scanned_barcodes.push(data.bar_code.bar_code);
                }
            } else {
                this.scanned_barcodes.push(data.bar_code.bar_code);
            }

            if (!this.orderInList(data.order)) {
                this.scanned_orders.push(data.order.id);
                this.allPlacesList.push(data.order);
                this.allBarcodesCount += data.order.bar_codes.length;

            }
            this.printOrders.push(data.order);
            this.onPrint(data.bar_code.id, data.order.uid);
        });

        this.loading = false;

        // setTimeout(() => this.onPrint(), 1000);
    }

    onPrint(bar, uid) {
        this.bar = bar;
        this.uid = uid;
        this.setPrint = true;
    }

    orderInList(order) {
        return (this.scanned_orders.indexOf(order.id) > -1);
    }

    is_scaned(barcode) {
        if (this.scanned_barcodes.indexOf(barcode) > -1) {
            return true;
        }

        this.notAllPlacesScanned = true;
        return false;

    }

    onDelete(uid) {

        for (let i = 0; i < this.allPlacesList.length; i++) {
            if (this.allPlacesList[i].uid === uid) {
                for (let j = 0; j < this.allPlacesList[i].bar_codes.length; j++) {

                    let fnd = this.scanned_barcodes.indexOf(this.allPlacesList[i].bar_codes[j].bar_code);
                    if (fnd > -1) {

                        this.scanned_barcodes.splice(fnd, 1);
                    }
                }
                this.allBarcodesCount -= this.allPlacesList[i].bar_codes.length;
                this.scanned_orders.splice(this.scanned_orders.indexOf(this.allPlacesList[i].id), 1);
                this.allPlacesList.splice(i, 1);
                break;
            }
        }
    }

    isScannedAll() {
        return ((this.allBarcodesCount === this.scanned_barcodes.length) && (this.allBarcodesCount > 0));
    }

    onCreatePack() {

        let barcode_ids: number[] = [];
        this.allPlacesList.forEach(function (item) {
            item.bar_codes.forEach(function (br) {
                barcode_ids.push(br.id);
            });
        });

        this.api.createPack({barcode_ids: barcode_ids}).subscribe(data => {
            alert("Транспортная единица c ID: " + data.id + " создана");
            this.clearAll();

        });
    }

    clearAll() {
        this.allPlacesList = [];
        this.scanned_orders = [];
        this.scanned_barcodes = [];
    }
    setPrinter(e) {
        this.setPrint = !e;
    }
}
