import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {Hub, Pallet, Transit, User} from '../../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../../service/sklad.service';
import {HelpersService} from '../../../service/helpers.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {HubsService} from '../../../service/hubs.service';

@Component({
    selector: 'app-transits-list',
    templateUrl: './transits-list.component.html',
    styleUrls: ['./transits-list.component.scss']
})
export class TransitsListComponent implements OnInit {
    public gridData: GridDataResult;

    private pageTitle = 'Перемещения';
    public pageSize = 20;
    public skip = 0;
    public selectedTransit: string[] = [];
    public selectedUser: User = null;

    @ViewChild('input_type') inputType: ElementRef;
    @ViewChild('input_status') inputStatus: ElementRef;
    @ViewChild('input_hub_destination') inputHubDestination: ElementRef;

    @ViewChild('transit_date') transitDate: ElementRef;
    public query: any;
    protected newCourier: User = null;

    public hubs: Hub[];

    public transitTypes = Transit.TYPES_DESCRIPTION;
    public transitStatuses = Transit.STATUSES_DESCRIPTION;

    public formList: FormGroup;
    public formEdit: FormGroup;

    constructor(private title: Title,
                private api: SkladService,
                public helpers: HelpersService,
                private hubsService: HubsService,
                protected dialog: DialogService
    ) {
        this.title.setTitle(this.pageTitle);

        this.formList = new FormGroup({
            inputType: new FormControl(0),
            inputStatus: new FormControl(0),
            inputHubDestination: new FormControl(0),
        });

        this.formEdit = new FormGroup(
            {
                new_status: new FormControl('')
            }
        );
    }

    getPrint(data) {
        return ['/transit-print', {'trId': data}]
    }


    ngOnInit() {
        this.inputStatus.nativeElement.value = 0;
        this.loadTransit();

        this.hubsService.get()
            .subscribe((data: Hub[]) => {
                this.hubs = data;
            })
    }


    public loadTransit() {
        this.selectedTransit = [];

        const query = {
            'status': this.inputStatus.nativeElement.value,
            'type': this.inputType.nativeElement.value,
            'hub_destination_id': this.inputHubDestination.nativeElement.value,
            'pageSize': this.pageSize,
            'skip': this.skip,
        };

        this.api.getHubTransitList(query).subscribe(data => {
            this.gridData = {data: data.transits.data, total: data.transits.total};
        });

    }

    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {

            this.selectedUser = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadTransit();
    }

    onSetStatus() {
        if (!this.formEdit.value.new_status) {
            this.dialog.alert('Выберите статус');
            return;
        }

        const dialog = this.dialog.confirm('Задать статус ' + this.helpers.getNameTransitStatus(this.formEdit.value.new_status) + ' ' + this.selectedTransit.length + ' перемещениям?').subscribe(result => {
            if (result) {
                this.api.setTransitStatus(this.selectedTransit, this.formEdit.value.new_status).subscribe(data => {
                    this.loadTransit();
                    if (data.message) {
                        this.dialog.alert(data.message);
                    }
                });
            }
            dialog.unsubscribe();
        })
    }

    getHubName(hubId) {
        return this.hubsService.getHubNameById(+hubId);
    }
}
