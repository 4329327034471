import {Injectable} from '@angular/core';
import {LaraService} from '../service/lara.service';

@Injectable({
    providedIn: 'root'
})
export class ClientsApiService extends LaraService {
    /** Список родительских компаний */
    public getParentCompanies() {
        return this.get('/clients/parentCompanies');
    }

    /** Список активных родительских компаний */
    public getActiveParentCompanies() {
        return this.get('/clients/parentCompanies?only_active=1');
    }
}
