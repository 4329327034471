import {Component, Inject} from '@angular/core';
import {ClientInfo, Hub} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {OrderLoadingService} from '../../orders-list/order-loading.service';
import {EventerService} from '../../service/eventer.service';
import {OrdersService} from '../../service/orders.service';
import {ZordersService} from '../../service/zorders.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {HubsService} from '../../service/hubs.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Router} from '@angular/router';
import {StatusSelectEventsService} from '../../components/status-select/status-select-events.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-spb-control',
    templateUrl: './spb-control.component.html',
    styleUrls: ['./spb-control.component.scss']
})

export class SpbControlComponent {
    public selectedClients: ClientInfo[];
    public selectedStatuses: number[] = [];

    public formFilter: FormGroup;
    public query;

    public orders;

    public minimized: boolean = false;

    public selectedHub: Hub;

    public dataIsLoading: boolean = false;
    public excelIsLoading: boolean = false;
    public currentPage: number = 1;
    public pageSize: number = 20;
    public pageOffset: number = 0;
    public totalOrders: number = 0;

    constructor(
        public helper: HelpersService,
        private eventer: OrderLoadingService,
        private eventerService: EventerService,
        protected api: OrdersService,
        protected dialog: DialogService,
        protected dataPipe: DatePipe,
        protected currentUserService: CurrentUserService,
        protected hubsService: HubsService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private router: Router,
        private fileDownloadService: FileDownloadService,
        private statusSelectEventsService: StatusSelectEventsService
    ) {
        const today = new Date();
        const dateTo = new Date();
        dateTo.setDate(today.getDate() + 3);

        this.formFilter = new FormGroup({
            date_type: new FormControl('delivery_date'),
            date_from: new FormControl(today),
            date_to: new FormControl(dateTo),
            not_collected: new FormControl(false),
            not_sended: new FormControl(false),
            logsis_delivery: new FormControl(null),
        });
    }

    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
    }

    onSelectStatus(event) {
        this.selectedStatuses = event;
    }

    onChangeHub(hub) {
        this.selectedHub = hub;
    }

    onNotCollectedClick(event: Event) {
        const checked = (<HTMLInputElement>event.srcElement).checked;
        if (checked) {
            this.selectedStatuses = [1, 2, 3];
        } else {
            this.selectedStatuses = [];
        }

        this.statusSelectEventsService.notify(this.selectedStatuses);
    }

    onNotSendedClick(event) {
        const checked = (<HTMLInputElement>event.srcElement).checked;
        if (checked) {
            this.selectedStatuses = [1, 2, 3];
        } else {
            this.selectedStatuses = [];
        }

        this.statusSelectEventsService.notify(this.selectedStatuses);
    }

    loadOrders() {
        this.dataIsLoading = true;

        this.api.getOrdersForControl(this.getSearchQuery())
            .subscribe((data) => {
                this.orders = data;
                this.currentPage = data.currentPage;
                this.totalOrders = data.total;

                this.dataIsLoading = false;
            }, error => {
                this.dataIsLoading = false;
            })
    }

    onExcelClick() {
        const reportParams = this.getSearchQuery();
        let fileName = 'SpbControl_';

        fileName += reportParams.date_from + ' - ' + reportParams.date_to;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/hubs/getOrdersForControl/excel', fileName + '.xlsx', 'post', reportParams)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    formatNum(value) {
        return parseFloat(value).toFixed(2);
    }

    onSearchOrdersClick() {
        // сбрасываем пагинацию
        this.pageOffset = 0;

        this.loadOrders();
    }

    onPageChange(event) {
        console.log(event);

        this.pageOffset = event.skip;
        this.loadOrders();
    }

    getSearchQuery() {
        const query = this.formFilter.value;

        query.skip = this.pageOffset;
        query.take = this.pageSize;

        if (this.selectedClients) {
            query.clients = this.selectedClients.map((client: ClientInfo) => client.id);
        }

        query.statuses = this.selectedStatuses;

        query.date_from = this.dataPipe.transform(query.date_from, 'yyyy-MM-dd');
        query.date_to = this.dataPipe.transform(query.date_to, 'yyyy-MM-dd');

        if (this.selectedHub) {
            query.hub_destination_id = this.selectedHub.id;
        }

        this.query = query;
        return this.query
    }
}
