import {NgModule} from '@angular/core';
import {ServicesRoutingModule} from './services-routing.module';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import {ServiceRequestsListComponent} from './service-requests-list/service-requests-list.component';
import {BodyModule} from '@progress/kendo-angular-grid';

@NgModule({
    declarations: [
        ServiceRequestsListComponent,
    ],
    exports: [],
    imports: [
        RouterModule,
        ServicesRoutingModule,
        ComponentsModule,
        BodyModule,
    ],
    providers: [],
    entryComponents: [],
})
export class ServicesModule {
}
