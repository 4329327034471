import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {StaffService} from '../../service/staff.service';
import {ReturnAct} from '../../service/models';

interface ReasonFormat {
    name: string,
    id: number
}

@Component({
    selector: 'app-return-act-return-reason-select',
    templateUrl: './return-act-return-reason-select.component.html',
    styleUrls: ['./return-act-return-reason-select.component.scss']
})
export class ReturnActReturnReasonSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Причина возврата на склад';
    @Input() initValue: number = null;
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: ReasonFormat[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    constructor(
        private api: StaffService,
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }
        this.dropdownList = ReturnAct.REASONS_DESCRIPTION;
        if (this.initValue) {
            this.selectedItems = this.dropdownList.filter(x => { return x.id === this.initValue })
        }
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.id) !== -1
        })
    }
    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.id));
    }
}
