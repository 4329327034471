import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AddressService extends LaraService {



    /** setAddressCoordinates */
    public setAddressCoordinates(addressId, latitude, longitude, quality: number = null) {
        return this.post('/address/setAddressCoordinates', {
            address_id: addressId,
            latitude,
            longitude,
            quality,
        });
    }

    /** получает список заказов, которые используют этот же ID адреса и разброс координат по ним. */
    public getAddress(addressId) {
        return this.get('/address/getAddress/'+addressId);
    }

    /**
     * Отдаёт страницу списка заказов/заборов/складов, которые используют указанный адрес
     * @param params
     */
    public getAddressUsage(params) {
        return this.post('/address/getAddressUsage', params);
    }

    /**
     * задает новую оценку качества для переданного адреса
     * @param addressId
     * @param quality
     */
    public setAddressQuality(addressId, quality) {
        return this.post('/address/setAddressQuality', {
            address_id: addressId,
            quality
        });
    }

    /**
     * Обновление флага "Требуется прозвон" для заказа/забора/склада
     * @param objectType
     * @param objectId
     * @param callRequired
     */
    public setLocationAddressRequiresCall(objectType, objectId, callRequired) {
        return this.post('/address/setLocationAddressRequiresCall', {
            object_type: objectType,
            object_id: objectId,
            call_required: callRequired,
        });
    }

    /**
     * привязывает логи к вечно плохому адресу
     * @param ids
     */
    public attachAddressLogsToSystemReservedBadAddress(ids: number[]) {
        return this.post('/address/attachAddressLogsToSystemReservedBadAddress', {ids});
    }

    /**
     * поиск адресов и логов в кеше
     * @param payload
     */
    public lookupCacheForAddress(payload) {
        return this.post('/address/lookupCacheForAddress', payload);
    }

    /**
     * привязывает логи к выбранному адресу
     * @param ids
     * @param addressId
     */
    public attachAddressLogsToAddress(ids: number[], addressId: number) {
        return this.post('/address/attachAddressLogsToAddress', {
            address_id: addressId,
            ids,
        });
    }

}
