import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SkladService} from "../../../service/sklad.service";
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-te-print',
    templateUrl: './te-print.component.html',
    styleUrls: ['./te-print.component.scss'],
})
export class TePrintComponent implements OnInit {

    packId: String;
    innerNumber: String;
    deliveryDate: String;
    status: String;
    dateObj: String;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    json: any;

    public loading = false;

    constructor(private route: ActivatedRoute, private api: SkladService, public helper: HelpersService) {
    }

    ngOnInit() {
        this.loading = true;
        this.route.params.subscribe(params => {
            this.packId = params['packId'];
        });

        this.api.getTransit(this.packId).subscribe((data) => {
            this.json = data;
            this.loading = false;
        });

    }

    checkStatus(status: number) {
        return this.helper.getNameOrderStatus(status);
        /*switch (status) {
            case 1: {
                return 'Новая заявка';
            }
            case 2: {
                return 'Заявка принята';
            }
            case 3: {
                return 'На складе';
            }
            case 4: {
                return 'На доставке';
            }
            case 5: {
                return 'Доставлен';
            }
            case 6: {
                return 'Частичный отказ';
            }
            case 7: {
                return 'Полный отказ';
            }
            case 8: {
                return 'Отмена';
            }
            case 10: {
                return 'Отмена для возврата';
            }
            default: {
                console.log('Invalid choice');
            }
        }*/
    }

    printPage() {
        window.print();
    }

}
