import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';

@Component({
    selector: 'app-root',
    templateUrl: './errordialog.component.html',
    styleUrls: ['./errordialog.component.scss']
})

export class ErrorDialogComponent {

    title = 'Angular-Interceptor';

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog
    ) {
    }

    closeWindows() {
        this.dialog.closeAll()
    }
}
