import {Component} from '@angular/core';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {MatrixComponent} from '../matrix/matrix.component';
import {ClientInfo, MatrixCategory, MatrixData} from '../../service/models';
import {MatrixCategoriesComponent} from '../matrix-categories/matrix-categories.component';

@Component({
    selector: 'app-matrix-by-amount',
    templateUrl: '../matrix/matrix.component.html',
    styleUrls: ['../matrix/matrix.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class MatrixOrdersAmountComponent extends MatrixComponent {
    public tariffType: string = this.TARIFF_TYPE_AMOUNT;
    private categoryListSource: MatrixCategory[];

    public selectedCategory = null;

    public loadMatrixCategories() {
        /* грузим список категорий */
        this.api.getMatrixCategoryList().subscribe(data => {
            this.categoryListSource = data.moscow;
            this.categoryList = data.moscow.filter((category: MatrixCategory) => {
                return !category.is_offer && category.is_volume_based && !category.client_id;
            });

            if (this.categoryList.length) {
                this.selectedCategory = this.categoryList[0].name;
            }

            this.loadMatrix();

            if (this.selectedClient) {
                this.setClientsCategoriesList();
            }
        });
    }

    private setClientsCategoriesList() {
        // оставим категории клиента, если они есть
        this.categoryList = this.categoryListSource.filter(category => {
            return category.client_id === this.selectedClient.id;
        })

        if (this.categoryList.length) {
            this.selectedCategory = this.categoryList[0].name;
            this.loadMatrix();
        } else {
            this.formMatrixList = null;
        }
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        if (client) {
            this.selectedCategory = null;
            this.setClientsCategoriesList();

            this.isSelectedCategory = true;
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid_old + ': ' + client.full_name);
        } else {
            this.isSelectedCategory = true;
            this.selectedCategory = null;
            this.title.setTitle(this.pageTitle);

            this.loadMatrixCategories();
        }
    }

    protected updateTariffMatrix(data) {
        this.api.updateTariffMatrix(
            data,
            this.selectedCategory,
            this.selectedRegionCode
        ).subscribe(() => {
            this.loadMatrix();
        });
    }

    protected getMatrixFromServer() {
        this.api.getTariffMatrix(
            this.selectedRegionCode,
            this.selectedCategory
        ).subscribe((data: MatrixData) => {
            this.processMatrixData(data);
        });
    }

    public onAddNewCategoryClick() {
        this.app.createDialog(MatrixCategoriesComponent, {
            client: this.selectedClient ? this.selectedClient : null,
            type: 'moscow'
        }).onDestroy(data => {
            this.loadMatrixCategories();
        })
    }

    getSelectedCategoryDescription() {
        if (!this.selectedCategory) {
            return null;
        }

        const category = this.categoryListSource.find(cat => cat.name === this.selectedCategory);
        if (!category) {
            return null;
        }

        return 'От ' + category.min_orders_count + ' заказов';
    }

    /**
     * Применить выбранную матрицу к клиенту с указанного числа
     */
    onMatrixApplyToClientClick() {
        const dialog = this.dialog.confirm('Уверены? Пути назад нет.').subscribe(result => {
            if (result) {
                this.matrixIsApplying = true;

                const params = {
                    start_date: this.datePipe.transform(this.formMatrixAmount.get('tariff_start_at').value, 'yyyy-MM-dd'),
                    client_id: this.selectedClient.id,
                    region_code: this.selectedRegionCode,
                    category: this.selectedCategory,
                };

                this.api.setMatrixByAmount(params)
                    .subscribe(data => {
                    this.eventer.updateClientList.emit();
                    this.selectedClient.matrix = this.selectedCategory;

                    this.matrixIsApplying = false;
                }, error => {
                    this.matrixIsApplying = false;
                });
            }
            dialog.unsubscribe();
        })
    }

    canApplyMatrixOnClient() {
        return this.selectedClient && this.selectedCategory && (this.selectedClient.matrix !== this.selectedCategory);
    }

    matrixIsActiveOnClient() {
        return this.selectedClient && this.selectedCategory && (this.selectedClient.matrix === this.selectedCategory);
    }
}
