import {Component, OnInit} from '@angular/core';
import { LaraService } from '../../service/lara.service';
import {NavigationEnd, Router} from '@angular/router';
import {interval} from 'rxjs';

@Component({
    selector: 'app-problems-counter',
    template: `<span *ngIf="count && !loading">{{count.problemsCount}}
        <i (click)="getCount()" class="fas fa-sync-alt"></i>
    </span>
        <div *ngIf="loading" class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>`,
    styles: ['span {padding: 2px 5px;border-radius: 5px;background-color: red;color: white;}']
})
export class ProblemsCounterComponent implements OnInit {
    count;
    loading = false;
    constructor(
        protected api: LaraService,
        private router: Router
    ) {
        interval(60000).subscribe(x => this.checkUrl(router.url))
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.checkUrl(val.url);
            }
        })
    }
    ngOnInit() {
        this.getCount();
    }
    checkUrl(url) {
        const val = url.split('/');
        if (val.indexOf('problems') > -1) {
            this.getCount();
        }
    }
    getCount() {
        this.loading = true;
        this.count = this.api.getProblemsCount().subscribe( data => {
            this.count =  data;
            this.loading = false;
        });
    }
}
