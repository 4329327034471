import {Component, Input} from '@angular/core';
import {AppComponent} from '../../app.component';
import {LoggerService} from '../../service/logger.service';
import {ChangesLoggerModalComponent} from '../changes-logger-modal/changes-logger-modal.component';

@Component({
    selector: 'app-changes-logger',
    templateUrl: './changes-logger.component.html',
    styleUrls: ['./changes-logger.component.scss']
})

export class ChangesLoggerComponent {
    public loading = false;

    @Input('entity') public entity: string;
    @Input('entityId') public entityId: number;

    constructor(
        private api: LoggerService,
        protected app: AppComponent
    ) {
    }

    public onOpen() {
        this.loading = true;
        this.api.getChangesHistory(this.entity, this.entityId).subscribe((data) => {
            this.app.createDialog(ChangesLoggerModalComponent, {
                historySource: data
            });
            this.loading = false;
        });
    }
}
