import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class DateHelper {
    /**
     * Вычисляет возраст по дате рождения
     * @param dob Дата рождения
     * @param now Опциональная дата расчёта возврата
     */
    public static calculateAge(dob: Date | string, now: Date | string | null = null) {
        if (!(dob instanceof Date)) {
            dob = new Date(dob);
        }

        if (null === now) {
            now = new Date();
        } else if (!(now instanceof Date)) {
            now = new Date(now);
        }

        let years = (now.getFullYear() - dob.getFullYear());
        if (now.getMonth() < dob.getMonth() ||
            now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate()) {

            years--;
        }

        return years;
    }

    /**
     * Возвращает валидатор возраста
     * @param opts
     */
    public static getAgeValidatorFn(opts: {min?: number, max?: number, base?: Date}) {
        return (control: AbstractControl): ValidationErrors | null => {
            const {min, max, base} = opts;

            let dob = control.value;
            if (null === dob) {
                return null;
            }

            let dobHasLength = true;
            if (!(dob instanceof Date)) {
                dobHasLength = !!dob.trim().length;
                if (dobHasLength) {
                    dob = new Date(dob);
                }
            }

            if (!dobHasLength) {
                return null;
            }

            const errors = {};

            const age = DateHelper.calculateAge(dob, base);
            if (isNaN(age)) {
                errors['invalidInput'] = {
                    actual: dob,
                }

                return errors;
            }

            if (age < 0) {
                errors['dobInFuture'] = {
                    actual: age,
                }

                return errors;
            }

            if (max && age > max) {
                errors['max'] = {
                    max,
                    actual: age,
                }
            }

            if (min && age < min) {
                errors['min'] = {
                    min,
                    actual: age,
                }
            }

            if (Object.keys(errors).length) {
                return errors;
            }

            return null;
        };
    }

    constructor(private datePipe: DatePipe) {
    }

    /**
     * Возвращает все дни переданного интервала
     * @param dateStart
     * @param dateEnd
     */
    public returnIntervalDays(dateStart: Date, dateEnd: Date) {
        if (dateStart > dateEnd) {
            return [];
        }

        const reportDates = [];
        while (dateStart <= dateEnd) {
            reportDates.push(this.datePipe.transform(dateStart, 'yyyy-MM-dd'));
            dateStart.setDate(dateStart.getDate() + 1);
        }

        return reportDates;
    }
}
