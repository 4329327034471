import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {PpActClient} from '../../service/models';
import {PpActClientImportComponent} from '../pp-act-client-import/pp-act-client-import.component';
import {AppComponent} from '../../app.component';
import {PpActClientProcessComponent} from './pp-act-client-process/pp-act-client-process.component';

@Component({
    selector: 'app-pp-act-client',
    templateUrl: './pp-act-client.component.html',
    styleUrls: ['./pp-act-client.component.scss']
})
export class PpActClientComponent implements OnInit {
    public formFilter: FormGroup;
    public isLoading = false;
    public selectedAllCheckBox = false;
    public gridData: PpActClient[] = [];

    public dateTypes = [
        {
            value: 1,
            name: 'Дата акта',
        },
        {
            value: 2,
            name: 'Дата загрузки',
        },
    ];

    public statuses = [
        {
            id: 1,
            name: 'Новый',
        },
        {
            id: 2,
            name: 'Загружен',
        },
        {
            id: 3,
            name: 'Обработан',
        },
    ];

    selectedDateType = this.dateTypes[0];

    public selectedActIds: number[] = [];

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        protected app: AppComponent,
    ) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        this.formFilter = new FormGroup(
            {
                date_type: new FormControl(this.selectedDateType.value),
                date_start: new FormControl(yesterday, Validators.required),
                date_end: new FormControl(today, Validators.required),
                status_id: new FormControl(null),
                client_id: new FormControl(null),
            },
        );
    }

    ngOnInit() {
        this.onFormSubmit();
    }

    /**
     * Событие при смене клиента
     * @param event
     */
    onChangeClientSelect(event) {
        this.formFilter.controls.client_id.setValue(event ? event.id : null);
        this.onFormSubmit();
    }

    /**
     * Событие при смене даты
     * @param controlName
     */
    onDateChange(controlName) {
        this.onFormSubmit();
    }

    /**
     * Событие при отправке формы
     */
    onFormSubmit() {
        if (!this.formFilter.value.date_start || !this.formFilter.value.date_end) {
            return this.notifier.openNotifier('Укажите дату!', null, {class: 'danger', duration: 3000});
        }
        if (!this.formFilter.value.date_type) {
            return this.notifier.openNotifier('Укажите тип даты!', null, {
                class: 'danger',
                duration: 3000,
            });
        }

        const query = {...this.formFilter.value};
        query.date_start = this.datePipe.transform(query.date_start, 'yyyy-MM-dd')
        query.date_end = this.datePipe.transform(query.date_end, 'yyyy-MM-dd')

        this.isLoading = true;
        this.api.getPpClientActs(query).subscribe(result => {
            this.isLoading = false;
            this.gridData = result;
        }, error => {
            this.isLoading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }

    /**
     * Выбор типа даты
     * @param event
     */
    onChangeDateType(event) {
        this.formFilter.controls.date_type.setValue(event.length > 0 ? event[0].value : null);
    }

    /**
     * Событие при смене статуса
     */
    onChangeStatusSelect() {
        this.onFormSubmit();
    }

    public onClientActLoadClick() {
        this.app.createDialog(PpActClientImportComponent, {
            isOnlyActLoading: true,
        }).onDestroy(data => {

        })
    }

    public onProcessClick(actId: number) {
        this.app.createDialog(PpActClientProcessComponent, {
            actId: actId,
        }).onDestroy(data => {
            this.onFormSubmit();
        })
    }

    /**
     * Может быть постобработан
     * @param act
     */
    public canBePostProcessed(act: PpActClient) {
        return act.status !== PpActClient.STATUS_POSTPROCESSED;
    }

    /**
     * Акт по местам?
     * @param act
     */
    public isByPlaces(act: PpActClient) {
        return act.barcode_type === PpActClient.BARCODE_TYPE_ORDER_PLACE;
    }
}
