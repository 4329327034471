import {Component, DoCheck, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientInfo, ReturnAct, UserHub} from '../../service/models';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {DetmirService} from '../../service/detmir.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ChooseDateDialogComponent} from '../../components/choose-date-dialog/choose-date-dialog.component';
import {DialogService} from '../../components/dialog/dialog.service';
import {parseNumber} from '@progress/kendo-angular-intl';
import {Subject} from 'rxjs';
import {EventerService} from '../../service/eventer.service';
import {CurrentUserService} from '../../service/current-user.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

@Component({
    selector: 'app-returnacts',
    templateUrl: './returnacts.component.html',
    styleUrls: ['./returnacts.component.scss'],
})
export class ReturnactsComponent implements OnInit, DoCheck {
    public appClientChangedEventsSubject: Subject<any> = new Subject<any>();
    public appHubChangedEventsSubject: Subject<any> = new Subject<any>();
    public appActStatusChangedEventsSubject: Subject<any> = new Subject<any>();
    public returnActList: any[] = [];
    public message: string = '';
    public selectedClient: ClientInfo;
    public actId;
    public selectedActs: number[] = [];
    public sendingReturn: boolean = false;
    public gettingLabel: boolean = false;
    public sendingTE: boolean = false;
    public deletingFromTE: boolean = false;
    @ViewChild('input_datestart') dateStart: ElementRef;
    @ViewChild('input_dateend') dateEnd: ElementRef;
    public chooseDate: boolean = false;
    public print: boolean = false;
    public barcode: string;
    public selectedHubs: UserHub[] = [];
    public selectedStatuses: number[] = [];
    public returnActForAttach: ReturnAct | null = null;
    public returnActForAttachId: number | null = null;
    public zorderIdForAttach: number | null = null;
    public isSaveFilterActive = false;
    @ViewChild('closeModal') private closeModal;
    private pageTitle = 'Возвратные акты';
    private toggleBoundInProgress = {};
    private isSetFiltersFromHistory = false;
    public barcodeSearch = 0;
    public isCanAccessScans: boolean = false;
    public isCanUploadScans: boolean = false;

    constructor(
        private currentUserService: CurrentUserService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private eventerService: EventerService,
        private title: Title,
        private api: SkladService,
        public helpers: HelpersService,
        private detmirApi: DetmirService,
        private commonDialog: DialogService,
        private router: Router,
        private datePipe: DatePipe,
    ) {
        this.title.setTitle(this.pageTitle);
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.writeFiltersFromHistory();
        this.setDefaultValues();
        this.currentUserService.get().subscribe(async () => {
            let hub = this.currentUserService.getCurrentHub();
            this.selectedHubs = [hub];

            this.isCanUploadScans = this.helpers.checkPermissions('files:return-act-image:upload');
            this.isCanAccessScans = this.helpers.checkPermissions('files:return-act-image:download') || this.isCanUploadScans;
        });
    }

    ngDoCheck(): void {
        this.setFiltersFromHistory();
    }


    public onUpdateFilterInState() {
        if (!this.isSaveFilterActive) {
            return;
        }
        history.replaceState({
            dateStart: this.dateStart.nativeElement.value,
            dateEnd: this.dateEnd.nativeElement.value,
            selectedClient: this.selectedClient ? this.selectedClient : [],
            selectedHubs: this.selectedHubs,
            selectedStatuses: this.selectedStatuses,
            scrollY: window.scrollY,
        }, '');
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        this.clearActs();
        this.onUpdateFilterInState();
    }

    public clearActs() {
        this.message = '';
        this.returnActList = [];
    }

    public onSend(actId) {
        this.actId = actId;
        this.openDialog();
    }

    public onChangeSelectedActs(event) {
        const value = parseNumber(event.target.value);
        const index = this.selectedActs.indexOf(value);
        if (index > -1) {
            this.selectedActs.splice(index, 1);
        } else {
            this.selectedActs.push(value);
        }
    }

    public onDeleteFromTE(actId) {
        this.actId = actId
        this.deletingFromTE = true;

        this.detmirApi.deleteFromTE(actId).subscribe(data => {
            this.deletingFromTE = false;

            this.commonDialog.alert(data.result);
            this.loadActs();
        });
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        dialogConfig.data = {
            defaultDate: tomorrow,
            description: 'Выберите дату для возврата',
        };

        const dialogRef = this.dialog.open(ChooseDateDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                this.sendToDetmir(this.actId, data)
            });
    }

    public printBarcode(barcode) {
        this.print = true;
        this.barcode = barcode;

    }

    public sendToDetmir(actId, date) {
        this.actId = actId;
        if (!date) {
            this.commonDialog.alert('не выбрана дата для возврата!');
            return;
        }
        this.sendingReturn = true;
        this.detmirApi.sendReturnAct(actId, date).subscribe(() => {
            this.sendingReturn = false;
            this.commonDialog.alert('Отправили. Через 10 минут можно получать штрихкод');

            this.loadActs();
        });
    }

    public getLabel(actId) {
        this.actId = actId;
        this.gettingLabel = true;
        this.detmirApi.getLabel(actId).subscribe(data => {
            this.gettingLabel = false;
            this.commonDialog.alert(data.result);
            this.loadActs();
        });
    }

    public sendTE() {

        this.sendingTE = true;
        console.log(this.selectedActs);
        this.detmirApi.sendTEInfo(this.selectedActs).subscribe(data => {
            this.sendingTE = false;
            this.selectedActs = [];

            this.commonDialog.alert(data.result);
            this.loadActs();
        });
    }

    public showLogs(logs) {
        let str = '<table border="1" class="bordered_table">';
        for (let i = 0; i < logs.length; i++) {

            str += '<tr valign="top"><td>' + logs[i].created_at + '</td><tr><td><xmp>' + logs[i].request + '</xmp></td></tr>';
            str += '<tr><td><xmp>' + logs[i].response + '</xmp></td></tr>';
        }

        str += '</table>';
        this.commonDialog.alert(str, 800, 600, true);
    }

    public loadActs(scrollY = null) {
        this.isSaveFilterActive = true;
        this.onUpdateFilterInState();
        if (!this.dateStart.nativeElement.value || !this.dateEnd.nativeElement.value) {
            this.message = 'Выберите даты начала и конца периода';
            return;
        }

        this.message = 'подгружаем акты...';
        this.api.getReturnActs(
            (this.selectedClient ? this.selectedClient.id : 0),
            this.helpers.formatDateForSQL(this.dateStart.nativeElement.value),
            this.helpers.formatDateForSQL(this.dateEnd.nativeElement.value),
            this.selectedHubs.length ? this.selectedHubs[0].id : null,
            this.selectedStatuses,
            this.barcodeSearch
        ).subscribe(data => {
            console.log(data);
            if ((data != null) && (data.length)) {
                this.returnActList = data;
                this.message = '';
            } else {
                this.message = 'Нет актов за выбранный период';
                this.returnActList = [];
            }
            setTimeout(() => {
                if (scrollY) {
                    window.scrollTo(300, scrollY);
                }
            }, 0);
        });
    }

    /**
     * Обработчик изменения в селекторе хаба
     * @param hub
     */
    onChangeHub(hub) {
        this.selectedHubs = hub ? [hub] : [];
        this.onUpdateFilterInState();
    }

    /**
     * Событие при смене фильтра по статусу акта
     * @param event
     */
    onChangeReturnActStatus(event: number[]) {
        this.selectedStatuses = event;
        this.onUpdateFilterInState();
    }

    /**
     * Возвращает признак активности процесса изменения состояния привязки возвратного акта к забору
     * @param returnActId
     */
    isToggleBoundInProgress(returnActId) {
        return ('undefined' !== this.toggleBoundInProgress[returnActId] && this.toggleBoundInProgress[returnActId]);
    }

    /**
     * Возвращает признак возможности изменить состояние привязки возвратного акта к забору
     * @param returnAct
     */
    isBoundWithZorderEditable(returnAct) {
        if (returnAct.zorder_id) {
            return returnAct.status === ReturnAct.STATUS_FORMED;
        }

        if (!returnAct.zorder_id) {
            return returnAct.status === ReturnAct.STATUS_FORMED;
        }

        return false;
    }

    /**
     * Обрабтка выбора забора из списка возможных для привязки возвратного акта
     * @param selectedZorders
     */
    onZorderChange(selectedZorders) {
        this.zorderIdForAttach = selectedZorders.length ? selectedZorders[0] : null;
    }

    /**
     * Обработчик клика по кнопке привязки возвратного акта к забору в модальном окне
     */
    onFinishAttachReturnActToZorder() {
        if (!this.zorderIdForAttach || !this.returnActForAttach) {
            return;
        }

        this.attachReturnActToZorder(this.returnActForAttach.id, this.zorderIdForAttach);
    }

    /**
     * Обработчик клика по кнопке привязки возвратного акта к забору
     * @param returnAct
     */
    onAttachReturnActToZorder(returnAct) {
        if (this.isToggleBoundInProgress(returnAct.id)) {
            return;
        }
        this.returnActForAttach = returnAct;
        this.returnActForAttachId = returnAct.id;
    }

    /**
     * Обработчик клика по кнопке отвязки возвратного акта от забора
     * @param returnActId
     */
    onDetachReturnActFromZorder(returnActId) {
        if (this.isToggleBoundInProgress(returnActId)) {
            return;
        }

        const dialog = this.dialogService.confirm('Отвязать возвратный акт от забора?')
            .subscribe(confirmed => {
                if (confirmed) {
                    this.detachReturnActFromZorder(returnActId);
                }

                dialog.unsubscribe();
            }, () => {
                dialog.unsubscribe();
            });
    }

    /**
     * Закрывает модальное окно привязки возвратного акта к забору
     */
    hideModal() {
        this.returnActForAttachId = null;
        this.returnActForAttach = null;
        this.zorderIdForAttach = null;

        this.closeModal.nativeElement.click();
    }

    isFormed(act) {
        return (act.status >= ReturnAct.STATUS_FORMED)
    }

    onDateChange(event) {
        this.onUpdateFilterInState();
    }

    setDefaultValues() {
        document.getElementById('input_dateend')['value'] = this.datePipe.transform(Date(), 'dd.MM.yyyy');
        document.getElementById('input_datestart')['value'] = this.datePipe.transform(Date(), 'dd.MM.yyyy');
    }

    /**
     * Установка фильтров сохраненных в истории
     * @private
     */
    public setFiltersFromHistory() {
        let value = document.getElementById('filtersInHistory')['value'];
        if (value && !this.isSetFiltersFromHistory) {
            value = JSON.parse(value);
            document.getElementById('input_datestart')['value'] = value.dateStart;
            document.getElementById('input_dateend')['value'] = value.dateEnd;
            this.appClientChangedEventsSubject.next(value.selectedClient ? [value.selectedClient.id] : []);
            this.appHubChangedEventsSubject.next(value.selectedHubs.map(x => x.id));
            this.appActStatusChangedEventsSubject.next(value.selectedStatuses);
            this.isSetFiltersFromHistory = true;
            this.selectedClient = value.selectedClient;
            setTimeout(() => {
                this.loadActs(value.scrollY);
            }, 300);
        }
    }

    /**
     * Обновление акта в списке актом из ответа от сервера
     * @param returnAct
     * @private
     */
    private updateReturnActInList(returnAct) {
        this.returnActList = this.returnActList.map(loopReturnAct => {
            if (loopReturnAct.id === returnAct.id) {
                loopReturnAct = returnAct;
            }

            return loopReturnAct;
        });
    }

    /**
     * Запускает привязку возвратного акта к забору
     * @param returnActId
     * @param zorderId
     * @private
     */
    private attachReturnActToZorder(returnActId, zorderId) {
        this.toggleBoundInProgress[returnActId] = true;

        this.api.attachReturnActToZorder(returnActId, zorderId).subscribe(returnAct => {
            this.updateReturnActInList(returnAct);
            delete this.toggleBoundInProgress[returnActId];
        }, () => {
            delete this.toggleBoundInProgress[returnActId];
        });
    }

    /**
     * Запускает отвязку возвратного акта от забора
     * @param returnActId
     * @private
     */
    private detachReturnActFromZorder(returnActId) {
        this.toggleBoundInProgress[returnActId] = true;

        this.api.detachReturnActFromZorder(returnActId).subscribe(returnAct => {
            this.updateReturnActInList(returnAct);
            delete this.toggleBoundInProgress[returnActId];
        }, () => {
            delete this.toggleBoundInProgress[returnActId];
        });
    }

    /**
     * Получение фильтров из истории
     * и временная запись
     * @private
     */
    private writeFiltersFromHistory() {
        window.onpopstate = function (e) {
            setTimeout(() => {
                document.getElementById('filtersInHistory')['value'] = JSON.stringify(e.state);
            }, 0);
        }
    }

    onScanInputChange(event) {
        this.barcodeSearch = event.target.value.length === 0 ? 0 : event.target.value;
        this.loadActs();
    }

    onFindZorder(event: any) {
        this.api.getZorder(event.target.value).subscribe(data => {
            if (data && data.zorder && data.zorder.id) {
                this.onZorderChange([data.zorder.id]);
            }
        })
    }

    async onChangeZorderForReturnAct(act) {
        this.detachReturnActFromZorder(act.id);

        do {
            await this.pause();
            if (!this.isToggleBoundInProgress(act.id)) {
                return document.getElementById('AttachReturnActToZorderBtn' + act.id).click();
            }
        } while (true);
    }

    public pause() {
        return new Promise(resolve => setTimeout(() => {
            resolve();
        }, 500));
    }
}
