import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {NotifierService} from '../service/notifier.service';

@Injectable({providedIn: 'root'})
/**
 * Проверяем, нужно ли перезагрузить фронтенд
 */
export class FrontendNeedUpdateService {
    private interval;
    private startDate: Date;
    private http: HttpClient;

    constructor(
        private handler: HttpBackend,
        private notifierService: NotifierService
    ) {
        this.http = new HttpClient(handler);

        this.startDate = new Date;

        this.interval = setInterval(() => {
            this.checkLastModifiedTime();
        }, 120000);
    }

    protected handleError(e) {
        return throwError(e);
    }

    private checkLastModifiedTime() {
        this.http.get('/', {
                responseType: 'text',
                observe: 'response',
                headers: new HttpHeaders({
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                })
            })
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe((response) => {
                const lastModified = new Date(response.headers.get('last-modified'));
                if (lastModified > this.startDate) {
                    this.notifierService.openNotifier(
                        'Мы провели обновление системы. ' +
                        'Пожалуйста, обновите страницу, нажав Shift + F5',
                        'Ok',
                        {
                            'duration': 10000
                        }
                    );
                }
            });
    }
}
