import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ServiceRequestsListComponent} from './service-requests-list/service-requests-list.component';

const routes: Routes = [
    {path: 'requests-list', component: ServiceRequestsListComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ServicesRoutingModule {
}
