import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../service/models';

export interface OrderBadgeItemInterface {
    flag: string;
    iconClasses: string[];
    labelText: string;
}

@Component({
    selector: 'app-order-badges',
    templateUrl: './order-badges.component.html',
    styleUrls: ['./order-badges.component.scss'],
})
export class OrderBadgesComponent implements OnInit {
    public static readonly ORDER_BADGES = {
        is_important: {
            iconClasses: ['fas', 'fa-exclamation-triangle'],
            labelText: 'Важный',
        },
        is_very_important: {
            iconClasses: ['fas', 'fa-exclamation-triangle'],
            labelText: 'Очень важный',
        },
        is_client_return_combined: {
            iconClasses: ['fas', 'fa-retweet'],
            labelText: 'Доставка и возврат',
        },
        is_client_return_only: {
            iconClasses: ['fas', 'fa-undo-alt'],
            labelText: 'Только возврат',
        },
        is_bulky: {
            iconClasses: ['fas', 'fa-truck'],
            labelText: 'Габаритный',
        },
        // is_expensive: {
        //     iconClasses: ['fas', 'fa-dollar-sign'],
        //     labelText: 'Дорогой',
        // },
        is_installation_service: {
            iconClasses: ['fas', 'fa-tools'],
            labelText: 'Услуги монтажа',
        },
        pickup_point_shipment: {
            iconClasses: ['fa', 'fa-hiking'],
            labelText: 'Самовывоз (ПВЗ)',
        },
        vip_delivery_shipment: {
            iconClasses: ['fa', 'fa-crown'],
            labelText: 'VIP доставка',
        },
    }
    @Input() order: Order;
    @Input() displayIcon: boolean = true;
    @Input() displayLabel: boolean = false;
    @Input() invertedColors: boolean = false;
    @Input() muted: boolean = false;
    public itemsList: string[];
    public readonly ORDER__CLIENT_RETURN_ONLY = Order.CLIENT_RETURN_ONLY
    public readonly ORDER__CLIENT_RETURN_COMBINED = Order.CLIENT_RETURN_COMBINED

    constructor() {
    }

    ngOnInit() {
        console.info(this.order);
        if (!this.displayIcon && !this.displayLabel) {
            this.displayIcon = true;
        }

        let itemsList = [];

        if (this.order.is_important === 1) {
            itemsList.push('is_important');
        } else if (this.order.is_important > 1) {
            itemsList.push('is_very_important');
        }

        if (this.order.is_client_return === Order.CLIENT_RETURN_COMBINED) {
            itemsList.push('is_client_return_combined');
        } else if (this.order.is_client_return === Order.CLIENT_RETURN_ONLY) {
            itemsList.push('is_client_return_only');
        }

        if (this.order.option && this.order.option.is_bulky) {
            itemsList.push('is_bulky');
        } else if (this.order.option_is_bulky) {
            itemsList.push('is_bulky');
        }

        if (this.order.option && this.order.option.option_pickup_point_shipment) {
            itemsList.push('pickup_point_shipment');
        } else if (this.order.option_pickup_point_shipment) {
            itemsList.push('pickup_point_shipment');
        }

        if (this.order.option && this.order.option.option_vip_delivery_shipment) {
            itemsList.push('vip_delivery_shipment');
        } else if (this.order.option_vip_delivery_shipment) {
            itemsList.push('vip_delivery_shipment');
        }

        // if (this.order.is_expensive) {
        //     itemsList.push('is_expensive');
        // }

        if (this.order.is_installation_service && this.order.is_installation_service > Order.INSTALLATION_SERVICE_NOT_DECLARED) {
            itemsList.push('is_installation_service');
        }

        console.warn(itemsList)
        this.itemsList = itemsList;
    }
}
