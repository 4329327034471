import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {HelpersService} from '../../service/helpers.service';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {OrderSignature} from '../../service/models';
import {State} from '@progress/kendo-data-query/dist/npm/state';
import {DialogService} from '../../components/dialog/dialog.service';
import {IOrdersSignaturesVerificationQueueStatsResponse} from '../../admin/admin-navbar/orders-signatures-verification-queue-counter.component';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

interface IOrdersSignaturesVerificationQueueResponse extends GridDataResult {
    current_page: number,
    data: IOrdersSignaturesVerificationQueueDataItem[],
    from: number,
    last_page: number,
    per_page: number,
    skip: number,
    stats: IOrdersSignaturesVerificationQueueStatsResponse,
    to: number,
    total: number,
}

interface IOrdersSignaturesVerificationQueueDataItem {
    order_id: number,
    is_verified_count: number,
    is_effective_count: number,
    signatures_count: number,
    order_uid: string,
    target_name?: string,
    target_contacts?: string,
    recipient_name?: string,
    effective_signature?: OrderSignature | null,
    other_signatures: OrderSignature[],
}

@Component({
    selector: 'app-orders-signatures-verification-queue',
    templateUrl: './orders-signatures-verification-queue.component.html',
    styleUrls: ['./orders-signatures-verification-queue.component.scss'],
})
export class OrdersSignaturesVerificationQueueComponent implements OnInit {
    public formFilter: FormGroup;
    public stats: IOrdersSignaturesVerificationQueueStatsResponse | null = null;
    public loading = false;
    public massActionPanelVisible = false;
    public ordersInQueueGridData: GridDataResult;
    public ordersInQueueGridSelection = [];
    public ordersInQueueGridState: State = {
        take: 50,
        skip: 0,
    }
    public rights = {
        verifySignature: false,
        reVerifySignature: false,
    }

    public selectedOrders = [];
    public massVerifyInProgress: boolean = false;

    public activeFilter = null;
    public readonly filterPresets = [
        {
            id: 'has_verified',
            label: 'Верифицированы',
            filterPayload: {
                has_verified: 'yes',
                has_effective: 'any',
                has_signatures: 'any',
            },
        },
        {
            id: 'queued_for_verification',
            label: 'Ожидают',
            filterPayload: {
                has_verified: 'no',
                has_effective: 'yes',
                has_signatures: 'any',
            },
        },
        {
            id: 'missing_effective',
            label: 'Не выбрана подпись',
            filterPayload: {
                has_verified: 'any',
                has_effective: 'no',
                has_signatures: 'yes',
            },
        },
        {
            id: 'missing_signature',
            label: 'Подпись не загружена',
            filterPayload: {
                has_verified: 'any',
                has_effective: 'any',
                has_signatures: 'no',
            },
        },
    ];
    private readonly DEFAULT_FILTER_PRESET: string = 'queued_for_verification';
    private firstRun: boolean = true;

    constructor(
        private api: LaraService,
        private datePipe: DatePipe,
        private dialog: DialogService,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        this.rights.verifySignature = this.helpers.checkPermissions('orders:verify-signature');
        this.rights.reVerifySignature = this.helpers.checkPermissions('orders:re-verify-signature');
        this.activeFilter = this.filterPresets.find(filter => filter.id === this.DEFAULT_FILTER_PRESET);

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        this.formFilter = new FormGroup({
            date_start: new FormControl(yesterday),
            date_end: new FormControl(today),
        });

        this.getOrdersSignaturesVerificationQueue();
    }

    /**
     * Обработка перехода по страницам списка
     * @param skip
     * @param take
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        if (this.loading) {
            return;
        }

        this.ordersInQueueGridState.skip = skip;
        this.ordersInQueueGridState.take = take;

        this.getOrdersSignaturesVerificationQueue();
    }

    /**
     * Обработка изменения значений фильтра
     */
    public onFormFilterChange(): void {
        if (this.loading) {
            return;
        }

        this.getOrdersSignaturesVerificationQueue();
    }

    /**
     * Обработка клика по кнопке "Обновить"
     */
    public onRefreshListClick(): void {
        if (this.loading) {
            return;
        }

        this.getOrdersSignaturesVerificationQueue();
    }

    /**
     * Возвращает признак недоступности чекбокса выбора заказа
     * @param queueItem
     */
    public isDisabledMassActionCheckbox(queueItem: IOrdersSignaturesVerificationQueueDataItem) {
        if (!this.rights.verifySignature && !this.rights.reVerifySignature) {
            return true;
        }

        if (queueItem.effective_signature && !this.rights.reVerifySignature) {
            return true;
        }

        if (!queueItem.effective_signature) {
            return true;
        }

        return false;
    }

    /**
     * Обработка клика по чекбоксу выбора заказа для массовой верификации
     * @param event
     */
    public onMassActionSelectionChange(event) {
        if (event.selectedRows.length) {
            event.selectedRows.map(unit => {
                const queueItem: IOrdersSignaturesVerificationQueueDataItem = unit.dataItem;
                this.selectedOrders.push({
                    order_id: queueItem.order_id,
                    signature_id: queueItem.effective_signature.id,
                });
            });
        }

        if (event.deselectedRows.length) {
            event.deselectedRows.map(unit => {
                const queueItem: IOrdersSignaturesVerificationQueueDataItem = unit.dataItem;
                this.selectedOrders = this.selectedOrders.filter(selectedItem => selectedItem.order_id !== queueItem.order_id);
            });
        }

        this.massActionPanelVisible = !!this.selectedOrders.length;
    }

    /**
     * Клик по кнопке массовой верификации подписей
     */
    public onMassVerify() {
        if (this.massVerifyInProgress) {
            return;
        }

        this.massVerify();
    }

    /**
     * Загрузка страницы очереди верификации подписей
     * @private
     */
    private getOrdersSignaturesVerificationQueue(): void {
        this.loading = true;
        const filter = {
            take: this.ordersInQueueGridState.take,
            skip: this.ordersInQueueGridState.skip,
            ...this.activeFilter.filterPayload,
            date_start: this.datePipe.transform(this.formFilter.get('date_start').value, 'yyyy-MM-dd'),
            date_end: this.datePipe.transform(this.formFilter.get('date_end').value, 'yyyy-MM-dd'),
        };

        this.api.getOrdersSignaturesVerificationQueue(filter).subscribe((responsePayload: IOrdersSignaturesVerificationQueueResponse) => {
            this.stats = responsePayload.stats;

            if (this.firstRun) {
                this.firstRun = false;

                if (this.seekForBestFilterPreset()) {
                    this.getOrdersSignaturesVerificationQueue();
                    return;
                }
            }

            this.loading = false;

            responsePayload.data = responsePayload.data.map((item: IOrdersSignaturesVerificationQueueDataItem) => {
                if (item.effective_signature) {
                    item.effective_signature = new OrderSignature(item.effective_signature);
                }

                item.other_signatures = item.other_signatures.map(signature => {
                    return new OrderSignature(signature);
                });

                return item;
            });

            this.ordersInQueueGridState.take = responsePayload.per_page;
            this.ordersInQueueGridState.skip = responsePayload.skip;

            this.ordersInQueueGridData = responsePayload;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Массовая верификация подписей
     * @private
     */
    private massVerify() {
        this.massVerifyInProgress = true;
        this.api.orderSignaturesMassVerify(this.selectedOrders).subscribe(data => {
            this.massVerifyInProgress = false;
            this.selectedOrders = [];
            this.ordersInQueueGridSelection = [];
            this.massActionPanelVisible = false;

            let successCount = 0;
            let errors = [];
            let anonErrors = [];
            let dialogMessages = [];

            data.map(item => {
                if ('undefined' !== typeof item.error) {
                    if ('undefined' === typeof item.order) {
                        anonErrors.push('<li><strong>Запись №' + item.order_id + '</strong>: ' + item.error + '</li>');
                        return;
                    }

                    errors.push('<li><strong>Заказ ' + item.order.uid + '</strong>: ' + item.error + '</li>');
                    return;
                }

                successCount++;
            });

            dialogMessages.push('<p><strong>Успешно верифицировано</strong>: ' + successCount + '</p>');

            if (errors.length > 0) {
                dialogMessages.push('<p><strong>Ошибки</strong>: ' + errors.length + '</p>');
                dialogMessages.push('<ol>' + errors.join(' ') + '</ol>');
            }

            if (anonErrors.length > 0) {
                dialogMessages.push('<p><strong>Другие ошибки</strong>: ' + anonErrors.length + '</p>');
                dialogMessages.push('<ol>' + anonErrors.join(' ') + '</ol>');
            }

            if (dialogMessages.length > 0) {
                this.dialog.alert('<div class="text-left">' + dialogMessages.join('') + '</div>', 500, 400, true);
            }

            this.getOrdersSignaturesVerificationQueue();
        }, () => {
            this.massVerifyInProgress = false;
        });
    }

    /**
     * Выбирает подходящий фильтр глядя на статистику заказов
     * @private
     */
    private seekForBestFilterPreset(): boolean {
        if (this.activeFilter.id != this.DEFAULT_FILTER_PRESET) {
            return false;
        }

        let newFilterPreset = this.DEFAULT_FILTER_PRESET;

        if (0 === this.stats.not_verified_count) {
            if (0 === this.stats.no_effective_count) {
                if (this.stats.missing_count) {
                    newFilterPreset = 'missing_signature';
                }
            } else {
                newFilterPreset = 'missing_effective';
            }
        }

        if (newFilterPreset === this.activeFilter.id) {
            return false;
        }

        this.activeFilter = this.filterPresets.find(filter => filter.id === newFilterPreset);

        return true;
    }
}
