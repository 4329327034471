import {Component, OnInit, } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HelpersService} from '../../../service/helpers.service';
import {parseNumber} from '@progress/kendo-angular-intl';
import {ClientUser} from '../../../service/models';
import {NotifierService} from '../../../service/notifier.service';
import {LaraService} from '../../../service/lara.service';

@Component({
    selector: 'app-client-users-create',
    templateUrl: './client-users-create.component.html',
    styleUrls: ['./client-users-create.component.scss']
})
export class ClientUsersCreateComponent implements OnInit {
    public formClientUser: FormGroup;
    public clientId = null;
    public loading = false;

    constructor(
        private api: LaraService,
        private route: ActivatedRoute,
        private router: Router,
        public helper: HelpersService,
        private notifier: NotifierService,
    ) {
        this.formClientUser = new FormGroup(
            {
                is_active: new FormControl(1),
                login: new FormControl(null, Validators.required),
                email: new FormControl(null, Validators.required),
                name: new FormControl(null, Validators.required),
                phone: new FormControl(null, Validators.required),
                password: new FormControl(null, Validators.required),
            }
        );
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.clientId = parseNumber(params.get('client_id'));
            if (!this.clientId) {
                alert('Клиент для которого требуется создание нового пользователя не выбран!');
                this.router.navigate(['/']);
            }
        });
    }


    public onSubmit() {
        if (!this.formClientUser.valid) {
            this.notifier.openNotifier('Заполните все обязательные поля!')
        }
        const query = this.formClientUser.value;

        this.loading = true;
        this.api.createClientUser(this.clientId, query).subscribe(data => {
            this.loading = false;
            this.notifier.openNotifier(data.message);
        }, error => {
            this.loading = false;
        });
    }
}
