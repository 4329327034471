import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {ClientInfo, Pvz, SubAgent, User} from './models';
import {ApiClientInfo, ApiResult} from './api_result';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {EventerService} from './eventer.service';
import {Router} from '@angular/router';
import {NotifierService} from './notifier.service';

@Injectable({
    providedIn: 'root',
})

export class LaraService {
    private TOKEN_KEY = 'access_token';

    constructor(
        private http: HttpClient,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private eventer: EventerService,
        private router: Router,
        private notifier: NotifierService,
    ) {
    }

    protected headers() {
        const headers = {};

        const token = this.storage.get(this.TOKEN_KEY);
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        return headers;
    }

    /**
     * Возвращает строку для GET запроса из объекта
     * @param params
     */
    protected getQueryStringFromArray(params) {
        return Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    /**
     * GET запрос
     *
     * @param url
     * @param params
     */
    protected get(url, params = null): Observable<any> {
        return new Observable((observer) => {

            this.http.get<any>(environment.lara_api_url + url, {params: params, headers: this.headers()})
                .pipe(catchError(this.handleError.bind(this, observer)))
                .subscribe((data: ApiResult) => {
                    observer.next(data.response);
                    observer.complete();
                });
        });
    }

    /**
     * POST запрос
     *
     * @param url
     * @param param
     */
    protected post(url, param): Observable<any> {
        return new Observable((observer) => {
            this.http.post(environment.lara_api_url + url, param, {headers: this.headers()})
                .pipe(catchError(this.handleError.bind(this, observer)))
                .subscribe((data: ApiResult) => {
                    observer.next(data.response);
                    observer.complete();
                });
        });
    }


    /**
     * DELETE запрос
     *
     * @param url
     * @param params
     */
    protected delete(url, params = null): Observable<any> {
        return new Observable((observer) => {
            this.http.delete(environment.lara_api_url + url, {params: params, headers: this.headers()})
                .pipe(catchError(this.handleError.bind(this, observer)))
                .subscribe((data: ApiResult) => {
                    observer.next(data.response);
                    observer.complete();
                });
        });
    }

    protected handleError(observer: Subscriber<any>, e) {

        if (e.error) {
            if (e.error.response.error === 'This action is unauthorized.') {
                this.notifier.openNotifier('Ошибка прав доступа. Перезайдите в систему!',
                    null, {class: 'danger'});
            } else if (e.status === 501) {
                this.storage.remove(this.TOKEN_KEY);
                this.eventer.currentUserData.emit(false);
                if (e.error.response.error === 'Unauthorized') {
                    this.router.navigate(['login']);
                }
            } else {
                if (e.error.message) {
                    this.eventer.apiError.emit(e.error.message);
                } else if (e.error.response) {
                    this.eventer.apiError.emit(e.error.response.error);
                }
            }
        }


        // return error to observer
        observer.error(e);

        return throwError(e);
    }

    /**
     * Подписчик на ошибки апи
     */
    public errorEmit() {

        return this.eventer.apiError;
    }

    /**
     * список клиентов
     * */
    public getClientList(): Observable<ClientInfo[]> {
        return this.get('/clients/list');
    }

    /**
     * Список клиентов для селектора
     * @param active
     */
    public getClientListForSelector() {
        return this.get('/clients/list', {is_active: 1})
    }

    /**
     * Список направлений
     **/
    public getDistrictList() {
        return this.get('/logistic/districts');
    }

    /**
     * Список курьеров
     */
    public getCouriersList(sort, skip, pageSize) {
        return this.post('/personal/couriers/list', {sort: sort, isActive: true, skip: skip, pageSize: pageSize});
    }

    /**
     * Фильтр по курьерам
     * @param name
     * @param type
     * @param phone
     */
    public setCouriersListFilter(sort, name, type, phone, isActive, hubs, skip, pageSize) {
        return this.post('/personal/couriers/list', {
            sort: sort,
            fullName: name,
            type: type,
            phone: phone,
            isActive: isActive,
            hubs: hubs,
            skip: skip,
            pageSize: pageSize,
        });
    }

    /**
     * Создание курьера
     * @param params
     */
    public createCourier(params) {
        return this.post('/personal/courier/new', params);
    }

    /**
     * Информация о курьере
     */
    public getCourierInfo(user_id) {
        return this.get('/personal/courier/' + user_id);
    }

    /**
     * Обновление информации о курьере
     * @param data
     */
    public setCourierInfo(data) {
        return this.post('/personal/courier/update', data)
    }

    /**
     * Отправка СМС с логином и паролем курьеру
     * @param id
     */
    public sendCourierPassword(id) {
        return this.post('/personal/courier-password/' + id, '');
    }

    /**
     * Предложение возможных логинов для пользователя по ФИО
     * @param params
     */
    public suggestLogins(params) {
        return this.post('/members/suggest-logins', params);
    }

    /**
     * Проверка занятости логина
     * @param params
     */
    public checkLoginAvailable(params) {
        return this.post('/members/check-login-available', params);
    }

    /**
     * Список штрафов
     * @param skip
     * @param pageSize
     */
    public getPenaltyList(skip, pageSize, onlyDeleted, hub_id) {
        return this.post('/personal/penalty/list', {skip: skip, pageSize: pageSize, onlyDeleted: onlyDeleted, hub_id});
    }

    /**
     * Список причин штрафов
     */
    public getPenaltyReasonList() {
        return this.get('/personal/penalty/reason/list');
    }

    /**
     * Обновление причины
     */
    public updatePenaltyReason(id, data) {
        return this.post('/personal/penalty/reason/update/' + id, data);
    }

    /**
     * Удаление причины
     */
    public removePenaltyReason(id) {
        return this.post('/personal/penalty/reason/remove/' + id, []);
    }

    /**
     * Удаление причины
     */
    public addPenaltyReason(data) {
        return this.post('/personal/penalty/reason/add', data);
    }

    public complaintAndPenalty(data) {
        return this.post('/personal/complaintAndPenalty', data);
    }

    /**
     * Добавить штраф
     * @param data
     */
    public addPenalty(data) {
        return this.post('/personal/penalty/add', data);
    }

    /**
     * Подтвердить штраф
     * @param penaltyId
     */
    public confirmPenalty(penaltyId) {
        return this.get('/personal/penalty/confirm/' + penaltyId);
    }

    /**
     * Удалить штраф
     * @param penaltyId
     */
    public deletePenalty(penaltyId, reason) {
        return this.post('/personal/penalty/delete/' + penaltyId, {'delete_reason': reason});
    }

    public cancelPenaltyConfirmation(penaltyId) {
        return this.post('/personal/penalty/cancelPenaltyConfirmation/' + penaltyId, {});
    }

    /**
     * Логи штрафа
     * @param penaltyId
     */
    public getPenaltyLogs(penaltyId) {
        return this.get('/personal/penalty/logs/' + penaltyId);
    }

    /**
     * Список причин для штрафов
     */
    public getRulesList() {
        return this.get('/personal/rules/list');
    }

    /**
     * Инфа про одного клиента
     * @param clientId
     */
    public getClientInfo(clientId): Observable<ApiClientInfo> {
        return this.get('/clients/client/' + clientId);
    }

    /**
     * Обновление данных клиента
     * @param clientId
     * @param data
     */
    public updateClient(clientId, data) {
        return this.post('/clients/client/' + clientId, data);
    }

    /**
     * Затягивание клиента из старой базы
     * @deprecated
     * @param uid
     */
    public loadOldClient(uid) {
        return this.get('/clients/createOldClient/' + uid);
    }

    /**
     * @deprecated
     * обновление старых клиентов
     */
    public updateOldClients() {
        return this.get('/clients/updateOldClients');
    }

    /**
     * список субагентов
     * @param isPvz - загружать ли список их пвз
     */
    public getSubAgentList(isPvz = false): Observable<SubAgent[]> {
        return this.get('/agents/list', {
            'is_pvz': isPvz,
        });
    }

    /**
     * список ПВЗ
     */
    public getPvzList(): Observable<Pvz[]> {
        return this.get('/agents/pvz');
    }

    /**
     * авторизация с сохранение токена
     * @param email
     * @param password
     */
    public login(email, password) {
        return new Observable((observer) => {
            this.post('/members/login', {
                email: email,
                password: password,
            }).subscribe((data: any) => {
                this.setToken(observer, data.access_token);
                this.eventer.currentUserData.emit(true);
            });
        });
    }

    /**
     * обновление токена
     */
    public updateToken() {
        return new Observable((observer) => {
            this.get('/members/refresh').subscribe((data: any) => {
                this.setToken(observer, data.access_token);
            });
        });
    }

    protected setToken(observer, token) {
        this.storage.set(this.TOKEN_KEY, token);
        observer.next(true);
        observer.complete();
    }

    public getToken() {
        return this.storage.get(this.TOKEN_KEY) || '';
    }

    /**
     * выход
     */
    public logout() {
        this.storage.remove(this.TOKEN_KEY);
        return this.get('/members/logout');
    }


    /**
     * текщий пользователь
     */
    public currentUser(): Observable<User> {
        if (this.storage.get(this.TOKEN_KEY)) {
            return this.get('/members/get');
        }
        return new Observable((observer) => {
            observer.next(null);
            observer.complete();
        });
    }

    /**
     * Данные пользователи по его id
     * @param id
     */
    public getUserInfo(id) {
        return this.get('/members/user/' + id);
    }

    public getCourierFullName(courier_id) {
        return this.get('/personal/courier-old-id/' + courier_id);
    }

    /**
     * список пользователей
     * */
    public getUserList(): Observable<User[]> {
        return this.get('/members/list');
    }

    /**
     * Отправляет код для логина в приложение
     * @param params
     */
    getConfirmationCode(params) {
        return this.post('/courier/getConfirmationCode', params)
    }

    /**
     * Залогиним курьера, если ввёл правильный код
     * @param params
     */
    loginWithCode(params) {
        return new Observable((observer) => {
            this.post('/courier/loginWithCode', params).subscribe(
                (data: any) => {
                    this.setToken(observer, data.access_token);
                    this.eventer.currentUserData.emit(true);
                });
        });
    }

    /**
     * заказ детально
     * @param orderId
     */
    public getOrder(orderId) {
        return this.get('/order/detail/' + orderId);
    }

    /**
     * Чеки по заказу
     * @param uid
     */
    public getOrderReceipts(uid) {
        return this.get('/order/receipts/' + uid);
    }

    /**
     * Исправление чеков
     * @param uid
     */
    public correctReceipts(uid) {
        return this.post('/order/receipts/correctReceipts/' + uid, {});
    }

    /**
     * Поиск заказов
     * @param data
     * @param filter
     */
    public searchOrders(data, filter = {}) {
        return this.get('/order/search', {...filter, find: data});
    }

    /**
     * Поиск заказов
     * @param data
     * @param filter
     */
    public searchOrdersByType(data, filter) {
        return this.get('/order/search', filter);
    }

    /**
     * выгрузка логов
     * @param orderId
     */
    public getLogs(orderId) {
        return this.get('/order/history/' + orderId);
    }

    /**
     * выгрузка логов сканирования штрих-кодов
     * @param orderId
     */
    public getBarCodeScanLog(orderId) {
        return this.get('/order/getBarCodeScanLog/' + orderId);
    }

    /**
     * Получение актов приёма-передачи или излишков
     * для принятых мест
     * @param orderUid
     */
    public getMainAndExcessActs(orderUid) {
        return this.get('/order/getMainAndExcessActs/' + orderUid);
    }

    public getDocuments(orderId) {
        return this.get('/order/documents/' + orderId);
    }

    /**
     * Отправка смс по заказу на почту или телефон
     * @param uid - заказа
     * @param message - сообщение
     * @param withParams - Отправка сообщения осушествляется по параметрам или данные достаются из карточки клиента
     * @param isSendOnEmail - Отправляет смску по почте или телефону
     * @param contactData - Данные почты или телефона
     * @param subject - Тема письма
     */
    public sendOrderMessage(uid, message, withParams = false, isSendOnEmail = true, contactData: string = null, subject: string = null) {
        return this.post('/notifications/sms', {
            order_uid: uid,
            message: message,
            withParams: withParams,
            isSendOnEmail: isSendOnEmail,
            contactData: contactData,
            subject: subject,
        });
    }

    /**
     * Выгрузка выплат картой
     * @param orderId
     */
    public getOrderCardPayments(orderId) {
        return this.get('/order/cardPayments/' + orderId)
    }

    /**
     * подписи по заказу
     * @param orderUid
     */
    public getOrderSignatures(orderUid: string) {
        return this.get('/signature/' + orderUid)
    }

    /**
     * похожие подписи
     * @param orderUid
     * @param params
     */
    public getSimilarSignatures(orderUid: string, params) {
        return this.post('/signature/getSimilarSignatures/' + orderUid, params)
    }

    /**
     * Клонирование подписи в альбом заказа
     * @param orderUid
     * @param signatureId
     */
    public cloneSignatureToOrder(orderUid: string, signatureId: number) {
        return this.post('/signature/cloneSignatureToOrder/' + orderUid, {
            signature_id: signatureId,
        });
    }

    /**
     * Отправка подписей на почту
     * @param params
     */
    sendSignOnMail(params) {
        return this.post('/signature/send-signature', params)
    }

    /**
     * Отправка маршрутного листа по заказу с подписью на почту
     * @param params
     */
    sendMlistWithSignOnMail(params) {
        return this.post('/signature/send-mlist-signature', params)
    }

    /**
     * Установка эффективной подписи заказа
     * @param orderUid
     * @param signatureId
     */
    public setEffectiveOrderSignature(orderUid: string, signatureId: number) {
        return this.post('/signature/setEffectiveOrderSignature/' + orderUid, {
            id: signatureId,
        });
    }

    /**
     * Установка статуса "Верифицирована" для подписи заказа
     * @param orderUid
     * @param signatureId
     */
    public markOrderSignatureVerified(orderUid: string, signatureId: number) {
        return this.post('/signature/markOrderSignatureVerified/' + orderUid, {
            id: signatureId,
        });
    }

    /**
     * Статус на складе
     * @param orderId
     */
    public setStatusStorage(orderId) {
        return this.post('/order/reset/storage/' + orderId, {});
    }

    /**
     * Статус на доставке
     * @param orderId
     */
    public setStatusDelivery(orderId) {
        return this.post('/order/reset/delivery/' + orderId, {});
    }

    /**
     * забор детально
     * @param orderId
     */
    public getZorder(zorderId) {
        return this.get('/zorder/detail/' + zorderId);
    }

    /**
     * Логи по забору
     * @param zorderId
     */
    public getLogsZorder(zorderId) {
        return this.get('/zorder/history/' + zorderId);
    }


    /** Все подстатусы */
    public getAllSubstatuses() {
        return this.get('/zorder/allSubstatuses');
    }

    /**
     * забор, смена даты и подстатуса
     * @param orderId
     */
    public setDateSubstatusZorder(date, substatus, zid) {
        return this.post('/zorder/setNewDate', {zorderId: zid, substatus: substatus, date: date});
    }

    /**
     * забор, смена веса и обьёма
     * @param zorderId
     */
    public setWeightCapacityZorder(weight, capacity, zid) {
        return this.post('/zorder/edit/' + zid, {weight: weight, capacity: capacity});
    }

    /**
     * Забор смена статуса
     * @param zid
     * @param status
     */
    public setStatusZorder(zid, status) {
        return this.post('/zorder/setStatusZorder/' + zid, {status: status});
    }


    /**
     * забор, кастомные изменения
     * @param zorderId
     */
    public setNewDataZorder(zid, data) {
        return this.post('/zorder/edit/' + zid, data);
    }

    /**
     * забор, смена координат
     * @param zorderId
     */
    public setCoordinatesZorder(coordinates, zid) {
        return this.post('/zorder/edit/' + zid, {coordinates: coordinates});
    }

    /**
     * Редактирование адреса забора
     * @param zid
     * @param data
     */
    public setAddressZorder(zid, data) {
        return this.post('/zorder/updateAddress/' + zid, data);
    }

    /**
     * Нераспознанные адреса
     */
    public getBadAddresses() {
        return this.get('/logistic/getBadAddress');
    }

    /**
     * Заказы с плохими адресами
     */
    public getBadAddressedOrders(params) {
        return this.get('/logistic/getBadAddressedOrders?' + this.getQueryStringFromArray(params));
    }

    /**
     * Нераспознанные адреса
     */
    public checkLogist() {
        return this.get('/logistic/check');
    }

    /**
     * Редактирование адреса в заказе
     * @param address
     */
    public setAddressOrder(uid, address, change_address_reason) {
        return this.post('/order/edit/' + uid, {address: address, change_address_reason: change_address_reason})
    }

    /**
     * Редактирование квартиры/офиса/домофона в заказе
     * @param address
     */
    public setOfficeAndIntercomOrder(uid, office, intercom) {
        return this.post('/order/edit/' + uid, {office, intercom})
    }

    /**
     * Редактирование интервала в заказе
     * @param interval
     */
    public setIntervalOrder(uid, interval, reason) {
        return this.post('/order/setDeliveryInterval/' + uid, {
            delivery_time: interval,
            change_interval_reason: reason,
        });
    }

    /**
     * Специальный интервал
     * @param uid
     * @param dt_start
     * @param dt_end
     */
    public setCustomInterval(uid, dt_start, dt_end, reason) {
        return this.post('/order/setCustomInterval/' + uid, {
            delivery_time_start: dt_start,
            delivery_time_end: dt_end,
            change_interval_reason: reason,
        })
    }

    /**
     * Редактирование времени возврата на склад
     * @param weight
     */
    public setExpectedReturnTimeOrder(uid, expected_return_time) {
        return this.post('/order/edit/' + uid, expected_return_time);
    }

    /**
     * Редактирование веса в заказе
     * @param weight
     */
    public setWeightOrder(uid, weight) {
        return this.post('/order/edit/' + uid, {weight: weight});
    }

    /**
     * Подтверждение или отмена подтверждения проверки веса
     * @param weightValidated
     */
    public setOrderWeightValidated(uid, weightValidated) {
        return this.post('/order/edit/' + uid, {weight_validated: weightValidated});
    }

    /**
     * Установка или снятие признака негабаритного заказа
     * @param isBulky
     */
    public setOrderIsBulky(uid, is_bulky) {
        return this.post('/order/edit/' + uid, {
            is_bulky,
        });
    }

    public setOrderAddressValidated(uid) {
        return this.post('/order/edit/' + uid, {suspicious_address: 1});
    }

    /**
     * Редактирование фактического получателя заказа
     * @param uid
     * @param recipientName
     * @param recipientNameClear
     */
    public updateRecipientName(uid, recipientName, recipientNameClear) {
        return this.post('/order/edit/' + uid, {
            recipient_name: recipientName,
            recipient_name_clear: recipientNameClear,
        });
    }

    /**
     * Редактирование объёма в заказе
     * @param dimension
     */
    public setDimensionOrder(uid, dimension) {
        return this.post('/order/edit/' + uid, {dimension: dimension});
    }

    /**
     * Редактирование комментария от КС
     * @param uid
     * @param comment
     */
    public setOperatorComment(uid, comment) {
        return this.post('/order/edit/' + uid, {operator_comment: comment});
    }

    /**
     * Редактирование параметров заказа
     * @param uid
     * @param data
     */
    public editOrder(uid, data) {
        return this.post('/order/edit/' + uid, data);
    }

    /**
     * Смена типа оплаты
     * @param uid
     * @param data
     */
    public setPaymentType(uid, data) {
        return this.post('/order/setPaymentType/' + uid, data);
    }

    /**
     * Редактирование даты доставки в заказе
     * @param delivery_date
     */
    public setDeliveryDateOrder(uid, date, substatus) {
        return this.post('/order/setDeliveryDate/' + uid, {delivery_date: date, substatus_id: substatus});
    }

    /**
     * Повторная доставка
     * @param uid
     * @param new_date
     */
    public reDeliveryOrder(uid, new_date) {
        return this.post('/order/repeatDelivery', {uid: uid, delivery_date: new_date});
    }

    /**
     * запрос на занос кгт
     * @param uid
     * @param floor
     * @param lift
     */
    public setKGT(uid, floor, lift, lifting) {
        const data: any = {};

        data.floor = floor;
        data.lift = lift;
        data.option_skid_kgt = lifting;

        return this.post('/order/setKGT/' + uid, data);
    }

    /**
     * Список транзитов для мест заказа
     * @param uid
     */
    public getOrderTransits(uid) {
        return this.get('/order/getOrderTransits/' + uid);
    }

    /**
     * Редактирование курьера
     * @param uid
     * @param courier_id
     */
    public updateCourierOrder(uid, courier_id) {
        return this.post('/order/reCourier', {uid: uid, courier_id: courier_id, return_full_order_info: true});
    }

    /**
     * Редактирование координатов в заказе
     * @param coordinates
     */
    public setCoordinatesOrder(coordinates, uid) {
        return this.post('/order/edit/' + uid, {coordinates: coordinates});
    }

    /**
     * Редактирование опций заказа
     */
    public updateOptionsOrder(uid, data) {
        return this.post('/order/edit/' + uid, data);
    }

    /**
     * Сделать заказ важным
     */
    public makeImportant(uid, data) {
        return this.post('/order/makeImportant/' + uid, data);
    }

    /**
     * Изменение признака проверки заказа
     *
     * @param uid
     * @param value
     */
    public changeIsVerified(uid, value) {
        return this.post('/order/changeIsVerified/' + uid, {is_verified: value});
    }

    /**
     * Редактирование товарных позиций
     */
    public updateSettingsGoods(uid, data) {
        return this.post('/order/changeGoods/' + uid, data);
    }


    /**
     * Список заказов без акта ПП
     * @param clientUid
     */
    public getOrdersWithoutTransAct(clientUid) {
        return this.get('/storage/acts/getOrdersWithoutTransAct/' + clientUid);
    }

    /**
     * Создание акта ПП
     * @param data
     */
    public createTransAct(data) {
        return this.post('/storage/acts/createTransAct', data);
    }

    /**
     * Начало сборки второй смены
     */
    public startSecondShiftAssembling() {
        return this.post('/storage/second-shift/startAssembling', []);
    }

    /**
     * Информация о сборке второй смены
     */
    public getSecondShiftAssemblingInfo() {
        return this.post('/storage/second-shift/getAssemblingInfo', []);
    }

    /**
     * Информация о сборке курьера второй смены
     */
    public getSecondShiftCourierAssemblingInfo(courierId: number) {
        return this.post('/storage/second-shift/getAssemblingCourierInfo', {'courier_id': courierId});
    }

    /**
     * Пересоздание акта
     * @param courierId
     */
    public secondShiftActRecreate(courierId: number) {
        return this.post('/storage/second-shift/recreateCourierAct', {'courier_id': courierId});
    }

    /**
     * Список Тарифов курьеров
     */
    public getCouriersTariffs(date, hub_id) {
        return this.get('/logistic/getTarificatorSettings/' + date + '/' + hub_id);
    }

    /**
     * Тарификатор
     */
    public courTarSettingsMinSalary() {
        return this.get('/dictionaries/getIntegers/courier_tar_settings__min_salary');
    }

    /**
     * Проверка праздничного дня
     * @param date
     */
    public getHolidayDate(date) {
        return this.get('/logistic/isHoliday/' + date)
    }

    /**
     * Изменить статус дня
     * @param date
     * @param status
     */
    public updateHolidayStatus(date, status) {
        return this.get('/logistic/setHoliday/' + date + '/' + status)
    }

    /**
     * Смена тарифов курьера
     * @param date
     * @param courier_id
     * @param pay_type
     */
    public updateCourierTariff(data) {
        return this.post('/logistic/updateTarificatorSetting', data)
    }

    /**
     * Список проблем
     */
    public getProblemList(filter) {
        return this.post('/logistic/problems', filter);
    }

    /**
     * Список отсортированных проблем
     */
    public getProblemListById(id) {
        return this.get('/logistic/problems/' + id);
    }

    /**
     * Взять проблему в работу
     * @param problemId
     */
    public getProblemInWork(problemId) {
        return this.post('/logistic/getProblemInWork', {problemId: problemId});
    }

    /**
     * Взять КГТ проблему в работу
     * @param id
     */
    public getKgtInWork(id) {
        return this.post('/logistic/getKGTInWork', {orderId: id});
    }

    /**
     * Решить проблему
     * @param problemId
     */
    public resolveProblem(problemId) {
        return this.post('/logistic/resolveProblem', {problemId: problemId});
    }

    /**
     * Установка отмены с подстатусом
     * @param uid
     * @param substatus
     */
    public setCancelSubstatus(uid, substatus) {
        return this.post('/order/cancelOrder', {uid: uid, substatus_id: substatus});
    }

    public setPartialCancelOrder(uid, refuseGoodIds, clientReturnGoodIds, refuseServiceIds) {
        return this.post('/order/setFailPartial/' + uid, {
            refuse_good_ids: refuseGoodIds,
            client_return_good_ids: clientReturnGoodIds,
            refuse_service_ids: refuseServiceIds,
        });
    }

    public setRefusedCancelOrder(uid, clientReturnGoodIds) {
        return this.post('/order/setRefused/' + uid, {
            client_return_good_ids: clientReturnGoodIds,
        });
    }

    /**
     * Отправка сообщения о недозвоне
     * @param uid
     * @param client_mail
     */
    public sendDialOutMail(uid) {
        return this.post('/order/sendDialOutMail/' + uid, {});
    }

    /**
     * Шаблоны push уведомлений
     */
    public getPushTemplates() {
        return this.get('/notifications/push-templates');
    }

    /**
     * Установить доставку день в день
     * @param uid
     * @param params
     */
    public setSamedayDelivery(uid, params) {
        return this.post('/order/setSamedayDelivery/' + uid, params);
    }

    /**
     * Отправка push уведомлений
     * @param uid
     * @param subject
     * @param content
     * @param hubs
     */
    public sendPushNotification(uid: (number | number[] | null), subject, content, hubs: (number[] | null) = null) {
        return this.post('/notifications/push', {
            courier_id: /boolean|number|string/.test(typeof uid) ? uid : 0,
            courier_ids: /boolean|number|string/.test(typeof uid) ? null : uid,
            subject,
            content,
            hubs,
        });
    }

    /**
     * Отправка sms уведомлений курьерам
     * @param courier_ids
     * @param content
     * @param order_uid
     */
    public sendCourierSmsNotification(courier_ids: number[], content, order_uid: string = null) {
        return this.post('/notifications/courierSms', {
            courier_ids: courier_ids,
            content: content,
            order_uid: order_uid,
        });
    }

    /**
     * Получить отправленные уведомления
     * @param data
     */
    public getDispatchedNotifications(data) {
        return this.post('/notifications/getDispatchedNotifications', data)
    }

    /**
     * Кол-во проблем
     */
    public getProblemsCount() {
        return this.get('/logistic/problems-count');
    }

    /**
     * Очередь верификации подписей
     * @param params
     */
    public getOrdersSignaturesVerificationQueue(params) {
        return this.post('/signature/verificationQueue/getQueue', params);
    }

    /**
     * Основная статистика очереди верификации подписей
     */
    public getOrdersSignaturesVerificationQueueStats() {
        return this.get('/signature/verificationQueue/getQueueStats');
    }

    /**
     * Массовая верификация подписей
     * @param params
     */
    public orderSignaturesMassVerify(params) {
        return this.post('/signature/verificationQueue/massVerify', params);
    }

    /**
     * Звонок через манго
     * @param phone
     */
    public makeCall(phone) {
        return this.post('/mango/makeCall', {phone: phone})
    }

    /**
     * Получить все роли
     */
    public getRoles() {
        return this.get('/members/roles');
    }

    /**
     * Получить детали роли
     * @param id
     */
    public getRoleDetail(id) {
        return this.get('/members/roles/' + id);
    }


    /**
     * Получить права пользователей
     */
    public getMembersPermissions() {
        return this.get('/members/permissions');
    }

    /**
     * Получить сгруппированные права пользователей
     */
    public getMembersPermissionsGroups() {
        return this.get('/members/permission-groups');
    }

    /**
     * Установка прав для роли
     * @param id
     * @param data
     */
    public setRolePermissions(id, data) {
        return this.post('/members/roles/' + id, data);
    }

    /**
     * Создать роль
     * @param data
     */
    public createRole(data) {
        return this.post('/members/roles', data);
    }

    /**
     * Создать разрешение
     * @param data
     */
    public createPermission(data) {
        return this.post('/members/permissions', data);
    }

    /**
     * Пересчёт забора
     * @param z_id
     */
    public zorderRecalc(z_id) {
        return this.post('/zorder/recalc/' + z_id, '');
    }

    public orderRecalc(uid) {
        return this.post('/order/reCalculate/' + uid, '');
    }

    /**
     * Статистика сборки курьеров
     * @param data
     */
    public getCouriersShipingReport(data) {
        return this.post('/statistics/getCouriersShipingReport', data);
    }

    /**
     * Получение возможных номеров для смены
     */
    public getExtensions() {
        return this.get('/mango/extensions');
    }

    /**
     * Установка номера
     * @param num
     */
    public setExtension(num) {
        return this.post('/mango/setExtension', {extension: num});
    }

    public getRegionalOrders(data) {
        return this.post('/logistic/getRegionalOrders', data);
    }

    /**
     * Список причин отказа на месте
     */
    public getRefuseReasonsList() {
        return this.get('/logistic/getRefuseReasonsList');
    }

    /**
     * Список неподтверждённых заказов с отказом на месте
     * @param data
     */
    public getRefusedOrders(data) {
        return this.post('/logistic/getRefusedOrders', data);
    }

    /**
     * Размер списка неподтверждённых заказов с отказом на месте
     * @param data
     */
    public getRefusedOrdersQueueStats(data) {
        return this.post('/logistic/getRefusedOrdersQueueStats', data);
    }

    /**
     * Подтверждение заказа с отказом на месте
     * @param data
     */
    public confirmRefusedOrders(data) {
        return this.post('/logistic/confirmRefusedOrders', data);
    }

    /**
     * Список хабов
     */
    public getHubsList() {
        return this.get('/hubs/list');
    }

    /**
     * Список хабов с интервалами доставки
     */
    public getHubsListWithDeliveryIntervals() {
        return this.get('/hubs/listWithDeliveryIntervals');
    }

    /**
     * Список курьеров-пятитонников
     * @param hub_id Фильтровать по хабу
     */
    public getCargoCouriersList(hub_id = null) {
        return this.get('/logistic/cargo-workdays/getCargoCouriersList' + (hub_id ? '?hub_id=' + hub_id : ''));
    }

    public getPerenosCouriersList(hub_id = null) {
        return this.get('/personal/perenosCouriers' + (hub_id ? '?hub_id=' + hub_id : ''));
    }

    public getPickupPointCouriersList(hub_id = null) {
        return this.get('/personal/pickupPointCouriers' + (hub_id ? '?hub_id=' + hub_id : ''));
    }


    /**
     * Список тарифов курьеров-пятитонников
     */
    public getCargoTariffsList() {
        return this.get('/logistic/cargo-workdays/getCargoTariffsList');
    }

    /**
     * Список рабочих дней курьеров
     * @param filters
     */
    public getCouriersWorkdays(filters) {
        return this.post('/logistic/cargo-workdays/getCouriersWorkdays', filters);
    }

    /**
     * Получение актуальной записи рабочего дня курьера
     * @param courierId
     * @param date
     */
    public getCourierCargoWorkday(courierId, date) {
        return this.get('/logistic/cargo-workdays/getCargoCourierWorkday/' + courierId + '/' + date);
    }

    /**
     * Обновление рабочего дня курьера-Пятитонника
     * @param courierId
     * @param date
     * @param type
     * @param data
     */
    public updateCourierCargoWorkday(courierId, date, type, data) {
        return this.post('/logistic/cargo-workdays/updateCargoCourierWorkday/' + courierId + '/' + date + '/' + type, data);
    }

    /**
     * Подтверждение показателей рабочего дня курьера для дальнейшей выплаты зарплаты
     * @param courierId
     * @param date
     */
    public approveCargoCourierWorkday(courierId, date) {
        return this.post('/logistic/cargo-workdays/approveCargoCourierWorkday/' + courierId + '/' + date, {});
    }

    /**
     * Отмена подтверждения показателей рабочего дня курьера
     * @param courierId
     * @param date
     */
    public unApproveCargoCourierWorkday(courierId, date) {
        return this.post('/logistic/cargo-workdays/unApproveCargoCourierWorkday/' + courierId + '/' + date, {});
    }

    /**
     * Аннулирование рабочего дня курьера
     * @param courierId
     * @param date
     */
    public cancelCargoCourierWorkday(courierId, date) {
        return this.post('/logistic/cargo-workdays/cancelCargoCourierWorkday/' + courierId + '/' + date, {});
    }

    /**
     * Отмена аннулирования рабочего дня курьера
     * @param courierId
     * @param date
     */
    public unCancelCargoCourierWorkday(courierId, date) {
        return this.post('/logistic/cargo-workdays/unCancelCargoCourierWorkday/' + courierId + '/' + date, {});
    }

    /**
     * Запрашивает вводные данные запроса к API createorder из журнала в виде пригодном для вставки в Postman в режиме Bulk Edit
     * @param recordId
     */
    public getCreateOrderInputFromHistoryRecordForPostmanBulkEdit(recordId) {
        return this.get('/order/getCreateOrderInputFromHistoryRecordForPostmanBulkEdit/' + recordId);
    }

    /**
     * Запрашивает вводные данные из журнала в виде пригодном для вставки в Postman в режиме Bulk Edit
     * @param origin
     * @param recordId
     */
    public getCommonLogRecordForPostmanBulkEdit(origin, recordId) {
        return this.get('/logger/getCommonLogRecordForPostmanBulkEdit/' + origin + '/' + recordId);
    }

    /**
     * Приход курьера на работу
     * @param oldCourierId
     */
    public loginCourier(oldCourierId) {
        return this.post('/courier/login', {old_courier_id: oldCourierId})
    }

    /**
     * Ручная отметка о приходе курьера на работу
     * @param oldCourierId
     */
    public setCourierArrivedForWork(oldCourierId) {
        return this.post('/logistic/setCourierArrivedForWork', {old_courier_id: oldCourierId})
    }

    /**
     * Получить точки курьера по ID пользователя
     */
    public getCouriersPoints(userId) {
        return this.get('/courier/getCourierPoints/' + userId)
    }

    /**
     * Получить маршрутный лист курьера по ID пользователя
     * @param courierId
     * @param date
     * @param shiftNumber
     */
    getCourierRouteList(courierId, date, shiftNumber) {
        let query = courierId;
        if (date) {
            query += '/' + date;
        }
        if (shiftNumber) {
            query += '/' + shiftNumber;
        }
        return this.get('/courier/getRouteList/' + query)
    }

    /**
     * Возвращает статус по заданиям
     * @param params
     */
    getJobsProgress(params) {
        return this.post('/jobs/getJobsProgress', params);
    }

    /**
     * Получение списка типов курьера
     */
    getCourierTypes() {
        return this.get('/courier/getCourierTypes')
    }


    /**
     * Получение пользователя клиента по clientUserId
     * @param clientUserId
     */
    public getClientUser(clientUserId: any) {
        return this.get('/client-users/' + clientUserId);
    }

    /**
     * Обновление пользователя клиента
     * @param clientUserId
     * @param query
     */
    public updateClientUser(clientUserId, query: any) {
        return this.post('/client-users/' + clientUserId, query);
    }

    /**
     * Создание пользователя клиента
     * @param clientId
     * @param query
     */
    createClientUser(clientId: any, query: any) {
        return this.post('/client-users/create/' + clientId, query);
    }

    /**
     * Получение шаблонов смс отправляемых клинтским отделом
     */
    getSmsTemplates(query) {
        return this.get('/notifications/sms-templates', query)
    }

    /**
     * Получение шаблонов email отправляемых клинтским отделом в ИМ
     */
    getImTemplates(query) {
        return this.get('/notifications/imTemplates', query)
    }

    /**
     * получение шаблона по типу
     * @param type
     */
    getAlertSMSTemplates() {
        return this.get('/notifications/getAlertSMSTemplates');
    }

    getAlertSMSCost(data) {
        return this.post('/notifications/getAlertSMSCost', data);
    }

    sendAlertSMS(data) {
        return this.post('/notifications/sendAlertSMS', data);
    }


    /**
     * Получение шаблонов уведомлений для забора отправляемых клинтским отделом
     */
    getZorderTemplates(query) {
        return this.get('/notifications/zorder-templates', query)
    }

    /**
     * Сделать заказ доставленным за вчерашний день
     * @param orderId
     * @param query
     */
    makeOrderDeliveredYesterday(orderId, query) {
        return this.post('/order/makeDeliveredYesterday/' + orderId, query)
    }

    /** Установка курьера Пятитонника при приёме заказа и приёме курьера на склад */
    public setCargoCourierWhenReceivingAtWarehouse(order_id) {
        return this.post('/order/set-cargo-courier/' + order_id, {})
    }

    /**
     * Получение списка причин для проблем
     */
    getProblemsReasonsList() {
        return this.get('/logistic/problems-reasons-list')
    }

    /** set label for order as printed */
    public setLabelPrinted(uid) {
        return this.get('/order/setLabelPrinted/' + uid);
    }

    /**
     * Получение списка статусов для курьерского графика
     */
    getCourierScheduleStateses() {
        return this.get('/logistic/courier-schedule/statuses')
    }

    /**
     * Отправка уведомлений курьеру (смс/пуш) по забору
     * @param data
     */
    sendNotificationCourierAboutZorder(data) {
        return this.post('/notifications/sendCourierNotifyAboutZorder', data)
    }

    /**
     * Попытки доставки по заказу
     * @param uid
     */
    orderDeliveries(uid) {
        return this.get('/order/deliveries/' + uid);
    }

    /**
     * Отправка сообщения на почту ИМ
     * @param data
     */
    sendEmailToIm(data) {
        return this.post('/notifications/emailToIm', data);
    }

    /**
     * Отправка сообщения на почту ИМ используя тип шаблона
     * @param data
     */
    emailToImUseType(data) {
        return this.post('/notifications/emailToImUseType', data);
    }

    /**
     * Привязка возвратного акта к забору
     * @param returnActId
     * @param zorderId
     */
    attachReturnActToZorder(returnActId, zorderId) {
        return this.post('/storage/return-acts/attachReturnActToZorder', {
            return_act_id: returnActId,
            zorder_id: zorderId,
        });
    }

    /**
     * Отвязка возвратного акта от забора
     * @param returnActId
     */
    detachReturnActFromZorder(returnActId) {
        return this.post('/storage/return-acts/detachReturnActFromZorder', {
            return_act_id: returnActId,
        });
    }

    /**
     * Список причин жалоб
     */
    getComplaintList() {
        return this.get('/personal/complaint/list');
    }

    /**
     * переводит статус товаров в карточке заказа в отказной
     */
    setRefuseStatusToGoods(uid) {
        return this.post('/order/setRefuseStatusToGoods', {
            uid: uid,
        });
    }

    /**
     * Подтвердить жалобу
     * @param id
     * @param params
     */
    public confirmComplaint(id, params) {
        return this.post('/personal/complaint/confirm/' + id, params);
    }

    /**
     * Логи жалобы
     * @param id
     */
    getComplaintLogs(id) {
        return this.get('/personal/complaint/logs/' + id);
    }

    /**
     * Удаление жалобы
     * @param complaintId
     * @param reason
     */
    public deleteComplaint(complaintId, reason) {
        return this.post('/personal/complaint/delete/' + complaintId, {'delete_reason': reason});
    }

    addComplaint(params) {
        return this.post('/personal/complaint/add', params);
    }

    /**
     * Размещение возвратного акта в архиве
     * @param barcode
     */
    scanReturnActToPlaceInArchive(barcode: string) {
        return this.post('/storage/return-acts/scanReturnActToPlaceInArchive', {
            barcode,
        });
    }

    /**
     * Поиск по штрих-коду
     * @param barcode
     */
    lookupByBarcode(barcode: string) {
        return this.get('/lookupByBarcode?barcode=' + encodeURIComponent(barcode));
    }

    /**
     * Получает список файлов объекта
     * @param params
     */
    listItemFiles(params) {
        return this.post('/files/listItemFiles', params);
    }

    /**
     * Получает список типов файлов для загрузки
     * @param item_type
     */
    getFileTypes(item_type: string|null) {
        return this.get('/files/types' + (item_type ? '?item_type=' + item_type : ''));
    }

    /**
     * Получает данные для корректировки сдвига интервала
     * @param uid
     */
    getIntervalShiftRollbackData(uid) {
        return this.get('/order/getIntervalShiftRollbackData/' + uid);
    }

    /**
     * Корректировка инициативы сдвига интервала
     * @param uid
     * @param params
     */
    updateIntervalShiftReason(uid, params) {
        return this.post('/order/updateIntervalShiftReason/' + uid, params);
    }

    /**
     * Откат сдвига интервала
     * @param uid
     * @param params
     */
    rollbackIntervalShift(uid, params) {
        return this.post('/order/rollbackIntervalShift/' + uid, params);
    }

    /**
     * Назначение ворот погрузки
     * @param shippingQueueId
     * @param gateId
     */
    assignLoadingGate(shippingQueueId, gateId) {
        return this.post('/storage/assembling/assignLoadingGate/' + shippingQueueId + '/' + gateId, {});
    }

    setOrderStorageExpireDate(uid, params) {
        return this.post('/storage/setOrderStorageExpireDate/' + uid, params);
    }

    setOrderPickupPointFreeStorageExpireDate(uid, params) {
        return this.post('/storage/setOrderPickupPointFreeStorageExpireDate/' + uid, params);
    }

    /**
     * Услуги по категориям
     */
    public getServicesByCategories() {
        return this.get('/matrix/service_zone_groups/services_by_categories');
    }

    /**
     * Смена признака видимости заказа в getstatusv3
     */
    setVisibilityForApi(uid: string, isVisible: number | string | boolean) {
        return this.post('/order/setVisibilityForApi/' + uid + '/' + (isVisible ? '1' : '0'), {});
    }

    setStatusAccepted(orderId) {
        return this.post('/order/setStatusAccepted', {order_id: orderId});
    }

    deleteProblem(problemId, orderId) {
        return this.post('/order/deleteProblem', {problem_id: problemId, order_id: orderId})
    }

    /**
     * Удаляет место из заказа
     */
    deletePlaceFromOrder(barcodeId) {
        return this.post('/order/deletePlaceFromOrder', {
            barcode_id: barcodeId,
        });
    }


    /**
     * Список цен топлива
     */
    public getFuelPrices(date) {
        return this.get('/logistic/fuel/prices/' + date);
    }

    /**
     * Обновление цены топлива
     */
    public updateFuelPrice(id, data) {
        return this.post('/logistic/fuel/prices/' + id, data);
    }
}
