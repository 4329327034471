import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TransitCarrier} from '../../../service/models';

@Component({
  selector: 'app-transit-carrier-modal',
  templateUrl: './transit-carrier-modal.component.html',
  styleUrls: ['./transit-carrier-modal.component.scss']
})
export class TransitCarrierModalComponent implements OnInit {
    public width;
    public transit;

    public carriers: TransitCarrier[];
    public carriersSource: TransitCarrier[];
    public newCarrier: TransitCarrier;
    public selectedCarrier: TransitCarrier;

    public formEdit: FormGroup;

    public isNewCarrier: boolean = true;
    public carrierIsSaving: boolean = false;
    public allowEditing: boolean = true;

    constructor(
        protected app: AppComponent,
        protected api: SkladService) {
        this.formEdit = new FormGroup({
            name: new FormControl(),
            inn: new FormControl(),
            kpp: new FormControl(),
            fact_address: new FormControl(),
            jur_address: new FormControl(),
            phones: new FormControl()
        });
    }

    ngOnInit() {
        this.api.getTransitCarriers()
            .subscribe(data => {
                this.carriers = data;
                this.carriersSource = data;
            })
    }

    handleFilter(value) {
        this.carriers = this.carriersSource.filter((s) => {
            return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onCarrierNameChange($event) {
        if (this.newCarrier.id) {
            // заполним форму данными водителя
            const carrier = this.carriers.find(d => this.newCarrier.id === d.id);

            for(let i in this.formEdit.controls) {
                this.formEdit.get(i).setValue(carrier[i]);
            }

            this.selectedCarrier = this.newCarrier;
            this.isNewCarrier = false;
            this.allowEditing = false;
        }
    }

    onChooseNewCarrierClick() {
        this.isNewCarrier = true;
        this.allowEditing = true;
        for(let i in this.formEdit.controls) {
            this.formEdit.get(i).setValue(null);
        }
    }

    onEditExistingCarrierClick() {
        this.allowEditing = true;
    }

    onSaveCarrierButtonClick() {
        this.carrierIsSaving = true;

        const carrierData = this.formEdit.value;
        carrierData.id = this.selectedCarrier.id;

        this.api.saveTransitCarrier(carrierData)
            .subscribe((data: TransitCarrier) => {
                this.carrierIsSaving = false;

                this.addCarrierToTransit(data);
            }, error => {
                this.carrierIsSaving = false;
            });
    }

    onAddCarrierButtonClick() {
        this.carrierIsSaving = true;
        this.api.addTransitCarrier(this.formEdit.value)
            .subscribe((data: TransitCarrier) => {
                this.carrierIsSaving = false;

                this.addCarrierToTransit(data);
            }, error => {
                this.carrierIsSaving = false;
            });
    }

    onSetCarrierButtonClick() {
        this.addCarrierToTransit(this.selectedCarrier);
    }

    /** установить водителя в перемещении */
    addCarrierToTransit(carrier: TransitCarrier) {
        this.api.setCarrierInTransit(this.transit.id, carrier.id)
            .subscribe(data => {
                this.carrierIsSaving = false;
                this.onClose();
            }, error => {
                this.carrierIsSaving = false;
            });
    }

    public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
        return {
            id: 0,
            name: text
        };
    }));
}
