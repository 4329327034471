import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {ClientInfo, Hub, OrdersRequest, User} from "../../service/models";
import {FormControl, FormGroup} from "@angular/forms";
import {HelpersService} from "../../service/helpers.service";
import {OrderLoadingService} from "../../orders-list/order-loading.service";
import {EventerService} from "../../service/eventer.service";
import {OrdersService} from "../../service/orders.service";
import {DialogService} from "../../components/dialog/dialog.service";
import {DatePipe} from "@angular/common";
import {CurrentUserService} from "../../service/current-user.service";
import {HubsService} from "../../service/hubs.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {FileDownloadService} from "../../http/file-download.service";
import {StatusSelectComponent} from "../../components/status-select/status-select.component";

@Component({
    selector: 'app-orders-check',
    templateUrl: './orders-check.component.html',
    styleUrls: ['./orders-check.component.scss']
})
export class OrdersCheckComponent implements OnInit {
    @ViewChild('input_status') statusSelectComp: StatusSelectComponent;

    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();
    public minimiz;
    public selectedClients: ClientInfo[] = [];
    public selectedStatuses: number[] = [];
    public formFilter: FormGroup;
    public query: OrdersRequest = new OrdersRequest();
    public statuses = [];
    public intervals: any[] = [];
    public selectedIntervals: any[] = [];
    public toDay = new Date();
    public minimized = false;
    public selectedHubs: Hub[] = [];
    public selectedHubId: number;
    public hubsList: Hub[] = null;
    public status: String;
    public uid: String;
    public statistic: {
        total: number,
        total_order: number,
        total_zorder: number,
        total_moscow: number,
        total_mo: number,
        total_region: number,
        total_barcodes: number,
        total_before_14: number,
        total_after_18: number
    } = {
        total: 0,
        total_order: 0,
        total_zorder: 0,
        total_moscow: 0,
        total_mo: 0,
        total_region: 0,
        total_barcodes: 0,
        total_before_14: 0,
        total_after_18: 0
    };
    public date_mode_options = [
        {
            id: 'delivery',
            label: 'Дата доставки',
        },
        {
            id: 'created_at',
            label: 'Дата заявки',
        },
        {
            id: 'stock_datetime',
            label: 'Дата на складе',
        }
    ];
    protected multiCourier: User;
    public excelIsLoading: boolean = false;

    constructor(
        public helper: HelpersService,
        private eventer: OrderLoadingService,
        private eventerService: EventerService,
        protected api: OrdersService,
        private fileDownloadService: FileDownloadService,
        protected dialog: DialogService,
        protected dataPipe: DatePipe,
        protected currentUserService: CurrentUserService,
        protected hubsService: HubsService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) {
        this.formFilter = new FormGroup({
            hub_id: new FormControl(0),
            date_mode: new FormControl('delivery'),
            date: new FormGroup({
                from: new FormControl(this.dataPipe.transform(this.toDay, 'yyyy-MM-dd')),
                to: new FormControl('')
            }),
            weight: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            hash_bar_codes_count: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            not_document: new FormControl(''),
        });

        for (const status of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
            this.statuses.push({
                status: status,
                title: this.helper.getNameOrderStatus(status)
            });
        }

        this.eventerService.primaryHubChange.subscribe((hub) => {
            console.info('eventerService.primaryHubChange', hub);
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            this.refillDeliveryIntervals();
        });

        let hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.formFilter.controls.hub_id.setValue(hub.id);
        this.refillDeliveryIntervals();

        this.selectedStatuses = [3];
        this.statusSelectComp.selectedStatuses = this.selectedStatuses;
        if (innerWidth <= 1280) {
            this.minimized = true;
        }
    }

    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
    }

    getSearchQuery() {
        const query: OrdersRequest = this.formFilter.value;
        query.statuses = this.selectedStatuses;
        query.client_uids = this.selectedClients.map((client: ClientInfo) => client.client_uid);
        query.intervals = this.selectedIntervals;

        query[query.date_mode] = {
            from: this.dataPipe.transform(query.date.from, 'yyyy-MM-dd'),
            to: this.dataPipe.transform(query.date.to, 'yyyy-MM-dd'),
        }

        query.hub_id = this.selectedHubId;
        this.query = query;
        console.log(query);
        return this.query;
    }

    getStatistic() {
        this.api.getStatistic(this.getSearchQuery()).subscribe(statistic => this.statistic = statistic);
    }

    onSearchOrders() {
        if (this.minimized !== this.minimiz
        ) {
            this.minimized = this.minimiz;
        }
        this.eventer.loading.emit(this.getSearchQuery());
        this.getStatistic();
    }

    onSelectInterval(event) {
        this.selectedIntervals.push(event.interval);
    }

    onDeSelectInterval(event) {
        this.selectedIntervals = this.selectedIntervals.filter(st => st !== event.interval);
    }

    onSelectAllInterval() {
        this.selectedIntervals = this.selectedIntervals.map(st => st.interval);
    }

    onDeSelectAllInterval() {
        this.selectedIntervals = [];
    }


    onSelectStatus(event) {
        this.selectedStatuses = event;
    }

    formatNum(value) {
        return parseFloat(value).toFixed(2);
    }

    onChangeHub(hub) {
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;

        this.formFilter.controls.hub_id.setValue(hub.id);

        this.refillDeliveryIntervals();

        this.appUserSelectEventsSubjectEmit();
    }

    appUserSelectEventsSubjectEmit() {
        this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    refillDeliveryIntervals() {
        if (null === this.hubsList) {
            this.selectedIntervals = [];
            this.formFilter.controls.hub_id.setValue(0);
            return;
        }

        this.intervals = [];
        let selectedHub = this.selectedHubs[0] || null;
        if (!selectedHub) {
            this.selectedIntervals = [];
            this.formFilter.controls.hub_id.setValue(0);
            return;
        }

        let hub = this.hubsList.find((hub) => {
            return hub.id === selectedHub.id;
        });

        for (let deliveryInterval of hub.delivery_intervals) {
            this.intervals.push({
                interval: deliveryInterval.id,
                title: deliveryInterval.label,
            });
        }

        this.selectedIntervals = this.selectedIntervals.filter((selectedInterval) => {
            return hub.delivery_intervals.find(interval => {
                return interval.id === selectedInterval.interval;
            });
        });
    }

    exportExcel() {
        const query = this.getSearchQuery();
        query.limit = '200000';
        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/order/listOrdersForCheck?downloadAsExcel', 'Контроль заказов.xls', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }
}
