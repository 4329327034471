import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {BadAddressedTarget} from "../../service/models";
import {HelpersService} from "../../service/helpers.service";

@Component({
    selector: 'app-unrecognized-addresses',
    templateUrl: './unrecognized-addresses.component.html',
    styleUrls: ['./unrecognized-addresses.component.scss']
})
export class UnrecognizedAddressesComponent implements OnInit {
    public listOfBadAddressedOrders: GridDataResult;
    public listOfBadAddressedOrdersPageSize: number = 50;
    public listOfBadAddressedOrdersSkip: number = 0;
    public listBadQualityAddresses: boolean = false;
    public listAddressesRequiresCall: boolean = false;
    public listAddressesBlacklistCoords: boolean = false;

    public loading = false;

    constructor(
        protected api: LaraService,
        public helper: HelpersService,
    ) {
    }

    ngOnInit() {
        this.loadBadAddressedOrders()
    }

    /**
     * Подгрузка страницы списка заказов с плохими адресами
     */
    loadBadAddressedOrders() {
        this.loading = true;
        const params = {
            per_page: this.listOfBadAddressedOrdersPageSize,
            page: 1 + Math.floor(this.listOfBadAddressedOrdersSkip / this.listOfBadAddressedOrdersPageSize),
            with_bad_quality: this.listBadQualityAddresses ? 1 : 0,
            with_call_required: this.listAddressesRequiresCall ? 1 : 0,
            with_blacklist_coords: this.listAddressesBlacklistCoords ? 1 : 0,
        }

        this.api.getBadAddressedOrders(params).subscribe(responsePayload => {
            responsePayload.data = responsePayload.data.map(item => {
                return new BadAddressedTarget(item);
            });
            this.listOfBadAddressedOrders = responsePayload;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    resolveOrderAddressIssueType(dataItem: BadAddressedTarget) {
        let issues = [];

        if (dataItem.x_address_blacklist_coords) {
            issues.push('Координаты в черном списке');
        }

        if (dataItem.x_address_requires_call) {
            issues.push('Требуется прозвон');
        }

        if (dataItem.x_target_coords_empty) {
            issues.push('Нет координат объекта');
        }

        if (dataItem.x_address_coords_empty) {
            issues.push('Нет координат адреса');
        }

        if (dataItem.x_bad_dadata) {
            issues.push('Низкокачественная DaData');
        }

        if (dataItem.x_coords_mismatch) {
            issues.push('Координаты отличаются');
        }

        if (dataItem.x_address_quality_bad_forever) {
            issues.push('Служебный вечно плохой адрес');
        } else if (dataItem.x_address_quality_bad) {
            issues.push('Адрес помечен как плохой');
        }

        return issues.join('; ');
    }

    /**
     * Обработка перехода по страницам списка
     * @param skip
     * @param take
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        console.warn(skip, take);
        this.listOfBadAddressedOrdersSkip = skip;
        this.listOfBadAddressedOrdersPageSize = take;
        return this.loadBadAddressedOrders();
    }

    /**
     * Обработка клика по кнопке обновления списка
     */
    onRefreshListClick() {
        this.loadBadAddressedOrders();
    }

    /**
     * Обработка закрытия модального окна заказа
     */
    onClosed() {
        this.loadBadAddressedOrders();
    }
}
