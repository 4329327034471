import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';

import {ClientInfo, MatrixData, MatrixTopDeliveryZone} from '../../service/models';
import {MatrixService} from '../../service/matrix.service';

@Component({
    selector: 'app-matrix-topdelivery',
    templateUrl: './matrix-topdelivery.component.html',
    styleUrls: ['./matrix-topdelivery.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class MatrixTopdeliveryComponent implements OnInit {
    public hubId: number;

    public zonesList: MatrixTopDeliveryZone[];
    public categoryList: string[];
    public returnTypes;

    public selectedZoneId: number;

    public selectedCategory = 'A';
    public isSelectedCategory = true;
    public selectedClient: ClientInfo;

    public pageTitle = 'Тарифная матрица';

    public formMatrixOptions: FormGroup;
    public formMatrixList: FormArray;
    public formMatrixMass: FormArray;

    public isMatrixLoading: boolean = false;

    public dataHash: MatrixData;

    public optionsKey = [
        'sms', 'sms_type',
        'cod_cash', 'cod_card',
        'buyback', 'insurance'
    ];

    public optionsTitle = [
        'SMS', 'Тип SMS',
        'Наложенный платеж (нал) %', 'Наложенный платеж (безнал) %',
        'Плата за возврат', 'Страховка'
    ];

    public tarTypesTitle = [
        'Фиксированная цена', 'Процент от тарифа'
    ];

    constructor(
        private api: MatrixService,
        private title: Title
    ) {
    }

    ngOnInit() {
        this.loadEmptyMatrix();
        this.loadZones();

        /* грузим список категорий */
        this.api.getMatrixCategoryList().subscribe(data => {
            this.categoryList = data.top_delivery;
        });

        this.api.getReturnTypes().subscribe((data) => {
            this.returnTypes = data;
        });
    }

    private loadZones() {
        /* грузим список групп зон */
        this.api.getTopDelieryZones().subscribe((data) => {
            if (data.length) {
                this.zonesList = data;
                this.selectedZoneId = data[0]['id'];

                this.loadMatrix();
            }
        });
    }

    public onChangeZone(id: number) {
        this.selectedZoneId = id;
        this.loadMatrix();
    }

    public onChangeCategory(categoryCode: string) {
        this.selectedCategory = categoryCode;
        this.loadMatrix();
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;

        if (client) {
            this.selectedCategory = client.matrix;
            this.isSelectedCategory = false;
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.isSelectedCategory = true;
            this.title.setTitle(this.pageTitle);
        }

        this.loadMatrix();
    }

    public onAddMass() {
        this.formMatrixMass.push(new FormControl('', Validators.required));

        const newRow: FormArray = new FormArray([]);
        newRow.push(
            new FormArray([
                new FormControl('', Validators.required),
                new FormControl('', Validators.required)
            ])
        );

        this.formMatrixList.push(newRow);
    }

    public onDeleteMass(index) {
        this.formMatrixMass.removeAt(index);
        this.formMatrixList.removeAt(index);
    }

    public onSubmitForm() {
        const matrix = {};
        for (let i = 0; i < this.formMatrixMass.length; i++) {
            matrix[this.formMatrixMass.controls[i].value] = [
                this.getMatrixControl(i, 0).value,
                this.getMatrixControl(i, 1).value
            ];
        }

        const data = {
            matrix: matrix,
            options: this.formMatrixOptions.value,
            category: this.selectedClient ? null : this.selectedCategory
        };

        this.isMatrixLoading = true;
        this.api.updateTopDeliveryMatrix(
            data,
            this.selectedCategory,
            this.selectedZoneId,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe(() => {
            this.loadMatrix();
            this.isMatrixLoading = false;
        }, error => {
            this.isMatrixLoading = false;
        });
    }

    public getMatrixControl(mass_index, type) {
        const arr: FormArray = this.formMatrixList.controls[mass_index] as FormArray;
        const ctrl: FormArray = arr.controls[0] as FormArray;
        return ctrl.controls[type];
    }

    /**
     * Можно ли редактировать матрицу?
     */
    public canEditMatrix() {
        // можно проверить права, если нужно
        return true;
    }

    protected createOptionsForm(values) {
        this.formMatrixOptions = new FormGroup({});
        for (const key of this.optionsKey) {
            this.formMatrixOptions.addControl(key, new FormControl(values[key] === undefined ? null : values[key]));
        }
    }

    protected loadEmptyMatrix() {
        this.formMatrixList = new FormArray([]);
        this.formMatrixMass = new FormArray([]);
        this.createOptionsForm([]);
    }

    protected loadMatrix() {
        this.createOptionsForm({});

        this.formMatrixList = new FormArray([]);
        this.formMatrixMass = new FormArray([]);

        this.api.getTopDeliveryMatrix(
            this.selectedZoneId,
            this.selectedCategory,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe((data: MatrixData) => {
            /* форма опций */
            this.createOptionsForm(this.selectedClient ? data.options : data.options_default);

            /* сама матрица */
            this.dataHash = data;
            const matrix = data.matrix,
                mass = data.mass;

            for (let i = 0; i < mass.length; i++) {
                this.formMatrixMass.push(new FormControl(mass[i], Validators.required));

                const hash = [];
                const row: FormArray = new FormArray([]);
                hash.push(matrix[i][2]);
                row.push(new FormArray([
                    new FormControl(matrix[i][0], Validators.required),
                    new FormControl(matrix[i][1], Validators.required)
                ]))

                this.formMatrixList.push(row);
            }

            // this.checkMatrixForEmptyRows();
        });
    }

    /**
     * Если матрица весов пустая, добавим строку
     */
    public checkMatrixForEmptyRows() {
        if (this.dataHash.mass.length > 0) {
            return;
        }

        if (!this.canEditMatrix()) {
            return;
        }

        this.formMatrixMass.push(new FormControl('', Validators.required));

        const hash = [];
        const row: FormArray = new FormArray([]);
        hash.push(null);
        row.push(new FormArray([
            new FormControl(null, Validators.required),
            new FormControl(null, Validators.required)
        ]))

        this.formMatrixList.push(row);
    }

    /**
     * Делаем пример расчёта на половине весового интервала
     * @param weightTo
     * @param price
     * @param priceDelta
     */
    public getCalculateExample(weightTo: number, price: number, priceDelta: number) {
        if (+weightTo === 0 || price === null) {
            return null;
        }

        const weightFrom = this.getPreviousMassValue(weightTo);
        const weight = Math.round(10 * (weightFrom + weightTo) / 2) / 10;

        let deliveryPrice = 0;
        deliveryPrice += (1 * (Math.ceil(weight - weightFrom) * priceDelta + price));

        return 'Цена за ' + weight + 'кг: ' + deliveryPrice + ' руб';
    }

    /**
     * Получаем предыдущий вес для указания интервала
     * @param currentMass
     */
    public getPreviousMassValue(currentMass) {
        const masses: number[] = [];
        for (const mass of this.formMatrixMass.controls) {
            masses.push(+mass.value);
        }

        // сортируем в обратном порядке
        masses.sort((a, b) => {
            return b - a;
        });

        for (const mass of masses) {
            if (mass < currentMass) {
                return mass;
            }
        }

        return 0;
    }
}
