import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-choose-date-dialog',
    templateUrl: './choose-date-dialog.component.html',
    styleUrls: ['./choose-date-dialog.component.scss']
})
export class ChooseDateDialogComponent implements OnInit {

    public chooseDateForm: FormGroup;
    public description: string;
    public defaultDate;


    constructor(
        private datePipe: DatePipe,
        private dialogRef: MatDialogRef<ChooseDateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.description;
        this.defaultDate = data.defaultDate;
    }


    ngOnInit() {
        this.chooseDateForm = new FormGroup({
            chooseDate: new FormControl(this.defaultDate, Validators.required)
        });
    }

    choose() {
        let date = this.chooseDateForm.value.chooseDate;
        if (date) {
            date = this.datePipe.transform(date, 'yyyy-MM-dd');
        }
        this.dialogRef.close(date);
    }

    close() {
        this.dialogRef.close();
    }

}
