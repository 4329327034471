import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-act-modal',
    templateUrl: './act-modal.component.html',
    styleUrls: ['./act-modal.component.scss']
})
export class ActModalComponent implements OnInit {

    public act: any;

    constructor(
        protected app: AppComponent,
        public helpers: HelpersService
    ) {
    }

    ngOnInit() {

    }


    onClose() {
        this.app.closeDialog(this);
    }

}
