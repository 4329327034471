import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CourierDialogComponent} from './courier-dialog/courier-dialog.component';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-courier-card',
    templateUrl: './courier-card.component.html',
    styleUrls: ['./courier-card.component.scss'],
})
export class CourierCardComponent implements OnInit {
    @Input('courier_id') public courier_id: string | number;

    @Output() dialogOpenedChange = new EventEmitter<any>();
    @Output() onDestroy = new EventEmitter<boolean>();

    public loading = false;
    private dialogRef: ComponentRef<any>;

    constructor(
        private app: AppComponent,
    ) {
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.dialogRef = this.app.createDialog(CourierDialogComponent, {
            courierId: this.courier_id || null,
            parent: this,
        });

        this.dialogOpenedChange.emit(true);

        this.dialogRef.onDestroy(() => {
            this.dialogOpenedChange.emit(false);
            this.onDestroy.emit(true);
        });
    }

    public onClose() {
        this.dialogRef.destroy();
        this.loading = false;
    }
}
