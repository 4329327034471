import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';

@Component({
    selector: 'app-transit-modal',
    templateUrl: './transit-modal.component.html',
    styleUrls: ['./transit-modal.component.scss']
})
export class TransitModalComponent implements OnInit {
    public transit;
    public width;
    public palletInput = false;

    constructor(public helpers: HelpersService,
                protected app: AppComponent,
                protected api: SkladService) {
        this.width = window.innerWidth * 0.5;
    }

    ngOnInit() {
        this.ordersNums();
    }

    ordersNums() {
        let i = 1;
        for (const pack of this.transit.packs) {
            for (const order of pack.orders) {
                order.num = i++;
            }
        }
    }

    onClose() {
        this.app.closeDialog(this);
    }

    addPallet(event) {
        this.api.setPalletToTransit(this.transit.id, [event.target.value]).subscribe(data => {
            this.updateTransit();
            console.log(data);
        });
        event.target.value = '';
    }

    orderStatus(status) {
        return this.helpers.getNameOrderStatus(status);
    }

    deletePallet(palletId) {

        this.api.removePalletFromTransit(this.transit.id, [palletId]).subscribe(data => {
            console.log(data);
            this.updateTransit();
            alert('Палета №' + palletId + ' удалена');
        });
    }

    updateTransit() {
        this.api.getTransitInfo(this.transit.id).subscribe((data) => {
            this.transit = data;
        });
    }

}
