import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientInfo, ShiftType, User, UserHub} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {ZordersService} from '../../service/zorders.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {DatePipe} from '@angular/common';
// import {OrdersMapComponent} from '../../components/orders-map/orders-map.component';
import {GridDataResult, PageChangeEvent, RowArgs, RowClassArgs} from '@progress/kendo-angular-grid';
import {State} from "@progress/kendo-data-query";
import {Subject} from "rxjs";
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";
import {ZorderListResponseItem} from "../../shared/models/zorders/ZorderListResponseItem";
import {NotifierService} from '../../service/notifier.service';

@Component({
    selector: 'app-zorders-list',
    templateUrl: './zorders-list.component.html',
    styleUrls: ['./zorders-list.component.scss'],
})
export class ZordersListComponent implements OnInit, OnDestroy {
    public selectedClients: ClientInfo[] = [];
    public selectedUsers: User[] = [];
    public formFilter: FormGroup;
    public formEdit: FormGroup;
    public formCourier: FormGroup;
    public toDay = new Date();
    public mobile = false;
    public query = {};
    public state: State = {take: 50, skip: 0};
    public gridData: GridDataResult;
    public loading: boolean = false;
    @ViewChild('input_date') inputDate: ElementRef;
    public selectedZorders: string[] = [];
    // protected map: OrdersMapComponent;
    public selectedZordersInMap: string[] = [];
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public selectedHub: UserHub = null;
    public selectedHubs: UserHub[] = null;
    public selectedShiftNumber;
    public appSameDaySelectEventsSubject: Subject<any> = new Subject<any>();
    protected newCourier: User = null;
    private dialogSubscription;
    public zorderStatuses = [];

    constructor(
        public helper: HelpersService,
        protected api: ZordersService,
        protected dialog: DialogService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private datePipe: DatePipe,
        private notifier: NotifierService,
    ) {
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [this.selectedHub];
        this.formFilter = new FormGroup(
            {

                delivery: new FormGroup({
                    from: new FormControl(this.datePipe.transform(this.toDay, 'yyyy-MM-dd')),
                    to: new FormControl(this.datePipe.transform(this.toDay, 'yyyy-MM-dd')),
                }),
                status: new FormControl(),
                old_id: new FormControl(),
                comments: new FormControl(),
                hub_id: new FormControl(),
                is_sameday: new FormControl(null),
                inputDate: new FormControl(),
            },
        );

        this.formEdit = new FormGroup(
            {
                new_status: new FormControl(),

            },
        );

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
        this.zorderStatuses = this.helper.getStatusesForEditZorder();
    }

    ngOnInit() {
        console.log(window.screen.width)
        if (window.screen.width < 500) { // 768px portrait
            this.mobile = true;
        }

        this.state.take = this.mobile ? 500 : 500;

        // трюк чтоб контекст компонента перезаписал контекст грида при вызове rowClass метода getRowClass
        this.getRowClass = this.getRowClass.bind(this);
    }

    ngOnDestroy() {
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
    }

    public mySelectionKey(context: RowArgs): string {
        if (context.dataItem.status !== 7) {
            return context.dataItem.id;
        }
    }

    getZordersList() {
        this.loading = true;
        this.api.getList(this.getSearchQuery()).subscribe(responsePayload => {
            responsePayload.zorders.data = responsePayload.zorders.data.map(item => {
                return new ZorderListResponseItem(item);
            });

            this.gridData = {
                data: responsePayload.zorders.data,
                total: responsePayload.zorders.total,
            };
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    change() {
        const filtered = this.selectedZorders.filter(function (e) {
            return e != null;
        });
        this.selectedZorders = filtered;
    }

    selector() {
        if (this.selectedZorders.length !== 0) {
            this.selectedZorders = [];
        }
    }

    onChangeClient(clients: ClientInfo[]) {
        if (clients) {
            this.selectedClients = clients;

            if (clients.length > 0) {
                this.onSearchZorders();
            }
        } else {
            console.log('client not selected ');
            this.selectedClients = [];
        }
    }

    onSelectUser(users: User[]) {
        if (users) {
            this.selectedUsers = users;
        } else {
            this.selectedUsers = [];
        }
    }

    onSelectedInMap(data) {
        this.selectedZordersInMap = data[1];
    }

    getSearchQuery() {
        let query = this.formFilter.value;
        query.delivery.from = this.datePipe.transform(query.delivery.from, 'yyyy-MM-dd');
        query.delivery.to = this.datePipe.transform(query.delivery.to, 'yyyy-MM-dd');
        query.client_ids = this.selectedClients.map((client: ClientInfo) => client.id);
        query.courier_ids = this.selectedUsers.map((user: User) => user.id);
        query.hub_id = this.selectedHub.id;

        query.skip = this.state.skip;
        query.pageSize = this.state.take;
        query.shift_number = this.selectedShiftNumber;

        console.log('COMPILE QUERY', query);

        this.query = query;

        return this.query
    }

    onSearchZorders() {
        this.selectedZorders = [];
        this.getZordersList();
    }

    /**
     * Обработка перехода по страницам списка
     * @param skip
     * @param take
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        console.warn(skip, take);
        this.state.skip = skip;
        this.state.take = take;
        this.getZordersList();
    }

    onSetSelectCourier(user: User) {
        this.newCourier = user;
    }

    onUseSetCourier() {
        if (!this.newCourier) {
            this.dialog.alert('Выберите курьера').unsubscribe();
            return;
        }
        const uids = this.selectedZorders;

        this.setCourierForZorders(uids, this.newCourier.id, 0);
    }


    setCourierForZorders(zordersIds, courierId, isForce) {
        this.api.setCourierForZorders(zordersIds, this.newCourier.id, isForce).subscribe(data => {
            if (data.errors !== '') {
                if (data.need_force && !isForce) {
                    if (confirm(data.errors + ' Назначить все равно?')) {
                        this.setCourierForZorders(zordersIds, courierId, 1);
                    }
                } else {
                    alert(data.errors);
                }
            }
            this.dialog.alert('Курьер ' + this.newCourier.name + ' назначен на ' + zordersIds.length + ' забора');
            this.onSearchZorders();
            console.log('все поменяли');
        });
    }

    onSetStatus() {
        if (!this.formEdit.value.new_status) {
            this.dialogSubscription = this.dialog.alert('Выберите статус')
                .subscribe((result) => {
                    this.dialogSubscription.unsubscribe();
                });

            return;
        }

        this.dialogSubscription = this.dialog.confirm('Задать статус ' + this.helper.getNameZOrderStatus(this.formEdit.value.new_status) + ' ' + this.selectedZorders.length + ' заборам?')
            .subscribe(result => {
                if (result) {
                    this.api.setStatusForZorders(this.selectedZorders, this.formEdit.value.new_status).subscribe(data => {
                        this.onSearchZorders();
                        if (data.message) {
                            this.dialog.alert(data.message);
                        }
                    });
                }
                this.dialogSubscription.unsubscribe();
            })
    }

    // setMapInstance(map: any) {
    //     this.map = map;
    // }

    onChangeHub(hub) {
        if (hub.id !== this.selectedHub.id) {
            this.selectedUsers = [];
        }

        this.selectedHub = hub;
        this.selectedHubs = [hub];
        this.selectedZorders = [];
        console.log('hub changed');
        console.log(this.selectedHub);
        this.appUserSelectEventsSubjectEmit();
        this.onSearchZorders();
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
    }

    onChangeSameday(event) {
        this.formFilter.get('is_sameday').setValue(event);
    }

    /**
     * Возвращает класс строки
     * @param context
     */
    public getRowClass(context: RowClassArgs) {
        let classes = [];
        if (context.dataItem.is_sameday) {
            classes.push('is-sameday');
            if (1 !== this.formFilter.value.is_sameday) {
                classes.push('saturate');
            }
        }

        return classes;
    }

    onChangeShift(shifts: number[]) {
        if (shifts.findIndex(x => x === ShiftType.FIRST_SHIFT) !== -1 && shifts.length === 1) {
            this.appSameDaySelectEventsSubject.next(1);
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: ShiftType.FIRST_SHIFT});
            this.selectedShiftNumber = ShiftType.FIRST_SHIFT;
        } else if (shifts.findIndex(x => x === ShiftType.SECOND_SHIFT) !== -1 && shifts.length === 1) {
            this.appSameDaySelectEventsSubject.next(2);
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: ShiftType.SECOND_SHIFT});
            this.selectedShiftNumber = ShiftType.SECOND_SHIFT;
        } else {
            this.appSameDaySelectEventsSubject.next(0);
            this.appHubChangeEventSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: []});
            this.selectedShiftNumber = [];
        }
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000})
        });
    }
}
