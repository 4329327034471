import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocReturnAct} from "../../../service/models";
import {DocReturnActHelper} from "../../../service/helpers/docReturnAct.helper";
import {DocReturnActCardComponent} from "../doc-return-act-card.component";
import {AppComponent} from "../../../app.component";

@Component({
    selector: 'app-doc-return-act-dialog',
    templateUrl: './doc-return-act-dialog.component.html',
    styleUrls: ['./doc-return-act-dialog.component.scss']
})
export class DocReturnActDialogComponent implements OnInit {
    @Input('act') public act: DocReturnAct;
    @Output() onClosed = new EventEmitter<boolean>();
    @ViewChild('closeModal') private closeModal;

    public parent: DocReturnActCardComponent;
    public opened = false;
    public width;

    constructor(
        public docReturnActHelper: DocReturnActHelper,
        protected app: AppComponent,
    ) {
    }

    ngOnInit() {
        this.opened = true;
    }

    /**
     * Обработка события закрытия модального окна - закрывает окно и уведомляет об этом родителей
     */
    onClose() {
        this.opened = false;
        this.app.closeDialog(this);
        this.parent.DialogClosed(this.act.id);
    }

    /**
     * Клик по кнопке удаления записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    onRemoveRecord(id, act_id) {
        this.parent.onRemoveRecord(id, act_id);
    }

    /**
     * Клик по кнопке восстановления записи заказа/штрихкода в акте
     * @param id
     * @param act_id
     */
    onRestoreRecord(id, act_id) {
        this.parent.onRestoreRecord(id, act_id);
    }
}
