import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {ChangesLogger} from '../../service/models';

@Component({
    templateUrl: './changes-logger-modal.component.html',
    styleUrls: ['./changes-logger-modal.component.scss']
})

export class ChangesLoggerModalComponent implements OnInit {
    public width;

    public historySource: { rows: [], columns: [] };
    public consoleUserName: string = 'Робот';

    public changes: ChangesLogger[];
    public columns: {};

    public actionNames = {
        'create': 'Создание',
        'update': 'Редактирование',
        'delete': 'Удаление',
    };

    constructor(
        protected app: AppComponent
    ) {}

    ngOnInit() {
        this.changes = this.historySource.rows;
        this.columns = this.historySource.columns;
    }

    onClose() {
        this.app.closeDialog(this);
    }

    public formatDate(date) {
        const dateTime = new Date(date.replace(/-/g, '/'));
        return dateTime.toLocaleString('ru-RU');
    }
}
