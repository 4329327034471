import {Component, Input, OnInit} from '@angular/core';
import {MatrixService} from '../../service/matrix.service';
import {ClientInfo, MatrixCategory} from '../../service/models';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-matrix-categories',
    templateUrl: './matrix-categories.component.html',
    styleUrls: ['./matrix-categories.component.scss']
})
export class MatrixCategoriesComponent implements OnInit {

    @Input() type: string;
    @Input() client: ClientInfo;

    public types: { label: string; type: string }[] = [
        {label: 'Тарифная матрица М, МО, СПБ по км', type: 'moscow'},
        {label: 'Санкт-Петербург', type: 'spb'},
    ];

    public categoriesLoading: boolean = false;
    public categories: MatrixCategory[];
    public form: FormGroup;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Информация о заказах';

    constructor(
        private api: MatrixService,
        protected app: AppComponent,
    ) {
        if (window.pageYOffset > 0) {
            this.windowTop = window.pageYOffset + 50;
        }

        if (window.innerWidth > 450) {
            this.width = 450;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = Math.min(0.9 * window.innerWidth, 450);
    }

    ngOnInit() {
        this.loadCategories();
    }

    loadForm() {
        let categoriesFormArray = new FormArray([]);

        if (this.categories) {
            this.categories.forEach((category) => {
                if (this.client) {
                    if (category.client_id === this.client.id) {
                        categoriesFormArray.push(this.getCategoryFormGroup(category));
                    }
                } else if (category.is_volume_based && !category.is_offer && !category.client_id) {
                    categoriesFormArray.push(this.getCategoryFormGroup(category));
                }
            })
        } else {
            categoriesFormArray.push(this.getCategoryFormGroup());
        }

        this.form = new FormGroup({
            'categories': categoriesFormArray
        })

        this.categoriesControls.forEach(control => {
            control.disable();
        })
    }

    onNewCategoryClick() {
        const newForm = this.getCategoryFormGroup();
        newForm.get('name').disable();

        (<FormArray>this.form.get('categories'))
            .push(newForm);
    }

    onChangeOrdersAmount(index, amount) {
        const nameControl = this.categoriesControls[index].get('name');

        if (!this.client) {
            nameControl.patchValue(`По объему: от ${amount}`);
        } else {
            nameControl.patchValue(`Клиент ${this.client.client_uid_old}: от ${amount}`);
        }
    }

    /**
     * Получить FormGroup строки категории
     * @param category
     * @private
     */
    private getCategoryFormGroup(category?: MatrixCategory) {
        return new FormGroup({
            'id': new FormControl(category ? category.id : null),
            'name': new FormControl(category ? category.name : '', Validators.required),
            'min_orders_count': new FormControl(category ? category.min_orders_count : 0, [
                Validators.required,
                Validators.min(0)
            ]),
        })
    }

    get categoriesControls() {
        return (<FormArray>this.form.get('categories')).controls;
    }

    loadCategories() {
        this.categoriesLoading = true;
        this.api.getCategories(this.type)
            .subscribe(data => {
                this.categories = data;
                this.loadForm();
                this.categoriesLoading = false;
            }, error => {
                this.categoriesLoading = false;
            })
    }

    onCategorySave(index) {
        const query = this.categoriesControls[index].value;

        if (this.client) {
            query.client_id = this.client.id;
        }

        this.categoriesLoading = true;

        if (query.id) {
            this.api.updateCategory(query)
                .subscribe(data => {
                    this.categories = data;
                    this.loadForm();
                    this.categoriesLoading = false;
                }, error => {
                    this.categoriesLoading = false;
                })
        } else {
            query.matrix_type = this.type;
            this.api.createCategory(query)
                .subscribe(data => {
                    this.categories = data;
                    this.loadForm();
                    this.categoriesLoading = false;
                }, error => {
                    this.categoriesLoading = false;
                })
        }
    }

    onCategoryEdit(index) {
        this.categoriesControls[index].get('min_orders_count').enable();
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
