import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HubGate} from '../../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SkladService} from '../../../service/sklad.service';
import {AppComponent} from '../../../app.component';
import {NotifierService} from '../../../service/notifier.service';

@Component({
    selector: 'app-hub-gate-modal',
    templateUrl: './hub-gate-modal.component.html',
    styleUrls: ['./hub-gate-modal.component.scss'],
})
export class HubGateModalComponent implements OnInit {
    @Input('hub_gate_id') public hub_gate_id: string | number | null = null;
    @Output() dialogOpenedChange = new EventEmitter<any>();

    public hubGate: HubGate | null = null;
    public loading = false;
    public loadingSave = false;
    public loadingCheckNum = false;
    public checkNumInterval = null;
    public isInvalidGateNum = false;
    public isInvalidCapacity = false;
    public formEdit: FormGroup;
    public dialogTitle: string;

    public readonly HUB_GATE__STATUES = HubGate.STATUSES;

    constructor(
        private api: SkladService,
        private app: AppComponent,
        private notifier: NotifierService,
    ) {
        this.formEdit = new FormGroup({
            'hub_id': new FormControl(1),
            'num': new FormControl(null, [
                Validators.required,
                Validators.min(1),
                Validators.max(255),
            ]),
            'tip': new FormControl(null),
            'lat': new FormControl(null),
            'lon': new FormControl(null),
            'assembling_capacity': new FormControl(null, [
                Validators.required,
                Validators.min(0),
                Validators.max(100),
            ]),
            'loading_capacity': new FormControl(2, [
                Validators.required,
                Validators.min(0),
                Validators.max(10),
            ]),
            'status': new FormControl(HubGate.STATUS_RESERVED),
        });
    }

    ngOnInit() {
        if (this.hub_gate_id) {
            this.loadHubGate();
        } else {
            this.checkCapacities();
            this.updateTitle();
            this.getNextHubGateNum();
        }
    }

    /**
     * Обработка клика по кнопке закрытия диалога
     */
    onClose() {
        this.dialogOpenedChange.emit(false);
        this.app.closeDialog(this);
    }

    /**
     * Обработка изменений в номере ворот
     */
    onInputGateNum() {
        if (this.checkNumInterval) {
            clearTimeout(this.checkNumInterval);
        }

        this.checkNumInterval = setTimeout(() => {
            this.checkHubGateNum();
        }, 500);
    }

    /**
     * Обработка клика по кнопке сохранения ворот
     */
    onClickSave() {
        this.save();
    }

    /**
     * Обработка изменений в полях ёмкостей
     */
    onInputAnyCapacity() {
        this.checkCapacities();
    }

    /**
     * Проверка корректности значений ёмкостей
     * @private
     */
    private checkCapacities() {
        const assemblingCapacity = this.formEdit.value.assembling_capacity;
        const loadingCapacity = this.formEdit.value.loading_capacity;

        this.isInvalidCapacity = !HubGate.isCapacitiesValid(assemblingCapacity, loadingCapacity);
        this.formEdit.updateValueAndValidity();
    }

    /**
     * Получение следующего номера ворот для хаба
     * @private
     */
    private getNextHubGateNum() {
        this.loadingCheckNum = true;
        this.formEdit.disable();
        this.api.getNextHubGateNum(1).subscribe(data => {
            this.loadingCheckNum = false;
            this.formEdit.enable();
            this.formEdit.patchValue({
                num: data,
            });
        }, () => {
            this.loadingCheckNum = false;
            this.formEdit.enable();
        });
    }

    /**
     * Обновление заголовка модального окна
     * @private
     */
    private updateTitle() {
        const titleParts = ['Хаб МСК, '];
        if (null === this.hub_gate_id) {
            titleParts.push('новые ворота');
        } else {
            titleParts.push('ворота ' + this.hubGate.num);
            titleParts.push('[' + this.hubGate.id + ']');
        }

        this.dialogTitle = titleParts.join('');
    }

    /**
     * Загрузка существующих ворот
     * @private
     */
    private loadHubGate() {
        this.loading = true;
        this.dialogTitle = 'Загрузка...';
        this.api.getHubGate(this.hub_gate_id).subscribe(data => {
            this.hubGate = data;
            this.formEdit.patchValue(this.hubGate);
            this.checkCapacities();
            this.updateTitle();
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Проверка номера ворот на уникальность в рамках хаба
     * @private
     */
    private checkHubGateNum() {
        this.loadingCheckNum = true;
        this.checkNumInterval = null;
        this.api.checkHubGateNum({
            hub_id: 1,
            num: this.formEdit.value.num,
        }).subscribe(data => {
            this.loadingCheckNum = false;
            this.isInvalidGateNum = (data && data.id && (null === this.hubGate || data.id !== this.hubGate.id));
        }, () => {
            this.loadingCheckNum = false;
        });
    }

    /**
     * Сохранение ворот (создание или редактирование)
     * @private
     */
    private save() {
        this.loadingSave = true;
        this.api.saveHubGate(this.formEdit.value, this.hub_gate_id).subscribe(data => {
            this.hubGate = data;
            this.formEdit.patchValue(this.hubGate);
            this.formEdit.updateValueAndValidity();
            this.updateTitle();
            this.loadingSave = false;
            this.notifier.openNotifier('Изменения сохранены', null, {duration: 3000});
        }, () => {
            this.loadingSave = false;
        })
    }
}
