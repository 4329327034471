import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../service/helpers.service';
import {AppComponent} from '../../../app.component';
import {FormControl, FormGroup} from '@angular/forms';
import {OrdersService} from '../../../service/orders.service';

@Component({
    selector: 'app-zone-group-add',
    templateUrl: './zone-group-add.component.html',
    styleUrls: ['./zone-group-add.component.scss'],
})

export class ZoneGroupAddComponent implements OnInit {
    public width;
    public groupForm: FormGroup;
    public hubId: number;

    constructor(
        public helpers: HelpersService,
        protected app: AppComponent,
        protected api: OrdersService
    ) {
        this.width = Math.min(window.innerWidth * 0.5, 400);
    }

    ngOnInit() {
        this.groupForm = new FormGroup({
            'name': new FormControl('')
        })
    }

    onSaveGroup() {
        this.api.addZoneGroup({
            hub_id: this.hubId,
            name: this.groupForm.get('name').value
        })
            .subscribe(data => {
                this.onClose();
            });
    }

    formIsValid() {
        return this.groupForm.valid;
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
