import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParentCompany} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {ClientsApiService} from '../../clients/clients-api.service';


@Component({
    selector: 'app-company-select',
    templateUrl: './company-select.component.html',
    styleUrls: ['./company-select.component.scss']
})

export class CompanySelectComponent implements OnInit {
    public parentCompanies: ParentCompany[];
    public formList: FormGroup;
    @Input() protected activeCompany = false;
    @Input() protected selectDefaultCompanyId = null;
    @Output() onChange = new EventEmitter<ParentCompany>();
    selectedCompany: ParentCompany;

    constructor(
        private clientsApi: ClientsApiService,
    ) {
        this.formList = new FormGroup({
            company_id: new FormControl(0),
        });
    }

    ngOnInit(): void {
        if (this.activeCompany) {
            this.clientsApi.getActiveParentCompanies().subscribe(data => {
                this.setValue(data);
            })
        } else {
            this.clientsApi.getParentCompanies().subscribe(data => {
                this.setValue(data);
            })
        }
    }

    setValue(data) {
        this.parentCompanies = data;
        if (this.selectDefaultCompanyId) {
            this.formList.controls.company_id.setValue(this.selectDefaultCompanyId);
        }
    }

    companyChange(companyId) {
        this.selectedCompany = (this.parentCompanies.filter(company => {
                return parseInt(companyId, 0) === company.id;
        }))[0];

        if (!this.selectedCompany) {
            this.selectedCompany = null;
        }
        this.onChange.emit(this.selectedCompany);
    }
}
