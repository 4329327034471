import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {CurrentUserService} from './service/current-user.service';
import {EventerService} from './service/eventer.service';
import {throwError} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {FrontendNeedUpdateService} from './system/frontend-need-update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {

    @ViewChild('dialogs', {read: ViewContainerRef}) dialogs: ViewContainerRef;

    constructor(
        private currentUser: CurrentUserService,
        private router: Router,
        private resolver: ComponentFactoryResolver,
        private event: EventerService,
        private frontendNeedUpdateService: FrontendNeedUpdateService
    ) {
        this.router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            /* для сбера игнорим отсутствие авторизации */
            if (this.router.url === '/sberbank') {
                return;
            }
            /* для прибытия курьеров игнорим отсутствие авторизации */
            if (this.router.url === '/login/courier') {
                return;
            }
            if (!this.currentUser.isLogin()) {
                this.router.navigate(['login']);
                this.currentUser.logout();
            }
        });
    }

    /**
     * Создание диалога
     * @param component
     * @param args
     */
    createDialog(component, args = {}) {
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
            component
        );
        const dialog: ComponentRef<any> = this.dialogs.createComponent(factory);
        const keys = Object.keys(args);
        for (let i of keys) {
            dialog.instance[i] = args[i];
        }
        dialog.instance.dialog = dialog;

        return dialog;
    }

    /**
     * закрытие диалога
     * @param dialog
     */
    closeDialog(dialog) {
        if (!dialog.dialog) {
            return throwError('Not found dialog element');
        }
        dialog.dialog.destroy();
    }
}
