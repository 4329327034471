import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {SkladService} from '../../../service/sklad.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../../service/helpers.service';

@Component({
  selector: 'app-pp-act-client-process',
  templateUrl: './pp-act-client-process.component.html',
  styleUrls: ['./pp-act-client-process.component.scss']
})
export class PpActClientProcessComponent implements OnInit {

    @Input() actId: number;

    public width = 700;
    public windowHeight = 600;

    public actIsProcessing: boolean = false;
    public actIsFinishing: boolean = false;

    public barcodesFailed;
    public barcodesAdoptedBefore;
    public actsMatching;
    public placesNotFoundInActs;

    public loadingStatus: string;
    public loadingError: string;

    constructor(
        protected app: AppComponent,
        protected api: SkladService,
        protected dialog: DialogService,
        private datePipe: DatePipe,
        private helper: HelpersService,
    ) {

    }

    ngOnInit() {
        this.actIsProcessing = true;
        this.api.postProcessClientAct(this.actId, {'idle': 1})
            .subscribe((data) => {
                this.barcodesFailed = data.errors;
                this.barcodesAdoptedBefore = data.placesAdoptedBefore;
                this.actsMatching = data.actsMatching;
                this.placesNotFoundInActs = data.placesNotFoundInActs;

                this.actIsProcessing = false;
            }, error => {
                this.actIsProcessing = false;
            })
    }

    onFinishActClick() {
        this.actIsFinishing = true;
        this.api.postProcessClientAct(this.actId, {'idle': 0})
            .subscribe((data) => {
                this.loadingStatus = data.message;

                this.actIsFinishing = false;
            }, error => {
                this.actIsFinishing = false;
            })
    }

    public formatDate(str) {
        return this.datePipe.transform(str, 'dd.MM.yyyy');
    }

    onClose() {
        this.app.closeDialog(this);
    }
}
