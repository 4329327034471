import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {StatisticsService} from '../../statistics.service';

@Component({
    selector: 'app-courier-calls-dialog',
    templateUrl: './courier-calls-dialog.component.html',
    styleUrls: ['./courier-calls-dialog.component.scss'],
})
export class CourierCallsDialogComponent implements OnInit {
    @Input('calls') calls;
    @Input('courier') courier;

    public recordingIds = [];
    public isLoadingRecordingIds = false;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Звонки курьера';

    constructor(
        protected app: AppComponent,
        protected api: StatisticsService,
    ) {
        if (window.innerWidth > 900) {
            this.width = 900;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.title = 'Звонки курьера - ' + this.courier.courier_id + ':' + this.courier.courier_name;
        this.windowTop = (100 + window.scrollY);
        this.loadRecordingIds();
    }

    onClose() {
        this.app.closeDialog(this);
    }

    private loadRecordingIds() {
        let entryIds = this.calls.map(x => x.id);
        if (entryIds.length < 1) {
            return;
        }
        this.isLoadingRecordingIds = true;
        this.api.getCourierCallsRecordingIds({
            'entry_ids': entryIds,
        }).subscribe(data => {
            this.recordingIds = data;
            this.isLoadingRecordingIds = false;
        }, error => {
            this.isLoadingRecordingIds = false;
        });
    }
}
