import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReturnAct} from '../../service/models';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';

interface StatusFormat {
    name: string,
    id: number
}

@Component({
    selector: 'app-return-act-status-select',
    templateUrl: './return-act-status-select.component.html',
    styleUrls: ['./return-act-status-select.component.scss'],
})
export class ReturnActStatusSelectComponent implements OnInit, OnDestroy {
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Статус акта';
    @Input() initValue: number = null;
    @Input() allowed_statuses: number[] = [];
    @Input() events: Observable<number[]>;
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList: StatusFormat[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};
    @Input() private multi = false;
    private eventsSubscription: Subscription;

    constructor() {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }

        this.dropdownList = this.filterByAllowedStatuses();
        if (this.initValue) {
            this.selectedItems = this.dropdownList.filter(x => {
                return x.id === this.initValue
            })
        }

        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true,
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.id) !== -1
        });
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel(selectedItems) {
        this.onChange.emit(selectedItems.map((item) => item.id));
    }

    private filterByAllowedStatuses() {
        if (!this.allowed_statuses.length) {
            return ReturnAct.STATUSES_DESCRIPTION;
        }

        return ReturnAct.STATUSES_DESCRIPTION.filter(x => {
            return (this.allowed_statuses.indexOf(x.id) !== -1);
        });
    }
}
