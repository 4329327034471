import {AfterViewInit, Component, Input} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {AppComponent} from '../../app.component';
import {NotifierService} from '../../service/notifier.service';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-act-print',
    templateUrl: './act-print.component.html',
    styleUrls: ['./act-print.component.scss'],
})
export class ActPrintComponent {
    public loading = false;
    @Input('icon') public icon = false;
    @Input('pp_act_number') public pp_act_number: string;

    constructor(
        private api: SkladService,
        private notifier: NotifierService,
        private app: AppComponent,
    ) {
    }

    public onOpen() {
        this.loading = true;
        this.api.getPpActByNumberForPrint(this.pp_act_number).subscribe((data) => {
            this.app.createDialog(ActPrintDialogComponent, {
                data: data,
            });
            this.loading = false;
        }, error => {
            this.loading = false;
            this.notifier.openNotifier(error.error.response.message, null, {class: 'danger', duration: 3000});
        });
    }
}


@Component({
    selector: 'app-act-print-dialog',
    styleUrls: ['./act-print.component.scss'],
    templateUrl: 'act-print-dialog.component.html',
})
export class ActPrintDialogComponent implements AfterViewInit {
    public data = null;
    public places = null;
    private WinPrint = window.open('', '', 'left=50,top=50,width=670,height=800,toolbar=0,menubar=0,location=0,scrollbars=1,status=0,clearcache=yes');
    private prtContent = null;

    constructor(
        protected app: AppComponent,
        public helpers: HelpersService,
    ) {
    }
    ngAfterViewInit() {
        this.prtContent = document.getElementById('print-div');
        const content = '' +
            '<html lang="ru">' +
            '    <head>' +
            '<meta http-equiv="Cache-Control" content="no-store"/>' +
            '<meta http-equiv="Pragma" content="no-cache"/>' +
            '<meta http-equiv="Expires" content="0"/>' +
            '        <meta charset="utf-8">' +
            '        <meta content="width=device-width, initial-scale=1" name="viewport">' +
            '        <link href="../../../assets/css/bootstrap.css" rel="stylesheet">' +
            '        <title>Печать актов</title>' +
            '    </head>' +
            this.prtContent.innerHTML +
            '       <script src="../../../assets/js/bootstrap.bundle.js"></script>' +
            '    </body>' +
            '</html>'

        this.WinPrint.document.write(content);
        /** автоматические закрытие окна, работает на Chrome подобных. Firefox срабатывает не корректно */
        this.WinPrint.addEventListener('afterprint', (event) => {
            this.WinPrint.close();
        });
        this.WinPrint.document.close();
        this.WinPrint.focus();

        /** для FireFox подобных иначе не успевает отрендерить стиль **/
        setTimeout(() => {
             this.WinPrint.print();
             this.app.closeDialog(this);
        }, !window.clientInformation ? 300 : 50);
    }
}
