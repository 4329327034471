import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-orders-placement-check',
    templateUrl: './orders-placement-check.component.html',
    styleUrls: ['./orders-placement-check.component.scss']
})
export class OrdersPlacementCheckComponent implements OnInit {
    public loading: boolean = false;

    public simpleMode: boolean = true;
    public normalMode: boolean;

    public order = null;

    public placementLogId = null;
    public placementZone = null;
    public placementShelf = null;
    public placementShelfInvalid = false;

    @ViewChild('barCodeInput') barCodeInput: ElementRef;

    constructor(
        protected api: SkladService,
        public title: Title
    ) {
    }

    ngOnInit() {
        this.title.setTitle('Проверка размещения');
        this.normalMode = !this.simpleMode;

        setTimeout(() => {
            this.barCodeInputFocus();
        }, 0);
    }

    /**
     * Обработка клика по кнопке переключения режима (между нормальным и простым)
     */
    onModeToggle() {
        this.simpleMode = !this.simpleMode;
        this.normalMode = !this.simpleMode;

        this.barCodeInputFocus();
    }

    /**
     * Обработка ввода штрихкода
     */
    onBarCodeEnter() {
        this.loading = true;
        this.order = null;

        let barCode = this.barCodeInput.nativeElement.value.trim();
        if (!barCode.length) {
            return;
        }

        let requestPayload = {
            'simple_mode': this.simpleMode,
            'barcode': barCode,
        }

        if (this.normalMode && this.placementZone) {
            requestPayload['zone_id'] = this.placementZone.zone_id;
        }

        this.api.orderPlacementScanBarCode(requestPayload).subscribe(data => {
            console.info(data);
            if ('undefined' !== typeof data.zone) {
                if (this.normalMode) {
                    this.placementZone = data.zone;
                    this.placementShelf = this.placementZone.shelf;
                } else {
                    alert('не надо сканировать полку в простом режиме');
                }
            }

            if ('undefined' !== typeof data.order) {
                this.order = data.order;
                this.placementLogId = data.placement_log_id;

                if (this.normalMode) {
                    this.placementShelfInvalid = this.placementZone.zone_id !== this.order.zone_id;
                }
            }

            this.loading = false;

            this.barCodeInputFocus(true);
        }, err => {
            console.warn('ERR', err);
        });

        this.barCodeInputFocus(true);
    }

    /**
     * Обработка клика по кнопке подтверждения размещения на неправильной полке
     */
    onInvalidShelfAccept() {
        this.loading = true;

        let requestPayload = {
            'simple_mode': this.simpleMode,
            'order_id': this.order.id,
            'order_uid': this.order.uid,
            'zone_id': this.placementZone.zone_id,
            'placement_log_id': this.placementLogId,
        }

        this.api.orderPlacementInvalidShelfAccept(requestPayload).subscribe(data => {
            console.info(data);

            this.placementShelfInvalid = false;
            this.loading = false;

            this.barCodeInputFocus(true);
        }, err => {
            console.warn('ERR', err);
        });

        this.barCodeInputFocus(true);
    }

    /**
     * Обработка клика по кнопке подтверждения перемещения на правильную полку
     */
    onInvalidShelfFix() {
        this.loading = true;

        let requestPayload = {
            'simple_mode': this.simpleMode,
            'order_id': this.order.id,
            'order_uid': this.order.uid,
            'zone_id': this.placementZone.zone_id,
            'placement_log_id': this.placementLogId,
        }

        this.api.orderPlacementInvalidShelfFix(requestPayload).subscribe(data => {
            console.info(data);

            this.placementShelfInvalid = false;
            this.loading = false;
        }, err => {
            console.warn('ERR', err);
        });

        this.barCodeInputFocus(true);
    }

    /**
     * Устанавливает фокус на поле ввода штрихкода
     * @param reset Очищать ли поле ввода
     */
    barCodeInputFocus(reset:boolean = false) {
        if (reset) {
            this.barCodeInput.nativeElement.value = '';
        }

        this.barCodeInput.nativeElement.focus();
    }

}
