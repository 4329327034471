import {Injectable} from '@angular/core';
import {LaraService} from './lara.service';

@Injectable({
    providedIn: 'root',
})

export class ReturnActService extends LaraService {
    /**
     * Немного информации по возвратному акту по его ШК
     * @param oldVactId
     */
    getReturnActDetails(oldVactId: string) {
        return this.get('/storage/return-acts/getReturnActDetails/' + oldVactId);
    }

    /**
     * Получение писка клиентов для создания
     * возвратного акта
     */
    getClientsForCreateReturnAct(hubId) {
        return this.get('/storage/return-acts/clientsForCreateReturnAct', {hub_id: hubId});
    }

    /**
     * Получение списка складов клиента
     * для которых возможны возвраты
     * @param clientId
     */
    clientWarehousesWhereReturnsPossible(hubId, clientId) {
        return this.get('/storage/return-acts/clientWarehousesWhereReturnsPossible', {
            client_id: clientId,
            hub_id: hubId,
        });
    }

    /**
     * Получение сипска возвратных актов
     * в статусе новый
     * @param clientId
     * @param warehouseId
     */
    getNewReturnActs(hubId, clientId, warehouseId) {
        return this.get('/storage/return-acts/getNewReturnActs', {
            client_id: clientId,
            warehouse_id: warehouseId,
            hub_id: hubId,
        });
    }


    /**
     * Получение информации
     * перед созданием акта
     * @param data
     */
    getInfoBeforeCreateAct(data) {
        return this.get('/storage/return-acts/infoBeforeCreateAct', data);
    }

    /**
     * Создание нового возвратного акта
     * @param data
     */
    createNewReturnAct(data) {
        return this.post('/storage/return-acts/new', data);
    }

    /**
     * Получение списка заборов с курьерами
     * для приявзки к возвратному акту
     * @param return_act_id
     */
    getZordersCanBeLinkedWithReturnAct(return_act_id) {
        return this.get('/storage/return-acts/zordersCanBeLinkedWithReturnAct/' + return_act_id);
    }

    /** получить возвратный акт по id **/
    public getActWithGoods(returnActId) {
        return this.get('/storage/return-acts/getActWithGoods/' + returnActId);
    }

    /** получить возвратный акт по id для печати **/
    public getActWithGoodsAndPlaces(returnActId) {
        return this.get('/storage/return-acts/getActWithGoodsAndPlaces/' + returnActId);
    }

    /** Установить статус возвратному акту **/
    public setReturnActStatus(returnActId, status) {
        return this.post(`/storage/return-acts/setReturnActStatus/${returnActId}`, {
            status: status
        });
    }

    public removeGoodFromReturnAct(data) {
        return this.post('/storage/return-acts/removeGoodFromReturnAct', data)
    }

    public removeOrderFromReturnAct(data) {
        return this.post('/storage/return-acts/removeOrderFromReturnAct', data)
    }

    /** получить товары которые можно добавить в возвратный акт **/
    public getOrdersForReturn(returnActId) {
        return this.get('/storage/return-acts/getOrdersForReturn/' + returnActId);
    }

    public addGoodsToReturnAct(data) {
        return this.post('/storage/return-acts/addGoodsToReturnAct', data);
    }

    public addOrderToReturnAct(data) {
        return this.post('/storage/return-acts/addOrderToReturnAct', data);
    }

    public scanShkForReturn(data) {
        return this.post('/storage/return-acts/scanShk', data);
    }

    public setReturnActFormed(returnActId) {
        return this.post('/storage/return-acts/setReturnActFormed', {return_act_id: returnActId});
    }

    // найдет или создаст возвратный акт по отсканированному месту/заказу
    public findOrCreateReturn(search) {
        return this.post('/storage/return-acts/findOrCreateReturnActByShk', {shk: search});
    }

    /**
     * помечает заказ как вскрытый/не вскрытый
     * @param data
     */
    public markOrderOpened(data) {
        return this.post('/storage/return-acts/markOrderOpened', data);
    }

    /**
     * Получить возвратные акты
     * для которых есть забор
     * @param data
     */
    public getReturnActsForWithZorder(data) {
        return this.post('/storage/return-acts/getReturnActsForWithZorder', data);
    }

    /**
     * Сканирование возвратного акта
     * и передача на доставку
     * @param data
     */
    public scanReturnActBeforeTransferForDelivery(data) {
        return this.post('/storage/return-acts/scanReturnActBeforeTransferForDelivery', data);
    }

    /**
     * Передача возвратного акта на доставку
     * @param data
     */
    public transferActForDelivery(data) {
        return this.post('/storage/return-acts/transferActForDelivery', data);
    }

    /**
     * Передача возвратного акта на доставку
     * @param data
     */
    public transferActToClient(data) {
        return this.post('/storage/return-acts/transferActToClient', data);
    }


    /**
     * Возврат акта с доставки
     * @param data
     */
    returnFromDeliveryReturnAct(data) {
        return this.post('/storage/return-acts/returnFromDeliveryReturnAct', data);
    }

    /**
     * "Исправление" свойств возвратного акта
     * @deprecated Временное решение - удалим, когда потеряет актуальность
     * @param oldVactId
     */
    fixReturnAct(oldVactId) {
        return this.post('/storage/return-acts/fixReturnAct/' + oldVactId, {});
    }
}
