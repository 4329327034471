import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {Observable, Subscription} from 'rxjs';
import {SkladService} from '../../service/sklad.service';

@Component({
    selector: 'app-penalty-and-complaint-select',
    templateUrl: './penalty-and-complaint-select.component.html',
    styleUrls: ['./penalty-and-complaint-select.component.scss']
})
export class PenaltyAndComplaintSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi = false;
    @Input() itemsShowLimit = 1;
    @Input() placeHolder = 'Тип документа';
    @Input() initValue: number[] = [];
    @Output() onChange = new EventEmitter<number[]>();
    dropdownList = [
        {
            name: 'Штрафы',
            value: 1,
        },
        {
            name: 'Жалобы',
            value: 2,
        }
    ];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    private eventsSubscription: Subscription;
    @Input() events: Observable<number[]>;

    constructor(
        private api: SkladService,
    ) {
    }

    ngOnInit(): void {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onSetSelectedValues(data));
        }
        if (this.initValue.length > 0) {
            this.selectedItems = this.dropdownList.filter(x => {
                return this.initValue.indexOf(x.value) !== -1
            })
        }
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: true
        };
    }

    /**
     * Смена выбранных данных, от родителя
     * @param data
     */
    onSetSelectedValues(data) {
        this.selectedItems = this.dropdownList.filter(x => {
            return data.indexOf(x.value) !== -1
        })
    }
    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
