import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-universal-edit-modal',
    templateUrl: './universal-edit-modal.component.html',
    styleUrls: ['./universal-edit-modal.component.scss'],
})
export class UniversalEditModalComponent implements OnInit {

    @Input() addItem;
    @Input() updateItem;

    @Input() fields: { key: string, label: string, type: string, items?: [] }[];

    public form: FormGroup;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Редактирование';

    public itemIsSaving: boolean = false;
    public isNewItem: boolean = true;

    public dateMask = [/[0-3]/, /\d/, '.', /[01]/, /\d/, '.', /[12]/, /[09]/, /\d/, /\d/];

    constructor(
        protected app: AppComponent,
    ) {
        if (window.innerWidth > 800) {
            this.width = 800;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        const controls = {};
        for (let i in this.fields) {
            controls[this.fields[i].key] = new FormControl();
        }

        this.form = new FormGroup(controls);
    }

    onAddItemClick() {
        const query = this.form.value;
        this.addItem(query, () => { this.onClose(); });
    }

    onSaveItemClick() {

    }

    onClose() {
        this.app.closeDialog(this);
    }
}
