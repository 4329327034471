import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-printlabel',
    templateUrl: './printlabel.component.html',
    styleUrls: ['./printlabel.component.scss']
})
export class PrintlabelComponent implements OnInit {
    uid: String;
    innerNumber: String;
    deliveryDate: String;
    status: String;
    dateObj: String;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            // console.log(params);
            this.uid = params['uid'];
            this.innerNumber = params['inner_n'];
            this.deliveryDate = params['delivery_date'];
            this.status = params['status'];
            this.black = 'black';
            this.svg = 'svg';
            this.CODE128 = 'CODE128';
            this.bottom = 'bottom';
            this.center = 'center';
            this.monospace = 'monospace';
        })
    }

}
